import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Surveys from './Surveys';
import SurveyForm from './SurveyForm';
import SurveyView from './SurveyView';

export default (props) => {
  return (
    <Router>
      <div>
        <Route exact path="/cascopt/surveys" render={routeProps => <Surveys {...props} {...routeProps}/>} />
        <Route exact path="/cascopt/surveys/:id" render={routeProps => <SurveyView {...props} {...routeProps}/>} />
        <Route path="/cascopt/surveys/:id/edit" render={routeProps => <SurveyForm {...props} {...routeProps}/>} />
      </div>
    </Router>
  )
}
