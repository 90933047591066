import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Bims from './Bims';
import BimForm from './BimForm';
import BimView from './BimView';

export default (props) => {
  return (
    <Router>
      <div>
        <Route exact path="/cascopt/bims" render={routeProps => <Bims {...props} {...routeProps}/>} />
        <Route exact path="/cascopt/bims/:id" render={routeProps => <BimView {...props} {...routeProps}/>} />
        <Route path="/cascopt/bims/:id/edit" component={BimForm} />
      </div>
    </Router>
  )
}
