/* eslint-disable react/prefer-stateless-function */
import React from 'react';

export default class DsmWaitOnAt extends React.Component {
  render() {
    return (
      <div>
        <h3 id="header">DSM Waiting for AT</h3>
        <a href="/cascopt/dsms" className="view-record">
          DSM Dashboard
        </a>
        <br />
        <a href="/cascopt/aerotriangulations" className="view-record">
          AT Dashboard
        </a>
      </div>
    );
  }
}
