/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';;
import { Link } from 'react-router-dom';

  export default class Project extends React.Component {
    render() {
      return (
        <div>
          <h3 className="mb-4 text-4xl">
            <i className="fa fa-building"></i> Listing Companies
          </h3>
          <hr />
        </div>
      );
    }
  }
