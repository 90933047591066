import React from 'react';
import PropTypes from 'prop-types';
import { FormErrors } from './FormErrors';
import update from 'immutability-helper';
import { validations } from '../utils/validations';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

export default class DeliveryForm extends React.Component {
  static propTypes = {
    handleNewDelivery: PropTypes.func
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      client_number: { value: "", valid: false },
      client_name: { value: "", valid: false },
      due_date: { value: "", valid: false },
      job_number: { value: "", valid: false },
      project_id: { value: "", valid: false },
      delivered: "",
      invoice_stage: "",
      ftp_name: { value: "", valid: true },
      ftp_pass: { value: "", valid: true },
      ftp_update: { value: "", valid: true },
      delivery_method: { value: "", valid: true },
      project_notes: { value: "", valid: true },
      delivery_notes: { value: "", valid: true },
      date_delivered: { value: "", valid: true },
      formErrors: {},
      formValid: false,
      editing: false,
    };
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
  }

  static formValidations = {
    project_id: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    ftp_name: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    ftp_pass: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    ftp_update: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    date_delivered: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    delivery_method: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    project_notes: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    delivery_notes: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ]
  }

  componentDidMount() {
    if(this.props.match) {
    $.ajax({
      type: "GET",
      url: `/cascopt/deliveries/${this.props.match.params.id}`,
      dataType: "JSON"
    }).done((data) => {
      this.setState({
        client_number: { value: data.project.client_number, valid: true },
        client_name: { value: data.project.client_name, valid: true },
        job_number: { value: data.project.job_number, valid: true },
        due_date: { value: data.project.due_date, valid: true },
        project_id: { value: data.delivery.project_id, valid: true },
        delivered: data.delivery.delivered,
        invoice_stage: data.delivery.invoice_stage,
        ftp_name: { value: data.delivery.ftp_name, valid: true },
        ftp_pass: { value: data.delivery.ftp_pass, valid: true },
        date_delivered: { value: data.delivery.date_delivered, valid: true },
        ftp_update: { value: data.delivery.ftp_update, valid: true },
        delivery_method: { value: data.delivery.delivery_method, valid: true },
        project_notes: { value: data.project.project_notes, valid: true },
        delivery_notes: { value: data.project.delivery_notes, valid: true },
        editing: this.props.match.path === "/cascopt/deliveries/:id/edit",
      });
    });
   }
  }

  handleUserInput = (fieldName, fieldValue, validations) => {
    const newFieldState = update(this.state[fieldName],
                                  {value: {$set: fieldValue}});
    this.setState({[fieldName]: newFieldState},
                  () => { this.validateField(fieldName, fieldValue, validations) });
  }

  validateField (fieldName, fieldValue, validations) {
    let fieldValid;

    let fieldErrors = validations.reduce((errors, v) => {
      let e = v(fieldValue);
      if(e !== '') {
        errors.push(e);
      }
      return(errors);
    }, []);

    fieldValid = fieldErrors.length === 0;

    const newFieldState = update(this.state[fieldName],
                                  {valid: {$set: fieldValid}});

    const newFormErrors = update(this.state.formErrors,
                                  {$merge: {[fieldName]: fieldErrors}});

    this.setState({[fieldName]: newFieldState,
                    formErrors: newFormErrors}, this.validateForm);
  }

  validateForm() {
    this.setState({formValid: this.state.project_id.valid &&
                              this.state.ftp_name.valid &&
                              this.state.ftp_pass.valid &&
                              this.state.ftp_update.valid &&
                              this.state.delivery_method.valid &&
                              this.state.project_notes.valid &&
                              this.state.delivery_notes.valid &&
                              this.state.date_delivered.valid
                            });
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.state.editing ?
      this.updateDelivery() :
      this.addDelivery();
  }

  updateDelivery() {
    const delivery = {project_id: this.state.project_id.value,
      delivered: this.state.delivered,
      invoice_stage: this.state.invoice_stage,
      ftp_name: this.state.ftp_name.value,
      ftp_pass: this.state.ftp_pass.value,
      ftp_update: this.state.ftp_update.value,
      date_delivered: this.state.date_delivered.value,
      delivery_method: this.state.delivery_method.value,
      project_notes: this.state.project_notes.value,
      delivery_notes: this.state.delivery_notes.value
      };
    $.ajax({
      type: "PATCH",
      url: `/cascopt/deliveries/${this.props.match.params.id}`,
      data: {delivery: delivery}
    })
    .done((data) => {
      console.log('delivery updated!');
      this.resetFormErrors();
      this.props.history.goBack('/cascopt/deliveries');
    })
    .fail((response) => {
      alert("Please review the form for errors");
      this.setState({
        formErrors: response.responseJSON,
        formValid: false
      })
    });
  }

  addDelivery() {
    const delivery = {project_id: this.state.project_id.value,
      delivered: this.state.delivered,
      invoice_stage: this.state.invoice_stage,
      ftp_name: this.state.ftp_name.value,
      ftp_pass: this.state.ftp_pass.value,
      date_delivered: this.state.date_delivered.value,
      ftp_update: this.state.ftp_update.value,
      delivery_method: this.state.delivery_method.value,
      project_notes: this.state.project_notes.value,
      delivery_notes: this.state.delivery_notes.value
      };
    $.post('/cascopt/deliveries', {delivery: delivery})
    .done((data) => {
      this.props.handleNewDelivery(data);
      this.resetFormErrors();
    })
    .fail((response) => {
      this.setState({formErrors: response.responseJSON, formValid: false})
    });
  }

  deleteDelivery = () => {
    if(confirm("Are you sure you want to delete this survey?")) {
      $.ajax({
        type: "DELETE",
        url: `/cascopt/deliveries/${this.props.match.params.id}`,
      })
      .done((data) => {
        this.props.history.push('/cascopt/deliveries');
        this.resetFormErrors();
      })
      .fail((response) => {
        console.log("Delivery deletion failed!");
      });
    }
  }

  resetFormErrors() {
    this.setState({formErrors: {}})
  }

  handleChange(e) {
    this.handleUserInput(e.target.name, e.target.value, DeliveryForm.formValidations[e.target.name]);
  }

  handleSelectChange(key) {
  return function ({ value }) {
      this.setState({[key]: value});
    }.bind(this);
  }

  render() {
    return (
      <div className="container" id="form-card">
        <h4 className="mb-4 text-4xl">
          {this.state.editing ? "Update Delivery" : "Create Delivery"} for |
          {this.state.client_number.value} - {this.state.job_number.value}|
        </h4>
        <p>Due:{moment(this.state.due_date.value).format("MM/DD/YYYY")}</p>
        <p>Client: {this.state.client_name.value}</p>
        <small>
          (* represents a <strong>required field</strong>)
        </small>
        <FormErrors formErrors={this.state.formErrors} />
        <form onSubmit={this.handleFormSubmit}>
          <div className="row">
            <div className="col-md-3">
              <label>Delivery Method *</label>
              <input
                name="delivery_method"
                placeholder="Delivery Method"
                value={this.state.delivery_method.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col-md-3">
              <label>Delivered *</label>
              <Select
                name="delivered"
                value={this.state.delivered}
                onChange={this.handleSelectChange("delivered")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
            {this.state.delivered == "Yes" && (
              <div className="col-md-3">
                <label>Date Delivered *</label>
                <input
                  type="date"
                  name="date_delivered"
                  value={this.state.date_delivered.value}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            )}
          </div>
          <p/>
          <div className="row">
            <div className="col-md-3">
              <label>Invoice Stage *</label>
              <Select
                name="invoice_stage"
                value={this.state.invoice_stage}
                onChange={this.handleSelectChange("invoice_stage")}
                options={[
                  { value: "Invoice", label: "Invoice" },
                  { value: "Invoice T&M", label: "Invoice T&M" },
                  {
                    value: "Invoice Partial Billing",
                    label: "Invoice Partial Billing",
                  },
                ]}
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col">
              <label>Notes *</label>
              <textarea
                name="delivery_notes"
                placeholder="Notes"
                value={this.state.delivery_notes.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col">
              <label>Project Notes</label>
              <textarea
                name="project_notes"
                placeholder="Project Notes"
                value={this.state.project_notes.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
          </div>
          <p />
          <input
            type="submit"
            value={this.state.editing ? "Update" : "Create"}
            className="btn btn-secondary btn-sm"
          />
          <a
            className="btn btn-danger btn-sm"
            onClick={this.props.history.goBack}
          >
            Cancel
          </a>
        </form>
        <br />
      </div>
    );
  }
}
