import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import moment from 'moment';

export default class ProjectEstimates extends React.Component {
  static propTypes = {
    projects: PropTypes.array
  }

  static defaultProps = {
    projects: []
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      projects: this.props.projects,
      isHidden: true,
      isHiddenNotComplete: false,
      isLoading: false
    }
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden
    })
  }

  toggleHiddenNotComplete() {
    this.setState({
      isHiddenNotComplete: !this.state.isHiddenNotComplete
    })
  }

  componentDidMount() {
    if (this.props.match) {
      this.setState({ isLoading: true });
      $.ajax({
        type: "GET",
        url: "/cascopt/project_estimates",
        dataType: "JSON"
      }).done((data) => {
        this.setState({ projects: data, isLoading: false });
      })
    }
  }

  render() {
    const pickDateColor = (date) => {
      const dueDate = new moment(date);
      const yellowDate = new moment(date).subtract(5, "days");
      const now = moment();

      if (now < yellowDate) {
        return "black";
      }
      if (now < dueDate) {
        return "blue";
      }
      return "black";
    };

    const { projects, isLoading } = this.state;
    if (isLoading) {
      return <div className="loader"></div>;
    }

    return (
      <div>
        <p />
        {!this.state.isHiddenNotComplete && (
          <ReactTable
            key={projects.id}
            data={projects}
            filterable
            defaultPageSize={100}
            sortable={true}
            style={{
              height: "800px",
              textAlign: "center",
            }}
            columns={[
              {
                Header: "Project Info",
                columns: [
                  {
                    Header: "Job Number",
                    filterable: false,
                    Cell: (props) => (
                      <a
                        href={`/cascopt/projects/${props.original.id}`}
                        className="view-record"
                      >
                        {props.original.job_number}
                      </a>
                    ),
                  },
                  {
                    Header: "Project Name",
                    accessor: "job_name",
                    filterable: false,
                  },
                  {
                    Header: "Client #",
                    filterable: false,
                    accessor: "client_number",
                  },
                  {
                    Header: "Client Name",
                    filterable: false,
                    accessor: "client_name",
                  },
                  {
                    Header: "Last Updated",
                    filterable: false,
                    accessor: "updated_at",
                    Cell: (props) => (
                      <span style={{ color: pickDateColor(props.value) }}>
                        {moment(props.value).format("MM/DD/YYYY")}
                      </span>
                    ),
                  },
                ],
              },
            ]}
            defaultSorted={[
              {
                id: "job_number",
                asc: true,
              },
            ]}
            className="-striped -highlight"
          />
        )}
      </div>
    );
  }
}
