import React from 'react';
import PropTypes from 'prop-types';
import { FormErrors } from './FormErrors';
import update from 'immutability-helper';
import { validations } from '../utils/validations';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { withRouter } from 'react-router-dom';

export default class CompSubForm extends React.Component {
  static propTypes = {
    handleNewCompSub: PropTypes.func
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      client_number: { value: '', valid: false },
      client_name: { value: '', valid: false },
      project_due_date: { value: '', valid: false },
      job_number: { value: '', valid: false },
      project_id: {value: '', valid: false},
      sub_name: {value: '', valid: false},
      due_date: {value: '', valid: true},
      sub_done: '',
      models_to_sub: {value: '', valid: true},
      models_to_do: {value: '', valid: true},
      received_date: {value: '', valid: true},
      received: '',
      partial_delivered: '',
      hours: {value: '', valid: true},
      rejected: '',
      rejected_date: {value: '', valid: true},
      rejected_notes: {value: '', valid: true},
      actual_hours: {value: '', valid: true},
      formErrors: {},
      formValid: false,
      editing: false
    }
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
  }

  static formValidations = {
    project_id: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    sub_name: [
      (s) => { return(validations.checkMinLength(s, 3)) }
    ],
    due_date: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    models_to_sub: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    models_to_do: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    received_date: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    hours: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    rejected_date: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    rejected_notes: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    actual_hours: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
  }

  componentDidMount() {
    if(this.props.match) {
    $.ajax({
      type: "GET",
      url: `/cascopt/comp_subs/${this.props.match.params.id}`,
      dataType: "JSON"
    }).done((data) => {
      this.setState({
        client_number: {value: data.project.client_number, valid: true},
        client_name: { value: data.project.client_name, valid: true },
        job_number: { value: data.project.job_number, valid: true },
        project_due_date: { value: data.project.due_date, valid: true },
        project_id: {value: data.comp_sub.project_id, valid: true},
        sub_name: {value: data.comp_sub.sub_name, valid: true},
        due_date: {value: data.comp_sub.due_date, valid: true},
        sub_done: data.comp_sub.sub_done,
        models_to_sub: {value: data.comp_sub.models_to_sub, valid: true},
        models_to_do: {value: data.comp_sub.models_to_do, valid: true},
        received_date: {value: data.comp_sub.received_date, valid: true},
        received: data.comp_sub.received,
        partial_delivered: data.comp_sub.partial_delivered,
        hours: {value: data.comp_sub.hours, valid: true},
        rejected: data.comp_sub.rejected,
        rejected_date: {value: data.comp_sub.rejected_date, valid: true},
        rejected_notes: {value: data.comp_sub.rejected_notes, valid: true},
        actual_hours: {value: data.comp_sub.actual_hours, valid: true},
        editing: this.props.match.path === '/cascopt/comp_subs/:id/edit'
      });
    });
   }
  }

  handleUserInput = (fieldName, fieldValue, validations) => {
    const newFieldState = update(this.state[fieldName],
                                  {value: {$set: fieldValue}});
    this.setState({[fieldName]: newFieldState},
                  () => { this.validateField(fieldName, fieldValue, validations) });
  }

  validateField (fieldName, fieldValue, validations) {
    let fieldValid;

    let fieldErrors = validations.reduce((errors, v) => {
      let e = v(fieldValue);
      if(e !== '') {
        errors.push(e);
      }
      return(errors);
    }, []);

    fieldValid = fieldErrors.length === 0;

    const newFieldState = update(this.state[fieldName],
                                  {valid: {$set: fieldValid}});

    const newFormErrors = update(this.state.formErrors,
                                  {$merge: {[fieldName]: fieldErrors}});

    this.setState({[fieldName]: newFieldState,
                    formErrors: newFormErrors}, this.validateForm);
  }

  validateForm() {
    this.setState({formValid: this.state.project_id.valid &&
                              this.state.sub_name.valid &&
                              this.state.due_date.valid &&
                              this.state.models_to_sub.valid &&
                              this.state.models_to_do.valid &&
                              this.state.received_date.valid &&
                              this.state.hours.valid &&
                              this.state.rejected_date.valid &&
                              this.state.rejected_notes.valid &&
                              this.state.actual_hours.valid
                            });
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.state.editing ?
      this.updateCompSub() :
      this.addCompSub();
  }

  updateCompSub() {
    const comp_sub = {project_id: this.state.project_id.value,
      sub_name: this.state.sub_name.value,
      due_date: this.state.due_date.value,
      sub_done: this.state.sub_done,
      models_to_sub: this.state.models_to_sub.value,
      models_to_do: this.state.models_to_do.value,
      received_date: this.state.received_date.value,
      received: this.state.received,
      partial_delivered: this.state.partial_delivered,
      hours: this.state.hours.value,
      rejected: this.state.rejected,
      rejected_date: this.state.rejected_date.value,
      rejected_notes: this.state.rejected_notes.value,
      actual_hours: this.state.actual_hours.value};
    $.ajax({
      type: "PATCH",
      url: `/cascopt/comp_subs/${this.props.match.params.id}`,
      data: {comp_sub: comp_sub}
    })
    .done((data) => {
      console.log('Compilation-Sub updated!');
      this.resetFormErrors();
      this.props.history.goBack('/cascopt/comp_subs');
    })
    .fail((response) => {
      this.setState({formErrors: response.responseJSON,
        formValid: false})
    });
  }

  addCompSub() {
    const comp_sub = {project_id: this.state.project_id.value,
      sub_name: this.state.sub_name.value,
      due_date: this.state.due_date.value,
      sub_done: this.state.sub_done,
      models_to_sub: this.state.models_to_sub.value,
      models_to_do: this.state.models_to_do.value,
      received_date: this.state.received_date.value,
      received: this.state.received,
      partial_delivered: this.state.partial_delivered,
      hours: this.state.hours.value,
      rejected: this.state.rejected,
      rejected_date: this.state.rejected_date.value,
      rejected_notes: this.state.rejected_notes.value,
      actual_hours: this.state.actual_hours.value};
    $.post('/cascopt/comp_subs', {comp_sub: comp_sub})
    .done((data) => {
      this.props.handleNewCompSub(data);
      this.resetFormErrors();
    })
    .fail((response) => {
      this.setState({formErrors: response.responseJSON,
        formValid: false})
    });
  }

  deleteCompSub = () => {
    if(confirm("Are you sure you want to delete this Compilation-Sub?")) {
      $.ajax({
        type: "DELETE",
        url: `/cascopt/comp_subs/${this.props.match.params.id}`,
      })
      .done((data) => {
        this.props.history.push('/cascopt/comp_subs');
        this.resetFormErrors();
      })
      .fail((response) => {
        console.log("Compilation-Sub deletion failed!");
      });
    }
  }

  resetFormErrors() {
    this.setState({formErrors: {}})
  }

  handleChange(e) {
    this.handleUserInput(e.target.name, e.target.value, CompSubForm.formValidations[e.target.name]);
  }

  handleSelectChange(key) {
  return function ({ value }) {
      this.setState({[key]: value});
    }.bind(this);
  }

  render() {
    return (
      <div className="jumbotron" id="form-card">
        <h4 className="mb-4 text-4xl">
        {this.state.editing ?
          'Update Compilation Sub' :
            'Create Compilation Sub'} for |{this.state.client_number.value} - {this.state.job_number.value}|
        </h4>
        <h5>Due: <input type="date" readOnly format="mm/dd/yyyy" className="form-control-plaintext" value={this.state.project_due_date.value} /></h5>
        <h5> Client: {this.state.client_name.value}</h5>
        <small>
          (* represents a <strong>required field</strong>)
        </small>
        <FormErrors formErrors = {this.state.formErrors} />
        <form onSubmit={this.handleFormSubmit} >
          <div className="row">
            <div className="col">
              <label>Sub Name *</label>
              <input name="sub_name" placeholder="Sub Name"
                value={this.state.sub_name.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
            <div className="col">
              <label>Sub Due Date *</label>
              <input type="date" name="due_date" placeholder="Due Date"
                value={this.state.due_date.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
          </div>

          <p />
          <div className="row">
            <div className="col">
              <label>Models To Sub *</label>
              <input name="models_to_sub" placeholder="Models to Sub"
                value={this.state.models_to_sub.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
            <div className="col">
              <label>Models To Do *</label>
              <input name="models_to_do" placeholder="Models To Do"
                value={this.state.models_to_do.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
            <div className="col">
              <label>Partial Delivered *</label>
              <Select
                name="partial_delivered"
                value={this.state.partial_delivered}
                onChange={this.handleSelectChange("partial_delivered")}
                options={[
                  { value: 'n/a', label: 'n/a'},
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
          </div>

          <p />
          <div className="row">
            <div className="col">
              <label>Est Hours *</label>
              <input name="hours" placeholder="Hours"
                value={this.state.hours.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
            <div className="col">
              <label>Actual Hours (hh:mm format) *</label>
              <input name="actual_hours" placeholder="Actual Hours"
                value={this.state.actual_hours.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
            <div className="col">
              <label>Rejected *</label>
              <Select
                name="rejected"
                value={this.state.rejected}
                onChange={this.handleSelectChange("rejected")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
            <div className="col">
              <label>Rejected Date *</label>
              <input type="date" name="rejected_date" placeholder="Date Rejected"
                value={this.state.rejected_date.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
          </div>

          <p />
          <div className="row">
            <div className="col">
              <label>Sub Done *</label>
              <Select
                name="sub_done"
                value={this.state.sub_done}
                onChange={this.handleSelectChange("sub_done")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
            <div className="col">
              <label>Received *</label>
              <Select
                name="received"
                value={this.state.received}
                onChange={this.handleSelectChange("received")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
            <div className="col">
              <label>Received Date *</label>
              <input type="date" name="received_date" placeholder="Date Received"
                value={this.state.received_date.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
          </div>

          <p />
          <div className="row">
          <div className="col">
              <label>Rejected Notes *</label>
              <textarea name="rejected_notes" placeholder="Rejected Notes" rows="4" cols="40"
                value={this.state.rejected_notes.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
          </div>

          <p />
          <input type="submit"
            value={this.state.editing ? 'Update' : 'Create'} className="btn btn-secondary btn-sm"
           />
        </form>
        <br />
      </div>
    )
  }
}
