/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';

export default class Contact extends React.Component {
  render() {
    return (
      <div>
        {/* eslint - disable - next - line react/jsx-one-expression-per-line */}
        <h3 className="mb-4 text-4xl">
          <i className="fa fa-users" /> Listing Active Contacts
        </h3>
        <a href="/inactive_contacts" className="view-record">
          View Inactive Contacts
        </a>
        <hr />
      </div>
    );
  }
}
