import React from 'react';
import ProposalForm from './ProposalForm';

const getType = (proposal_type) => {
  switch (proposal_type) {
    case 'Flight':
      return ProposalForm;
    case 'UAV':
      return ProposalForm;
    case 'Film':
      return ProposalForm;
    case 'Film - Photo Geodetic':
      return ProposalForm;
    case 'Film (Image Only)':
      return ProposalForm;
    case 'Film (Mapping Only)':
      return ProposalForm;
    case 'Film (Image Only) - Photo Geodetic':
      return ProposalForm;
    case 'Film (Mapping Only) - Photo Geodetic':
      return ProposalForm;
    case 'Digital':
      return ProposalForm;
    case 'Digital - Photo Geodetic':
      return ProposalForm;
    case 'Digital (Image Only)':
      return ProposalForm;
    case 'Digital (Mapping Only)':
      return ProposalForm;
    case 'Digital (Image Only) - Photo Geodetic':
      return ProposalForm;
    case 'Digital (Mapping Only) - Photo Geodetic':
      return ProposalForm;
    case 'LiDAR':
      return ProposalForm;
    case 'Scan':
      return ProposalForm;
    case 'Plot':
      return ProposalForm;
    case 'Survey':
      return ProposalForm;
    case 'Other':
      return ProposalForm;
    case 'ADOT':
      return ProposalForm;
    case 'LiDAR Processing':
      return ProposalForm;
    case 'Construction':
      return ProposalForm;
    default:
      return ProposalForm;
  }
};

const ProposalType = (props) => {
  const ProposingForm = getType(props.proposal.proposal_type);
  if (!ProposingForm) return null;
  return (
    <ProposingForm {...props} />
  );
};

export default ProposalType;
