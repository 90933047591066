import React from 'react';
import PropTypes from 'prop-types';
import { FormErrors } from './FormErrors';
import update from 'immutability-helper';
import { validations } from '../utils/validations';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { withRouter } from 'react-router-dom';

export default class DroneForm extends React.Component {
  static propTypes = {
    handleNewDrone: PropTypes.func
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      problem_survey: '',
      client_number: {value: '', valid: false},
      client_name: {value: '', valid: false},
      job_number: {value: '', valid: false},
      due_date: {value: '', valid: false},
      project_id: { value: '', valid: false },
      flown: '',
      photo_quality: { value: '', valid: true },
      altitude: { value: '', valid: true },
      video: '',
      deliverables: { value: '', valid: true },
      camera: { value: '', valid: true },
      aircraft: { value: '', valid: true },
      photos_uploaded: '',
      no_control: '',
      obliques: '',
      gsd: { value: '', valid: true },
      processing_software: { value: '', valid: true },
      flight_date: { value: '', valid: true },
      project_notes: { value: '', valid: true },
      drone_notes: { value: '', valid: true },
      formErrors: {},
      formValid: false,
      editing: false
    }
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
  }

  static formValidations = {
    project_id: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    photo_quality: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    altitude: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    deliverables: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    camera: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    aircraft: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    gsd: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    processing_software: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    flight_date: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    project_notes: [
      (s) => { return (validations.checkMinLength(s, 0)) }
    ],
    drone_notes: [
      (s) => { return (validations.checkMinLength(s, 0)) }
    ]
  }

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/cascopt/drones/${this.props.match.params.id}`,
        dataType: "JSON"
      }).done((data) => {
        this.setState({
          problem_survey: data.drone.problem_survey,
          client_number: {value: data.project.client_number, valid: true},
          client_name: {value: data.project.client_name, valid: true},
          job_number: {value: data.project.job_number, valid: true},
          due_date: {value: data.project.due_date, valid: true},
          project_id: { value: data.drone.project_id, valid: true },
          flown: data.drone.flown,
          altitude: { value: data.drone.altitude, valid: true },
          video: data.drone.video,
          deliverables: { value: data.drone.deliverables, valid: true },
          camera: { value: data.drone.camera, valid: true },
          aircraft: { value: data.drone.aircraft, valid: true },
          photos_uploaded: data.drone.photos_uploaded,
          no_control: data.drone.no_control,
          obliques: data.drone.obliques,
          gsd: { value: data.gsd, valid: true },
          flight_date: { value: data.drone.flight_date, valid: true },
          processing_software: { value: data.drone.processing_software, valid: true },
          photo_quality: { value: data.drone.photo_quality, valid: true },
          project_notes: { value: data.project.project_notes, valid: true },
          drone_notes: { value: data.project.drone_notes, valid: true },
          editing: this.props.match.path === '/drones/:id/edit'
        });
      });
    }
  }

  handleUserInput = (fieldName, fieldValue, validations) => {
    const newFieldState = update(this.state[fieldName],
      { value: { $set: fieldValue } });
    this.setState({ [fieldName]: newFieldState },
      () => { this.validateField(fieldName, fieldValue, validations) });
  }

  validateField(fieldName, fieldValue, validations) {
    let fieldValid;

    let fieldErrors = validations.reduce((errors, v) => {
      let e = v(fieldValue);
      if (e !== '') {
        errors.push(e);
      }
      return (errors);
    }, []);

    fieldValid = fieldErrors.length === 0;

    const newFieldState = update(this.state[fieldName],
      { valid: { $set: fieldValid } });

    const newFormErrors = update(this.state.formErrors,
      { $merge: { [fieldName]: fieldErrors } });

    this.setState({
      [fieldName]: newFieldState,
      formErrors: newFormErrors
    }, this.validateForm);
  }

  validateForm() {
    this.setState({
      formValid: this.state.project_id.valid &&
        this.state.photo_quality.valid &&
        this.state.altitude.valid &&
        this.state.deliverables.valid &&
        this.state.camera.valid &&
        this.state.aircraft.valid &&
        this.state.gsd.valid &&
        this.state.flight_date.valid &&
        this.state.processing_software.valid &&
        this.state.project_notes.valid &&
        this.state.drone_notes.valid
    });
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.state.editing ?
      this.updateDrone() :
      this.addDrone();
  }

  updateDrone() {
    const drone = {
      problem_survey: this.state.problem_survey,
      project_id: this.state.project_id.value,
      flown: this.state.flown,
      photo_quality: this.state.photo_quality.value,
      altitude: this.state.altitude.value,
      video: this.state.video,
      deliverables: this.state.deliverables.value,
      camera: this.state.camera.value,
      aircraft: this.state.aircraft.value,
      photos_uploaded: this.state.photos_uploaded,
      no_control: this.state.no_control,
      obliques: this.state.obliques,
      gsd: this.state.gsd.value,
      flight_date: this.state.flight_date.value,
      processing_software: this.state.processing_software.value,
      project_notes: this.state.project_notes.value,
      drone_notes: this.state.drone_notes.value
    };
    $.ajax({
      type: "PATCH",
      url: `/cascopt/drones/${this.props.match.params.id}`,
      data: { drone: drone }
    })
      .done((data) => {
        console.log('drone updated!');
        this.resetFormErrors();
        this.props.history.goBack('/cascopt/drones');
      })
      .fail((response) => {
        alert("Please review the form for errors");
        this.setState({
          formErrors: response.responseJSON,
          formValid: false
        })
      });
  }

  addDrone() {
    const drone = {
      problem_survey: this.state.problem_survey,
      project_id: this.state.project_id.value,
      flown: this.state.flown,
      photo_quality: this.state.photo_quality.value,
      altitude: this.state.altitude.value,
      video: this.state.video,
      deliverables: this.state.deliverables.value,
      camera: this.state.camera.value,
      aircraft: this.state.aircraft.value,
      photos_uploaded: this.state.photos_uploaded,
      no_control: this.state.no_control,
      obliques: this.state.obliques,
      gsd: this.state.gsd.value,
      flight_date: this.state.flight_date.value,
      processing_software: this.state.processing_software.value,
      project_notes: this.state.project_notes.value,
      drone_notes: this.state.drone_notes.value
    };
    $.post('/cascopt/drones', { drone: drone })
      .done((data) => {
        this.props.handleNewDrone(data);
        this.resetFormErrors();
      })
      .fail((response) => {
        this.setState({ formErrors: response.responseJSON, formValid: false })
      });
  }

  deleteDrone = () => {
    if (confirm("Are you sure you want to delete this drone?")) {
      $.ajax({
        type: "DELETE",
        url: `/cascopt/drones/${this.props.match.params.id}`,
      })
        .done((data) => {
          this.props.history.push('/cascopt/drones');
          this.resetFormErrors();
        })
        .fail((response) => {
          console.log("Drone deletion failed!");
        });
    }
  }

  resetFormErrors() {
    this.setState({ formErrors: {} })
  }

  handleChange(e) {
    this.handleUserInput(e.target.name, e.target.value, DroneForm.formValidations[e.target.name]);
  }

  handleSelectChange(key) {
  return function ({ value }) {
      this.setState({[key]: value});
    }.bind(this);
  }

  render() {
    return (
      <div className="jumbotron" id="form-card">
        <h4 className="mb-4 text-4xl">
          {this.state.editing ?
            'Update Drone' :
            'Create Drone'} for |{this.state.client_number.value}-{this.state.job_number.value}|
        </h4>
        <h5>Due: <input type="date" readOnly className="form-control-plaintext" format="MM/DD/YYYY" value={this.state.due_date.value} /></h5>
        <h5>Client: {this.state.client_name.value}</h5>
        <small>
          (* represents a <strong>required field</strong>)
        </small>
        <FormErrors formErrors={this.state.formErrors} />
        <form onSubmit={this.handleFormSubmit} >
          <div className="row">
            <div className="col">
              <label>Aircraft *</label>
              <input type="text" name="aircraft" placeholder="Aircraft"
                value={this.state.aircraft.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
            <div className="col">
              <label>Camera *</label>
              <input type="text" name="camera" placeholder="Camera"
                value={this.state.camera.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
            <div className="col">
              <label>Altitude *</label>
              <input type="text" name="altitude" placeholder="Altitude"
                value={this.state.altitude.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
            <div className="col">
              <label>GSD *</label>
              <input type="text" name="gsd" placeholder="GSD"
                value={this.state.gsd.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col-md-3">
              <label>Processing Software *</label>
              <input type="text" name="processing_software" placeholder="Processing Software"
                value={this.state.processing_software.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col">
              <label>Video *</label>
              <Select
                name="video"
                value={this.state.video}
                onChange={this.handleSelectChange("video")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
            <div className="col">
              <label>Photos Uploaded *</label>
              <Select
                name="photos_uploaded"
                value={this.state.photos_uploaded}
                onChange={this.handleSelectChange("photos_uploaded")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
            <div className="col-md-1">
              <label>No Control *</label>
              <Select
                name="no_control"
                value={this.state.no_control}
                onChange={this.handleSelectChange("no_control")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
            <div className="col">
              <label>Obliques *</label>
              <Select
                name="obliques"
                value={this.state.obliques}
                onChange={this.handleSelectChange("obliques")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col-md-4">
              <label>Flown *</label>
              <Select
                name="flown"
                value={this.state.flown}
                onChange={this.handleSelectChange("flown")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
            {this.state.flown == 'Yes' &&
            <div className="col-md-4">
              <label>Flight Date *</label>
              <input type="date" name="flight_date" placeholder="Flight Date"
                value={this.state.flight_date.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>}
            <div className="col-md-4">
              <label>Problem w/ Survey</label>
              <Select
                name="problem_survey"
                value={this.state.problem_survey}
                onChange={this.handleSelectChange("problem_survey")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col">
              <label>Notes *</label>
              <textarea name="drone_notes" placeholder="UAV Notes" rows="4" cols="40"
                value={this.state.drone_notes.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col">
              <label>Project Notes</label>
              <textarea name="project_notes" placeholder="Project Notes" rows="4" cols="40"
                value={this.state.project_notes.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
          </div>
          <p />
          <input type="submit"
            value={this.state.editing ? 'Update' : 'Create'} className="btn btn-secondary btn-sm"
            />
        </form>
        <br />
      </div>
    )
  }
}
