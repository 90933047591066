import React from 'react';
import PropTypes from 'prop-types';
import { FormErrors } from './FormErrors';
import update from 'immutability-helper';
import { validations } from '../utils/validations';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

export default class DsmForm extends React.Component {
  static propTypes = {
    handleNewDsm: PropTypes.func
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      grid_distance: { value: '', valid: true },
      staff: { value: '', valid: true },
      due_date: { value: '', valid: true },
      client_number: { value: '', valid: true },
      job_number: { value: '', valid: true },
      job_name: { value: '', valid: false },
      project_location: { value: '', valid: false },
      project_id: { value: '', valid: false },
      complete: '',
      tiled_gridded: '',
      done_date: { value: '', valid: false },
      project_notes: { value: '', valid: true },
      dsm_notes: { value: '', valid: true },
      formErrors: {},
      formValid: false,
      editing: false,
    }
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
  }

  static formValidations = {
    project_id: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    staff: [
      (s) => { return (validations.checkMinLength(s, 3)) }
    ],
    grid_distance: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    due_date: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    done_date: [
      (s) => { return (validations.checkMinLength(s, 3)) }
    ],
    dsm_notes: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    project_notes: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ]
  }

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/cascopt/dsms/${this.props.match.params.id}`,
        dataType: "JSON"
      }).done((data) => {
        this.setState({
          client_name: { value: data.project.client_name, valid: true },
          project_manager: data.project.project_manager,
          deliverables: data.project.deliverables,
          due_date: { value: data.project.due_date, valid: true },
          client_number: { value: data.project.client_number, valid: true },
          job_number: { value: data.project.job_number, valid: true },
          job_name: { value: data.project.job_name, valid: true },
          project_id: { value: data.dsm.project_id, valid: true },
          staff: { value: data.dsm.staff, valid: true },
          grid_distance: { value: data.dsm.grid_distance, valid: true },
          complete: data.dsm.complete,
          tiled_gridded: data.dsm.tiled_gridded,
          due_date: { value: data.dsm.due_date, valid: true },
          done_date: { value: data.dsm.done_date, valid: true },
          dsm_notes: { value: data.dsm.dsm_notes, valid: true },
          project_notes: { value: data.project.project_notes, valid: true },
          editing: this.props.match.path === '/cascopt/dsms/:id/edit'
        });
      });
    }
  }

  handleUserInput = (fieldName, fieldValue, validations) => {
    const newFieldState = update(this.state[fieldName],
      { value: { $set: fieldValue } });
    this.setState({ [fieldName]: newFieldState },
      () => { this.validateField(fieldName, fieldValue, validations) });
  }

  validateField(fieldName, fieldValue, validations) {
    let fieldValid;

    let fieldErrors = validations.reduce((errors, v) => {
      let e = v(fieldValue);
      if (e !== '') {
        errors.push(e);
      }
      return (errors);
    }, []);

    fieldValid = fieldErrors.length === 0;

    const newFieldState = update(this.state[fieldName],
      { valid: { $set: fieldValid } });

    const newFormErrors = update(this.state.formErrors,
      { $merge: { [fieldName]: fieldErrors } });

    this.setState({
      [fieldName]: newFieldState,
      formErrors: newFormErrors
    }, this.validateForm);
  }

  validateForm() {
    this.setState({
      formValid: this.state.project_id.valid &&
        this.state.job_name.valid &&
        this.state.project_location.valid &&
        this.state.staff.valid &&
        this.state.grid_distance.valid &&
        this.state.due_date.valid &&
        this.state.done_date.valid &&
        this.state.project_notes.valid &&
        this.state.dsm_notes.valid
    });
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.state.editing ?
      this.updateDsm() :
      this.addDsm();
  }

  updateDsm() {
    const dsm = {
      project_id: this.state.project_id.value,
      staff: this.state.staff.value,
      grid_distance: this.state.grid_distance.value,
      complete: this.state.complete,
      tiled_gridded: this.state.tiled_gridded,
      due_date: this.state.due_date.value,
      done_date: this.state.done_date.value,
      project_notes: this.state.project_notes.value,
      dsm_notes: this.state.dsm_notes.value
    };
    $.ajax({
      type: "PATCH",
      url: `/cascopt/dsms/${this.props.match.params.id}`,
      data: { dsm: dsm }
    })
      .done((data) => {
        console.log('dsm updated!');
        this.resetFormErrors();
        this.props.history.goBack('/cascopt/dsms');
      })
      .fail((response) => {
        alert("Please review the form for errors");
        this.setState({
          formErrors: response.responseJSON,
          formValid: false
        })
      });
  }

  addDsm() {
    const dsm = {
      project_id: this.state.project_id.value,
      staff: this.state.staff.value,
      grid_distance: this.state.grid_distance.value,
      complete: this.state.complete,
      tiled_gridded: this.state.tiled_gridded,
      due_date: this.state.due_date.value,
      done_date: this.state.done_date.value,
      project_notes: this.state.project_notes.value,
      dsm_notes: this.state.dsm_notes.value
    };
    $.post('/cascopt/dsms', { dsm: dsm })
      .done((data) => {
        this.props.handleNewDsm(data);
        this.resetFormErrors();
      })
      .fail((response) => {
        this.setState({ formErrors: response.responseJSON, formValid: false })
      });
  }

  deleteDsm = () => {
    if (confirm("Are you sure you want to delete this dsm?")) {
      $.ajax({
        type: "DELETE",
        url: `/cascopt/dsms/${this.props.match.params.id}`,
      })
        .done((data) => {
          this.props.history.push('/cascopt/dsms');
          this.resetFormErrors();
        })
        .fail((response) => {
          console.log("DSM deletion failed!");
        });
    }
  }

  resetFormErrors() {
    this.setState({ formErrors: {} })
  }

  handleChange(e) {
    this.handleUserInput(e.target.name, e.target.value, DsmForm.formValidations[e.target.name]);
  }

  handleSelectChange(key) {
    return function ({ value }) {
      this.setState({ [key]: value });
    }.bind(this);
  }

  render() {
    return (
      <div className="container" id="form-card">
        <h4 className="mb-4 text-4xl">
          {this.state.editing ?
            'Update DSM ' :
            'Create DSM '}
          for {this.state.job_name.value} | {this.state.client_number.value || ''}-{this.state.job_number.value || ''}
        </h4>
        <small>
          (* represents a <strong>required field</strong>)
        </small>
        <FormErrors formErrors={this.state.formErrors} />
        <form onSubmit={this.handleFormSubmit} >
          <div className="row">
            <div className="col">
              <label>Staff *</label>
              <input type="text" name="staff" placeholder="DSM Staff"
                required
                value={this.state.staff.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
            <div className="col">
              <label>Grid Distance *</label>
              <input type="text" name="grid_distance"
                value={this.state.grid_distance.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
            <div className="col">
              <label>Tiled/Gridded</label>
              <Select
                name="tiled_gridded"
                value={this.state.tiled_gridded}
                onChange={this.handleSelectChange("tiled_gridded")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col">
              <label>DSM Due Date *</label>
              <input type="date" name="due_date"
                value={this.state.due_date.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
            <div className="col">
              <label>Complete</label>
              <Select
                required
                name="complete"
                value={this.state.complete}
                onChange={this.handleSelectChange("complete")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
            <div className="col">
              <label>DSM Done Date *</label>
              <input type="date" name="done_date"
                value={this.state.done_date.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col">
              <label>DSM Notes *</label>
              <textarea name="dsm_notes" placeholder="DSM Notes" rows="4" cols="40"
                value={this.state.dsm_notes.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col">
              <label>Project Notes</label>
              <textarea name="project_notes"
                placeholder="Project Notes"
                value={this.state.project_notes.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
          </div>
          <p />
          <input type="submit"
            value={this.state.editing ? 'Update' : 'Create'} className="btn btn-secondary btn-sm"
          />
          <a className="btn btn-danger btn-sm" onClick={this.props.history.goBack}>Cancel</a>
        </form>
        <br />
      </div>
    )
  }
}
