/* eslint-disable react/prefer-stateless-function */
import React from 'react';

export default class Aerotriangulation extends React.Component {
  render() {
    return (
      <div>
        <h3 id="header">Projects in Aerial Triangulation</h3>
        <a href="/cascopt/not_received_at" className="view-record">
          AT Dashboard - Control Not Received
        </a>
        <br />
        <a href="/cascopt/at_to_sub" className="view-record">
          AT Dashboard - To Sub
        </a>
        <br />
        <a href="/cascopt/dsms" className="view-record">
          DSM Dashboard
        </a>
        <br />
        <a href="/cascopt/at_search" className="view-record">
          AT Search
        </a>
      </div>
    );
  }
}
