// analytics_infinite_scroll_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["table", "tbody"];
  static values = { hasMore: Boolean };

  initialize() {
    this.loading = false;
    this.page = 1;
  }

  connect() {
    this.scroll();
    window.addEventListener("scroll", () => this.scroll());
  }

  disconnect() {
    window.removeEventListener("scroll", () => this.scroll());
  }

  scroll() {
    if (this.bottomVisible() && !this.loading) {
      this.loading = true;
      this.page++;
      this.loadMore();
    }
  }

  bottomVisible() {
    const scrollY = window.scrollY;
    const visible = document.documentElement.clientHeight;
    const pageHeight = document.documentElement.scrollHeight;
    const bottomOfPage = visible + scrollY >= pageHeight;
    return bottomOfPage || pageHeight < visible;
  }

  loadMore() {
    fetch(`/cascopt/analytics/production_analytics/pg.json?page=${this.page}`, {
      headers: { "X-Requested-With": "XMLHttpRequest" },
    })
      .then((response) => response.json())
      .then((data) => {
        this.hasMoreValue = data.has_more;
        this.tbodyTarget.insertAdjacentHTML("beforeend", data.content);
        this.loading = false;
      });
  }
}
