import React from "react";
import PropTypes from "prop-types";
import { FormErrors } from "./FormErrors";
import update from "immutability-helper";
import { validations } from "../utils/validations";
import Select from "react-select";
import "react-select/dist/react-select.css";
import { withRouter } from "react-router-dom";

export default class LidarForm extends React.Component {
  static propTypes = {
    handleNewLidar: PropTypes.func,
  };

  constructor(props, railsContext) {
    super(props);
    this.state = {
      initial_qc_emp: { value: "", valid: false },
      client_number: { value: "", valid: false },
      client_name: { value: "", valid: false },
      job_number: { value: "", valid: false },
      due_date: { value: "", valid: false },
      project_id: { value: "", valid: false },
      ground_employee: { value: "", valid: true },
      lidar_employee: { value: "", valid: false },
      qc_employee: { value: "", valid: true },
      complete: "",
      final_lidar: "",
      cleaned: "",
      dem_grid: "",
      classified_macro: "",
      total_hours: { value: "", valid: true },
      total_blocks: { value: "", valid: true },
      total_people: { value: "", valid: true },
      completion_rate: { value: "", valid: true },
      comments: { value: "", valid: true },
      blocks: "",
      stringing: "",
      complexity: { value: "", valid: true },
      ppsm: { value: "", valid: true },
      file_location: { value: "", valid: true },
      planimetrics: { value: "", valid: true },
      project_notes: { value: "", valid: true },
      lidar_notes: { value: "", valid: true },
      lidar_required: "",
      current_lidar_staff: "",
      lidar_staff: "",
      to_sub: "",
      total_tiles: { value: "", valid: true },
      model_diagram: { value: "", valid: true },
      actual_hours: { value: "", valid: true },
      lidar_sub_name: { value: "", valid: true },
      sub_alloted_hours: { value: "", valid: true },
      sub_due_date: { value: "", valid: true },
      cleaned_date: { value: "", valid: true },
      date_sent_to_sub: { value: "", valid: true },
      received_from_sub_date: { value: "", valid: true },
      tiles_to_sub: { value: "", valid: true },
      rejected: "",
      rejected_date: { value: "", valid: true },
      rejected_reason: { value: "", valid: true },
      redeliver_date: { value: "", valid: true },
      tiles_received_from_sub: { value: "", valid: true },
      sub_notes: { value: "", valid: true },
      comp_staff_options: [
        { value: "READY", label: "READY" },
        { value: "Axel", label: "Axel" },
        { value: "Becky C", label: "Becky C" },
        { value: "Ben", label: "Ben" },
        { value: "Brian", label: "Brian" },
        { value: "Dave", label: "Dave" },
        { value: "Grek", label: "Grek" },
        { value: "Joe", label: "Joe" },
        { value: "Karole", label: "Karole" },
        { value: "Lawrence", label: "Lawrence" },
        { value: "Max", label: "Max" },
        { value: "Michael", label: "Michael" },
        { value: "Miguel", label: "Miguel" },
        { value: "Sherman", label: "Sherman" },
      ],
      deliverable_options: [
        { value: "DTM", label: "DTM" },
        { value: 'Plan 1" = 10', label: 'Plan 1" = 10' },
        { value: 'Plan 1" = 20', label: 'Plan 1" = 20' },
        { value: 'Plan 1" = 30', label: 'Plan 1" = 30' },
        { value: 'Plan 1" = 40', label: 'Plan 1" = 40' },
        { value: 'Plan 1" = 50', label: 'Plan 1" = 50' },
        { value: 'Plan 1" = 60', label: 'Plan 1" = 60' },
        { value: 'Plan 1" = 100', label: 'Plan 1" = 100' },
        { value: "Contours .5", label: "Contours .5" },
        { value: "Contours 1", label: "Contours 1" },
        { value: "Contours 2", label: "Contours 2" },
        { value: "Contours 5", label: "Contours 5" },
        { value: "Contours 10", label: "Contours 10" },
        { value: "XML", label: "XML" },
        { value: "DXF", label: "DXF" },
        { value: "DMG", label: "DMG" },
        { value: "SHP", label: "SHP" },
      ],
      support_options: [
        { value: "LAZ", label: "LAZ" },
        { value: "LAS", label: "LAS" },
        { value: "KMZ", label: "KMZ" },
        { value: "DXF", label: "DXF" },
        { value: "Ortho for Plan", label: "Ortho for Plan" },
      ],
      tiles_remaining: { value: "", valid: true },
      macro_file_location: { value: "", valid: true },
      macro_ran: "",
      ground_only_ortho_location: { value: "", valid: true },
      ground_only_las_ortho_created: "",
      formErrors: {},
      formValid: false,
      editing: false,
    };
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
  }

  static formValidations = {
    project_id: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    initial_qc_emp: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    tiles_remaining: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    macro_file_location: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    ground_only_ortho_location: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    lidar_employee: [
      (s) => {
        return validations.checkMinLength(s, 3);
      },
    ],
    ground_employee: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    qc_employee: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    total_hours: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    total_blocks: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    total_people: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    completion_rate: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    comments: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    complexity: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    ppsm: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    file_location: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    planimetrics: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    project_notes: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    lidar_notes: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    total_tiles: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    model_diagram: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    actual_hours: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    lidar_sub_name: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    sub_alloted_hours: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    sub_due_date: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    cleaned_date: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    date_sent_to_sub: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    received_from_sub_date: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    tiles_to_sub: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    rejected_date: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    rejected_reason: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    redeliver_date: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    tiles_received_from_sub: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    sub_notes: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
  };

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/cascopt/lidars/${this.props.match.params.id}`,
        dataType: "JSON",
      }).done((data) => {
        this.setState({
          initial_qc_emp: { value: data.lidar.initial_qc_emp, valid: true },
          tiles_remaining: { value: data.lidar.tiles_remaining, valid: true },
          macro_file_location: {
            value: data.lidar.macro_file_location,
            valid: true,
          },
          ground_only_ortho_location: {
            value: data.lidar.ground_only_ortho_location,
            valid: true,
          },
          initial_qc_emp: { value: data.lidar.initial_qc_emp, valid: true },
          client_number: { value: data.project.client_number, valid: true },
          client_name: { value: data.project.client_name, valid: true },
          job_number: { value: data.project.job_number, valid: true },
          due_date: { value: data.project.due_date, valid: true },
          project_id: { value: data.lidar.project_id, valid: true },
          ground_employee: { value: data.lidar.ground_employee, valid: true },
          lidar_employee: { value: data.lidar.lidar_employee, valid: true },
          qc_employee: { value: data.lidar.qc_employee, valid: true },
          complete: data.lidar.complete,
          final_lidar: data.lidar.final_lidar,
          cleaned: data.lidar.cleaned,
          dem_grid: data.lidar.dem_grid,
          classified_macro: data.lidar.classified_macro,
          macro_ran: data.lidar.macro_ran,
          ground_only_las_ortho_created:
            data.lidar.ground_only_las_ortho_created,
          total_hours: { value: data.lidar.total_hours, valid: true },
          total_blocks: { value: data.lidar.total_blocks, valid: true },
          total_people: { value: data.lidar.total_people, valid: true },
          completion_rate: { value: data.lidar.completion_rate, valid: true },
          comments: { value: data.lidar.comments, valid: true },
          blocks: data.lidar.blocks,
          stringing: data.lidar.stringing,
          complexity: { value: data.lidar.complexity, valid: true },
          ppsm: { value: data.lidar.ppsm, valid: true },
          file_location: { value: data.lidar.file_location, valid: true },
          planimetrics: { value: data.lidar.planimetrics, valid: true },
          project_notes: { value: data.project.project_notes, valid: true },
          lidar_notes: { value: data.project.lidar_notes, valid: true },
          lidar_required: data.lidar.lidar_required,
          current_lidar_staff: data.lidar.current_lidar_staff,
          lidar_staff: data.lidar.lidar_staff,
          to_sub: data.lidar.to_sub,
          total_tiles: { value: data.lidar.total_tiles, valid: true },
          model_diagram: { value: data.lidar.model_diagram, valid: true },
          actual_hours: { value: data.lidar.actual_hours, valid: true },
          lidar_sub_name: { value: data.lidar.lidar_sub_name, valid: true },
          sub_alloted_hours: {
            value: data.lidar.sub_alloted_hours,
            valid: true,
          },
          sub_due_date: { value: data.lidar.sub_due_date, valid: true },
          cleaned_date: { value: data.lidar.cleaned_date, valid: true },
          date_sent_to_sub: { value: data.lidar.date_sent_to_sub, valid: true },
          received_from_sub_date: {
            value: data.lidar.received_from_sub_date,
            valid: true,
          },
          tiles_to_sub: { value: data.lidar.tiles_to_sub, valid: true },
          rejected: data.lidar.rejected,
          rejected_date: { value: data.lidar.rejected_date, valid: true },
          rejected_reason: { value: data.lidar.rejected_reason, valid: true },
          redeliver_date: { value: data.lidar.redeliver_date, valid: true },
          tiles_received_from_sub: {
            value: data.lidar.tiles_received_from_sub,
            valid: true,
          },
          sub_notes: { value: data.lidar.sub_notes, valid: true },
          editing: this.props.match.path === "/cascopt/lidars/:id/edit",
        });
      });
    }
  }

  handleUserInput = (fieldName, fieldValue, validations) => {
    const newFieldState = update(this.state[fieldName], {
      value: { $set: fieldValue },
    });
    this.setState({ [fieldName]: newFieldState }, () => {
      this.validateField(fieldName, fieldValue, validations);
    });
  };

  validateField(fieldName, fieldValue, validations) {
    let fieldValid;

    let fieldErrors = validations.reduce((errors, v) => {
      let e = v(fieldValue);
      if (e !== "") {
        errors.push(e);
      }
      return errors;
    }, []);

    fieldValid = fieldErrors.length === 0;

    const newFieldState = update(this.state[fieldName], {
      valid: { $set: fieldValid },
    });

    const newFormErrors = update(this.state.formErrors, {
      $merge: { [fieldName]: fieldErrors },
    });

    this.setState(
      { [fieldName]: newFieldState, formErrors: newFormErrors },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.project_id.valid &&
        this.state.ground_employee.valid &&
        this.state.tiles_remaining.valid &&
        this.state.macro_file_location.valid &&
        this.state.macro_ran &&
        this.state.ground_only_ortho_location.valid &&
        this.state.ground_only_las_ortho_created &&
        this.state.lidar_employee.valid &&
        this.state.qc_employee.valid &&
        this.state.total_hours.valid &&
        this.state.total_blocks.valid &&
        this.state.total_people.valid &&
        this.state.completion_rate.valid &&
        this.state.comments.valid &&
        this.state.complexity.valid &&
        this.state.ppsm.valid &&
        this.state.file_location.valid &&
        this.state.planimetrics.valid &&
        this.state.project_notes.valid &&
        this.state.lidar_notes.valid &&
        this.state.initial_qc_emp &&
        this.state.total_tiles &&
        this.state.model_diagram &&
        this.state.actual_hours &&
        this.state.lidar_sub_name &&
        this.state.sub_alloted_hours &&
        this.state.sub_due_date &&
        this.state.cleaned_date &&
        this.state.date_sent_to_sub &&
        this.state.received_from_sub_date &&
        this.state.tiles_received_from_sub &&
        this.state.tiles_to_sub &&
        this.state.rejected_date &&
        this.state.rejected_reason &&
        this.state.redeliver_date &&
        this.state.sub_notes,
    });
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.state.editing ? this.updateLidar() : this.addLidar();
  };

  updateLidar() {
    const lidar = {
      project_id: this.state.project_id.value,
      ground_employee: this.state.ground_employee.value,
      lidar_employee: this.state.lidar_employee.value,
      qc_employee: this.state.qc_employee.value,
      complete: this.state.complete,
      final_lidar: this.state.final_lidar,
      cleaned: this.state.cleaned,
      dem_grid: this.state.dem_grid,
      classified_macro: this.state.classified_macro,
      total_hours: this.state.total_hours.value,
      tiles_remaining: this.state.tiles_remaining.value,
      macro_file_location: this.state.macro_file_location.value,
      macro_ran: this.state.macro_ran,
      ground_only_ortho_location: this.state.ground_only_ortho_location.value,
      ground_only_las_ortho_created: this.state.ground_only_las_ortho_created,
      total_blocks: this.state.total_blocks.value,
      total_people: this.state.total_people.value,
      completion_rate: this.state.completion_rate.value,
      comments: this.state.comments.value,
      blocks: this.state.blocks,
      stringing: this.state.stringing,
      complexity: this.state.complexity.value,
      ppsm: this.state.ppsm.value,
      file_location: this.state.file_location.value,
      planimetrics: this.state.planimetrics.value,
      project_notes: this.state.project_notes.value,
      lidar_notes: this.state.lidar_notes.value,
      initial_qc_emp: this.state.initial_qc_emp.value,
      lidar_required: this.state.lidar_required,
      current_lidar_staff: this.state.current_lidar_staff,
      lidar_staff: this.state.lidar_staff,
      to_sub: this.state.to_sub,
      total_tiles: this.state.total_tiles.value,
      model_diagram: this.state.model_diagram.value,
      actual_hours: this.state.actual_hours.value,
      lidar_sub_name: this.state.lidar_sub_name.value,
      sub_alloted_hours: this.state.sub_alloted_hours.value,
      sub_due_date: this.state.sub_due_date.value,
      cleaned_date: this.state.cleaned_date.value,
      date_sent_to_sub: this.state.date_sent_to_sub.value,
      received_from_sub_date: this.state.received_from_sub_date.value,
      tiles_to_sub: this.state.tiles_to_sub.value,
      rejected: this.state.rejected,
      rejected_date: this.state.rejected_date.value,
      rejected_reason: this.state.rejected_reason.value,
      redeliver_date: this.state.redeliver_date.value,
      tiles_received_from_sub: this.state.tiles_received_from_sub.value,
      sub_notes: this.state.sub_notes.value,
    };
    $.ajax({
      type: "PATCH",
      url: `/cascopt/lidars/${this.props.match.params.id}`,
      data: { lidar: lidar },
    })
      .done((data) => {
        console.log("lidar updated!");
        this.resetFormErrors();
        this.props.history.goBack("/cascopt/lidars");
      })
      .fail((response) => {
        alert("Please review the form for errors");
        this.setState({
          formErrors: response.responseJSON,
          formValid: false,
        });
      });
  }

  addLidar() {
    const lidar = {
      project_id: this.state.project_id.value,
      ground_employee: this.state.ground_employee.value,
      lidar_employee: this.state.lidar_employee.value,
      qc_employee: this.state.qc_employee.value,
      complete: this.state.complete,
      final_lidar: this.state.final_lidar,
      cleaned: this.state.cleaned,
      dem_grid: this.state.dem_grid,
      classified_macro: this.state.classified_macro,
      total_hours: this.state.total_hours.value,
      tiles_remaining: this.state.tiles_remaining.value,
      macro_file_location: this.state.macro_file_location.value,
      macro_ran: this.state.macro_ran,
      ground_only_ortho_location: this.state.ground_only_ortho_location.value,
      ground_only_las_ortho_created: this.state.ground_only_las_ortho_created,
      total_blocks: this.state.total_blocks.value,
      total_people: this.state.total_people.value,
      completion_rate: this.state.completion_rate.value,
      comments: this.state.comments.value,
      blocks: this.state.blocks,
      stringing: this.state.stringing,
      complexity: this.state.complexity.value,
      ppsm: this.state.ppsm.value,
      file_location: this.state.file_location.value,
      planimetrics: this.state.planimetrics.value,
      project_notes: this.state.project_notes.value,
      lidar_notes: this.state.lidar_notes.value,
      initial_qc_emp: this.state.initial_qc_emp.value,
      lidar_required: this.state.lidar_required,
      current_lidar_staff: this.state.current_lidar_staff,
      lidar_staff: this.state.lidar_staff,
      to_sub: this.state.to_sub,
      total_tiles: this.state.total_tiles.value,
      model_diagram: this.state.model_diagram.value,
      actual_hours: this.state.actual_hours.value,
      lidar_sub_name: this.state.lidar_sub_name.value,
      sub_alloted_hours: this.state.sub_alloted_hours.value,
      sub_due_date: this.state.sub_due_date.value,
      cleaned_date: this.state.cleaned_date.value,
      date_sent_to_sub: this.state.date_sent_to_sub.value,
      received_from_sub_date: this.state.received_from_sub_date.value,
      tiles_to_sub: this.state.tiles_to_sub.value,
      rejected: this.state.rejected,
      rejected_date: this.state.rejected_date.value,
      rejected_reason: this.state.rejected_reason.value,
      redeliver_date: this.state.redeliver_date.value,
      tiles_received_from_sub: this.state.tiles_received_from_sub.value,
      sub_notes: this.state.sub_notes.value,
    };
    $.post("/cascopt/lidars", { lidar: lidar })
      .done((data) => {
        this.props.handleNewLidar(data);
        this.resetFormErrors();
      })
      .fail((response) => {
        this.setState({ formErrors: response.responseJSON, formValid: false });
      });
  }

  deleteLidar = () => {
    if (confirm("Are you sure you want to delete this LiDAR?")) {
      $.ajax({
        type: "DELETE",
        url: `/cascopt/lidars/${this.props.match.params.id}`,
      })
        .done((data) => {
          this.props.history.push("/lidars");
          this.resetFormErrors();
        })
        .fail((response) => {
          console.log("LiDAR deletion failed!");
        });
    }
  };

  resetFormErrors() {
    this.setState({ formErrors: {} });
  }

  handleChange(e) {
    this.handleUserInput(
      e.target.name,
      e.target.value,
      LidarForm.formValidations[e.target.name]
    );
  }

  handleMultiSelectChange(key) {
    return function (array) {
      this.setState({ [key]: array });
    }.bind(this);
  }

  handleSelectChange(key) {
    return function ({ value }) {
      this.setState({ [key]: value });
    }.bind(this);
  }

  render() {
    return (
      <div className="container" id="form-card">
        <h4 className="mb-4 text-4xl">
          <hr />
          {this.state.editing ? "Update LiDAR" : "Create LiDAR"} -{" "}
          {this.state.client_number.value} - {this.state.job_number.value}
        </h4>
        <small>
          (* represents a <strong>required field</strong>)
        </small>
        <FormErrors formErrors={this.state.formErrors} />
        <form onSubmit={this.handleFormSubmit}>
          <div className="row">
            <div className="col-md">
              <label>Is LiDAR Required?</label>
              <Select
                required
                name="lidar_required"
                value={this.state.lidar_required}
                onChange={this.handleSelectChange("lidar_required")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
            <div className="col-md">
              <label>PPSM</label>
              <input
                name="ppsm"
                value={this.state.ppsm.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col-md">
              <label>Macro Ran</label>
              <Select
                required
                name="macro_ran"
                value={this.state.macro_ran}
                onChange={this.handleSelectChange("macro_ran")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col-md">
              <label>Current LiDAR Staff *</label>
              <Select.Creatable
                name="current_lidar_staff"
                value={this.state.current_lidar_staff}
                allowCreate={true}
                multi
                simpleValue
                delimiter=", "
                onChange={this.handleMultiSelectChange("current_lidar_staff")}
                options={this.state.comp_staff_options}
              />
            </div>
            <div className="col-md">
              <label>LiDAR Staff *</label>
              <Select.Creatable
                name="lidar_staff"
                value={this.state.lidar_staff}
                allowCreate={true}
                multi
                simpleValue
                delimiter=", "
                onChange={this.handleMultiSelectChange("lidar_staff")}
                options={this.state.comp_staff_options}
              />
            </div>
            <div className="col-md">
              <label>To Sub *</label>
              <Select
                required
                name="to_sub"
                value={this.state.to_sub}
                onChange={this.handleSelectChange("to_sub")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col-md">
              <label>Total Tiles</label>
              <input
                name="total_tiles"
                value={this.state.total_tiles.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col-md">
              <label>Tiles Remaining</label>
              <input
                name="tiles_remaining"
                value={this.state.tiles_remaining.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col-md">
              <label>Cost Quote from Sub*</label>
              {/* actual_hours has been repurposed as 'cost quote from sub' field */}
              <input
                name="actual_hours"
                placeholder="Cost Quote from Sub"
                value={this.state.actual_hours.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col-md">
              <label>Cooper Staff LiDAR Hours (hh:mm)*</label>
              <input
                name="total_hours"
                placeholder="Cooper Staff Hours"
                value={this.state.total_hours.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col-md">
              <label>File Location</label>
              <input
                name="file_location"
                value={this.state.file_location.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col-md">
              <label>Macro File Location</label>
              <input
                name="macro_file_location"
                value={this.state.macro_file_location.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col-md">
              <label>Ground Only LAS Ortho Created *</label>
              <Select
                name="ground_only_las_ortho_created"
                value={this.state.ground_only_las_ortho_created}
                onChange={this.handleSelectChange(
                  "ground_only_las_ortho_created"
                )}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
            <div className="col-md">
              <label>Ground Only LAS Orth File Location</label>
              <input
                name="ground_only_ortho_location"
                value={this.state.ground_only_ortho_location.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col-md-3">
              <label>LiDAR Cleaned *</label>
              <Select
                name="cleaned"
                value={this.state.cleaned}
                onChange={this.handleSelectChange("cleaned")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
            {this.state.cleaned == "Yes" && 
            <div className="col-md-3">
              <label>Date Cleaned</label>
              <input
                type="date"
                name="cleaned_date"
                value={this.state.cleaned_date.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            }
            <div className="col-md-3">
              <label>Classified/Macro *</label>
              <Select
                name="classified_macro"
                value={this.state.classified_macro}
                onChange={this.handleSelectChange("classified_macro")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
            <div className="col-md-3">
              <label>DEM Grid *</label>
              <Select
                name="dem_grid"
                value={this.state.dem_grid}
                onChange={this.handleSelectChange("dem_grid")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col-md-3">
              <label>Final LiDAR on ToArchive *</label>
              <Select
                name="final_lidar"
                value={this.state.final_lidar}
                onChange={this.handleSelectChange("final_lidar")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
            <div className="col-md-3">
              <label>LiDAR Done *</label>
              <Select
                name="complete"
                value={this.state.complete}
                onChange={this.handleSelectChange("complete")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
          </div>
          <p />
          <h5>LiDAR Sub Info</h5>
          <div className="row">
            <div className="col">
              <label>Sub Name</label>
              <input
                type="text"
                name="lidar_sub_name"
                placeholder="Who is doing the LiDAR?"
                value={this.state.lidar_sub_name.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col">
              <label>Date Sent to Sub</label>
              <input
                type="date"
                name="date_sent_to_sub"
                value={this.state.date_sent_to_sub.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col">
              <label>Sub Due Date</label>
              <input
                type="date"
                name="sub_due_date"
                value={this.state.sub_due_date.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col">
              <label>Received From Sub Date</label>
              <input
                type="date"
                name="received_from_sub_date"
                value={this.state.received_from_sub_date.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col-md-3">
              <label>Tiles to Sub</label>
              <input
                type="text"
                name="tiles_to_sub"
                placeholder="How many tiles were sent to sub?"
                value={this.state.tiles_to_sub.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col-md-3">
              <label>Tiles Received from Sub (#)</label>
              <input
                name="tiles_received_from_sub"
                value={this.state.tiles_received_from_sub.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col-md-3">
              <label>Rejected</label>
              <Select
                name="rejected"
                value={this.state.rejected}
                onChange={this.handleSelectChange("rejected")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
            <div className="col-md-3">
              <label>Rejected Date</label>
              <input
                type="date"
                name="rejected_date"
                value={this.state.rejected_date.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
          </div>
          <p />
          <div className="form-group row">
            <div className="col-md-3">
              <label>Rejected Reason</label>
              <input
                name="rejected_reason"
                value={this.state.rejected_reason.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col-md-3">
              <label>Redeliver Date</label>
              <input
                type="date"
                name="redeliver_date"
                value={this.state.redeliver_date.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col-md-3">
              <label>Surface File(s) Location</label>
              <input
                name="comments"
                value={this.state.comments.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col">
              <label>Deliverables</label>
              <Select.Creatable
                name="blocks"
                value={this.state.blocks}
                allowCreate={true}
                multi
                simpleValue
                delimiter=", "
                onChange={this.handleMultiSelectChange("blocks")}
                options={this.state.deliverable_options}
              />
            </div>
            <div className="col">
              <label>Support Files</label>
              <Select.Creatable
                name="stringing"
                value={this.state.stringing}
                allowCreate={true}
                multi
                simpleValue
                delimiter=", "
                onChange={this.handleMultiSelectChange("stringing")}
                options={this.state.support_options}
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col">
              <label>Sub Notes</label>
              <textarea
                name="sub_notes"
                placeholder="Notes from Sub"
                value={this.state.sub_notes.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
          </div>
          <p />
          <input
            type="submit"
            value={this.state.editing ? "Update" : "Create"}
            className="btn btn-secondary btn-sm"
          />
          <a
            className="btn btn-danger btn-sm"
            onClick={this.props.history.goBack}
          >
            Cancel
          </a>
        </form>
        <br />
      </div>
    );
  }
}
