import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import 'react-select/dist/react-select.css';

export default class OpportunityForm extends React.Component {
  static propTypes = {
    handleNewOpportunity: PropTypes.func
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      user_id: '',
      contact_id: '',
      name: '',
      location: '',
      zip: '',
      latitude: '',
      longitude: '',
      date: '',
      opportunity_type: '',
      secondary_contact: '',
      status: '',
      notes: '',
      industry: '',
      industry_options: [
        { value: 'Airport Maintenance', label: 'Airport Maintenance' },
        { value: 'Energy', label: 'Energy' },
        { value: 'Facilities Maintenance', label: 'Facilities Maintenance' },
        { value: 'Flood Analysis', label: 'Flood Analysis' },
        { value: 'Land Development', label: 'Land Development' },
        { value: 'Mining', label: 'Mining' },
        { value: 'Municipal & Government', label: 'Municipal & Government' },
        { value: 'Oil & Gas', label: 'Oil & Gas' },
        { value: 'Renewable Energy', label: 'Renewable Energy' },
        { value: 'Transportation', label: 'Transportation' },
        { value: 'Waste Management', label: 'Waste Management' },
      ],
      comments: '',
      editing: false,
    }
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/opportunities/${this.props.match.params.id}`,
        dataType: "JSON"
      }).done((data) => {
        this.setState({
          user_id: data.opportunity.user_id,
          contact_id: data.opportunity.contact_id,
          name: data.opportunity.name,
          location: data.opportunity.location,
          zip: data.opportunity.zip,
          date: data.opportunity.date,
          opportunity_type: data.opportunity.opportunity_type,
          secondary_contact: data.opportunity.secondary_contact,
          status: data.opportunity.status,
          notes: data.opportunity.notes,
          industry: data.opportunity.industry,
          comments: data.opportunity.comments,
          latitude: data.opportunity.latitude,
          longitude: data.opportunity.longitude,
          editing: this.props.match.path === '/opportunities/:id/edit'
        });
      });
    }
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.state.editing ?
      this.updateOpportunity() :
      this.addOpportunity();
  }

  updateOpportunity() {
    const opportunity = {
      user_id: this.state.user_id,
      contact_id: this.state.contact_id,
      name: this.state.name,
      location: this.state.location,
      zip: this.state.zip,
      date: this.state.date,
      opportunity_type: this.state.opportunity_type,
      secondary_contact: this.state.secondary_contact,
      status: this.state.status,
      notes: this.state.notes,
      industry: this.state.industry,
      comments: this.state.comments,
      latitude: this.state.latitude,
      longitude: this.state.longitude
    };
    $.ajax({
      type: "PATCH",
      url: `/opportunities/${this.props.match.params.id}`,
      data: { opportunity: opportunity }
    })
      .done((data) => {
        console.log('Opportunity updated!');
      })
      .fail((response) => {
        console.log("did not work");
        alert("Please review the form for errors");
      });
  }

  addOpportunity() {
    const opportunity = {
      user_id: this.state.user_id,
      contact_id: this.state.contact_id,
      name: this.state.name,
      location: this.state.location,
      zip: this.state.zip,
      date: this.state.date,
      opportunity_type: this.state.opportunity_type,
      secondary_contact: this.state.secondary_contact,
      status: this.state.status,
      notes: this.state.notes,
      industry: this.state.industry,
      comments: this.state.comments,
      latitude: this.state.latitude,
      longitude: this.state.longitude
    };
    $.post('/opportunities', { opportunity: opportunity })
      .done((data) => {
        this.props.handleNewOpportunity(data);
        console.log("Opportunity Successfully Created!");
      })
      .fail((response) => {
        console.log("did not work");
        alert("Please review the form for errors");
      });
  }

  deleteOpportunity = () => {
    if (confirm("Are you sure you want to delete this opportunity?")) {
      $.ajax({
        type: "DELETE",
        url: `/opportunities/${this.props.match.params.id}`,
      })
        .done((data) => {
          this.props.history.push('/opportunities');
        })
        .fail((response) => {
          console.log("Opportunity deletion failed!");
        });
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSelectChange(key) {
    return function ({ value }) {
      this.setState({ [key]: value });
    }.bind(this);
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <h3 className="mb-4 text-4xl">
            {this.state.editing ? "Update Opportunity" : "Create Opportunity"}
          </h3>
          <small>
            (* represents a <strong>required field</strong>)
          </small>
          <form onSubmit={this.handleFormSubmit}>
            <div className="row">
              <div className="col-md">
                <label title="Name this opportunity">Opportunity Name *</label>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  required
                  value={this.state.name}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-6">
                <label title="Select the industry this opportunity is for">
                  Opportunity Industry *
                </label>
                <Select.Creatable
                  name="industry"
                  value={this.state.industry}
                  onChange={this.handleSelectChange("industry")}
                  options={this.state.industry_options}
                />
              </div>
              <div className="col-md-6">
                <label title="Select the date for this opportunity">
                  Opportunity Date *
                </label>
                <input
                  type="date"
                  name="date"
                  placeholder="Opp Date"
                  required
                  value={this.state.date}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md">
                <label title="Where is this opportunity located?">
                  Opportunity Location *
                </label>
                <input
                  type="text"
                  name="location"
                  placeholder="Location"
                  required
                  value={this.state.location}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-md-3">
                <label title="Where is this opportunity located?">Zip *</label>
                <input
                  type="text"
                  name="zip"
                  placeholder="Zip Code"
                  value={this.state.zip}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-6">
                <label title="Select the type of opportunity">
                  What type of opportunity is this? *
                </label>
                <Select
                  name="opportunity_type"
                  value={this.state.opportunity_type}
                  onChange={this.handleSelectChange("opportunity_type")}
                  options={[
                    { value: "ADOT", label: "ADOT" },
                    { value: "Construction", label: "Construction" },
                    { value: "Other", label: "Custom" },
                    { value: "Digital", label: "Digital" },
                    {
                      value: "Digital - Photo Geodetic",
                      label: "Digital - Photo Geodetic",
                    },
                    {
                      value: "Digital (Image Only)",
                      label: "Digital (Image Only)",
                    },
                    {
                      value: "Digital (Mapping Only)",
                      label: "Digital (Mapping Only)",
                    },
                    {
                      value: "Digital (Image Only) - Photo Geodetic",
                      label: "Digital (Image Only) - Photo Geodetic",
                    },
                    {
                      value: "Digital (Mapping Only) - Photo Geodetic",
                      label: "Digital (Mapping Only) - Photo Geodetic",
                    },
                    { value: "Film", label: "Film" },
                    {
                      value: "Film - Photo Geodetic",
                      label: "Film - Photo Geodetic",
                    },
                    { value: "Film (Image Only)", label: "Film (Image Only)" },
                    {
                      value: "Film (Mapping Only)",
                      label: "Film (Mapping Only)",
                    },
                    {
                      value: "Film (Image Only) - Photo Geodetic",
                      label: "Film (Image Only) - Photo Geodetic",
                    },
                    {
                      value: "Film (Mapping Only) - Photo Geodetic",
                      label: "Film (Mapping Only) - Photo Geodetic",
                    },
                    { value: "Flight", label: "Flight" },
                    { value: "LiDAR", label: "LiDAR" },
                    { value: "LiDAR Processing", label: "LiDAR Processing" },
                    { value: "NAAS", label: "NAAS" },
                    { value: "Plot", label: "Plot" },
                    { value: "Scan", label: "Scan" },
                    { value: "Survey", label: "Survey" },
                    { value: "UAV", label: "UAV" },
                  ]}
                />
              </div>
              <div className="col-md-6">
                <label title="Select the opportunity status">
                  Opportunity Status *
                </label>
                <Select
                  name="status"
                  value={this.state.status}
                  onChange={this.handleSelectChange("status")}
                  options={[
                    { value: "Lost", label: "Lost" },
                    { value: "Closed", label: "Closed" },
                    { value: "Open", label: "Open" },
                  ]}
                />
              </div>
            </div>
            <p />
            {this.state.status == "Lost" && (
              <div>
                <div className="row">
                  <div className="col">
                    <label>Reason Lost *</label>
                    <textarea
                      type="text"
                      name="reason_lost"
                      placeholder="Reason Lost"
                      value={this.state.reason_lost}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            )}
            <p />
            <div className="row">
              <div className="col">
                <label>Comments *</label>
                <textarea
                  type="text"
                  name="comments"
                  placeholder="Opportunity Comments"
                  value={this.state.comments}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-6">
                <label>Secondary Contact *</label>
                <input
                  type="text"
                  name="secondary_contact"
                  placeholder="Secondary Contact"
                  value={this.state.secondary_contact}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Notes (internal) *</label>
                <textarea
                  type="text"
                  name="notes"
                  placeholder="Notes"
                  value={this.state.notes}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <input
              type="submit"
              value={this.state.editing ? "Update" : "Create"}
              className="btn btn-secondary btn-sm"
            />
            <a
              className="btn btn-danger btn-sm"
              onClick={this.props.history.goBack}
            >
              Cancel
            </a>
          </form>
          <br />
        </div>
      </div>
    );
  }

}
