import React from "react";
import PropTypes from "prop-types";
import update from "immutability-helper";
import Header from "./Header";
import Legend from "../../Shared/components/Legend";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import moment from "moment";

export default class InProcessings extends React.Component {
  static propTypes = {
    in_processings: PropTypes.array,
  };

  static defaultProps = {
    in_processings: [],
  };

  constructor(props, railsContext) {
    super(props);
    this.state = {
      in_processings: this.props.in_processings,
      isHidden: true,
      isHiddenNotComplete: false,
      isHiddenComplete: true,
      isLoading: false,
    };
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }

  toggleHiddenNotComplete() {
    this.setState({
      isHiddenNotComplete: !this.state.isHiddenNotComplete,
    });
  }

  toggleHiddenComplete() {
    this.setState({
      isHiddenComplete: !this.state.isHiddenComplete,
    });
  }

  componentDidMount() {
    if (this.props.match) {
      this.setState({ isLoading: true });
      $.ajax({
        type: "GET",
        url: "/cascopt/in_processings",
        dataType: "JSON",
      }).done((data) => {
        this.setState({ in_processings: data, isLoading: false });
      });
    }
  }

  render() {
    const pickDateColor = (date) => {
      const dueDate = new moment(date);
      const yellowDate = new moment(date).subtract(5, "days");
      const now = moment();

      if (now < yellowDate) {
        return "green";
      }
      if (now < dueDate) {
        return "blue";
      }
      return "red";
    };

    const { in_processings, isLoading } = this.state;
    if (isLoading) {
      return <div className="loader"></div>;
    }

    return (
      <div>
        <div className="row">
          <div className="col-md-8">
            <Header />
          </div>
          <div className="col">
            <Legend />
          </div>
        </div>
        <hr />
        <small>* Projects listed are currently in In-Processing</small>
        {!this.state.isHiddenNotComplete && (
          <ReactTable
            key={in_processings.id}
            data={in_processings}
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id]) === filter.value
            }
            defaultPageSize={100}
            style={{
              height: "800px",
              textAlign: "center",
            }}
            columns={[
              {
                Header: "In-Processing Info",
                columns: [
                  {
                    Header: "Job Number",
                    accessor: "project.job_number",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id].includes(filter.value),
                    Cell: (props) => (
                      <a
                        href={`/cascopt/in_processings/${props.original.id}`}
                        className="view-record"
                      >
                        {props.original.project.job_number}
                      </a>
                    ),
                  },
                  {
                    Header: "Job Name",
                    accessor: "project.job_name",
                    width: 300,
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .includes(filter.value.toLowerCase()),
                    Cell: (props) => (
                      <a
                        href={`/cascopt/in_processings/${props.original.id}`}
                        className="view-record"
                      >
                        {props.original.project.job_name}
                      </a>
                    ),
                  },
                  {
                    Header: "Client",
                    accessor: "project.client_name",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .includes(filter.value.toLowerCase()),
                  },
                  {
                    Header: "Project Due Date",
                    accessor: "project.due_date",
                    filterable: false,
                    Cell: (props) => (
                      <span style={{ color: pickDateColor(props.value) }}>
                        {moment(props.value).format("MM/DD/YYYY")}
                      </span>
                    ),
                  },
                  {
                    Header: "Location",
                    accessor: "project.project_location",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .includes(filter.value.toLowerCase()),
                  },
                  {
                    Header: "Flight Crew",
                    accessor: "project.flight_crew",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .includes(filter.value.toLowerCase()),
                  },
                  {
                    Header: "Est. Flight Date",
                    filterable: false,
                    accessor: "project.est_flight_date",
                    Cell: (props) => (
                      <span>{moment(props.value).format("MM/DD/YYYY")}</span>
                    ),
                  },
                  {
                    Header: "Priority",
                    filterable: false,
                    accessor: "project.priority_status",
                  },
                  {
                    Header: "In In-Processing Since",
                    accessor: "project.stage_changetime",
                    filterable: false,
                    Cell: (props) => (
                      <span>{moment(props.value).format("MM/DD/YYYY")}</span>
                    ),
                  },
                  {
                    Header: "Modify",
                    filterable: false,
                    Cell: (props) => (
                      <a
                        href={`/cascopt/in_processings/${props.original.id}/edit`}
                        className="view-record"
                      >
                        Edit
                      </a>
                    ),
                  },
                ],
              },
            ]}
            SubComponent={(v) => (
              <div className="text-left" style={{ padding: "10px" }}>
                <b>Project Notes:</b>
                <br />
                {v.original.project.project_notes || "None."}
                <br />
                <b>Notes from Client:</b>
                <br />
                {v.original.project.client_notes || "None."}
                <br />
                <b>Lead Project Manager/Project Manager:</b>
                <br />
                {v.original.project.project_manager || "None."} /{" "}
                {v.original.project.asst_pm || "None."}
                <br />
                <b>Client PM:</b>
                <br />
                {v.original.project.client_contact || "None."}
                <br />
                <b>Client #:</b>
                <br />
                {v.original.project.client_number || "None."}
                <br />
                <b>Map Scale:</b>
                <br />
                {v.original.project.map_scale || "None."}
                <br />
                <b>CI:</b>
                <br />
                {v.original.project.ci || "None."}
                <br />
                <b># of Models:</b>
                <br />
                {v.original.project.models || "None."}
              </div>
            )}
            defaultSorted={[
              {
                id: "project.due_date",
                asc: true,
              },
            ]}
            className="-striped -highlight"
          />
        )}
      </div>
    );
  }
}
