/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/jsx-one-expression-per-line */
import React from "react";

export default class Project extends React.Component {
  render() {
    return (
      <div>
        <h3 id="header">Projects - Fly and Hold Dashboard</h3>
        <a href="/cascopt/projects" className="view-record">
          Projects in Production
        </a>
      </div>
    );
  }
}
