import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

export default class Bim extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bim: props.bim,
      project: props.project,
      isHidden: false,
    };
  }

  static propTypes = {
    bim: PropTypes.object,
  };

  static defaultProps = {
    bim: {},
  };

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/cascopt/bims/${this.props.match.params.id}`,
        dataType: "JSON",
      }).done((data) => {
        this.setState({ data });
      });
    }
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }

  render() {
    return (
      <div className="card">
        <div className="container">
          <a href={`/cascopt/bims`} className="stage-record">
            Back to BIM Dashboard
          </a>
          <hr />
          <Link to={`/cascopt/bims/${this.state.bim.id}/edit`}>
            <button className="btn btn-sm btn-success">Edit BIM</button>
          </Link>
          <p />
          <button
            onClick={this.toggleHidden.bind(this)}
            title="Click to expand/hide create company form"
            className="btn btn-secondary btn-sm"
          >
            {this.state.isHidden ? (
              <span>
                Show Project Details{" "}
                <i className="fa fa-plus-circle" aria-hidden="true"></i>
              </span>
            ) : (
              <span>
                Hide Project Details{" "}
                <i className="fa fa-minus-circle" aria-hidden="true"></i>
              </span>
            )}
          </button>
          <p />
          {!this.state.isHidden && (
            <p id="pt-view-project-block">
              <h5>Project Details</h5>
              <b>Client Rep:</b> {this.state.project.project_manager} |{" "}
              <b>Project Manager:</b> {this.state.project.asst_pm || "n/a"}
              <br />
              <b>Job:</b> {this.state.project.job_name || ""} |{" "}
              {this.state.project.client_number || ""}-
              {this.state.project.job_number || ""}
              <br />
              <b>Client:</b> {this.state.project.client_name || ""},{" "}
              {this.state.project.client_number || ""}
              <br />
              <b>Job Due Date:</b>{" "}
              {moment(this.state.project.due_date || "").format("MM/DD/YYYY")}
              <br />
            </p>
          )}
          <br />
          Last Updated: {this.state.project.last_updated}
          <hr />
          <form>
            <div className="row">
              <div className="col-md-3">
                <label>Actual Hours</label>
                <p>{this.state.bim.hours || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>BIM Complete</label>
                <p>{this.state.bim.complete || "n/a"}</p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>BIM To Sub</label>
                <p>{this.state.bim.bim_to_sub || "n/a"}</p>
              </div>
            </div>
            <p />
            {this.state.bim.bim_to_sub == "Yes" && (
              <div>
                <div className="row">
                  <div className="col-md-3">
                    <label>BIM Sub</label>
                    <p>{this.state.bim.bim_sub_name || "n/a"}</p>
                  </div>
                  <div className="col-md-3">
                    <label>BIM Sub Date Received</label>
                    <p>{this.state.bim.bim_sub_date_rec || "n/a"}</p>
                  </div>
                  <div className="col-md-3">
                    <label>BIM Sub Complete</label>
                    <p>{this.state.bim.bim_sub_complete || "n/a"}</p>
                  </div>
                  {this.state.bim.bim_sub_complete == "Yes" && (
                    <div className="col-md-3">
                      <label>BIM Sub Date Completed</label>
                      <p>{this.state.bim.bim_sub_date_done || "n/a"}</p>
                    </div>
                  )}
                </div>
                <p />
                <div className="row">
                  <div className="col-md-3">
                    <label>BIM Rejected</label>
                    <p>{this.state.bim.rejected || "No"}</p>
                  </div>
                  {this.state.bim.bim_rejected == "Yes" && (
                    <div className="col-md-3">
                      <label>BIM Rejected Date</label>
                      <p>{this.state.bim.rejected_date || "n/a"}</p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </form>
          <hr />
          {this.state.project.project_notes && (
            <div>
              <h5>Project Information</h5>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Notes from Project</label>
                  <p>{this.state.project.project_notes || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.project_purpose && (
            <div>
              <h5>Project Purpose Information</h5>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Project Purpose</label>
                  <p>{this.state.project.project_purpose || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          <label>Uploaded Files</label>
          <hr />
          <div className="row">
            {this.state.project.pm_notes && (
              <div className="col">
                <label>PM File</label>
                <p />
                <img src={this.state.project.pm_notes || ""} />
                <p />
                <a
                  href={this.state.project.pm_file_download}
                  className="btn btn-info btn-sm"
                >
                  Download
                </a>
                <br />
                <small>
                  <strong>Filename:</strong>
                  <br />
                  {this.state.project.pm_file || ""}
                </small>
              </div>
            )}
            {this.state.project.control && (
              <div className="col">
                <label>Control File</label>
                <p />
                <img src={this.state.project.control || ""} />
                <p />
                <a
                  href={this.state.project.control_download}
                  className="btn btn-info btn-sm"
                >
                  Download
                </a>
                <br />
                <small>
                  <strong>Filename:</strong>
                  <br />
                  {this.state.project.control_file || ""}
                </small>
              </div>
            )}
            {this.state.project.boundaries && (
              <div className="col">
                <label>Boundary File</label>
                <p />
                <img src={this.state.project.boundaries || ""} />
                <p />
                <a
                  href={this.state.project.boundary_download}
                  className="btn btn-info btn-sm"
                >
                  Download
                </a>
                <br />
                <small>
                  <strong>Filename:</strong>
                  <br />
                  {this.state.project.boundary_file || ""}
                </small>
              </div>
            )}
            {this.state.project.photo && (
              <div className="col">
                <label>Photo ID File</label>
                <p />
                <img src={this.state.project.photo || ""} />
                <p />
                <a
                  href={this.state.project.photo_download}
                  className="btn btn-info btn-sm"
                >
                  Download
                </a>
                <br />
                <small>
                  <strong>Filename:</strong>
                  <br />
                  {this.state.project.photo_file || ""}
                </small>
              </div>
            )}
            {this.state.project.attachment && (
              <div className="col">
                <label>Misc. File</label>
                <p />
                <img src={this.state.project.attachment || ""} />
                <p />
                <a
                  href={this.state.project.attachment_download}
                  className="btn btn-info btn-sm"
                >
                  Download
                </a>
                <br />
                <small>
                  <strong>Filename:</strong>
                  <br />
                  {this.state.project.attachment_file || ""}
                </small>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
