import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Quotes from './Quotes';
import QuoteForm from './QuoteForm';
import QuoteView from './QuoteView';
import QuotingForm from './QuotingForm';

export default (props) => {
  return (
    <Router>
      <div>
        <Route exact path="/quotes" render={routeProps => <Quotes {...props} {...routeProps}/>} />
        <Route exact path="/quotes/:id" render={routeProps => <QuoteView {...props} {...routeProps}/>} />
        <Route path="/quotes/:id/edit" render={routeProps => <QuotingForm {...props} {...routeProps}/>} />
      </div>
    </Router>
  )
}
