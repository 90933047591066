import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';

export default class Scan extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scan: props.scan,
      project: props.project,
      flight: props.flight,
      isHidden: false
    }
  }

  static propTypes = {
    scan: PropTypes.object
  }

  static defaultProps = {
    scan: {}
  }

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/cascopt/scans/${this.props.match.params.id}`,
        dataType: "JSON"
      }).done((data) => {
        this.setState({ data });
      })
    }
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden
    })
  }

  render() {
    const problemColor = (color) => {
      const problemYes = this.state.scan.problem_flight == "Yes";
      const problemNo = this.state.scan.problem_flight == "No";

      if (problemYes) {
        return "red";
      }
      if (problemNo) {
        return "green";
      }
    };
    return (
      <div className="card">
        <div className="container">
          <a href={`/cascopt/scans`} className="stage-record">
            Back to Scan Dashboard
          </a>
          <hr />
          <Link to={`/cascopt/scans/${this.state.scan.id}/edit`}>
            <button className="btn btn-sm btn-success">Edit Scan</button>
          </Link>
          <p />
          <button
            onClick={this.toggleHidden.bind(this)}
            title="Click to expand/hide create company form"
            className="btn btn-secondary btn-sm"
          >
            {this.state.isHidden ? (
              <span>
                Show Project Details{" "}
                <i className="fa fa-plus-circle" aria-hidden="true"></i>
              </span>
            ) : (
              <span>
                Hide Project Details{" "}
                <i className="fa fa-minus-circle" aria-hidden="true"></i>
              </span>
            )}
          </button>
          <p />
          {!this.state.isHidden && (
            <p id="pt-view-project-block">
              <h5>Project Details</h5>
              <b>Client Rep:</b> {this.state.project.project_manager} |{" "}
              <b>Project Manager:</b> {this.state.project.asst_pm || "n/a"}
              <hr />
              <div className="form-group row">
                <div className="col-md-6">
                  <b>Job:</b> {this.state.project.job_name || ""} |{" "}
                  {this.state.project.client_number || ""}-
                  {this.state.project.job_number || ""}
                  <br />
                  <b>Edge Job #:</b> {this.state.project.edge_job_number || "n/a"}
                  <br />
                  <b>Due:</b>{" "}
                  {moment(this.state.project.due_date || "").format(
                    "MM/DD/YYYY"
                  )}
                  <br />
                  <b>Client:</b> {this.state.project.client_name || ""}
                  <br />
                  <b>Deliverables:</b>{" "}
                  {this.state.project.deliverables ||
                    "Contact PM for deliverables info"}
                  <br />
                  <b>Project Location:</b>{" "}
                  {this.state.project.project_location || ""}
                  <br />
                  <b>Flight Crew:</b> {this.state.project.flight_crew || ""}
                  <br />
                  <b>Color Film:</b> {this.state.project.color_film || ""}
                  <br />
                  <b>Flight Lines:</b> {this.state.project.flight_lines || ""}
                </div>
                <div className="col-md-6">
                  <b>Exposures:</b> {this.state.project.exposures || ""}
                  <br />
                  <b>Stereo PRS:</b> {this.state.project.stereo_prs || ""}
                  <br />
                  <b>Units:</b> {this.state.project.units || "n/a"}
                  <br />
                  <b>Project Type:</b>{" "}
                  {this.state.project.project_type || "n/a"}
                </div>
              </div>
            </p>
          )}
          <br />
          Last Updated: {this.state.project.last_updated}
          <hr />
          <div className="row">
            <div className="col-md-6">
              <label>Scan to Use</label>
              <p>{this.state.scan.which_scan}</p>
            </div>
          </div>
          <hr />
          <form>
            <div className="row">
              <div className="col">
                <label>Scan Staff</label>
                <p>{this.state.scan.scan_staff || "n/a"}</p>
              </div>
              <div className="col">
                <label>Camera Number</label>
                <p>{this.state.scan.camera_number || "n/a"}</p>
              </div>
              <div className="col">
                <label>Roll Number</label>
                <p>{this.state.scan.roll_number || "n/a"}</p>
              </div>
              <div className="col">
                <label>Scan Resolution</label>
                <p>{this.state.scan.scan_res || "n/a"}</p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Server Location</label>
                <p>{this.state.scan.server_location || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>File Format</label>
                <p>{this.state.scan.file_format || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Photo Quality</label>
                <p>{this.state.scan.photo_quality || "n/a"}</p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Dig Equalized</label>
                <p>{this.state.scan.dig_equalized || "n/a"}</p>
              </div>
              <div className="col">
                <label>Scan QC</label>
                <p>{this.state.scan.scan_qc || "n/a"}</p>
              </div>
              <div className="col">
                <label>Review AT</label>
                <p>{this.state.scan.review_at || "n/a"}</p>
              </div>
              <div className="col">
                <label>Film Annotated</label>
                <p>{this.state.scan.film_annotated || "n/a"}</p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>ABGPS Received</label>
                <p>{moment(this.state.scan.abgps_received || "n/a").format(
                    "MM/DD/YYYY"
                  )}</p>
              </div>
              <div className="col-md-3">
                <label>Scan Complete</label>
                <p>{this.state.scan.scan_complete || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Scan Date Done</label>
                <p>
                  {moment(this.state.scan.scan_date_done || "n/a").format(
                    "MM/DD/YYYY"
                  )}
                </p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Problem w/ Flight</label>
                <p style={{color: problemColor(this.state.scan.problem_flight.value)}}>{this.state.scan.problem_flight || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Scans Deleted</label>
                <p>{this.state.project.scans_deleted || "No"}</p>
              </div>
            </div>
            <p />
            {this.state.flight.second_flight == "Yes" &&
              this.state.flight.imagery_lidar == "Imagery" && (
                <div>
                  <hr />
                  <h5>
                    Secondary Scanning Information (if project has 2 imagery
                    flights)
                  </h5>
                  <hr />
                  <div className="row">
                    <div className="col">
                      <label>Scan Staff</label>
                      <p>{this.state.scan.second_scan_staff || ""}</p>
                    </div>
                    <div className="col">
                      <label>Camera Number</label>
                      <p>{this.state.scan.second_camera_number || ""}</p>
                    </div>
                    <div className="col">
                      <label>Roll Number</label>
                      <p>{this.state.scan.second_roll_number || ""}</p>
                    </div>
                    <div className="col">
                      <label>Scan Resolution</label>
                      <p>{this.state.scan.second_scan_res || ""}</p>
                    </div>
                  </div>
                  <p />
                  <div className="row">
                    <div className="col-md-3">
                      <label>Server Location</label>
                      <p>{this.state.scan.second_server_location || ""}</p>
                    </div>
                    <div className="col-md-3">
                      <label>File Format</label>
                      <p>{this.state.scan.second_file_format || ""}</p>
                    </div>
                    <div className="col-md-3">
                      <label>Photo Quality</label>
                      <p>{this.state.scan.second_photo_quality || ""}</p>
                    </div>
                  </div>
                  <p />
                  <div className="row">
                    <div className="col">
                      <label>Dig Equalized</label>
                      <p>{this.state.scan.second_dig_equalized || ""}</p>
                    </div>
                    <div className="col">
                      <label>Scan QC</label>
                      <p>{this.state.scan.second_scan_qc || ""}</p>
                    </div>
                    <div className="col">
                      <label>Film Annotated</label>
                      <p>{this.state.scan.second_film_annotated || ""}</p>
                    </div>
                  </div>
                </div>
              )}
            <p />
            <div className="row">
              <div className="col">
                <label>Scan Notes</label>
                <p>{this.state.project.scans_notes || ""}</p>
              </div>
            </div>
            <p />
            {this.state.project.lidar_scope && (
              <div className="row">
                <div className="col">
                  <label>LiDAR Scope</label>
                  <p>{this.state.project.lidar_scope || ""}</p>
                </div>
              </div>
            )}
          </form>
          <hr />
          {this.state.project.project_notes && (
            <div>
              <h5>Project Information</h5>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Notes from Project</label>
                  <p>{this.state.project.project_notes || ""}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.survey_notes && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Notes from Survey</label>
                  <p>{this.state.project.survey_notes || ""}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.flights_notes && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Notes from Flight</label>
                  <p>{this.state.project.flights_notes || ""}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.drone_notes && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Notes from UAV</label>
                  <p>{this.state.project.drone_notes || ""}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.project_purpose && (
            <div>
              <h5>Project Purpose Information</h5>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Project Purpose</label>
                  <p>{this.state.project.project_purpose || ""}</p>
                </div>
              </div>
              <p />
            </div>
          )}
        </div>
      </div>
    );
  }
}
