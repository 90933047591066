/* eslint-disable react/prefer-stateless-function */
import React from "react";

export default class FieldSurvey extends React.Component {
  render() {
    return (
      <div>
        <h3 id="header">Field Surveys - Being Done by Cooper Aerial</h3>
      </div>
    );
  }
}
