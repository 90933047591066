import React from "react";
import PropTypes from "prop-types";
import update from "immutability-helper";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import moment from "moment";

export default class ScanSearch extends React.Component {
  static propTypes = {
    scans: PropTypes.array,
  };

  static defaultProps = {
    scans: [],
  };

  constructor(props, railsContext) {
    super(props);
    this.state = {
      scans: this.props.scans,
      isHidden: true,
      isHiddenNotComplete: false,
      isHiddenComplete: true,
      isLoading: false,
    };
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }

  toggleHiddenNotComplete() {
    this.setState({
      isHiddenNotComplete: !this.state.isHiddenNotComplete,
    });
  }

  toggleHiddenComplete() {
    this.setState({
      isHiddenComplete: !this.state.isHiddenComplete,
    });
  }

  componentDidMount() {
    if (this.props.match) {
      this.setState({ isLoading: true });
      $.ajax({
        type: "GET",
        url: "/cascopt/scans",
        dataType: "JSON",
      }).done((data) => {
        this.setState({ scans: data, isLoading: false });
      });
    }
  }

  addNewScan(scan) {
    const scans = update(this.state.scans, { $push: [scan] });
    this.setState({
      scans: scans.sort(function (a, b) {
        return new Date(a.created_at) - new Date(b.created_at);
      }),
    });
  }

  render() {
    const pickDateColor = (date) => {
      const dueDate = new moment(date);
      const yellowDate = new moment(date).subtract(5, "days");
      const now = moment();

      if (now < yellowDate) {
        return "black";
      }
      if (now < dueDate) {
        return "blue";
      }
      return "red";
    };

    const { scans, isLoading } = this.state;
    if (isLoading) {
      return <div className="loader"></div>;
    }

    return (
      <div>
        <div className="row">
          <div className="col-md-8">
            <h5>Scan Search Dashboard</h5>
            <a href="/cascopt/scans" className="view-record">
              Scans Dashboard
            </a>
          </div>
        </div>
        <hr />
        {!this.state.isHiddenNotComplete && (
          <ReactTable
            key={scans.id}
            data={scans}
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id]) === filter.value
            }
            defaultPageSize={100}
            style={{
              height: "800px",
              textAlign: "center",
            }}
            columns={[
              {
                Header: "Scanning Search",
                columns: [
                  {
                    Header: "Scan Staff",
                    accessor: "scan_staff",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .startsWith(filter.value.toLowerCase()),
                  },
                  {
                    Header: "Job Number",
                    accessor: "project.job_number",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id].startsWith(filter.value),
                    Cell: (props) => (
                      <a
                        href={`/cascopt/scans/${props.original.id}`}
                        className="view-record"
                      >
                        {props.original.project.job_number}
                      </a>
                    ),
                  },
                  {
                    Header: "Roll Number",
                    accessor: "roll_number",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id].startsWith(filter.value),
                    Cell: (props) => (
                      <a
                        href={`/cascopt/scans/${props.original.id}`}
                        className="view-record"
                      >
                        {props.original.roll_number}
                      </a>
                    ),
                  },
                  {
                    Header: "Job Name",
                    accessor: "project.job_name",
                    width: 300,
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .startsWith(filter.value.toLowerCase()),
                    Cell: (props) => (
                      <a
                        href={`/cascopt/scans/${props.original.id}`}
                        className="view-record"
                      >
                        {props.original.project.job_name}
                      </a>
                    ),
                  },
                  {
                    Header: "Scan Complete Date",
                    filterable: false,
                    accessor: "scan_date_done",
                    Cell: (props) => (
                      <span>{moment(props.value).format("MM/DD/YYYY")}</span>
                    ),
                  },
                  {
                    Header: "Client",
                    accessor: "project.client_name",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .startsWith(filter.value.toLowerCase()),
                  },
                ],
              },
            ]}
            defaultSorted={[
              {
                id: "project.due_date",
                asc: true,
              },
            ]}
            className="-striped -highlight"
          />
        )}
      </div>
    );
  }
}
