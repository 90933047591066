import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";

export default class InProcessing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      in_processing: props.in_processing,
      project: props.project,
      isHidden: false,
    };
  }

  static propTypes = {
    in_processing: PropTypes.object,
  };

  static defaultProps = {
    in_processing: {},
  };

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/cascopt/in_processings/${this.props.match.params.id}`,
        dataType: "JSON",
      }).done((data) => {
        this.setState({ data });
      });
    }
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }

  render() {
    return (
      <div className="card">
        <div className="container">
          <a href={`/cascopt/in_processings`} className="stage-record">
            Back to In-Processing Dashboard
          </a>
          <hr />
          <Link
            to={`/cascopt/in_processings/${this.state.in_processing.id}/edit`}
          >
            <button className="btn btn-sm btn-success">
              Edit In-Processing
            </button>
          </Link>
          <p />
          <button
            onClick={this.toggleHidden.bind(this)}
            title="Click to expand/hide create company form"
            className="btn btn-secondary btn-sm"
          >
            {this.state.isHidden ? (
              <span>
                Show Project Details{" "}
                <i className="fa fa-plus-circle" aria-hidden="true"></i>
              </span>
            ) : (
              <span>
                Hide Project Details{" "}
                <i className="fa fa-minus-circle" aria-hidden="true"></i>
              </span>
            )}
          </button>
          <p />
          {!this.state.isHidden && (
            <p id="pt-view-project-block">
              <h5>Project Details</h5>
              <b>Client Rep:</b> {this.state.project.project_manager} |{" "}
              <b>Project Manager:</b> {this.state.project.asst_pm || "n/a"}
              <hr />
              <div className="form-group row">
                <div className="col-md-6">
                  <b>Job:</b> {this.state.project.job_name || ""} |{" "}
                  {this.state.project.client_number || ""}-
                  {this.state.project.job_number || ""}
                  <br />
                  <b>Edge Job #:</b>{" "}
                  {this.state.project.edge_job_number || "n/a"}
                  <br />
                  <b>Due:</b>{" "}
                  {moment(this.state.project.due_date || "").format(
                    "MM/DD/YYYY"
                  )}
                  <br />
                  <b>Client:</b> {this.state.project.client_name || ""}
                  <br />
                  <b>Deliverables:</b>{" "}
                  {this.state.project.deliverables ||
                    "Contact PM for deliverables info"}
                  <br />
                  <b>Project Location:</b>{" "}
                  {this.state.project.project_location || ""}
                  <br />
                  <b>Flight Crew:</b> {this.state.project.flight_crew || ""}
                  <br />
                  <b>Est. Flight Date:</b>{" "}
                  {moment(this.state.project.est_flight_date || "").format(
                    "MM/DD/YYYY"
                  )}
                </div>
                <div className="col-md-6">
                  <b>Color Film:</b> {this.state.project.color_film || ""}
                  <br />
                  <b>Flight Lines:</b> {this.state.project.flight_lines || ""}
                  <br />
                  <b>Exposures:</b> {this.state.project.exposures || ""}
                  <br />
                  <b>Stereo PRS:</b> {this.state.project.stereo_prs || ""}
                  <br />
                  <b>Units:</b> {this.state.project.units || "n/a"}
                  <br />
                  <b>Project Type:</b>{" "}
                  {this.state.project.project_type || "n/a"}
                </div>
              </div>
            </p>
          )}
          <br />
          Last Updated: {this.state.project.last_updated}
          <hr />
          <form>
            <div className="row">
              <div className="col-md-4">
                <label>GPS/IMU Processed</label>
                <p>{this.state.in_processing.gps_imu_processed || "n/a"}</p>
              </div>
              <div className="col-md-4">
                <label>LiDAR Status</label>
                <p>{this.state.in_processing.lidar_status || "n/a"}</p>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-4">
                <label>In-Processing Complete</label>
                <p>{this.state.in_processing.complete || "n/a"}</p>
              </div>
            </div>
            {this.state.project.survey_notes && (
              <div>
                <div className="row">
                  <div className="col">
                    <label>Notes from Survey</label>
                    <p>{this.state.project.survey_notes || "n/a"}</p>
                  </div>
                </div>
                <p />
              </div>
            )}
            {this.state.project.project_purpose && (
              <div>
                <h5>Project Purpose Information</h5>
                <hr />
                <div className="row">
                  <div className="col">
                    <label>Project Purpose</label>
                    <p>{this.state.project.project_purpose || "n/a"}</p>
                  </div>
                </div>
                <p />
              </div>
            )}
          </form>
          <br />
        </div>
      </div>
    );
  }
}
