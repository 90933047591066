/* eslint-disable react/prefer-stateless-function */
import React from "react";

export default class InProcessing extends React.Component {
  render() {
    return (
      <div>
        <h3 id="header">Projects in In-Processing</h3>
      </div>
    );
  }
}
