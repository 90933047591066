import React from "react";
import PropTypes from "prop-types";
import update from "immutability-helper";
import Header from "./Header";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import moment from "moment";

export default class InvoiceTmDashboard extends React.Component {
  static propTypes = {
    invoices: PropTypes.array,
  };

  static defaultProps = {
    invoices: [],
  };

  constructor(props, railsContext) {
    super(props);
    this.state = {
      invoices: this.props.invoices,
      isHidden: true,
      isHiddenNotComplete: false,
      isHiddenComplete: true,
      isLoading: false,
    };
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }

  toggleHiddenNotComplete() {
    this.setState({
      isHiddenNotComplete: !this.state.isHiddenNotComplete,
    });
  }

  toggleHiddenComplete() {
    this.setState({
      isHiddenComplete: !this.state.isHiddenComplete,
    });
  }

  componentDidMount() {
    if (this.props.match) {
      this.setState({ isLoading: true });
      $.ajax({
        type: "GET",
        url: "/cascopt/invoices",
        dataType: "JSON",
      }).done((data) => {
        this.setState({ invoices: data, isLoading: false });
      });
    }
  }

  addNewInvoice(invoice) {
    const invoices = update(this.state.invoices, { $push: [invoice] });
    this.setState({
      invoices: invoices.sort(function (a, b) {
        return new Date(a.created_at) - new Date(b.created_at);
      }),
    });
  }

  render() {
    const pickDateColor = (date) => {
      const dueDate = new moment(date);
      const yellowDate = new moment(date).subtract(5, "days");
      const now = moment();

      if (now < yellowDate) {
        return "green";
      }
      if (now < dueDate) {
        return "green";
      }
      return "black";
    };

    const { invoices, isLoading } = this.state;
    if (isLoading) {
      return <div className="loader"></div>;
    }

    return (
      <div>
        <div className="row">
          <div className="col-md-8">
            <Header />
          </div>
        </div>
        <hr />
        {!this.state.isHiddenNotComplete && (
          <ReactTable
            key={invoices.id}
            data={invoices}
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id]) === filter.value
            }
            defaultPageSize={100}
            style={{
              height: "800px",
              textAlign: "center",
            }}
            columns={[
              {
                Header: "Invoice - Time and Materials Info",
                columns: [
                  {
                    Header: "Job Number",
                    accessor: "project.job_number",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id].includes(filter.value),
                    Cell: (props) => (
                      <a
                        href={`/cascopt/time_and_materials/${props.original.id}`}
                        className="view-record"
                      >
                        {props.original.project.job_number}
                      </a>
                    ),
                  },
                  {
                    Header: "Job Name",
                    accessor: "project.job_name",
                    width: 300,
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .includes(filter.value.toLowerCase()),
                    Cell: (props) => (
                      <a
                        href={`/cascopt/time_and_materials/${props.original.id}`}
                        className="view-record"
                      >
                        {props.original.project.job_name}
                      </a>
                    ),
                  },
                  {
                    Header: "Client",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .includes(filter.value.toLowerCase()),
                    accessor: "project.client_name",
                  },
                  {
                    Header: "Client Rep",
                    accessor: "project.project_manager",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .includes(filter.value.toLowerCase()),
                  },
                  {
                    Header: "Project Manager",
                    accessor: "project.asst_pm",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .includes(filter.value.toLowerCase()),
                  },
                  {
                    Header: "Billed",
                    accessor: "billed",
                    Cell: ({ value }) => (value ? "Yes" : "No"),
                    Filter: ({ filter, onChange }) => (
                      <select
                        onChange={(event) => onChange(event.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : ""}
                      >
                        <option value="">Show All</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    ),
                  },
                  {
                    Header: "Paid",
                    accessor: "paid",
                    Cell: ({ value }) => (value ? "Yes" : "No"),
                    Filter: ({ filter, onChange }) => (
                      <select
                        onChange={(event) => onChange(event.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : ""}
                      >
                        <option value="">Show All</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    ),
                  },
                  {
                    Header: "Hours",
                    filterable: false,
                    accessor: "hours",
                  },
                  {
                    Header: "Rate",
                    filterable: false,
                    accessor: "rate",
                    Cell: (props) => (
                      <span>
                        {props.value.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </span>
                    ),
                  },
                  {
                    Header: "Total",
                    filterable: false,
                    accessor: "total_amount",
                    Cell: (props) => (
                      <span>
                        {props.value.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </span>
                    ),
                  },
                  {
                    Header: "Invoice #",
                    accessor: "check_num",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id] && row[filter.id].includes(filter.value),
                  },
                  {
                    Header: "Modify",
                    filterable: false,
                    Cell: (props) => (
                      <a
                        href={`/cascopt/invoices/${props.original.id}/edit`}
                        className="view-record"
                      >
                        Edit
                      </a>
                    ),
                  },
                ],
              },
            ]}
            defaultSorted={[
              {
                id: "project.client_name",
                asc: true,
              },
            ]}
            className="-striped -highlight"
          />
        )}
      </div>
    );
  }
}
