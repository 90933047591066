import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import moment from 'moment';

export default class Timesheets extends React.Component {
  static propTypes = {
    timesheets: PropTypes.array
  }

  static defaultProps = {
    timesheets: []
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      timesheets: this.props.timesheets,
      isHidden: true,
      isHiddenNotComplete: false,
      isLoading: false
    }
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden
    })
  }

  toggleHiddenNotComplete() {
    this.setState({
      isHiddenNotComplete: !this.state.isHiddenNotComplete
    })
  }

  componentDidMount() {
    if (this.props.match) {
      this.setState({ isLoading: true });
      $.ajax({
        type: "GET",
        url: "/cascoclock/timesheets",
        dataType: "JSON"
      }).done((data) => {
        this.setState({ timesheets: data, isLoading: false });
      })
    }
  }

  render() {
    const pickDateColor = (date) => {
      const dueDate = new moment(date);
      const yellowDate = new moment(date).subtract(5, 'days');
      const now = moment();

      if (now < yellowDate) { return 'black'; };
      if (now < dueDate) { return 'black'; };
      return 'black';
    }

    const { timesheets, isLoading } = this.state;
    if (isLoading) {
      return <div className="loader"></div>;
    }

    return (
      <div>
        <ReactTable key={timesheets.id}
          data={timesheets}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value}
          defaultPageSize={100}
          style={{
            height: "800px",
            textAlign: "center"
          }}
          columns={[
            {
              Header: "Timesheet Info",
              foldable: true,
              columns: [
                {
                  Header: "Date",
                  filterable: false,
                  accessor: "date",
                  Cell: props => <span>{moment(props.value).format("MM/DD/YYYY")}</span>
                },
                {
                  Header: "Project Info",
                  accessor: "project_info",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={event => onChange(event.target.value)}
                      value={filter ? filter.value : ''}
                      style={{
                        width: '100%',
                        color: 'black',
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase()),
                  Cell: props => <a href={`/cascoclock/timesheets/${props.original.id}`} className="view-record" >{props.original.project_info}</a>
                },
                {
                  Header: "Task",
                  filterable: false,
                  accessor: "task"
                },
                {
                  Header: "Client Info",
                  filterable: false,
                  accessor: "client_number"
                },
                {
                  Header: "Hours",
                  filterable: false,
                  accessor: "hours"
                },
                {
                  Header: "Minutes",
                  filterable: false,
                  accessor: "minutes"
                },
                {
                  Header: "Edit",
                  filterable: false,
                  Cell: props => <a href={`/cascoclock/timesheets/${props.original.id}/edit`} className="view-record" >Edit</a>
                }
              ]
            }
          ]}
          defaultSorted={[
            {
              id: "date",
              desc: true
            }
          ]}
          className="-striped -highlight"
        />
      </div>
    )
  }
}
