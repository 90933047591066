import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import QualityControls from './QualityControls';
import QCForm from './QCForm';
import QCView from './QCView';

export default (props) => {
  return (
    <Router>
      <div>
        <Route exact path="/cascopt/quality_controls" render={routeProps => <QualityControls {...props} {...routeProps}/>} />
        <Route exact path="/cascopt/quality_controls/:id" render={routeProps => <QCView {...props} {...routeProps}/>} />
        <Route path="/cascopt/quality_controls/:id/edit" component={QCForm} />
      </div>
    </Router>
  )
}
