import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";

export default class Cancelled extends React.Component {
  static propTypes = {
    projects: PropTypes.array
  }

  static defaultProps = {
    projects: []
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      projects: this.props.projects,
      isHidden: true,
      isHiddenNotComplete: false,
      isLoading: false
    }
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden
    })
  }

  toggleHiddenNotComplete() {
    this.setState({
      isHiddenNotComplete: !this.state.isHiddenNotComplete
    })
  }

  componentDidMount() {
    if (this.props.match) {
      this.setState({ isLoading: true });
      $.ajax({
        type: "GET",
        url: "/cascopt/projects",
        dataType: "JSON"
      }).done((data) => {
        this.setState({ projects: data, isLoading: false });
      })
    }
  }

  render() {
    const { projects, isLoading } = this.state;
    if (isLoading) {
      return <div className="loader"></div>;
    }

    return (
      <div>
        <Header />
        <p />
        {!this.state.isHiddenNotComplete && (
          <ReactTable
            key={projects.id}
            data={projects}
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id]) === filter.value
            }
            defaultPageSize={100}
            style={{
              height: "800px",
              textAlign: "center",
            }}
            columns={[
              {
                Header: "Project Info",
                columns: [
                  {
                    Header: "Client Rep",
                    accessor: "project_manager",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .includes(filter.value.toLowerCase()),
                  },
                  {
                    Header: "Project Manager",
                    accessor: "asst_pm",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .includes(filter.value.toLowerCase()),
                  },
                  {
                    Header: "Job Number",
                    accessor: "job_number",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id].includes(filter.value),
                    Cell: (props) => (
                      <a
                        href={`/cascopt/projects/${props.original.id}`}
                        className="view-record"
                      >
                        {props.original.job_number}
                      </a>
                    ),
                  },
                  {
                    Header: "Project Name",
                    accessor: "job_name",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .includes(filter.value.toLowerCase()),
                    Cell: (props) => (
                      <a
                        href={`/cascopt/projects/${props.original.id}`}
                        className="view-record"
                      >
                        {props.value}
                      </a>
                    ),
                  },
                  {
                    Header: "Company",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .includes(filter.value.toLowerCase()),
                    accessor: "client_name",
                  },
                ],
              },
            ]}
            defaultSorted={[
              {
                id: "job_number",
                asc: true,
              },
            ]}
            className="-striped -highlight"
          />
        )}
      </div>
    );
  }
}
