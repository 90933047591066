import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import Header from './Header';
import Legend from '../../Shared/components/Legend';
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import moment from 'moment';


export default class Drones extends React.Component {
  static propTypes = {
    drones: PropTypes.array
  }

  static defaultProps = {
    drones: []
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      drones: this.props.drones,
      isHidden: true,
      isHiddenNotComplete: false,
      isHiddenComplete: true,
      isLoading: false
    }
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden
    })
  }

  toggleHiddenNotComplete() {
    this.setState({
      isHiddenNotComplete: !this.state.isHiddenNotComplete
    })
  }

  toggleHiddenComplete() {
    this.setState({
      isHiddenComplete: !this.state.isHiddenComplete
    })
  }

  componentDidMount() {
    if (this.props.match) {
      this.setState({ isLoading: true });
      $.ajax({
        type: "GET",
        url: "/cascopt/drones",
        dataType: "JSON"
      }).done((data) => {
        this.setState({ drones: data, isLoading: false });
      })
    }
  }

  addNewFlight(drone) {
    const drones = update(this.state.drones, { $push: [drone] });
    this.setState({
      drones: drones.sort(function (a, b) {
        return new Date(a.created_at) - new Date(b.created_at);
      })
    });
  }

  render() {
    const pickDateColor = (date) => {
      const dueDate = new moment(date);
      const yellowDate = new moment(date).subtract(5, 'days');
      const now = moment();

      if (now < yellowDate) { return 'green'; };
      if (now < dueDate) { return 'blue'; };
      return 'red';
    }

    const { drones, isLoading } = this.state;
    if (isLoading) {
      return <div className="loader"></div>;
    }

    return (
      <div>
        <div className="row">
          <div className="col-md-8">
            <Header />
          </div>
          <div className="col">
            <Legend />
          </div>
        </div>
        <hr />
        <p>Not Flown</p>
        {!this.state.isHiddenNotComplete &&
          <ReactTable key={drones.id}
            data={drones}
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id]) === filter.value}
            defaultPageSize={100}
            style={{
              height: "800px",
              textAlign: "center"
            }}
            columns={[
              {
                Header: "Drone Info",
                columns: [
                  {
                    Header: "Job Number",
                    accessor: "project.job_number",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={event => onChange(event.target.value)}
                        value={filter ? filter.value : ''}
                        style={{
                          width: '100%',
                          color: 'black',
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id].includes(filter.value),
                    Cell: props => <a href={`/cascopt/drones/${props.original.id}`} className="view-record" >{props.original.project.job_number}</a>
                  },
                  {
                    Header: "Job Name",
                    accessor: "project.job_name",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={event => onChange(event.target.value)}
                        value={filter ? filter.value : ''}
                        style={{
                          width: '100%',
                          color: 'black',
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
                    Cell: props => <a href={`/cascopt/drones/${props.original.id}`} className="view-record" >{props.original.project.job_name}</a>
                  },
                  {
                    Header: "Client",
                    accessor: "project.client_name",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={event => onChange(event.target.value)}
                        value={filter ? filter.value : ''}
                        style={{
                          width: '100%',
                          color: 'black',
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
                  },
                  {
                    Header: "Project Due Date",
                    accessor: "project.due_date",
                    filterable: false,
                    Cell: props => <span style={{ "color": pickDateColor(props.value) }}>{props.value}</span>
                  },
                  {
                    Header: "Flight Date",
                    filterable: false,
                    accessor: "flight_date"
                  },
                  {
                    Header: "Altitude",
                    filterable: false,
                    accessor: "altitude"
                  },
                  {
                    Header: "GSD",
                    filterable: false,
                    accessor: "gsd"
                  },
                  {
                    Header: "Video",
                    filterable: false,
                    accessor: "Video"
                  },
                  {
                    Header: "Obliques",
                    filterable: false,
                    accessor: "obliques"
                  },
                  {
                    Header: "No Control",
                    filterable: false,
                    accessor: "no_control"
                  },
                  {
                    Header: "Deliverables",
                    filterable: false,
                    accessor: "deliverables"
                  },
                  {
                    Header: "Processing Software",
                    filterable: false,
                    accessor: "processing_software"
                  },
                  {
                    Header: "Priority",
                    filterable: false,
                    accessor: "project.priority_status"
                  },
                  {
                    Header: "Modify",
                    filterable: false,
                    Cell: props => <a href={`/cascopt/drones/${props.original.id}/edit`} className="view-record" >Edit</a>
                  }
                ]
              }
            ]}
            defaultSorted={[
              {
                id: "project.due_date",
                asc: true
              }
            ]}
            className="-striped -highlight"
          />}
      </div>
    )
  }
}
