import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

export default class CompSub extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      comp_sub: props.comp_sub,
      project: props.project
    }
  }

  static propTypes = {
    comp_sub: PropTypes.object
  }

  static defaultProps = {
    comp_sub: {}
  }

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/cascopt/comp_subs/${this.props.match.params.id}`,
        dataType: "JSON"
      }).done((data) => {
        this.setState({ data });
      })
    }
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <div className="row text-center">
            <div className="col">
              <a href={`/cascopt/surveys/${this.state.comp_sub.project_id}`} className="view-record" >
                Survey
              </a>
            </div>
            <div className="col">
              <a href={`/cascopt/flights/${this.state.comp_sub.project_id}`} className="view-record" >
                Flight
              </a>
            </div>
            <div className="col">
              <a href={`/cascopt/drones/${this.state.comp_sub.project_id}`} className="view-record" >
                Drone
              </a>
            </div>
            <div className="col">
              <a href={`/cascopt/scans/${this.state.comp_sub.project_id}`} className="view-record" >
                Scan
              </a>
            </div>
            <div className="col">
              <a href={`/cascopt/aerotriangulations/${this.state.comp_sub.project_id}`} className="view-record" >
                AT
              </a>
            </div>
            <div className="col">
              <a href={`/cascopt/lidars/${this.state.comp_sub.project_id}`} className="view-record" >
                LiDAR
              </a>
            </div>
            <div className="col">
              <a href={`/cascopt/compilations/${this.state.comp_sub.project_id}`} className="view-record" >
                Compilation
              </a>
            </div>
            <div className="col">
              <a href={`/cascopt/rectifications/${this.state.comp_sub.project_id}`} className="view-record" >
                Rectification
              </a>
            </div>
            <div className="col">
              <a href={`/cascopt/edits/${this.state.comp_sub.project_id}`} className="view-record" >
                Edit
              </a>
            </div>
            <div className="col">
              <a href={`/cascopt/plots/${this.state.comp_sub.project_id}`} className="view-record" >
                Plot
              </a>
            </div>
            <div className="col">
              <a href={`/cascopt/deliveries/${this.state.comp_sub.project_id}`} className="view-record" >
                Delivery
              </a>
            </div>
            <div className="col">
              <a href={`/cascopt/invoices/${this.state.comp_sub.project_id}`} className="view-record" >
                Invoice
              </a>
            </div>
          </div>
          <hr />
          <p />
          <a href={`/cascopt/projects/${this.state.comp_sub.project_id}`} className="view-record" >
            Back to Project Details
          </a>
          <p />
          <a href={`/cascopt/comp_subs`} className="view-record" >
            Back to Compilation Sub Dashboard
          </a>
          <p />
          <h4 className="mb-4 text-4xl">Compilation Sub for {this.state.project.job_name || ''} | {this.state.project.client_number || ''}-{this.state.project.job_number || ''}</h4>
          <h5>Due: <input type="date" readOnly format="mm/dd/yyyy" className="form-control-plaintext" value={this.state.project.due_date || ''} /></h5>
          <h5>Client: {this.state.project.client_name || ''}</h5>
          <hr />
          <form>
            <div className="row">
              <div className="col">
                <label>Sub Name</label>
                <input type="text" readOnly className="form-control"
                  value={this.state.comp_sub.sub_name || ''} />
              </div>
              <div className="col">
                <label>Sub Due Date</label>
                <input type="date" readOnly format="mm/dd/yyyy" className="form-control"
                  value={this.state.comp_sub.due_date || ''} />
              </div>
              <label>To Sub (fromcomp)</label>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Models to Sub</label>
                <input type="text" readOnly className="form-control"
                  value={this.state.comp_sub.models_to_sub || ''} />
              </div>
              <div className="col">
                <label>Models To Do</label>
                <input type="text" readOnly className="form-control"
                  value={this.state.comp_sub.models_to_do || ''} />
              </div>
              <div className="col">
                <label>Partial Delivered</label>
                <input type="text" readOnly className="form-control"
                  value={this.state.comp_sub.partial_delivered || ''} />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Est Hours</label>
                <input type="text" readOnly className="form-control"
                  value={this.state.comp_sub.hours || ''} />
              </div>
              <div className="col">
                <label>Actual Hours (hh:mm format)</label>
                <input type="text" readOnly className="form-control"
                  value={this.state.comp_sub.actual_hours || ''} />
              </div>
              <div className="col">
                <label>Rejected</label>
                <input type="text" readOnly className="form-control"
                  value={this.state.comp_sub.rejected || ''} />
              </div>
              <div className="col">
                <label>Rejected Date</label>
                <input type="date" readOnly format="mm/dd/yyyy"  className="form-control"
                  value={this.state.comp_sub.rejected_date || ''} />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Sub Done</label>
                <input type="text" readOnly className="form-control"
                  value={this.state.comp_sub.sub_done || ''} />
              </div>
              <div className="col">
                <label>Received</label>
                <input type="text" readOnly className="form-control"
                  value={this.state.comp_sub.received || ''} />
              </div>
              <div className="col">
                <label>Received Date</label>
                <input type="date" readOnly format="mm/dd/yyyy" className="form-control"
                  value={this.state.comp_sub.received_date || ''} />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Rejected Notes</label>
                <input type="text" readOnly className="form-control"
                  value={this.state.comp_sub.rejected_notes || ''} />
              </div>
            </div>
          </form>
          <p />
          <Link to={`/cascopt/comp_subs/${this.state.comp_sub.id}/edit`} >
            <button className="btn btn-sm btn-success">Edit</button>
          </Link>
          <hr />
          <div className="row">
            <div className="col">
              <label>Notes from Project</label>
              <input type="text" readOnly
                value={this.state.project.project_notes || ''}
                className="form-control" />
            </div>
          </div>
          <p />
          <label>Uploaded Files</label>
          <hr />
          <div className="row">
            <div className="col">
              <label>Control File</label>
              <p />
              <img src={this.state.project.control || ''} />
              <p />
              <a href={this.state.project.control_download} className="btn btn-info btn-sm" >Download</a>
              <br />
              <small>
                <strong>Filename:</strong><br />
                {this.state.project.control_file || ''}
              </small>
            </div>
            <div className="col">
              <label>Boundary File</label>
              <p />
              <img src={this.state.project.boundaries || ''} />
              <p />
              <a href={this.state.project.boundary_download} className="btn btn-info btn-sm" >Download</a>
              <br />
              <small>
                <strong>Filename:</strong><br />
                {this.state.project.boundary_file || ''}
              </small>
            </div>
            <div className="col">
              <label>Photo ID File</label>
              <p />
              <img src={this.state.project.photo || ''} />
              <p />
              <a href={this.state.project.photo_download} className="btn btn-info btn-sm" >Download</a>
              <br />
              <small>
                <strong>Filename:</strong><br />
                {this.state.project.photo_file || ''}
              </small>
            </div>
            <div className="col">
              <label>Misc. File</label>
              <p />
              <img src={this.state.project.attachment || ''} />
              <p />
              <a href={this.state.project.attachment_download} className="btn btn-info btn-sm" >Download</a>
              <br />
              <small>
                <strong>Filename:</strong><br />
                {this.state.project.attachment_file || ''}
              </small>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
