import React from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import moment from "moment";

export default class MarketingProjects extends React.Component {
  static propTypes = {
    projects: PropTypes.array,
  };

  static defaultProps = {
    projects: [],
  };

  constructor(props, railsContext) {
    super(props);
    this.state = {
      projects: this.props.projects,
      isHidden: true,
      isHiddenNotComplete: false,
      isLoading: false,
    };
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }

  toggleHiddenNotComplete() {
    this.setState({
      isHiddenNotComplete: !this.state.isHiddenNotComplete,
    });
  }

  componentDidMount() {
    if (this.props.match) {
      this.setState({ isLoading: true });
      $.ajax({
        type: "GET",
        url: "/marketing/projects",
        dataType: "JSON",
      }).done((data) => {
        this.setState({ projects: data, isLoading: false });
      });
    }
  }

  getTrProps = (state, rowInfo, instance) => {
    if (rowInfo) {
      return {
        style: {
          background: rowInfo.row.flagged == true ? "#88aa2a" : "",
        },
      };
    }
    return {};
  };

  render() {
    const { projects, isLoading } = this.state;
    if (isLoading) {
      return <div className="loader"></div>;
    }

    return (
      <div>
        {!this.state.isHiddenNotComplete && (
          <ReactTable
            key={projects.id}
            data={projects}
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id]) === filter.value
            }
            defaultPageSize={100}
            sortable={true}
            style={{
              height: "800px",
              textAlign: "center",
            }}
            columns={[
              {
                Header: "Project Info",
                columns: [
                  {
                    Header: "Project Start Date",
                    filterable: false,
                    accessor: "created_at",
                    Cell: (props) => (
                      <span>{moment(props.value).format("MM/DD/YYYY")}</span>
                    ),
                  },
                  {
                    Header: "Client Rep",
                    accessor: "project_manager",
                    filterMethod: (filter, row) => {
                      if (filter.value === "all") {
                        return true;
                      }
                      if (filter.value === "phil") {
                        return row[filter.id] == "Philip Gershkovich";
                      }
                      if (filter.value === "emily") {
                        return row[filter.id] == "Emily Martin";
                      }
                      if (filter.value === "zachary") {
                        return row[filter.id] == "Zachary Radel";
                      }
                      if (filter.value === "pete") {
                        return row[filter.id] == "Pete Priestner";
                      }
                      if (filter.value === "dave") {
                        return row[filter.id] == "Dave Major";
                      }
                      if (filter.value === "dennis") {
                        return row[filter.id] == "Dennis Harmon";
                      }
                      if (filter.value === "kim") {
                        return row[filter.id] == "Kimberly Edwards";
                      }
                      if (filter.value === "jim") {
                        return row[filter.id] == "Jim Crume";
                      }
                      if (filter.value === "zach") {
                        return row[filter.id] == "Zachary Bearley";
                      }
                      if (filter.value === "sandy") {
                        return row[filter.id] == "Sandy Little";
                      }
                      if (filter.value === "beth") {
                        return row[filter.id] == "Elizabeth Moore";
                      }
                      if (filter.value === "tom") {
                        return row[filter.id] == "Tom Warren";
                      }
                    },
                    Filter: ({ filter, onChange }) => (
                      <select
                        onChange={(event) => onChange(event.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : "all"}
                      >
                        <option value="all">Select User</option>
                        <option value="dave">Dave</option>
                        <option value="dennis">Dennis</option>
                        <option value="beth">Elizabeth</option>
                        <option value="emily">Emily</option>
                        <option value="jim">Jim</option>
                        <option value="kim">Kimberly</option>
                        <option value="pete">Pete</option>
                        <option value="phil">Phil</option>
                        <option value="sandy">Sandy</option>
                        <option value="tom">Tom</option>
                        <option value="zachary">Zachary Radel</option>
                        <option value="zach">Zach</option>
                      </select>
                    ),
                  },
                  {
                    Header: "Project Manager",
                    accessor: "asst_pm",
                    filterMethod: (filter, row) => {
                      if (filter.value === "all") {
                        return true;
                      }
                      if (filter.value === "phil") {
                        return row[filter.id] == "Philip Gershkovich";
                      }
                      if (filter.value === "emily") {
                        return row[filter.id] == "Emily Martin";
                      }
                      if (filter.value === "zachary") {
                        return row[filter.id] == "Zachary Radel";
                      }
                      if (filter.value === "pete") {
                        return row[filter.id] == "Pete Priestner";
                      }
                      if (filter.value === "dave") {
                        return row[filter.id] == "Dave Major";
                      }
                      if (filter.value === "dennis") {
                        return row[filter.id] == "Dennis Harmon";
                      }
                      if (filter.value === "kim") {
                        return row[filter.id] == "Kimberly Edwards";
                      }
                      if (filter.value === "jim") {
                        return row[filter.id] == "Jim Crume";
                      }
                      if (filter.value === "zach") {
                        return row[filter.id] == "Zachary Bearley";
                      }
                      if (filter.value === "sandy") {
                        return row[filter.id] == "Sandy Little";
                      }
                      if (filter.value === "beth") {
                        return row[filter.id] == "Elizabeth Moore";
                      }
                      if (filter.value === "tom") {
                        return row[filter.id] == "Tom Warren";
                      }
                    },
                    Filter: ({ filter, onChange }) => (
                      <select
                        onChange={(event) => onChange(event.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : "all"}
                      >
                        <option value="all">Select User</option>
                        <option value="dave">Dave</option>
                        <option value="dennis">Dennis</option>
                        <option value="beth">Elizabeth</option>
                        <option value="emily">Emily</option>
                        <option value="jim">Jim</option>
                        <option value="kim">Kimberly</option>
                        <option value="pete">Pete</option>
                        <option value="phil">Phil</option>
                        <option value="sandy">Sandy</option>
                        <option value="tom">Tom</option>
                        <option value="zachary">Zachary Radel</option>
                        <option value="zach">Zach</option>
                      </select>
                    ),
                  },
                  {
                    Header: "Federal/Private",
                    accessor: "federal_private",
                    filterMethod: (filter, row) => {
                      if (filter.value === "all") {
                        return true;
                      }
                      if (filter.value === "federal") {
                        return row[filter.id] == "Federal";
                      }
                      if (filter.value === "private") {
                        return row[filter.id] == "Private";
                      }
                    },
                    Filter: ({ filter, onChange }) => (
                      <select
                        onChange={(event) => onChange(event.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : "all"}
                      >
                        <option value="all">Select</option>
                        <option value="federal">Federal</option>
                        <option value="private">Private</option>
                      </select>
                    ),
                  },
                  {
                    Header: "Project Name",
                    accessor: "job_name",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .startsWith(filter.value.toLowerCase()),
                    Cell: (props) => (
                      <a
                        href={`/marketing/projects/${props.original.id}`}
                        className="view-record"
                      >
                        {props.original.job_name}
                      </a>
                    ),
                  },
                  {
                    Header: "Company",
                    accessor: "client_name",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .startsWith(filter.value.toLowerCase()),
                  },
                  {
                    Header: "Client Contact",
                    accessor: "client_contact",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .startsWith(filter.value.toLowerCase()),
                  },
                  {
                    Header: "Location",
                    accessor: "opportunity.location",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .includes(filter.value.toLowerCase()),
                  },
                  {
                    Header: "Cost",
                    accessor: "quote.quote_cost",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .startsWith(filter.value.toLowerCase()),
                  },
                  {
                    Header: "Industry",
                    accessor: "opportunity.industry",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .startsWith(filter.value.toLowerCase()),
                  },
                  {
                    Header: "Flagged",
                    filterable: false,
                    accessor: "flagged",
                    show: false,
                  },
                ],
              },
            ]}
            className="-striped -highlight"
            getTrProps={this.getTrProps}
          />
        )}
      </div>
    );
  }
}
