import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Plots from './Plots';
import PlotForm from './PlotForm';
import PlotView from './PlotView';

export default (props) => {
  return (
    <Router>
      <div>
        <Route exact path="/cascopt/plots" render={routeProps => <Plots {...props} {...routeProps}/>} />
        <Route exact path="/cascopt/plots/:id" render={routeProps => <PlotView {...props} {...routeProps}/>} />
        <Route path="/cascopt/plots/:id/edit" component={PlotForm} />
      </div>
    </Router>
  )
}
