import React from 'react';
import PropTypes from 'prop-types';
import { FormErrors } from './FormErrors';
import update from 'immutability-helper';
import { validations } from '../utils/validations';
import Select from 'react-select';
import 'react-select/dist/react-select.css';

export default class BimForm extends React.Component {
  static propTypes = {
    handleNewBim: PropTypes.func
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      client_number: { value: '', valid: false },
      client_name: { value: '', valid: false },
      job_number: { value: '', valid: false },
      due_date: { value: '', valid: false },
      project_id: {value: '', valid: false},
      hours: { value: '', valid: false },
      bim_to_sub: 'No',
      bim_sub_name: { value: '', valid: false },
      bim_sub_date_rec: { value: '', valid: false },
      bim_sub_date_done: { value: '', valid: false },
      rejected: 'No',
      rejected_date: { value: '', valid: false },
      bim_sub_complete: 'No',
      complete: 'No',
      formErrors: {},
      formValid: false,
      editing: false
    }
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
  }

  static formValidations = {
    project_id: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    hours: [
      (s) => { return(validations.checkMinLength(s, 0)) }
    ],
    bim_sub_name: [
      (s) => { return(validations.checkMinLength(s, 0)) }
    ],
    bim_sub_date_rec: [
      (s) => { return(validations.checkMinLength(s, 0)) }
    ],
    bim_sub_date_done: [
      (s) => { return(validations.checkMinLength(s, 0)) }
    ],
    rejected_date: [
      (s) => { return(validations.checkMinLength(s, 0)) }
    ],
  }

  componentDidMount() {
    if(this.props.match) {
    $.ajax({
      type: "GET",
      url: `/cascopt/bims/${this.props.match.params.id}`,
      dataType: "JSON"
    }).done((data) => {
      this.setState({
        project_id: { value: data.bim.project_id, valid: true },
        client_number: {value: data.project.client_number, valid: true},
        client_name: { value: data.project.client_name, valid: true },
        job_number: { value: data.project.job_number, valid: true },
        due_date: { value: data.project.due_date, valid: true },
        hours: { value: data.bim.hours, valid: true },
        complete: data.bim.complete,
        bim_to_sub: data.bim.bim_to_sub,
        rejected: data.bim.rejected,
        bim_sub_complete: data.bim.bim_sub_complete,
        bim_sub_name: { value: data.bim.bim_sub_name, valid: true },
        bim_sub_date_rec: { value: data.bim.bim_sub_date_rec, valid: true },
        bim_sub_date_done: { value: data.bim.bim_sub_date_done, valid: true },
        rejected_date: { value: data.bim.rejected_date, valid: true },
        editing: this.props.match.path === '/cascopt/bims/:id/edit'
      });
    });
   }
  }

  handleUserInput = (fieldName, fieldValue, validations) => {
    const newFieldState = update(this.state[fieldName],
                                  {value: {$set: fieldValue}});
    this.setState({[fieldName]: newFieldState},
                  () => { this.validateField(fieldName, fieldValue, validations) });
  }

  validateField (fieldName, fieldValue, validations) {
    let fieldValid;

    let fieldErrors = validations.reduce((errors, v) => {
      let e = v(fieldValue);
      if(e !== '') {
        errors.push(e);
      }
      return(errors);
    }, []);

    fieldValid = fieldErrors.length === 0;

    const newFieldState = update(this.state[fieldName],
                                  {valid: {$set: fieldValid}});

    const newFormErrors = update(this.state.formErrors,
                                  {$merge: {[fieldName]: fieldErrors}});

    this.setState({[fieldName]: newFieldState,
                    formErrors: newFormErrors}, this.validateForm);
  }

  validateForm() {
    this.setState({formValid: this.state.project_id.valid &&
                              this.state.hours.valid &&
                              this.state.bim_sub_name &&
                              this.state.bim_sub_date_rec &&
                              this.state.bim_sub_date_done &&
                              this.state.rejected_date
                            });
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.state.editing ?
      this.updateBim() :
      this.addBim();
  }

  updateBim() {
    const bim = {
      project_id: this.state.project_id.value,
      hours: this.state.hours.value,
      complete: this.state.complete,
      bim_to_sub: this.state.bim_to_sub,
      rejected: this.state.rejected,
      bim_sub_complete: this.state.bim_sub_complete,
      bim_sub_name: this.state.bim_sub_name.value,
      bim_sub_date_rec: this.state.bim_sub_date_rec.value,
      bim_sub_date_done: this.state.bim_sub_date_done.value,
      rejected_date: this.state.rejected_date.value,
      };
    $.ajax({
      type: "PATCH",
      url: `/cascopt/bims/${this.props.match.params.id}`,
      data: {bim: bim}
    })
    .done((data) => {
      console.log('BIM updated!');
      this.resetFormErrors();
      this.props.history.goBack('/cascopt/bims');
    })
    .fail((response) => {
      alert("Please review the form for errors");
      this.setState({
        formErrors: response.responseJSON,
        formValid: false})
      });
  }

  addBim() {
    const bim = {
      project_id: this.state.project_id.value,
      hours: this.state.hours,
      complete: this.state.complete,
      bim_to_sub: this.state.bim_to_sub,
      rejected: this.state.rejected,
      bim_sub_complete: this.state.bim_sub_complete,
      bim_sub_name: this.state.bim_sub_name.value,
      bim_sub_date_rec: this.state.bim_sub_date_rec.value,
      bim_sub_date_done: this.state.bim_sub_date_done.value,
      rejected_date: this.state.rejected_date.value,
      };
    $.post('/cascopt/bims', {bim: bim})
    .done((data) => {
      this.props.handleNewBim(data);
      this.resetFormErrors();
    })
    .fail((response) => {
      this.setState({formErrors: response.responseJSON, formValid: false})
    });
  }

  deleteBim = () => {
    if(confirm("Are you sure you want to delete this LiDAR?")) {
      $.ajax({
        type: "DELETE",
        url: `/cascopt/bims/${this.props.match.params.id}`,
      })
      .done((data) => {
        this.props.history.push('/bims');
        this.resetFormErrors();
      })
      .fail((response) => {
        console.log("BIM deletion failed!");
      });
    }
  }

  resetFormErrors() {
    this.setState({formErrors: {}})
  }

  handleChange(e) {
    this.handleUserInput(e.target.name, e.target.value, BimForm.formValidations[e.target.name]);
  }

  handleSelectChange(key) {
  return function ({ value }) {
      this.setState({[key]: value});
    }.bind(this);
  }

  render() {
    return (
      <div className="container" id="form-card">
        <h4 className="mb-4 text-4xl">
        {this.state.editing ?
          'Update BIM' :
            'Create BIM'} for |{this.state.client_number.value} - {this.state.job_number.value}|
        </h4>
        <h5>Due: <input type="date" readOnly format="mm/dd/yyyy" className="form-control-plaintext" value={this.state.due_date.value} /></h5>
        <h5>Client: {this.state.client_name.value}</h5>
        <small>
          (* represents a <strong>required field</strong>)
        </small>
        <FormErrors formErrors = {this.state.formErrors} />
        <form onSubmit={this.handleFormSubmit} >
          <div className="row">
            <div className="col-md-3">
              <label>Hours *</label>
              <input name="hours" placeholder="e.g. 8"
                value={this.state.hours.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
            <div className="col-md-3">
              <label>BIM Complete *</label>
              <Select
                name="complete"
                value={this.state.complete}
                onChange={this.handleSelectChange("complete")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col-md-3">
              <label>BIM To Sub *</label>
              <Select
                name="bim_to_sub"
                value={this.state.bim_to_sub}
                onChange={this.handleSelectChange("bim_to_sub")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
          </div>
          <p />
          {this.state.bim_to_sub == "Yes" &&
          <div>
          <div className="row">
            <div className="col-md-3">
              <label>BIM Sub Name *</label>
              <input name="bim_sub_name" placeholder="e.g. COWI"
                value={this.state.bim_sub_name.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
            <div className="col-md-3">
              <label>BIM Sub Date Received *</label>
              <input type="date" name="bim_sub_date_rec"
                value={this.state.bim_sub_date_rec.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
            <div className="col-md-3">
              <label>BIM Sub Complete *</label>
              <Select
                name="bim_sub_complete"
                value={this.state.bim_sub_complete}
                onChange={this.handleSelectChange("bim_sub_complete")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
            {this.state.bim_sub_complete == "Yes" &&
            <div className="col-md-3">
              <label>BIM Sub Date Completed *</label>
              <input type="date" name="bim_sub_date_done"
                value={this.state.bim_sub_date_done.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>}
          </div>
          </div>}
          <p />
          <div className="row">
            <div className="col-md-3">
              <label>BIM Rejected *</label>
              <Select
                name="rejected"
                value={this.state.rejected}
                onChange={this.handleSelectChange("rejected")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
            {this.state.rejected == "Yes" &&
            <div className="col-md-3">
              <label>BIM Rejected Date *</label>
              <input type="date" name="rejected_date"
                value={this.state.rejected_date.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>}
          </div>
          <p />
          <input type="submit"
            value={this.state.editing ? 'Update' : 'Create'} className="btn btn-secondary btn-sm"
            />
          <a className="btn btn-danger btn-sm" onClick={this.props.history.goBack}>Cancel</a>
        </form>
        <br />
      </div>
    )
  }
}
