import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["search", "results"];

  connect() {
    this.originalData = Array.from(this.resultsTarget.querySelectorAll("tr"));
    this.sortedColumn = null;
    this.sortDirection = "asc";
  }

  search() {
    const query = this.searchTarget.value.toLowerCase();
    const filteredData = this.originalData.filter((row) => {
      return Array.from(row.cells).some((cell) =>
        cell.textContent.toLowerCase().includes(query)
      );
    });
    this.resultsTarget.innerHTML = "";
    this.resultsTarget.append(...filteredData);
  }

  sort(event) {
    const column = event.currentTarget.dataset.sortColumn;
    this.sortDirection =
      this.sortedColumn === column && this.sortDirection === "asc"
        ? "desc"
        : "asc";
    this.sortedColumn = column;

    const sortedData = [...this.originalData].sort((a, b) => {
      const aValue = a
        .querySelector(`td:nth-child(${this.getColumnIndex(column)})`)
        .textContent.trim();
      const bValue = b
        .querySelector(`td:nth-child(${this.getColumnIndex(column)})`)
        .textContent.trim();

      if (aValue === bValue) return 0;
      if (this.sortDirection === "asc") return aValue > bValue ? 1 : -1;
      return aValue < bValue ? 1 : -1;
    });

    this.resultsTarget.innerHTML = "";
    this.resultsTarget.append(...sortedData);
  }

  getColumnIndex(columnName) {
    return (
      [
        "estimate_name",
        "contact",
        "acquisition_type",
        "project_location",
        "project_total",
      ].indexOf(columnName) + 1
    );
  }
}
