/* eslint-disable react/prefer-stateless-function */
import React from 'react';

export default class PostQcDashboard extends React.Component {
  render() {
    return (
      <div>
        <h3 className="mb-4 text-4xl">Projects in Post QC Stages</h3>
      </div>
    )
  }
}
