import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Drones from './Drones';
import DroneForm from './DroneForm';
import DroneView from './DroneView';

export default (props) => {
  return (
    <Router>
      <div>
        <Route exact path="/cascopt/drones" render={routeProps => <Drones {...props} {...routeProps}/>} />
        <Route exact path="/cascopt/drones/:id" render={routeProps => <DroneView {...props} {...routeProps}/>} />
        <Route path="/cascopt/drones/:id/edit" component={DroneForm} />
      </div>
    </Router>
  )
}
