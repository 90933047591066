/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';

export default class Project extends React.Component {
  render() {
    return (
      <div>
        <h3 id="header">Production Reports Dashboard</h3>
      </div>
    );
  }
}
