import React from 'react';
import PropTypes from 'prop-types';
import { FormErrors } from './FormErrors';
import update from 'immutability-helper';
import { validations } from '../utils/validations';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

export default class InvoiceForm extends React.Component {
  static propTypes = {
    handleNewInvoice: PropTypes.func
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      payment_type: { value: "", valid: false },
      payment_desc: { value: "", valid: false },
      payment_type1: { value: "", valid: false },
      payment_desc1: { value: "", valid: false },
      alt_invoice_number: { value: "", valid: false },
      alt_invoice_date: { value: "", valid: false },
      alt_paid_date: { value: "", valid: false },
      alt_paid: "",
      alt_invoiced: "",
      purchase_order: { value: "", valid: false },
      project_type: { value: "", valid: false },
      flight_cost: { value: "", valid: false },
      survey_cost: { value: "", valid: false },
      lab_cost: { value: "", valid: false },
      map_cost: { value: "", valid: false },
      image_cost: { value: "", valid: false },
      lidar_cost: { value: "", valid: false },
      quote_cost: { value: "", valid: false },
      client_number: { value: "", valid: false },
      client_name: { value: "", valid: false },
      invoice_due_date: { value: "", valid: false },
      job_number: { value: "", valid: false },
      project_id: { value: "", valid: false },
      due_date: { value: "", valid: true },
      delivered: "",
      project_done: "",
      final_delivery: { value: "", valid: true },
      to_billing: "",
      no_invoice: "",
      invoice_date: { value: "", valid: true },
      invoice_number: { value: "", valid: true },
      invoiced: "",
      date_paid: { value: "", valid: true },
      paid: "",
      invoice_contact: { value: "", valid: false },
      billing_info: { value: "", valid: true },
      flight_sub_cost: { value: "0", valid: true },
      map_sub_cost: { value: "0", valid: true },
      survey_sub_cost: { value: "0", valid: true },
      formErrors: {},
      formValid: false,
      editing: false,
    };
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
  }

  static formValidations = {
    project_id: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    invoice_contact: [
      (s) => { return(validations.checkMinLength(s, 3)) }
    ],
    purchase_order: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    alt_invoice_number: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    alt_invoice_date: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    alt_paid_date: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    invoice_number: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    due_date: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    final_delivery: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    invoice_date: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    date_paid: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    payment_type: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    payment_desc: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    payment_type1: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    payment_desc1: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    billing_info: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    flight_sub_cost: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    map_sub_cost: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    survey_sub_cost: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ]
  }

  componentDidMount() {
    if(this.props.match) {
    $.ajax({
      type: "GET",
      url: `/cascopt/invoices/${this.props.match.params.id}`,
      dataType: "JSON"
    }).done((data) => {
      this.setState({
        flight_sub_cost: { value: data.invoice.flight_sub_cost, valid: true },
        map_sub_cost: { value: data.invoice.map_sub_cost, valid: true },
        survey_sub_cost: { value: data.invoice.survey_sub_cost, valid: true },
        payment_type: { value: data.invoice.payment_type, valid: true },
        payment_desc: { value: data.invoice.payment_desc, valid: true },
        payment_type1: { value: data.invoice.payment_type1, valid: true },
        payment_desc1: { value: data.invoice.payment_desc1, valid: true },
        alt_invoice_number: {
          value: data.invoice.alt_invoice_number,
          valid: true,
        },
        alt_invoice_date: { value: data.invoice.alt_invoice_date, valid: true },
        alt_paid_date: { value: data.invoice.alt_paid_date, valid: true },
        purchase_order: { value: data.project.purchase_order, valid: true },
        project_type: { value: data.project.project_type, valid: true },
        flight_cost: { value: data.project.flight_cost, valid: true },
        survey_cost: { value: data.project.survey_cost, valid: true },
        lab_cost: { value: data.project.lab_cost, valid: true },
        map_cost: { value: data.project.map_cost, valid: true },
        image_cost: { value: data.project.img_cost, valid: true },
        lidar_cost: { value: data.project.lidar_flight_cost, valid: true },
        quote_cost: { value: data.project.quote_cost, valid: true },
        client_number: { value: data.project.client_number, valid: true },
        client_name: { value: data.project.client_name, valid: true },
        job_number: { value: data.project.job_number, valid: true },
        invoice_due_date: { value: data.project.due_date, valid: true },
        project_id: { value: data.invoice.project_id, valid: true },
        due_date: { value: data.invoice.due_date, valid: true },
        delivered: data.invoice.delivered,
        alt_paid: data.invoice.alt_paid,
        alt_invoiced: data.invoice.alt_invoiced,
        project_done: data.invoice.project_done,
        final_delivery: { value: data.invoice.final_delivery, valid: true },
        to_billing: data.invoice.to_billing,
        no_invoice: data.invoice.no_invoice,
        invoice_date: { value: data.invoice.invoice_date, valid: true },
        invoice_number: { value: data.invoice.invoice_number, valid: true },
        invoiced: data.invoice.invoiced,
        date_paid: { value: data.invoice.date_paid, valid: true },
        paid: data.invoice.paid,
        invoice_contact: { value: data.invoice.invoice_contact, valid: true },
        billing_info: { value: data.invoice.billing_info, valid: true },
        editing: this.props.match.path === "/cascopt/invoices/:id/edit",
      });
    });
   }
  }

  handleUserInput = (fieldName, fieldValue, validations) => {
    const newFieldState = update(this.state[fieldName],
                                  {value: {$set: fieldValue}});
    this.setState({[fieldName]: newFieldState},
                  () => { this.validateField(fieldName, fieldValue, validations) });
  }

  validateField (fieldName, fieldValue, validations) {
    let fieldValid;

    let fieldErrors = validations.reduce((errors, v) => {
      let e = v(fieldValue);
      if(e !== '') {
        errors.push(e);
      }
      return(errors);
    }, []);

    fieldValid = fieldErrors.length === 0;

    const newFieldState = update(this.state[fieldName],
                                  {valid: {$set: fieldValid}});

    const newFormErrors = update(this.state.formErrors,
                                  {$merge: {[fieldName]: fieldErrors}});

    this.setState({[fieldName]: newFieldState,
                    formErrors: newFormErrors}, this.validateForm);
  }

  validateForm() {
    this.setState({formValid: this.state.project_id.valid &&
                              this.state.due_date.valid &&
                              this.state.purchase_order.valid &&
                              this.state.final_delivery.valid &&
                              this.state.invoice_date.valid &&
                              this.state.date_paid.valid &&
                              this.state.invoice_contact.valid &&
                              this.state.billing_info.valid &&
                              this.state.invoice_number.valid &&
                              this.state.alt_invoice_number.valid &&
                              this.state.alt_invoice_date.valid &&
                              this.state.alt_paid_date.valid &&
                              this.state.payment_type.valid &&
                              this.state.payment_desc.valid &&
                              this.state.payment_type1.valid &&
                              this.state.payment_desc1.valid &&
                              this.state.flight_sub_cost.valid &&
                              this.state.map_sub_cost.valid &&
                              this.state.survey_sub_cost.valid
                            });
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.state.editing ?
      this.updateInvoice() :
      this.addInvoice();
  }

  updateInvoice() {
    const invoice = {
      project_id: this.state.project_id.value,
      flight_sub_cost: this.state.flight_sub_cost.value,
      map_sub_cost: this.state.map_sub_cost.value,
      survey_sub_cost: this.state.survey_sub_cost.value,
      payment_type: this.state.payment_type.value,
      payment_desc: this.state.payment_desc.value,
      payment_type1: this.state.payment_type1.value,
      payment_desc1: this.state.payment_desc1.value,
      purchase_order: this.state.purchase_order.value,
      alt_invoice_number: this.state.alt_invoice_number.value,
      alt_invoice_date: this.state.alt_invoice_date.value,
      alt_paid_date: this.state.alt_paid_date.value,
      invoice_contact: this.state.invoice_contact.value,
      invoice_number: this.state.invoice_number.value,
      due_date: this.state.due_date.value,
      delivered: this.state.delivered,
      project_done: this.state.project_done,
      final_delivery: this.state.final_delivery.value,
      to_billing: this.state.to_billing,
      no_invoice: this.state.no_invoice,
      invoice_date: this.state.invoice_date.value,
      invoiced: this.state.invoiced,
      date_paid: this.state.date_paid.value,
      paid: this.state.paid,
      alt_paid: this.state.alt_paid,
      alt_invoiced: this.state.alt_invoiced,
      billing_info: this.state.billing_info.value,
    };
    $.ajax({
      type: "PATCH",
      url: `/cascopt/invoices/${this.props.match.params.id}`,
      data: {invoice: invoice}
    })
    .done((data) => {
      console.log('invoice updated!');
      this.resetFormErrors();
      this.props.history.goBack('/cascopt/invoices');
    })
    .fail((response) => {
      alert("Please review the form for errors");
      this.setState({
        formErrors: response.responseJSON,
        formValid: false
      })
    });
  }

  addInvoice() {
    const invoice = {
      project_id: this.state.project_id.value,
      flight_sub_cost: this.state.flight_sub_cost.value,
      map_sub_cost: this.state.map_sub_cost.value,
      survey_sub_cost: this.state.survey_sub_cost.value,
      payment_type: this.state.payment_type.value,
      payment_desc: this.state.payment_desc.value,
      payment_type1: this.state.payment_type1.value,
      payment_desc1: this.state.payment_desc1.value,
      purchase_order: this.state.purchase_order.value,
      alt_invoice_number: this.state.alt_invoice_number.value,
      alt_invoice_date: this.state.alt_invoice_date.value,
      alt_paid_date: this.state.alt_paid_date.value,
      invoice_contact: this.state.invoice_contact.value,
      invoice_number: this.state.invoice_number.value,
      due_date: this.state.due_date.value,
      delivered: this.state.delivered,
      project_done: this.state.project_done,
      final_delivery: this.state.final_delivery.value,
      to_billing: this.state.to_billing,
      no_invoice: this.state.no_invoice,
      invoice_date: this.state.invoice_date.value,
      invoiced: this.state.invoiced,
      date_paid: this.state.date_paid.value,
      paid: this.state.paid,
      alt_paid: this.state.alt_paid,
      alt_invoiced: this.state.alt_invoiced,
      billing_info: this.state.billing_info.value,
    };
    $.post('/cascopt/invoices', {invoice: invoice})
    .done((data) => {
      this.props.handleNewInvoice(data);
      this.resetFormErrors();
    })
    .fail((response) => {
      this.setState({formErrors: response.responseJSON,
        formValid: false})
    });
  }

  deleteInvoice = () => {
    if(confirm("Are you sure you want to delete this invoice?")) {
      $.ajax({
        type: "DELETE",
        url: `/cascopt/invoices/${this.props.match.params.id}`,
      })
      .done((data) => {
        this.props.history.push('/cascopt/invoices');
        this.resetFormErrors();
      })
      .fail((response) => {
        console.log("Invoice deletion failed!");
      });
    }
  }

  resetFormErrors() {
    this.setState({formErrors: {}})
  }

  handleChange(e) {
    this.handleUserInput(e.target.name, e.target.value, InvoiceForm.formValidations[e.target.name]);
  }

  handleSelectChange(key) {
  return function ({ value }) {
      this.setState({[key]: value});
    }.bind(this);
  }

  render() {
    /* Automatically set invoice due date to 30 days out */
    let invoiceDue = moment().add(30, 'days').toISOString().substr(0, 10);
    this.state.due_date.value = invoiceDue;

    return (
      <div className="container" id="form-card">
        <h4 className="mb-4 text-4xl">
          {this.state.editing ? "Update Invoice" : "Create Invoice"} for |
          {this.state.client_number.value} - {this.state.job_number.value}|
        </h4>
        <p>
          Due: {moment(this.state.invoice_due_date.value).format("MM/DD/YYYY")}
        </p>
        <p> Client: {this.state.client_name.value}</p>
        <small>
          (* represents a <strong>required field</strong>)
        </small>
        {this.state.project_type.value == "Normal" && (
          <div className="row">
            <div className="col-md-4">
              Flight Cost: {this.state.flight_cost.value || "n/a"}
              <br />
              Survey Cost: {this.state.survey_cost.value || "n/a"}
              <br />
              Lab Cost: {this.state.lab_cost.value || "n/a"}
              <br />
              Map Cost: {this.state.map_cost.value || "n/a"}
              <br />
            </div>
            <div className="col-md-4">
              Image Cost: {this.state.image_cost.value || "n/a"}
              <br />
              LiDAR Cost: {this.state.lidar_cost.value || "n/a"}
              <br />
              Quote Cost: {this.state.quote_cost.value || "n/a"}
            </div>
          </div>
        )}
        {this.state.project_type.value == "Film w/ LiDAR" && (
          <div className="row">
            <div className="col-md-4">
              Flight Cost: {this.state.flight_cost.value || "n/a"}
              <br />
              Survey Cost: {this.state.survey_cost.value || "n/a"}
              <br />
              Lab Cost: {this.state.lab_cost.value || "n/a"}
              <br />
              Map Cost: {this.state.map_cost.value || "n/a"}
              <br />
            </div>
            <div className="col-md-4">
              Image Cost: {this.state.image_cost.value || "n/a"}
              <br />
              LiDAR Cost: {this.state.lidar_cost.value || "n/a"}
              <br />
              Quote Cost: {this.state.quote_cost.value || "n/a"}
            </div>
          </div>
        )}
        {this.state.project_type.value == "Digital w/ LiDAR" && (
          <div className="row">
            <div className="col-md-4">
              Flight Cost: {this.state.flight_cost.value || "n/a"}
              <br />
              Survey Cost: {this.state.survey_cost.value || "n/a"}
              <br />
              Lab Cost: {this.state.lab_cost.value || "n/a"}
              <br />
              Map Cost: {this.state.map_cost.value || "n/a"}
              <br />
            </div>
            <div className="col-md-4">
              Image Cost: {this.state.image_cost.value || "n/a"}
              <br />
              LiDAR Cost: {this.state.lidar_cost.value || "n/a"}
              <br />
              Quote Cost: {this.state.quote_cost.value || "n/a"}
            </div>
          </div>
        )}
        {this.state.project_type.value == "BIM" && (
          <div className="row">
            <div className="col-md-4">
              Quote Cost: {this.state.quote_cost.value || "n/a"}
            </div>
          </div>
        )}
        {this.state.project_type.value == "Survey Only" && (
          <div className="row">
            <div className="col-md-4">
              Survey Cost: {this.state.survey_cost.value || "n/a"}
            </div>
            <div className="col-md-4">
              Quote Cost: {this.state.quote_cost.value || "n/a"}
            </div>
          </div>
        )}
        {this.state.project_type.value == "Plot Only" && (
          <div className="row">
            <div className="col-md-4">
              Quote Cost: {this.state.quote_cost.value || "n/a"}
            </div>
          </div>
        )}
        {this.state.project_type.value == "Compilation Only" && (
          <div className="row">
            <div className="col-md-4">
              Quote Cost: {this.state.quote_cost.value || "n/a"}
            </div>
          </div>
        )}
        {this.state.project_type.value == "Image Only" && (
          <div className="row">
            <div className="col-md-4">
              Quote Cost: {this.state.quote_cost.value || "n/a"}
            </div>
          </div>
        )}
        {this.state.project_type.value == "Scan Only" && (
          <div className="row">
            <div className="col-md-4">
              Quote Cost: {this.state.quote_cost.value || "n/a"}
            </div>
          </div>
        )}
        {this.state.project_type.value == "Flight Only" && (
          <div className="row">
            <div className="col-md-4">
              Flight Cost: {"$" + this.state.flight_cost.value || "n/a"}
            </div>
            <div className="col-md-4">
              Quote Cost: {this.state.quote_cost.value || "n/a"}
            </div>
          </div>
        )}
        {this.state.project_type.value == "Compilation w/ LiDAR" && (
          <div className="row">
            <div className="col-md-4">
              Quote Cost: {this.state.quote_cost.value || "n/a"}
            </div>
          </div>
        )}
        {this.state.project_type.value == "LiDAR Only" && (
          <div className="row">
            <div className="col-md-4">
              Quote Cost: {this.state.quote_cost.value || "n/a"}
            </div>
          </div>
        )}
        <hr />
        <FormErrors formErrors={this.state.formErrors} />
        <form onSubmit={this.handleFormSubmit}>
          <div className="row">
            <div className="col-md-2">
              <label>Purchase Order # *</label>
              <input
                name="purchase_order"
                placeholder="Purchase Order #"
                value={this.state.purchase_order.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col-md-2">
              <label>Client PM *</label>
              <input
                name="invoice_contact"
                placeholder="Client PM"
                value={this.state.invoice_contact.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col-md-2">
              <label>Invoice # *</label>
              <input
                name="invoice_number"
                placeholder="Invoice #"
                value={this.state.invoice_number.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col-md-2">
              <label>Invoiced *</label>
              <Select
                name="invoiced"
                value={this.state.invoiced}
                onChange={this.handleSelectChange("invoiced")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
            {this.state.invoiced == "Yes" && (
              <div className="col-md-2">
                <label>Invoice Date *</label>
                <input
                  type="date"
                  name="invoice_date"
                  placeholder="Invoice Date"
                  value={this.state.invoice_date.value}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            )}
            {this.state.invoiced == "Yes" && (
              <div className="col-md-2">
                <label>Due Date *</label>
                <input
                  type="date"
                  name="due_date"
                  defaultValue={this.state.due_date.value}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            )}
          </div>
          <p />
          <div className="row">
            <div className="col-md-2">
              <label>Paid *</label>
              <Select
                name="paid"
                value={this.state.paid}
                onChange={this.handleSelectChange("paid")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
            {this.state.paid == "Yes" && (
              <div className="col-md-2">
                <label>Date Paid *</label>
                <input
                  type="date"
                  name="date_paid"
                  placeholder="Date Paid"
                  value={this.state.date_paid.value}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            )}
          </div>
          <p />
          <div className="row">
            <div className="col-md-3">
              <label>Flight Sub Cost *</label>
              <input
                name="flight_sub_cost"
                placeholder="Actual Flight Sub Cost"
                value={this.state.flight_sub_cost.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col-md-3">
              <label>Map Sub Cost *</label>
              <input
                name="map_sub_cost"
                placeholder="Actual Map Sub Cost"
                value={this.state.map_sub_cost.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col-md-3">
              <label>Survey Sub Cost *</label>
              <input
                name="survey_sub_cost"
                placeholder="Actual Map Sub Cost"
                value={this.state.survey_sub_cost.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col">
              <label>Billing Info *</label>
              <textarea
                name="billing_info"
                placeholder="Billing Info"
                value={this.state.billing_info.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
          </div>
          <p />
          <h5>Flight Invoice</h5>
          <hr />
          <div className="row">
            <div className="col-md-2">
              <label>Flight Invoice # *</label>
              <input
                name="alt_invoice_number"
                placeholder="Invoice #"
                value={this.state.alt_invoice_number.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col-md-2">
              <label>Flight Invoiced *</label>
              <Select
                name="alt_invoiced"
                value={this.state.alt_invoiced}
                onChange={this.handleSelectChange("alt_invoiced")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
            {this.state.alt_invoiced == "Yes" && (
              <div className="col-md-2">
                <label>Flight Invoice Date *</label>
                <input
                  type="date"
                  name="alt_invoice_date"
                  placeholder="Date Paid"
                  value={this.state.alt_invoice_date.value}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            )}
          </div>
          <p />
          <div className="row">
            <div className="col-md-2">
              <label>Flight Invoice Paid *</label>
              <Select
                name="alt_paid"
                value={this.state.alt_paid}
                onChange={this.handleSelectChange("alt_paid")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
            {this.state.alt_paid == "Yes" && (
              <div className="col-md-2">
                <label>Flight Invoice Date Paid *</label>
                <input
                  type="date"
                  name="alt_paid_date"
                  placeholder="Date Paid"
                  value={this.state.alt_paid_date.value}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            )}
          </div>
          <p />
          <input
            type="submit"
            value={this.state.editing ? "Update" : "Create"}
            className="btn btn-secondary btn-sm"
          />
          <a
            className="btn btn-danger btn-sm"
            onClick={this.props.history.goBack}
          >
            Cancel
          </a>
        </form>
        <br />
      </div>
    );
  }
}
