import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Specifications from './Specifications';
import SpecificationForm from './SpecificationForm';
import SpecificationView from './SpecificationView';
import SpecForm from './SpecForm';

export default (props) => {
  return (
    <Router>
      <div>
        <Route exact path="/specifications" render={routeProps => <Specifications {...props} {...routeProps}/>} />
        <Route exact path="/specifications/:id" render={routeProps => <SpecificationView {...props} {...routeProps}/>} />
        <Route path="/specifications/:id/edit" render={routeProps => <SpecForm {...props} {...routeProps}/>} />
      </div>
    </Router>
  )
}
