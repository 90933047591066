import React from "react";
import PropTypes from "prop-types";
import update from "immutability-helper";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import "react-select/dist/react-select.css";

export default class QuoteForm extends React.Component {
  static propTypes = {
    handleNewQuote: PropTypes.func,
  };

  constructor(props, railsContext) {
    super(props);
    this.state = {
      bonus_perc: 0,
      isHiddenServices: false,
      isHidden: false,
      registered_land_surveyor: "0",
      rls_hrs: 0,
      rls_cp: 150,
      survey_party_chief: "0",
      spc_hrs: 0,
      spc_cp: 90,
      survey_tech: "0",
      st_hrs: 0,
      st_cp: 60,
      survey_crew2: "0",
      sc2_hrs: 0,
      sc2_cp: 100,
      survey_crew3: "0",
      sc3_hrs: 0,
      sc3_cp: 120,
      rw_plans_tech: "0",
      rw_plans_hrs: 0,
      rw_plans_cp: 80,
      total_adjustment: 0,
      adjustment_reason: "",
      client_survey: "",
      flight_sub: "",
      flight_sub_options: [
        { value: "Cooper Aerial", label: "Cooper Aerial" },
        { value: "Geomni", label: "Geomni" },
        { value: "Verisk", label: "Verisk" },
        { value: "NAAS", label: "NAAS" },
        { value: "SAM", label: "SAM" },
        { value: "CSTI", label: "CSTI" },
        { value: "Atlantic Group", label: "Atlantic Group" },
        { value: "Aero-Graphics", label: "Aero-Graphics" },
        { value: "Keystone Aerial", label: "Keystone Aerial" },
        { value: "American Aerial", label: "American Aerial" },
        { value: "Valley Air", label: "Valley Air" },
        { value: "Radman Aerial", label: "Radman Aerial" },
        { value: "Air Flight SVCS", label: "Air Flight SVCS" },
        {
          value: "Skyview Aerial Photo (South CA)",
          label: "Skyview Aerial Photo (South CA)",
        },
        { value: "Talos Aviation", label: "Talos Aviation" },
        { value: "Paragon", label: "Paragon" },
        { value: "Midwest Aerial Photo", label: "Midwest Aerial Photo" },
        { value: "Gammair", label: "Gammair" },
        {
          value: "Richard Crouse and Associates",
          label: "Richard Crouse and Associates",
        },
        {
          value: "I.F. Rooks & Associates, Inc.",
          label: "I.F. Rooks & Associates, Inc.",
        },
        {
          value: "Aerial Data Service, Inc.",
          label: "Aerial Data Service, Inc.",
        },
        { value: "Aerodata, Inc.", label: "Aerodata, Inc." },
        { value: "Lasermap USA", label: "Lasermap USA" },
        { value: "Aerials, Inc.", label: "Aerials, Inc." },
        {
          value: "Gulf Coast Aerial Mapping",
          label: "Gulf Coast Aerial Mapping",
        },
        { value: "Air Photographics, Inc.", label: "Air Photographics, Inc." },
        { value: "Ace Aerial", label: "Ace Aerial" },
        { value: "Georgia Aerial Surveys", label: "Georgia Aerial Surveys" },
        {
          value: "Krawietz Aerial Photo Service",
          label: "Krawietz Aerial Photo Service",
        },
        {
          value: "Continental Aerial Surveys Inc.",
          label: "Continental Aerial Surveys Inc.",
        },
        { value: "Aerial Imaging Inc.", label: "Aerial Imaging Inc." },
        {
          value: "McClintock Land Associates, Inc.",
          label: "McClintock Land Associates, Inc.",
        },
        { value: "Eagle Aerial Solutions", label: "Eagle Aerial Solutions" },
        {
          value: "Rocky Mountain Aerial Surveys",
          label: "Rocky Mountain Aerial Surveys",
        },
        { value: "Blue Skies Consulting", label: "Blue Skies Consulting" },
        { value: "GeoTerra", label: "GeoTerra" },
        { value: "Quantum Spatial", label: "Quantum Spatial" },
        { value: "Aerocon", label: "Aerocon" },
        { value: "Pickett Aerial", label: "Pickett Aerial" },
        { value: "Airborne Sensing", label: "Airborne Sensing" },
        { value: "MAP RES", label: "MAP RES" },
        { value: "Predictive Service", label: "Predictive Service" },
        { value: "Wilson Company", label: "Wilson Company" },
        { value: "KBM, Inc.", label: "KBM, Inc." },
        { value: "Air Flight Service", label: "Air Flight Service" },
        { value: "Lidar Services LLC", label: "Lidar Services LLC" },
        { value: "Eagle Mapping", label: "Eagle Mapping" },
      ],
      flight_quote: "",
      flight_markup: "",
      flight_cost: "",
      flight_notes: "",
      panels: "",
      abgps: "",
      survey_sub: "",
      survey_cost: "",
      survey_quote: "",
      survey_markup: "0",
      survey_notes: "",
      contact_prints: "",
      contact_prints_cp: "",
      diapositives: "",
      diapositives_cp: "",
      scan_cp: "",
      lab_discount: "",
      lab_cost: "",
      lab_notes: "",
      analytics_hp: "",
      analytics_cp: "",
      photogrammetry_cp: "",
      photogrammetry_hp: "",
      xsec: "",
      xsec_cp: "",
      xsec_hp: "",
      cartography_cp: "",
      cartography_hp: "",
      translation: "",
      translation_cp: "",
      translation_hp: "",
      map_discount: "",
      map_cost: "",
      img_hp: "",
      img_cp: "",
      plots: "",
      plot_x: "",
      plot_y: "",
      plot_cp: "",
      delivery: "",
      delivery_type: "",
      delivery_type_options: [
        { value: "FTP", label: "FTP" },
        { value: "USB", label: "USB" },
        { value: "H/D", label: "H/D" },
        { value: "DVD", label: "DVD" },
      ],
      delivery_cp: "",
      img_discount: "",
      img_cost: "",
      quote_cost: "",
      map_notes: "",
      img_notes: "",
      plot_mount: "",
      camera: "",
      supplemental_survey: "",
      plain_gc: "",
      description: "",
      sales_tax: "",
      unit_price: "",
      shipping: "",
      discount: "",
      verify_boundary: "No",
      rough_grade: "No",
      stake_building: "No",
      storm_sewer: "No",
      underground_utility: "No",
      stake_electric: "No",
      stake_concrete: "No",
      stake_walls: "No",
      perform_survey: "No",
      cad_tech: "No",
      stake_subgrade: "No",
      stake_abc: "No",
      offsite_staking: "No",
      verify_boundary_price: 0,
      rough_grade_price: 0,
      stake_building_price: 0,
      storm_sewer_price: 0,
      underground_utility_price: 0,
      stake_electric_price: 0,
      stake_concrete_price: 0,
      stake_walls_price: 0,
      perform_survey_price: 0,
      cad_tech_price: 0,
      stake_subgrade_price: 0,
      stake_abc_price: 0,
      offsite_staking_price: 0,
      add_services: "No",
      add_services_price: 0,
      editing: false,
      quote_type: this.props.quoteType || "",
      user_id: this.props.userId || "",
      ...props.quote,
    };
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/quotes/${this.props.match.params.id}`,
        dataType: "JSON",
      }).done((data) => {
        this.setState({
          bonus_perc: data.quote.bonus_perc,
          add_services: data.quote.add_services,
          add_services_price: data.quote.add_services_price,
          verify_boundary: data.quote.verify_boundary,
          rough_grade: data.quote.rough_grade,
          stake_building: data.quote.stake_building,
          storm_sewer: data.quote.storm_sewer,
          underground_utility: data.quote.underground_utility,
          stake_electric: data.quote.stake_electric,
          stake_concrete: data.quote.stake_concrete,
          stake_walls: data.quote.stake_walls,
          perform_survey: data.quote.perform_survey,
          cad_tech: data.quote.cad_tech,
          stake_subgrade: data.quote.stake_subgrade,
          stake_abc: data.quote.stake_abc,
          offsite_staking: data.quote.offsite_staking,
          verify_boundary_price: data.quote.verify_boundary_price,
          rough_grade_price: data.quote.rough_grade_price,
          stake_building_price: data.quote.stake_building_price,
          storm_sewer_price: data.quote.storm_sewer_price,
          underground_utility_price: data.quote.underground_utility_price,
          stake_electric_price: data.quote.stake_electric_price,
          stake_concrete_price: data.quote.stake_concrete_price,
          stake_walls_price: data.quote.stake_walls_price,
          perform_survey_price: data.quote.perform_survey_price,
          cad_tech_price: data.quote.cad_tech_price,
          stake_subgrade_price: data.quote.stake_subgrade_price,
          stake_abc_price: data.quote.stake_abc_price,
          offsite_staking_price: data.quote.offsite_staking_price,
          registered_land_surveyor: data.quote.registered_land_surveyor,
          rls_hrs: data.quote.rls_hrs,
          rls_cp: data.quote.rls_cp,
          survey_party_chief: data.quote.survey_party_chief,
          spc_hrs: data.quote.spc_hrs,
          spc_cp: data.quote.spc_cp,
          survey_tech: data.quote.survey_tech,
          st_hrs: data.quote.st_hrs,
          st_cp: data.quote.st_cp,
          survey_crew2: data.quote.survey_crew2,
          sc2_hrs: data.quote.sc2_hrs,
          sc2_cp: data.quote.sc2_cp,
          survey_crew3: data.quote.survey_crew3,
          sc3_hrs: data.quote.sc3_hrs,
          sc3_cp: data.quote.sc3_cp,
          rw_plans_tech: data.quote.rw_plans_tech,
          rw_plans_hrs: data.quote.rw_plans_hrs,
          rw_plans_cp: data.quote.rw_plans_cp,
          total_adjustment: data.quote.total_adjustment,
          adjustment_reason: data.quote.adjustment_reason,
          client_survey: data.quote.client_survey,
          user_id: data.quote.user_id,
          opportunity_id: data.quote.opportunity_id,
          specification_id: data.quote.specification_id,
          flight_sub: data.quote.flight_sub,
          flight_quote: data.quote.flight_quote,
          flight_markup: data.quote.flight_markup,
          flight_cost: data.quote.flight_cost,
          flight_notes: data.quote.flight_notes,
          panels: data.quote.panels,
          abgps: data.quote.abgps,
          survey_sub: data.quote.survey_sub,
          survey_cost: data.quote.survey_cost,
          survey_quote: data.quote.survey_quote,
          survey_markup: data.quote.survey_markup,
          survey_notes: data.quote.survey_notes,
          contact_prints: data.quote.contact_prints,
          contact_prints_cp: data.quote.contact_prints_cp,
          diapositives: data.quote.diapositives,
          diapositives_cp: data.quote.diapositives_cp,
          scan_cp: data.quote.scan_cp,
          lab_discount: data.quote.lab_discount,
          lab_cost: data.quote.lab_cost,
          lab_notes: data.quote.lab_notes,
          analytics_hp: data.quote.analytics_hp,
          analytics_cp: data.quote.analytics_cp,
          photogrammetry_cp: data.quote.photogrammetry_cp,
          photogrammetry_hp: data.quote.photogrammetry_hp,
          xsec: data.quote.xsec,
          xsec_cp: data.quote.xsec_cp,
          xsec_hp: data.quote.xsec_hp,
          cartography_cp: data.quote.cartography_cp,
          cartography_hp: data.quote.cartography_hp,
          translation: data.quote.translation,
          translation_cp: data.quote.translation_cp,
          translation_hp: data.quote.translation_hp,
          map_discount: data.quote.map_discount,
          map_cost: data.quote.map_cost,
          img_hp: data.quote.img_hp,
          img_cp: data.quote.img_cp,
          plots: data.quote.plots,
          plot_x: data.quote.plot_x,
          plot_y: data.quote.plot_y,
          plot_cp: data.quote.plot_cp,
          delivery: data.quote.delivery,
          delivery_type: data.quote.delivery_type,
          delivery_cp: data.quote.delivery_cp,
          img_discount: data.quote.img_discount,
          img_cost: data.quote.img_cost,
          quote_cost: data.quote.quote_cost,
          map_notes: data.quote.map_notes,
          img_notes: data.quote.img_notes,
          plot_mount: data.quote.plot_mount,
          camera: data.quote.camera,
          supplemental_survey: data.quote.supplemental_survey,
          plain_gc: data.quote.plain_gc,
          description: data.quote.description,
          sales_tax: data.quote.sales_tax,
          unit_price: data.quote.unit_price,
          shipping: data.quote.shipping,
          discount: data.quote.discount,
          quote_type: data.quote.quote_type,
          editing: this.props.match.path === "/quotes/:id/edit",
        });
      });
    }
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.state.editing ? this.updateQuote() : this.addQuote();
  };

  updateQuote() {
    const quote = {
      bonus_perc: this.state.bonus_perc,
      add_services: this.state.add_services,
      add_services_price: this.state.add_services_price,
      verify_boundary: this.state.verify_boundary,
      rough_grade: this.state.rough_grade,
      stake_building: this.state.stake_building,
      storm_sewer: this.state.storm_sewer,
      underground_utility: this.state.underground_utility,
      stake_electric: this.state.stake_electric,
      stake_concrete: this.state.stake_concrete,
      stake_walls: this.state.stake_walls,
      perform_survey: this.state.perform_survey,
      verify_boundary: this.state.verify_boundary,
      cad_tech: this.state.cad_tech,
      stake_subgrade: this.state.stake_subgrade,
      stake_abc: this.state.stake_abc,
      offsite_staking: this.state.offsite_staking,
      verify_boundary_price: this.state.verify_boundary_price,
      rough_grade_price: this.state.rough_grade_price,
      stake_building_price: this.state.stake_building_price,
      storm_sewer_price: this.state.storm_sewer_price,
      underground_utility_price: this.state.underground_utility_price,
      stake_electric_price: this.state.stake_electric_price,
      stake_concrete_price: this.state.stake_concrete_price,
      stake_walls_price: this.state.stake_walls_price,
      perform_survey_price: this.state.perform_survey_price,
      verify_boundary_price: this.state.verify_boundary_price,
      cad_tech_price: this.state.cad_tech_price,
      stake_subgrade_price: this.state.stake_subgrade_price,
      stake_abc_price: this.state.stake_abc_price,
      offsite_staking_price: this.state.offsite_staking_price,
      registered_land_surveyor: this.state.registered_land_surveyor,
      rls_hrs: this.state.rls_hrs,
      rls_cp: this.state.rls_cp,
      survey_party_chief: this.state.survey_party_chief,
      spc_hrs: this.state.spc_hrs,
      spc_cp: this.state.spc_cp,
      survey_tech: this.state.survey_tech,
      st_hrs: this.state.st_hrs,
      st_cp: this.state.st_cp,
      survey_crew2: this.state.survey_crew2,
      sc2_hrs: this.state.sc2_hrs,
      sc2_cp: this.state.sc2_cp,
      survey_crew3: this.state.survey_crew3,
      sc3_hrs: this.state.sc3_hrs,
      sc3_cp: this.state.sc3_cp,
      rw_plans_tech: this.state.rw_plans_tech,
      rw_plans_hrs: this.state.rw_plans_hrs,
      rw_plans_cp: this.state.rw_plans_cp,
      total_adjustment: this.state.total_adjustment,
      adjustment_reason: this.state.adjustment_reason,
      client_survey: this.state.client_survey,
      user_id: this.props.userId,
      opportunity_id: this.props.oppId,
      specification_id: this.props.specId,
      quote_type: this.props.quoteType,
      flight_sub: this.state.flight_sub,
      flight_quote: this.state.flight_quote,
      flight_markup: this.state.flight_markup,
      flight_cost: this.state.flight_cost,
      flight_notes: this.state.flight_notes,
      panels: this.state.panels,
      abgps: this.state.abgps,
      survey_sub: this.state.survey_sub,
      survey_cost: this.state.survey_cost,
      survey_quote: this.state.survey_quote,
      survey_markup: this.state.survey_markup,
      survey_notes: this.state.survey_notes,
      contact_prints: this.state.contact_prints,
      contact_prints_cp: this.state.contact_prints_cp,
      diapositives: this.state.diapositives,
      diapositives_cp: this.state.diapositives_cp,
      scan_cp: this.state.scan_cp,
      lab_discount: this.state.lab_discount,
      lab_cost: this.state.lab_cost,
      lab_notes: this.state.lab_notes,
      analytics_hp: this.state.analytics_hp,
      analytics_cp: this.state.analytics_cp,
      photogrammetry_cp: this.state.photogrammetry_cp,
      photogrammetry_hp: this.state.photogrammetry_hp,
      xsec: this.state.xsec,
      xsec_cp: this.state.xsec_cp,
      xsec_hp: this.state.xsec_hp,
      cartography_cp: this.state.cartography_cp,
      cartography_hp: this.state.cartography_hp,
      translation: this.state.translation,
      translation_cp: this.state.translation_cp,
      translation_hp: this.state.translation_hp,
      map_discount: this.state.map_discount,
      map_cost: this.state.map_cost,
      img_hp: this.state.img_hp,
      img_cp: this.state.img_cp,
      plots: this.state.plots,
      plot_x: this.state.plot_x,
      plot_y: this.state.plot_y,
      plot_cp: this.state.plot_cp,
      delivery: this.state.delivery,
      delivery_type: this.state.delivery_type,
      delivery_cp: this.state.delivery_cp,
      img_discount: this.state.img_discount,
      img_cost: this.state.img_cost,
      quote_cost: this.state.quote_cost,
      map_notes: this.state.map_notes,
      img_notes: this.state.img_notes,
      plot_mount: this.state.plot_mount,
      camera: this.state.camera,
      supplemental_survey: this.state.supplemental_survey,
      plain_gc: this.state.plain_gc,
      description: this.state.description,
      sales_tax: this.state.sales_tax,
      unit_price: this.state.unit_price,
      shipping: this.state.shipping,
      discount: this.state.discount,
    };
    $.ajax({
      type: "PATCH",
      url: `/quotes/${this.props.match.params.id}`,
      data: { quote: quote },
    })
      .done((data) => {
        console.log("Quote updated!");
      })
      .fail((response) => {
        console.log("did not work");
        alert("Please review the form for errors");
      });
  }

  addQuote() {
    const quote = {
      bonus_perc: this.state.bonus_perc,
      add_services: this.state.add_services,
      add_services_price: this.state.add_services_price,
      verify_boundary: this.state.verify_boundary,
      rough_grade: this.state.rough_grade,
      stake_building: this.state.stake_building,
      storm_sewer: this.state.storm_sewer,
      underground_utility: this.state.underground_utility,
      stake_electric: this.state.stake_electric,
      stake_concrete: this.state.stake_concrete,
      stake_walls: this.state.stake_walls,
      perform_survey: this.state.perform_survey,
      verify_boundary: this.state.verify_boundary,
      cad_tech: this.state.cad_tech,
      stake_subgrade: this.state.stake_subgrade,
      stake_abc: this.state.stake_abc,
      offsite_staking: this.state.offsite_staking,
      verify_boundary_price: this.state.verify_boundary_price,
      rough_grade_price: this.state.rough_grade_price,
      stake_building_price: this.state.stake_building_price,
      storm_sewer_price: this.state.storm_sewer_price,
      underground_utility_price: this.state.underground_utility_price,
      stake_electric_price: this.state.stake_electric_price,
      stake_concrete_price: this.state.stake_concrete_price,
      stake_walls_price: this.state.stake_walls_price,
      perform_survey_price: this.state.perform_survey_price,
      verify_boundary_price: this.state.verify_boundary_price,
      cad_tech_price: this.state.cad_tech_price,
      stake_subgrade_price: this.state.stake_subgrade_price,
      stake_abc_price: this.state.stake_abc_price,
      offsite_staking_price: this.state.offsite_staking_price,
      registered_land_surveyor: this.state.registered_land_surveyor,
      rls_hrs: this.state.rls_hrs,
      rls_cp: this.state.rls_cp,
      survey_party_chief: this.state.survey_party_chief,
      spc_hrs: this.state.spc_hrs,
      spc_cp: this.state.spc_cp,
      survey_tech: this.state.survey_tech,
      st_hrs: this.state.st_hrs,
      st_cp: this.state.st_cp,
      survey_crew2: this.state.survey_crew2,
      sc2_hrs: this.state.sc2_hrs,
      sc2_cp: this.state.sc2_cp,
      survey_crew3: this.state.survey_crew3,
      sc3_hrs: this.state.sc3_hrs,
      sc3_cp: this.state.sc3_cp,
      rw_plans_tech: this.state.rw_plans_tech,
      rw_plans_hrs: this.state.rw_plans_hrs,
      rw_plans_cp: this.state.rw_plans_cp,
      total_adjustment: this.state.total_adjustment,
      adjustment_reason: this.state.adjustment_reason,
      client_survey: this.state.client_survey,
      user_id: this.props.userId,
      opportunity_id: this.props.oppId,
      specification_id: this.props.specId,
      quote_type: this.props.quoteType,
      flight_sub: this.state.flight_sub,
      flight_quote: this.state.flight_quote,
      flight_markup: this.state.flight_markup,
      flight_cost: this.state.flight_cost,
      flight_notes: this.state.flight_notes,
      panels: this.state.panels,
      abgps: this.state.abgps,
      survey_sub: this.state.survey_sub,
      survey_cost: this.state.survey_cost,
      survey_quote: this.state.survey_quote,
      survey_markup: this.state.survey_markup,
      survey_notes: this.state.survey_notes,
      contact_prints: this.state.contact_prints,
      contact_prints_cp: this.state.contact_prints_cp,
      diapositives: this.state.diapositives,
      diapositives_cp: this.state.diapositives_cp,
      scan_cp: this.state.scan_cp,
      lab_discount: this.state.lab_discount,
      lab_cost: this.state.lab_cost,
      lab_notes: this.state.lab_notes,
      analytics_hp: this.state.analytics_hp,
      analytics_cp: this.state.analytics_cp,
      photogrammetry_cp: this.state.photogrammetry_cp,
      photogrammetry_hp: this.state.photogrammetry_hp,
      xsec: this.state.xsec,
      xsec_cp: this.state.xsec_cp,
      xsec_hp: this.state.xsec_hp,
      cartography_cp: this.state.cartography_cp,
      cartography_hp: this.state.cartography_hp,
      translation: this.state.translation,
      translation_cp: this.state.translation_cp,
      translation_hp: this.state.translation_hp,
      map_discount: this.state.map_discount,
      map_cost: this.state.map_cost,
      img_hp: this.state.img_hp,
      img_cp: this.state.img_cp,
      plots: this.state.plots,
      plot_x: this.state.plot_x,
      plot_y: this.state.plot_y,
      plot_cp: this.state.plot_cp,
      delivery: this.state.delivery,
      delivery_type: this.state.delivery_type,
      delivery_cp: this.state.delivery_cp,
      img_discount: this.state.img_discount,
      img_cost: this.state.img_cost,
      quote_cost: this.state.quote_cost,
      map_notes: this.state.map_notes,
      img_notes: this.state.img_notes,
      plot_mount: this.state.plot_mount,
      camera: this.state.camera,
      supplemental_survey: this.state.supplemental_survey,
      plain_gc: this.state.plain_gc,
      description: this.state.description,
      sales_tax: this.state.sales_tax,
      unit_price: this.state.unit_price,
      shipping: this.state.shipping,
      discount: this.state.discount,
    };
    $.post("/quotes", { quote: quote })
      .done((data) => {
        this.props.handleNewQuote(data);
        console.log("Quote Successfully Created!");
      })
      .fail((response) => {
        console.log("did not work");
        alert("Please review the form for errors");
      });
  }

  deleteQuote = () => {
    if (confirm("Are you sure you want to delete this quote?")) {
      $.ajax({
        type: "DELETE",
        url: `/quotes/${this.props.match.params.id}`,
      })
        .done((data) => {
          this.props.history.push("/quotes");
        })
        .fail((response) => {
          console.log("Quote deletion failed!");
        });
    }
  };

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSelectChange(key) {
    return function ({ value }) {
      this.setState({ [key]: value });
    }.bind(this);
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }

  toggleHiddenServices() {
    this.setState({
      isHiddenServices: !this.state.isHiddenServices,
    });
  }

  render() {
    {
      /* quote cost calculation */
    }
    var quoteCost =
      parseFloat(this.state.verify_boundary_price) +
      parseFloat(this.state.rough_grade_price) +
      parseFloat(this.state.stake_building_price) +
      parseFloat(this.state.storm_sewer_price) +
      parseFloat(this.state.underground_utility_price) +
      parseFloat(this.state.stake_electric_price) +
      parseFloat(this.state.stake_concrete_price) +
      parseFloat(this.state.stake_walls_price) +
      parseFloat(this.state.perform_survey_price) +
      parseFloat(this.state.cad_tech_price) +
      parseFloat(this.state.stake_subgrade_price) +
      parseFloat(this.state.stake_abc_price) +
      parseFloat(this.state.offsite_staking_price) +
      parseFloat(this.state.add_services_price);

    {
      /* bonus percentage calculation */
    }
    var bonus_percent = parseFloat(this.state.bonus_perc);
    var bonus_amount = bonus_percent / 100;
    var bonus_total = bonus_amount * quoteCost;

    this.state.quote_cost = Math.ceil(quoteCost);

    return (
      <div className="card">
        <div className="card-body">
          <h4 className="mb-4 text-4xl">
            {this.state.editing
              ? "Update Construction Quote"
              : "Create Construction Quote"}
          </h4>
          <small>
            (* represents a <strong>required field</strong>)
          </small>
          <form onSubmit={this.handleFormSubmit}>
            <div className="row">
              <div className="col-md-4">
                <label>Quote Type *</label>
                <Select
                  name="quote_type"
                  disabled={true}
                  value={this.props.quoteType}
                  options={[
                    { value: "Film", label: "Film" },
                    { value: "Digital", label: "Digital" },
                    { value: "LiDAR", label: "LiDAR" },
                    { value: "Scan", label: "Scan" },
                    { value: "Plot", label: "Plot" },
                    { value: "Survey", label: "Survey" },
                    { value: "Other", label: "Other" },
                    { value: "Construction", label: "Construction" },
                  ]}
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Verify Boundary and Topo and set site control *</label>
                <Select
                  required
                  name="verify_boundary"
                  value={this.state.verify_boundary}
                  onChange={this.handleSelectChange("verify_boundary")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
              <div className="col">
                <label>Cost</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="verify_boundary_price"
                    required
                    value={this.state.verify_boundary_price}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Rough Grade *</label>
                <Select
                  required
                  name="rough_grade"
                  value={this.state.rough_grade}
                  onChange={this.handleSelectChange("rough_grade")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
              <div className="col">
                <label>Cost</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="rough_grade_price"
                    required
                    value={this.state.rough_grade_price}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <p />
            <div className="row">
              <div className="col">
                <label>Stake Building Foundation *</label>
                <Select
                  required
                  name="stake_building"
                  value={this.state.stake_building}
                  onChange={this.handleSelectChange("stake_building")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
              <div className="col">
                <label>Cost</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="stake_building_price"
                    required
                    value={this.state.stake_building_price}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Storm Sewer Staking *</label>
                <Select
                  required
                  name="storm_sewer"
                  value={this.state.storm_sewer}
                  onChange={this.handleSelectChange("storm_sewer")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
              <div className="col">
                <label>Cost</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="storm_sewer_price"
                    required
                    value={this.state.storm_sewer_price}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Underground Utility Staking *</label>
                <Select
                  required
                  name="underground_utility"
                  value={this.state.underground_utility}
                  onChange={this.handleSelectChange("underground_utility")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
              <div className="col">
                <label>Cost</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="underground_utility_price"
                    required
                    value={this.state.underground_utility_price}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Stake Electric *</label>
                <Select
                  required
                  name="stake_electric"
                  value={this.state.stake_electric}
                  onChange={this.handleSelectChange("stake_electric")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
              <div className="col">
                <label>Cost</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="stake_electric_price"
                    required
                    value={this.state.stake_electric_price}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Stake concrete, curb and sidewalk *</label>
                <Select
                  required
                  name="stake_concrete"
                  value={this.state.stake_concrete}
                  onChange={this.handleSelectChange("stake_concrete")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
              <div className="col">
                <label>Cost</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="stake_concrete_price"
                    required
                    value={this.state.stake_concrete_price}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Stake walls and fences *</label>
                <Select
                  required
                  name="stake_walls"
                  value={this.state.stake_walls}
                  onChange={this.handleSelectChange("stake_walls")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
              <div className="col">
                <label>Cost</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="stake_walls_price"
                    required
                    value={this.state.stake_walls_price}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Perform as-built survey for paving and G&D *</label>
                <Select
                  required
                  name="perform_survey"
                  value={this.state.perform_survey}
                  onChange={this.handleSelectChange("perform_survey")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
              <div className="col">
                <label>Cost</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="perform_survey_price"
                    required
                    value={this.state.perform_survey_price}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>CAD Tech *</label>
                <Select
                  required
                  name="cad_tech"
                  value={this.state.cad_tech}
                  onChange={this.handleSelectChange("cad_tech")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
              <div className="col">
                <label>Cost</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="cad_tech_price"
                    required
                    value={this.state.cad_tech_price}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Stake Subgrade *</label>
                <Select
                  required
                  name="stake_subgrade"
                  value={this.state.stake_subgrade}
                  onChange={this.handleSelectChange("stake_subgrade")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
              <div className="col">
                <label>Cost</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="stake_subgrade_price"
                    required
                    value={this.state.stake_subgrade_price}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Stake ABC *</label>
                <Select
                  required
                  name="stake_abc"
                  value={this.state.stake_abc}
                  onChange={this.handleSelectChange("stake_abc")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
              <div className="col">
                <label>Cost</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="stake_abc_price"
                    required
                    value={this.state.stake_abc_price}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Verify Boundary and Topo and set site control *</label>
                <Select
                  required
                  name="offsite_staking"
                  value={this.state.offsite_staking}
                  onChange={this.handleSelectChange("offsite_staking")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
              <div className="col">
                <label>Cost</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="offsite_staking_price"
                    required
                    value={this.state.offsite_staking_price}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Additional Services *</label>
                <Select
                  required
                  name="additional_services"
                  value={this.state.add_services}
                  onChange={this.handleSelectChange("add_services")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
              <div className="col">
                <label>Cost</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="add_services_price"
                    required
                    value={this.state.add_services_price}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <h5>Total Quote Cost</h5>
            <hr />
            <div className="row">
              <div className="col">
                <label>Quote Cost</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="quote_cost"
                    required
                    value={this.state.quote_cost}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <input
              type="submit"
              value={this.state.editing ? "Update" : "Create"}
              className="btn btn-secondary btn-sm"
            />
            <a
              className="btn btn-danger btn-sm"
              onClick={this.props.history.goBack}
            >
              Cancel
            </a>
          </form>
          <br />
        </div>
      </div>
    );
  }
}
