import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default class Drone extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      drone: props.drone,
      project: props.project
    }
  }

  static propTypes = {
    drone: PropTypes.object
  }

  static defaultProps = {
    drone: {}
  }

  componentDidMount() {
    if(this.props.match) {
      $.ajax({
        type: "GET",
        url: `/cascopt/drones/${this.props.match.params.id}`,
        dataType: "JSON"
      }).done((data) => {
        this.setState({ data });
      })
    }
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <div className="row text-center">
            <div className="col">
              <a href={`/cascopt/surveys/${this.state.drone.project_id}`} className="stage-record" >
                Survey
              </a>
            </div>
            <div className="col">
              <a href={`/cascopt/flights/${this.state.drone.project_id}`} className="stage-record" >
                Flight
              </a>
            </div>
            <div className="col">
              <a href={`/cascopt/drones/${this.state.drone.project_id}`} className="stage-record" >
                Drone
              </a>
            </div>
            <div className="col">
              <a href={`/cascopt/scans/${this.state.drone.project_id}`} className="stage-record" >
                Scan
              </a>
            </div>
            <div className="col">
              <a href={`/cascopt/aerotriangulations/${this.state.drone.project_id}`} className="stage-record" >
                AT
              </a>
            </div>
            <div className="col">
              <a href={`/cascopt/lidars/${this.state.drone.project_id}`} className="stage-record" >
                LiDAR
              </a>
            </div>
            <div className="col">
              <a href={`/cascopt/compilations/${this.state.drone.project_id}`} className="stage-record" >
                Compilation
              </a>
            </div>
            <div className="col">
              <a href={`/cascopt/rectifications/${this.state.drone.project_id}`} className="stage-record" >
                Rectification
              </a>
            </div>
            <div className="col">
              <a href={`/cascopt/edits/${this.state.drone.project_id}`} className="stage-record" >
                Edit
              </a>
            </div>
            <div className="col">
              <a href={`/cascopt/plots/${this.state.drone.project_id}`} className="stage-record" >
                Plot
              </a>
            </div>
            <div className="col">
              <a href={`/cascopt/quality_controls/${this.state.drone.project_id}`} className="stage-record" >
                QC
              </a>
            </div>
            <div className="col">
              <a href={`/cascopt/deliveries/${this.state.drone.project_id}`} className="stage-record" >
                Delivery
              </a>
            </div>
            <div className="col">
              <a href={`/cascopt/invoices/${this.state.drone.project_id}`} className="stage-record" >
                Invoice
              </a>
            </div>
          </div>
          <hr />
          <p />
          <a href={`/cascopt/projects/${this.state.drone.project_id}`} className="stage-record" >
            Back to Project Details
          </a>
          <p />
          <a href={`/cascopt/drones`} className="stage-record" >
            Back to UAV Dashboard
          </a>
          <p />
          <h4 className="mb-4 text-4xl">UAV Flight for {this.state.project.job_name || ''} | {this.state.project.client_number || ''}-{this.state.project.job_number || ''}</h4>
          <h5>Due: <input type="date" readOnly format="MM/DD/YYYY" className="form-control-plaintext" value={this.state.project.due_date || ''} /></h5>
          <h5>Client: {this.state.project.client_name || ''}</h5>
          <hr />
            <form>
            <div className="row">
              <div className="col">
                <label>Aircraft</label>
                <input type="text" readOnly
                  value={this.state.drone.aircraft || ''}
                  className="form-control" />
              </div>
              <div className="col">
                <label>Camera</label>
                <input type="text" readOnly
                  value={this.state.drone.camera || ''}
                  className="form-control" />
              </div>
              <div className="col">
                <label>Altitude</label>
                <input type="text" readOnly
                  value={this.state.drone.altitude || ''}
                  className="form-control" />
              </div>
              <div className="col">
                <label>GSD</label>
                <input type="text" readOnly
                  value={this.state.drone.gsd || ''}
                  className="form-control" />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Photo Quality</label>
                <input type="text" readOnly
                  value={this.state.drone.photo_quality || ''}
                  className="form-control" />
              </div>
              <div className="col">
                <label>Processing Software</label>
                <input type="text" readOnly
                  value={this.state.drone.processing_software || ''}
                  className="form-control" />
              </div>
              <div className="col">
                <label>Deliverables</label>
                <input type="text" readOnly
                  value={this.state.drone.deliverables || ''}
                  className="form-control" />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Video</label>
                <input type="text" readOnly
                  value={this.state.drone.video || ''}
                  className="form-control" />
              </div>
              <div className="col">
                <label>Photos Uploaded</label>
                <input type="text" readOnly
                  value={this.state.drone.photos_uploaded || ''}
                  className="form-control" />
              </div>
              <div className="col-md-1">
                <label>No Control</label>
                <input type="text" readOnly
                  value={this.state.drone.no_control || ''}
                  className="form-control" />
              </div>
              <div className="col">
                <label>Obliques</label>
                <input type="text" readOnly
                  value={this.state.drone.obliques || ''}
                  className="form-control" />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-4">
                <label>Flown</label>
                <input type="text" readOnly
                  value={this.state.drone.flown || ''}
                  className="form-control" />
              </div>
              {this.state.drone.flown == 'Yes' &&
              <div className="col-md-4">
                <label>Flight Date</label>
                <input type="date" readOnly
                  format="MM/DD/YYYY" value={this.state.drone.flight_date || ''}
                  className="form-control" />
              </div>}
              <div className="col-md-4">
                <label>Problem w/ Survey</label>
                <input type="text" readOnly
                  value={this.state.drone.problem_survey || ''}
                  className="form-control" />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Notes</label>
                <textarea type="text" readOnly
                  value={this.state.drone.drone_notes || ''}
                  className="form-control" />
              </div>
            </div>
          </form>
          <p />
            <Link to={`/cascopt/drones/${this.state.drone.id}/edit`} >
              <button className="btn btn-sm btn-success">Edit</button>
            </Link>
            <hr />
            <h5>Project Information</h5>
            <hr />
            <div className="row">
              <div className="col">
                <label>Notes from Project</label>
                <textarea type="text" readOnly
                  value={this.state.project.project_notes || ''}
                  className="form-control" />
              </div>
            </div>
            <p />
            <div className="row">
            {this.state.project.survey_notes &&
              <div className="col">
                <label>Notes from Survey</label>
                <textarea type="text" readOnly
                  value={this.state.project.survey_notes || ''}
                  className="form-control" />
              </div>}
            </div>
            <p />
            <div className="row">
            {this.state.project.flights_notes &&
              <div className="col">
                <label>Notes from Flight</label>
                <textarea type="text" readOnly
                  value={this.state.project.flights_notes || ''}
                  className="form-control" />
              </div>}
            </div>
            <p />
            <label>Uploaded Files</label>
            <hr />
            <div className="row">
            {this.state.project.control &&
              <div className="col">
                <label>Control File</label>
                <p />
                <img src={this.state.project.control || ''} />
                <p />
                <a href={this.state.project.control_download} className="btn btn-info btn-sm" >Download</a>
                <br />
                <small>
                  <strong>Filename:</strong><br />
                  {this.state.project.control_file || ''}
                </small>
              </div>}
              {this.state.project.boundaries &&
              <div className="col">
                <label>Boundary File</label>
                <p />
                <img src={this.state.project.boundaries || ''} />
                <p />
                <a href={this.state.project.boundary_download} className="btn btn-info btn-sm" >Download</a>
                <br />
                <small>
                  <strong>Filename:</strong><br />
                  {this.state.project.boundary_file || ''}
                </small>
              </div>}
              {this.state.project.photo &&
              <div className="col">
                <label>Photo ID File</label>
                <p />
                <img src={this.state.project.photo || ''} />
                <p />
                <a href={this.state.project.photo_download} className="btn btn-info btn-sm" >Download</a>
                <br />
                <small>
                  <strong>Filename:</strong><br />
                  {this.state.project.photo_file || ''}
                </small>
              </div>}
              {this.state.project.attachment &&
              <div className="col">
                <label>Misc. File</label>
                <p />
                <img src={this.state.project.attachment || ''} />
                <p />
                <a href={this.state.project.attachment_download} className="btn btn-info btn-sm" >Download</a>
                <br />
                <small>
                  <strong>Filename:</strong><br />
                  {this.state.project.attachment_file || ''}
                </small>
              </div>}
            </div>
        </div>
      </div>
    )
  }
}
