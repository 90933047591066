import React from "react";
import PropTypes from "prop-types";
import update from "immutability-helper";
import Header from "./Header";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";

export default class Estimate extends React.Component {
  static propTypes = {
    estimates: PropTypes.array,
  };

  static defaultProps = {
    estimates: [],
  };

  constructor(props, railsContext) {
    super(props);
    this.state = {
      estimates: this.props.estimates,
      isHidden: true,
      isHiddenNotComplete: false,
      isLoading: false,
    };
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }

  toggleHiddenNotComplete() {
    this.setState({
      isHiddenNotComplete: !this.state.isHiddenNotComplete,
    });
  }

  componentDidMount() {
    if (this.props.match) {
      this.setState({ isLoading: true });
      $.ajax({
        type: "GET",
        url: "/estimates",
        dataType: "JSON",
      }).done((data) => {
        this.setState({ estimates: data, isLoading: false });
      });
    }
  }

  render() {
    const { estimates, isLoading } = this.state;
    if (isLoading) {
      return <div className="loader"></div>;
    }

    return (
      <div>
        <div className="row">
          <div className="col">
            <Header />
          </div>
        </div>
        <button
          onClick={this.toggleHidden.bind(this)}
          title="Click to expand/hide create estimate form"
          className="btn btn-secondary btn-sm"
        >
          {this.state.isHidden ? (
            <span>
              Create Company{" "}
              <i className="fa fa-plus-circle" aria-hidden="true"></i>
            </span>
          ) : (
            <span>
              Close Company Form{" "}
              <i className="fa fa-minus-circle" aria-hidden="true"></i>
            </span>
          )}
        </button>
        <p />
        {!this.state.isHidden && (
          <CompanyForm handleNewCompany={this.addNewCompany} />
        )}
        <p />
        <ReactTable
          key={estimates.id}
          data={estimates}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          defaultPageSize={100}
          style={{
            height: "800px",
            textAlign: "center",
          }}
          columns={[
            {
              Header: "Estimate Info",
              foldable: true,
              columns: [
                {
                  Header: "Estimate Name",
                  accessor: "estimate_name",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={(event) => onChange(event.target.value)}
                      value={filter ? filter.value : ""}
                      style={{
                        width: "100%",
                        color: "black",
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id]
                      .toLowerCase()
                      .includes(filter.value.toLowerCase()),
                  Cell: (props) => (
                    <a
                      href={`/estimates/${props.original.id}`}
                      className="view-record"
                    >
                      {props.original.estimate_name}
                    </a>
                  ),
                },
                {
                  Header: "Client First Name",
                  accessor: "contact.f_name",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={(event) => onChange(event.target.value)}
                      value={filter ? filter.value : ""}
                      style={{
                        width: "100%",
                        color: "black",
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id]
                      .toLowerCase()
                      .includes(filter.value.toLowerCase()),
                },
                {
                  Header: "Client Last Name",
                  accessor: "contact.l_name",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={(event) => onChange(event.target.value)}
                      value={filter ? filter.value : ""}
                      style={{
                        width: "100%",
                        color: "black",
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id]
                      .toLowerCase()
                      .includes(filter.value.toLowerCase()),
                },
                {
                  Header: "Acquisition Type",
                  accessor: "acquisition_type",
                  filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                      return true;
                    }
                    if (filter.value === "digital") {
                      return row[filter.id] == "Digital";
                    }
                    if (filter.value === "digitalLidar") {
                      return row[filter.id] == "Digital w/ LiDAR";
                    }
                    if (filter.value === "film") {
                      return row[filter.id] == "Film";
                    }
                    if (filter.value === "uav") {
                      return row[filter.id] == "UAV";
                    }
                  },
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={(event) => onChange(event.target.value)}
                      style={{ width: "100%" }}
                      value={filter ? filter.value : "all"}
                    >
                      <option value="all">All</option>
                      <option value="digital">Digital</option>
                      <option value="digitalLidar">Digital w/ LiDAR</option>
                      <option value="film">Film</option>
                      <option value="uav">UAV</option>
                    </select>
                  ),
                },
                {
                  Header: "Modify",
                  filterable: false,
                  Cell: (props) => (
                    <a
                      href={`/estimates/${props.original.id}/edit`}
                      className="view-record"
                    >
                      Edit
                    </a>
                  ),
                },
              ],
            },
          ]}
          defaultSorted={[
            {
              id: "name",
              asc: true,
            },
          ]}
          className="-striped -highlight"
        />
      </div>
    );
  }
}
