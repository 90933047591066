import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import CompSubs from './CompSubs';
import CompSubForm from './CompSubForm';
import CompSubView from './CompSubView';

export default (props) => {
  return (
    <Router>
      <div>
        <Route exact path="/cascopt/comp_subs" render={routeProps => <CompSubs {...props} {...routeProps}/>} />
        <Route exact path="/cascopt/comp_subs/:id" render={routeProps => <CompSubView {...props} {...routeProps}/>} />
        <Route path="/cascopt/comp_subs/:id/edit" component={CompSubForm} />
      </div>
    </Router>
  )
}
