/* eslint-disable react/prefer-stateless-function */
import React from 'react';

export default class CompToSub extends React.Component {
  render() {
    return (
      <div>
        <h3 id="header">Projects in Compilation - Sent to Sub</h3>
        <a href="/cascopt/compilations" className="view-record">Compilation Dashboard</a>
      </div>
    );
  }
}
