import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import FlightVendors from "./FlightVendors";
import FlightVendorView from "./FlightVendorView";
import FlightVendorForm from "./FlightVendorForm";

export default (props) => {
  return (
    <Router>
      <div>
        <Route exact path="/flight_vendors" component={FlightVendors} />
        <Route exact path="/flight_vendors/:id" component={FlightVendorView} />
        <Route path="/flight_vendors/:id/edit" component={FlightVendorForm} />
      </div>
    </Router>
  );
};
