import React from 'react';
import PropTypes from 'prop-types';
import CompSubForm from './CompSubForm';
import update from 'immutability-helper';
import Header from './Header';
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";

export default class CompSubs extends React.Component {
  static propTypes = {
    comp_subs: PropTypes.array
  }

  static defaultProps = {
    comp_subs: []
  }

  constructor (props, railsContext) {
    super(props)
    this.state = {
      comp_subs: this.props.comp_subs,
      isHidden: true,
      isHiddenNotComplete: false,
      isHiddenComplete: true,
      isLoading: false
    }
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden
    })
  }

  toggleHiddenNotComplete() {
    this.setState({
      isHiddenNotComplete: !this.state.isHiddenNotComplete
    })
  }

  toggleHiddenComplete() {
    this.setState({
      isHiddenComplete: !this.state.isHiddenComplete
    })
  }

  componentDidMount() {
    if(this.props.match) {
      this.setState({ isLoading: true });
      $.ajax({
        type: "GET",
        url: "/cascopt/comp_subs",
        dataType: "JSON"
      }).done((data) => {
        this.setState({comp_subs: data, isLoading: false });
      })
    }
  }

  addNewCompSub(comp_sub) {
    const comp_subs = update(this.state.comp_subs, {$push: [comp_sub]});
    this.setState({
      comp_subs: comp_subs.sort(function(a,b) {
        return new Date(a.created_at) - new Date(b.created_at);
      })
    });
  }

  render() {
    const { comp_subs, isLoading } = this.state;
    if (isLoading) {
      return <div className="loader"></div>;
    }

    return (
      <div>
        <Header />
        <p />
        <p>Compilation-Sub Not Complete</p>
        <button
          onClick={this.toggleHiddenNotComplete.bind(this)}
          className="btn btn-secondary btn-sm"
        >
          {this.state.isHiddenNotComplete ? "Expand +" : "Hide -"}
        </button>
        <p />
        {!this.state.isHiddenNotComplete && (
          <ReactTable
            key={comp_subs.id}
            data={comp_subs}
            defaultPageSize={20}
            style={{
              height: "800px",
              textAlign: "center",
            }}
            columns={[
              {
                Header: "Comp-Sub Info",
                columns: [
                  {
                    Header: "Job Number",
                    Cell: (props) => (
                      <a
                        href={`/cascopt/comp_subs/${props.original.id}`}
                        className="view-record"
                      >
                        {props.original.project.job_number}
                      </a>
                    ),
                  },
                  {
                    Header: "Job Name",
                    width: 300,
                    Cell: (props) => (
                      <a
                        href={`/cascopt/comp_subs/${props.original.id}`}
                        className="view-record"
                      >
                        {props.original.project.job_name}
                      </a>
                    ),
                  },
                  {
                    Header: "Project Due Date",
                    accessor: "project.due_date",
                  },
                  {
                    Header: "Sub Due Date",
                    accessor: "due_date",
                  },
                  {
                    Header: "To Sub",
                    accessor: "props.original.compilation.to_sub",
                  },
                  {
                    Header: "Sub Name",
                    accessor: "sub_name",
                  },
                  {
                    Header: "Partial Delivered",
                    accessor: "partial_delivered",
                  },
                  {
                    Header: "Models To Sub",
                    accessor: "models_to_sub",
                  },
                  {
                    Header: "Models To Do",
                    accessor: "models_to_do",
                  },
                  {
                    Header: "Map Scale",
                    accessor: "props.original.project.map_scale",
                  },
                  {
                    Header: "Camera",
                    accessor: "props.original.scan.camera_name",
                  },
                  {
                    Header: "Staff",
                    accessor: "props.original.comp.staff",
                  },
                  {
                    Header: "Modify",
                    Cell: (props) => (
                      <a
                        href={`/cascopt/comp_subs/${props.original.id}/edit`}
                        className="view-record"
                      >
                        Edit
                      </a>
                    ),
                  },
                ],
              },
            ]}
            SubComponent={(v) => (
              <div className="text-left" style={{ padding: "10px" }}>
                <b>Client PM:</b>
                <br />
                {v.original.project.client_contact || "None."}
                <br />
                <b>Client #:</b>
                <br />
                {v.original.project.client_number || "None."}
                <br />
                <b>Map Scale:</b>
                <br />
                {v.original.project.map_scale || "None."}
                <br />
                <b>CI:</b>
                <br />
                {v.original.project.ci || "None."}
                <br />
                <b># of Models:</b>
                <br />
                {v.original.project.models || "None."}
              </div>
            )}
            defaultSorted={[
              {
                id: "project.due_date",
                asc: true,
              },
            ]}
            className="-striped -highlight"
          />
        )}
      </div>
    );
  }
}
