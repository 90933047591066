/* eslint-disable react/prefer-stateless-function */
import React from 'react';

export default class Compilation extends React.Component {
  render() {
    return (
      <div>
        <h3 id="header">Projects in Compilation</h3>
        <a href="/cascopt/comp_to_sub" className="view-record">Compilation Dashboard - To Sub</a><br />
      </div>
    )
  }
}
