import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Companies from './Companies';
import CompanyView from './CompanyView';
import CompanyForm from './CompanyForm';

export default (props) => {
  return (
    <Router>
      <div>
        <Route exact path="/companies" component={Companies} />
        <Route exact path="/companies/:id" component={CompanyView} />
        <Route path="/companies/:id/edit" component={CompanyForm} />
      </div>
    </Router>
  )
}
