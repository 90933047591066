import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

export default class CompView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      compilation: props.compilation,
      project: props.project,
      scan: props.scan,
      aerotriangulation: props.aerotriangulation,
      rectification: props.rectification,
      dsm: props.dsm,
      user: props.user,
      quality_control: props.quality_control,
      isHidden: false,
    };
  }

  static propTypes = {
    compilation: PropTypes.object,
  };

  static defaultProps = {
    compilation: {},
  };

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/cascopt/compilations/${this.props.match.params.id}`,
        dataType: "JSON",
      }).done((data) => {
        this.setState({ data });
      });
    }
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }

  render() {
    const problemColor = (color) => {
      const problemYes = this.state.compilation.problem_at == "Yes";
      const problemNo = this.state.compilation.problem_at == "No";

      if (problemYes) {
        return "red";
      }
      if (problemNo) {
        return "green";
      }
    };
    return (
      <div className="card">
        <div className="container">
          <a href={`/cascopt/compilations`} className="stage-record">
            Back to Compilation Dashboard
          </a>
          <hr />
          <Link to={`/cascopt/compilations/${this.state.compilation.id}/edit`}>
            <button className="btn btn-sm btn-success">Edit Compilation</button>
          </Link>
          <p />
          <button
            onClick={this.toggleHidden.bind(this)}
            title="Click to expand/hide create company form"
            className="btn btn-secondary btn-sm"
          >
            {this.state.isHidden ? (
              <span>
                Show Project Details{" "}
                <i className="fa fa-plus-circle" aria-hidden="true"></i>
              </span>
            ) : (
              <span>
                Hide Project Details{" "}
                <i className="fa fa-minus-circle" aria-hidden="true"></i>
              </span>
            )}
          </button>
          <p />
          {!this.state.isHidden && (
            <div id="pt-view-project-block">
              <h5>Project Details</h5>
              <b>Client Rep:</b> {this.state.project.project_manager} |{" "}
              <b>Project Manager:</b> {this.state.project.asst_pm || "n/a"}
              <hr />
              <div className="form-group row">
                <div className="col-md-6">
                  <strong>
                    Estimated Hours: {this.state.project.comp_hours}
                  </strong>
                  <br />
                  <b>Project Type:</b>{" "}
                  {this.state.project.project_type || "n/a"}
                  <br />
                  <b>Job:</b> {this.state.project.job_name || ""} |{" "}
                  {this.state.project.client_number || ""}-
                  {this.state.project.job_number || ""}
                  <br />
                  <b>Project Due Date:</b>{" "}
                  {moment(this.state.project.due_date || "").format(
                    "MM/DD/YYYY"
                  )}
                  <br />
                  <b>Client:</b> {this.state.project.client_name || ""}
                  <br />
                  <b>Deliverables:</b>{" "}
                  {this.state.project.deliverables ||
                    "Contact PM for deliverables info"}
                  <br />
                  {this.state.scan.which_scan == "Primary" && (
                    <div>
                      <b>Camera Number:</b>{" "}
                      {this.state.scan.camera_number || "n/a"}
                      <br />
                      <b>Roll Number:</b> {this.state.scan.roll_number || "n/a"}
                      <br />
                    </div>
                  )}
                  {this.state.scan.which_scan == "Secondary" && (
                    <div>
                      <b>Camera Number (Secondary Scan):</b>{" "}
                      {this.state.scan.second_camera_number || "n/a"}
                      <br />
                      <b>Roll Number (Secondary Scan):</b>{" "}
                      {this.state.scan.second_roll_number || "n/a"}
                      <br />
                    </div>
                  )}
                  <b>Map Scale:</b> {this.state.project.map_scale || ""}
                  <br />
                  <b>GSD:</b> {this.state.project.gsd || ""}
                  <br />
                  <b>Flight Lines:</b> {this.state.project.flight_lines || ""}
                  <br />
                  <b># of Exposures: </b> {this.state.project.exposures || ""}
                </div>
                <div className="col-md-6">
                  {this.state.project.map_type != "Plan Only" && (
                    <div>
                      <b>CI:</b> {this.state.project.ci || "n/a"}
                      <br />
                    </div>
                  )}
                  <b>Map Type:</b> {this.state.project.map_type || ""}
                  <br />
                  <b>Edge Match Job:</b>{" "}
                  <span className="red-legend">
                    {this.state.project.edge_job_number || "No edge job #"}
                  </span>
                  <br />
                  {this.state.user.type == "ProjectManager" ||
                    this.state.user.role == "operations" ||
                    this.state.user.type == "ProductionManager" ||
                    (this.state.user.type == "Admin" && (
                      <div>
                        <b>Estimated Hours per Model:</b>{" "}
                        {this.state.project.est_hrs_model || ""}
                        <br />
                      </div>
                    ))}
                  <b>PRS:</b> {this.state.project.stereo_prs || ""}
                  <br />
                  <b>Actual Flight Date:</b>{" "}
                  {moment(
                    this.state.project.flight_done ||
                      "Project has not been flown"
                  ).format("MM/DD/YYYY") || "Project has not been flown."}
                  <br />
                  <b>Units:</b> {this.state.project.units || "n/a"}
                  <br />
                  <b>Image Sub:</b>{" "}
                  {this.state.aerotriangulation.image_sub || "n/a"}
                  <br />
                  <b>Project Type:</b>{" "}
                  {this.state.project.project_type || "n/a"}
                  <br />
                  <b>LiDAR Received:</b> {this.state.project.lidar_rec || "n/a"}
                  <br />
                  <b>Rectification Staff:</b>{" "}
                  {this.state.rectification.img_staff || "None entered."}
                </div>
              </div>
            </div>
          )}
          <br />
          Last Updated: {this.state.project.last_updated}
          <hr />
          {this.state.project.project_notes && (
            <div>
              <h5>Project Information</h5>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Notes from Project</label>
                  <p>{this.state.project.project_notes || "n/a"}</p>
                </div>
              </div>
              <hr />
            </div>
          )}
          <form>
            <p>
              <strong>
                ***If compilation is not required, mark the 'Is Compilation
                Required?' field to 'No' and submit the form to have the project
                move past Compilation!***
              </strong>
            </p>
            <div className="row">
              <div className="col-md-3">
                <label>Is Compilation Required?</label>
                <p>{this.state.compilation.compilation_required || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Grid Distance</label>
                <p>{this.state.compilation.grid_distance || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Grid Distance (from DSM)</label>
                <p>{this.state.dsm.grid_distance || "n/a"}</p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Current Comp Staff</label>
                <p>{this.state.compilation.current_comp_staff || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Comp Staff</label>
                <p>{this.state.compilation.comp_staff || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>To Sub</label>
                <p>{this.state.compilation.to_sub || "n/a"}</p>
              </div>
            </div>
            <p />
            <div className="form-group row">
              <div className="col-md-3">
                <label>Total Models</label>
                <p>{this.state.compilation.models || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Hours per Model</label>
                <p>{this.state.compilation.hrs_per_model || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Models To Do</label>
                <p>{this.state.compilation.models_todo || "n/a"}</p>
              </div>
              {this.state.user.type == "ProjectManager" ||
                this.state.user.role == "operations" ||
                this.state.user.type == "ProductionManager" ||
                (this.state.user.type == "Admin" && (
                  <div className="col-md-3">
                    <label>Comp Hours (hh:mm)</label>
                    <p>{this.state.compilation.total_comp_hours || "n/a"}</p>
                  </div>
                ))}
            </div>
            <p />
            <div className="form-group row">
              <div className="col-md-3">
                <label>Batch Set</label>
                <p>{this.state.compilation.batch_set || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Setup Quality</label>
                <p>{this.state.compilation.setup_quality || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Readability</label>
                <p>{this.state.compilation.readability || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Model Diagram</label>
                <p>{this.state.compilation.model_diagram || "n/a"}</p>
              </div>
            </div>
            <p />
            <div className="form-group row">
              <div className="col-md-3">
                <label>Compilation Done</label>
                <p>{this.state.compilation.comp_done || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Compilation Done Date</label>
                <p>
                  {moment(
                    this.state.compilation.comp_done_date || "n/a"
                  ).format("MM/DD/YYYY")}
                </p>
              </div>
            </div>
            <p />
            <div className="form-group row">
              <div className="col-md-3">
                <label>Problem w/ AT</label>
                <p
                  style={{
                    color: problemColor(
                      this.state.compilation.problem_at.value
                    ),
                  }}
                >
                  {this.state.compilation.problem_at || "n/a"}
                </p>
              </div>
            </div>
            <p />
            {this.state.quality_control.type == "PostQc" && (
              <div className="form-group row">
                <div className="col-md-3">
                  <label>Post QC - Fixed</label>
                  <p>{this.state.compilation.fixed || "n/a"}</p>
                </div>
              </div>
            )}
            <p />
            <div className="row">
              <div className="col">
                <label>Compilation Notes</label>
                <p>{this.state.project.comp_notes || "n/a"}</p>
              </div>
            </div>
            <p />
            {this.state.project.lidar_scope && (
              <div className="row">
                <div className="col">
                  <label>LiDAR Scope</label>
                  <p>{this.state.project.lidar_scope || "n/a"}</p>
                </div>
              </div>
            )}
            <p />
            <h5>Comp Sub Info</h5>
            <hr />
            <div className="form-group row">
              <div className="col">
                <label>Sub Name</label>
                <p>{this.state.compilation.sub_name || "n/a"}</p>
              </div>
              {this.state.user.type == "ProjectManager" ||
                this.state.user.role == "operations" ||
                this.state.user.type == "ProductionManager" ||
                (this.state.user.type == "Admin" && (
                  <div className="col">
                    <label>Sub Cost</label>
                    <p>{this.state.compilation.sub_alloted_hours || "n/a"}</p>
                  </div>
                ))}
              <div className="col">
                <label>Sub Due Date</label>
                <p>
                  {moment(this.state.compilation.due_date || "n/a").format(
                    "MM/DD/YYYY"
                  )}
                </p>
              </div>
              <div className="col">
                <label>Received From Sub Date</label>
                <p>
                  {moment(this.state.compilation.received_date || "n/a").format(
                    "MM/DD/YYYY"
                  )}
                </p>
              </div>
            </div>
            <p />
            <div className="form-group row">
              <div className="col">
                <label>Models to Sub</label>
                <p>{this.state.compilation.models_to_sub || "n/a"}</p>
              </div>
              <div className="col">
                <label>Models to Do</label>
                <p>{this.state.compilation.models_to_do || "n/a"}</p>
              </div>
              <div className="col">
                <label>Sub Hours</label>
                <p>{this.state.compilation.hours || "n/a"}</p>
              </div>
            </div>
            <p />
            <div className="form-group row">
              <div className="col-md-4">
                <label>Rejected</label>
                <p>{this.state.compilation.rejected || "n/a"}</p>
              </div>
              <div className="col-md-4">
                <label>Rejected Date</label>
                <p>
                  {moment(this.state.compilation.rejected_date || "n/a").format(
                    "MM/DD/YYYY"
                  )}
                </p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Sub Notes</label>
                <p>{this.state.compilation.sub_notes || "n/a"}</p>
              </div>
            </div>
          </form>
          <hr />
          {this.state.project.project_content && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Project Notes from Proposal</label>
                  <p>{this.state.project.project_content || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.mapping_content && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Mapping Notes from Proposal</label>
                  <p>{this.state.project.mapping_content || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.survey_notes && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Notes from Survey</label>
                  <p>{this.state.project.survey_notes || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.flights_notes && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Notes from Flight</label>
                  <p>{this.state.project.flights_notes || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.drone_notes && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Notes from Drone</label>
                  <p>{this.state.project.drone_notes || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.scans_notes && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Notes from Scan</label>
                  <p>{this.state.project.scans_notes || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.aero_notes && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Notes from AT</label>
                  <p>{this.state.project.aero_notes || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.lidar_notes && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Notes from LiDAR</label>
                  <p>{this.state.project.lidar_notes || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.project_purpose && (
            <div>
              <h5>Project Purpose Information</h5>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Project Purpose</label>
                  <p>{this.state.project.project_purpose || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          <label>Uploaded Files</label>
          <hr />
          <div className="row">
            {this.state.project.pm_notes && (
              <div className="col">
                <label>PM File</label>
                <p />
                <img src={this.state.project.pm_notes || ""} />
                <p />
                <a
                  href={this.state.project.pm_file_download}
                  className="btn btn-info btn-sm"
                >
                  Download
                </a>
                <br />
                <small>
                  <strong>Filename:</strong>
                  <br />
                  {this.state.project.pm_file || ""}
                </small>
              </div>
            )}
            {this.state.project.control && (
              <div className="col">
                <label>Control File</label>
                <p />
                <img src={this.state.project.control || ""} />
                <p />
                <a
                  href={this.state.project.control_download}
                  className="btn btn-info btn-sm"
                >
                  Download
                </a>
                <br />
                <small>
                  <strong>Filename:</strong>
                  <br />
                  {this.state.project.control_file || ""}
                </small>
              </div>
            )}
            {this.state.project.boundaries && (
              <div className="col">
                <label>Boundary File</label>
                <p />
                <img src={this.state.project.boundaries || ""} />
                <p />
                <a
                  href={this.state.project.boundary_download}
                  className="btn btn-info btn-sm"
                >
                  Download
                </a>
                <br />
                <small>
                  <strong>Filename:</strong>
                  <br />
                  {this.state.project.boundary_file || ""}
                </small>
              </div>
            )}
            {this.state.project.photo && (
              <div className="col">
                <label>Photo ID File</label>
                <p />
                <img src={this.state.project.photo || ""} />
                <p />
                <a
                  href={this.state.project.photo_download}
                  className="btn btn-info btn-sm"
                >
                  Download
                </a>
                <br />
                <small>
                  <strong>Filename:</strong>
                  <br />
                  {this.state.project.photo_file || ""}
                </small>
              </div>
            )}
            {this.state.project.attachment && (
              <div className="col">
                <label>Misc. File</label>
                <p />
                <img src={this.state.project.attachment || ""} />
                <p />
                <a
                  href={this.state.project.attachment_download}
                  className="btn btn-info btn-sm"
                >
                  Download
                </a>
                <br />
                <small>
                  <strong>Filename:</strong>
                  <br />
                  {this.state.project.attachment_file || ""}
                </small>
              </div>
            )}
          </div>
          <br />
          <Link to={`/cascopt/compilations/${this.state.compilation.id}/edit`}>
            <button className="btn btn-sm btn-success">Edit Compilation</button>
          </Link>
        </div>
      </div>
    );
  }
}
