import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

export default class Aerotriangulation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aerotriangulation: props.aerotriangulation,
      project: props.project,
      scan: props.scan,
      flight: props.flight,
      rectification: props.rectification,
      isHidden: false,
    };
  }

  static propTypes = {
    aerotriangulation: PropTypes.object,
  };

  static defaultProps = {
    aerotriangulation: {},
  };

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/cascopt/aerotriangulations/${this.props.match.params.id}`,
        dataType: "JSON",
      }).done((data) => {
        this.setState({ data });
      });
    }
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }

  render() {
    const problemColor = (color) => {
      const problemYes = this.state.aerotriangulation.problem_at == "Yes";
      const problemNo = this.state.aerotriangulation.problem_at == "No";

      if (problemYes) {
        return "red";
      }
      if (problemNo) {
        return "green";
      }
    };
    return (
      <div className="card">
        <div className="container">
          <a href={`/cascopt/aerotriangulations`} className="stage-record">
            Back to AT Dashboard
          </a>
          <hr />
          <Link
            to={`/cascopt/aerotriangulations/${this.state.aerotriangulation.id}/edit`}
          >
            <button className="btn btn-sm btn-success">Edit AT</button>
          </Link>
          <p />
          <button
            onClick={this.toggleHidden.bind(this)}
            title="Click to expand/hide create company form"
            className="btn btn-secondary btn-sm"
          >
            {this.state.isHidden ? (
              <span>
                Show Project Details{" "}
                <i className="fa fa-plus-circle" aria-hidden="true"></i>
              </span>
            ) : (
              <span>
                Hide Project Details{" "}
                <i className="fa fa-minus-circle" aria-hidden="true"></i>
              </span>
            )}
          </button>
          <p />
          {!this.state.isHidden && (
            <div id="pt-view-project-block">
              <h5>Project Details</h5>
              <b>Client Rep:</b> {this.state.project.project_manager} |{" "}
              <b>Project Manager:</b> {this.state.project.asst_pm || "n/a"}
              <hr />
              <div className="form-group row">
                <div className="col-md-6">
                  <b>Job:</b> {this.state.project.job_name || ""} |{" "}
                  {this.state.project.client_number || ""}-
                  {this.state.project.job_number || ""}
                  <br />
                  <b>Edge Job #:</b>{" "}
                  {this.state.project.edge_job_number || "n/a"}
                  <br />
                  <b>Due:</b>{" "}
                  {moment(this.state.project.due_date || "").format(
                    "MM/DD/YYYY"
                  )}
                  <br />
                  <b>Deliverables:</b>{" "}
                  {this.state.project.deliverables ||
                    "Contact PM for deliverables info"}
                  <br />
                  <b>Flight Date:</b>{" "}
                  {moment(this.state.project.est_flight_date).format(
                    "MM/DD/YYYY"
                  )}
                  <br />
                  <b>Map Scale:</b> {this.state.project.map_scale || "n/a"}
                  <br />
                  <b>Map Type:</b> {this.state.project.map_type || "n/a"}
                  <br />
                  {this.state.project.map_type != "Plan Only" && (
                    <div>
                      <b>CI:</b> {this.state.project.ci || "n/a"}
                      <br />
                    </div>
                  )}
                  {this.state.scan.which_scan == "Primary" && (
                    <div>
                      <b>Camera Number:</b>{" "}
                      {this.state.scan.camera_number || "n/a"}
                      <br />
                      <b>Roll Number:</b> {this.state.scan.roll_number || "n/a"}
                      <br />
                    </div>
                  )}
                  {this.state.scan.which_scan == "Secondary" && (
                    <div>
                      <b>Camera Number (Secondary Scan):</b>{" "}
                      {this.state.scan.second_camera_number || "n/a"}
                      <br />
                      <b>Roll Number (Secondary Scan):</b>{" "}
                      {this.state.scan.second_roll_number || "n/a"}
                      <br />
                    </div>
                  )}
                </div>
                <div className="col-md-6">
                  <b>Image Res:</b> {this.state.scan.scan_res || "n/a"}
                  <br />
                  <b># of Exposures:</b> {this.state.project.exposures || "n/a"}
                  <br />
                  <b>Image Sub:</b>{" "}
                  {this.state.aerotriangulation.image_sub || "n/a"}
                  <br />
                  <b>Actual Flight Date:</b>{" "}
                  {moment(
                    this.state.project.flight_done ||
                      "Project has not been flown"
                  ).format("MM/DD/YYYY")}
                  <br />
                  <b>AT To Sub:</b>{" "}
                  {this.state.aerotriangulation.at_sub || "n/a"}
                  <br />
                  <b>Units:</b> {this.state.project.units}
                  <br />
                  <b>Project Type:</b>{" "}
                  {this.state.project.project_type || "n/a"}
                  <br />
                  <b>Rectification Staff:</b>{" "}
                  {this.state.rectification.img_staff ||
                    "No rectification staff provided."}
                </div>
              </div>
            </div>
          )}
          <br />
          Last Updated: {this.state.project.last_updated}
          <hr />
          <form>
            <div className="row">
              <div className="col-md-3">
                <label>DSM Required</label>
                <p>{this.state.aerotriangulation.dsm_required || "n/a"}</p>
              </div>
            </div>
            <p />
            {this.state.aerotriangulation.comp_sub_notes && (
              <div className="row">
                <div className="col-md-3">
                  <label>CompSub Notes</label>
                  <p>{this.state.aerotriangulation.comp_sub_notes || "n/a"}</p>
                </div>
              </div>
            )}
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>AT Staff</label>
                <p>{this.state.aerotriangulation.at_staff || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>AT Started</label>
                <p>{this.state.aerotriangulation.at_started || ""}</p>
              </div>
              <div className="col-md-3">
                <label>AT On Hold</label>
                <p>{this.state.aerotriangulation.on_hold || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>GSD Results</label>
                <p>{this.state.aerotriangulation.gsd_results || "n/a"}</p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Photos Reviewed</label>
                <p>{this.state.aerotriangulation.photos_reviewed || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Blinds</label>
                <p>{this.state.aerotriangulation.blinds || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Enough Coverage</label>
                <p>{this.state.aerotriangulation.enough_coverage || ""}</p>
              </div>
              <div className="col-md-3">
                <label>Control Results</label>
                <p>{this.state.aerotriangulation.control_results || ""}</p>
              </div>
            </div>
            <p />
            {this.state.aerotriangulation.at_sub == "Yes" && (
              <div>
                <div className="row">
                  <div className="col-md-3">
                    <label>AT To Sub</label>
                    <p>{this.state.aerotriangulation.at_sub || "n/a"}</p>
                  </div>
                  <div className="col-md-3">
                    <label>AT Sub, Date Sent</label>
                    <p>
                      {moment(
                        this.state.aerotriangulation.at_sub_date_sent || "n/a"
                      ).format("MM/DD/YYYY")}
                    </p>
                  </div>
                  <div className="col-md-3">
                    <label>AT Sub, Date Received</label>
                    <p>
                      {moment(
                        this.state.aerotriangulation.at_sub_date_rec || "n/a"
                      ).format("MM/DD/YYYY")}
                    </p>
                  </div>
                  <div className="col-md-3">
                    <label>AT QC Staff</label>
                    <p>{this.state.aerotriangulation.at_sub_name || "n/a"}</p>
                  </div>
                  <div className="col-md-3">
                    <label>AT Sub Done</label>
                    <p>{this.state.aerotriangulation.at_sub_done || "n/a"}</p>
                  </div>
                </div>
              </div>
            )}
            <p />
            {this.state.flight.second_flight == "Yes" &&
              this.state.flight.imagery_lidar == "Imagery" && (
                <div>
                  <hr />
                  <h5>
                    Secondary AT Information (if project has 2 imagery flights)
                  </h5>
                  <hr />
                  <div className="row">
                    <div className="col-md-3">
                      <label>AT Staff</label>
                      <p>
                        {this.state.aerotriangulation.second_at_staff || "n/a"}
                      </p>
                    </div>
                    <div className="col-md-3">
                      <label>Photos Reviewed</label>
                      <p>
                        {this.state.aerotriangulation.second_photos_reviewed ||
                          "n/a"}
                      </p>
                    </div>
                  </div>
                  <p />
                  <div className="row">
                    <div className="col-md-3">
                      <label>Blinds</label>
                      <p>
                        {this.state.aerotriangulation.second_blinds || "n/a"}
                      </p>
                    </div>
                    <div className="col-md-3">
                      <label>Enough Coverage</label>
                      <p>
                        {this.state.aerotriangulation.second_enough_coverage ||
                          "n/a"}
                      </p>
                    </div>
                    <div className="col-md-3">
                      <label>Control Results</label>
                      <p>
                        {this.state.aerotriangulation.second_control_results ||
                          "n/a"}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>AT Done</label>
                <p>{this.state.aerotriangulation.at_done || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>AT Done Date</label>
                <p>
                  {moment(
                    this.state.aerotriangulation.at_done_date || "n/a"
                  ).format("MM/DD/YYYY")}
                </p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Problem w/ AT</label>
                <p
                  style={{
                    color: problemColor(
                      this.state.aerotriangulation.problem_at.value
                    ),
                  }}
                >
                  {this.state.aerotriangulation.problem_at || "n/a"}
                </p>
              </div>
              {this.state.aerotriangulation.problem_at == "Yes" && (
                <div className="col-md-3">
                  <label>Problem</label>
                  <p>{this.state.aerotriangulation.problem || "n/a"}</p>
                </div>
              )}
            </div>
            <p />
            {/* start of lidar fields */}
            <div className="row">
              <div className="col-md-3">
                <label>Registration Staff</label>
                <p>{this.state.aerotriangulation.lidar_staff || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>LiDAR Started</label>
                <p>{this.state.aerotriangulation.lidar_started || ""}</p>
              </div>
              <div className="col-md-3">
                <label>LiDAR On Hold</label>
                <p>{this.state.aerotriangulation.lidar_on_hold || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>SA Results</label>
                <p>{this.state.aerotriangulation.sa_results || "n/a"}</p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Total Tiles</label>
                <p>{this.state.aerotriangulation.total_tiles || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>LiDAR Blinds</label>
                <p>{this.state.aerotriangulation.lidar_blinds || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>LiDAR Enough Coverage</label>
                <p>
                  {this.state.aerotriangulation.lidar_enough_coverage || ""}
                </p>
              </div>
              <div className="col-md-3">
                <label>Registration Results</label>
                <p>{this.state.aerotriangulation.registration_results || ""}</p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>LiDAR Done</label>
                <p>{this.state.aerotriangulation.lidar_done || "n/a"}</p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Problem w/ LiDAR</label>
                <p>{this.state.aerotriangulation.problem_lidar || "n/a"}</p>
              </div>
            </div>
            <p />
            {/* end of lidar fields */}
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Image Sub</label>
                <p>{this.state.aerotriangulation.image_sub || "n/a"}</p>
              </div>
              {this.state.aerotriangulation.image_sub == "Yes" && (
                <div className="col-md-3">
                  <label>Image Sub Name</label>
                  <p>{this.state.aerotriangulation.image_sub_name || "n/a"}</p>
                </div>
              )}
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>AT Notes</label>
                <p>{this.state.project.aero_notes || "n/a"}</p>
              </div>
            </div>
            <p />
            {this.state.project.lidar_scope && (
              <div className="row">
                <div className="col">
                  <label>LiDAR Scope</label>
                  <p>{this.state.aerotriangulation.lidar_scope || "n/a"}</p>
                </div>
              </div>
            )}
          </form>
          <hr />
          {this.state.project.project_notes && (
            <div>
              <h5>Project Information</h5>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Notes from Project</label>
                  <p>{this.state.project.project_notes || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.survey_notes && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Notes from Survey</label>
                  <p>{this.state.project.survey_notes || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.flights_notes && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Notes from Flight</label>
                  <p>{this.state.project.flights_notes || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.drone_notes && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Notes from Drone</label>
                  <p>{this.state.project.drone_notes || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.scans_notes && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Notes from Scan</label>
                  <p>{this.state.project.scans_notes || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.project_purpose && (
            <div>
              <h5>Project Purpose Information</h5>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Project Purpose</label>
                  <p>{this.state.project.project_purpose || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
        </div>
      </div>
    );
  }
}
