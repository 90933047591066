import React from 'react';
import PropTypes from 'prop-types';
import { FormErrors } from './FormErrors';
import update from 'immutability-helper';
import { validations } from '../utils/validations';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import moment from 'moment';

export default class EditForm extends React.Component {
  static propTypes = {
    handleNewEdit: PropTypes.func
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      no_edit_required: "",
      xml_generated: "No",
      problem_comp: "No",
      edit_status: "",
      edit_status_options: [
        { value: "Currently Editing", label: "Currently Editing" },
        { value: "Finished Editing", label: "Finished Editing" },
        { value: "Hold for LiDAR data", label: "Hold for LiDAR data" },
        { value: "Hold for more models", label: "Hold for more models" },
        { value: "Hold per Ben", label: "Hold per Ben" },
        { value: "Hold per Sales", label: "Hold per Sales" },
        { value: "Holding for check", label: "Holding for check" },
        { value: "Holding for imagery", label: "Holding for imagery" },
        { value: "In Progress", label: "In Progress" },
        { value: "Needs Translated", label: "Needs Translated" },
        { value: "Needs XML Surface", label: "Needs XML Surface" },
        { value: "Next", label: "Next" },
        { value: "On Hold", label: "On Hold" },
        { value: "Post QC", label: "Post QC" },
      ],
      type: { value: '', valid: true },
      edit_date_done: { value: "", valid: false },
      client_number: { value: "", valid: false },
      client_name: { value: "", valid: false },
      job_number: { value: "", valid: false },
      due_date: { value: "", valid: false },
      project_id: { value: "", valid: false },
      edit_staff: { value: "", valid: false },
      edit_done: "No",
      translated: "No",
      edit_check: "No",
      total_hours: { value: "", valid: false },
      project_notes: { value: "", valid: true },
      edit_notes: { value: "", valid: true },
      fixed: '',
      formErrors: {},
      formValid: false,
      editing: false,
    };
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
  }

  static formValidations = {
    project_id: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    total_hours: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    edit_staff: [
      (s) => { return (validations.checkMinLength(s, 3)) }
    ],
    edit_date_done: [
      (s) => { return(validations.checkMinLength(s, 3)) }
    ],
    project_notes: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    edit_notes: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ]
  }

  componentDidMount() {
    if(this.props.match) {
    $.ajax({
      type: "GET",
      url: `/cascopt/edits/${this.props.match.params.id}`,
      dataType: "JSON"
    }).done((data) => {
      this.setState({
        type: { value: data.quality_control.type, valid: true },
        no_edit_required: data.edit.no_edit_required,
        xml_generated: data.edit.xml_generated,
        problem_comp: data.edit.problem_comp,
        edit_status: data.edit.edit_status,
        edit_date_done: { value: data.edit.edit_date_done, valid: true },
        client_number: { value: data.project.client_number, valid: true },
        client_name: { value: data.project.client_name, valid: true },
        job_number: { value: data.project.job_number, valid: true },
        due_date: { value: data.project.due_date, valid: true },
        project_id: { value: data.edit.project_id, valid: true },
        edit_staff: { value: data.edit.edit_staff, valid: true },
        total_hours: { value: data.edit.total_hours, valid: true },
        edit_done: data.edit.edit_done,
        translated: data.edit.translated,
        edit_check: data.edit.edit_check,
        project_notes: {value: data.project.project_notes, valid: true},
        edit_notes: {value: data.project.edit_notes, valid: true},
        fixed: data.edit.fixed,
        editing: this.props.match.path === '/cascopt/edits/:id/edit'
      });
    });
   }
  }

  handleUserInput = (fieldName, fieldValue, validations) => {
    const newFieldState = update(this.state[fieldName],
                                  {value: {$set: fieldValue}});
    this.setState({[fieldName]: newFieldState},
                  () => { this.validateField(fieldName, fieldValue, validations) });
  }

  validateField (fieldName, fieldValue, validations) {
    let fieldValid;

    let fieldErrors = validations.reduce((errors, v) => {
      let e = v(fieldValue);
      if(e !== '') {
        errors.push(e);
      }
      return(errors);
    }, []);

    fieldValid = fieldErrors.length === 0;

    const newFieldState = update(this.state[fieldName],
                                  {valid: {$set: fieldValid}});

    const newFormErrors = update(this.state.formErrors,
                                  {$merge: {[fieldName]: fieldErrors}});

    this.setState({[fieldName]: newFieldState,
                    formErrors: newFormErrors}, this.validateForm);
  }

  validateForm() {
    this.setState({formValid: this.state.project_id.valid &&
                              this.state.edit_staff.valid &&
                              this.state.total_hours.valid &&
                              this.state.edit_date_done.valid &&
                              this.state.project_notes &&
                              this.state.edit_notes
                            });
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.state.editing ?
      this.updateEdit() :
      this.addEdit();
  }

  updateEdit() {
    const edit = {
      no_edit_required: this.state.no_edit_required,
      xml_generated: this.state.xml_generated,
      problem_comp: this.state.problem_comp,
      edit_status: this.state.edit_status,
      edit_date_done: this.state.edit_date_done.value,
      project_id: this.state.project_id.value,
      edit_staff: this.state.edit_staff.value,
      total_hours: this.state.total_hours.value,
      edit_done: this.state.edit_done,
      translated: this.state.translated,
      edit_check: this.state.edit_check,
      project_notes: this.state.project_notes.value,
      edit_notes: this.state.edit_notes.value,
      fixed: this.state.fixed,
    };
    $.ajax({
      type: "PATCH",
      url: `/cascopt/edits/${this.props.match.params.id}`,
      data: {edit: edit}
    })
    .done((data) => {
      console.log('edit updated!');
      this.resetFormErrors();
      this.props.history.goBack('/edits');
    })
    .fail((response) => {
      alert("Please review the form for errors");
      this.setState({formErrors: response.responseJSON,
        formValid: false
      })
    });
  }

  addEdit() {
    const edit = {
      no_edit_required: this.state.no_edit_required,
      xml_generated: this.state.xml_generated,
      problem_comp: this.state.problem_comp,
      edit_status: this.state.edit_status,
      edit_date_done: this.state.edit_date_done.value,
      project_id: this.state.project_id.value,
      edit_staff: this.state.edit_staff.value,
      total_hours: this.state.total_hours.value,
      edit_done: this.state.edit_done,
      translated: this.state.translated,
      edit_check: this.state.edit_check,
      project_notes: this.state.project_notes.value,
      edit_notes: this.state.edit_notes.value,
      fixed: this.state.fixed,
    };
    $.post('/cascopt/edits', {edit: edit})
    .done((data) => {
      this.props.handleNewEdit(data);
      this.resetFormErrors();
    })
    .fail((response) => {
      this.setState({formErrors: response.responseJSON,
        formValid: false})
    });
  }

  deleteEdit = () => {
    if(confirm("Are you sure you want to delete this Edit?")) {
      $.ajax({
        type: "DELETE",
        url: `/cascopt/edits/${this.props.match.params.id}`,
      })
      .done((data) => {
        this.props.history.push('/cascopt/edits');
        this.resetFormErrors();
      })
      .fail((response) => {
        console.log("Edit deletion failed!");
      });
    }
  }

  resetFormErrors() {
    this.setState({formErrors: {}})
  }

  handleChange(e) {
    this.handleUserInput(e.target.name, e.target.value, EditForm.formValidations[e.target.name]);
  }

  handleSelectChange(key) {
  return function ({ value }) {
      this.setState({[key]: value});
    }.bind(this);
  }

  render() {
    return (
      <div className="container" id="form-card">
        <h4 className="mb-4 text-4xl">
        {this.state.editing ?
          'Update Edit' :
            'Create Edit'} for {this.state.client_number.value} - {this.state.job_number.value}

        </h4>
        <p>Due: {moment(this.state.due_date.value).format("MM/DD/YYYY")}</p>
        <p>Client: {this.state.client_name.value}</p>
        <small>
          (* represents a <strong>required field</strong>)
        </small>
        <FormErrors formErrors = {this.state.formErrors} />
        <form onSubmit={this.handleFormSubmit} >
          <div className="row">
            <div className="col-md-3">
              <label>Is Editing Required?</label>
              <Select
                name="no_edit_required"
                value={this.state.no_edit_required}
                onChange={this.handleSelectChange("no_edit_required")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col-md-3">
              <label>Edit Staff *</label>
              <input name="edit_staff" placeholder="Edit Staff"
                value={this.state.edit_staff.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
            <div className="col-md-3">
              <label>Edit Status *</label>
              <Select.Creatable
                name="edit_status"
                value={this.state.edit_status}
                onChange={this.handleSelectChange("edit_status")}
                options={this.state.edit_status_options}
              />
            </div>
            <div className="col-md-3">
              <label>Edit Check *</label>
              <Select
                name="edit_check"
                value={this.state.edit_check}
                onChange={this.handleSelectChange("edit_check")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
            <div className="col-md-3">
              <label>Translated *</label>
              <Select
                name="translated"
                value={this.state.translated}
                onChange={this.handleSelectChange("translated")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col-md-3">
              <label>XML generated for this project?</label>
              <Select
                name="xml_generated"
                value={this.state.xml_generated}
                onChange={this.handleSelectChange("xml_generated")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
            <div className="col-md-3">
              <label>Problem w/ Compilation</label>
              <Select
                name="problem_comp"
                value={this.state.problem_comp}
                onChange={this.handleSelectChange("problem_comp")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col-md-3">
              <label>Edit Done *</label>
              <Select
                name="edit_done"
                value={this.state.edit_done}
                onChange={this.handleSelectChange("edit_done")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
            {this.state.edit_done == 'Yes' &&
            <div className="col-md-3">
              <label>Edit Date Done *</label>
              <input type="date" name="edit_date_done"
                value={this.state.edit_date_done.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>}
          </div>
          <p />
          {this.state.type.value == "PostQc" && 
          <div className="row">
            <div className="col-md-2">
              <label>Post QC - Fixed</label>
              <Select
                name="fixed"
                value={this.state.fixed}
                onChange={this.handleSelectChange("fixed")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
          </div>}
          <p />
          <div className="row">
            <div className="col">
              <label>Notes *</label>
              <textarea name="edit_notes" placeholder="Notes"
                value={this.state.edit_notes.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col">
              <label>Project Notes</label>
              <textarea name="project_notes" placeholder="Project Notes"
                value={this.state.project_notes.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
          </div>
          <p />
          <input type="submit"
            value={this.state.editing ? 'Update' : 'Create'} className="btn btn-secondary btn-sm"
          />
          <a className="btn btn-danger btn-sm" onClick={this.props.history.goBack}>Cancel</a>
        </form>
        <br />
      </div>
    )
  }
}
