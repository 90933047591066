import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";

export default class Issues extends React.Component {
  static propTypes = {
    image_archives: PropTypes.array
  }

  static defaultProps = {
    image_archives: []
  }

  constructor (props, railsContext) {
    super(props)
    this.state = {
      image_archives: this.props.image_archives,
      isHidden: true,
      isHiddenNotComplete: false,
      isLoading: false
    }
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden
    })
  }

  toggleHiddenNotComplete() {
    this.setState({
      isHiddenNotComplete: !this.state.isHiddenNotComplete
    })
  }

  componentDidMount() {
    if(this.props.match) {
      this.setState({ isLoading: true });
      $.ajax({
        type: "GET",
        url: "/image_archives",
        dataType: "JSON"
      }).done((data) => {
        this.setState({image_archives: data, isLoading: false });
      })
    }
  }

  render () {
    const { image_archives, isLoading } = this.state;
    if (isLoading) {
      return <div className="loader"></div>;
    }

    return (
      <div>
        <Header/>
         <p />
        <button onClick={this.toggleHiddenNotComplete.bind(this)} className="btn btn-secondary btn-sm" >
             {this.state.isHiddenNotComplete ?
               'Show Image Archives +' :
               'Hide Image Archives -'}
        </button>
        <p />
        {!this.state.isHiddenNotComplete && <ReactTable key={image_archives.id}
          data={ image_archives }
          defaultPageSize={20}
          style={{
            height: "800px",
            textAlign: "center"
          }}
          columns={[
            {
              Header: "Image Archives",
              columns: [
                {
                  Header: "Job Name",
                  accessor: "project.job_name"
                },
                {
                  Header: "Roll #",
                  accessor: "roll_number"
                },
                {
                  Header: "Options",
                  Cell: props => <a href={`/cascopt/projects/${props.original.id}`} className="view-record" >View</a>
                }
              ]
            }
          ]}
          className="-striped -highlight"
        />}
      </div>
    )
  }
}
