import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

export default class Lidar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lidar: props.lidar,
      project: props.project,
      flight: props.flight,
      isHidden: false,
    };
  }

  static propTypes = {
    lidar: PropTypes.object,
  };

  static defaultProps = {
    lidar: {},
  };

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/cascopt/lidars/${this.props.match.params.id}`,
        dataType: "JSON",
      }).done((data) => {
        this.setState({ data });
      });
    }
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }

  render() {
    return (
      <div className="card">
        <div className="container">
          <a href={`/cascopt/lidars`} className="stage-record">
            Back to LiDAR Dashboard
          </a>
          <hr />
          <Link to={`/cascopt/lidars/${this.state.lidar.id}/edit`}>
            <button className="btn btn-sm btn-success">Edit LiDAR</button>
          </Link>
          <p />
          <button
            onClick={this.toggleHidden.bind(this)}
            title="Click to expand/hide create company form"
            className="btn btn-secondary btn-sm"
          >
            {this.state.isHidden ? (
              <span>
                Show Project Details{" "}
                <i className="fa fa-plus-circle" aria-hidden="true"></i>
              </span>
            ) : (
              <span>
                Hide Project Details{" "}
                <i className="fa fa-minus-circle" aria-hidden="true"></i>
              </span>
            )}
          </button>
          <p />
          {!this.state.isHidden && (
            <div id="pt-view-project-block">
              <h5>Project Details</h5>
              <b>Client Rep:</b> {this.state.project.project_manager} |{" "}
              <b>Project Manager:</b> {this.state.project.asst_pm || "n/a"}
              <hr />
              <div className="form-group row">
                <div className="col-md-6">
                  <b>Project Type:</b>{" "}
                  {this.state.project.project_type || "n/a"}
                  <br />
                  <b>Job:</b> {this.state.project.job_name || ""} |{" "}
                  {this.state.project.client_number || ""}-
                  {this.state.project.job_number || ""}
                  <br />
                  <b>Job Due Date:</b>{" "}
                  {moment(this.state.project.due_date || "").format(
                    "MM/DD/YYYY"
                  )}
                  <br />
                  <b>Client:</b> {this.state.project.client_name || ""}
                  <br />
                  <b>Deliverables:</b>{" "}
                  {this.state.project.deliverables ||
                    "Contact PM for deliverables info"}
                  <br />
                  <b>Map Scale:</b> {this.state.project.map_scale || ""}
                  <br />
                  <b>GSD:</b> {this.state.project.gsd || ""}
                  <br />
                  <b>GPC Sent:</b> {this.state.flight.sent_gpc || ""}
                </div>
                <div className="col-md-6">
                  <b>CI:</b> {this.state.project.ci || "n/a"}
                  <br />
                  <b>Map Type:</b> {this.state.project.map_type || ""}
                  <br />
                  <b>Edge Match Job:</b>{" "}
                  <span className="red-legend">
                    {this.state.project.edge_job_number || "No edge job #"}
                  </span>
                  <br />
                  <b>Actual Flight Date:</b>{" "}
                  {moment(
                    this.state.project.flight_done ||
                      "Project has not been flown"
                  ).format("MM/DD/YYYY") || "Project has not been flown."}
                  <br />
                  <b>Units:</b> {this.state.project.units || "n/a"}
                </div>
              </div>
            </div>
          )}
          <br />
          Last Updated: {this.state.project.last_updated}
          <hr />
          <form>
            <div className="row">
              <div className="col-md">
                <label>Is LiDAR Required?</label>
                <p>{this.state.lidar.lidar_required || "n/a"}</p>
              </div>
              <div className="col-md">
                <label>PPSM</label>
                <p>{this.state.lidar.ppsm || "n/a"}</p>
              </div>
              <div className="col-md">
                <label>Macro Ran</label>
                <p>{this.state.lidar.macro_ran || "n/a"}</p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md">
                <label>Current LiDAR Staff</label>
                <p>{this.state.lidar.current_lidar_staff || "n/a"}</p>
              </div>
              <div className="col-md">
                <label>LiDAR Staff</label>
                <p>{this.state.lidar.lidar_staff || "n/a"}</p>
              </div>
              <div className="col-md">
                <label>To Sub</label>
                <p>{this.state.lidar.to_sub || "n/a"}</p>
              </div>
            </div>
            <p />
            <div className="form-group row">
              <div className="col-md">
                <label>Total Tiles</label>
                <p>{this.state.lidar.total_tiles || "n/a"}</p>
              </div>
              <div className="col-md">
                <label>Tiles Remaining</label>
                <p>{this.state.lidar.tiles_remaining || "n/a"}</p>
              </div>
              <div className="col-md">
                <label>Cost Quote from Sub</label>
                <p>{this.state.lidar.actual_hours || "n/a"}</p>
              </div>
              <div className="col-md">
                <label>LiDAR Hours (hh:mm)</label>
                <p>{this.state.lidar.total_hours || "n/a"}</p>
              </div>
            </div>
            <p />
            <div className="form-group row">
              <div className="col-md">
                <label>LiDAR File Location</label>
                <p>{this.state.lidar.file_location || "n/a"}</p>
              </div>
              <div className="col-md">
                <label>Macro File Location</label>
                <p>{this.state.lidar.macro_file_location || "n/a"}</p>
              </div>
              <div className="col-md">
                <label>Ground Only LAS Ortho Created</label>
                <p>{this.state.lidar.ground_only_las_ortho_created || "n/a"}</p>
              </div>
              <div className="col-md">
                <label>Ground Only LAS Ortho Location</label>
                <p>{this.state.lidar.ground_only_ortho_location || "n/a"}</p>
              </div>
            </div>
            <p />
            <div className="form-group row">
              <div className="col-md-3">
                <label>LiDAR Cleaned</label>
                <p>{this.state.lidar.cleaned || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Cleaned Date</label>
                <p>{this.state.lidar.cleaned_date || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Classified/Macro</label>
                <p>{this.state.lidar.classified_macro || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>DEM Grid</label>
                <p>{this.state.lidar.dem_grid || "n/a"}</p>
              </div>
            </div>
            <p />
            <div className="form-group row">
              <div className="col-md-3">
                <label>Final LiDAR on ToArchive</label>
                <p>{this.state.lidar.final_lidar || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>LiDAR Done</label>
                <p>{this.state.lidar.complete || "n/a"}</p>
              </div>
            </div>
            <p />
            {this.state.project.lidar_scope && (
              <div className="row">
                <div className="col">
                  <label>LiDAR Scope</label>
                  <p>{this.state.project.lidar_scope || "n/a"}</p>
                </div>
              </div>
            )}
            <p />
            <h5>LiDAR Sub Info</h5>
            <hr />
            <div className="form-group row">
              <div className="col">
                <label>Sub Name</label>
                <p>{this.state.lidar.lidar_sub_name || "n/a"}</p>
              </div>
              <div className="col">
                <label>Date Sent to Sub</label>
                <p>
                  {moment(this.state.lidar.date_sent_to_sub || "n/a").format(
                    "MM/DD/YYYY"
                  )}
                </p>
              </div>
              <div className="col">
                <label>Sub Due Date</label>
                <p>
                  {moment(this.state.lidar.sub_due_date || "n/a").format(
                    "MM/DD/YYYY"
                  )}
                </p>
              </div>
              <div className="col">
                <label>Received From Sub Date</label>
                <p>
                  {moment(
                    this.state.lidar.received_from_sub_date || "n/a"
                  ).format("MM/DD/YYYY")}
                </p>
              </div>
            </div>
            <p />
            <div className="form-group row">
              <div className="col-md-3">
                <label>Tiles to Sub</label>
                <p>{this.state.lidar.tiles_to_sub || "0"}</p>
              </div>
              <div className="col-md-3">
                <label>Tiles Received from Sub</label>
                <p>{this.state.lidar.tiles_received_from_sub || "0"}</p>
              </div>
            </div>
            <p />
            <div className="form-group row">
              <div className="col-md-3">
                <label>Rejected</label>
                <p>{this.state.lidar.rejected || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Rejected Date</label>
                <p>
                  {moment(this.state.lidar.rejected_date || "n/a").format(
                    "MM/DD/YYYY"
                  )}
                </p>
              </div>
              <div className="col-md-3">
                <label>Rejected Reason</label>
                <p>{this.state.lidar.rejected_reason || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Redeliver Date</label>
                <p>
                  {moment(this.state.lidar.redeliver_date || "n/a").format(
                    "MM/DD/YYYY"
                  )}
                </p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-6">
                <label>Sub Notes</label>
                <p>{this.state.lidar.sub_notes || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Surface File(s) Location</label>
                <p>{this.state.lidar.comments || "n/a"}</p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-6">
                <label>Deliverables</label>
                <p>{this.state.lidar.blocks || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Support Files</label>
                <p>{this.state.lidar.stringing || "n/a"}</p>
              </div>
            </div>
          </form>
          <hr />
          {this.state.project.project_notes && (
            <div>
              <h5>Project Information</h5>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Notes from Project</label>
                  <p>{this.state.project.project_notes}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.survey_notes && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Notes from Survey</label>
                  <p>{this.state.project.survey_notes}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.flights_notes && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Notes from Flight</label>
                  <p>{this.state.project.flights_notes}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.drone_notes && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Notes from Drone</label>
                  <p>{this.state.project.drone_notes}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.scans_notes && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Notes from Scan</label>
                  <p>{this.state.project.scans_notes}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.aero_notes && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Notes from AT</label>
                  <p>{this.state.project.aero_notes}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.project_purpose && (
            <div>
              <h5>Project Purpose Information</h5>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Project Purpose</label>
                  <p>{this.state.project.project_purpose}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          <label>Uploaded Files</label>
          <hr />
          <div className="row">
            {this.state.project.pm_notes && (
              <div className="col">
                <label>PM File</label>
                <p />
                <img src={this.state.project.pm_notes || ""} />
                <p />
                <a
                  href={this.state.project.pm_file_download}
                  className="btn btn-info btn-sm"
                >
                  Download
                </a>
                <br />
                <small>
                  <strong>Filename:</strong>
                  <br />
                  {this.state.project.pm_file || ""}
                </small>
              </div>
            )}
            {this.state.project.control && (
              <div className="col">
                <label>Control File</label>
                <p />
                <img src={this.state.project.control || ""} />
                <p />
                <a
                  href={this.state.project.control_download}
                  className="btn btn-info btn-sm"
                >
                  Download
                </a>
                <br />
                <small>
                  <strong>Filename:</strong>
                  <br />
                  {this.state.project.control_file || ""}
                </small>
              </div>
            )}
            {this.state.project.boundaries && (
              <div className="col">
                <label>Boundary File</label>
                <p />
                <img src={this.state.project.boundaries || ""} />
                <p />
                <a
                  href={this.state.project.boundary_download}
                  className="btn btn-info btn-sm"
                >
                  Download
                </a>
                <br />
                <small>
                  <strong>Filename:</strong>
                  <br />
                  {this.state.project.boundary_file || ""}
                </small>
              </div>
            )}
            {this.state.project.photo && (
              <div className="col">
                <label>Photo ID File</label>
                <p />
                <img src={this.state.project.photo || ""} />
                <p />
                <a
                  href={this.state.project.photo_download}
                  className="btn btn-info btn-sm"
                >
                  Download
                </a>
                <br />
                <small>
                  <strong>Filename:</strong>
                  <br />
                  {this.state.project.photo_file || ""}
                </small>
              </div>
            )}
            {this.state.project.attachment && (
              <div className="col">
                <label>Misc. File</label>
                <p />
                <img src={this.state.project.attachment || ""} />
                <p />
                <a
                  href={this.state.project.attachment_download}
                  className="btn btn-info btn-sm"
                >
                  Download
                </a>
                <br />
                <small>
                  <strong>Filename:</strong>
                  <br />
                  {this.state.project.attachment_file || ""}
                </small>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
