import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import 'react-select/dist/react-select.css';

export default class QuoteForm extends React.Component {
  static propTypes = {
    handleNewQuote: PropTypes.func
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      opportunity_id: "",
      specification_id: "",
      quote_type: "",
      flight_sub: "",
      flight_sub_options: [
        { value: "Cooper Aerial", label: "Cooper Aerial" },
        { value: "Geomni", label: "Geomni" },
        { value: "Verisk", label: "Verisk" },
        { value: "NAAS", label: "NAAS" },
        { value: "SWAS", label: "SWAS" },
        { value: "SAM", label: "SAM" },
        { value: "CSTI", label: "CSTI" },
        { value: "Atlantic Group", label: "Atlantic Group" },
        { value: "Aero-Graphics", label: "Aero-Graphics" },
        { value: "Keystone Aerial", label: "Keystone Aerial" },
        { value: "American Aerial", label: "American Aerial" },
        { value: "Valley Air", label: "Valley Air" },
        { value: "Radman Aerial", label: "Radman Aerial" },
        { value: "Air Flight SVCS", label: "Air Flight SVCS" },
        {
          value: "Skyview Aerial Photo (South CA)",
          label: "Skyview Aerial Photo (South CA)",
        },
        { value: "Midwest Aerial Photo", label: "Midwest Aerial Photo" },
        { value: "Gammair", label: "Gammair" },
        {
          value: "Richard Crouse and Associates",
          label: "Richard Crouse and Associates",
        },
        {
          value: "I.F. Rooks & Associates, Inc.",
          label: "I.F. Rooks & Associates, Inc.",
        },
        {
          value: "Aerial Data Service, Inc.",
          label: "Aerial Data Service, Inc.",
        },
        { value: "Aerodata, Inc.", label: "Aerodata, Inc." },
        { value: "Lasermap USA", label: "Lasermap USA" },
        { value: "Aerials, Inc.", label: "Aerials, Inc." },
        {
          value: "Gulf Coast Aerial Mapping",
          label: "Gulf Coast Aerial Mapping",
        },
        { value: "Air Photographics, Inc.", label: "Air Photographics, Inc." },
        { value: "Ace Aerial", label: "Ace Aerial" },
        { value: "Georgia Aerial Surveys", label: "Georgia Aerial Surveys" },
        {
          value: "Krawietz Aerial Photo Service",
          label: "Krawietz Aerial Photo Service",
        },
        {
          value: "Continental Aerial Surveys Inc.",
          label: "Continental Aerial Surveys Inc.",
        },
        { value: "Aerial Imaging Inc.", label: "Aerial Imaging Inc." },
        {
          value: "McClintock Land Associates, Inc.",
          label: "McClintock Land Associates, Inc.",
        },
        { value: "Eagle Aerial Solutions", label: "Eagle Aerial Solutions" },
        {
          value: "Rocky Mountain Aerial Surveys",
          label: "Rocky Mountain Aerial Surveys",
        },
        { value: "Blue Skies Consulting", label: "Blue Skies Consulting" },
        { value: "GeoTerra", label: "GeoTerra" },
        { value: "Quantum Spatial", label: "Quantum Spatial" },
        { value: "Aerocon", label: "Aerocon" },
        { value: "Pickett Aerial", label: "Pickett Aerial" },
        { value: "Airborne Sensing", label: "Airborne Sensing" },
        { value: "MAP RES", label: "MAP RES" },
        { value: "Predictive Service", label: "Predictive Service" },
        { value: "Wilson Company", label: "Wilson Company" },
        { value: "KBM, Inc.", label: "KBM, Inc." },
        { value: "Air Flight Service", label: "Air Flight Service" },
        { value: "Lidar Services LLC", label: "Lidar Services LLC" },
        { value: "Eagle Mapping", label: "Eagle Mapping" },
      ],
      flight_quote: "",
      flight_markup: "",
      flight_cost: "",
      flight_notes: "",
      panels: "",
      abgps: "",
      survey_sub: "",
      survey_cost: "",
      survey_quote: "",
      survey_markup: "",
      survey_notes: "",
      contact_prints: "",
      contact_prints_cp: "",
      diapositives: "",
      diapositives_cp: "",
      scan_cp: "",
      lab_discount: "",
      lab_cost: "",
      lab_notes: "",
      analytics_hp: "",
      analytics_cp: "",
      photogrammetry_cp: "",
      photogrammetry_hp: "",
      xsec: "",
      xsec_cp: "",
      xsec_hp: "",
      cartography_cp: "",
      cartography_hp: "",
      translation: "",
      translation_cp: "",
      translation_hp: "",
      map_discount: "",
      map_cost: "",
      img_hp: "",
      img_cp: "",
      plots: "",
      plot_x: "",
      plot_y: "",
      plot_cp: "",
      delivery: "",
      delivery_type: "",
      delivery_cp: "",
      img_discount: "",
      img_cost: "",
      quote_cost: "",
      map_notes: "",
      img_notes: "",
      plot_mount: "",
      camera: "",
      supplemental_survey: "",
      plain_gc: "",
      description: "",
      sales_tax: "",
      unit_price: "",
      shipping: "",
      discount: "",
      editing: false,
    };
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if(this.props.match) {
    $.ajax({
      type: "GET",
      url: `/quotes/${this.props.match.params.id}`,
      dataType: "JSON"
    }).done((data) => {
      this.setState({
        opportunity_id: data.quote.opportunity_id,
        specification_id: data.quote.specification_id,
        quote_type: data.quote.quote_type,
        flight_sub: data.quote.flight_sub,
        flight_quote: data.quote.flight_quote,
        flight_markup: data.quote.flight_markup,
        flight_cost: data.quote.flight_cost,
        flight_notes: data.quote.flight_notes,
        panels: data.quote.panels,
        abgps: data.quote.abgps,
        survey_sub: data.quote.survey_sub,
        survey_cost: data.quote.survey_cost,
        survey_quote: data.quote.survey_quote,
        survey_markup: data.quote.survey_markup,
        survey_notes: data.quote.survey_notes,
        contact_prints: data.quote.contact_prints,
        contact_prints_cp: data.quote.contact_prints_cp,
        diapositives: data.quote.diapositives,
        diapositives_cp: data.quote.diapositives_cp,
        scan_cp: data.quote.scan_cp,
        lab_discount: data.quote.lab_discount,
        lab_cost: data.quote.lab_cost,
        lab_notes: data.quote.lab_notes,
        analytics_hp: data.quote.analytics_hp,
        analytics_cp: data.quote.analytics_cp,
        photogrammetry_cp: data.quote.photogrammetry_cp,
        photogrammetry_hp: data.quote.photogrammetry_hp,
        xsec: data.quote.xsec,
        xsec_cp: data.quote.xsec_cp,
        xsec_hp: data.quote.xsec_hp,
        cartography_cp: data.quote.cartography_cp,
        cartography_hp: data.quote.cartography_hp,
        translation: data.quote.translation,
        translation_cp: data.quote.translation_cp,
        translation_hp: data.quote.translation_hp,
        map_discount: data.quote.map_discount,
        map_cost: data.quote.map_cost,
        img_hp: data.quote.img_hp,
        img_cp: data.quote.img_cp,
        plots: data.quote.plots,
        plot_x: data.quote.plot_x,
        plot_y: data.quote.plot_y,
        plot_cp: data.quote.plot_cp,
        delivery: data.quote.delivery,
        delivery_type: data.quote.delivery_type,
        delivery_cp: data.quote.delivery_cp,
        img_discount: data.quote.img_discount,
        img_cost: data.quote.img_cost,
        quote_cost: data.quote.quote_cost,
        map_notes: data.quote.map_notes,
        img_notes: data.quote.img_notes,
        plot_mount: data.quote.plot_mount,
        camera: data.quote.camera,
        supplemental_survey: data.quote.supplemental_survey,
        plain_gc: data.quote.plain_gc,
        description: data.quote.description,
        sales_tax: data.quote.sales_tax,
        unit_price: data.quote.unit_price,
        shipping: data.quote.shipping,
        discount: data.quote.discount,
        editing: this.props.match.path === '/quotes/:id/edit'
      });
    });
   }
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.state.editing ?
      this.updateQuote() :
      this.addQuote();
  }

  updateQuote() {
    const quote = {
      opportunity_id: this.state.opportunity_id,
      specification_id: this.state.specification_id,
      quote_type: this.state.quote_type,
      flight_sub: this.state.flight_sub,
      flight_quote: this.state.flight_quote,
      flight_markup: this.state.flight_markup,
      flight_cost: this.state.flight_cost,
      flight_notes: this.state.flight_notes,
      panels: this.state.panels,
      abgps: this.state.abgps,
      survey_sub: this.state.survey_sub,
      survey_cost: this.state.survey_cost,
      survey_quote: this.state.survey_quote,
      survey_markup: this.state.survey_markup,
      survey_notes: this.state.survey_notes,
      contact_prints: this.state.contact_prints,
      contact_prints_cp: this.state.contact_prints_cp,
      diapositives: this.state.diapositives,
      diapositives_cp: this.state.diapositives_cp,
      scan_cp: this.state.scan_cp,
      lab_discount: this.state.lab_discount,
      lab_cost: this.state.lab_cost,
      lab_notes: this.state.lab_notes,
      analytics_hp: this.state.analytics_hp,
      analytics_cp: this.state.analytics_cp,
      photogrammetry_cp: this.state.photogrammetry_cp,
      photogrammetry_hp: this.state.photogrammetry_hp,
      xsec: this.state.xsec,
      xsec_cp: this.state.xsec_cp,
      xsec_hp: this.state.xsec_hp,
      cartography_cp: this.state.cartography_cp,
      cartography_hp: this.state.cartography_hp,
      translation: this.state.translation,
      translation_cp: this.state.translation_cp,
      translation_hp: this.state.translation_hp,
      map_discount: this.state.map_discount,
      map_cost: this.state.map_cost,
      img_hp: this.state.img_hp,
      img_cp: this.state.img_cp,
      plots: this.state.plots,
      plot_x: this.state.plot_x,
      plot_y: this.state.plot_y,
      plot_cp: this.state.plot_cp,
      delivery: this.state.delivery,
      delivery_type: this.state.delivery_type,
      delivery_cp: this.state.delivery_cp,
      img_discount: this.state.img_discount,
      img_cost: this.state.img_cost,
      quote_cost: this.state.quote_cost,
      map_notes: this.state.map_notes,
      img_notes: this.state.img_notes,
      plot_mount: this.state.plot_mount,
      camera: this.state.camera,
      supplemental_survey: this.state.supplemental_survey,
      plain_gc: this.state.plain_gc,
      description: this.state.description,
      sales_tax: this.state.sales_tax,
      unit_price: this.state.unit_price,
      shipping: this.state.shipping,
      discount: this.state.discount
    };
    $.ajax({
      type: "PATCH",
      url: `/quotes/${this.props.match.params.id}`,
      data: { quote: quote }
    })
      .done((data) => {
        console.log('Quote updated!');
        this.props.history.goBack('/quotes');
      })
      .fail((response) => {
        console.log("did not work");
      });
  }

  addQuote() {
    const quote = {
      opportunity_id: this.state.opportunity_id,
      specification_id: this.state.specification_id,
      quote_type: this.state.quote_type,
      flight_sub: this.state.flight_sub,
      flight_quote: this.state.flight_quote,
      flight_markup: this.state.flight_markup,
      flight_cost: this.state.flight_cost,
      flight_notes: this.state.flight_notes,
      panels: this.state.panels,
      abgps: this.state.abgps,
      survey_sub: this.state.survey_sub,
      survey_cost: this.state.survey_cost,
      survey_quote: this.state.survey_quote,
      survey_markup: this.state.survey_markup,
      survey_notes: this.state.survey_notes,
      contact_prints: this.state.contact_prints,
      contact_prints_cp: this.state.contact_prints_cp,
      diapositives: this.state.diapositives,
      diapositives_cp: this.state.diapositives_cp,
      scan_cp: this.state.scan_cp,
      lab_discount: this.state.lab_discount,
      lab_cost: this.state.lab_cost,
      lab_notes: this.state.lab_notes,
      analytics_hp: this.state.analytics_hp,
      analytics_cp: this.state.analytics_cp,
      photogrammetry_cp: this.state.photogrammetry_cp,
      photogrammetry_hp: this.state.photogrammetry_hp,
      xsec: this.state.xsec,
      xsec_cp: this.state.xsec_cp,
      xsec_hp: this.state.xsec_hp,
      cartography_cp: this.state.cartography_cp,
      cartography_hp: this.state.cartography_hp,
      translation: this.state.translation,
      translation_cp: this.state.translation_cp,
      translation_hp: this.state.translation_hp,
      map_discount: this.state.map_discount,
      map_cost: this.state.map_cost,
      img_hp: this.state.img_hp,
      img_cp: this.state.img_cp,
      plots: this.state.plots,
      plot_x: this.state.plot_x,
      plot_y: this.state.plot_y,
      plot_cp: this.state.plot_cp,
      delivery: this.state.delivery,
      delivery_type: this.state.delivery_type,
      delivery_cp: this.state.delivery_cp,
      img_discount: this.state.img_discount,
      img_cost: this.state.img_cost,
      quote_cost: this.state.quote_cost,
      map_notes: this.state.map_notes,
      img_notes: this.state.img_notes,
      plot_mount: this.state.plot_mount,
      camera: this.state.camera,
      supplemental_survey: this.state.supplemental_survey,
      plain_gc: this.state.plain_gc,
      description: this.state.description,
      sales_tax: this.state.sales_tax,
      unit_price: this.state.unit_price,
      shipping: this.state.shipping,
      discount: this.state.discount
    };
    $.post('/quotes', { quote: quote })
      .done((data) => {
        this.props.handleNewQuote(data);
        console.log("Quote Successfully Created!");
        location.reload();
      })
      .fail((response) => {
        console.log("did not work");
      });
  }

  deleteQuote = () => {
    if (confirm("Are you sure you want to delete this quote?")) {
      $.ajax({
        type: "DELETE",
        url: `/quotes/${this.props.match.params.id}`,
      })
        .done((data) => {
          this.props.history.push('/quotes');
        })
        .fail((response) => {
          console.log("Quote deletion failed!");
        });
    }
  }

  handleChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  handleSelectChange(key) {
    return function ({ value }) {
      this.setState({ [key]: value });
    }.bind(this);
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <h4 className="mb-4 text-4xl">
            {this.state.editing ?
              'Update Quote' :
              'Create Quote'}
          </h4>
          <small>
            (* represents a <strong>required field</strong>)
          </small>
          <form onSubmit={this.handleFormSubmit} >
            <div className="row">
              <div className="col-md-3">
                <label>Opportunity ID *</label>
                <input type="text" name="opportunity_id" placeholder="Opp ID"
                  value={this.state.opportunity_id}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-3">
                <label>Spec ID *</label>
                <input type="text" name="specification_id" placeholder="Spec ID"
                  value={this.state.specification_id}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-3">
                <label>Quote Type *</label>
                <Select
                name="quote_type"
                disabled={true}
                value={this.state.quote_type}
                options={[
                  { value: 'Film', label: 'Film' },
                  { value: 'Digital', label: 'Digital' },
                  { value: 'LiDAR', label: 'LiDAR' },
                  { value: 'Scan', label: 'Scan' },
                  { value: 'Plot', label: 'Plot' },
                  { value: 'Survey', label: 'Survey' },
                  { value: 'Other', label: 'Other' },
                ]}
              />
              </div>
            </div>
            <p />
            <h5>Flight</h5>
            <hr />
            <div className="row">
              <div className="col-md-3">
                <label>Flight Sub *</label>
                <Select.Creatable
                  allowCreate={true}
                  name="flight_sub"
                  value={this.state.flight_sub}
                  onChange={this.handleSelectChange("flight_sub")}
                  options={this.state.flight_sub_options}
                />
              </div>
              <div className="col-md-3">
                <label>Flight Quote *</label>
                <input type="text" name="flight_quote"
                  value={this.state.flight_quote}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-3">
                <label>Flight Markup *</label>
                <input type="text" name="flight_markup"
                  value={this.state.flight_markup}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-3">
                <label>Flight Cost *</label>
                <input type="text" name="flight_cost"
                  value={this.state.flight_cost}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Flight Notes</label>
                <textarea name="flight_notes"
                  value={this.state.flight_notes}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
            </div>
            <p />
            <h5>Survey</h5>
            <hr />
            <div className="row">
              <div className="col-md-1">
                <label>Panels *</label>
                <input type="text" name="panels"
                  value={this.state.panels}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-1">
                <label>ABGPS *</label>
                <input type="text" name="abgps"
                  value={this.state.abgps}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-3">
                <label>Surveyor *</label>
                <input type="text" name="survey_sub"
                  value={this.state.survey_sub}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-3">
                <label>Survey Quote *</label>
                <input type="text" name="survey_quote"
                  value={this.state.survey_quote}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-1">
                <label>Markup *</label>
                <input type="text" name="survey_markup"
                  value={this.state.survey_markup}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-3">
                <label>Survey Cost *</label>
                <input type="text" name="survey_cost"
                  value={this.state.survey_cost}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Survey Notes</label>
                <textarea name="survey_notes"
                  value={this.state.survey_notes}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
            </div>
            <p />
            <h5>Photo Lab</h5>
            <hr />
            <div className="row">
              <div className="col-md-2">
                <label>Contact Prints *</label>
                <input type="text" name="contact_prints"
                  value={this.state.contact_prints}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-1">
                <label>Order *</label>
                <input type="checkbox"
                  className="form-control" />
              </div>
              <div className="col-md-3">
                <label>Cost Per *</label>
                <input type="text" name="contact_prints_cp"
                  value={this.state.contact_prints_cp}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-3">
                <label>Total *</label>
                <input type="text"
                  className="form-control" />
              </div>
              <div className="col-md-3">
                <label>Subtotal *</label>
                <input type="text"
                  className="form-control" />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-2">
                <label>Diapositives *</label>
                <input type="text" name="diapositives"
                  value={this.state.diapositives}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-1">
                <label>Order *</label>
                <input type="checkbox"
                  className="form-control" />
              </div>
              <div className="col-md-3">
                <label>Cost Per *</label>
                <input type="text" name="diapositives_cp"
                  value={this.state.diapositives_cp}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-3">
                <label>Total *</label>
                <input type="text"
                  className="form-control" />
              </div>
              <div className="col-md-3">
                <label>Discount *</label>
                <input type="text" name="lab_discount"
                  value={this.state.lab_discount}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-2">
                <label>Scanning *</label>
                <input type="text"
                  className="form-control" />
              </div>
              <div className="col-md-1">

              </div>
              <div className="col-md-3">
                <label>Cost Per *</label>
                <input type="text" name="scan_cp"
                  value={this.state.scan_cp}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-3">
                <label>Total *</label>
                <input type="text"
                  className="form-control" />
              </div>
              <div className="col-md-3">
                <label>Lab Cost *</label>
                <input type="text" name="lab_cost"
                  value={this.state.lab_cost}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Lab Notes</label>
                <textarea name="lab_notes"
                  value={this.state.lab_notes}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
            </div>
            <p />
            <h5>Mapping</h5>
            <hr />
            <div className="row">
              <div className="col-md-2">
                <label>Analytics *</label>
                <input type="text"
                  className="form-control" />
              </div>
              <div className="col-md-2">
                <label>Hours Per *</label>
                <input type="text" name="analytics_hp"
                  value={this.state.analytics_hp}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-2">
                <label>Hours *</label>
                <input type="text"
                  className="form-control" />
              </div>
              <div className="col-md-2">
                <label>Cost Per *</label>
                <input type="text" name="analytics_cp"
                  value={this.state.analytics_cp}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-2">
                <label>Total *</label>
                <input type="text"
                  className="form-control" />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-2">
                <label>Photogrammetry *</label>
                <input type="text"
                  className="form-control" />
              </div>
              <div className="col-md-2">
                <label>Hours Per *</label>
                <input type="text" name="photogrammetry_hp"
                  value={this.state.photogrammetry_hp}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-2">
                <label>Hours *</label>
                <input type="text"
                  className="form-control" />
              </div>
              <div className="col-md-2">
                <label>Cost Per *</label>
                <input type="text" name="photogrammetry_cp"
                  value={this.state.photogrammetry_cp}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-2">
                <label>Total *</label>
                <input type="text"
                  className="form-control" />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-2">
                <label>X Sections *</label>
                <input type="text" name="xsec"
                  value={this.state.xsec}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-2">
                <label>Hours Per *</label>
                <input type="text" name="xsec_hp"
                  value={this.state.xsec_hp}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-2">
                <label>Hours *</label>
                <input type="text"
                  className="form-control" />
              </div>
              <div className="col-md-2">
                <label>Cost Per *</label>
                <input type="text" name="xsec_cp"
                  value={this.state.xsec_cp}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-2">
                <label>Total *</label>
                <input type="text"
                  className="form-control" />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-2">
                <label>Cartography *</label>
                <input type="text"
                  className="form-control" />
              </div>
              <div className="col-md-2">
                <label>Hours Per *</label>
                <input type="text" name="cartography_hp"
                  value={this.state.cartography_hp}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-2">
                <label>Hours *</label>
                <input type="text"
                  className="form-control" />
              </div>
              <div className="col-md-2">
                <label>Cost Per *</label>
                <input type="text" name="cartography_cp"
                  value={this.state.cartography_cp}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-2">
                <label>Total *</label>
                <input type="text"
                  className="form-control" />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-2">
                <label>Translation *</label>
                <input type="text" name="translation"
                  value={this.state.translation}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-2">
                <label>Hours Per *</label>
                <input type="text" name="translation_hp"
                  value={this.state.translation_hp}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-2">
                <label>Hours *</label>
                <input type="text"
                  className="form-control" />
              </div>
              <div className="col-md-2">
                <label>Cost Per *</label>
                <input type="text" name="translation_cp"
                  value={this.state.translation_cp}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-2">
                <label>Total *</label>
                <input type="text"
                  className="form-control" />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-2">
                <label>Subtotal *</label>
                <input type="text"
                  className="form-control" />
              </div>
              <div className="col-md-1">
                <label>Discount *</label>
                <input type="text" name="map_discount"
                  value={this.state.map_discount}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-2">
                <label>Mapping Cost *</label>
                <input type="text" name="map_cost"
                  value={this.state.map_cost}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Map Notes</label>
                <textarea name="map_notes"
                  value={this.state.map_notes}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
            </div>
            <p />
            <h5>Imagery</h5>
            <hr />
            <div className="row">
              <div className="col-md-2">
                <label>Rectification *</label>
                <input type="text"
                  className="form-control" />
              </div>
              <div className="col-md-2">
                <label>Hours Per</label>
                <input type="text" name="img_hp"
                  value={this.state.img_hp}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-2">
                <label>Hours</label>
                <input type="text"
                  className="form-control" />
              </div>
              <div className="col-md-2">
                <label>Cost Per</label>
                <input type="text" name="img_cp"
                  value={this.state.img_cp}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-2">
                <label>Subtotal *</label>
                <input type="text"
                  className="form-control" />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-2">
                <label>Plots *</label>
                <input type="text" name="plots"
                  value={this.state.plots}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-1">
                <label>Length</label>
                <input type="text" name="plot_x"
                  value={this.state.plot_x}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-1">
                <label>Width</label>
                <input type="text" name="plot_y"
                  value={this.state.plot_y}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-2">
                <label>Mounted</label>
                <input type="text" name="plot_mount"
                  value={this.state.plot_mount}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-2">
                <label>Cost Per</label>
                <input type="text" name="plot_cp"
                  value={this.state.plot_cp}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-2">
                <label>Total *</label>
                <input type="text"
                  className="form-control" />
              </div>
              <div className="col-md-2">
                <label>Discount *</label>
                <input type="text" name="discount"
                  value={this.state.discount}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-2">
                <label>Delivery</label>
                <input type="text" name="delivery"
                  value={this.state.delivery}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-2">
                <label>Delivery Type</label>
                <input type="text" name="delivery_type"
                  value={this.state.delivery_type}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-2">

              </div>
              <div className="col-md-2">
                <label>Cost Per</label>
                <input type="text" name="delivery_cp"
                  value={this.state.delivery_cp}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-2">
                <label>Total *</label>
                <input type="text"
                  className="form-control" />
              </div>
              <div className="col-md-2">
                <label>Imagery Cost</label>
                <input type="text" name="img_cost"
                  value={this.state.img_cost}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
            </div>
            <p />
            <h5>Total Quote Cost</h5>
            <hr />
            <div className="row">
              <div className="col-md-2">
                <label>Quote Cost</label>
                <input type="text" name="quote_cost"
                  value={this.state.quote_cost}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
            </div>
            <p />
            <input type="submit"
              value={this.state.editing ? 'Update' : 'Create'} className="btn btn-secondary btn-sm"
            />
          </form>
          <br />
        </div>
      </div>
    )
  }

}
