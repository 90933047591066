import React from 'react';
import PropTypes from 'prop-types';
import { FormErrors } from './FormErrors';
import update from 'immutability-helper';
import { validations } from '../utils/validations';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

export default class FlightForm extends React.Component {
  static propTypes = {
    handleNewFlight: PropTypes.func
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      imagery_lidar: '',
      flight_to_use: '',
      second_flight: 'No',
      second_flown: 'No',
      second_flight_date: { value: '', valid: true },
      second_problem_survey: 'No',
      second_uav_flight: 'No',
      problem_survey: '',
      uav_flight: 'No',
      hobbs_time: { value: '', valid: true},
      maintenance_time: { value: '', valid: true},
      tail_number: { value: '', valid: true},
      job_name: { value: '', valid: false },
      client_number: { value: '', valid: false },
      job_number: { value: '', valid: false },
      due_date: { value: '', valid: false },
      client_name: { value: '', valid: false },
      project_location: { value: '', valid: false },
      flight_crew: { value: '', valid: false },
      est_flight_date: { value: '', valid: false },
      color_film: { value: '', valid: false },
      flight_lines: { value: '', valid: false },
      exposures: { value: '', valid: false },
      stereo_prs: { value: '', valid: false },
      project_id: { value: '', valid: false },
      flown: '',
      flights_notes: { value: '', valid: true },
      flight_date: { value: '', valid: true },
      sent_gpc: { value: '', valid: true },
      project_notes: { value: '', valid: true },
      photo_quality: { value: '', valid: true },
      altitude: { value: '', valid: true },
      video: '',
      deliverable: { value: '', valid: true },
      camera: { value: '', valid: true },
      aircraft: { value: '', valid: true },
      photos_uploaded: '',
      no_control: '',
      obliques: '',
      gsd: { value: '', valid: true },
      processing_software: { value: '', valid: true },
      formErrors: {},
      formValid: false,
      editing: false
    }
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
  }

  static formValidations = {
    project_id: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    flight_date: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    sent_gpc: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    second_flight_date: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    project_notes: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    flights_notes: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    photo_quality: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    altitude: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    deliverable: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    camera: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    aircraft: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    gsd: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    processing_software: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    hobbs_time: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    maintenance_time: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    tail_number: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
  }

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/cascopt/flights/${this.props.match.params.id}`,
        dataType: "JSON"
      }).done((data) => {
        this.setState({
          imagery_lidar: data.flight.imagery_lidar,
          flight_to_use: data.flight.flight_to_use,
          second_flight: data.flight.second_flight,
          second_flown: data.flight.second_flown,
          second_flight_date: { value: data.flight.second_flight_date, valid: true },
          second_problem_survey: data.flight.second_problem_survey,
          second_uav_flight: data.flight.second_uav_flight,
          problem_survey: data.flight.problem_survey,
          uav_flight: data.flight.uav_flight,
          job_name: { value: data.project.job_name, valid: true },
          client_number: { value: data.project.client_number, valid: true },
          job_number: { value: data.project.job_number, valid: true },
          due_date: { value: data.project.due_date, valid: true },
          client_name: { value: data.project.client_name, valid: true },
          project_location: { value: data.project.project_location, valid: true },
          flight_crew: { value: data.project.flight_crew, valid: true },
          est_flight_date: { value: data.project.est_flight_date, valid: true },
          color_film: { value: data.project.color_film, valid: true },
          flight_lines: { value: data.project.flight_lines, valid: true },
          exposures: { value: data.project.exposures, valid: true },
          stereo_prs: { value: data.project.stereo_prs, valid: true },
          project_id: { value: data.flight.project_id, valid: true },
          flown: data.flight.flown,
          flight_date: { value: data.flight.flight_date, valid: true },
          sent_gpc: { value: data.flight.sent_gpc, valid: true },
          project_notes: { value: data.project.project_notes, valid: true },
          flights_notes: { value: data.project.flights_notes, valid: true },
          photo_quality: { value: data.flight.photo_quality, valid: true },
          altitude: { value: data.flight.altitude, valid: true },
          video: data.flight.video,
          deliverable: { value: data.flight.deliverable, valid: true },
          camera: { value: data.flight.camera, valid: true },
          aircraft: { value: data.flight.aircraft, valid: true },
          photos_uploaded: data.flight.photos_uploaded,
          no_control: data.flight.no_control,
          obliques: data.flight.obliques,
          gsd: { value: data.flight.gsd, valid: true },
          processing_software: { value: data.flight.processing_software, valid: true },
          hobbs_time: { value: data.flight.hobbs_time, valid: true },
          maintenance_time: { value: data.flight.maintenance_time, valid: true },
          tail_number: { value: data.flight.tail_number, valid: true },
          editing: this.props.match.path === '/cascopt/flights/:id/edit'
        });
      });
    }
  }

  handleUserInput = (fieldName, fieldValue, validations) => {
    const newFieldState = update(this.state[fieldName],
      { value: { $set: fieldValue } });
    this.setState({ [fieldName]: newFieldState },
      () => { this.validateField(fieldName, fieldValue, validations) });
  }

  validateField(fieldName, fieldValue, validations) {
    let fieldValid;

    let fieldErrors = validations.reduce((errors, v) => {
      let e = v(fieldValue);
      if (e !== '') {
        errors.push(e);
      }
      return (errors);
    }, []);

    fieldValid = fieldErrors.length === 0;

    const newFieldState = update(this.state[fieldName],
      { valid: { $set: fieldValid } });

    const newFormErrors = update(this.state.formErrors,
      { $merge: { [fieldName]: fieldErrors } });

    this.setState({
      [fieldName]: newFieldState,
      formErrors: newFormErrors
    }, this.validateForm);
  }

  validateForm() {
    this.setState({
      formValid: this.state.project_id.valid &&
        this.state.flight_date &&
        this.state.sent_gpc &&
        this.state.second_flight_date &&
        this.state.project_notes.valid &&
        this.state.flights_notes.valid
    });
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.state.editing ?
      this.updateFlight() :
      this.addFlight();
  }

  updateFlight() {
    const flight = {
      imagery_lidar: this.state.imagery_lidar,
      flight_to_use: this.state.flight_to_use,
      second_flight: this.state.second_flight,
      second_flown: this.state.second_flown,
      second_flight_date: this.state.second_flight_date.value,
      second_problem_survey: this.state.second_problem_survey,
      second_uav_flight: this.state.second_uav_flight,
      problem_survey: this.state.problem_survey,
      uav_flight: this.state.uav_flight,
      project_id: this.state.project_id.value,
      flown: this.state.flown,
      flight_date: this.state.flight_date.value,
      sent_gpc: this.state.sent_gpc.value,
      project_notes: this.state.project_notes.value,
      flights_notes: this.state.flights_notes.value,
      photo_quality: this.state.photo_quality.value,
      altitude: this.state.altitude.value,
      video: this.state.video,
      deliverable: this.state.deliverable.value,
      camera: this.state.camera.value,
      aircraft: this.state.aircraft.value,
      photos_uploaded: this.state.photos_uploaded,
      no_control: this.state.no_control,
      obliques: this.state.obliques,
      gsd: this.state.gsd.value,
      processing_software: this.state.processing_software.value,
      hobbs_time: this.state.hobbs_time.value,
      maintenance_time: this.state.maintenance_time.value,
      tail_number: this.state.tail_number.value,
    };
    $.ajax({
      type: "PATCH",
      url: `/cascopt/flights/${this.props.match.params.id}`,
      data: { flight: flight }
    })
      .done((data) => {
        console.log('flight updated!');
        this.resetFormErrors();
        this.props.history.goBack('/cascopt/flights');
      })
      .fail((response) => {
        alert("Please review the form for errors");
        this.setState({
          formErrors: response.responseJSON,
          formValid: false
        })
      });
  }

  addFlight() {
    const flight = {
      imagery_lidar: this.state.imagery_lidar,
      flight_to_use: this.state.flight_to_use,
      second_flight: this.state.second_flight,
      second_flown: this.state.second_flown,
      second_flight_date: this.state.second_flight_date.value,
      second_problem_survey: this.state.second_problem_survey,
      second_uav_flight: this.state.second_uav_flight,
      problem_survey: this.state.problem_survey,
      uav_flight: this.state.uav_flight,
      project_id: this.state.project_id.value,
      flown: this.state.flown,
      flight_date: this.state.flight_date.value,
      sent_gpc: this.state.sent_gpc.value,
      project_notes: this.state.project_notes.value,
      flights_notes: this.state.flights_notes.value,
      photo_quality: this.state.photo_quality.value,
      altitude: this.state.altitude.value,
      video: this.state.video,
      deliverable: this.state.deliverable.value,
      camera: this.state.camera.value,
      aircraft: this.state.aircraft.value,
      photos_uploaded: this.state.photos_uploaded,
      no_control: this.state.no_control,
      obliques: this.state.obliques,
      gsd: this.state.gsd.value,
      processing_software: this.state.processing_software.value,
      hobbs_time: this.state.hobbs_time.value,
      maintenance_time: this.state.maintenance_time.value,
      tail_number: this.state.tail_number.value,
    };
    $.post('/cascopt/flights', { flight: flight })
      .done((data) => {
        this.props.handleNewFlight(data);
        this.resetFormErrors();
      })
      .fail((response) => {
        this.setState({ formErrors: response.responseJSON, formValid: false })
      });
  }

  deleteFlight = () => {
    if (confirm("Are you sure you want to delete this flight?")) {
      $.ajax({
        type: "DELETE",
        url: `/cascopt/flights/${this.props.match.params.id}`,
      })
        .done((data) => {
          this.props.history.push('/cascopt/flights');
          this.resetFormErrors();
        })
        .fail((response) => {
          console.log("Flight deletion failed!");
        });
    }
  }

  resetFormErrors() {
    this.setState({ formErrors: {} })
  }

  handleChange(e) {
    this.handleUserInput(e.target.name, e.target.value, FlightForm.formValidations[e.target.name]);
  }

  handleSelectChange(key) {
    return function ({ value }) {
      this.setState({ [key]: value });
    }.bind(this);
  }

  render() {
    return (
      <div className="container" id="form-card">
        <h4 className="mb-4 text-4xl">
          {this.state.editing ?
            'Update Flight ' :
            'Create Flight '}
          for {this.state.job_name.value || ''} | {this.state.client_number.value || ''}-{this.state.job_number.value || ''}
        </h4>
        <p>Due: {moment(this.state.due_date.value || '').format("MM/DD/YYYY")}</p>
        <p>Client: {this.state.client_name.value || ''}</p>
        <p>
        Project Location: {this.state.project_location.value || ''} | Flight Crew: {this.state.flight_crew.value || ''}
         | Est Flight Date: {moment(this.state.est_flight_date.value || '').format("MM/DD/YYYY")}  | Color Film: {this.state.color_film.value || ''}
         | Flight Lines: {this.state.flight_lines.value || ''} | Exposures: {this.state.exposures.value || ''}
         | Stereo PRS: {this.state.stereo_prs.value || ''}
        </p>
        <small>
          (* represents a <strong>required field</strong>)
        </small>
        <FormErrors formErrors={this.state.formErrors} />
        <form onSubmit={this.handleFormSubmit} >
          <div className="row">
            <div className="col-md-3">
              <label>Flight to Use</label>
              <Select
                name="flight_to_use"
                value={this.state.flight_to_use}
                onChange={this.handleSelectChange("flight_to_use")}
                options={[
                  { value: 'Primary', label: 'Primary' },
                  { value: 'Secondary', label: 'Secondary' },
                ]}
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col-md-3">
              <label>Flown *</label>
              <Select
                name="flown"
                value={this.state.flown}
                onChange={this.handleSelectChange("flown")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
            <div className="col-md-3">
              <label>Actual Flight Date *</label>
              <input type="date" name="flight_date" placeholder="Actual Flight Date"
                value={this.state.flight_date.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
            <div className="col-md-3">
              <label>UAV Flight *</label>
              <Select
                name="uav_flight"
                value={this.state.uav_flight}
                onChange={this.handleSelectChange("uav_flight")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
            <div className="col-md-3">
              <label>Problem w/ Survey</label>
              <Select
                name="problem_survey"
                value={this.state.problem_survey}
                onChange={this.handleSelectChange("problem_survey")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col-md-3">
              <label>Hobbs Time (NAAS)</label>
              <input type="text" name="hobbs_time" placeholder="Hobbs Time"
                value={this.state.hobbs_time.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
            <div className="col-md-3">
              <label>Maintenance Time (NAAS)</label>
              <input type="text" name="maintenance_time" placeholder="Maintenance Time"
                value={this.state.maintenance_time.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
            <div className="col-md-3">
              <label>N # (NAAS)</label>
              <input type="text" name="tail_number" placeholder="N #"
                value={this.state.tail_number.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
          </div>
          <p />
          {this.state.uav_flight == "Yes" &&
          <div>
          <h5>UAV Information</h5>
          <div className="row">
            <div className="col">
              <label>Aircraft *</label>
              <input type="text" name="aircraft" placeholder="Aircraft"
                value={this.state.aircraft.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
            <div className="col">
              <label>Camera *</label>
              <input type="text" name="camera" placeholder="Camera"
                value={this.state.camera.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
            <div className="col">
              <label>Altitude *</label>
              <input type="text" name="altitude" placeholder="Altitude"
                value={this.state.altitude.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
            <div className="col">
              <label>GSD *</label>
              <input type="text" name="gsd" placeholder="GSD"
                value={this.state.gsd.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col-md-3">
              <label>Processing Software *</label>
              <input type="text" name="processing_software" placeholder="Processing Software"
                value={this.state.processing_software.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col">
              <label>Video *</label>
              <Select
                name="video"
                value={this.state.video}
                onChange={this.handleSelectChange("video")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
            <div className="col">
              <label>Photos Uploaded *</label>
              <Select
                name="photos_uploaded"
                value={this.state.photos_uploaded}
                onChange={this.handleSelectChange("photos_uploaded")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
            <div className="col-md-1">
              <label>No Control *</label>
              <Select
                name="no_control"
                value={this.state.no_control}
                onChange={this.handleSelectChange("no_control")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
            <div className="col">
              <label>Obliques *</label>
              <Select
                name="obliques"
                value={this.state.obliques}
                onChange={this.handleSelectChange("obliques")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
          </div>
          </div>}
          <p />
          <div className="row">
            <div className="col-md-3">
              <label>Secondary Flight</label>
              <Select
                name="second_flight"
                value={this.state.second_flight}
                onChange={this.handleSelectChange("second_flight")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
          </div>
          <p />
          {this.state.second_flight == "Yes" &&
            <div>
            <div className="row">
              <div className="col-md-3">
                <label>Imagery or LiDAR</label>
                <Select
                  name="imagery_lidar"
                  value={this.state.imagery_lidar}
                  onChange={this.handleSelectChange("imagery_lidar")}
                  options={[
                    { value: 'Imagery', label: 'Imagery' },
                    { value: 'LiDAR', label: 'LiDAR' },
                  ]}
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Flown *</label>
                <Select
                  name="second_flown"
                  value={this.state.second_flown}
                  onChange={this.handleSelectChange("second_flown")}
                  options={[
                    { value: 'No', label: 'No' },
                    { value: 'Yes', label: 'Yes' },
                  ]}
                />
              </div>
              <div className="col-md-3">
                <label>Actual Flight Date *</label>
                <input type="date" name="second_flight_date" placeholder="Actual Flight Date"
                  value={this.state.second_flight_date.value}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-3">
                <label>UAV Flight *</label>
                <Select
                  name="second_uav_flight"
                  value={this.state.second_uav_flight}
                  onChange={this.handleSelectChange("second_uav_flight")}
                  options={[
                    { value: 'No', label: 'No' },
                    { value: 'Yes', label: 'Yes' },
                  ]}
                />
              </div>
              <div className="col-md-3">
                <label>Problem w/ Survey</label>
                <Select
                  name="second_problem_survey"
                  value={this.state.second_problem_survey}
                  onChange={this.handleSelectChange("second_problem_survey")}
                  options={[
                    { value: 'No', label: 'No' },
                    { value: 'Yes', label: 'Yes' },
                  ]}
                />
              </div>
            </div>
            </div>}
          <p />
          <div className="row">
            <div className="col">
              <label>Flight Notes *</label>
              <textarea name="flights_notes" placeholder="Flight Notes" rows="4"
                value={this.state.flights_notes.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col">
              <label>Project Notes</label>
              <textarea name="project_notes" placeholder="Project Notes" rows="4"
                value={this.state.project_notes.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
          </div>
          <p />
          <input type="submit"
            value={this.state.editing ? 'Update' : 'Create'} className="btn btn-secondary btn-sm"
          />
          <a className="btn btn-danger btn-sm" onClick={this.props.history.goBack}>Cancel</a>
        </form>
        <br />
      </div>
    )
  }
}
