/* eslint-disable react/prefer-stateless-function */
import React from 'react';

export default class Drone extends React.Component {
  render() {
    return (
      <div>
        <h3 id="header">UAV Dashboard </h3>
        <div className="dropdown">
          <button
            className="btn btn-sm btn-secondary dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            data-bs-toggle="dropdown"
          >
            UAV Dashboard Options
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a
              href="/cascopt/uav_overview"
              className="dropdown-item view-record"
            >
              UAV Projects Overview
            </a>
          </div>
        </div>
      </div>
    );
  }
}
