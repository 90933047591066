import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import 'react-select/dist/react-select.css';

export default class PlotSpec extends React.Component {
  static propTypes = {
    handleNewSpecification: PropTypes.func
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      mapping_only: '',
      image_only: '',
      map_scale: '',
      map_scale_options: [
        { value: '10', label: '10' },
        { value: '20', label: '20' },
        { value: '30', label: '30' },
        { value: '40', label: '40' },
        { value: '50', label: '50' },
        { value: '50-80', label: '50-80' },
        { value: '100', label: '100' },
        { value: '200', label: '200' },
        { value: '200-400', label: '200-400' },
        { value: '400', label: '400' },
      ],
      gsd: '',
      ci: '',
      ci_options: [
        { value: '0.5', label: '0.5' },
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '5-10', label: '5-10' },
        { value: '10', label: '10' },
      ],
      models: '',
      map_type: '',
      file_format: '',
      version: '',
      flight_lines: '',
      exposures: '',
      notes: '',
      map_prs: '',
      stereo_prs_options: [
        { value: '1:2000', label: '1:2000' },
        { value: '1:3600', label: '1:3600' },
        { value: '1:7200', label: '1:7200' },
        { value: '1:9600', label: '1:9600' },
        { value: '1:12000', label: '1:12000' },
        { value: '1:14400', label: '1:44400' },
        { value: '1:24000', label: '1:24000' },
        { value: '1:2400', label: '1:2400' },
        { value: '1:2800', label: '1:2800' },
        { value: '1:3000', label: '1:3000' },
        { value: '1:3300', label: '1:3300' },
        { value: '1:3340', label: '1:3340' },
        { value: '1:3360', label: '1:3360' },
        { value: '1:3400', label: '1:3400' },
        { value: '1:4000', label: '1:4000' },
        { value: '1:4800', label: '1:4800' },
        { value: '1:5000', label: '1:5000' },
        { value: '1:5400', label: '1:5400' },
        { value: '1:6000', label: '1:6000' },
        { value: '1:6400', label: '1:6400' },
        { value: '1:6600', label: '1:6600' },
        { value: '1:8000', label: '1:8000' },
        { value: '1:10000', label: '1:10000' },
        { value: '1:13000', label: '1:13000' },
        { value: '1:14000', label: '1:14000' },
        { value: '1:16000', label: '1:16000' },
        { value: '1:18000', label: '1:18000' },
        { value: '1:20000', label: '1:20000' },
        { value: '1:28000', label: '1:28000' },
      ],
      film_type: '',
      units: '',
      diff_img_flight: '',
      ppsm: '',
      acreage: '',
      square_miles: '',
      bare_earth_only: '',
      detail_level: '',
      quantity: '',
      laminated: '',
      size_y: '',
      size_x: '',
      frame: '',
      logo: '',
      frame_color: '',
      classification: '',
      mounted: '',
      plot_options: '',
      scan_res: '',
      number_scans: '',
      deliverable: '',
      location: '',
      staff: '',
      editing: false,
      isHidden: true,
      spec_type: this.props.specType || '',
      user_id: this.props.userId || '',
      ...props.specification,
      plots: Object.keys(props.specification.plots).map(key => props.specification.plots[key]),
    }
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
    this.addPlot = this.addPlot.bind(this);
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden
    })
  }

  componentDidMount() {
    if(this.props.match) {
    $.ajax({
      type: "GET",
      url: `/specifications/${this.props.match.params.id}`,
      dataType: "JSON"
    }).done((data) => {
      this.setState({
        user_id: data.specification.user_id,
        opportunity_id: data.specification.opportunity_id,
        mapping_only: data.specification.mapping_only,
        image_only: data.specification.image_only,
        map_scale: data.specification.map_scale,
        gsd: data.specification.gsd,
        ci: data.specification.ci,
        models: data.specification.models,
        map_type: data.specification.map_type,
        file_format: data.specification.file_format,
        version: data.specification.version,
        flight_lines: data.specification.flight_lines,
        exposures: data.specification.exposures,
        notes: data.specification.notes,
        map_prs: data.specification.map_prs,
        film_type: data.specification.film_type,
        units: data.specification.units,
        diff_img_flight: data.specification.diff_img_flight,
        ppsm: data.specification.ppsm,
        acreage: data.specification.acreage,
        square_miles: data.specification.square_miles,
        bare_earth_only: data.specification.bare_earth_only,
        detail_level: data.specification.detail_level,
        quantity: data.specification.quantity,
        laminated: data.specification.laminated,
        size_y: data.specification.size_y,
        size_x: data.specification.size_x,
        spec_type: data.specification.spec_type,
        frame: data.specification.frame,
        logo: data.specification.logo,
        frame_color: data.specification.frame_color,
        classification: data.specification.classification,
        mounted: data.specification.mounted,
        plot_options: data.specification.plot_options,
        scan_res: data.specification.scan_res,
        number_scans: data.specification.number_scans,
        deliverable: data.specification.deliverable,
        location: data.specification.location,
        staff: data.specification.staff,
        plots: Object.keys(data.specification.plots).map(key => data.specification.plots[key]),
        editing: this.props.match.path === '/specifications/:id/edit'
      });
    });
   }
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.state.editing ?
      this.updateSpecification() :
      this.addSpecification();
  }

  updateSpecification() {
    const specification = {
      user_id: this.props.userId,
      opportunity_id: this.props.oppId,
      mapping_only: this.state.mapping_only,
      image_only: this.state.image_only,
      map_scale: this.state.map_scale,
      gsd: this.state.gsd,
      ci: this.state.ci,
      models: this.state.models,
      map_type: this.state.map_type,
      file_format: this.state.file_format,
      version: this.state.version,
      flight_lines: this.state.flight_lines,
      exposures: this.state.exposures,
      notes: this.state.notes,
      map_prs: this.state.map_prs,
      film_type: this.state.film_type,
      units: this.state.units,
      diff_img_flight: this.state.diff_img_flight,
      ppsm: this.state.ppsm,
      acreage: this.state.acreage,
      square_miles: this.state.square_miles,
      bare_earth_only: this.state.bare_earth_only,
      detail_level: this.state.detail_level,
      quantity: this.state.quantity,
      laminated: this.state.laminated,
      size_y: this.state.size_y,
      size_x: this.state.size_x,
      spec_type: this.props.specType,
      frame: this.state.frame,
      logo: this.state.logo,
      frame_color: this.state.frame_color,
      classification: this.state.classification,
      mounted: this.state.mounted,
      plot_options: this.state.plot_options,
      scan_res: this.state.scan_res,
      number_scans: this.state.number_scans,
      deliverable: this.state.deliverable,
      location: this.state.location,
      staff: this.state.staff,
      plots: this.state.plots,
    };
    $.ajax({
      type: "PATCH",
      url: `/specifications/${this.props.match.params.id}`,
      data: { specification: specification }
    })
      .done((data) => {
        console.log('Specification updated!');
      })
      .fail((response) => {
        console.log("did not work");
        alert("Please review the form for errors");
      });
  }

  addSpecification() {
    const specification = {
      user_id: this.props.userId,
      opportunity_id: this.props.oppId,
      mapping_only: this.state.mapping_only,
      image_only: this.state.image_only,
      map_scale: this.state.map_scale,
      gsd: this.state.gsd,
      ci: this.state.ci,
      models: this.state.models,
      map_type: this.state.map_type,
      file_format: this.state.file_format,
      version: this.state.version,
      flight_lines: this.state.flight_lines,
      exposures: this.state.exposures,
      notes: this.state.notes,
      map_prs: this.state.map_prs,
      film_type: this.state.film_type,
      units: this.state.units,
      diff_img_flight: this.state.diff_img_flight,
      ppsm: this.state.ppsm,
      acreage: this.state.acreage,
      square_miles: this.state.square_miles,
      bare_earth_only: this.state.bare_earth_only,
      detail_level: this.state.detail_level,
      quantity: this.state.quantity,
      laminated: this.state.laminated,
      size_y: this.state.size_y,
      size_x: this.state.size_x,
      spec_type: this.props.specType,
      frame: this.state.frame,
      logo: this.state.logo,
      frame_color: this.state.frame_color,
      classification: this.state.classification,
      mounted: this.state.mounted,
      plot_options: this.state.plot_options,
      scan_res: this.state.scan_res,
      number_scans: this.state.number_scans,
      deliverable: this.state.deliverable,
      location: this.state.location,
      staff: this.state.staff,
      plots: this.state.plots,
    };
    $.post('/specifications', { specification: specification })
      .done((data) => {
        this.props.handleNewSpecification(data);
        console.log("Specification Successfully Created!");
      })
      .fail((response) => {
        console.log("did not work");
        alert("Please review the form for errors");
      });
  }

  deleteSpecification = () => {
    if (confirm("Are you sure you want to delete this specification?")) {
      $.ajax({
        type: "DELETE",
        url: `/specifications/${this.props.match.params.id}`,
      })
        .done((data) => {
          this.props.history.push('/specifications');
        })
        .fail((response) => {
          console.log("Specification deletion failed!");
        });
    }
  }

  handleChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  handleSelectChange(key) {
    return function ({ value }) {
      this.setState({ [key]: value });
    }.bind(this);
  }

  handlePlotSelectChange(plots, idx, field) {
    return function ({ value }) {
        const newPlots = this.state.plots;
        newPlots[idx][field] = value;
        this.setState({plots: newPlots});
    }.bind(this)
  }

  handlePlotChangeFactory(plots, idx, field) {
    return function (event) {
        const newPlots = this.state.plots;
        newPlots[idx][field] = event.target.value;
        this.setState({plots: newPlots});
    }.bind(this)
  }

  addPlot() {
    const newPlots = this.state.plots;
    newPlots.push({...this.PLOT})
    this.setState({plots: newPlots});
  }

  render() {

    const {plots} = this.state;

    return (
      <div className="card">
        <div className="card-body">
          <h4 className="mb-4 text-4xl">
            {this.state.editing ?
              'Update Plot Specification' :
              'Create Plot Specification'}
          </h4>
          <small>
            (* represents a <strong>required field</strong>)
          </small>
          <form onSubmit={this.handleFormSubmit} >
            <div className="row">
              <div className="col-md-3">
                <label title="The type of specification the form is for, this is set per the opportunity type">Spec Type *</label>
                <Select
                name="spec_type"
                disabled={true}
                value={this.state.spec_type}
                options={[
                  { value: 'Film', label: 'Film' },
                  { value: 'Digital', label: 'Digital' },
                  { value: 'LiDAR', label: 'LiDAR' },
                  { value: 'Scan', label: 'Scan' },
                  { value: 'Plot', label: 'Plot' },
                  { value: 'Survey', label: 'Survey' },
                  { value: 'Other', label: 'Other' },
                ]}
              />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-4">
                <label title="Enter the number of plots">Quantity *</label>
                <input type="text" name="quantity" placeholder="Quantity"
                  required
                  value={this.state.quantity}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-2">
                <label title="Enter the X size">Size X *</label>
                <input type="text" name="size_x" placeholder="Size X"
                  required
                  value={this.state.size_x}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-2">
                <label title="Enter the Y size">Size Y *</label>
                <input type="text" name="size_y" placeholder="Size Y"
                  required
                  value={this.state.size_y}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-4">
                <label title="Select option for mounted">Mounted *</label>
                <Select
                  required
                  name="mounted"
                  value={this.state.mounted}
                  onChange={this.handleSelectChange("mounted")}
                  options={[
                    { value: "None", label: "None" },
                    { value: '1/2', label: '1/2' },
                    { value: '3/16', label: '3/16' },
                  ]}
                />
              </div>
              <div className="col-md-4">
                <label title="Select option for frame">Frame *</label>
                <Select
                  required
                  name="frame"
                  value={this.state.frame}
                  onChange={this.handleSelectChange("frame")}
                  options={[
                    { value: 'Metal', label: 'Metal' },
                    { value: 'Plastic', label: 'Plastic' },
                    { value: 'None', label: 'None' },
                  ]}
                />
              </div>
              {this.state.frame == "Metal" &&
              <div className="col-md-4">
                <label title="Enter the frame color">Frame Color *</label>
                <input type="text" name="frame_color" placeholder="Frame Color"
                  value={this.state.frame_color}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>}
              {this.state.frame == "Plastic" &&
              <div className="col-md-4">
                <label title="Enter the frame color">Frame Color *</label>
                <input type="text" name="frame_color" placeholder="Frame Color"
                  value={this.state.frame_color}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>}
            </div>
            <p />
            <div className="row">
              <div className="col-md-4">
                <label title="Select whether this plot is laminated or not">Laminated *</label>
                <Select
                  required
                  name="laminated"
                  value={this.state.laminated}
                  onChange={this.handleSelectChange("laminated")}
                  options={[
                    { value: 'No', label: 'No' },
                    { value: 'Yes', label: 'Yes' },
                  ]}
                />
              </div>
              <div className="col-md-4">
                <label title="Select the lamination type">Lamination Type *</label>
                <Select
                  required
                  name="plot_options"
                  value={this.state.plot_options}
                  onChange={this.handleSelectChange("plot_options")}
                  options={[
                    { value: "n/a", label: "n/a" },
                    { value: 'Dry Erase', label: 'Dry Erase' },
                    { value: 'UV Protection', label: 'UV Protection' },
                  ]}
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-8">
                <label>Notes</label>
                <textarea name="notes" placeholder="Notes"
                  value={this.state.notes}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
            </div>
            <p />
            <hr />
            <button title="Click to add another plot to this specification" type="button" onClick={this.toggleHidden.bind(this)} className="btn btn-success btn-sm" >
               {this.state.isHidden ?
                 'Show/Create Additional Plots +' :
                 'Hide Additonal Plots Form -'}
            </button>
            {!this.state.isHidden &&
            <div>
            <br />
            {
              plots.map((plot, idx) => (
                <div key={`plot-${idx}`}>
                  <div className="row">
                    <div className="col-md-4">
                      <label title="Enter the number of plots">Quantity</label>
                      <input
                        type="text"
                        name={`plot-${idx}-quantity`}
                        placeholder="Quantity"
                        value={this.state.plots[idx]["quantity"]}
                        onChange={this.handlePlotChangeFactory(plots, idx, "quantity")}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-2">
                      <label title="Enter the X size">Size X *</label>
                      <input
                        type="text"
                        name={`plot-${idx}-size_x`}
                        placeholder="Size X"
                        value={this.state.plots[idx]["size_x"]}
                        onChange={this.handlePlotChangeFactory(plots,idx, "size_x")}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-2">
                      <label title="Enter the Y size">Size Y *</label>
                      <input
                        type="text"
                        name={`plot-${idx}-size_y`}
                        placeholder="Size Y"
                        value={this.state.plots[idx]["size_y"]}
                        onChange={this.handlePlotChangeFactory(plots,idx, "size_y")}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-4">
                      <label title="Select option for mounted">Mounted *</label>
                      <Select
                        name={`plot-${idx}-mounted`}
                        value={this.state.plots[idx]["mounted"]}
                        onChange={this.handlePlotSelectChange(plots, idx, "mounted")}
                        options={[
                          { value: "None", label: "None" },
                          { value: '1/2', label: '1/2' },
                          { value: '3/16', label: '3/16' },
                        ]}
                      />
                    </div>
                    <div className="col-md-4">
                      <label title="Select option for frame">Frame *</label>
                      <Select
                        name={`plot-${idx}-frame`}
                        value={this.state.plots[idx]["frame"]}
                        onChange={this.handlePlotSelectChange(plots, idx, "frame")}
                        options={[
                          { value: 'Metal', label: 'Metal' },
                          { value: 'Plastic', label: 'Plastic' },
                          { value: 'None', label: 'None' },
                        ]}
                      />
                    </div>
                    {this.state.plots[idx]["frame"] == "Metal" &&
                    <div className="col-md-4">
                      <label title="Enter the frame color">Frame Color *</label>
                      <input type="text" name="frame_color" placeholder="Frame Color"
                        value={this.state.plots[idx]["frame_color"]}
                        onChange={this.handlePlotChangeFactory(plots,idx, "frame_color")}
                        className="form-control" />
                    </div>}
                    {this.state.plots[idx]["frame"] == "Plastic" &&
                    <div className="col-md-4">
                      <label title="Enter the frame color">Frame Color *</label>
                      <input type="text" name="frame_color" placeholder="Frame Color"
                        value={this.state.plots[idx]["frame_color"]}
                        onChange={this.handlePlotChangeFactory(plots,idx, "frame_color")}
                        className="form-control" />
                    </div>}
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-4">
                      <label title="Select whether this plot is laminated or not">Laminated</label>
                      <Select
                        name={`plot-${idx}-laminated`}
                        value={this.state.plots[idx]["laminated"]}
                        onChange={this.handlePlotSelectChange(plots, idx, "laminated")}
                        options={[
                          { value: 'No', label: 'No' },
                          { value: 'Yes', label: 'Yes' },
                        ]}
                      />
                    </div>
                    <div className="col-md-4">
                      <label title="Select the lamination type">Lamination Type *</label>
                      <Select
                        name={`plot-${idx}-plot_options`}
                        value={this.state.plots[idx]["plot_options"]}
                        onChange={this.handlePlotSelectChange(plots, idx, "plot_options")}
                        options={[
                          { value: "n/a", label: "n/a" },
                          { value: 'Dry Erase', label: 'Dry Erase' },
                          { value: 'UV Protection', label: 'UV Protection' },
                        ]}
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-8">
                      <label>Notes</label>
                      <textarea
                        name={`plot-${idx}-notes`}
                        placeholder="Notes"
                        value={this.state.plots[idx]["notes"]}
                        onChange={this.handlePlotChangeFactory(plots,idx, "notes")}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <hr />
                </div>
              ))
            }
            <br />
            <button title="Add another plot" type="button" onClick={this.addPlot} className="btn btn-success btn-sm">Add Another Plot</button>
            </div>}
            <p />
            <input title="Click to submit" type="submit"
              value={this.state.editing ? 'Update' : 'Create'} className="btn btn-secondary btn-sm"
            />
            <a title="Click to cancel" className="btn btn-danger btn-sm" onClick={this.props.history.goBack}>Cancel</a>
          </form>
          <br />
        </div>
      </div>
    )
  }

}
