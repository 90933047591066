import React from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import update from "immutability-helper";

export default class Contacts extends React.Component {
  static propTypes = {
    contacts: PropTypes.array,
  };

  static defaultProps = {
    contacts: [],
  };

  constructor(props, railsContext) {
    super(props);
    this.state = {
      contacts: this.props.contacts,
      isHidden: true,
      isHiddenNotComplete: false,
      isLoading: false,
    };
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }

  toggleHiddenNotComplete() {
    this.setState({
      isHiddenNotComplete: !this.state.isHiddenNotComplete,
    });
  }

  componentDidMount() {
    if (this.props.match) {
      this.setState({ isLoading: true });
      $.ajax({
        type: "GET",
        url: "/crm/contacts",
        dataType: "JSON",
      }).done((data) => {
        this.setState({ contacts: data, isLoading: false });
      });
    }
  }

  addNewContact = (contact) => {
    const contacts = update(this.state.contacts, { $push: [contact] });
    this.setState({
      contacts: contacts.sort(function (a, b) {
        return new Date(a.created_at) - new Date(b.created_at);
      }),
    });
  };

  render() {
    const { contacts, isLoading } = this.state;
    if (isLoading) {
      return <div className="loader"></div>;
    }

    return (
      <div>
        <ReactTable
          key={contacts.id}
          data={contacts}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          defaultPageSize={25}
          pageSizeOptions={[5, 10, 20, 25, 50, 100]}
          showPaginationTop={true}
          style={{
            height: "800px",
            textAlign: "center",
          }}
          columns={[
            {
              Header: "Client Info",
              columns: [
                {
                  Header: "Company",
                  accessor: "company_name",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={(event) => onChange(event.target.value)}
                      value={filter ? filter.value : ""}
                      style={{
                        width: "100%",
                        color: "black",
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id]
                      .toLowerCase()
                      .startsWith(filter.value.toLowerCase()),
                },
                {
                  Header: "First Name",
                  accessor: "f_name",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={(event) => onChange(event.target.value)}
                      value={filter ? filter.value : ""}
                      style={{
                        width: "100%",
                        color: "black",
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id]
                      .toLowerCase()
                      .startsWith(filter.value.toLowerCase()),
                  Cell: (props) => (
                    <a
                      href={`/crm/contacts/${props.original.id}`}
                      className="view-record"
                    >
                      {props.value}
                    </a>
                  ),
                },
                {
                  Header: "Last Name",
                  accessor: "l_name",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={(event) => onChange(event.target.value)}
                      value={filter ? filter.value : ""}
                      style={{
                        width: "100%",
                        color: "black",
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id]
                      .toLowerCase()
                      .startsWith(filter.value.toLowerCase()),
                },
                {
                  Header: "Awarded Projects Value",
                  accessor: "total_won_quote_cost",
                  Cell: (props) => (
                    <span>
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(props.value)}
                    </span>
                  ),
                },
                {
                  Header: "Won Opportunities",
                  accessor: "won_opportunities_count",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={(event) => onChange(event.target.value)}
                      value={filter ? filter.value : ""}
                      style={{
                        width: "100%",
                        color: "black",
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id]
                      .toLowerCase()
                      .startsWith(filter.value.toLowerCase()),
                },
                {
                  Header: "Opportunities",
                  accessor: "opportunities_count",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={(event) => onChange(event.target.value)}
                      value={filter ? filter.value : ""}
                      style={{
                        width: "100%",
                        color: "black",
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id]
                      .toLowerCase()
                      .startsWith(filter.value.toLowerCase()),
                },
              ],
            },
          ]}
          defaultSorted={[
            {
              id: "total_won_quote_cost",
              desc: true,
            },
          ]}
          className="-striped -highlight"
        />
      </div>
    );
  }
}
