import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Dsms from './Dsms';
import DsmForm from './DsmForm';
import DsmView from './DsmView';

export default (props) => {
  return (
    <Router>
      <div>
        <Route exact path="/cascopt/dsms" render={routeProps => <Dsms {...props} {...routeProps} />} />
        <Route exact path="/cascopt/dsms/:id" render={routeProps => <DsmView {...props} {...routeProps} />} />
        <Route path="/cascopt/dsms/:id/edit" render={routeProps => <DsmForm {...props} {...routeProps} />} />
      </div>
    </Router>
  );
};
