import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import InProcessings from "./InProcessings";
import InProcessingForm from "./InProcessingForm";
import InProcessingView from "./InProcessingView";

export default (props) => {
  return (
    <Router>
      <div>
        <Route
          exact path="/cascopt/in_processings" render={(routeProps) => <InProcessings {...props} {...routeProps} />}
        />
        <Route
          exact
          path="/cascopt/in_processings/:id"
          render={(routeProps) => <InProcessingView {...props} {...routeProps} />}
        />
        <Route
          path="/cascopt/in_processings/:id/edit"
          render={(routeProps) => <InProcessingForm {...props} {...routeProps} />}
        />
      </div>
    </Router>
  );
};
