import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Proposals from './Proposals';
import ProposalForm from './ProposalForm';
import ProposalView from './ProposalView';
import ProposingForm from './ProposingForm';

export default (props) => {
  return (
    <Router>
      <div>
        <Route exact path="/proposals" render={routeProps => <Proposals {...props} {...routeProps}/>} />
        <Route exact path="/proposals/:id" render={routeProps => <ProposalView {...props} {...routeProps}/>} />
        <Route path="/proposals/:id/edit" render={routeProps => <ProposingForm {...props} {...routeProps}/>} />
      </div>
    </Router>
  )
}
