import React from 'react';
import PropTypes from 'prop-types';
import { FormErrors } from './FormErrors';
import update from 'immutability-helper';
import { validations } from '../utils/validations';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

export default class AerotriangulationForm extends React.Component {
  static propTypes = {
    handleNewAerotriangulation: PropTypes.func
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      image_sub: 'No',
      image_sub_name: { value: '', valid: true },
      at_sub_name: { value: '', valid: true },
      at_sub_date_rec: { value: '', valid: true },
      at_sub_date_sent: { value: '', valid: true },
      at_sub_done: '',
      at_sub_done_date: { value: '', valid: true },
      problem_at: '',
      problem: { value: '', valid: false },
      control_in: { value: '', valid: false },
      at_done_date: { value: '', valid: false },
      job_name: { value: '', valid: false },
      client_number: { value: '', valid: false },
      client_name: { value: '', valid: false },
      due_date: { value: '', valid: false },
      job_number: { value: '', valid: false },
      control_status: '',
      abgps: { value: '', valid: false },
      control_in: { value: '', valid: false },
      exposures: { value: '', valid: false },
      flight_lines: { value: '', valid: false },
      project_id: { value: '', valid: false },
      at_started: '',
      at_sub: 'No',
      at_done: 'No',
      enough_coverage: '',
      second_enough_coverage: '',
      control_results: { value: '', valid: true },
      second_control_results: { value: '', valid: true },
      at_staff: { value: '', valid: false },
      second_at_staff: { value: '', valid: false },
      total_hrs: { value: '', valid: true },
      photos_reviewed: '',
      second_photos_reviewed: '',
      on_hold: 'No',
      project_notes: { value: '', valid: true },
      aero_notes: { value: '', valid: true },
      comp_sub_notes: { value: '', valid: true },
      gsd_results: { value: '', valid: true },
      blinds: 'No',
      second_blinds: 'No',
      dsm_required: 'No',
      lidar_staff: { value: '', valid: true },
      lidar_started: 'No',
      lidar_on_hold: 'No',
      sa_results: { value: '', valid: true },
      total_tiles: { value: '', valid: true },
      lidar_blinds: 'No',
      lidar_enough_coverage: 'No',
      registration_results: { value: '', valid: true },
      lidar_done: 'No',
      problem_lidar: 'No',
      formErrors: {},
      formValid: false,
      editing: false
    }
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
  }

  static formValidations = {
    project_id: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    image_sub_name: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    at_sub_name: [
      (s) => { return (validations.checkMinLength(s, 3)) }
    ],
    at_sub_done_date: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    at_sub_date_rec: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    at_sub_date_sent: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    at_staff: [
      (s) => { return (validations.checkMinLength(s, 3)) }
    ],
    second_at_staff: [
      (s) => { return (validations.checkMinLength(s, 3)) }
    ],
    control_in: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    control_results: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    second_control_results: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    hrs_per_photo: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    total_hrs: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    review_photos: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    photo_scale: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    ci: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    map_scale: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    camera: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    roll_number: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    coordinate_system: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    at_done_date: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    control_in: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    problem: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    project_notes: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    aero_notes: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    comp_sub_notes: [
      (s) => { return (validations.checkMinLength(s, 0)) }
    ],
    gsd_results: [
      (s) => { return (validations.checkMinLength(s, 0)) }
    ],
    lidar_staff: [
      (s) => { return (validations.checkMinLength(s, 0)) }
    ],
    sa_results: [
      (s) => { return (validations.checkMinLength(s, 0)) }
    ],
    total_tiles: [
      (s) => { return (validations.checkMinLength(s, 0)) }
    ],
    registration_results: [
      (s) => { return (validations.checkMinLength(s, 0)) }
    ],
  }

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/cascopt/aerotriangulations/${this.props.match.params.id}`,
        dataType: "JSON"
      }).done((data) => {
        this.setState({
          gsd_results: { value: data.aerotriangulation.gsd_results, valid: true },
          imagery_lidar: data.flight.imagery_lidar,
          second_flight: data.flight.second_flight,
          image_sub_name: { value: data.aerotriangulation.image_sub_name, valid: true },
          image_sub: data.aerotriangulation.image_sub,
          at_sub_name: { value: data.aerotriangulation.at_sub_name, valid: true },
          at_sub_date_rec: { value: data.aerotriangulation.at_sub_date_rec, valid: true },
          at_sub_date_sent: { value: data.aerotriangulation.at_sub_date_sent, valid: true },
          at_sub_done: data.aerotriangulation.at_sub_done,
          at_sub_done_date: { value: data.aerotriangulation.at_sub_done_date, valid: true },
          problem_at: data.aerotriangulation.problem_at,
          problem: { value: data.aerotriangulation.problem, valid: true },
          control_in: { value: data.project.control_in, valid: true },
          at_done_date: { value: data.aerotriangulation.at_done_date, valid: true },
          job_name: { value: data.project.job_name, valid: true },
          control_status: data.project.control_status,
          client_number: { value: data.project.client_number, valid: true },
          client_name: { value: data.project.client_name, valid: true },
          due_date: { value: data.project.due_date, valid: true },
          job_number: { value: data.project.job_number, valid: true },
          abgps: { value: data.project.abgps, valid: true },
          exposures: { value: data.project.exposures, valid: true },
          flight_lines: { value: data.project.flight_lines, valid: true },
          project_id: { value: data.aerotriangulation.project_id, valid: true },
          at_started: data.aerotriangulation.at_started,
          at_sub: data.aerotriangulation.at_sub,
          at_done: data.aerotriangulation.at_done,
          enough_coverage: data.aerotriangulation.enough_coverage,
          second_enough_coverage: data.aerotriangulation.second_enough_coverage,
          control_results: { value: data.aerotriangulation.control_results, valid: true },
          second_control_results: { value: data.aerotriangulation.second_control_results, valid: true },
          at_staff: { value: data.aerotriangulation.at_staff, valid: true },
          second_at_staff: { value: data.aerotriangulation.second_at_staff, valid: true },
          total_hrs: { value: data.aerotriangulation.total_hrs, valid: true },
          photos_reviewed: data.aerotriangulation.photos_reviewed,
          second_photos_reviewed: data.aerotriangulation.second_photos_reviewed,
          on_hold: data.aerotriangulation.on_hold,
          project_notes: { value: data.project.project_notes, valid: true },
          aero_notes: { value: data.project.aero_notes, valid: true },
          comp_sub_notes: { value: data.project.comp_sub_notes, valid: true },
          blinds: data.aerotriangulation.blinds,
          second_blinds: data.aerotriangulation.second_blinds,
          dsm_required: data.aerotriangulation.dsm_required,
          lidar_staff: { value: data.aerotriangulation.lidar_staff, valid: true },
          lidar_started: data.aerotriangulation.lidar_started,
          lidar_on_hold: data.aerotriangulation.lidar_on_hold,
          sa_results: { value: data.aerotriangulation.sa_results, valid: true },
          total_tiles: { value: data.aerotriangulation.total_tiles, valid: true },
          lidar_blinds: data.aerotriangulation.lidar_blinds,
          lidar_enough_coverage: data.aerotriangulation.lidar_enough_coverage,
          registration_results: { value: data.aerotriangulation.registration_results, valid: true },
          lidar_done: data.aerotriangulation.lidar_done,
          problem_lidar: data.aerotriangulation.problem_lidar,
          editing: this.props.match.path === '/cascopt/aerotriangulations/:id/edit'
        });
      });
    }
  }

  handleUserInput = (fieldName, fieldValue, validations) => {
    const newFieldState = update(this.state[fieldName],
      { value: { $set: fieldValue } });
    this.setState({ [fieldName]: newFieldState },
      () => { this.validateField(fieldName, fieldValue, validations) });
  }

  validateField(fieldName, fieldValue, validations) {
    let fieldValid;

    let fieldErrors = validations.reduce((errors, v) => {
      let e = v(fieldValue);
      if (e !== '') {
        errors.push(e);
      }
      return (errors);
    }, []);

    fieldValid = fieldErrors.length === 0;

    const newFieldState = update(this.state[fieldName],
      { valid: { $set: fieldValid } });

    const newFormErrors = update(this.state.formErrors,
      { $merge: { [fieldName]: fieldErrors } });

    this.setState({
      [fieldName]: newFieldState,
      formErrors: newFormErrors
    }, this.validateForm);
  }

  validateForm() {
    this.setState({
      formValid: this.state.project_id &&
        this.state.at_staff &&
        this.state.second_at_staff &&
        this.state.at_sub_name &&
        this.state.at_sub_date_rec &&
        this.state.at_sub_date_sent &&
        this.state.at_sub_done_date &&
        this.state.control_results &&
        this.state.second_control_results &&
        this.state.hrs_per_photo &&
        this.state.total_hrs &&
        this.state.review_photos &&
        this.state.photo_scale &&
        this.state.ci &&
        this.state.map_scale &&
        this.state.camera &&
        this.state.roll_number &&
        this.state.coordinate_system &&
        this.state.at_done_date &&
        this.state.problem &&
        this.state.project_notes &&
        this.state.aero_notes &&
        this.state.comp_sub_notes &&
        this.state.image_sub_name &&
        this.state.gsd_results &&
        this.state.lidar_staff &&
        this.state.sa_results &&
        this.state.total_tiles &&
        this.state.registration_results
    });
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.state.editing ?
      this.updateAerotriangulation() :
      this.addAerotriangulation();
  }

  updateAerotriangulation() {
    const aerotriangulation = {
      gsd_results: this.state.gsd_results.value,
      image_sub_name: this.state.image_sub_name.value,
      image_sub: this.state.image_sub,
      at_sub_name: this.state.at_sub_name.value,
      at_sub_date_rec: this.state.at_sub_date_rec.value,
      at_sub_date_sent: this.state.at_sub_date_sent.value,
      at_sub_done_date: this.state.at_sub_done_date.value,
      at_sub_done: this.state.at_sub_done,
      problem_at: this.state.problem_at,
      problem: this.state.problem.value,
      control_status: this.state.control_status,
      at_done_date: this.state.at_done_date.value,
      project_id: this.state.project_id.value,
      at_started: this.state.at_started,
      at_sub: this.state.at_sub,
      at_done: this.state.at_done,
      enough_coverage: this.state.enough_coverage,
      control_results: this.state.control_results.value,
      at_staff: this.state.at_staff.value,
      second_enough_coverage: this.state.second_enough_coverage,
      second_control_results: this.state.second_control_results.value,
      second_at_staff: this.state.second_at_staff.value,
      total_hrs: this.state.total_hrs.value,
      photos_reviewed: this.state.photos_reviewed,
      second_photos_reviewed: this.state.second_photos_reviewed,
      on_hold: this.state.on_hold,
      project_notes: this.state.project_notes.value,
      aero_notes: this.state.aero_notes.value,
      comp_sub_notes: this.state.comp_sub_notes.value,
      blinds: this.state.blinds,
      second_blinds: this.state.second_blinds,
      dsm_required: this.state.dsm_required,
      lidar_staff: this.state.lidar_staff.value,
      lidar_started: this.state.lidar_started,
      lidar_on_hold: this.state.lidar_on_hold,
      sa_results: this.state.sa_results.value,
      total_tiles: this.state.total_tiles.value,
      lidar_blinds: this.state.lidar_blinds,
      lidar_enough_coverage: this.state.lidar_enough_coverage,
      registration_results: this.state.registration_results.value,
      lidar_done: this.state.lidar_done,
      problem_lidar: this.state.problem_lidar
    };
    $.ajax({
      type: "PATCH",
      url: `/cascopt/aerotriangulations/${this.props.match.params.id}`,
      data: { aerotriangulation: aerotriangulation }
    })
      .done((data) => {
        console.log('AT updated!');
        this.resetFormErrors();
        this.props.history.goBack('/cascopt/aerotriangulations');
      })
      .fail((response) => {
        alert("Please review the form for errors");
        this.setState({
          formErrors: response.responseJSON,
          formValid: false
        })
      });
  }

  addAerotriangulation() {
    const aerotriangulation = {
      gsd_results: this.state.gsd_results.value,
      image_sub_name: this.state.image_sub_name.value,
      image_sub: this.state.image_sub,
      at_sub_name: this.state.at_sub_name.value,
      at_sub_date_rec: this.state.at_sub_date_rec.value,
      at_sub_date_sent: this.state.at_sub_date_sent.value,
      at_sub_done_date: this.state.at_sub_done_date.value,
      at_sub_done: this.state.at_sub_done,
      problem_at: this.state.problem_at,
      problem: this.state.problem.value,
      control_status: this.state.control_status,
      at_done_date: this.state.at_done_date.value,
      project_id: this.state.project_id.value,
      at_started: this.state.at_started,
      at_sub: this.state.at_sub,
      at_done: this.state.at_done,
      enough_coverage: this.state.enough_coverage,
      control_results: this.state.control_results.value,
      at_staff: this.state.at_staff.value,
      second_enough_coverage: this.state.second_enough_coverage,
      second_control_results: this.state.second_control_results.value,
      second_at_staff: this.state.second_at_staff.value,
      total_hrs: this.state.total_hrs.value,
      photos_reviewed: this.state.photos_reviewed,
      second_photos_reviewed: this.state.second_photos_reviewed,
      on_hold: this.state.on_hold,
      project_notes: this.state.project_notes.value,
      aero_notes: this.state.aero_notes.value,
      comp_sub_notes: this.state.comp_sub_notes.value,
      blinds: this.state.blinds,
      second_blinds: this.state.second_blinds,
      dsm_required: this.state.dsm_required,
      lidar_staff: this.state.lidar_staff.value,
      lidar_started: this.state.lidar_started,
      lidar_on_hold: this.state.lidar_on_hold,
      sa_results: this.state.sa_results.value,
      total_tiles: this.state.total_tiles.value,
      lidar_blinds: this.state.lidar_blinds,
      lidar_enough_coverage: this.state.lidar_enough_coverage,
      registration_results: this.state.registration_results.value,
      lidar_done: this.state.lidar_done,
      problem_lidar: this.state.problem_lidar,
    };
    $.post('/cascopt/aerotriangulations', { aerotriangulation: aerotriangulation })
      .done((data) => {
        this.props.handleNewAerotriangulation(data);
        this.resetFormErrors();
      })
      .fail((response) => {
        this.setState({ formErrors: response.responseJSON, formValid: false })
      });
  }

  deleteAerotriangulation = () => {
    if (confirm("Are you sure you want to delete this AT?")) {
      $.ajax({
        type: "DELETE",
        url: `/cascopt/aerotriangulations/${this.props.match.params.id}`,
      })
        .done((data) => {
          this.props.history.push('/cascopt/aerotriangulations');
          this.resetFormErrors();
        })
        .fail((response) => {
          console.log("AT deletion failed!");
        });
    }
  }

  resetFormErrors() {
    this.setState({ formErrors: {} })
  }

  handleChange(e) {
    this.handleUserInput(e.target.name, e.target.value, AerotriangulationForm.formValidations[e.target.name]);
  }

  handleSelectChange(key) {
    return function ({ value }) {
      this.setState({ [key]: value });
    }.bind(this);
  }

  render() {
    return (
      <div className="container" id="form-card">
        <h4 className="mb-4 text-4xl">
          {this.state.editing ? "Update AT " : "Create AT "}
          for {this.state.job_name.value || ""} |{" "}
          {this.state.client_number.value || ""}-
          {this.state.job_number.value || ""}
        </h4>
        {this.state.problem_at == "Yes" && (
          <div>
            <h5 style={{ color: "red" }}>
              The Problem w/ AT field is marked as <b>Yes</b>, be sure to update
              the field if the problem has been resolved!
            </h5>
          </div>
        )}
        <div id="pt-view-project-block">
          <h5>Project Details</h5>
          Job: {this.state.job_name.value || ""}
          <br />
          Due: {moment(this.state.due_date.value || "").format("MM/DD/YYYY")}
          <br />
          Client: {this.state.client_name.value}
          <br />
          Control Status: {this.state.control_status} | ABGPS:{" "}
          {this.state.abgps.value} | Control In:{" "}
          {moment(this.state.control_in.value).format("MM/DD/YYYY")}| Exposures:{" "}
          {this.state.exposures.value} | Flight Lines:{" "}
          {this.state.flight_lines.value}
        </div>
        <hr />
        <small>
          (* represents a <strong>required field</strong>)
        </small>
        <FormErrors formErrors={this.state.formErrors} />
        <form onSubmit={this.handleFormSubmit}>
          <div className="row">
            <div className="col-md-3">
              <label>DSM Required *</label>
              <Select
                name="dsm_required"
                value={this.state.dsm_required}
                onChange={this.handleSelectChange("dsm_required")}
                required
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col">
              <label>AT Staff *</label>
              <input
                name="at_staff"
                placeholder="AT Staff"
                value={this.state.at_staff.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col">
              <label>AT Started *</label>
              <Select
                name="at_started"
                value={this.state.at_started}
                onChange={this.handleSelectChange("at_started")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
            <div className="col">
              <label>On Hold *</label>
              <Select
                name="on_hold"
                value={this.state.on_hold}
                onChange={this.handleSelectChange("on_hold")}
                options={[
                  { value: "Boundary", label: "Boundary" },
                  { value: "Client", label: "Client" },
                  { value: "Flight", label: "Flight" },
                  { value: "Waiting on PID", label: "Waiting on PID" },
                  { value: "Pick PIDs", label: "Pick PIDs" },
                  { value: "Survey", label: "Survey" },
                  { value: "No", label: "No" },
                ]}
              />
            </div>
            <div className="col">
              <label>GSD Results *</label>
              <input
                name="gsd_results"
                placeholder="GSD Results"
                value={this.state.gsd_results.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
          </div>

          <p />
          <div className="row">
            <div className="col">
              <label>Control Status *</label>
              <input
                name="control_status"
                readOnly
                value={this.state.control_status}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col">
              <label>Control In *</label>
              <input
                type="date"
                name="control_in"
                readOnly
                value={this.state.control_in.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col">
              <label>Exposures *</label>
              <input
                name="exposures"
                placeholder="Exposures"
                readOnly
                value={this.state.exposures.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col">
              <label>Photos Reviewed *</label>
              <Select
                name="photos_reviewed"
                value={this.state.photos_reviewed}
                onChange={this.handleSelectChange("photos_reviewed")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
            <div className="col">
              <label>AT Sub *</label>
              <Select
                name="at_sub"
                value={this.state.at_sub}
                onChange={this.handleSelectChange("at_sub")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
          </div>
          <p />
          {this.state.at_sub == "Yes" && (
            <div>
              <br />
              <div className="row">
                <div className="col-md-3">
                  <label>AT QC Staff Name *</label>
                  <input
                    type="text"
                    name="at_sub_name"
                    value={this.state.at_sub_name.value}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>AT Sub, Date Sent *</label>
                  <input
                    type="date"
                    name="at_sub_date_sent"
                    value={this.state.at_sub_date_sent.value}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>AT Sub, Date Received *</label>
                  <input
                    type="date"
                    name="at_sub_date_rec"
                    value={this.state.at_sub_date_rec.value}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>AT Sub Done *</label>
                  <Select
                    name="at_sub_done"
                    value={this.state.at_sub_done}
                    onChange={this.handleSelectChange("at_sub_done")}
                    options={[
                      { value: "No", label: "No" },
                      { value: "Yes", label: "Yes" },
                    ]}
                  />
                </div>
              </div>
            </div>
          )}

          <p />
          <div className="row">
            <div className="col">
              <label>Blinds *</label>
              <Select
                name="blinds"
                value={this.state.blinds}
                onChange={this.handleSelectChange("blinds")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
            <div className="col">
              <label>Enough Coverage *</label>
              <Select
                name="enough_coverage"
                value={this.state.enough_coverage}
                onChange={this.handleSelectChange("enough_coverage")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
            <div className="col">
              <label>Control Results *</label>
              <input
                name="control_results"
                placeholder="Control Results"
                value={this.state.control_results.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
          </div>
          <p />
          {this.state.second_flight == "Yes" &&
            this.state.imagery_lidar == "Imagery" && (
              <div>
                <hr />
                <h5>
                  Secondary AT Information (if project has 2 imagery flights)
                </h5>
                <hr />
                <div className="row">
                  <div className="col">
                    <label>AT Staff *</label>
                    <input
                      name="second_at_staff"
                      placeholder="AT Staff"
                      value={this.state.second_at_staff.value}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label>Photos Reviewed *</label>
                    <Select
                      name="second_photos_reviewed"
                      value={this.state.second_photos_reviewed}
                      onChange={this.handleSelectChange(
                        "second_photos_reviewed"
                      )}
                      options={[
                        { value: "No", label: "No" },
                        { value: "Yes", label: "Yes" },
                      ]}
                    />
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Blinds *</label>
                    <Select
                      name="second_blinds"
                      value={this.state.second_blinds}
                      onChange={this.handleSelectChange("second_blinds")}
                      options={[
                        { value: "No", label: "No" },
                        { value: "Yes", label: "Yes" },
                      ]}
                    />
                  </div>
                  <div className="col">
                    <label>Enough Coverage *</label>
                    <Select
                      name="second_enough_coverage"
                      value={this.state.second_enough_coverage}
                      onChange={this.handleSelectChange(
                        "second_enough_coverage"
                      )}
                      options={[
                        { value: "No", label: "No" },
                        { value: "Yes", label: "Yes" },
                      ]}
                    />
                  </div>
                  <div className="col">
                    <label>Control Results *</label>
                    <input
                      name="second_control_results"
                      placeholder="Control Results"
                      value={this.state.second_control_results.value}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            )}

          <p />
          <div className="row">
            <div className="col-md-2">
              <label>AT Done *</label>
              <Select
                name="at_done"
                value={this.state.at_done}
                onChange={this.handleSelectChange("at_done")}
                options={[
                  { value: "MODDRC", label: "MODDRC" },
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
            {this.state.at_done == "Yes" && (
              <div className="col-md-2">
                <label>AT Done Date *</label>
                <input
                  type="date"
                  name="at_done_date"
                  value={this.state.at_done_date.value}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            )}
          </div>
          <p />
          <div className="row">
            <div className="col-md-2">
              <label>Problem w/ AT</label>
              <Select
                name="problem_at"
                value={this.state.problem_at}
                onChange={this.handleSelectChange("problem_at")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
            {this.state.problem_at == "Yes" && (
              <div className="col">
                <label>Problem</label>
                <textarea
                  name="problem"
                  placeholder="AT Problem"
                  value={this.state.problem.value}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            )}
          </div>
          <p />
          {/* start of lidar fields */}
          <div className="row">
            <div className="col">
              <label>Registration Staff *</label>
              <input
                name="lidar_staff"
                placeholder="Registration Staff"
                value={this.state.lidar_staff.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col">
              <label>LiDAR Started *</label>
              <Select
                name="lidar_started"
                value={this.state.lidar_started}
                onChange={this.handleSelectChange("lidar_started")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
            <div className="col">
              <label>On Hold *</label>
              <Select
                name="lidar_on_hold"
                value={this.state.lidar_on_hold}
                onChange={this.handleSelectChange("lidar_on_hold")}
                options={[
                  { value: "Boundary", label: "Boundary" },
                  { value: "Client", label: "Client" },
                  { value: "Flight", label: "Flight" },
                  { value: "Waiting on PID", label: "Waiting on PID" },
                  { value: "Pick PIDs", label: "Pick PIDs" },
                  { value: "Survey", label: "Survey" },
                  { value: "No", label: "No" },
                ]}
              />
            </div>
            <div className="col">
              <label>SA Results *</label>
              <input
                name="sa_results"
                placeholder="SA Results"
                value={this.state.sa_results.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col">
              <label>Total Tiles *</label>
              <input
                name="total_tiles"
                placeholder="Total Tiles"
                value={this.state.total_tiles.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col">
              <label>LiDAR Blinds *</label>
              <Select
                name="lidar_blinds"
                value={this.state.lidar_blinds}
                onChange={this.handleSelectChange("lidar_blinds")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
            <div className="col">
              <label>LiDAR Enough Coverage *</label>
              <Select
                name="lidar_enough_coverage"
                value={this.state.lidar_enough_coverage}
                onChange={this.handleSelectChange("lidar_enough_coverage")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
            <div className="col">
              <label>Registration Results *</label>
              <input
                name="registration_results"
                placeholder="Registration Results"
                value={this.state.registration_results.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col-md-2">
              <label>LiDAR Done *</label>
              <Select
                name="lidar_done"
                value={this.state.lidar_done}
                onChange={this.handleSelectChange("lidar_done")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col-md-2">
              <label>Problem w/ LiDAR</label>
              <Select
                name="problem_lidar"
                value={this.state.problem_lidar}
                onChange={this.handleSelectChange("problem_lidar")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
          </div>
          <p />
          {/* end of lidar fields */}
          <div className="row">
            <div className="col-md-2">
              <label>Image Sub</label>
              <Select
                name="image_sub"
                value={this.state.image_sub}
                onChange={this.handleSelectChange("image_sub")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
            {this.state.image_sub == "Yes" && (
              <div className="col-md-2">
                <label>Image Sub Name</label>
                <input
                  type="text"
                  name="image_sub_name"
                  placeholder="AT Sub Name"
                  value={this.state.image_sub_name.value}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            )}
          </div>
          <p />
          <div className="row">
            <div className="col">
              <label>CompSub Notes *</label>
              <textarea
                name="comp_sub_notes"
                placeholder="CompSub Notes"
                value={this.state.comp_sub_notes.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col">
              <label>AT Notes *</label>
              <textarea
                name="aero_notes"
                placeholder="Notes"
                value={this.state.aero_notes.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
          </div>
          <p />

          <div className="row">
            <div className="col">
              <label>Project Notes</label>
              <textarea
                name="project_notes"
                placeholder="Project Notes"
                value={this.state.project_notes.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
          </div>

          <p />
          <input
            type="submit"
            value={this.state.editing ? "Update" : "Create"}
            className="btn btn-secondary btn-sm"
          />
          <a
            className="btn btn-danger btn-sm"
            onClick={this.props.history.goBack}
          >
            Cancel
          </a>
        </form>
        <br />
      </div>
    );
  }
}
