/* eslint-disable react/prefer-stateless-function */
import React from 'react';

export default class Survey extends React.Component {
  render() {
    return (
      <div>
        <h3 id="header">Projects in Survey</h3>
      </div>
    );
  }
}
