import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import 'react-select/dist/react-select.css';

export default class QuoteForm extends React.Component {
  static propTypes = {
    handleNewQuote: PropTypes.func
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      isHidden: true,
      plot_shipping: "0",
      flight_sub: "",
      flight_sub_options: [
        { value: "Cooper Aerial", label: "Cooper Aerial" },
        { value: "Geomni", label: "Geomni" },
        { value: "Verisk", label: "Verisk" },
        { value: "NAAS", label: "NAAS" },
        { value: "SWAS", label: "SWAS" },
        { value: "SAM", label: "SAM" },
        { value: "CSTI", label: "CSTI" },
        { value: "Argos", label: "Argos" },
        { value: "Talos Aviation", label: "Talos Aviation" },
        { value: "Paragon", label: "Paragon" },
        { value: "Atlantic Group", label: "Atlantic Group" },
        { value: "Aero-Graphics", label: "Aero-Graphics" },
        { value: "Keystone Aerial", label: "Keystone Aerial" },
        { value: "American Aerial", label: "American Aerial" },
        { value: "Valley Air", label: "Valley Air" },
        { value: "Radman Aerial", label: "Radman Aerial" },
        { value: "Air Flight SVCS", label: "Air Flight SVCS" },
        {
          value: "Skyview Aerial Photo (South CA)",
          label: "Skyview Aerial Photo (South CA)",
        },
        { value: "Midwest Aerial Photo", label: "Midwest Aerial Photo" },
        { value: "Gammair", label: "Gammair" },
        {
          value: "Richard Crouse and Associates",
          label: "Richard Crouse and Associates",
        },
        {
          value: "I.F. Rooks & Associates, Inc.",
          label: "I.F. Rooks & Associates, Inc.",
        },
        {
          value: "Aerial Data Service, Inc.",
          label: "Aerial Data Service, Inc.",
        },
        { value: "Aerodata, Inc.", label: "Aerodata, Inc." },
        { value: "Lasermap USA", label: "Lasermap USA" },
        { value: "Aerials, Inc.", label: "Aerials, Inc." },
        {
          value: "Gulf Coast Aerial Mapping",
          label: "Gulf Coast Aerial Mapping",
        },
        { value: "Air Photographics, Inc.", label: "Air Photographics, Inc." },
        { value: "Ace Aerial", label: "Ace Aerial" },
        { value: "Georgia Aerial Surveys", label: "Georgia Aerial Surveys" },
        {
          value: "Krawietz Aerial Photo Service",
          label: "Krawietz Aerial Photo Service",
        },
        {
          value: "Continental Aerial Surveys Inc.",
          label: "Continental Aerial Surveys Inc.",
        },
        { value: "Aerial Imaging Inc.", label: "Aerial Imaging Inc." },
        {
          value: "McClintock Land Associates, Inc.",
          label: "McClintock Land Associates, Inc.",
        },
        { value: "Eagle Aerial Solutions", label: "Eagle Aerial Solutions" },
        {
          value: "Rocky Mountain Aerial Surveys",
          label: "Rocky Mountain Aerial Surveys",
        },
        { value: "Blue Skies Consulting", label: "Blue Skies Consulting" },
        { value: "GeoTerra", label: "GeoTerra" },
        { value: "Quantum Spatial", label: "Quantum Spatial" },
        { value: "Aerocon", label: "Aerocon" },
        { value: "Pickett Aerial", label: "Pickett Aerial" },
        { value: "Airborne Sensing", label: "Airborne Sensing" },
        { value: "MAP RES", label: "MAP RES" },
        { value: "Predictive Service", label: "Predictive Service" },
        { value: "Wilson Company", label: "Wilson Company" },
        { value: "KBM, Inc.", label: "KBM, Inc." },
        { value: "Air Flight Service", label: "Air Flight Service" },
        { value: "Lidar Services LLC", label: "Lidar Services LLC" },
        { value: "Eagle Mapping", label: "Eagle Mapping" },
      ],
      flight_quote: "0",
      flight_markup: "0",
      flight_cost: "0",
      flight_notes: "",
      panels: "0",
      abgps: "",
      survey_sub: "",
      survey_cost: "0",
      survey_quote: "0",
      survey_markup: "0",
      survey_notes: "",
      contact_prints: "0",
      contact_prints_cp: "0",
      diapositives: "0",
      diapositives_cp: "0",
      scan_cp: "0",
      lab_discount: "0",
      lab_cost: "0",
      lab_notes: "",
      analytics_hp: "0",
      analytics_cp: "0",
      photogrammetry_cp: "0",
      photogrammetry_hp: "0",
      xsec: "0",
      xsec_cp: "0",
      xsec_hp: "0",
      cartography_cp: "0",
      cartography_hp: "0",
      translation: "0",
      translation_cp: "0",
      translation_hp: "0",
      map_discount: "0",
      map_cost: "0",
      img_hp: "0",
      img_cp: "0",
      plots: "0",
      plot_x: "",
      plot_y: "",
      plot_cp: "0",
      delivery: "0",
      delivery_type: "",
      delivery_type_options: [
        { value: "Shipped", label: "Shipped" },
        { value: "Client Pickup", label: "Client Pickup" },
        { value: "Local Delivery", label: "Local Delivery" },
        {
          value: "Local Delivery and Install",
          label: "Local Delivery and Install",
        },
        { value: "Long Distance Delivery", label: "Long Distance Delivery" },
      ],
      delivery_cp: "0",
      img_discount: "0",
      img_cost: "0",
      quote_cost: "",
      map_notes: "",
      img_notes: "",
      plot_mount: "",
      camera: "",
      supplemental_survey: "",
      plain_gc: "",
      description: "",
      sales_tax: "0",
      unit_price: "0",
      shipping: "0",
      discount: "0",
      editing: false,
      additionalPlots: Object.keys(props.plots).map((key) => props.plots[key]),
    };
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if(this.props.match) {
    $.ajax({
      type: "GET",
      url: `/quotes/${this.props.match.params.id}`,
      dataType: "JSON"
    }).done((data) => {
      this.setState({
        user_id: data.quote.user_id,
        plot_shipping: data.quote.plot_shipping,
        opportunity_id: data.quote.opportunity_id,
        specification_id: data.quote.specification_id,
        flight_sub: data.quote.flight_sub,
        flight_quote: data.quote.flight_quote,
        flight_markup: data.quote.flight_markup,
        flight_cost: data.quote.flight_cost,
        flight_notes: data.quote.flight_notes,
        panels: data.quote.panels,
        abgps: data.quote.abgps,
        survey_sub: data.quote.survey_sub,
        survey_cost: data.quote.survey_cost,
        survey_quote: data.quote.survey_quote,
        survey_markup: data.quote.survey_markup,
        survey_notes: data.quote.survey_notes,
        contact_prints: data.quote.contact_prints,
        contact_prints_cp: data.quote.contact_prints_cp,
        diapositives: data.quote.diapositives,
        diapositives_cp: data.quote.diapositives_cp,
        scan_cp: data.quote.scan_cp,
        lab_discount: data.quote.lab_discount,
        lab_cost: data.quote.lab_cost,
        lab_notes: data.quote.lab_notes,
        analytics_hp: data.quote.analytics_hp,
        analytics_cp: data.quote.analytics_cp,
        photogrammetry_cp: data.quote.photogrammetry_cp,
        photogrammetry_hp: data.quote.photogrammetry_hp,
        xsec: data.quote.xsec,
        xsec_cp: data.quote.xsec_cp,
        xsec_hp: data.quote.xsec_hp,
        cartography_cp: data.quote.cartography_cp,
        cartography_hp: data.quote.cartography_hp,
        translation: data.quote.translation,
        translation_cp: data.quote.translation_cp,
        translation_hp: data.quote.translation_hp,
        map_discount: data.quote.map_discount,
        map_cost: data.quote.map_cost,
        img_hp: data.quote.img_hp,
        img_cp: data.quote.img_cp,
        plots: data.quote.plots,
        plot_x: data.quote.plot_x,
        plot_y: data.quote.plot_y,
        plot_cp: data.quote.plot_cp,
        delivery: data.quote.delivery,
        delivery_type: data.quote.delivery_type,
        delivery_cp: data.quote.delivery_cp,
        img_discount: data.quote.img_discount,
        img_cost: data.quote.img_cost,
        quote_cost: data.quote.quote_cost,
        map_notes: data.quote.map_notes,
        img_notes: data.quote.img_notes,
        plot_mount: data.quote.plot_mount,
        camera: data.quote.camera,
        supplemental_survey: data.quote.supplemental_survey,
        plain_gc: data.quote.plain_gc,
        description: data.quote.description,
        sales_tax: data.quote.sales_tax,
        unit_price: data.quote.unit_price,
        shipping: data.quote.shipping,
        discount: data.quote.discount,
        quote_type: data.quote.quote_type,
        editing: this.props.match.path === '/quotes/:id/edit'
      });
    });
   }
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.state.editing ?
      this.updateQuote() :
      this.addQuote();
  }

  updateQuote() {
    const quote = {
      user_id: this.props.userId,
      plot_shipping: this.state.plot_shipping,
      opportunity_id: this.props.oppId,
      specification_id: this.props.specId,
      quote_type: this.props.quoteType,
      flight_sub: this.state.flight_sub,
      flight_quote: this.state.flight_quote,
      flight_markup: this.state.flight_markup,
      flight_cost: this.state.flight_cost,
      flight_notes: this.state.flight_notes,
      panels: this.state.panels,
      abgps: this.state.abgps,
      survey_sub: this.state.survey_sub,
      survey_cost: this.state.survey_cost,
      survey_quote: this.state.survey_quote,
      survey_markup: this.state.survey_markup,
      survey_notes: this.state.survey_notes,
      contact_prints: this.state.contact_prints,
      contact_prints_cp: this.state.contact_prints_cp,
      diapositives: this.state.diapositives,
      diapositives_cp: this.state.diapositives_cp,
      scan_cp: this.state.scan_cp,
      lab_discount: this.state.lab_discount,
      lab_cost: this.state.lab_cost,
      lab_notes: this.state.lab_notes,
      analytics_hp: this.state.analytics_hp,
      analytics_cp: this.state.analytics_cp,
      photogrammetry_cp: this.state.photogrammetry_cp,
      photogrammetry_hp: this.state.photogrammetry_hp,
      xsec: this.state.xsec,
      xsec_cp: this.state.xsec_cp,
      xsec_hp: this.state.xsec_hp,
      cartography_cp: this.state.cartography_cp,
      cartography_hp: this.state.cartography_hp,
      translation: this.state.translation,
      translation_cp: this.state.translation_cp,
      translation_hp: this.state.translation_hp,
      map_discount: this.state.map_discount,
      map_cost: this.state.map_cost,
      img_hp: this.state.img_hp,
      img_cp: this.state.img_cp,
      plots: this.props.plotQty,
      plot_x: this.props.plotLength,
      plot_y: this.props.plotWidth,
      plot_cp: this.state.plot_cp,
      delivery: this.state.delivery,
      delivery_type: this.state.delivery_type,
      delivery_cp: this.state.delivery_cp,
      img_discount: this.state.img_discount,
      img_cost: this.state.img_cost,
      quote_cost: this.state.quote_cost,
      map_notes: this.state.map_notes,
      img_notes: this.state.img_notes,
      plot_mount: this.props.plotMount,
      camera: this.state.camera,
      supplemental_survey: this.state.supplemental_survey,
      plain_gc: this.state.plain_gc,
      description: this.state.description,
      sales_tax: this.state.sales_tax,
      unit_price: this.state.unit_price,
      shipping: this.state.shipping,
      discount: this.state.discount
    };
    $.ajax({
      type: "PATCH",
      url: `/quotes/${this.props.match.params.id}`,
      data: { quote: quote }
    })
      .done((data) => {
        console.log('Quote updated!');
      })
      .fail((response) => {
        console.log("did not work");
        alert("Please review the form for errors");
      });
  }

  addQuote() {
    const quote = {
      user_id: this.props.userId,
      plot_shipping: this.state.plot_shipping,
      opportunity_id: this.props.oppId,
      specification_id: this.props.specId,
      quote_type: this.props.quoteType,
      flight_sub: this.state.flight_sub,
      flight_quote: this.state.flight_quote,
      flight_markup: this.state.flight_markup,
      flight_cost: this.state.flight_cost,
      flight_notes: this.state.flight_notes,
      panels: this.state.panels,
      abgps: this.state.abgps,
      survey_sub: this.state.survey_sub,
      survey_cost: this.state.survey_cost,
      survey_quote: this.state.survey_quote,
      survey_markup: this.state.survey_markup,
      survey_notes: this.state.survey_notes,
      contact_prints: this.state.contact_prints,
      contact_prints_cp: this.state.contact_prints_cp,
      diapositives: this.state.diapositives,
      diapositives_cp: this.state.diapositives_cp,
      scan_cp: this.state.scan_cp,
      lab_discount: this.state.lab_discount,
      lab_cost: this.state.lab_cost,
      lab_notes: this.state.lab_notes,
      analytics_hp: this.state.analytics_hp,
      analytics_cp: this.state.analytics_cp,
      photogrammetry_cp: this.state.photogrammetry_cp,
      photogrammetry_hp: this.state.photogrammetry_hp,
      xsec: this.state.xsec,
      xsec_cp: this.state.xsec_cp,
      xsec_hp: this.state.xsec_hp,
      cartography_cp: this.state.cartography_cp,
      cartography_hp: this.state.cartography_hp,
      translation: this.state.translation,
      translation_cp: this.state.translation_cp,
      translation_hp: this.state.translation_hp,
      map_discount: this.state.map_discount,
      map_cost: this.state.map_cost,
      img_hp: this.state.img_hp,
      img_cp: this.state.img_cp,
      plots: this.props.plotQty,
      plot_x: this.props.plotLength,
      plot_y: this.props.plotWidth,
      plot_cp: this.state.plot_cp,
      delivery: this.state.delivery,
      delivery_type: this.state.delivery_type,
      delivery_cp: this.state.delivery_cp,
      img_discount: this.state.img_discount,
      img_cost: this.state.img_cost,
      quote_cost: this.state.quote_cost,
      map_notes: this.state.map_notes,
      img_notes: this.state.img_notes,
      plot_mount: this.props.plotMount,
      camera: this.state.camera,
      supplemental_survey: this.state.supplemental_survey,
      plain_gc: this.state.plain_gc,
      description: this.state.description,
      sales_tax: this.state.sales_tax,
      unit_price: this.state.unit_price,
      shipping: this.state.shipping,
      discount: this.state.discount
    };
    $.post('/quotes', { quote: quote })
      .done((data) => {
        this.props.handleNewQuote(data);
        console.log("Quote Successfully Created!");
      })
      .fail((response) => {
        console.log("did not work");
        alert("Please review the form for errors");
      });
  }

  deleteQuote = () => {
    if (confirm("Are you sure you want to delete this quote?")) {
      $.ajax({
        type: "DELETE",
        url: `/quotes/${this.props.match.params.id}`,
      })
        .done((data) => {
          this.props.history.push('/quotes');
        })
        .fail((response) => {
          console.log("Quote deletion failed!");
        });
    }
  }

  handleChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  handleSelectChange(key) {
    return function ({ value }) {
      this.setState({ [key]: value });
    }.bind(this);
  }

  calculateCost(plot) {
    if (plot["size_x"] <= "42" && plot["size_y"] <= "42" && plot["laminated"] == "Yes" && plot["mounted"] == "3/16" && plot["frame"] != "None") {
      let additionalPlotTotal = 200 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] <= "42" && plot["size_y"] <= "42" && plot["laminated"] == "Yes" && plot["mounted"] == "3/16" && plot["frame"] == "None") {
      let additionalPlotTotal = 150 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] <= "42" && plot["size_y"] <= "42" && plot["laminated"] == "Yes" && plot["mounted"] == "1/2" && plot["frame"] != "None") {
      let additionalPlotTotal = 225 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] <= "42" && plot["size_y"] <= "42" && plot["laminated"] == "Yes" && plot["mounted"] == "1/2" && plot["frame"] == "None") {
      let additionalPlotTotal = 175 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] <= "42" && plot["size_y"] <= "42" && plot["laminated"] == "No" && plot["mounted"] == "3/16" && plot["frame"] != "None") {
      let additionalPlotTotal = 150 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] <= "42" && plot["size_y"] <= "42" && plot["laminated"] == "No" && plot["mounted"] == "3/16" && plot["frame"] == "None") {
      let additionalPlotTotal = 100 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] <= "42" && plot["size_y"] <= "42" && plot["laminated"] == "No" && plot["mounted"] == "1/2" && plot["frame"] != "None") {
      let additionalPlotTotal = 175 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] <= "42" && plot["size_y"] <= "42" && plot["laminated"] == "No" && plot["mounted"] == "1/2" && plot["frame"] == "None") {
      let additionalPlotTotal = 125 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] <= "42" && plot["size_y"] <= "42" && plot["laminated"] == "Yes" && plot["mounted"] == "None" && plot["frame"] != "None") {
      let additionalPlotTotal = 150 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] <= "42" && plot["size_y"] <= "42" && plot["laminated"] == "Yes" && plot["mounted"] == "None" && plot["frame"] == "None") {
      let additionalPlotTotal = 100 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] <= "42" && plot["size_y"] <= "42" && plot["laminated"] == "No" && plot["mounted"] == "None" && plot["frame"] != "None") {
      let additionalPlotTotal = 100 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] <= "42" && plot["size_y"] <= "42" && plot["laminated"] == "No" && plot["mounted"] == "None" && plot["frame"] == "None") {
      let additionalPlotTotal = 50 * plot["quantity"];
      return additionalPlotTotal;
      {/* plots where longest side is 42 - 56 and shorter side is < 42 */}
    } else if (plot["size_x"] >= "42" && plot["size_x"] <= "56" && plot["size_y"] <= "42" || plot["size_x"] <= "42" && plot["size_y"] >= "42" && plot["size_y"] <= "56" && plot["laminated"] == "Yes" && plot["mounted"] == "3/16" && plot["frame"] != "None") {
      let additionalPlotTotal = 275 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "42" && plot["size_x"] <= "56" && plot["size_y"] <= "42" || plot["size_x"] <= "42" && plot["size_y"] >= "42" && plot["size_y"] <= "56" && plot["laminated"] == "Yes" && plot["mounted"] == "3/16" && plot["frame"] == "None") {
      let additionalPlotTotal = 225 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "42" && plot["size_x"] <= "56" && plot["size_y"] <= "42" || plot["size_x"] <= "42" && plot["size_y"] >= "42" && plot["size_y"] <= "56" && plot["laminated"] == "Yes" && plot["mounted"] == "1/2" && plot["frame"] != "None") {
      let additionalPlotTotal = 325 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "42" && plot["size_x"] <= "56" && plot["size_y"] <= "42" || plot["size_x"] <= "42" && plot["size_y"] >= "42" && plot["size_y"] <= "56" && plot["laminated"] == "Yes" && plot["mounted"] == "1/2" && plot["frame"] == "None") {
      let additionalPlotTotal = 225 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "42" && plot["size_x"] <= "56" && plot["size_y"] <= "42" || plot["size_x"] <= "42" && plot["size_y"] >= "42" && plot["size_y"] <= "56" && plot["laminated"] == "No" && plot["mounted"] == "3/16" && plot["frame"] != "None") {
      let additionalPlotTotal = 200 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "42" && plot["size_x"] <= "56" && plot["size_y"] <= "42" || plot["size_x"] <= "42" && plot["size_y"] >= "42" && plot["size_y"] <= "56" && plot["laminated"] == "No" && plot["mounted"] == "3/16" && plot["frame"] == "None") {
      let additionalPlotTotal = 150 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "42" && plot["size_x"] <= "56" && plot["size_y"] <= "42" || plot["size_x"] <= "42" && plot["size_y"] >= "42" && plot["size_y"] <= "56" && plot["laminated"] == "No" && plot["mounted"] == "1/2" && plot["frame"] != "None") {
      let additionalPlotTotal = 250 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "42" && plot["size_x"] <= "56" && plot["size_y"] <= "42" || plot["size_x"] <= "42" && plot["size_y"] >= "42" && plot["size_y"] <= "56" && plot["laminated"] == "No" && plot["mounted"] == "1/2" && plot["frame"] == "None") {
      let additionalPlotTotal = 200 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "42" && plot["size_x"] <= "56" && plot["size_y"] <= "42" || plot["size_x"] <= "42" && plot["size_y"] >= "42" && plot["size_y"] <= "56" && plot["laminated"] == "Yes" && plot["mounted"] == "None" && plot["frame"] != "None") {
      let additionalPlotTotal = 200 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "42" && plot["size_x"] <= "56" && plot["size_y"] <= "42" || plot["size_x"] <= "42" && plot["size_y"] >= "42" && plot["size_y"] <= "56" && plot["laminated"] == "Yes" && plot["mounted"] == "None" && plot["frame"] == "None") {
      let additionalPlotTotal = 150 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "42" && plot["size_x"] <= "56" && plot["size_y"] <= "42" || plot["size_x"] <= "42" && plot["size_y"] >= "42" && plot["size_y"] <= "56" && plot["laminated"] == "No" && plot["mounted"] == "None" && plot["frame"] != "None") {
      let additionalPlotTotal = 125 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "42" && plot["size_x"] <= "56" && plot["size_y"] <= "42" || plot["size_x"] <= "42" && plot["size_y"] >= "42" && plot["size_y"] <= "56" && plot["laminated"] == "No" && plot["mounted"] == "None" && plot["frame"] == "None") {
      let additionalPlotTotal = 75 * plot["quantity"];
      return additionalPlotTotal;
      {/* plots where longest side is 56 - 80 and shorter side is > 42 */}
    } else if (plot["size_x"] >= "56" && plot["size_x"] <= "80" && plot["size_y"] <= "42" || plot["size_x"] <= "42" && plot["size_y"] >= "56" && plot["size_y"] <= "80" && plot["laminated"] == "Yes" && plot["mounted"] == "3/16" && plot["frame"] != "None") {
      let additionalPlotTotal = 400 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "56" && plot["size_x"] <= "80" && plot["size_y"] <= "42" || plot["size_x"] <= "42" && plot["size_y"] >= "56" && plot["size_y"] <= "80" && plot["laminated"] == "Yes" && plot["mounted"] == "3/16" && plot["frame"] == "None") {
      let additionalPlotTotal = 300 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "56" && plot["size_x"] <= "80" && plot["size_y"] <= "42" || plot["size_x"] <= "42" && plot["size_y"] >= "56" && plot["size_y"] <= "80" && plot["laminated"] == "Yes" && plot["mounted"] == "1/2" && plot["frame"] != "None") {
      let additionalPlotTotal = 475 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "56" && plot["size_x"] <= "80" && plot["size_y"] <= "42" || plot["size_x"] <= "42" && plot["size_y"] >= "56" && plot["size_y"] <= "80" && plot["laminated"] == "Yes" && plot["mounted"] == "1/2" && plot["frame"] == "None") {
      let additionalPlotTotal = 375 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "56" && plot["size_x"] <= "80" && plot["size_y"] <= "42" || plot["size_x"] <= "42" && plot["size_y"] >= "56" && plot["size_y"] <= "80" && plot["laminated"] == "No" && plot["mounted"] == "3/16" && plot["frame"] != "None") {
      let additionalPlotTotal = 300 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "56" && plot["size_x"] <= "80" && plot["size_y"] <= "42" || plot["size_x"] <= "42" && plot["size_y"] >= "56" && plot["size_y"] <= "80" && plot["laminated"] == "No" && plot["mounted"] == "3/16" && plot["frame"] == "None") {
      let additionalPlotTotal = 200 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "56" && plot["size_x"] <= "80" && plot["size_y"] <= "42" || plot["size_x"] <= "42" && plot["size_y"] >= "56" && plot["size_y"] <= "80" && plot["laminated"] == "No" && plot["mounted"] == "1/2" && plot["frame"] != "None") {
      let additionalPlotTotal = 375 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "56" && plot["size_x"] <= "80" && plot["size_y"] <= "42" || plot["size_x"] <= "42" && plot["size_y"] >= "56" && plot["size_y"] <= "80" && plot["laminated"] == "No" && plot["mounted"] == "1/2" && plot["frame"] == "None") {
      let additionalPlotTotal = 275 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "56" && plot["size_x"] <= "80" && plot["size_y"] <= "42" || plot["size_x"] <= "42" && plot["size_y"] >= "56" && plot["size_y"] <= "80" && plot["laminated"] == "Yes" && plot["mounted"] == "None" && plot["frame"] != "None") {
      let additionalPlotTotal = 300 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "56" && plot["size_x"] <= "80" && plot["size_y"] <= "42" || plot["size_x"] <= "42" && plot["size_y"] >= "56" && plot["size_y"] <= "80" && plot["laminated"] == "Yes" && plot["mounted"] == "None" && plot["frame"] == "None") {
      let additionalPlotTotal = 200 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "56" && plot["size_x"] <= "80" && plot["size_y"] <= "42" || plot["size_x"] <= "42" && plot["size_y"] >= "56" && plot["size_y"] <= "80" && plot["laminated"] == "No" && plot["mounted"] == "None" && plot["frame"] != "None") {
      let additionalPlotTotal = 200 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "56" && plot["size_x"] <= "80" && plot["size_y"] <= "42" || plot["size_x"] <= "42" && plot["size_y"] >= "56" && plot["size_y"] <= "80" && plot["laminated"] == "No" && plot["mounted"] == "None" && plot["frame"] == "None") {
      let additionalPlotTotal = 100 * plot["quantity"];
      return additionalPlotTotal;
      {/* plots where longest side is 56 - 80 and shorter side is 42 - 90 */}
    } else if (plot["size_x"] >= "56" && plot["size_x"] <= "80" && plot["size_y"] >= "42" && plot["laminated"] == "Yes" && plot["mounted"] == "3/16" && plot["frame"] != "None") {
      let additionalPlotTotal = 800 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "56" && plot["size_x"] <= "80" && plot["size_y"] >= "42" && plot["laminated"] == "Yes" && plot["mounted"] == "3/16" && plot["frame"] == "None") {
      let additionalPlotTotal = 800 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "56" && plot["size_x"] <= "80" && plot["size_y"] >= "42" && plot["laminated"] == "Yes" && plot["mounted"] == "1/2" && plot["frame"] != "None") {
      let additionalPlotTotal = 950 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "56" && plot["size_x"] <= "80" && plot["size_y"] >= "42" && plot["laminated"] == "Yes" && plot["mounted"] == "1/2" && plot["frame"] == "None") {
      let additionalPlotTotal = 750 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "56" && plot["size_x"] <= "80" && plot["size_y"] >= "42" && plot["laminated"] == "No" && plot["mounted"] == "3/16" && plot["frame"] != "None") {
      let additionalPlotTotal = 600 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "56" && plot["size_x"] <= "80" && plot["size_y"] >= "42" && plot["laminated"] == "No" && plot["mounted"] == "3/16" && plot["frame"] == "None") {
      let additionalPlotTotal = 400 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "56" && plot["size_x"] <= "80" && plot["size_y"] >= "42" && plot["laminated"] == "No" && plot["mounted"] == "1/2" && plot["frame"] != "None") {
      let additionalPlotTotal = 750 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "56" && plot["size_x"] <= "80" && plot["size_y"] >= "42" && plot["laminated"] == "No" && plot["mounted"] == "1/2" && plot["frame"] == "None") {
      let additionalPlotTotal = 550 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "56" && plot["size_x"] <= "80" && plot["size_y"] >= "42" && plot["laminated"] == "Yes" && plot["mounted"] == "None" && plot["frame"] != "None") {
      let additionalPlotTotal = 600 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "56" && plot["size_x"] <= "80" && plot["size_y"] >= "42" && plot["laminated"] == "Yes" && plot["mounted"] == "None" && plot["frame"] == "None") {
      let additionalPlotTotal = 400 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "56" && plot["size_x"] <= "80" && plot["size_y"] >= "42" && plot["laminated"] == "No" && plot["mounted"] == "None" && plot["frame"] != "None") {
      let additionalPlotTotal = 400 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "56" && plot["size_x"] <= "80" && plot["size_y"] >= "42" && plot["laminated"] == "No" && plot["mounted"] == "None" && plot["frame"] == "None") {
      let additionalPlotTotal = 200 * plot["quantity"];
      return additionalPlotTotal;
      {/* plots where longest side is 80 - 120 and shorter side is 42 - 90 */}
    } else if (plot["size_x"] >= "80" && plot["size_x"] <= "120" && plot["size_y"] >= "42" && plot["laminated"] == "Yes" && plot["mounted"] == "3/16" && plot["frame"] != "None") {
      let additionalPlotTotal = 1200 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "80" && plot["size_x"] <= "120" && plot["size_y"] >= "42" && plot["laminated"] == "Yes" && plot["mounted"] == "3/16" && plot["frame"] == "None") {
      let additionalPlotTotal = 900 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "80" && plot["size_x"] <= "120" && plot["size_y"] >= "42" && plot["laminated"] == "Yes" && plot["mounted"] == "1/2" && plot["frame"] != "None") {
      let additionalPlotTotal = 1400 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "80" && plot["size_x"] <= "120" && plot["size_y"] >= "42" && plot["laminated"] == "Yes" && plot["mounted"] == "1/2" && plot["frame"] == "None") {
      let additionalPlotTotal = 1100 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "80" && plot["size_x"] <= "120" && plot["size_y"] >= "42" && plot["laminated"] == "No" && plot["mounted"] == "3/16" && plot["frame"] != "None") {
      let additionalPlotTotal = 900 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "80" && plot["size_x"] <= "120" && plot["size_y"] >= "42" && plot["laminated"] == "No" && plot["mounted"] == "3/16" && plot["frame"] == "None") {
      let additionalPlotTotal = 600 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "80" && plot["size_x"] <= "120" && plot["size_y"] >= "42" && plot["laminated"] == "No" && plot["mounted"] == "1/2" && plot["frame"] != "None") {
      let additionalPlotTotal = 1100 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "80" && plot["size_x"] <= "120" && plot["size_y"] >= "42" && plot["laminated"] == "No" && plot["mounted"] == "1/2" && plot["frame"] == "None") {
      let additionalPlotTotal = 800 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "80" && plot["size_x"] <= "120" && plot["size_y"] >= "42" && plot["laminated"] == "Yes" && plot["mounted"] == "None" && plot["frame"] != "None") {
      let additionalPlotTotal = 900 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "80" && plot["size_x"] <= "120" && plot["size_y"] >= "42" && plot["laminated"] == "Yes" && plot["mounted"] == "None" && plot["frame"] == "None") {
      let additionalPlotTotal = 600 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "80" && plot["size_x"] <= "120" && plot["size_y"] >= "42" && plot["laminated"] == "No" && plot["mounted"] == "None" && plot["frame"] != "None") {
      let additionalPlotTotal = 600 * plot["quantity"];
      return additionalPlotTotal;
    } else if (plot["size_x"] >= "80" && plot["size_x"] <= "120" && plot["size_y"] >= "42" && plot["laminated"] == "No" && plot["mounted"] == "None" && plot["frame"] == "None") {
      let additionalPlotTotal = 300 * plot["quantity"];
      return additionalPlotTotal;
    } else {
      let additionalPlotTotal = 0;
      return additionalPlotTotal;
    }
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden
    })
  }

  render() {

    const {additionalPlots} = this.state;

    if (this.props.plotLength <= "42" && this.props.plotWidth <= "42" && this.props.laminate == "Yes" && this.props.plotMount == "3/16" && this.props.frame != "None") {
      this.state.plot_cp = "200";
    } else if (this.props.plotLength <= "42" && this.props.plotWidth <= "42" && this.props.laminate == "Yes" && this.props.plotMount == "3/16" && this.props.frame == "None") {
      this.state.plot_cp = "150";
    } else if (this.props.plotLength <= "42" && this.props.plotWidth <= "42" && this.props.laminate == "Yes" && this.props.plotMount == "1/2" && this.props.frame != "None") {
      this.state.plot_cp = "225";
    } else if (this.props.plotLength <= "42" && this.props.plotWidth <= "42" && this.props.laminate == "Yes" && this.props.plotMount == "1/2" && this.props.frame == "None") {
      this.state.plot_cp = "175";
    } else if (this.props.plotLength <= "42" && this.props.plotWidth <= "42" && this.props.laminate == "No" && this.props.plotMount == "3/16" && this.props.frame != "None") {
      this.state.plot_cp = "150";
    } else if (this.props.plotLength <= "42" && this.props.plotWidth <= "42" && this.props.laminate == "No" && this.props.plotMount == "3/16" && this.props.frame == "None") {
      this.state.plot_cp = "100";
    } else if (this.props.plotLength <= "42" && this.props.plotWidth <= "42" && this.props.laminate == "No" && this.props.plotMount == "1/2" && this.props.frame != "None") {
      this.state.plot_cp = "175";
    } else if (this.props.plotLength <= "42" && this.props.plotWidth <= "42" && this.props.laminate == "No" && this.props.plotMount == "1/2" && this.props.frame == "None") {
      this.state.plot_cp = "125";
    } else if (this.props.plotLength <= "42" && this.props.plotWidth <= "42" && this.props.laminate == "Yes" && this.props.plotMount == "None" && this.props.frame != "None") {
      this.state.plot_cp = "150";
    } else if (this.props.plotLength <= "42" && this.props.plotWidth <= "42" && this.props.laminate == "Yes" && this.props.plotMount == "None" && this.props.frame == "None") {
      this.state.plot_cp = "100";
    } else if (this.props.plotLength <= "42" && this.props.plotWidth <= "42" && this.props.laminate == "No" && this.props.plotMount == "None" && this.props.frame != "None") {
      this.state.plot_cp = "100";
    } else if (this.props.plotLength <= "42" && this.props.plotWidth <= "42" && this.props.laminate == "No" && this.props.plotMount == "None" && this.props.frame == "None") {
      this.state.plot_cp = "50";
    {/* plots where longest side is 42 - 56 and shorter side is < 42 */}
  } else if (this.props.plotLength >= "42" && this.props.plotLength <= "56" && this.props.plotWidth <= "42" || this.props.plotLength <= "42" && this.props.plotWidth >= "42" && this.props.plotWidth <= "56" && this.props.laminate == "Yes" && this.props.plotMount == "3/16" && this.props.frame != "None") {
      this.state.plot_cp = "275";
    } else if (this.props.plotLength >= "42" && this.props.plotLength <= "56" && this.props.plotWidth <= "42" || this.props.plotLength <= "42" && this.props.plotWidth >= "42" && this.props.plotWidth <= "56" && this.props.laminate == "Yes" && this.props.plotMount == "3/16" && this.props.frame == "None") {
      this.state.plot_cp = "225";
    } else if (this.props.plotLength >= "42" && this.props.plotLength <= "56" && this.props.plotWidth <= "42" || this.props.plotLength <= "42" && this.props.plotWidth >= "42" && this.props.plotWidth <= "56" && this.props.laminate == "Yes" && this.props.plotMount == "1/2" && this.props.frame != "None") {
      this.state.plot_cp = "325";
    } else if (this.props.plotLength >= "42" && this.props.plotLength <= "56" && this.props.plotWidth <= "42" || this.props.plotLength <= "42" && this.props.plotWidth >= "42" && this.props.plotWidth <= "56" && this.props.laminate == "Yes" && this.props.plotMount == "1/2" && this.props.frame == "None") {
      this.state.plot_cp = "225";
    } else if (this.props.plotLength >= "42" && this.props.plotLength <= "56" && this.props.plotWidth <= "42" || this.props.plotLength <= "42" && this.props.plotWidth >= "42" && this.props.plotWidth <= "56" && this.props.laminate == "No" && this.props.plotMount == "3/16" && this.props.frame != "None") {
      this.state.plot_cp = "200";
    } else if (this.props.plotLength >= "42" && this.props.plotLength <= "56" && this.props.plotWidth <= "42" || this.props.plotLength <= "42" && this.props.plotWidth >= "42" && this.props.plotWidth <= "56" && this.props.laminate == "No" && this.props.plotMount == "3/16" && this.props.frame == "None") {
      this.state.plot_cp = "150";
    } else if (this.props.plotLength >= "42" && this.props.plotLength <= "56" && this.props.plotWidth <= "42" || this.props.plotLength <= "42" && this.props.plotWidth >= "42" && this.props.plotWidth <= "56" && this.props.laminate == "No" && this.props.plotMount == "1/2" && this.props.frame != "None") {
      this.state.plot_cp = "250";
    } else if (this.props.plotLength >= "42" && this.props.plotLength <= "56" && this.props.plotWidth <= "42" || this.props.plotLength <= "42" && this.props.plotWidth >= "42" && this.props.plotWidth <= "56" && this.props.laminate == "No" && this.props.plotMount == "1/2" && this.props.frame == "None") {
      this.state.plot_cp = "200";
    } else if (this.props.plotLength >= "42" && this.props.plotLength <= "56" && this.props.plotWidth <= "42" || this.props.plotLength <= "42" && this.props.plotWidth >= "42" && this.props.plotWidth <= "56" && this.props.laminate == "Yes" && this.props.plotMount == "None" && this.props.frame != "None") {
      this.state.plot_cp = "200";
    } else if (this.props.plotLength >= "42" && this.props.plotLength <= "56" && this.props.plotWidth <= "42" || this.props.plotLength <= "42" && this.props.plotWidth >= "42" && this.props.plotWidth <= "56" && this.props.laminate == "Yes" && this.props.plotMount == "None" && this.props.frame == "None") {
      this.state.plot_cp = "150";
    } else if (this.props.plotLength >= "42" && this.props.plotLength <= "56" && this.props.plotWidth <= "42" || this.props.plotLength <= "42" && this.props.plotWidth >= "42" && this.props.plotWidth <= "56" && this.props.laminate == "No" && this.props.plotMount == "None" && this.props.frame != "None") {
      this.state.plot_cp = "125";
    } else if (this.props.plotLength >= "42" && this.props.plotLength <= "56" && this.props.plotWidth <= "42" || this.props.plotLength <= "42" && this.props.plotWidth >= "42" && this.props.plotWidth <= "56" && this.props.laminate == "No" && this.props.plotMount == "None" && this.props.frame == "None") {
      this.state.plot_cp = "75";
    {/* plots where longest side is 56 - 80 and shorter side is > 42 */}
  } else if (this.props.plotLength >= "56" && this.props.plotLength <= "80" && this.props.plotWidth < "42" || this.props.plotLength < "42" && this.props.plotWidth >= "56" && this.props.plotWidth <= "80" && this.props.laminate == "Yes" && this.props.plotMount == "3/16" && this.props.frame != "None") {
      this.state.plot_cp = "400";
    } else if (this.props.plotLength >= "56" && this.props.plotLength <= "80" && this.props.plotWidth < "42" || this.props.plotLength < "42" && this.props.plotWidth >= "56" && this.props.plotWidth <= "80" && this.props.laminate == "Yes" && this.props.plotMount == "3/16" && this.props.frame == "None") {
      this.state.plot_cp = "300";
    } else if (this.props.plotLength >= "56" && this.props.plotLength <= "80" && this.props.plotWidth < "42" || this.props.plotLength < "42" && this.props.plotWidth >= "56" && this.props.plotWidth <= "80" && this.props.laminate == "Yes" && this.props.plotMount == "1/2" && this.props.frame != "None") {
      this.state.plot_cp = "475";
    } else if (this.props.plotLength >= "56" && this.props.plotLength <= "80" && this.props.plotWidth < "42" || this.props.plotLength < "42" && this.props.plotWidth >= "56" && this.props.plotWidth <= "80" && this.props.laminate == "Yes" && this.props.plotMount == "1/2" && this.props.frame == "None") {
      this.state.plot_cp = "375";
    } else if (this.props.plotLength >= "56" && this.props.plotLength <= "80" && this.props.plotWidth < "42" || this.props.plotLength < "42" && this.props.plotWidth >= "56" && this.props.plotWidth <= "80" && this.props.laminate == "No" && this.props.plotMount == "3/16" && this.props.frame != "None") {
      this.state.plot_cp = "300";
    } else if (this.props.plotLength >= "56" && this.props.plotLength <= "80" && this.props.plotWidth < "42" || this.props.plotLength < "42" && this.props.plotWidth >= "56" && this.props.plotWidth <= "80" && this.props.laminate == "No" && this.props.plotMount == "3/16" && this.props.frame == "None") {
      this.state.plot_cp = "200";
    } else if (this.props.plotLength >= "56" && this.props.plotLength <= "80" && this.props.plotWidth < "42" || this.props.plotLength < "42" && this.props.plotWidth >= "56" && this.props.plotWidth <= "80" && this.props.laminate == "No" && this.props.plotMount == "1/2" && this.props.frame != "None") {
      this.state.plot_cp = "375";
    } else if (this.props.plotLength >= "56" && this.props.plotLength <= "80" && this.props.plotWidth < "42" || this.props.plotLength < "42" && this.props.plotWidth >= "56" && this.props.plotWidth <= "80" && this.props.laminate == "No" && this.props.plotMount == "1/2" && this.props.frame == "None") {
      this.state.plot_cp = "275";
    } else if (this.props.plotLength >= "56" && this.props.plotLength <= "80" && this.props.plotWidth < "42" || this.props.plotLength < "42" && this.props.plotWidth >= "56" && this.props.plotWidth <= "80" && this.props.laminate == "Yes" && this.props.plotMount == "None" && this.props.frame != "None") {
      this.state.plot_cp = "300";
    } else if (this.props.plotLength >= "56" && this.props.plotLength <= "80" && this.props.plotWidth < "42" || this.props.plotLength < "42" && this.props.plotWidth >= "56" && this.props.plotWidth <= "80" && this.props.laminate == "Yes" && this.props.plotMount == "None" && this.props.frame == "None") {
      this.state.plot_cp = "200";
    } else if (this.props.plotLength >= "56" && this.props.plotLength <= "80" && this.props.plotWidth < "42" || this.props.plotLength < "42" && this.props.plotWidth >= "56" && this.props.plotWidth <= "80" && this.props.laminate == "No" && this.props.plotMount == "None" && this.props.frame != "None") {
      this.state.plot_cp = "200";
    } else if (this.props.plotLength >= "56" && this.props.plotLength <= "80" && this.props.plotWidth < "42" || this.props.plotLength < "42" && this.props.plotWidth >= "56" && this.props.plotWidth <= "80" && this.props.laminate == "No" && this.props.plotMount == "None" && this.props.frame == "None") {
      this.state.plot_cp = "100";
    {/* plots where longest side is 56 - 80 and shorter side is 42 - 90 */}
    } else if (this.props.plotLength >= "56" && this.props.plotLength <= "80" && this.props.plotWidth >= "42" && this.props.laminate == "Yes" && this.props.plotMount == "3/16" && this.props.frame != "None") {
      this.state.plot_cp = "800";
    } else if (this.props.plotLength >= "56" && this.props.plotLength <= "80" && this.props.plotWidth >= "42" && this.props.laminate == "Yes" && this.props.plotMount == "3/16" && this.props.frame == "None") {
      this.state.plot_cp = "600";
    } else if (this.props.plotLength >= "56" && this.props.plotLength <= "80" && this.props.plotWidth >= "42" && this.props.laminate == "Yes" && this.props.plotMount == "1/2" && this.props.frame != "None") {
      this.state.plot_cp = "950";
    } else if (this.props.plotLength >= "56" && this.props.plotLength <= "80" && this.props.plotWidth >= "42" && this.props.laminate == "Yes" && this.props.plotMount == "1/2" && this.props.frame == "None") {
      this.state.plot_cp = "750";
    } else if (this.props.plotLength >= "56" && this.props.plotLength <= "80" && this.props.plotWidth >= "42" && this.props.laminate == "No" && this.props.plotMount == "3/16" && this.props.frame != "None") {
      this.state.plot_cp = "600";
    } else if (this.props.plotLength >= "56" && this.props.plotLength <= "80" && this.props.plotWidth >= "42" && this.props.laminate == "No" && this.props.plotMount == "3/16" && this.props.frame == "None") {
      this.state.plot_cp = "400";
    } else if (this.props.plotLength >= "56" && this.props.plotLength <= "80" && this.props.plotWidth >= "42" && this.props.laminate == "No" && this.props.plotMount == "1/2" && this.props.frame != "None") {
      this.state.plot_cp = "750";
    } else if (this.props.plotLength >= "56" && this.props.plotLength <= "80" && this.props.plotWidth >= "42" && this.props.laminate == "No" && this.props.plotMount == "1/2" && this.props.frame == "None") {
      this.state.plot_cp = "550";
    } else if (this.props.plotLength >= "56" && this.props.plotLength <= "80" && this.props.plotWidth >= "42" && this.props.laminate == "Yes" && this.props.plotMount == "None" && this.props.frame != "None") {
      this.state.plot_cp = "600";
    } else if (this.props.plotLength >= "56" && this.props.plotLength <= "80" && this.props.plotWidth >= "42" && this.props.laminate == "Yes" && this.props.plotMount == "None" && this.props.frame == "None") {
      this.state.plot_cp = "400";
    } else if (this.props.plotLength >= "56" && this.props.plotLength <= "80" && this.props.plotWidth >= "42" && this.props.laminate == "No" && this.props.plotMount == "None" && this.props.frame != "None") {
      this.state.plot_cp = "400";
    } else if (this.props.plotLength >= "56" && this.props.plotLength <= "80" && this.props.plotWidth >= "42" && this.props.laminate == "No" && this.props.plotMount == "None" && this.props.frame == "None") {
      this.state.plot_cp = "200";
    {/* plots where longest side is 80 - 120 and shorter side is 42 - 90 */}
    } else if (this.props.plotLength >= "80" && this.props.plotLength <= "120" && this.props.plotWidth >= "42" && this.props.laminate == "Yes" && this.props.plotMount == "3/16" && this.props.frame != "None") {
      this.state.plot_cp = "1200";
    } else if (this.props.plotLength >= "80" && this.props.plotLength <= "120" && this.props.plotWidth >= "42" && this.props.laminate == "Yes" && this.props.plotMount == "3/16" && this.props.frame == "None") {
      this.state.plot_cp = "900";
    } else if (this.props.plotLength >= "80" && this.props.plotLength <= "120" && this.props.plotWidth >= "42" && this.props.laminate == "Yes" && this.props.plotMount == "1/2" && this.props.frame != "None") {
      this.state.plot_cp = "1400";
    } else if (this.props.plotLength >= "80" && this.props.plotLength <= "120" && this.props.plotWidth >= "42" && this.props.laminate == "Yes" && this.props.plotMount == "1/2" && this.props.frame == "None") {
      this.state.plot_cp = "1100";
    } else if (this.props.plotLength >= "80" && this.props.plotLength <= "120" && this.props.plotWidth >= "42" && this.props.laminate == "No" && this.props.plotMount == "3/16" && this.props.frame != "None") {
      this.state.plot_cp = "900";
    } else if (this.props.plotLength >= "80" && this.props.plotLength <= "120" && this.props.plotWidth >= "42" && this.props.laminate == "No" && this.props.plotMount == "3/16" && this.props.frame == "None") {
      this.state.plot_cp = "600";
    } else if (this.props.plotLength >= "80" && this.props.plotLength <= "120" && this.props.plotWidth >= "42" && this.props.laminate == "No" && this.props.plotMount == "1/2" && this.props.frame != "None") {
      this.state.plot_cp = "1100";
    } else if (this.props.plotLength >= "80" && this.props.plotLength <= "120" && this.props.plotWidth >= "42" && this.props.laminate == "No" && this.props.plotMount == "1/2" && this.props.frame == "None") {
      this.state.plot_cp = "800";
    } else if (this.props.plotLength >= "80" && this.props.plotLength <= "120" && this.props.plotWidth >= "42" && this.props.laminate == "Yes" && this.props.plotMount == "None" && this.props.frame != "None") {
      this.state.plot_cp = "900";
    } else if (this.props.plotLength >= "80" && this.props.plotLength <= "120" && this.props.plotWidth >= "42" && this.props.laminate == "Yes" && this.props.plotMount == "None" && this.props.frame == "None") {
      this.state.plot_cp = "600";
    } else if (this.props.plotLength >= "80" && this.props.plotLength <= "120" && this.props.plotWidth >= "42" && this.props.laminate == "No" && this.props.plotMount == "None" && this.props.frame != "None") {
      this.state.plot_cp = "600";
    } else if (this.props.plotLength >= "80" && this.props.plotLength <= "120" && this.props.plotWidth >= "42" && this.props.laminate == "No" && this.props.plotMount == "None" && this.props.frame == "None") {
      this.state.plot_cp = "300";
    } else {
      this.state.plot_cp = "0";
    }

    {/* plots cost */}
    var plotNumber = parseInt(this.props.plotQty);
    var plotCp = parseInt(this.state.plot_cp);
    var plotShipping = parseInt(this.state.plot_shipping);
    var plotPrice = parseInt(plotNumber) * parseInt(plotCp) + parseInt(plotShipping);
    var plotTotal = plotPrice;

    {/* delivery cost */}
    var deliveryQty = parseInt(this.state.delivery);
    var deliveryCp = parseInt(this.state.delivery_cp);
    var deliveryPrice = parseInt(deliveryQty) * parseInt(deliveryCp);
    var deliveryTotal = deliveryPrice;

    {/* imagery cost */}
    var imageSubtotal = parseInt(plotTotal) + parseInt(deliveryTotal)
    {/* image discount */}
    var imgDiscount = parseInt(imageSubtotal) * parseFloat(this.state.img_discount);
    var imgPrice = parseInt(imageSubtotal) - parseInt(imgDiscount);
    {/* end of image discount */}
    this.state.img_cost = imgPrice;

    {/* quote cost */ }

    var markup = parseInt(this.state.flight_quote) * parseFloat(this.state.flight_markup);
    var flightCost = markup / 100 + parseInt(this.state.flight_quote);
    this.state.flight_cost = flightCost;

    const arrayOfPlotCosts = additionalPlots.map(plot => this.calculateCost(plot));
    const totalPlotCost = arrayOfPlotCosts.reduce((sum, total) => sum + total, 0);
    var quoteCost = parseInt(imgPrice) + totalPlotCost + flightCost;
    this.state.quote_cost = quoteCost;

    return (
      <div className="card">
        <div className="card-body">
          <h4 className="mb-4 text-4xl">
            {this.state.editing ?
              'Update Plot Quote' :
              'Create Plot Quote'}
          </h4>
          <small>
            (* represents a <strong>required field</strong>)
          </small>
          <form onSubmit={this.handleFormSubmit} >
            <div className="row">
              <div className="col-md-3">
                <label>Quote Type *</label>
                <Select
                name="quote_type"
                disabled={true}
                value={this.props.quoteType}
                options={[
                  { value: 'Film', label: 'Film' },
                  { value: 'Digital', label: 'Digital' },
                  { value: 'LiDAR', label: 'LiDAR' },
                  { value: 'Scan', label: 'Scan' },
                  { value: 'Plot', label: 'Plot' },
                  { value: 'Survey', label: 'Survey' },
                  { value: 'Other', label: 'Other' },
                ]}
              />
            </div>
            </div>
            <p />
            <h5>Flight</h5>
            <hr />
            <button type="button" onClick={this.toggleHidden.bind(this)} title="Click to expand/hide aerial targets form" className="btn btn-secondary btn-sm" >
              {this.state.isHidden ?
                <span>Show Flight Form <i className="fa fa-plus-circle" aria-hidden="true"></i></span> :
                <span>Hide Flight Form <i className="fa fa-minus-circle" aria-hidden="true"></i></span>}
            </button>
            <p />
            {!this.state.isHidden &&
              <div>
                <div className="row">
                  <div className="col-md-3">
                    <label>Flight Sub *</label>
                    <Select.Creatable
                      required
                      allowCreate={true}
                      name="flight_sub"
                      value={this.state.flight_sub}
                      onChange={this.handleSelectChange("flight_sub")}
                      options={this.state.flight_sub_options}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Flight Quote *</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input type="text" name="flight_quote"
                        required
                        value={this.state.flight_quote}
                        onChange={this.handleChange}
                        className="form-control" />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label>Flight Markup *</label>
                    <div className="input-group mb-3">
                      <input type="text" name="flight_markup"
                        required
                        value={this.state.flight_markup}
                        onChange={this.handleChange}
                        className="form-control" />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label>Flight Cost *</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input type="text" name="flight_cost"
                        required
                        value={this.state.flight_cost}
                        onChange={this.handleChange}
                        className="form-control" />
                    </div>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label>Flight Notes</label>
                    <textarea name="flight_notes"
                      value={this.state.flight_notes}
                      onChange={this.handleChange}
                      className="form-control" />
                  </div>
                </div>
              </div>}
            <p />
            <h5>Plotting</h5>
            <hr />
            <div className="row">
              <div className="col">
                <label>Plot Description</label>
                <textarea type="text" name="description"
                  value={this.state.description}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Quantity *</label>
                <input type="text" name="plots"
                  readOnly
                  value={this.props.plotQty}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col">
                <label>Length</label>
                <input type="text" name="plot_x"
                  readOnly
                  value={this.props.plotLength}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col">
                <label>Width</label>
                <input type="text" name="plot_y"
                  value={this.props.plotWidth}
                  readOnly
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col">
                <label>Mounted</label>
                <input type="text" name="plot_mount"
                  value={this.props.plotMount}
                  readOnly
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col">
                <label>Frame</label>
                <input type="text" name="frame"
                  value={this.props.frame}
                  readOnly
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col">
                <label>Type</label>
                <input type="text" name="plot_mount"
                  value={this.props.laminateType}
                  readOnly
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col">
                <label>Cost Per</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input type="text" name="plot_cp"
                    value={this.state.plot_cp}
                    onChange={this.handleChange}
                    className="form-control" />
                </div>
              </div>
              <div className="col">
                <label>Discount *</label>
                <div className="input-group mb-3">
                  <input type="text" name="img_discount"
                    value={this.state.img_discount}
                    onChange={this.handleChange}
                    className="form-control" />
                  <span className="input-group-text">%</span>
                </div>
              </div>
            </div>
            <p />
            {
              additionalPlots.map((plot, idx) => (
                <div key={`plot-${idx}`}>
                {this.state.additionalPlots[idx]["quantity"] >= 1 &&
                <div>
                  <h5>Additional Plots</h5>
                  <hr />
                  <div className="row">
                    <div className="col">
                      <label>Quantity</label>
                      <input
                        type="text"
                        readOnly
                        name={`plot-${idx}-quantity`}
                        value={this.state.additionalPlots[idx]["quantity"]}
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <label>Length *</label>
                      <input
                        type="text"
                        readOnly
                        name={`plot-${idx}-size_x`}
                        placeholder="Size X"
                        value={this.state.additionalPlots[idx]["size_x"]}
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <label>Width *</label>
                      <input
                        type="text"
                        readOnly
                        name={`plot-${idx}-size_y`}
                        placeholder="Size Y"
                        value={this.state.additionalPlots[idx]["size_y"]}
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <label>Mounted *</label>
                      <input
                        type="text"
                        readOnly
                        name={`plot-${idx}-mounted`}
                        value={this.state.additionalPlots[idx]["mounted"]}
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <label>Frame *</label>
                      <input
                        type="text"
                        readOnly
                        name={`plot-${idx}-frame`}
                        value={this.state.additionalPlots[idx]["frame"]}
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <label>Laminated</label>
                      <input
                        type="text"
                        readOnly
                        name={`plot-${idx}-laminated`}
                        value={this.state.additionalPlots[idx]["laminated"]}
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <label>Type *</label>
                      <input
                        type="text"
                        readOnly
                        name={`plot-${idx}-plot_options`}
                        value={this.state.additionalPlots[idx]["plot_options"]}
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <label>Total *</label>
                      <div className="input-group mb-3">
                        <span className="input-group-text">$</span>
                        <input type="text"
                          defaultValue={this.calculateCost(this.state.additionalPlots[idx])}
                          onChange={this.handleChange}
                          className="form-control" />
                      </div>
                    </div>
                  </div>
                  <hr />
                  </div>}
                </div>
              ))
            }
            <p />
            <h5>Delivery Options</h5>
            <hr />
            <div className="row">
              <div className="col">
                <label>Delivery (#)</label>
                <input type="number" name="delivery"
                  value={this.state.delivery}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col">
                <label>Delivery Type</label>
                <Select.Creatable
                  allowCreate={true}
                  name="delivery_type"
                  value={this.state.delivery_type}
                  onChange={this.handleSelectChange("delivery_type")}
                  options={this.state.delivery_type_options}
                />
              </div>
              <div className="col">
                <label>Cost</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input type="text" name="delivery_cp"
                    value={this.state.delivery_cp}
                    onChange={this.handleChange}
                    className="form-control" />
                </div>
              </div>
              <div className="col">
                <label>Total *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input type="text"
                    readOnly
                    value={deliveryTotal}
                    className="form-control" />
                </div>
              </div>
            </div>
            <p />
            <h5>Total Quote Cost</h5>
            <hr />
            <div className="row">
              <div className="col">
                <label>Quote Cost</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input type="text" name="quote_cost"
                    value={this.state.quote_cost}
                    onChange={this.handleChange}
                    className="form-control" />
                </div>
              </div>
            </div>
            <p />
            <input type="submit"
              value={this.state.editing ? 'Update' : 'Create'} className="btn btn-secondary btn-sm"
            />
          </form>
          <br />
        </div>
      </div>
    )
  }

}
