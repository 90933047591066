import React from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";

export default class Leads extends React.Component {
  static propTypes = {
    leads: PropTypes.array,
  };

  static defaultProps = {
    leads: [],
  };

  constructor(props, railsContext) {
    super(props);
    this.state = {
      leads: this.props.leads,
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.match) {
      this.setState({ isLoading: true });
      $.ajax({
        type: "GET",
        url: "/crm/leads",
        dataType: "JSON",
      }).done((data) => {
        this.setState({ leads: data, isLoading: false });
      });
    }
  }

  render() {
    const { leads, isLoading } = this.state;
    if (isLoading) {
      return <div className="loader"></div>;
    }

    return (
      <div>
        <p />
        <ReactTable
          key={leads.id}
          data={leads}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          defaultPageSize={100}
          style={{
            height: "800px",
            textAlign: "center",
          }}
          columns={[
            {
              columns: [
                {
                  Header: "Client Rep",
                  accessor: "lead_owner",
                  filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                      return true;
                    }
                    if (filter.value === "pete") {
                      return row[filter.id] == "Pete Priestner";
                    }
                    if (filter.value === "tom") {
                      return row[filter.id] == "Tom Warren";
                    }
                    if (filter.value === "zach") {
                      return row[filter.id] == "Zachary Radel";
                    }
                    if (filter.value === "phil") {
                      return row[filter.id] == "Phil Gershkovich";
                    }
                  },
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={(event) => onChange(event.target.value)}
                      style={{ width: "100%" }}
                      value={filter ? filter.value : "all"}
                    >
                      <option value="all">All</option>
                      <option value="pete">Pete</option>
                      <option value="tom">Tom</option>
                      <option value="zach">Zach R.</option>
                      <option value="phil">Phil</option>
                    </select>
                  ),
                },
                {
                  Header: "Status",
                  accessor: "status",
                  filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                      return true;
                    }
                    if (filter.value === "target") {
                      return row[filter.id] == "Target";
                    }
                    if (filter.value === "suspect") {
                      return row[filter.id] == "Suspect";
                    }
                    if (filter.value === "prospect") {
                      return row[filter.id] == "Prospect";
                    }
                    if (filter.value === "accepted") {
                      return row[filter.id] == "Accepted";
                    }
                    if (filter.value === "client") {
                      return row[filter.id] == "Client";
                    }
                    if (filter.value === "existing") {
                      return row[filter.id] == "Existing";
                    }
                    if (filter.value === "rejected") {
                      return row[filter.id] == "Rejected";
                    }
                    if (filter.value === "network") {
                      return row[filter.id] == "Network";
                    }
                  },
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={(event) => onChange(event.target.value)}
                      style={{ width: "100%" }}
                      value={filter ? filter.value : "all"}
                    >
                      <option value="all">All Status</option>
                      <option value="target">Target</option>
                      <option value="suspect">Suspect</option>
                      <option value="prospect">Prospect</option>
                      <option value="accepted">Accepted</option>
                      <option value="client">Client</option>
                      <option value="existing">Existing</option>
                      <option value="rejected">Rejected</option>
                      <option value="network">Network</option>
                    </select>
                  ),
                },
                {
                  Header: "First Name",
                  accessor: "f_name",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={(event) => onChange(event.target.value)}
                      value={filter ? filter.value : ""}
                      style={{
                        width: "100%",
                        color: "black",
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id]
                      .toLowerCase()
                      .startsWith(filter.value.toLowerCase()),
                  Cell: (props) => (
                    <a
                      href={`/crm/leads/${props.original.id}`}
                      className="view-record"
                    >
                      {props.value}
                    </a>
                  ),
                },
                {
                  Header: "Last Name",
                  accessor: "l_name",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={(event) => onChange(event.target.value)}
                      value={filter ? filter.value : ""}
                      style={{
                        width: "100%",
                        color: "black",
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id]
                      .toLowerCase()
                      .startsWith(filter.value.toLowerCase()),
                },
                {
                  Header: "Email",
                  accessor: "email",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={(event) => onChange(event.target.value)}
                      value={filter ? filter.value : ""}
                      style={{
                        width: "100%",
                        color: "black",
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id]
                      .toLowerCase()
                      .startsWith(filter.value.toLowerCase()),
                },
                {
                  Header: "City",
                  accessor: "city",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={(event) => onChange(event.target.value)}
                      value={filter ? filter.value : ""}
                      style={{
                        width: "100%",
                        color: "black",
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id]
                      .toLowerCase()
                      .startsWith(filter.value.toLowerCase()),
                },
                {
                  Header: "State",
                  accessor: "state",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={(event) => onChange(event.target.value)}
                      value={filter ? filter.value : ""}
                      style={{
                        width: "100%",
                        color: "black",
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id]
                      .toLowerCase()
                      .startsWith(filter.value.toLowerCase()),
                },
              ],
            },
          ]}
          className="-striped -highlight"
        />
      </div>
    );
  }
}
