import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Compilations from './Compilations';
import CompilationForm from './CompilationForm';
import CompView from './CompView';

export default (props) => (
  <Router>
    <div>
      <Route exact path="/cascopt/compilations" render={routeProps => <Compilations {...props} {...routeProps}/>} />
      <Route exact path="/cascopt/compilations/:id" render={routeProps => <CompView {...props} {...routeProps}/>} />
      <Route path="/cascopt/compilations/:id/edit" render={routeProps => <CompilationForm {...props} {...routeProps}/>} />
    </div>
  </Router>
)
