import React from 'react';
import PropTypes from 'prop-types';
import BimForm from './BimForm';
import update from 'immutability-helper';
import Legend from '../../Shared/components/Legend';
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import moment from 'moment';

export default class Bims extends React.Component {
  static propTypes = {
    bims: PropTypes.array
  }

  static defaultProps = {
    bims: []
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      bims: this.props.bims,
      isHidden: true,
      isHiddenNotComplete: false,
      isHiddenComplete: true,
      isLoading: false
    }
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden
    })
  }

  toggleHiddenNotComplete() {
    this.setState({
      isHiddenNotComplete: !this.state.isHiddenNotComplete
    })
  }

  toggleHiddenComplete() {
    this.setState({
      isHiddenComplete: !this.state.isHiddenComplete
    })
  }

  componentDidMount() {
    if (this.props.match) {
      this.setState({ isLoading: true });
      $.ajax({
        type: "GET",
        url: "/cascopt/bims",
        dataType: "JSON"
      }).done((data) => {
        this.setState({ bims: data, isLoading: false });
      })
    }
  }

  addNewBim(bim) {
    const bims = update(this.state.bims, { $push: [bim] });
    this.setState({
      bims: bims.sort(function (a, b) {
        return new Date(a.created_at) - new Date(b.created_at);
      })
    });
  }

  render() {
    const pickDateColor = (date) => {
      const dueDate = new moment(date);
      const yellowDate = new moment(date).subtract(5, 'days');
      const now = moment();

      if (now < yellowDate) { return 'green'; };
      if (now < dueDate) { return 'blue'; };
      return 'red';
    }

    const { bims, isLoading } = this.state;
    if (isLoading) {
      return <div className="loader"></div>;
    }

    return (
      <div>
        <div className="row">
          <div className="col-md-8">
            <h3 id="header">All BIM Dashboard</h3>
            <a href="/cascopt/bims" className="view-record">
              Back to BIM dashboard
            </a>
          </div>
        </div>
        <hr />
        <p />
        {!this.state.isHiddenNotComplete &&
          <ReactTable key={bims.id}
            data={bims}
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id]) === filter.value}
            defaultPageSize={100}
            style={{
              height: "800px",
              textAlign: "center"
            }}
            columns={[
              {
                Header: "BIM Info",
                columns: [
                  {
                    Header: "Job Number",
                    accessor: "project.job_number",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={event => onChange(event.target.value)}
                        value={filter ? filter.value : ''}
                        style={{
                          width: '100%',
                          color: 'black',
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id].startsWith(filter.value),
                    Cell: props => <a href={`/cascopt/bims/${props.original.id}`} className="view-record" >{props.original.project.job_number}</a>
                  },
                  {
                    Header: "Job Name",
                    accessor: "project.job_name",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={event => onChange(event.target.value)}
                        value={filter ? filter.value : ''}
                        style={{
                          width: '100%',
                          color: 'black',
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase()),
                    Cell: props => <a href={`/cascopt/bims/${props.original.id}`} className="view-record" >{props.original.project.job_name}</a>
                  },
                  {
                    Header: "Client",
                    accessor: "project.client_name",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={event => onChange(event.target.value)}
                        value={filter ? filter.value : ''}
                        style={{
                          width: '100%',
                          color: 'black',
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase()),
                  },
                  {
                    Header: "To Sub",
                    filterable: false,
                    accessor: "bim_to_sub"
                  },
                  {
                    Header: "Hours",
                    filterable: false,
                    accessor: "hours"
                  },
                  {
                    Header: "Stage",
                    filterable: false,
                    accessor: "project.stage"
                  },
                  {
                    Header: "Modify",
                    filterable: false,
                    Cell: props => <a href={`/cascopt/bims/${props.original.id}/edit`} className="view-record" >Edit</a>
                  }
                ]
              }
            ]}
            defaultSorted={[
              {
                id: "project.due_date",
                asc: true
              }
            ]}
          className="-striped -highlight"
          />}
      </div>
    )
  }
}
