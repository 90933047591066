import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Flights from './Flights';
import FlightForm from './FlightForm';
import FlightView from './FlightView';

export default (props) => {
  return (
    <Router>
      <div>
        <Route exact path="/cascopt/flights" render={routeProps => <Flights {...props} {...routeProps}/>} />
        <Route exact path="/cascopt/flights/:id" render={routeProps => <FlightView {...props} {...routeProps}/>} />
        <Route path="/cascopt/flights/:id/edit" component={FlightForm} />
      </div>
    </Router>
  )
}
