import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import update from 'immutability-helper';

export default class Offices extends React.Component {
  static propTypes = {
    offices: PropTypes.array
  }

  static defaultProps = {
    offices: []
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      offices: this.props.offices,
      isHidden: true,
      isHiddenNotComplete: false,
      isLoading: false
    }
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden
    })
  }

  toggleHiddenNotComplete() {
    this.setState({
      isHiddenNotComplete: !this.state.isHiddenNotComplete
    })
  }

  componentDidMount() {
    if (this.props.match) {
      this.setState({ isLoading: true });
      $.ajax({
        type: "GET",
        url: "/offices",
        dataType: "JSON"
      }).done((data) => {
        this.setState({ offices: data, isLoading: false });
      })
    }
  }

  addNewOffice = (office) => {
    const offices = update(this.state.offices, { $push: [office] });
    this.setState({
      offices: offices.sort(function (a, b) {
        return new Date(a.created_at) - new Date(b.created_at);
      })
    });
  }

  render() {
    const { offices, isLoading } = this.state;
    if (isLoading) {
      return <div className="loader"></div>;
    }

    return (
      <div>
        <Header />
        <p />
        <ReactTable key={offices.id}
          data={offices}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value}
          defaultPageSize={100}
          style={{
            height: "800px",
            textAlign: "center"
          }}
          columns={[
            {
              Header: "Office Info",
              columns: [
                {
                  Header: "Client Number",
                  accessor: "client_number",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={event => onChange(event.target.value)}
                      value={filter ? filter.value : ''}
                      style={{
                        width: '100%',
                        color: 'black',
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id].startsWith(filter.value),
                  Cell: props => <a href={`/offices/${props.original.id}`} className="view-record">{props.value}</a>
                },
                {
                  Header: "Company",
                  accessor: "company.name",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={event => onChange(event.target.value)}
                      value={filter ? filter.value : ''}
                      style={{
                        width: '100%',
                        color: 'black',
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
                  Cell: props => <a href={`/offices/${props.original.id}`} className="view-record">{props.value}</a>
                },
                {
                  Header: "Office City Location",
                  accessor: "city",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={event => onChange(event.target.value)}
                      value={filter ? filter.value : ''}
                      style={{
                        width: '100%',
                        color: 'black',
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
                },
                {
                  Header: "Office State Location",
                  accessor: "state",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={event => onChange(event.target.value)}
                      value={filter ? filter.value : ''}
                      style={{
                        width: '100%',
                        color: 'black',
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
                },
                {
                  Header: "Office Zip",
                  accessor: "zip",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={event => onChange(event.target.value)}
                      value={filter ? filter.value : ''}
                      style={{
                        width: '100%',
                        color: 'black',
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase()),
                },
                {
                  Header: "Phone",
                  filterable: false,
                  accessor: "phone"
                },
                {
                  Header: "Modify",
                  filterable: false,
                  Cell: props => <a href={`/offices/${props.original.id}/edit`} className="view-record">Edit</a>
                }
              ]
            }
          ]}
          className="-striped -highlight"
        />
      </div>
    )
  }

}
