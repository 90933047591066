/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prefer-stateless-function */
/* eslint - disable - next - line react/jsx-one-expression-per-line */
import React from 'react';

export default class Proposal extends React.Component {
  render() {
    return (
      <div>
        <h3 className="mb-4 text-4xl"><i className="fa fa-usd"></i> Listing Proposals</h3>
        <hr />
      </div>
    );
  }
}
