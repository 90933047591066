import React from "react";
import PropTypes from "prop-types";
import update from "immutability-helper";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import "react-select/dist/react-select.css";

export default class QuoteForm extends React.Component {
  static propTypes = {
    handleNewQuote: PropTypes.func,
  };

  constructor(props, railsContext) {
    super(props);
    this.state = {
      bonus_perc: 0,
      total_adjustment: 0,
      adjustment_reason: "",
      flight_sub: "",
      flight_sub_options: [{ value: "NAAS", label: "NAAS" }],
      flight_quote: "",
      flight_markup: "",
      flight_cost: "",
      flight_notes: "",
      panels: "",
      abgps: "",
      survey_sub: "",
      survey_cost: "",
      survey_quote: "",
      survey_markup: "",
      survey_notes: "",
      contact_prints: "",
      contact_prints_cp: "",
      diapositives: "",
      diapositives_cp: "",
      scan_cp: "",
      lab_discount: "",
      lab_cost: "",
      lab_notes: "",
      analytics_hp: "",
      analytics_cp: "",
      photogrammetry_cp: "",
      photogrammetry_hp: "",
      xsec: "",
      xsec_cp: "",
      xsec_hp: "",
      cartography_cp: "",
      cartography_hp: "",
      translation: "",
      translation_cp: "",
      translation_hp: "",
      map_discount: "",
      map_cost: "",
      img_hp: "",
      img_cp: "",
      plots: "",
      plot_x: "",
      plot_y: "",
      plot_cp: "",
      delivery: "",
      delivery_type: "",
      delivery_type_options: [
        { value: "FTP", label: "FTP" },
        { value: "USB", label: "USB" },
        { value: "H/D", label: "H/D" },
        { value: "DVD", label: "DVD" },
      ],
      delivery_cp: "",
      img_discount: "",
      img_cost: "",
      quote_cost: "",
      map_notes: "",
      img_notes: "",
      plot_mount: "",
      camera: "",
      supplemental_survey: "",
      plain_gc: "",
      description: "",
      sales_tax: "",
      unit_price: "",
      shipping: "",
      camera_option: "",
      camera_option_options: [
        { value: "None", label: "None" },
        { value: "Phase One 150", label: "Phase One 150" },
        { value: "Phase One 280", label: "Phase One 280" },
      ],
      lidar_option: "",
      lidar_option_options: [
        { value: "None", label: "None" },
        { value: "Galaxy", label: "Galaxy" },
        { value: "Ultracam", label: "Ultracam" },
      ],
      flight_distance: 0,
      travel: "",
      fuel_charge: 0,
      raw_data_charge: 0,
      permit_charge: 0,
      airspace_charge: 0,
      flight_time: 0,
      flight_lines_price: 0,
      flight_distance_price: 0,
      travel_price: 0,
      fuel_charge_price: 0,
      raw_data_charge_price: 0,
      permit_charge_price: 0,
      airspace_charge_price: 0,
      flight_time_price: 0,
      night_weekends_price: 0,
      o2_use_price: 0,
      overnight_price: 0,
      nights_weekends: 0,
      o2_use: 0,
      overnight: 0,
      discount: "",
      editing: false,
      quote_type: this.props.quoteType || "",
      user_id: this.props.userId || "",
      ...props.quote,
    };
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/quotes/${this.props.match.params.id}`,
        dataType: "JSON",
      }).done((data) => {
        this.setState({
          bonus_perc: data.quote.bonus_perc,
          total_adjustment: data.quote.total_adjustment,
          adjustment_reason: data.quote.adjustment_reason,
          user_id: data.quote.user_id,
          opportunity_id: data.quote.opportunity_id,
          specification_id: data.quote.specification_id,
          flight_sub: data.quote.flight_sub,
          flight_quote: data.quote.flight_quote,
          flight_markup: data.quote.flight_markup,
          flight_cost: data.quote.flight_cost,
          flight_notes: data.quote.flight_notes,
          panels: data.quote.panels,
          abgps: data.quote.abgps,
          survey_sub: data.quote.survey_sub,
          survey_cost: data.quote.survey_cost,
          survey_quote: data.quote.survey_quote,
          survey_markup: data.quote.survey_markup,
          survey_notes: data.quote.survey_notes,
          contact_prints: data.quote.contact_prints,
          contact_prints_cp: data.quote.contact_prints_cp,
          diapositives: data.quote.diapositives,
          diapositives_cp: data.quote.diapositives_cp,
          scan_cp: data.quote.scan_cp,
          lab_discount: data.quote.lab_discount,
          lab_cost: data.quote.lab_cost,
          lab_notes: data.quote.lab_notes,
          analytics_hp: data.quote.analytics_hp,
          analytics_cp: data.quote.analytics_cp,
          photogrammetry_cp: data.quote.photogrammetry_cp,
          photogrammetry_hp: data.quote.photogrammetry_hp,
          xsec: data.quote.xsec,
          xsec_cp: data.quote.xsec_cp,
          xsec_hp: data.quote.xsec_hp,
          cartography_cp: data.quote.cartography_cp,
          cartography_hp: data.quote.cartography_hp,
          translation: data.quote.translation,
          translation_cp: data.quote.translation_cp,
          translation_hp: data.quote.translation_hp,
          map_discount: data.quote.map_discount,
          map_cost: data.quote.map_cost,
          img_hp: data.quote.img_hp,
          img_cp: data.quote.img_cp,
          plots: data.quote.plots,
          plot_x: data.quote.plot_x,
          plot_y: data.quote.plot_y,
          plot_cp: data.quote.plot_cp,
          delivery: data.quote.delivery,
          delivery_type: data.quote.delivery_type,
          delivery_cp: data.quote.delivery_cp,
          img_discount: data.quote.img_discount,
          img_cost: data.quote.img_cost,
          quote_cost: data.quote.quote_cost,
          map_notes: data.quote.map_notes,
          img_notes: data.quote.img_notes,
          plot_mount: data.quote.plot_mount,
          camera: data.quote.camera,
          supplemental_survey: data.quote.supplemental_survey,
          plain_gc: data.quote.plain_gc,
          description: data.quote.description,
          sales_tax: data.quote.sales_tax,
          unit_price: data.quote.unit_price,
          shipping: data.quote.shipping,
          camera_option: data.quote.camera_option,
          lidar_option: data.quote.lidar_option,
          flight_distance: data.quote.flight_distance,
          travel: data.quote.travel,
          fuel_charge: data.quote.fuel_charge,
          raw_data_charge: data.quote.raw_data_charge,
          permit_charge: data.quote.permit_charge,
          airspace_charge: data.quote.airspace_charge,
          flight_time: data.quote.flight_time,
          flight_lines: data.quote.flight_lines,
          flight_lines_price: data.quote.flight_lines_price,
          flight_distance_price: data.quote.flight_distance_price,
          travel_price: data.quote.travel_price,
          fuel_charge_price: data.quote.fuel_charge_price,
          raw_data_charge_price: data.quote.raw_data_charge_price,
          permit_charge_price: data.quote.permit_charge_price,
          airspace_charge_price: data.quote.airspace_charge_price,
          flight_time_price: data.quote.flight_time_price,
          night_weekends_price: data.quote.night_weekends_price,
          o2_use_price: data.quote.o2_use_price,
          overnight_price: data.quote.overnight_price,
          nights_weekends: data.quote.nights_weekends,
          o2_use: data.quote.o2_use,
          overnight: data.quote.overnight,
          discount: data.quote.discount,
          quote_type: data.quote.quote_type,
          editing: this.props.match.path === "/quotes/:id/edit",
        });
      });
    }
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.state.editing ? this.updateQuote() : this.addQuote();
  };

  updateQuote() {
    const quote = {
      bonus_perc: this.state.bonus_perc,
      total_adjustment: this.state.total_adjustment,
      adjustment_reason: this.state.adjustment_reason,
      user_id: this.props.userId,
      opportunity_id: this.props.oppId,
      specification_id: this.props.specId,
      quote_type: this.props.quoteType,
      flight_sub: this.state.flight_sub,
      flight_quote: this.state.flight_quote,
      flight_markup: this.state.flight_markup,
      flight_cost: this.state.flight_cost,
      flight_notes: this.state.flight_notes,
      panels: this.state.panels,
      abgps: this.state.abgps,
      survey_sub: this.state.survey_sub,
      survey_cost: this.state.survey_cost,
      survey_quote: this.state.survey_quote,
      survey_markup: this.state.survey_markup,
      survey_notes: this.state.survey_notes,
      contact_prints: this.state.contact_prints,
      contact_prints_cp: this.state.contact_prints_cp,
      diapositives: this.state.diapositives,
      diapositives_cp: this.state.diapositives_cp,
      scan_cp: this.state.scan_cp,
      lab_discount: this.state.lab_discount,
      lab_cost: this.state.lab_cost,
      lab_notes: this.state.lab_notes,
      analytics_hp: this.state.analytics_hp,
      analytics_cp: this.state.analytics_cp,
      photogrammetry_cp: this.state.photogrammetry_cp,
      photogrammetry_hp: this.state.photogrammetry_hp,
      xsec: this.state.xsec,
      xsec_cp: this.state.xsec_cp,
      xsec_hp: this.state.xsec_hp,
      cartography_cp: this.state.cartography_cp,
      cartography_hp: this.state.cartography_hp,
      translation: this.state.translation,
      translation_cp: this.state.translation_cp,
      translation_hp: this.state.translation_hp,
      map_discount: this.state.map_discount,
      map_cost: this.state.map_cost,
      img_hp: this.state.img_hp,
      img_cp: this.state.img_cp,
      plots: this.state.plots,
      plot_x: this.state.plot_x,
      plot_y: this.state.plot_y,
      plot_cp: this.state.plot_cp,
      delivery: this.state.delivery,
      delivery_type: this.state.delivery_type,
      delivery_cp: this.state.delivery_cp,
      img_discount: this.state.img_discount,
      img_cost: this.state.img_cost,
      quote_cost: this.state.quote_cost,
      map_notes: this.state.map_notes,
      img_notes: this.state.img_notes,
      plot_mount: this.state.plot_mount,
      camera: this.state.camera,
      supplemental_survey: this.state.supplemental_survey,
      plain_gc: this.state.plain_gc,
      description: this.state.description,
      sales_tax: this.state.sales_tax,
      unit_price: this.state.unit_price,
      shipping: this.state.shipping,
      camera_option: this.state.camera_option,
      lidar_option: this.state.lidar_option,
      flight_distance: this.state.flight_distance,
      travel: this.state.travel,
      fuel_charge: this.state.fuel_charge,
      raw_data_charge: this.state.raw_data_charge,
      permit_charge: this.state.permit_charge,
      airspace_charge: this.state.airspace_charge,
      flight_time: this.state.flight_time,
      flight_lines: this.state.flight_lines,
      flight_lines_price: this.state.flight_lines_price,
      flight_distance_price: this.state.flight_distance_price,
      travel_price: this.state.travel_price,
      fuel_charge_price: this.state.fuel_charge_price,
      raw_data_charge_price: this.state.raw_data_charge_price,
      permit_charge_price: this.state.permit_charge_price,
      airspace_charge_price: this.state.airspace_charge_price,
      flight_time_price: this.state.flight_time_price,
      night_weekends_price: this.state.night_weekends_price,
      o2_use_price: this.state.o2_use_price,
      overnight_price: this.state.overnight_price,
      nights_weekends: this.state.nights_weekends,
      o2_use: this.state.o2_use,
      overnight: this.state.overnight,
      discount: this.state.discount,
    };
    $.ajax({
      type: "PATCH",
      url: `/quotes/${this.props.match.params.id}`,
      data: { quote: quote },
    })
      .done((data) => {
        console.log("Quote updated!");
      })
      .fail((response) => {
        console.log("did not work");
        alert("Please review the form for errors");
      });
  }

  addQuote() {
    const quote = {
      bonus_perc: this.state.bonus_perc,
      total_adjustment: this.state.total_adjustment,
      adjustment_reason: this.state.adjustment_reason,
      user_id: this.props.userId,
      opportunity_id: this.props.oppId,
      specification_id: this.props.specId,
      quote_type: this.props.quoteType,
      flight_sub: this.state.flight_sub,
      flight_quote: this.state.flight_quote,
      flight_markup: this.state.flight_markup,
      flight_cost: this.state.flight_cost,
      flight_notes: this.state.flight_notes,
      panels: this.state.panels,
      abgps: this.state.abgps,
      survey_sub: this.state.survey_sub,
      survey_cost: this.state.survey_cost,
      survey_quote: this.state.survey_quote,
      survey_markup: this.state.survey_markup,
      survey_notes: this.state.survey_notes,
      contact_prints: this.state.contact_prints,
      contact_prints_cp: this.state.contact_prints_cp,
      diapositives: this.state.diapositives,
      diapositives_cp: this.state.diapositives_cp,
      scan_cp: this.state.scan_cp,
      lab_discount: this.state.lab_discount,
      lab_cost: this.state.lab_cost,
      lab_notes: this.state.lab_notes,
      analytics_hp: this.state.analytics_hp,
      analytics_cp: this.state.analytics_cp,
      photogrammetry_cp: this.state.photogrammetry_cp,
      photogrammetry_hp: this.state.photogrammetry_hp,
      xsec: this.state.xsec,
      xsec_cp: this.state.xsec_cp,
      xsec_hp: this.state.xsec_hp,
      cartography_cp: this.state.cartography_cp,
      cartography_hp: this.state.cartography_hp,
      translation: this.state.translation,
      translation_cp: this.state.translation_cp,
      translation_hp: this.state.translation_hp,
      map_discount: this.state.map_discount,
      map_cost: this.state.map_cost,
      img_hp: this.state.img_hp,
      img_cp: this.state.img_cp,
      plots: this.state.plots,
      plot_x: this.state.plot_x,
      plot_y: this.state.plot_y,
      plot_cp: this.state.plot_cp,
      delivery: this.state.delivery,
      delivery_type: this.state.delivery_type,
      delivery_cp: this.state.delivery_cp,
      img_discount: this.state.img_discount,
      img_cost: this.state.img_cost,
      quote_cost: this.state.quote_cost,
      map_notes: this.state.map_notes,
      img_notes: this.state.img_notes,
      plot_mount: this.state.plot_mount,
      camera: this.state.camera,
      supplemental_survey: this.state.supplemental_survey,
      plain_gc: this.state.plain_gc,
      description: this.state.description,
      sales_tax: this.state.sales_tax,
      unit_price: this.state.unit_price,
      shipping: this.state.shipping,
      camera_option: this.state.camera_option,
      lidar_option: this.state.lidar_option,
      flight_distance: this.state.flight_distance,
      travel: this.state.travel,
      fuel_charge: this.state.fuel_charge,
      raw_data_charge: this.state.raw_data_charge,
      permit_charge: this.state.permit_charge,
      airspace_charge: this.state.airspace_charge,
      flight_time: this.state.flight_time,
      flight_lines: this.state.flight_lines,
      flight_lines_price: this.state.flight_lines_price,
      flight_distance_price: this.state.flight_distance_price,
      travel_price: this.state.travel_price,
      fuel_charge_price: this.state.fuel_charge_price,
      raw_data_charge_price: this.state.raw_data_charge_price,
      permit_charge_price: this.state.permit_charge_price,
      airspace_charge_price: this.state.airspace_charge_price,
      flight_time_price: this.state.flight_time_price,
      night_weekends_price: this.state.night_weekends_price,
      o2_use_price: this.state.o2_use_price,
      overnight_price: this.state.overnight_price,
      nights_weekends: this.state.nights_weekends,
      o2_use: this.state.o2_use,
      overnight: this.state.overnight,
      discount: this.state.discount,
    };
    $.post("/quotes", { quote: quote })
      .done((data) => {
        this.props.handleNewQuote(data);
        console.log("Quote Successfully Created!");
      })
      .fail((response) => {
        console.log("did not work");
        alert("Please review the form for errors");
      });
  }

  deleteQuote = () => {
    if (confirm("Are you sure you want to delete this quote?")) {
      $.ajax({
        type: "DELETE",
        url: `/quotes/${this.props.match.params.id}`,
      })
        .done((data) => {
          this.props.history.push("/quotes");
        })
        .fail((response) => {
          console.log("Quote deletion failed!");
        });
    }
  };

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSelectChange(key) {
    return function ({ value }) {
      this.setState({ [key]: value });
    }.bind(this);
  }

  render() {
    var markup =
      parseInt(this.state.flight_quote) * parseFloat(this.state.flight_markup);
    var flightCost = markup / 100 + parseInt(this.state.flight_quote);
    var flightQuoteCost = Math.round(flightCost);
    // flight distance price
    this.state.flight_distance_price =
      parseInt(this.state.flight_distance) > 0
        ? parseInt(this.state.flight_distance) * 4.8 + 300
        : 0;
    // flight lines price
    this.state.flight_lines_price = parseInt(this.state.flight_lines) * 50;
    // flight time price
    this.state.flight_time_price = parseFloat(this.state.flight_time) * 1500;
    // data drive price
    this.state.fuel_charge_price = parseInt(this.state.fuel_charge) * 25;
    // raw data charge price
    this.state.raw_data_charge_price =
      parseInt(this.state.raw_data_charge) * 250;
    // permit charge price
    this.state.permit_charge_price = parseInt(this.state.permit_charge) * 1500;
    // airspace charge price
    this.state.airspace_charge_price =
      parseInt(this.state.airspace_charge) * 100;
    // nights/weekends/holidays price
    this.state.night_weekends_price =
      parseInt(this.state.nights_weekends) * 1500;
    // abgps price
    this.state.travel_price = parseInt(this.state.abgps) * 500;
    // o2 use price
    this.state.o2_use_price = parseInt(this.state.o2_use) * 45;
    // overnight price
    this.state.overnight_price = parseInt(this.state.overnight) * 500;
    var quoteCost =
      Math.round(flightQuoteCost) +
      parseFloat(this.state.shipping) +
      parseFloat(this.state.flight_distance_price) +
      parseFloat(this.state.flight_lines_price) +
      parseFloat(this.state.flight_time_price) +
      parseFloat(this.state.fuel_charge_price) +
      parseFloat(this.state.raw_data_charge_price) +
      parseFloat(this.state.permit_charge_price) +
      parseFloat(this.state.airspace_charge_price) +
      parseFloat(this.state.night_weekends_price) +
      parseFloat(this.state.travel_price) +
      parseFloat(this.state.o2_use_price) +
      parseFloat(this.state.overnight_price) +
      parseInt(this.state.total_adjustment);

    this.state.flight_cost = flightQuoteCost;

    this.state.quote_cost = quoteCost.toFixed(2);

    return (
      <div className="card">
        <div className="card-body">
          <h4 className="mb-4 text-4xl">
            {this.state.editing
              ? "Update NAAS Flight Quote"
              : "Create NAAS Flight Quote"}
          </h4>
          <small>
            (* represents a <strong>required field</strong>)
          </small>
          <form onSubmit={this.handleFormSubmit}>
            <div className="row">
              <div className="col-md-3">
                <label>Quote Type *</label>
                <Select
                  name="quote_type"
                  disabled={true}
                  value={this.state.quote_type}
                  options={[
                    { value: "Film", label: "Film" },
                    { value: "Digital", label: "Digital" },
                    { value: "LiDAR", label: "LiDAR" },
                    { value: "NAAS", label: "NAAS" },
                    { value: "Scan", label: "Scan" },
                    { value: "Plot", label: "Plot" },
                    { value: "Survey", label: "Survey" },
                    { value: "Flight", label: "Flight" },
                    { value: "Other", label: "Other" },
                  ]}
                />
              </div>
            </div>
            <p />
            <h5>Flight</h5>
            <hr />
            <div className="row">
              <div className="col-md-3">
                <label>Flight Sub *</label>
                <Select.Creatable
                  required
                  allowCreate={true}
                  name="flight_sub"
                  value={this.state.flight_sub}
                  onChange={this.handleSelectChange("flight_sub")}
                  options={this.state.flight_sub_options}
                />
              </div>
              <div className="col-md-3">
                <label>Flight Quote *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="flight_quote"
                    required
                    value={this.state.flight_quote}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <label>Flight Markup *</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    name="flight_markup"
                    required
                    value={this.state.flight_markup}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                  <span className="input-group-text">%</span>
                </div>
              </div>
              <div className="col-md-3">
                <label>Flight Cost *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="flight_cost"
                    required
                    value={flightQuoteCost}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Camera Option *</label>
                <Select.Creatable
                  required
                  allowCreate={true}
                  name="camera_option"
                  value={this.state.camera_option}
                  onChange={this.handleSelectChange("camera_option")}
                  options={this.state.camera_option_options}
                />
              </div>
              <div className="col">
                <label>LiDAR Option *</label>
                <Select.Creatable
                  required
                  allowCreate={true}
                  name="lidar_option"
                  value={this.state.lidar_option}
                  onChange={this.handleSelectChange("lidar_option")}
                  options={this.state.lidar_option_options}
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Flight Distance (miles)</label>
                <input
                  name="flight_distance"
                  value={this.state.flight_distance || 0}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>One Way/Round Trip</label>
                <Select
                  required
                  allowCreate={true}
                  name="travel"
                  value={this.state.travel}
                  onChange={this.handleSelectChange("travel")}
                  options={[
                    { value: "One Way", label: "One Way" },
                    { value: "Round Trip", label: "Round Trip" },
                  ]}
                />
              </div>
              <div className="col">
                <label>Flight Distance Price</label>
                <input
                  name="flight_distance_price"
                  value={this.state.flight_distance_price || 0}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Flight Lines</label>
                <input
                  name="flight_lines"
                  value={this.state.flight_lines || 0}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Flight Lines Price</label>
                <input
                  name="flight_lines_price"
                  value={this.state.flight_lines_price || 0}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>On Line Hours Flight Time</label>
                <input
                  name="flight_time"
                  value={this.state.flight_time || 0}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>On Line Hours Price</label>
                <input
                  name="flight_time_price"
                  value={this.state.flight_time_price || 0}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Data Drive</label>
                <Select
                  name="fuel_charge"
                  value={this.state.fuel_charge}
                  onChange={this.handleSelectChange("fuel_charge")}
                  options={[
                    { value: "0", label: "None" },
                    { value: "1", label: "40GB" },
                    { value: "2", label: "80GB" },
                    { value: "3", label: "120GB" },
                    { value: "4", label: "1TB" },
                    { value: "5", label: "2TB" },
                  ]}
                />
              </div>
              <div className="col">
                <label>Data Drive Price</label>
                <input
                  type="text"
                  name="fuel_charge_price"
                  value={this.state.fuel_charge_price || 0}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>RAW Data Process Level</label>
                <input
                  type="number"
                  min="0"
                  max="4"
                  name="raw_data_charge"
                  value={this.state.raw_data_charge || 0}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>RAW Data Price</label>
                <input
                  name="raw_data_charge_price"
                  value={this.state.raw_data_charge_price || 0}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-6">
                <label>Shipping Price</label>
                <input
                  name="shipping"
                  value={this.state.shipping || 0}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Permit Charge</label>
                <input
                  name="permit_charge"
                  value={this.state.permit_charge || 0}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Permit Price</label>
                <input
                  name="permit_charge_price"
                  value={this.state.permit_charge_price || 0}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>OT Weekends/Holidays</label>
                <input
                  name="nights_weekends"
                  value={this.state.nights_weekends || 0}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>OT Weekends/Holidays Price</label>
                <input
                  name="night_weekends_price"
                  value={this.state.night_weekends_price || 0}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>ABGPS Ground Station</label>
                <input
                  name="abgps"
                  value={this.state.abgps || 0}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>ABGPS Ground Station Price</label>
                <input
                  name="travel_price"
                  value={this.state.travel_price || 0}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>O2 Use as per High Altitude Number of Lines</label>
                <input
                  name="o2_use"
                  value={this.state.o2_use || 0}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>O2 Use Price</label>
                <input
                  name="o2_use_price"
                  value={this.state.o2_use_price || 0}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <p />
            <div className="row">
              <div className="col">
                <label>Restricted or Class B Airspace Charge</label>
                <input
                  name="airspace_charge"
                  value={this.state.airspace_charge || 0}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Price</label>
                <input
                  name="airspace_charge_price"
                  value={this.state.airspace_charge_price || 0}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Overnight</label>
                <input
                  name="overnight"
                  value={this.state.overnight || 0}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Overnight Price</label>
                <input
                  name="overnight_price"
                  value={this.state.overnight_price || 0}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Flight Notes</label>
                <textarea
                  name="flight_notes"
                  value={this.state.flight_notes}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-2">
                <label>Total Adjustment</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="total_adjustment"
                    required
                    value={this.state.total_adjustment}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <label>Explanation</label>
                <input
                  type="text"
                  name="adjustment_reason"
                  value={this.state.adjustment_reason}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <h5>Total Quote Cost</h5>
            <hr />
            <div className="row">
              <div className="col">
                <label>Quote Cost</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="quote_cost"
                    required
                    value={this.state.quote_cost}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <input
              type="submit"
              value={this.state.editing ? "Update" : "Create"}
              className="btn btn-secondary btn-sm"
            />
          </form>
          <br />
        </div>
      </div>
    );
  }
}
