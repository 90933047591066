import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import update from 'immutability-helper';
import { withRouter } from 'react-router-dom';

export default class CompanyForm extends React.Component {
  static propTypes = {
    handleNewCompany: PropTypes.func
  }

  constructor(props, _railsContext) {
    super(props)
    this.state = {
      user_id: '',
      alert: 'No',
      alert_message: '',
      name: '',
      website: '',
      ftp_name: '',
      ftp_password: '',
      premium_charge: 'No',
      editing: false,
    }
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/companies/${this.props.match.params.id}`,
        dataType: "JSON"
      }).done((data) => {
        this.setState({
          user_id: data.user_id,
          alert: data.alert,
          alert_message: data.alert_message,
          name: data.name,
          website: data.website,
          ftp_name: data.ftp_name,
          ftp_password: data.ftp_password,
          premium_charge: data.premium_charge,
          editing: this.props.match.path === '/companies/:id/edit'
        });
      });
    }
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.state.editing ?
      this.updateCompany() :
      this.addCompany();
  }

  updateCompany() {
    const company = {
      user_id: this.state.user_id,
      alert: this.state.alert,
      alert_message: this.state.alert_message,
      name: this.state.name,
      website: this.state.website,
      ftp_name: this.state.ftp_name,
      ftp_password: this.state.ftp_password,
      premium_charge: this.state.premium_charge
    };
    $.ajax({
      type: "PATCH",
      url: `/companies/${this.props.match.params.id}`,
      data: { company: company }
    })
      .done((_data) => {
        console.log('company updated!');
      })
      .catch((error) => {
        console.log(error);
        alert("Please review the form for: " + error.responseText.replace(/\W/g, ' '));
      })
      .fail((_response) => {
        console.log("did not work");
        alert("Please review the form for errors");
      });
  }

  addCompany() {
    const company = {
      user_id: this.state.user_id,
      alert: this.state.alert,
      alert_message: this.state.alert_message,
      name: this.state.name,
      website: this.state.website,
      ftp_name: this.state.ftp_name,
      ftp_password: this.state.ftp_password,
      premium_charge: this.state.premium_charge
    };
    $.post('/companies', { company: company })
      .done((data) => {
        this.props.handleNewCompany(data);
        console.log("Company Successfully Created!");
      })
      .catch((error) => {
        console.log(error);
        alert("Please review the form for: " + error.responseText.replace(/\W/g, ' '));
      })
      .fail((_response) => {
        console.log("did not work");
        alert("Please review the form for errors, the company already exists.");
      });
  }

  deleteCompany = () => {
    if (confirm("Are you sure you want to delete this company?")) {
      $.ajax({
        type: "DELETE",
        url: `/companies/${this.props.match.params.id}`,
      })
        .done((_data) => {
          this.props.history.push('/companies');
        })
        .fail((_response) => {
          console.log("Company deletion failed!");
        });
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSelectChange(key) {
    return function ({ value }) {
      this.setState({ [key]: value });
    }.bind(this);
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          {this.state.editing && (
            <div>
              <a
                href={`/companies`}
                title="Click to go back to main company dashboard"
                className="btn btn-sm btn-light"
              >
                Back to Company Dashboard
              </a>
              <hr />
              <h3 className="mb-4 text-4xl">Update {this.state.name || ""}</h3>
            </div>
          )}
          <h4 className="mb-4 text-4xl">
            {this.state.editing ? "" : "Create Company"}
          </h4>
          <small>
            (* represents a <strong>required field</strong>)
          </small>
          <form onSubmit={this.handleFormSubmit}>
            <div className="row">
              <div className="col-lg-6">
                <label title="Enter company name">Name *</label>
                <input
                  type="text"
                  name="name"
                  placeholder="Company Name"
                  required
                  value={this.state.name}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-lg-6">
                <label title="Enter company website">Website</label>
                <input
                  type="text"
                  name="website"
                  placeholder="Company Website"
                  value={this.state.website}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-lg-6">
                <label title="Enter company FTP name">FTP Name</label>
                <input
                  type="text"
                  name="ftp_name"
                  placeholder="Company FTP"
                  value={this.state.ftp_name}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-lg-6">
                <label title="Enter FTP password">FTP Password</label>
                <input
                  type="text"
                  name="ftp_password"
                  placeholder="FTP Password"
                  value={this.state.ftp_password}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-lg-3">
                <label title="Select 'Yes' if premium charge should be added to this company">
                  Add Premium Charge to Company
                </label>
                <Select
                  name="premium_charge"
                  value={this.state.premium_charge}
                  onChange={this.handleSelectChange("premium_charge")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-lg-3">
                <label title="Select 'Yes' if alert should be added to this company">
                  Add Alert for this Company
                </label>
                <Select
                  name="alert"
                  value={this.state.alert}
                  onChange={this.handleSelectChange("alert")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
            </div>
            <p />
            {this.state.alert == "Yes" && (
              <div className="row">
                <div className="col">
                  <label title="Add alert message for company">
                    Alert Message - This is an alert that serves as a reminder
                    for projects going into production. The alert will be shown
                    in the 'Create Project' form.
                  </label>
                  <input
                    type="text"
                    name="alert_message"
                    placeholder="Company Alert Message"
                    value={this.state.alert_message}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            )}
            <p />
            <input
              title="Click to submit"
              type="submit"
              value={this.state.editing ? "Update Company" : "Create Company"}
              className="btn btn-secondary btn-sm"
            />
          </form>
          <br />
        </div>
      </div>
    );
  }
}
