import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["startDate", "clientRep", "pm", "projectName", "company", "client", "location", "cost", "industry", "table"]
  connect() {
    // Code to run when the controller is connected to the DOM
  }

  // Add your custom controller actions here
  filterPosts(event) {
    const searchTerm = event.target.value.toLowerCase();

    this.startDateTargets.forEach((startDate) => {
      const postClientRep = startDate.nextElementSibling.textContent.toLowerCase();
      const postPM = startDate.nextElementSibling.nextElementSibling.textContent.toLowerCase();
      const postProjectName = startDate.nextElementSibling.nextElementSibling.nextElementSibling.textContent.toLowerCase();
      const postCompany = startDate.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.textContent.toLowerCase();
      const postClient = startDate.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.textContent.toLowerCase();
      const postLocation = startDate.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.textContent.toLowerCase();
      const postCost = startDate.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.textContent.toLowerCase();
      const postIndustry = startDate.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.textContent.toLowerCase();
      if (postClientRep.includes(searchTerm) || postPM.includes(searchTerm) || postProjectName.includes(searchTerm) || postCompany.includes(searchTerm) || postClient.includes(searchTerm) || postLocation.includes(searchTerm) || postCost.includes(searchTerm) || postIndustry.includes(searchTerm)) {
        startDate.parentElement.style.display = "";
      } else {
        startDate.parentElement.style.display = "none";
      }
    });
  }

  sort(event) {
    const table = this.tableTarget
    const tbody = table.tBodies[0]
    const th = event.currentTarget
    const index = Array.from(th.parentNode.children).indexOf(th)
    const direction = th.dataset.sortDirection == 'asc' ? 'desc' : 'asc'
    const rows = Array.from(tbody.rows)

    rows.sort((rowA, rowB) => {
      const cellA = rowA.cells[index].textContent.trim()
      const cellB = rowB.cells[index].textContent.trim()

      return cellA > cellB ? 1 : -1
    })

    if (direction == 'desc') rows.reverse()

    rows.forEach(row => tbody.appendChild(row))

    th.dataset.sortDirection = direction
  }
}
