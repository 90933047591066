import React from 'react';
import PropTypes from 'prop-types';

export default class CompToSub extends React.Component {
  render() {
    return (
      <div>
        <div className="card">
          <div className="card-body">
            <h6 id="header">Legend</h6>
            <div className="row">
              <small className="cyan-legend">
                Cyan represents the project has a hard delivery date
              </small>
            </div>
            <div className="row">
              <small className="red-legend">
                Red represents the project is due or past due
              </small>
            </div>
            <div className="row">
              <small className="blue-legend">
                Blue represents the project is due within 5 days
              </small>
            </div>
            <div className="row">
              <small className="green-legend">
                Green background represents the project has been received from
                sub
              </small>
            </div>
            <div className="row">
              <small className="orange-legend">
                Orange background over true/false represents the project is DSM
              </small>
            </div>
            <div className="row">
              <small className="magenta-legend">
                Magenta background over Yes/No represents the DSM being complete or not for the project
              </small>
            </div>
          </div>
        </div>
      </div>
    );
  }
}