import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import 'react-select/dist/react-select.css';

export default class QuoteForm extends React.Component {
  static propTypes = {
    handleNewQuote: PropTypes.func
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      bonus_perc: 2.5,
      lidar_flight_sub: "",
      lidar_flight_sub_options: [
        { value: "Cooper Aerial", label: "Cooper Aerial" },
        { value: "Geomni", label: "Geomni" },
        { value: "Verisk", label: "Verisk" },
        { value: "NAAS", label: "NAAS" },
        { value: "SAM", label: "SAM" },
        { value: "CSTI", label: "CSTI" },
        { value: "Kucera", label: "Kucera" },
        { value: "Paragon", label: "Paragon" },
        { value: "Terra Flight", label: "Terra Flight" },
        { value: "Berry and Associates", label: "Berry and Associates" },
        { value: "Aerial Surveys Int'l", label: "Aerial Surveys Int'l" },
        { value: "G Squared", label: "G Squared" },
        { value: "SurvTech Solutions", label: "SurvTech Solutions" },
        { value: "Sitech", label: "Sitech" },
        { value: "Atlantic Group", label: "Atlantic Group" },
        { value: "Aero-Graphics", label: "Aero-Graphics" },
        { value: "Keystone Aerial", label: "Keystone Aerial" },
        { value: "American Aerial", label: "American Aerial" },
        { value: "Valley Air", label: "Valley Air" },
        { value: "Radman Aerial", label: "Radman Aerial" },
        { value: "Air Flight SVCS", label: "Air Flight SVCS" },
        {
          value: "Skyview Aerial Photo (South CA)",
          label: "Skyview Aerial Photo (South CA)",
        },
        { value: "Midwest Aerial Photo", label: "Midwest Aerial Photo" },
        { value: "Gammair", label: "Gammair" },
        {
          value: "Richard Crouse and Associates",
          label: "Richard Crouse and Associates",
        },
        {
          value: "I.F. Rooks & Associates, Inc.",
          label: "I.F. Rooks & Associates, Inc.",
        },
        {
          value: "Aerial Data Service, Inc.",
          label: "Aerial Data Service, Inc.",
        },
        { value: "Aerodata, Inc.", label: "Aerodata, Inc." },
        { value: "Lasermap USA", label: "Lasermap USA" },
        { value: "Aerials, Inc.", label: "Aerials, Inc." },
        {
          value: "Gulf Coast Aerial Mapping",
          label: "Gulf Coast Aerial Mapping",
        },
        { value: "Air Photographics, Inc.", label: "Air Photographics, Inc." },
        { value: "Ace Aerial", label: "Ace Aerial" },
        { value: "Georgia Aerial Surveys", label: "Georgia Aerial Surveys" },
        {
          value: "Krawietz Aerial Photo Service",
          label: "Krawietz Aerial Photo Service",
        },
        {
          value: "Continental Aerial Surveys Inc.",
          label: "Continental Aerial Surveys Inc.",
        },
        { value: "Aerial Imaging Inc.", label: "Aerial Imaging Inc." },
        {
          value: "McClintock Land Associates, Inc.",
          label: "McClintock Land Associates, Inc.",
        },
        { value: "Eagle Aerial Solutions", label: "Eagle Aerial Solutions" },
        {
          value: "Rocky Mountain Aerial Surveys",
          label: "Rocky Mountain Aerial Surveys",
        },
        { value: "Blue Skies Consulting", label: "Blue Skies Consulting" },
        { value: "GeoTerra", label: "GeoTerra" },
        { value: "Quantum Spatial", label: "Quantum Spatial" },
        { value: "Aerocon", label: "Aerocon" },
        { value: "Pickett Aerial", label: "Pickett Aerial" },
        { value: "Airborne Sensing", label: "Airborne Sensing" },
        { value: "MAP RES", label: "MAP RES" },
        { value: "Predictive Service", label: "Predictive Service" },
        { value: "Wilson Company", label: "Wilson Company" },
        { value: "KBM, Inc.", label: "KBM, Inc." },
        { value: "Air Flight Service", label: "Air Flight Service" },
        { value: "Lidar Services LLC", label: "Lidar Services LLC" },
        { value: "Eagle Mapping", label: "Eagle Mapping" },
      ],
      lidar_flight_quote: "0",
      lidar_flight_markup: "0",
      lidar_flight_cost: "0",
      lidar_size: "0",
      size_type: "Acres",
      detail_level: "0",
      lidar_hours: "0",
      lidar_ppsm: "0",
      lidar_bare_earth: "",
      scan_qty: "0",
      analytics_qty: "0",
      photogrammetry_qty: "0",
      cartography_qty: "0",
      img_qty: "0",
      flight_sub: "",
      flight_sub_options: [
        { value: "Cooper Aerial", label: "Cooper Aerial" },
        { value: "Geomni", label: "Geomni" },
        { value: "Verisk", label: "Verisk" },
        { value: "NAAS", label: "NAAS" },
        { value: "SWAS", label: "SWAS" },
        { value: "SAM", label: "SAM" },
        { value: "CSTI", label: "CSTI" },
        { value: "Kucera", label: "Kucera" },
        { value: "Paragon", label: "Paragon" },
        { value: "Terra Flight", label: "Terra Flight" },
        { value: "Berry and Associates", label: "Berry and Associates" },
        { value: "Aerial Surveys Int'l", label: "Aerial Surveys Int'l" },
        { value: "G Squared", label: "G Squared" },
        { value: "SurvTech Solutions", label: "SurvTech Solutions" },
        { value: "Sitech", label: "Sitech" },
        { value: "Atlantic Group", label: "Atlantic Group" },
        { value: "Aero-Graphics", label: "Aero-Graphics" },
        { value: "Keystone Aerial", label: "Keystone Aerial" },
        { value: "American Aerial", label: "American Aerial" },
        { value: "Valley Air", label: "Valley Air" },
        { value: "Radman Aerial", label: "Radman Aerial" },
        { value: "Air Flight SVCS", label: "Air Flight SVCS" },
        {
          value: "Skyview Aerial Photo (South CA)",
          label: "Skyview Aerial Photo (South CA)",
        },
        { value: "Midwest Aerial Photo", label: "Midwest Aerial Photo" },
        { value: "Gammair", label: "Gammair" },
        {
          value: "Richard Crouse and Associates",
          label: "Richard Crouse and Associates",
        },
        {
          value: "I.F. Rooks & Associates, Inc.",
          label: "I.F. Rooks & Associates, Inc.",
        },
        {
          value: "Aerial Data Service, Inc.",
          label: "Aerial Data Service, Inc.",
        },
        { value: "Aerodata, Inc.", label: "Aerodata, Inc." },
        { value: "Lasermap USA", label: "Lasermap USA" },
        { value: "Aerials, Inc.", label: "Aerials, Inc." },
        {
          value: "Gulf Coast Aerial Mapping",
          label: "Gulf Coast Aerial Mapping",
        },
        { value: "Air Photographics, Inc.", label: "Air Photographics, Inc." },
        { value: "Ace Aerial", label: "Ace Aerial" },
        { value: "Georgia Aerial Surveys", label: "Georgia Aerial Surveys" },
        {
          value: "Krawietz Aerial Photo Service",
          label: "Krawietz Aerial Photo Service",
        },
        {
          value: "Continental Aerial Surveys Inc.",
          label: "Continental Aerial Surveys Inc.",
        },
        { value: "Aerial Imaging Inc.", label: "Aerial Imaging Inc." },
        {
          value: "McClintock Land Associates, Inc.",
          label: "McClintock Land Associates, Inc.",
        },
        { value: "Eagle Aerial Solutions", label: "Eagle Aerial Solutions" },
        {
          value: "Rocky Mountain Aerial Surveys",
          label: "Rocky Mountain Aerial Surveys",
        },
        { value: "Blue Skies Consulting", label: "Blue Skies Consulting" },
        { value: "GeoTerra", label: "GeoTerra" },
        { value: "Quantum Spatial", label: "Quantum Spatial" },
        { value: "Aerocon", label: "Aerocon" },
        { value: "Pickett Aerial", label: "Pickett Aerial" },
        { value: "Airborne Sensing", label: "Airborne Sensing" },
        { value: "MAP RES", label: "MAP RES" },
        { value: "Predictive Service", label: "Predictive Service" },
        { value: "Wilson Company", label: "Wilson Company" },
        { value: "KBM, Inc.", label: "KBM, Inc." },
        { value: "Air Flight Service", label: "Air Flight Service" },
        { value: "Lidar Services LLC", label: "Lidar Services LLC" },
        { value: "Eagle Mapping", label: "Eagle Mapping" },
      ],
      flight_quote: "0",
      flight_markup: "0",
      flight_cost: "0",
      flight_notes: "",
      panels: "0",
      abgps: "No",
      survey_sub: "",
      survey_cost: "0",
      survey_quote: "0",
      survey_markup: "0",
      survey_notes: "",
      contact_prints: "0",
      contact_prints_cp: "10",
      diapositives: "0",
      diapositives_cp: "15",
      scan_cp: "20",
      lab_discount: "0",
      lab_cost: "0",
      lab_notes: "",
      analytics_hp: "1.5",
      analytics_cp: "100",
      photogrammetry_cp: "75",
      photogrammetry_hp: "7",
      xsec: "0",
      xsec_cp: "70",
      xsec_hp: "3",
      cartography_cp: "50",
      cartography_hp: "2",
      translation: "0",
      translation_cp: "70",
      translation_hp: ".2",
      map_discount: "0",
      map_cost: "0",
      img_hp: "1",
      img_cp: "90",
      plots: "0",
      plot_x: "",
      plot_y: "",
      plot_cp: "50",
      delivery: "0",
      delivery_type: "",
      delivery_type_options: [
        { value: "FTP", label: "FTP" },
        { value: "USB", label: "USB" },
        { value: "H/D", label: "H/D" },
        { value: "DVD", label: "DVD" },
      ],
      delivery_cp: "0",
      img_discount: "0",
      img_cost: "0",
      quote_cost: "0",
      map_notes: "",
      img_notes: "",
      plot_mount: "",
      camera: "",
      supplemental_survey: "",
      plain_gc: "",
      description: "",
      sales_tax: "0",
      unit_price: "0",
      shipping: "0",
      discount: "0",
      editing: false,
    };
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if(this.props.match) {
    $.ajax({
      type: "GET",
      url: `/quotes/${this.props.match.params.id}`,
      dataType: "JSON"
    }).done((data) => {
      this.setState({
        bonus_perc: data.quote.bonus_perc,
        user_id: data.quote.user_id,
        lidar_ppsm: data.quote.lidar_ppsm,
        lidar_bare_earth: data.quote.lidar_bare_earth,
        scan_qty: data.quote.scan_qty,
        analytics_qty: data.quote.analytics_qty,
        cartography_qty: data.quote.cartography_qty,
        photogrammetry_qty: data.quote.photogrammetry_qty,
        img_qty: data.quote.img_qty,
        opportunity_id: data.quote.opportunity_id,
        specification_id: data.quote.specification_id,
        flight_sub: data.quote.flight_sub,
        flight_quote: data.quote.flight_quote,
        flight_markup: data.quote.flight_markup,
        flight_cost: data.quote.flight_cost,
        flight_notes: data.quote.flight_notes,
        panels: data.quote.panels,
        abgps: data.quote.abgps,
        survey_sub: data.quote.survey_sub,
        survey_cost: data.quote.survey_cost,
        survey_quote: data.quote.survey_quote,
        survey_markup: data.quote.survey_markup,
        survey_notes: data.quote.survey_notes,
        contact_prints: data.quote.contact_prints,
        contact_prints_cp: data.quote.contact_prints_cp,
        diapositives: data.quote.diapositives,
        diapositives_cp: data.quote.diapositives_cp,
        scan_cp: data.quote.scan_cp,
        lab_discount: data.quote.lab_discount,
        lab_cost: data.quote.lab_cost,
        lab_notes: data.quote.lab_notes,
        analytics_hp: data.quote.analytics_hp,
        analytics_cp: data.quote.analytics_cp,
        photogrammetry_cp: data.quote.photogrammetry_cp,
        photogrammetry_hp: data.quote.photogrammetry_hp,
        xsec: data.quote.xsec,
        xsec_cp: data.quote.xsec_cp,
        xsec_hp: data.quote.xsec_hp,
        cartography_cp: data.quote.cartography_cp,
        cartography_hp: data.quote.cartography_hp,
        translation: data.quote.translation,
        translation_cp: data.quote.translation_cp,
        translation_hp: data.quote.translation_hp,
        map_discount: data.quote.map_discount,
        map_cost: data.quote.map_cost,
        img_hp: data.quote.img_hp,
        img_cp: data.quote.img_cp,
        plots: data.quote.plots,
        plot_x: data.quote.plot_x,
        plot_y: data.quote.plot_y,
        plot_cp: data.quote.plot_cp,
        delivery: data.quote.delivery,
        delivery_type: data.quote.delivery_type,
        delivery_cp: data.quote.delivery_cp,
        img_discount: data.quote.img_discount,
        img_cost: data.quote.img_cost,
        quote_cost: data.quote.quote_cost,
        map_notes: data.quote.map_notes,
        img_notes: data.quote.img_notes,
        plot_mount: data.quote.plot_mount,
        camera: data.quote.camera,
        supplemental_survey: data.quote.supplemental_survey,
        plain_gc: data.quote.plain_gc,
        description: data.quote.description,
        sales_tax: data.quote.sales_tax,
        unit_price: data.quote.unit_price,
        shipping: data.quote.shipping,
        discount: data.quote.discount,
        quote_type: data.quote.quote_type,
        lidar_flight_sub: data.quote.lidar_flight_sub,
        lidar_flight_quote: data.quote.lidar_flight_quote,
        lidar_flight_markup: data.quote.lidar_flight_markup,
        lidar_flight_cost: data.quote.lidar_flight_cost,
        lidar_size: data.quote.lidar_size,
        size_type: data.quote.size_type,
        detail_level: data.quote.detail_level,
        lidar_hours: data.quote.lidar_hours,
        editing: this.props.match.path === "/quotes/:id/edit",
      });
    });
   }
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.state.editing ?
      this.updateQuote() :
      this.addQuote();
  }

  updateQuote() {
    const quote = {
      bonus_perc: this.state.bonus_perc,
      user_id: this.props.userId,
      lidar_ppsm: this.state.lidar_ppsm,
      lidar_bare_earth: this.state.lidar_bare_earth,
      lidar_size: this.state.lidar_size,
      size_type: this.state.size_type,
      detail_level: this.state.detail_level,
      lidar_hours: this.state.lidar_hours,
      scan_qty: this.props.scanQty,
      analytics_qty: this.props.analyticsQty,
      cartography_qty: this.props.cartographyQty,
      photogrammetry_qty: this.props.photogrammetryQty,
      img_qty: this.props.imgQty,
      opportunity_id: this.props.oppId,
      specification_id: this.props.specId,
      quote_type: this.props.quoteType,
      flight_sub: this.state.flight_sub,
      flight_quote: this.state.flight_quote,
      flight_markup: this.state.flight_markup,
      flight_cost: this.state.flight_cost,
      flight_notes: this.state.flight_notes,
      lidar_flight_sub: this.state.lidar_flight_sub,
      lidar_flight_quote: this.state.lidar_flight_quote,
      lidar_flight_markup: this.state.lidar_flight_markup,
      lidar_flight_cost: this.state.lidar_flight_cost,
      panels: this.state.panels,
      abgps: this.state.abgps,
      survey_sub: this.state.survey_sub,
      survey_cost: this.state.survey_cost,
      survey_quote: this.state.survey_quote,
      survey_markup: this.state.survey_markup,
      survey_notes: this.state.survey_notes,
      contact_prints: this.props.contactPrints,
      contact_prints_cp: this.state.contact_prints_cp,
      diapositives: this.props.diaPositives,
      diapositives_cp: this.state.diapositives_cp,
      scan_cp: this.state.scan_cp,
      lab_discount: this.state.lab_discount,
      lab_cost: this.state.lab_cost,
      lab_notes: this.state.lab_notes,
      analytics_hp: this.state.analytics_hp,
      analytics_cp: this.state.analytics_cp,
      photogrammetry_cp: this.state.photogrammetry_cp,
      photogrammetry_hp: this.state.photogrammetry_hp,
      xsec: this.state.xsec,
      xsec_cp: this.state.xsec_cp,
      xsec_hp: this.state.xsec_hp,
      cartography_cp: this.state.cartography_cp,
      cartography_hp: this.state.cartography_hp,
      translation: this.state.translation,
      translation_cp: this.state.translation_cp,
      translation_hp: this.state.translation_hp,
      map_discount: this.state.map_discount,
      map_cost: this.state.map_cost,
      img_hp: this.state.img_hp,
      img_cp: this.state.img_cp,
      plots: this.state.plots,
      plot_x: this.state.plot_x,
      plot_y: this.state.plot_y,
      plot_cp: this.state.plot_cp,
      delivery: this.state.delivery,
      delivery_type: this.state.delivery_type,
      delivery_cp: this.state.delivery_cp,
      img_discount: this.state.img_discount,
      img_cost: this.state.img_cost,
      quote_cost: this.state.quote_cost,
      map_notes: this.state.map_notes,
      img_notes: this.state.img_notes,
      plot_mount: this.state.plot_mount,
      camera: this.state.camera,
      supplemental_survey: this.state.supplemental_survey,
      plain_gc: this.state.plain_gc,
      description: this.state.description,
      sales_tax: this.state.sales_tax,
      unit_price: this.state.unit_price,
      shipping: this.state.shipping,
      discount: this.state.discount
    };
    $.ajax({
      type: "PATCH",
      url: `/quotes/${this.props.match.params.id}`,
      data: { quote: quote }
    })
      .done((data) => {
        console.log('Quote updated!');
      })
      .fail((response) => {
        console.log("did not work");
        alert("Please review the form for errors");
      });
  }

  addQuote() {
    const quote = {
      bonus_perc: this.state.bonus_perc,
      user_id: this.props.userId,
      lidar_ppsm: this.state.lidar_ppsm,
      lidar_bare_earth: this.state.lidar_bare_earth,
      lidar_size: this.state.lidar_size,
      size_type: this.state.size_type,
      detail_level: this.state.detail_level,
      lidar_hours: this.state.lidar_hours,
      scan_qty: this.props.scanQty,
      analytics_qty: this.props.analyticsQty,
      cartography_qty: this.props.cartographyQty,
      photogrammetry_qty: this.props.photogrammetryQty,
      img_qty: this.props.imgQty,
      opportunity_id: this.props.oppId,
      specification_id: this.props.specId,
      quote_type: this.props.quoteType,
      flight_sub: this.state.flight_sub,
      flight_quote: this.state.flight_quote,
      flight_markup: this.state.flight_markup,
      flight_cost: this.state.flight_cost,
      flight_notes: this.state.flight_notes,
      lidar_flight_sub: this.state.lidar_flight_sub,
      lidar_flight_quote: this.state.lidar_flight_quote,
      lidar_flight_markup: this.state.lidar_flight_markup,
      lidar_flight_cost: this.state.lidar_flight_cost,
      panels: this.state.panels,
      abgps: this.state.abgps,
      survey_sub: this.state.survey_sub,
      survey_cost: this.state.survey_cost,
      survey_quote: this.state.survey_quote,
      survey_markup: this.state.survey_markup,
      survey_notes: this.state.survey_notes,
      contact_prints: this.props.contactPrints,
      contact_prints_cp: this.state.contact_prints_cp,
      diapositives: this.props.diaPositives,
      diapositives_cp: this.state.diapositives_cp,
      scan_cp: this.state.scan_cp,
      lab_discount: this.state.lab_discount,
      lab_cost: this.state.lab_cost,
      lab_notes: this.state.lab_notes,
      analytics_hp: this.state.analytics_hp,
      analytics_cp: this.state.analytics_cp,
      photogrammetry_cp: this.state.photogrammetry_cp,
      photogrammetry_hp: this.state.photogrammetry_hp,
      xsec: this.state.xsec,
      xsec_cp: this.state.xsec_cp,
      xsec_hp: this.state.xsec_hp,
      cartography_cp: this.state.cartography_cp,
      cartography_hp: this.state.cartography_hp,
      translation: this.state.translation,
      translation_cp: this.state.translation_cp,
      translation_hp: this.state.translation_hp,
      map_discount: this.state.map_discount,
      map_cost: this.state.map_cost,
      img_hp: this.state.img_hp,
      img_cp: this.state.img_cp,
      plots: this.state.plots,
      plot_x: this.state.plot_x,
      plot_y: this.state.plot_y,
      plot_cp: this.state.plot_cp,
      delivery: this.state.delivery,
      delivery_type: this.state.delivery_type,
      delivery_cp: this.state.delivery_cp,
      img_discount: this.state.img_discount,
      img_cost: this.state.img_cost,
      quote_cost: this.state.quote_cost,
      map_notes: this.state.map_notes,
      img_notes: this.state.img_notes,
      plot_mount: this.state.plot_mount,
      camera: this.state.camera,
      supplemental_survey: this.state.supplemental_survey,
      plain_gc: this.state.plain_gc,
      description: this.state.description,
      sales_tax: this.state.sales_tax,
      unit_price: this.state.unit_price,
      shipping: this.state.shipping,
      discount: this.state.discount,
    };
    $.post('/quotes', { quote: quote })
      .done((data) => {
        this.props.handleNewQuote(data);
        console.log("Quote Successfully Created!");
      })
      .fail((response) => {
        console.log("did not work");
        alert("Please review the form for errors");
      });
  }

  deleteQuote = () => {
    if (confirm("Are you sure you want to delete this quote?")) {
      $.ajax({
        type: "DELETE",
        url: `/quotes/${this.props.match.params.id}`,
      })
        .done((data) => {
          this.props.history.push('/quotes');
        })
        .fail((response) => {
          console.log("Quote deletion failed!");
        });
    }
  }

  handleChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  handleSelectChange(key) {
    return function ({ value }) {
      this.setState({ [key]: value });
    }.bind(this);
  }

  toggleHiddenFlightComplete() {
    this.setState({
      isHiddenFlightComplete: !this.state.isHiddenFlightComplete
    })
  }

  toggleHiddenSurveyComplete() {
    this.setState({
      isHiddenSurveyComplete: !this.state.isHiddenSurveyComplete
    })
  }

  toggleHiddenPhotoLabComplete() {
    this.setState({
      isHiddenPhotoLabComplete: !this.state.isHiddenPhotoLabComplete
    })
  }

  toggleHiddenMappingComplete() {
    this.setState({
      isHiddenMappingComplete: !this.state.isHiddenMappingComplete
    })
  }

  toggleHiddenImageryComplete() {
    this.setState({
      isHiddenImageryComplete: !this.state.isHiddenImageryComplete
    })
  }

  toggleHiddenImageComplete() {
    this.setState({
      isHiddenImageComplete: !this.state.isHiddenImageComplete
    })
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <h4 className="mb-4 text-4xl">
            {this.state.editing ?
              'Update Custom Quote' :
              'Create Custom Quote'}
          </h4>
          <small>
            (* represents a <strong>required field</strong>)
          </small>
          <form onSubmit={this.handleFormSubmit} >
            <div className="row">
              <div className="col-md-3">
                <label>Quote Type *</label>
                <Select
                name="quote_type"
                disabled={true}
                value={this.props.quoteType}
                options={[
                  { value: 'Film', label: 'Film' },
                  { value: 'Digital', label: 'Digital' },
                  { value: 'LiDAR', label: 'LiDAR' },
                  { value: 'Scan', label: 'Scan' },
                  { value: 'Plot', label: 'Plot' },
                  { value: 'Survey', label: 'Survey' },
                  { value: 'Other', label: 'Custom' },
                ]}
              />
            </div>
            </div>
            <p />
            <h5>Total Quote Cost</h5>
            <hr />
            <div className="row">
              <div className="col-md-2">
                <label>Quote Cost</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input type="text" name="quote_cost"
                    value={this.state.quote_cost}
                    onChange={this.handleChange}
                    className="form-control" />
                </div>
              </div>
            </div>
            <p />
            <input type="submit"
              value={this.state.editing ? 'Update' : 'Create'} className="btn btn-secondary btn-sm"
            />
          </form>
          <br />
        </div>
      </div>
    )
  }

}
