import React from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";

export default class CampaignEmails extends React.Component {
  static propTypes = {
    campaign_emails: PropTypes.array,
  };

  static defaultProps = {
    campaign_emails: [],
  };

  constructor(props, railsContext) {
    super(props);
    this.state = {
      campaign_emails: this.props.campaign_emails,
      isHidden: true,
      isHiddenNotComplete: false,
      isLoading: false,
    };
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }

  toggleHiddenNotComplete() {
    this.setState({
      isHiddenNotComplete: !this.state.isHiddenNotComplete,
    });
  }

  componentDidMount() {
    if (this.props.match) {
      this.setState({ isLoading: true });
      $.ajax({
        type: "GET",
        url: "/marketing/campaign_emails",
        dataType: "JSON",
      }).done((data) => {
        this.setState({ campaign_emails: data, isLoading: false });
      });
    }
  }

  render() {
    const { campaign_emails, isLoading } = this.state;
    if (isLoading) {
      return <div className="loader"></div>;
    }

    return (
      <div>
        <ReactTable
          key={campaign_emails.id}
          data={campaign_emails}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          defaultPageSize={100}
          style={{
            height: "800px",
            textAlign: "center",
          }}
          columns={[
            {
              Header: "Email Campaign Info",
              foldable: true,
              columns: [
                {
                  Header: "Campaign Title",
                  accessor: "campaign.title",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={(event) => onChange(event.target.value)}
                      value={filter ? filter.value : ""}
                      style={{
                        width: "100%",
                        color: "black",
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id]
                      .toLowerCase()
                      .includes(filter.value.toLowerCase()),
                  Cell: (props) => (
                    <a
                      href={`/marketing/campaign_emails/${props.original.id}`}
                      className="view-record"
                    >
                      {props.original.campaign.title}
                    </a>
                  ),
                },
                {
                  Header: "Email Campaign Name",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={(event) => onChange(event.target.value)}
                      value={filter ? filter.value : ""}
                      style={{
                        width: "100%",
                        color: "black",
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id]
                      .toLowerCase()
                      .includes(filter.value.toLowerCase()),
                  accessor: "name",
                },
              ],
            },
          ]}
          defaultSorted={[
            {
              id: "campaign.title",
              asc: true,
            },
          ]}
          className="-striped -highlight"
        />
      </div>
    );
  }
}
