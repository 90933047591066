import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Edits from './Edits';
import EditForm from './EditForm';
import EditView from './EditView';

export default (props) => {
  return (
    <Router>
      <div>
        <Route exact path="/cascopt/edits" render={routeProps => <Edits {...props} {...routeProps}/>} />
        <Route exact path="/cascopt/edits/:id" render={routeProps => <EditView {...props} {...routeProps}/>} />
        <Route path="/cascopt/edits/:id/edit" component={EditForm} />
      </div>
    </Router>
  )
}
