import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import update from "immutability-helper";

export default class FlightVendor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flight_vendor: props.flight_vendor,
      isHidden: true,
    };
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }

  static propTypes = {
    flight_vendor: PropTypes.object,
  };

  static defaultProps = {
    flight_vendor: {},
  };

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/flight_vendors/${this.props.match.params.id}`,
        dataType: "JSON",
      }).done((data) => {
        this.setState({ flight_vendor: data });
      });
    }
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <a
            href={`/flight_vendors`}
            title="Click to go back to main vendor dashboard"
            className="btn btn-sm btn-light"
          >
            Back to Vendor Dashboard
          </a>
          <hr />
          <h3 className="mb-4 text-4xl">{this.state.flight_vendor.company || ""}</h3>
          <Link to={`/flight_vendors/${this.state.flight_vendor.id}/edit`}>
            <button
              title="Click to edit this vendor"
              className="btn btn-sm btn-success"
            >
              Edit Vendor
            </button>
          </Link>
          <p />
          <form>
            <div className="row">
              <div className="col-md-3">
                <label>Contact</label>
                <p>{this.state.flight_vendor.contact || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Email</label>
                <p>{this.state.flight_vendor.email || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Website</label>
                <p>{this.state.flight_vendor.website || "n/a"}</p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Phone</label>
                <p>
                  {this.state.flight_vendor.phone || "n/a"} | Alt. Phone:{" "}
                  {this.state.flight_vendor.phone_alt || "None"}
                </p>
              </div>
              <div className="col-md-3">
                <label>Fax</label>
                <p>{this.state.flight_vendor.fax || "n/a"}</p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Address</label>
                <p>{this.state.flight_vendor.address || "n/a"}</p>
              </div>
              <div className="col-md-2">
                <label>City</label>
                <p>{this.state.flight_vendor.city || "n/a"}</p>
              </div>
              <div className="col-md-2">
                <label>State</label>
                <p>{this.state.flight_vendor.state || "n/a"}</p>
              </div>
              <div className="col-md-2">
                <label>Zip</label>
                <p>{this.state.flight_vendor.zip || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Country</label>
                <p>{this.state.flight_vendor.country || "n/a"}</p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Camera</label>
                <p>{this.state.flight_vendor.camera || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Digital Flights</label>
                <p>{this.state.flight_vendor.digital || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Film</label>
                <p>{this.state.flight_vendor.film || "n/a"}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <label>Coverage States</label>
                <p>{this.state.flight_vendor.coverage_states || "n/a"}</p>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
