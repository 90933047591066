import React from 'react';
import PropTypes from 'prop-types';
import { FormErrors } from './FormErrors';
import update from 'immutability-helper';
import { validations } from '../utils/validations';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

export default class QCForm extends React.Component {
  static propTypes = {
    handleNewQC: PropTypes.func
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      client_number: { value: '', valid: false },
      client_name: { value: '', valid: false },
      due_date: { value: '', valid: false },
      job_number: { value: '', valid: false },
      project_id: { value: '', valid: false },
      qc_staff: { value: '', valid: false },
      qc_date: { value: '', valid: true },
      stage: { value: '', valid: true },
      qc_done: '',
      imagery_qc: '',
      problem_comp: '',
      problem_edit: '',
      problem_img: '',
      edit_fix: '',
      imagery_fix: '',
      comp_fix: '',
      final_edit: '',
      final_img: '',
      final_delivery: '',
      re_qc: '',
      type: '',
      project_notes: { value: '', valid: true },
      qc_notes: { value: '', valid: true },
      formErrors: {},
      formValid: false,
      editing: false
    }
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
  }

  static formValidations = {
    project_id: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    qc_staff: [
      (s) => { return (validations.checkMinLength(s, 3)) }
    ],
    qc_date: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    project_notes: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    qc_notes: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ]
  }

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/cascopt/quality_controls/${this.props.match.params.id}`,
        dataType: "JSON"
      }).done((data) => {
        this.setState({
          client_number: { value: data.project.client_number, valid: true },
          client_name: { value: data.project.client_name, valid: true },
          job_number: { value: data.project.job_number, valid: true },
          due_date: { value: data.project.due_date, valid: true },
          stage: { value: data.project.stage, valid: true },
          project_id: { value: data.quality_control.project_id, valid: true },
          qc_staff: { value: data.quality_control.qc_staff, valid: true },
          qc_date: { value: data.quality_control.qc_date, valid: true },
          qc_done: data.quality_control.qc_done,
          imagery_qc: data.quality_control.imagery_qc,
          problem_comp: data.quality_control.problem_comp,
          problem_edit: data.quality_control.problem_edit,
          problem_img: data.quality_control.problem_img,
          edit_fix: data.quality_control.edit_fix,
          imagery_fix: data.quality_control.imagery_fix,
          comp_fix: data.quality_control.comp_fix,
          final_edit: data.quality_control.final_edit,
          final_img: data.quality_control.final_img,
          final_delivery: data.quality_control.final_delivery,
          re_qc: data.quality_control.re_qc,
          type: data.project.type,
          project_notes: { value: data.project.project_notes, valid: true },
          qc_notes: { value: data.project.qc_notes, valid: true },
          editing: this.props.match.path === '/cascopt/quality_controls/:id/edit'
        });
      });
    }
  }

  handleUserInput = (fieldName, fieldValue, validations) => {
    const newFieldState = update(this.state[fieldName],
      { value: { $set: fieldValue } });
    this.setState({ [fieldName]: newFieldState },
      () => { this.validateField(fieldName, fieldValue, validations) });
  }

  validateField(fieldName, fieldValue, validations) {
    let fieldValid;

    let fieldErrors = validations.reduce((errors, v) => {
      let e = v(fieldValue);
      if (e !== '') {
        errors.push(e);
      }
      return (errors);
    }, []);

    fieldValid = fieldErrors.length === 0;

    const newFieldState = update(this.state[fieldName],
      { valid: { $set: fieldValid } });

    const newFormErrors = update(this.state.formErrors,
      { $merge: { [fieldName]: fieldErrors } });

    this.setState({
      [fieldName]: newFieldState,
      formErrors: newFormErrors
    }, this.validateForm);
  }

  validateForm() {
    this.setState({
      formValid: this.state.project_id.valid &&
        this.state.qc_staff.valid &&
        this.state.qc_date.valid &&
        this.state.project_notes.valid &&
        this.state.qc_notes.valid
    });
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.state.editing ?
      this.updateQC() :
      this.addQC();
  }

  updateQC() {
    const quality_control = {
      project_id: this.state.project_id.value,
      qc_staff: this.state.qc_staff.value,
      qc_date: this.state.qc_date.value,
      qc_done: this.state.qc_done,
      imagery_qc: this.state.imagery_qc,
      problem_comp: this.state.problem_comp,
      problem_edit: this.state.problem_edit,
      problem_img: this.state.problem_img,
      edit_fix: this.state.edit_fix,
      imagery_fix: this.state.imagery_fix,
      comp_fix: this.state.comp_fix,
      final_edit: this.state.final_edit,
      final_img: this.state.final_img,
      final_delivery: this.state.final_delivery,
      re_qc: this.state.re_qc,
      project_notes: this.state.project_notes.value,
      qc_notes: this.state.qc_notes.value
    };
    $.ajax({
      type: "PATCH",
      url: `/cascopt/quality_controls/${this.props.match.params.id}`,
      data: { quality_control: quality_control }
    })
      .done((data) => {
        console.log('QC updated!');
        this.resetFormErrors();
        this.props.history.goBack('/cascopt/quality_controls');
      })
      .fail((response) => {
        alert("Please review the form for errors");
        this.setState({
          formErrors: response.responseJSON,
          formValid: false
        })
      });
  }

  addQC() {
    const quality_control = {
      project_id: this.state.project_id.value,
      qc_staff: this.state.qc_staff.value,
      qc_date: this.state.qc_date.value,
      qc_done: this.state.qc_done,
      imagery_qc: this.state.imagery_qc,
      problem_comp: this.state.problem_comp,
      problem_edit: this.state.problem_edit,
      problem_img: this.state.problem_img,
      edit_fix: this.state.edit_fix,
      imagery_fix: this.state.imagery_fix,
      comp_fix: this.state.comp_fix,
      final_edit: this.state.final_edit,
      final_img: this.state.final_img,
      final_delivery: this.state.final_delivery,
      re_qc: this.state.re_qc,
      project_notes: this.state.project_notes.value,
      qc_notes: this.state.qc_notes.value
    };
    $.post('/cascopt/quality_controls', { quality_control: quality_control })
      .done((data) => {
        this.props.handleNewQC(data);
        this.resetFormErrors();
      })
      .fail((response) => {
        this.setState({
          formErrors: response.responseJSON,
          formValid: false
        })
      });
  }

  deleteQC = () => {
    if (confirm("Are you sure you want to delete this Quality Control?")) {
      $.ajax({
        type: "DELETE",
        url: `/cascopt/quality_controls/${this.props.match.params.id}`,
      })
        .done((data) => {
          this.props.history.push('/cascopt/quality_controls');
          this.resetFormErrors();
        })
        .fail((response) => {
          console.log("QC deletion failed!");
        });
    }
  }

  resetFormErrors() {
    this.setState({ formErrors: {} })
  }

  handleChange(e) {
    this.handleUserInput(e.target.name, e.target.value, QCForm.formValidations[e.target.name]);
  }

  handleSelectChange(key) {
    return function ({ value }) {
      this.setState({ [key]: value });
    }.bind(this);
  }

  render() {
    return (
      <div className="container" id="form-card">
        <h4 className="mb-4 text-4xl">
          {this.state.editing ? "Update QC" : "Create QC"} for |
          {this.state.client_number.value} - {this.state.job_number.value}|
        </h4>
        <p>
          Client: {this.state.client_name.value}
          <br />
          Due: {moment(this.state.due_date.value).format("MM/DD/YYYY")}
        </p>
        <small>
          *For QC to be considered complete, both QC Done and Imagery QC need to
          be marked as <strong>Yes</strong>
          <br />
          (* represents a <strong>required field</strong>)
        </small>
        <FormErrors formErrors={this.state.formErrors} />
        <form onSubmit={this.handleFormSubmit}>
          <div className="row">
            <div className="col-md-3">
              <label>QC Staff *</label>
              <input
                name="qc_staff"
                placeholder="QC Staff"
                value={this.state.qc_staff.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col-md-3">
              <label>QC Done *</label>
              <Select
                name="qc_done"
                value={this.state.qc_done}
                onChange={this.handleSelectChange("qc_done")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
            <div className="col-md-3">
              <label>Imagery QC Done *</label>
              <Select
                name="imagery_qc"
                value={this.state.imagery_qc}
                onChange={this.handleSelectChange("imagery_qc")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
            {this.state.qc_done == "Yes" && this.state.imagery_qc == "Yes" && (
              <div className="col-md-3">
                <label>QC Date *</label>
                <input
                  type="date"
                  name="qc_date"
                  placeholder="QC Date"
                  value={this.state.qc_date.value}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            )}
          </div>
          <p />
          <div className="row">
            <div className="col-md-3">
              <label>Problem w/ Compilation *</label>
              <Select
                name="problem_comp"
                value={this.state.problem_comp}
                onChange={this.handleSelectChange("problem_comp")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
            <div className="col-md-3">
              <label>Problem w/ Editing *</label>
              <Select
                name="prob_edit"
                value={this.state.problem_edit}
                onChange={this.handleSelectChange("problem_edit")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
            <div className="col-md-3">
              <label>Problem w/ Imagery *</label>
              <Select
                name="prob_img"
                value={this.state.problem_img}
                onChange={this.handleSelectChange("problem_img")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
          </div>
          <p />
          <div>
            <h5>Post QC</h5>
            <small>
              * For the project to move to Delivery, the Final Edit and Final
              Image and Final Delivery fields need to be marked as 'Yes'
              <br />
              * If Edit Needs Fixed is marked as 'Yes', the Project will move to
              the 'Edit Post QC' stage
              <br />
              * If Imagery Needs Fixed is marked as 'Yes', the Project will move
              to the 'Image Post QC' stage
              <br />* If Compilation Needs Fixed is marked as 'Yes', the Project
              will move to the 'Comp Post QC' stage
            </small>
            <hr />
            <div className="row">
              <div className="col-md-3">
                <label>Edit Needs Fixed *</label>
                <Select
                  name="edit_fix"
                  value={this.state.edit_fix}
                  onChange={this.handleSelectChange("edit_fix")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
              <div className="col-md-3">
                <label>Imagery Needs Fixed *</label>
                <Select
                  name="imagery_fix"
                  value={this.state.imagery_fix}
                  onChange={this.handleSelectChange("imagery_fix")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
              <div className="col-md-3">
                <label>Compilation Needs Fixed *</label>
                <Select
                  name="comp_fix"
                  value={this.state.comp_fix}
                  onChange={this.handleSelectChange("comp_fix")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
              <div className="col-md-3">
                <label>Needs Re-QC *</label>
                <Select
                  name="re_qc"
                  value={this.state.re_qc}
                  onChange={this.handleSelectChange("re_qc")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Final Edit on ToArchive *</label>
                <Select
                  name="final_edit"
                  value={this.state.final_edit}
                  onChange={this.handleSelectChange("final_edit")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
              <div className="col-md-3">
                <label>Final Image on ToArchive *</label>
                <Select
                  name="final_img"
                  value={this.state.final_img}
                  onChange={this.handleSelectChange("final_img")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
              <div className="col-md-3">
                <label>Final Delivery QC Done *</label>
                <Select
                  name="final_delivery"
                  value={this.state.final_delivery}
                  onChange={this.handleSelectChange("final_delivery")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
            </div>
            <hr />
          </div>
          <p />
          <div className="row">
            <div className="col">
              <label>Notes *</label>
              <textarea
                name="qc_notes"
                placeholder="Notes"
                value={this.state.qc_notes.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col">
              <label>Project Notes</label>
              <textarea
                name="project_notes"
                placeholder="Project Notes"
                value={this.state.project_notes.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
          </div>
          <p />
          <input
            type="submit"
            value={this.state.editing ? "Update" : "Create"}
            className="btn btn-secondary btn-sm"
          />
          <a
            className="btn btn-danger btn-sm"
            onClick={this.props.history.goBack}
          >
            Cancel
          </a>
        </form>
        <br />
      </div>
    );
  }
}
