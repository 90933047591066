/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/prefer-stateless-function */
import React from "react";

export default class FlightVendors extends React.Component {
  render() {
    return (
      <div>
        <h3 className="mb-4 text-4xl">
          <i className="fa fa-plane"></i> Listing Flight Vendors
        </h3>
        <hr />
      </div>
    );
  }
}
