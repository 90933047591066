/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';

export default class Project extends React.Component {
  render() {
    return (
      <div>
        <h3 id="header">Projects</h3>
        <div className="dropdown">
          <button
            className="btn btn-sm btn-secondary dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            data-bs-toggle="dropdown"
          >
            Project Dashboard Options
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a href="/cascopt/interesting_projects" className="dropdown-item view-record">
              Interesting Projects
            </a>
            <a href="/cascopt/marketing_projects" className="dropdown-item view-record">
              Projects for Marketing
            </a>
            <a href="/cascopt/all_projects" className="dropdown-item view-record">
              All Projects
            </a>
            <a href="/cascopt/fly_and_hold_projects" className="dropdown-item view-record">
              Fly and Hold Projects
            </a>
            <a href="/cascopt/not_started" className="dropdown-item view-record">
              Projects Not Started
            </a>
            <a href="/cascopt/on_hold" className="dropdown-item view-record">
              Projects On Hold
            </a>
            <a href="/cascopt/cancelled_projects" className="dropdown-item view-record">
              Cancelled Projects
            </a>
            <a
              href="/cascopt/projects_no_signed_proposal"
              className="dropdown-item view-record"
            >
              Projects without Signed Proposal Attached
            </a>
            <a href="/cascopt/archived" className="dropdown-item view-record">
              Projects Completed
            </a>
          </div>
        </div>
      </div>
    );
  }
}
