import React, { PropTypes } from "react";

export const FormErrors = ({ formErrors }) => (
  <div>
    {Object.keys(formErrors).map((formErrorField) => {
      return formErrors[formErrorField].map((error) => {
        return (
          <p className="alert alert-danger">
            {formErrorField.replaceAll("_", " ")} {error}
          </p>
        );
      });
    })}
  </div>
);
