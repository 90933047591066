import React from 'react';
import PropTypes from 'prop-types';
import { FormErrors } from './FormErrors';
import update from 'immutability-helper';
import { validations } from '../utils/validations';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { withRouter } from 'react-router-dom';
import { Creatable } from 'react-select';
import moment from 'moment';

export default class ScanForm extends React.Component {
  static propTypes = {
    handleNewScan: PropTypes.func
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      problem_flight: '',
      which_scan: '',
      scans_deleted: '',
      abgps_received: { value: '', valid: false },
      job_name: { value: '', valid: false },
      client_number: { value: '', valid: false },
      job_number: { value: '', valid: false },
      due_date: { value: '', valid: false },
      client_name: { value: '', valid: false },
      project_location: { value: '', valid: false },
      flight_crew: { value: '', valid: false },
      color_film: { value: '', valid: false },
      flight_lines: { value: '', valid: false },
      exposures: { value: '', valid: false },
      stereo_prs: { value: '', valid: false },
      project_id: { value: '', valid: false },
      scan_staff: { value: '', valid: false },
      second_scan_staff: { value: '', valid: false },
      diap_clean: 'No',
      second_diap_clean: 'No',
      scan_qc: 'No',
      second_scan_qc: 'No',
      scan_res: { value: '', valid: true },
      second_scan_res: { value: '', valid: true },
      dig_equalized: 'No',
      second_dig_equalized: 'No',
      server_location: { value: 'Tuc-Jobs', valid: true },
      second_server_location: { value: 'Tuc-Jobs', valid: true },
      file_format: '',
      second_file_format: '',
      file_format_options: [
        { value: 'Tiff', label: 'Tiff' },
        { value: 'Tifftiled@128', label: 'Tifftiled@128' },
        { value: 'Tifftiled@256', label: 'Tifftiled@256' },
        { value: 'Tifftiled@512', label: 'Tifftiled@512' },
        { value: 'JPG', label: 'JPG' },
      ],
      camera_number: { value: '', valid: true },
      camera_number_options: [
        { value: 'UC-SXp-CI-10411033', label: 'UC-SXp-CI-10411033' },
        { value: '13232_16', label: '13232_16' },
        { value: '850364-Leica_CityMapper_1035', label: '850364-Leica_CityMapper_1035' },
        { value: 'UCX_70515399', label: 'UCX_70515399' },
        { value: '151966_16', label: '151966_16' },
        { value: '13331_08', label: '13331_08' },
        { value: 'DMCII-A-10_005', label: 'DMCII-A-10_005' },
        { value: '1913041_DMCII-A-10_005', label: '1913041_DMCII-A-10_005' },
        { value: 'DMCII230-015', label: 'DMCII230-015' },
        { value: 'DMCIIe230-23526', label: 'DMCIIe230-23526' },
        { value: 'DMCII-140-036_C', label: 'DMCII-140-036_C' },
        { value: 'DMCIIe230-D', label: 'DMCIIe230-D' },
        { value: '13408_12', label: '13408_12' },
        { value: 'DMCII_140-005', label: 'DMCII_140-005' },
        { value: 'DMCII-140-036', label: 'DMCII-140-036' },
        { value: 'UC-Lp-2-80618081', label: 'UC-Lp-2-80618081' },
        { value: 'UC-Lp-2-00318003', label: 'UC-Lp-2-00318003' },
        { value: '13417_16', label: '13417_16' },
        { value: '13367_16', label: '13367_16' },
        { value: 'UCX_70515399\\13331_08', label: 'UCX_70515399\\13331_08' },
        { value: '13331-08', label: '13331-08' },
        { value: 'DMCIII_27539', label: 'DMCIII_27539' },
        { value: '431S51176X010093', label: '431S51176X010093' },
        { value: '151999_16', label: '151999_16' },
        { value: 'Leica_CityMapper-1016', label: 'Leica_CityMapper-1016' },
        { value: 'RCD 30', label: 'RCD 30' },
        { value: 'UC-Fp-1-20519084-f100', label: 'UC-Fp-1-20519084-f100' },
        { value: '13362_16', label: '13362_16' },
        { value: '13401_11', label: '13401_11' },
        { value: 'UC-Fp-1-50616147-f100', label: 'UC-Fp-1-50616147-f100' },
        { value: 'UC-Fp-1-50811038-f100', label: 'UC-Fp-1-50811038-f100' },
        { value: '13432_15', label: '13432_15' },
        { value: 'UCF_20519084', label: 'UCF_20519084' },
        { value: 'UC-Fp-1-50616147', label: 'UC-Fp-1-50616147' },
        { value: 'UCE_1_00817310-f80', label: 'UCE_1_00817310-f80' },
        { value: 'UC-Fp-1-50811038', label: 'UC-Fp-1-50811038' },
        { value: 'DSL', label: 'DSL' },
        { value: 'Multi', label: 'Multi' },
        { value: 'RTC 360', label: 'RTC 360' },
        { value: '13374_12', label: '13374_12' },
        { value: 'I. F. Rooks', label: 'I. F. Rooks' },
        { value: 'DMCII230-017', label: 'DMCII230-017' },
        { value: 'DMCIII_27552', label: 'DMCIII_27552' },
        { value: 'DMCIII_27551', label: 'DMCIII_27551' },
        { value: '1519666_16', label: '1519666_16' },
        { value: 'Prime', label: 'Prime' },
        { value: '1913009_Q3_C DMCII_140-036', label: '1913009_Q3_C DMCII_140-036' },
        { value: '431S51176X010093-f100', label: '431S51176X010093-f100' },
        { value: '1913020-3_DMCII230-015', label: '1913020-3_DMCII230-015' },
        { value: '13364_15', label: '13364_15' },
        { value: '850364-Leica_CityMapper_95527', label: '850364-Leica_CityMapper_95527' },
        { value: '850364-Leica_CityMapper_95522', label: '850364-Leica_CityMapper_95522' },
        { value: '190064', label: '190064' },
        { value: 'Leica_CityMapper_95538', label: 'Leica_CityMapper_95538' },
        { value: '850364-Leica_CityMapper_95538', label: '850364-Leica_CityMapper_95538' },
        { value: 'DMCII_140-036', label: 'DMCII_140-036' },
        { value: 'DMC01-0137', label: 'DMC01-0137' },
        { value: '13232-16', label: '13232-16' },
        { value: 'DJI Phantom 4 Pro', label: 'DJI Phantom 4 Pro' },
        { value: '850364-Leica_CityMapper_1017', label: '850364-Leica_CityMapper_1017' },
        { value: 'UC-Lp-1-60318350', label: 'UC-Lp-1-60318350' },
        { value: '13387', label: '13387' },
        { value: '13387-14', label: '13387-14' },
        { value: 'UC-Lp-1-60318350_Rev07.00', label: 'UC-Lp-1-60318350_Rev07.00' },
        { value: '13411', label: '13411' },
        { value: 'UC-E-1-50319383-f80', label: 'UC-E-1-50319383-f80' },
        { value: 'UC-Fp-1-00610270-f100', label: 'UC-Fp-1-00610270-f100' },
        { value: 'DMCIII_27546', label: 'DMCIII_27546' },
        { value: '13411_13', label: '13411_13' },
        { value: 'DSLR', label: 'DSLR' },
        { value: 'UC-F-1-30710015-f100', label: 'UC-F-1-30710015-f100' },
        { value: 'UCE_50617043', label: 'UCE_50617043' },
        { value: 'UC-Fp-1-90513022-f100', label: 'UC-Fp-1-90513022-f100' },
        { value: 'UC-Fmk2-354S61176X211214-f100', label: 'UC-Fmk2-354S61176X211214-f100' },
        { value: '13374', label: '13374' },
        { value: 'ZI_DMC230_DS', label: 'ZI_DMC230_DS' },
        { value: 'RCD30_82088', label: 'RCD30_82088' },
        { value: 'Phase One IXU-RS-1000', label: 'Phase One IXU-RS-1000' },
        { value: 'Zeiss', label: 'Zeiss' },
        { value: '141296', label: '141296' },
        { value: '141296_16', label: '141296_16' },
        { value: 'iXM-150F-MM010184-RS-50mm', label: 'iXM-150F-MM010184-RS-50mm' },
        { value: '140-005-005', label: '140-005-005' },
        { value: '354S12202X913245-f100', label: '354S12202X913245-f100' },
        { value: 'UC-Fp-1-90513022-f1002-f100', label: 'UC-Fp-1-90513022-f1002-f100' },
        { value: 'UC-SXp-1-20415191', label: 'UC-SXp-1-20415191' },
        { value: 'Keystone', label: 'Keystone' },
        { value: '13389_09', label: '13389_09' },
        { value: '850364-Leica_CityMapper_95530', label: '850364-Leica_CityMapper_95530' },
        { value: '13389', label: '13389' },
        { value: 'DMCIII_27545', label: 'DMCIII_27545' },
        { value: 'UC-E-1-50319383', label: 'UC-E-1-50319383' },
        { value: '13115', label: '13115' },
        { value: 'UC-EpII-1-52617043-f100', label: 'UC-EpII-1-52617043-f100' },
        { value: '431S01298X310241-f100', label: '431S01298X310241-f100' },
        { value: 'UC-Lp-1-20610323', label: 'UC-Lp-1-20610323' },
        { value: '2103052_UC-Fp-1-00610270-f100', label: '2103052_UC-Fp-1-00610270-f100' },
        { value: 'UC-EpII-1-52617043', label: 'UC-EpII-1-52617043' },
        { value: 'UC-E-1-40012508-f80', label: 'UC-E-1-40012508-f80' },
        { value: '13389_15', label: '13389_15' },
        { value: '13328_03', label: '13328_03' },
        { value: '13248_15', label: '13248_15' },
        { value: 'DMCII230-015Y14', label: 'DMCII230-015Y14' },
        { value: 'UC-Fp-1-58011038-f100', label: 'UC-Fp-1-58011038-f100' },
        { value: '13248_15_180', label: '13248_15_180' },
        { value: 'YZ000039', label: 'YZ000039' },
        { value: 'iXM-150F-MM011078-RS', label: 'iXM-150F-MM011078-RS' },
        { value: 'UCFp_354S12202X913245-f100', label: 'UCFp_354S12202X913245-f100' },
        { value: 'UCFP-1-90513022', label: 'UCFP-1-90513022' },
        { value: '431S01298X310241', label: '431S01298X310241' },
        { value: 'UC-Fp-1-58011038', label: 'UC-Fp-1-58011038' },
        { value: 'iXM-RS150F', label: 'iXM-RS150F' },
        { value: 'UC-Fp-1-00610270', label: 'UC-Fp-1-00610270' },
      ],
      second_camera_number: { value: '', valid: true },
      roll_number: { value: '', valid: true },
      second_roll_number: { value: '', valid: true },
      scan_date_done: { value: '', valid: true },
      scan_complete: '',
      review_at: '',
      film_annotated: 'No',
      second_film_annotated: 'No',
      photo_quality: { value: '', valid: true },
      second_photo_quality: { value: '', valid: true },
      project_notes: { value: '', valid: true },
      scans_notes: { value: '', valid: true },
      formErrors: {},
      formValid: false,
      editing: false
    }
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
  }

  static formValidations = {
    project_id: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    scan_staff: [
      (s) => { return (validations.checkMinLength(s, 3)) }
    ],
    scan_res: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    server_location: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    roll_number: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    camera_number: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    second_scan_staff: [
      (s) => { return (validations.checkMinLength(s, 3)) }
    ],
    second_scan_res: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    second_server_location: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    second_camera_number: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    second_roll_number: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    scan_date_done: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    photo_quality: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    second_photo_quality: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    abgps_received: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    project_notes: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    scans_notes: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
  }

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/cascopt/scans/${this.props.match.params.id}`,
        dataType: "JSON"
      }).done((data) => {
        this.setState({
          imagery_lidar: data.flight.imagery_lidar,
          which_scan: data.scan.which_scan,
          second_flight: data.flight.second_flight,
          problem_flight: data.scan.problem_flight,
          scans_deleted: data.scan.scans_deleted,
          abgps_received: { value: data.scan.abgps_received, valid: true },
          job_name: { value: data.project.job_name, valid: true },
          client_number: { value: data.project.client_number, valid: true },
          job_number: { value: data.project.job_number, valid: true },
          due_date: { value: data.project.due_date, valid: true },
          client_name: { value: data.project.client_name, valid: true },
          project_location: { value: data.project.project_location, valid: true },
          flight_crew: { value: data.project.flight_crew, valid: true },
          color_film: { value: data.project.color_film, valid: true },
          flight_lines: { value: data.project.flight_lines, valid: true },
          exposures: { value: data.project.exposures, valid: true },
          stereo_prs: { value: data.project.stereo_prs, valid: true },
          project_id: { value: data.scan.project_id, valid: true },
          scan_staff: { value: data.scan.scan_staff, valid: true },
          second_scan_staff: { value: data.scan.second_scan_staff, valid: true },
          diap_clean: data.scan.diap_clean,
          scan_qc: data.scan.scan_qc,
          second_diap_clean: data.scan.second_diap_clean,
          second_scan_qc: data.scan.second_scan_qc,
          scan_res: { value: data.scan.scan_res, valid: true },
          dig_equalized: data.scan.dig_equalized,
          server_location: { value: data.scan.server_location, valid: true },
          file_format: data.scan.file_format,
          camera_number: { value: data.scan.camera_number, valid: true },
          roll_number: { value: data.scan.roll_number, valid: true },
          second_scan_res: { value: data.scan.second_scan_res, valid: true },
          second_dig_equalized: data.scan.second_dig_equalized,
          second_server_location: { value: data.scan.second_server_location, valid: true },
          second_file_format: data.scan.second_file_format,
          second_camera_number: { value: data.scan.second_camera_number, valid: true },
          second_roll_number: { value: data.scan.second_roll_number, valid: true },
          scan_date_done: { value: data.scan.scan_date_done, valid: true },
          scan_complete: data.scan.scan_complete,
          review_at: data.scan.review_at,
          film_annotated: data.scan.film_annotated,
          photo_quality: { value: data.scan.photo_quality, valid: true },
          second_film_annotated: data.scan.second_film_annotated,
          second_photo_quality: { value: data.scan.second_photo_quality, valid: true },
          project_notes: { value: data.project.project_notes, valid: true },
          scans_notes: { value: data.project.scans_notes, valid: true },
          editing: this.props.match.path === '/cascopt/scans/:id/edit'
        });
      });
    }
  }

  handleUserInput = (fieldName, fieldValue, validations) => {
    const newFieldState = update(this.state[fieldName],
      { value: { $set: fieldValue } });
    this.setState({ [fieldName]: newFieldState },
      () => { this.validateField(fieldName, fieldValue, validations) });
  }

  validateField(fieldName, fieldValue, validations) {
    let fieldValid;

    let fieldErrors = validations.reduce((errors, v) => {
      let e = v(fieldValue);
      if (e !== '') {
        errors.push(e);
      }
      return (errors);
    }, []);

    fieldValid = fieldErrors.length === 0;

    const newFieldState = update(this.state[fieldName],
      { valid: { $set: fieldValid } });

    const newFormErrors = update(this.state.formErrors,
      { $merge: { [fieldName]: fieldErrors } });

    this.setState({
      [fieldName]: newFieldState,
      formErrors: newFormErrors
    }, this.validateForm);
  }

  validateForm() {
    this.setState({
      formValid: this.state.project_id.valid &&
        this.state.scan_staff.valid &&
        this.state.scan_res.valid &&
        this.state.server_location.valid &&
        this.state.camera_number.valid &&
        this.state.roll_number.valid &&
        this.state.second_scan_staff.valid &&
        this.state.second_scan_res.valid &&
        this.state.second_server_location.valid &&
        this.state.second_roll_number.valid &&
        this.state.scan_date_done.valid &&
        this.state.photo_quality.valid &&
        this.state.second_camera_number.valid &&
        this.state.second_photo_quality.valid &&
        this.state.abgps_received.valid &&
        this.state.project_notes.valid &&
        this.state.scans_notes
    });
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.state.editing ?
      this.updateScan() :
      this.addScan();
  }

  updateScan() {
    const scan = {
      problem_flight: this.state.problem_flight,
      which_scan: this.state.which_scan,
      scans_deleted: this.state.scans_deleted,
      abgps_received: this.state.abgps_received.value,
      project_id: this.state.project_id.value,
      scan_staff: this.state.scan_staff.value,
      diap_clean: this.state.diap_clean,
      scan_qc: this.state.scan_qc,
      scan_res: this.state.scan_res.value,
      dig_equalized: this.state.dig_equalized,
      server_location: this.state.server_location.value,
      file_format: this.state.file_format,
      camera_number: this.state.camera_number.value,
      roll_number: this.state.roll_number.value,
      second_scan_staff: this.state.second_scan_staff.value,
      second_diap_clean: this.state.second_diap_clean,
      second_scan_qc: this.state.second_scan_qc,
      second_scan_res: this.state.second_scan_res.value,
      second_dig_equalized: this.state.second_dig_equalized,
      second_server_location: this.state.second_server_location.value,
      second_file_format: this.state.second_file_format,
      second_camera_number: this.state.second_camera_number.value,
      second_roll_number: this.state.second_roll_number.value,
      scan_date_done: this.state.scan_date_done.value,
      scan_complete: this.state.scan_complete,
      review_at: this.state.review_at,
      second_film_annotated: this.state.second_film_annotated,
      second_photo_quality: this.state.second_photo_quality.value,
      film_annotated: this.state.film_annotated,
      photo_quality: this.state.photo_quality.value,
      project_notes: this.state.project_notes.value,
      scans_notes: this.state.scans_notes.value
    };
    $.ajax({
      type: "PATCH",
      url: `/cascopt/scans/${this.props.match.params.id}`,
      data: { scan: scan }
    })
      .done((data) => {
        console.log('scan updated!');
        this.resetFormErrors();
        this.props.history.goBack('/cascopt/scans');
      })
      .fail((response) => {
        alert("Please review the form for errors");
        this.setState({
          formErrors: response.responseJSON,
          formValid: false
        })
      });
  }

  addScan() {
    const scan = {
      problem_flight: this.state.problem_flight,
      which_scan: this.state.which_scan,
      scans_deleted: this.state.scans_deleted,
      abgps_received: this.state.abgps_received.value,
      project_id: this.state.project_id.value,
      scan_staff: this.state.scan_staff.value,
      diap_clean: this.state.diap_clean,
      scan_qc: this.state.scan_qc,
      scan_res: this.state.scan_res.value,
      dig_equalized: this.state.dig_equalized,
      server_location: this.state.server_location.value,
      file_format: this.state.file_format,
      camera_number: this.state.camera_number.value,
      roll_number: this.state.roll_number.value,
      second_scan_staff: this.state.second_scan_staff.value,
      second_diap_clean: this.state.second_diap_clean,
      second_scan_qc: this.state.second_scan_qc,
      second_scan_res: this.state.second_scan_res.value,
      second_dig_equalized: this.state.second_dig_equalized,
      second_server_location: this.state.second_server_location.value,
      second_file_format: this.state.second_file_format,
      second_camera_number: this.state.second_camera_number.value,
      second_roll_number: this.state.second_roll_number.value,
      scan_date_done: this.state.scan_date_done.value,
      scan_complete: this.state.scan_complete,
      review_at: this.state.review_at,
      film_annotated: this.state.film_annotated,
      second_film_annotated: this.state.second_film_annotated,
      second_photo_quality: this.state.second_photo_quality.value,
      photo_quality: this.state.photo_quality.value,
      project_notes: this.state.project_notes.value,
      scans_notes: this.state.scans_notes.value
    };
    $.post('/cascopt/scans', { scan: scan })
      .done((data) => {
        this.props.handleNewScan(data);
        this.resetFormErrors();
      })
      .fail((response) => {
        this.setState({ formErrors: response.responseJSON, formValid: false })
      });
  }

  deleteScan = () => {
    if (confirm("Are you sure you want to delete this scan?")) {
      $.ajax({
        type: "DELETE",
        url: `/cascopt/scans/${this.props.match.params.id}`,
      })
        .done((data) => {
          this.props.history.push('/scans');
          this.resetFormErrors();
        })
        .fail((response) => {
          console.log("Scan deletion failed!");
        });
    }
  }

  resetFormErrors() {
    this.setState({ formErrors: {} })
  }

  handleChange(e) {
    this.handleUserInput(e.target.name, e.target.value, ScanForm.formValidations[e.target.name]);
  }

  handleSelectChange(key) {
    return function ({ value }) {
      this.setState({ [key]: value });
    }.bind(this);
  }

  render() {
    return (
      <div className="container" id="form-card">
        <h4 className="mb-4 text-4xl">
          {this.state.editing ?
            'Update Scan ' :
            'Create Scan '}
          for {this.state.job_name.value || ''} | {this.state.client_number.value || ''}-{this.state.job_number.value || ''}
        </h4>
        <p>Due: {moment(this.state.due_date.value || '').format("MM/DD/YYYY")}</p>
        <p>Client: {this.state.client_name.value || ''}</p>
        <p>
          Project Location: {this.state.project_location.value || ''} | Flight Crew: {this.state.flight_crew.value || ''}
          | Color Film: {this.state.color_film.value || ''} | Flight Lines: {this.state.flight_lines.value || ''}
          | Exposures: {this.state.exposures.value || ''} | Stereo PRS: {this.state.stereo_prs.value || ''}
        </p>
        <small>
          (* represents a <strong>required field</strong>)
        </small>
        <FormErrors formErrors={this.state.formErrors} />
        <form onSubmit={this.handleFormSubmit} >
          <div className="row">
            <div className="col-md-3">
              <label>Scan to Use</label>
              <Select
                name="which_scan"
                value={this.state.which_scan}
                onChange={this.handleSelectChange("which_scan")}
                options={[
                  { value: 'Primary', label: 'Primary' },
                  { value: 'Secondary', label: 'Secondary' },
                ]}
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col">
              <label>Scan Staff *</label>
              <input name="scan_staff" placeholder="Scan Staff"
                value={this.state.scan_staff.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
            <div className="col">
              <label>Camera Number *</label>
              <input name="camera_number" placeholder="Camera Number"
                value={this.state.camera_number.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
            <div className="col">
              <label>Roll Number *</label>
              <input name="roll_number" placeholder="Roll Number"
                value={this.state.roll_number.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
            <div className="col">
              <label>Scan Resolution *</label>
              <input name="scan_res" placeholder="Scan Resolution"
                value={this.state.scan_res.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col-md-3">
              <label>Server Location *</label>
              <input name="server_location" placeholder="Server Location"
                value={this.state.server_location.value || ''}
                onChange={this.handleChange}
                className="form-control" />
            </div>
            <div className="col-md-3">
              <label>File Format *</label>
              <Select.Creatable
                allowCreate={true}
                name="file_format"
                value={this.state.file_format}
                onChange={this.handleSelectChange("file_format")}
                options={this.state.file_format_options}
              />
            </div>
            <div className="col-md-3">
              <label>Photo Quality *</label>
              <input name="photo_quality" placeholder="Photo Quality"
                value={this.state.photo_quality.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col">
              <label>Dig Equalized *</label>
              <Select
                name="dig_equalized"
                value={this.state.dig_equalized}
                onChange={this.handleSelectChange("dig_equalized")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
            <div className="col">
              <label>Scan QC *</label>
              <Select
                name="scan_qc"
                value={this.state.scan_qc}
                onChange={this.handleSelectChange("scan_qc")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
            <div className="col">
              <label>Review AT *</label>
              <Select
                name="review_at"
                value={this.state.review_at}
                onChange={this.handleSelectChange("review_at")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
            <div className="col">
              <label>Film Annotated *</label>
              <Select
                name="film_annotated"
                value={this.state.film_annotated}
                onChange={this.handleSelectChange("film_annotated")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
          </div>
          <p />
          <div className="row">
          <div className="col-md-3">
            <label>ABGPS Received *</label>
            <input type="date" name="abgps_received"
              value={this.state.abgps_received.value}
              onChange={this.handleChange}
              className="form-control" />
          </div>
            <div className="col-md-3">
              <label>Scan Complete *</label>
              <Select
                name="scan_complete"
                value={this.state.scan_complete}
                onChange={this.handleSelectChange("scan_complete")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
            {this.state.scan_complete == 'Yes' &&
            <div className="col-md-3">
              <label>Scan Date Done *</label>
              <input type="date" name="scan_date_done" placeholder="Scan Date Done"
                value={this.state.scan_date_done.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>}
          </div>
          <p />
          <div className="row">
            <div className="col-md-2">
              <label>Problem w/ Flight</label>
              <Select
                name="problem_flight"
                value={this.state.problem_flight}
                onChange={this.handleSelectChange("problem_flight")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
            <div className="col-md-2">
              <label>Scans Deleted *</label>
              <Select
                name="scans_deleted"
                value={this.state.scans_deleted}
                onChange={this.handleSelectChange("scans_deleted")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
          </div>
          <p />
          {this.state.second_flight == "Yes" && this.state.imagery_lidar == "Imagery" &&
            <div>
              <hr />
              <h5>Secondary Scanning Information (if project has 2 imagery flights)</h5>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Scan Staff</label>
                  <input name="second_scan_staff" placeholder="Scan Staff"
                    value={this.state.second_scan_staff.value}
                    onChange={this.handleChange}
                    className="form-control" />
                </div>
                <div className="col">
                  <label>Camera Number</label>
                  <input name="second_camera_number" placeholder="Camera Number"
                    value={this.state.second_camera_number.value}
                    onChange={this.handleChange}
                    className="form-control" />
                </div>
                <div className="col">
                  <label>Roll Number</label>
                  <input name="second_roll_number" placeholder="Roll Number"
                    value={this.state.second_roll_number.value}
                    onChange={this.handleChange}
                    className="form-control" />
                </div>
                <div className="col">
                  <label>Scan Resolution</label>
                  <input name="second_scan_res" placeholder="Scan Resolution"
                    value={this.state.second_scan_res.value}
                    onChange={this.handleChange}
                    className="form-control" />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Server Location</label>
                  <input name="second_server_location" placeholder="Server Location"
                    value={this.state.second_server_location.value || ''}
                    onChange={this.handleChange}
                    className="form-control" />
                </div>
                <div className="col-md-3">
                  <label>File Format</label>
                  <Select.Creatable
                    allowCreate={true}
                    name="second_file_format"
                    value={this.state.second_file_format}
                    onChange={this.handleSelectChange("second_file_format")}
                    options={this.state.file_format_options}
                  />
                </div>
                <div className="col-md-3">
                  <label>Photo Quality</label>
                  <input name="second_photo_quality" placeholder="Photo Quality"
                    value={this.state.second_photo_quality.value}
                    onChange={this.handleChange}
                    className="form-control" />
                </div>
              </div>
            </div>}
          <p />
          <div className="row">
            <div className="col">
              <label>Scan Notes *</label>
              <textarea name="scans_notes" placeholder="Scan Notes" rows="4" cols="40"
                value={this.state.scans_notes.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col">
              <label>Project Notes</label>
              <textarea name="project_notes" placeholder="Project Notes" rows="4" cols="40"
                value={this.state.project_notes.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
          </div>
          <p />
          <input type="submit"
            value={this.state.editing ? 'Update' : 'Create'} className="btn btn-secondary btn-sm"
          />
          <a className="btn btn-danger btn-sm" onClick={this.props.history.goBack}>Cancel</a>
        </form>
        <br />
      </div>
    )
  }
}
