import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Offices from './Offices';
import OfficeView from './OfficeView';

export default (props) => {
  return (
    <Router>
      <div>
        <Route exact path="/offices" render={routeProps => <Offices {...props} {...routeProps}/>} />
        <Route exact path="/offices/:id" render={routeProps => <OfficeView {...props} {...routeProps}/>} />
      </div>
    </Router>
  )
}
