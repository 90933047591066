/* eslint-disable react/prefer-stateless-function */
import React from 'react';

export default class Archive extends React.Component {
  render() {
    return (
      <div>
        <h3 id="header">Completed CascoHub Projects</h3>
        <a href="/cascopt/projects" className="view-record">
          Back to Projects
        </a>
        <br />
        <a href="/cascopt/all_projects" className="view-record">
          All Projects
        </a>
        <br />
        <a href="/cascopt/not_started" className="view-record">
          Projects Not Started
        </a>
        <br />
        <a href="/cascopt/on_hold" className="view-record">
          Projects On Hold
        </a>
        <br />
        <a href="/cascopt/cancelled_projects" className="view-record">
          Cancelled Projects
        </a>
        <hr />
      </div>
    );
  }
}
