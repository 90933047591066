/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/jsx-one-expression-per-line */
import React from "react";

export default class Metashape extends React.Component {
  render() {
    return (
      <div>
        <h3 id="header">Construction QA/QC</h3>
      </div>
    );
  }
}
