import React from 'react';
import PropTypes from 'prop-types';
import FlightVendorForm from './FlightVendorForm';
import update from 'immutability-helper';
import Header from './Header';
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";

export default class FlightVendors extends React.Component {
  static propTypes = {
    flight_vendors: PropTypes.array
  }

  static defaultProps = {
    flight_vendors: []
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      flight_vendors: this.props.flight_vendors,
      isHidden: true,
      isHiddenNotComplete: false,
      isLoading: false
    }
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden
    })
  }

  toggleHiddenNotComplete() {
    this.setState({
      isHiddenNotComplete: !this.state.isHiddenNotComplete
    })
  }

  componentDidMount() {
    if (this.props.match) {
      this.setState({ isLoading: true });
      $.ajax({
        type: "GET",
        url: "/flight_vendors",
        dataType: "JSON"
      }).done((data) => {
        this.setState({ flight_vendors: data, isLoading: false });
      })
    }
  }

  addNewFlightVendor = (flight_vendor) => {
    const flight_vendors = update(this.state.flight_vendors, { $push: [flight_vendor] });
    this.setState({
      flight_vendors: flight_vendors.sort(function (a, b) {
        return new Date(a.created_at) - new Date(b.created_at);
      })
    });
  }

  render() {
    const { flight_vendors, isLoading } = this.state;
    if (isLoading) {
      return <div className="loader"></div>;
    }

    return (
      <div>
        <div className="row">
          <div className="col">
            <Header />
          </div>
        </div>
        <button
          onClick={this.toggleHidden.bind(this)}
          title="Click to expand/hide create vendor form"
          className="btn btn-secondary btn-sm"
        >
          {this.state.isHidden ? (
            <span>
              Create Flight Vendor{" "}
              <i className="fa fa-plus-circle" aria-hidden="true"></i>
            </span>
          ) : (
            <span>
              Close Flight Vendor Form{" "}
              <i className="fa fa-minus-circle" aria-hidden="true"></i>
            </span>
          )}
        </button>
        <p />
        {!this.state.isHidden && (
          <FlightVendorForm handleNewFlightVendor={this.addNewFlightVendor} />
        )}
        <p />
        <div>***Use the search fields in the table below to filter flight vendors by company, contact, coverage states, etc.***</div>
        <p />
        <ReactTable
          key={flight_vendors.id}
          data={flight_vendors}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          defaultPageSize={100}
          style={{
            height: "800px",
            textAlign: "center",
          }}
          columns={[
            {
              Header: "Flight Vendors",
              foldable: true,
              columns: [
                {
                  Header: "Flight Vendor",
                  accessor: "company",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={(event) => onChange(event.target.value)}
                      value={filter ? filter.value : ""}
                      style={{
                        width: "100%",
                        color: "black",
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id]
                      .toLowerCase()
                      .startsWith(filter.value.toLowerCase()),
                  Cell: (props) => (
                    <a
                      href={`/flight_vendors/${props.original.id}`}
                      className="view-record"
                    >
                      {props.original.company}
                    </a>
                  ),
                },
                {
                  Header: "Contact",
                  accessor: "contact",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={(event) => onChange(event.target.value)}
                      value={filter ? filter.value : ""}
                      style={{
                        width: "100%",
                        color: "black",
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id]
                      .toLowerCase()
                      .startsWith(filter.value.toLowerCase()),
                  Cell: (props) => (
                    <a
                      href={`/flight_vendors/${props.original.id}`}
                      className="view-record"
                    >
                      {props.original.contact}
                    </a>
                  ),
                },
                {
                  Header: "Digital Flights",
                  accessor: "digital",
                  filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                      return true;
                    }
                    if (filter.value === "yes") {
                      return row[filter.id] == "Yes";
                    }
                    if (filter.value === "no") {
                      return row[filter.id] == "No";
                    }
                  },
                  Filter: ({ filter, onChange }) =>
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: "100%" }}
                      value={filter ? filter.value : "all"}
                    >
                      <option value="all">All</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                },
                {
                  Header: "Film Flights",
                  accessor: "film",
                  filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                      return true;
                    }
                    if (filter.value === "yes") {
                      return row[filter.id] == "Yes";
                    }
                    if (filter.value === "no") {
                      return row[filter.id] == "No";
                    }
                  },
                  Filter: ({ filter, onChange }) =>
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: "100%" }}
                      value={filter ? filter.value : "all"}
                    >
                      <option value="all">All</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                },
                {
                  Header: "Coverage States",
                  accessor: "coverage_states",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={(event) => onChange(event.target.value)}
                      value={filter ? filter.value : ""}
                      style={{
                        width: "100%",
                        color: "black",
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id]
                      .toLowerCase()
                      .includes(filter.value.toLowerCase()),
                  Cell: (props) => (
                    <a
                      href={`/flight_vendors/${props.original.id}`}
                      className="view-record"
                    >
                      {props.original.coverage_states}
                    </a>
                  ),
                },
                {
                  Header: "Modify",
                  filterable: false,
                  Cell: (props) => (
                    <a
                      href={`/flight_vendors/${props.original.id}/edit`}
                      className="view-record"
                    >
                      Edit
                    </a>
                  ),
                },
              ],
            },
          ]}
          defaultSorted={[
            {
              id: "company",
              asc: true,
            },
          ]}
          className="-striped -highlight"
        />
      </div>
    );
  }
}
