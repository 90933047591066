import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import 'react-select/dist/react-select.css';

export default class SpecificationForm extends React.Component {
  static propTypes = {
    handleNewSpecification: PropTypes.func
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      opportunity_id: '',
      mapping_only: '',
      image_only: '',
      map_scale: '',
      map_scale_options: [
        { value: '20', label: '20' },
        { value: '30', label: '30' },
        { value: '40', label: '40' },
        { value: '50', label: '50' },
        { value: '50-80', label: '50-80' },
        { value: '100', label: '100' },
        { value: '200', label: '200' },
        { value: '200-400', label: '200-400' },
        { value: '400', label: '400' },
      ],
      gsd: '',
      ci: '',
      ci_options: [
        { value: '0.5', label: '0.5' },
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '5-10', label: '5-10' },
        { value: '10', label: '10' },
      ],
      models: '',
      map_type: '',
      file_format: '',
      version: '',
      flight_lines: '',
      exposures: '',
      notes: '',
      map_prs: '',
      stereo_prs_options: [
        { value: '1:2000', label: '1:2000' },
        { value: '1:3600', label: '1:3600' },
        { value: '1:7200', label: '1:7200' },
        { value: '1:9600', label: '1:9600' },
        { value: '1:12000', label: '1:12000' },
        { value: '1:14400', label: '1:44400' },
        { value: '1:24000', label: '1:24000' },
        { value: '1:2400', label: '1:2400' },
        { value: '1:2800', label: '1:2800' },
        { value: '1:3000', label: '1:3000' },
        { value: '1:3300', label: '1:3300' },
        { value: '1:3340', label: '1:3340' },
        { value: '1:3360', label: '1:3360' },
        { value: '1:3400', label: '1:3400' },
        { value: '1:4000', label: '1:4000' },
        { value: '1:4800', label: '1:4800' },
        { value: '1:5000', label: '1:5000' },
        { value: '1:5400', label: '1:5400' },
        { value: '1:6000', label: '1:6000' },
        { value: '1:6400', label: '1:6400' },
        { value: '1:6600', label: '1:6600' },
        { value: '1:8000', label: '1:8000' },
        { value: '1:10000', label: '1:10000' },
        { value: '1:13000', label: '1:13000' },
        { value: '1:14000', label: '1:14000' },
        { value: '1:16000', label: '1:16000' },
        { value: '1:18000', label: '1:18000' },
        { value: '1:20000', label: '1:20000' },
        { value: '1:28000', label: '1:28000' },
      ],
      film_type: '',
      units: '',
      diff_img_flight: '',
      ppsm: '',
      acreage: '',
      square_miles: '',
      bare_earth_only: '',
      detail_level: '',
      quantity: '',
      laminated: '',
      size_y: '',
      size_x: '',
      spec_type: '',
      frame: '',
      logo: '',
      frame_color: '',
      classification: '',
      mounted: '',
      plot_options: '',
      scan_res: '',
      number_scans: '',
      deliverable: '',
      location: '',
      staff: '',
      editing: false,
    }
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if(this.props.match) {
    $.ajax({
      type: "GET",
      url: `/specifications/${this.props.match.params.id}`,
      dataType: "JSON"
    }).done((data) => {
      this.setState({
        opportunity_id: data.specification.opportunity_id,
        mapping_only: data.specification.mapping_only,
        image_only: data.specification.image_only,
        map_scale: data.specification.map_scale,
        gsd: data.specification.gsd,
        ci: data.specification.ci,
        models: data.specification.models,
        map_type: data.specification.map_type,
        file_format: data.specification.file_format,
        version: data.specification.version,
        flight_lines: data.specification.flight_lines,
        exposures: data.specification.exposures,
        notes: data.specification.notes,
        map_prs: data.specification.map_prs,
        film_type: data.specification.film_type,
        units: data.specification.units,
        diff_img_flight: data.specification.diff_img_flight,
        ppsm: data.specification.ppsm,
        acreage: data.specification.acreage,
        square_miles: data.specification.square_miles,
        bare_earth_only: data.specification.bare_earth_only,
        detail_level: data.specification.detail_level,
        quantity: data.specification.quantity,
        laminated: data.specification.laminated,
        size_y: data.specification.size_y,
        size_x: data.specification.size_x,
        spec_type: data.specification.spec_type,
        frame: data.specification.frame,
        logo: data.specification.logo,
        frame_color: data.specification.frame_color,
        classification: data.specification.classification,
        mounted: data.specification.mounted,
        plot_options: data.specification.plot_options,
        scan_res: data.specification.scan_res,
        number_scans: data.specification.number_scans,
        deliverable: data.specification.deliverable,
        location: data.specification.location,
        staff: data.specification.staff,
        editing: this.props.match.path === '/specifications/:id/edit'
      });
    });
   }
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.state.editing ?
      this.updateSpecification() :
      this.addSpecification();
  }

  updateSpecification() {
    const specification = {
      opportunity_id: this.state.opportunity_id,
      mapping_only: this.state.mapping_only,
      image_only: this.state.image_only,
      map_scale: this.state.map_scale,
      gsd: this.state.gsd,
      ci: this.state.ci,
      models: this.state.models,
      map_type: this.state.map_type,
      file_format: this.state.file_format,
      version: this.state.version,
      flight_lines: this.state.flight_lines,
      exposures: this.state.exposures,
      notes: this.state.notes,
      map_prs: this.state.map_prs,
      film_type: this.state.film_type,
      units: this.state.units,
      diff_img_flight: this.state.diff_img_flight,
      ppsm: this.state.ppsm,
      acreage: this.state.acreage,
      square_miles: this.state.square_miles,
      bare_earth_only: this.state.bare_earth_only,
      detail_level: this.state.detail_level,
      quantity: this.state.quantity,
      laminated: this.state.laminated,
      size_y: this.state.size_y,
      size_x: this.state.size_x,
      spec_type: this.state.spec_type,
      frame: this.state.frame,
      logo: this.state.logo,
      frame_color: this.state.frame_color,
      classification: this.state.classification,
      mounted: this.state.mounted,
      plot_options: this.state.plot_options,
      scan_res: this.state.scan_res,
      number_scans: this.state.number_scans,
      deliverable: this.state.deliverable,
      location: this.state.location,
      staff: this.state.staff
    };
    $.ajax({
      type: "PATCH",
      url: `/specifications/${this.props.match.params.id}`,
      data: { specification: specification }
    })
      .done((data) => {
        console.log('Specification updated!');
      })
      .fail((response) => {
        console.log("did not work");
      });
  }

  addSpecification() {
    const specification = {
      opportunity_id: this.state.opportunity_id,
      mapping_only: this.state.mapping_only,
      image_only: this.state.image_only,
      map_scale: this.state.map_scale,
      gsd: this.state.gsd,
      ci: this.state.ci,
      models: this.state.models,
      map_type: this.state.map_type,
      file_format: this.state.file_format,
      version: this.state.version,
      flight_lines: this.state.flight_lines,
      exposures: this.state.exposures,
      notes: this.state.notes,
      map_prs: this.state.map_prs,
      film_type: this.state.film_type,
      units: this.state.units,
      diff_img_flight: this.state.diff_img_flight,
      ppsm: this.state.ppsm,
      acreage: this.state.acreage,
      square_miles: this.state.square_miles,
      bare_earth_only: this.state.bare_earth_only,
      detail_level: this.state.detail_level,
      quantity: this.state.quantity,
      laminated: this.state.laminated,
      size_y: this.state.size_y,
      size_x: this.state.size_x,
      spec_type: this.state.spec_type,
      frame: this.state.frame,
      logo: this.state.logo,
      frame_color: this.state.frame_color,
      classification: this.state.classification,
      mounted: this.state.mounted,
      plot_options: this.state.plot_options,
      scan_res: this.state.scan_res,
      number_scans: this.state.number_scans,
      deliverable: this.state.deliverable,
      location: this.state.location,
      staff: this.state.staff
    };
    $.post('/specifications', { specification: specification })
      .done((data) => {
        this.props.handleNewSpecification(data);
        console.log("Specification Successfully Created!");
      })
      .fail((response) => {
        console.log("did not work");
      });
  }

  deleteSpecification = () => {
    if (confirm("Are you sure you want to delete this specification?")) {
      $.ajax({
        type: "DELETE",
        url: `/specifications/${this.props.match.params.id}`,
      })
        .done((data) => {
          this.props.history.push('/specifications');
        })
        .fail((response) => {
          console.log("Specification deletion failed!");
        });
    }
  }

  handleChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  handleSelectChange(key) {
    return function ({ value }) {
      this.setState({ [key]: value });
    }.bind(this);
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <h4 className="mb-4 text-4xl">
            {this.state.editing ?
              'Update Specification' :
              'Create Specification'}
          </h4>
          <small>
            (* represents a <strong>required field</strong>)
          </small>
          <form onSubmit={this.handleFormSubmit} >
            <div className="row">
              <div className="col-md-3">
                <label>Opportunity ID *</label>
                <input type="text" name="opportunity_id" placeholder="Opp ID"
                  value={this.state.opportunity_id}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-3">
                <label>Spec Type *</label>
                <Select
                name="spec_type"
                value={this.state.spec_type}
                onChange={this.handleSelectChange("spec_type")}
                options={[
                  { value: 'Film', label: 'Film' },
                  { value: 'Digital', label: 'Digital' },
                  { value: 'LiDAR', label: 'LiDAR' },
                  { value: 'Scan', label: 'Scan' },
                  { value: 'Plot', label: 'Plot' },
                  { value: 'Survey', label: 'Survey' },
                  { value: 'Other', label: 'Other' },
                ]}
              />
              </div>
              <div className="col-md-3">
                <label>Mapping Only *</label>
                <Select
                name="mapping_only"
                value={this.state.mapping_only}
                onChange={this.handleSelectChange("mapping_only")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
              </div>
              <div className="col-md-3">
                <label>Image Only *</label>
                <Select
                name="image_only"
                value={this.state.image_only}
                onChange={this.handleSelectChange("image_only")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Map Scale *</label>
                <Select.Creatable
                  allowCreate={true}
                  name="map_scale"
                  value={this.state.map_scale}
                  onChange={this.handleSelectChange("map_scale")}
                  options={this.state.map_scale_options}
                />
              </div>
              <div className="col-md-3">
                <label>GSD *</label>
                <input type="text" name="gsd" placeholder="GSD"
                  value={this.state.GSD}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-3">
                <label>CI *</label>
                <Select.Creatable
                  allowCreate={true}
                  name="ci"
                  value={this.state.ci}
                  onChange={this.handleSelectChange("ci")}
                  options={this.state.ci_options}
                />
              </div>
              <div className="col-md-3">
                <label>Models *</label>
                <input type="text" name="models" placeholder="Models"
                  value={this.state.models}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Map Type *</label>
                <Select
                  name="map_type"
                  value={this.state.map_type}
                  onChange={this.handleSelectChange("map_type")}
                  options={[
                    { value: 'DTM, Contours & Plan', label: 'DTM, Contours & Plan' },
                    { value: 'DTM & Contours', label: 'DTM & Contours' },
                    { value: 'Plan Only', label: 'Plan Only' },
                  ]}
              />
              </div>
              <div className="col-md-3">
                <label>File Format *</label>
                <Select
                  name="file_format"
                  value={this.state.file_format}
                  onChange={this.handleSelectChange("file_format")}
                  options={[
                    { value: 'AutoCAD', label: 'AutoCAD' },
                    { value: 'Microstation', label: 'Microstation' },
                    { value: 'ESRI Shape', label: 'ESRI Shape' },
                  ]}
              />
              </div>
              <div className="col-md-3">
                <label>Version *</label>
                <input type="text" name="version" placeholder="Version"
                  value={this.state.version}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-3">
                <label>Flight Lines *</label>
                <input type="text" name="flight_lines" placeholder="Flight Lines"
                  value={this.state.flight_lines}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Exposures *</label>
                <input type="text" name="exposures" placeholder="Exposures"
                  value={this.state.exposures}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-3">
                <label>Map PRS *</label>
                <Select.Creatable
                  allowCreate={true}
                  name="map_prs"
                  value={this.state.map_prs}
                  onChange={this.handleSelectChange("map_prs")}
                  options={this.state.stereo_prs_options}
                />
              </div>
              <div className="col-md-3">
                <label>Film Type *</label>
                <Select
                  name="film_type"
                  value={this.state.film_type}
                  onChange={this.handleSelectChange("film_type")}
                  options={[
                    { value: 'B/W', label: 'B/W' },
                    { value: 'Color', label: 'Color' },
                  ]}
                />
              </div>
              <div className="col-md-3">
                <label>Units *</label>
                <Select
                  name="units"
                  value={this.state.units}
                  onChange={this.handleSelectChange("units")}
                  options={[
                    { value: 'Ft', label: 'Ft' },
                    { value: 'M', label: 'M' },
                  ]}
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Diff. Img. Flight *</label>
                <Select
                  name="diff_img_flight"
                  value={this.state.diff_img_flight}
                  onChange={this.handleSelectChange("diff_img_flight")}
                  options={[
                    { value: 'No', label: 'No' },
                    { value: 'Yes', label: 'Yes' },
                  ]}
                />
              </div>
              <div className="col-md-3">
                <label>PPSM *</label>
                <input type="text" name="ppsm" placeholder="PPSM"
                  value={this.state.ppsm}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-3">
                <label>Acreage *</label>
                <input type="text" name="acreage" placeholder="Acreage"
                  value={this.state.acreage}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-3">
                <label>Square Miles *</label>
                <input type="text" name="square_miles" placeholder="Square Miles"
                  value={this.state.square_miles}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Bare Earth Only *</label>
                <Select
                  name="bare_earth_only"
                  value={this.state.bare_earth_only}
                  onChange={this.handleSelectChange("bare_earth_only")}
                  options={[
                    { value: 'No', label: 'No' },
                    { value: 'Yes', label: 'Yes' },
                  ]}
                />
              </div>
              <div className="col-md-3">
                <label>Detail Level *</label>
                <input type="text" name="detail_level" placeholder="Detail Level"
                  value={this.state.detail_level}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-3">
                <label>Quantity *</label>
                <input type="text" name="quantity" placeholder="Quantity"
                  value={this.state.quantity}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-3">
                <label>Laminated *</label>
                <Select
                  name="laminated"
                  value={this.state.laminated}
                  onChange={this.handleSelectChange("laminated")}
                  options={[
                    { value: 'No', label: 'No' },
                    { value: 'Yes', label: 'Yes' },
                  ]}
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-1">
                <label>Size X *</label>
                <input type="text" name="size_x" placeholder="Size X"
                  value={this.state.size_x}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-1">
                <label>Size Y *</label>
                <input type="text" name="size_y" placeholder="Size Y"
                  value={this.state.size_y}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-3">
                <label>Frame *</label>
                <Select
                  name="frame"
                  value={this.state.frame}
                  onChange={this.handleSelectChange("frame")}
                  options={[
                    { value: 'Metal', label: 'Metal' },
                    { value: 'Plastic', label: 'Plastic' },
                    { value: 'None', label: 'None' },
                  ]}
                />
              </div>
              <div className="col-md-3">
                <label>Logo *</label>
                <input type="text" name="logo" placeholder="Logo"
                  value={this.state.logo}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-3">
                <label>Frame Color *</label>
                <input type="text" name="frame_color" placeholder="Frame Color"
                  value={this.state.frame_color}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Classification *</label>
                <input type="text" name="classification" placeholder="Classification"
                  value={this.state.classification}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-3">
                <label>Mounted *</label>
                <Select
                  name="mounted"
                  value={this.state.mounted}
                  onChange={this.handleSelectChange("mounted")}
                  options={[
                    { value: '1/2', label: '1/2' },
                    { value: '1/4', label: '1/4' },
                  ]}
                />
              </div>
              <div className="col-md-3">
                <label>Plot Options *</label>
                <Select
                  name="plot_options"
                  value={this.state.plot_options}
                  onChange={this.handleSelectChange("plot_options")}
                  options={[
                    { value: 'Dry Erase', label: 'Dry Erase' },
                    { value: 'UV Protection', label: 'UV Protection' },
                  ]}
                />
              </div>
              <div className="col-md-3">
                <label>Scan Res *</label>
                <input type="text" name="scan_res" placeholder="Scan Res"
                  value={this.state.scan_res}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Number of Scans *</label>
                <input type="text" name="number_scans" placeholder="Number of Scans"
                  value={this.state.number_scans}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-3">
                <label>Deliverable *</label>
                <input type="text" name="deliverable" placeholder="Deliverable"
                  value={this.state.deliverable}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-3">
                <label>Location *</label>
                <input type="text" name="location" placeholder="Location"
                  value={this.state.location}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
              <div className="col-md-3">
                <label>Staff *</label>
                <input type="text" name="staff" placeholder="Staff"
                  value={this.state.staff}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Notes</label>
                <textarea name="notes" placeholder="Notes"
                  value={this.state.notes}
                  onChange={this.handleChange}
                  className="form-control" />
              </div>
            </div>
            <p />
            <input type="submit"
              value={this.state.editing ? 'Update' : 'Create'} className="btn btn-secondary btn-sm"
            />
            <hr />
          </form>
          <br />
        </div>
      </div>
    )
  }

}
