import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Opportunities from './Opportunities';
import OpportunityForm from './OpportunityForm';
import OpportunityView from './OpportunityView';

export default (props) => {
  return (
    <Router>
      <div>
        <Route exact path="/opportunities" render={routeProps => <Opportunities {...props} {...routeProps}/>} />
        <Route exact path="/opportunities/:id" render={routeProps => <OpportunityView {...props} {...routeProps}/>} />
        <Route path="/opportunities/:id/edit" render={routeProps => <OpportunityForm {...props} {...routeProps}/>} />
      </div>
    </Router>
  )
}
