import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

export default class Proposal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      proposal: props.proposal,
      quote: props.quote,
      specification: props.specification,
      opportunity: props.opportunity,
      contact: props.contact,
      office: props.office,
      company: props.company,
      user: props.user,
      additionalPlots: props.plots ? Object.keys(props.plots).map(key => props.plots[key]) : [],
    }
  }

  static propTypes = {
    proposal: PropTypes.object
  }

  static defaultProps = {
    proposal: {}
  }

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/proposals/${this.props.match.params.id}`,
        dataType: "JSON"
      }).done((data) => {
        this.setState({ data });
      })
    }
  }

  render() {

    const { additionalPlots } = this.state;

    var additionalPlotCost = 100;

    // if (this.state.proposal.project_manager == "Philip Gershkovich") {
    //   this.state.user.title = "President";
    //   this.state.user.extension = "602.678.5111 ext 289";
    //   this.state.user.email = "phil@cooperaerial.com";
    // } else if (this.state.proposal.project_manager == "Emily Martin") {
    //   this.state.user.title = "Project Manager";
    //   this.state.user.extension = "602.678.5111 ext 215";
    //   this.state.user.email = "emily@cooperaerial.com";
    // } else if (this.state.proposal.project_manager == "Zachary Radel") {
    //   this.state.user.title = "Sr. Project Manager";
    //   this.state.user.extension = "602.678.5111 ext 222";
    //   this.state.user.email = "zachary@cooperaerial.com";
    // } else if (this.state.proposal.project_manager == "Dave Major") {
    //   this.state.user.title = "VP Operations - Tucson";
    //   this.state.user.extension = "602.678.5111 ext 102";
    //   this.state.user.email = "dave@cooperaerial.com";
    // } else if (this.state.proposal.project_manager == "Pete Priestner") {
    //   this.state.user.title = "Business Development Manager";
    //   this.state.user.extension = "862-268-0978";
    //   this.state.user.email = "pete@cooperaerial.com";
    // } else if (this.state.proposal.project_manager == "Kimberly Edwards") {
    //   this.state.user.title = "Project Manager";
    //   this.state.user.extension = "602.678.5111 ext 230";
    //   this.state.user.email = "kedwards@cooperaerial.com";
    // } else if (this.state.proposal.project_manager == "Jim Crume") {
    //   this.state.user.title = "Surveying Project Manager";
    //   this.state.user.extension = "602.678.5111 ext 224";
    //   this.state.user.email = "jcrume@cooperaerial.com";
    // } else if (this.state.proposal.project_manager == "Sandy Little") {
    //   this.state.user.title = "Project Manager";
    //   this.state.user.extension = "814.345.1167";
    //   this.state.user.email = "slittle@noreastmapping.com";
    // }

    if (this.state.proposal.asst_pm == "Philip Gershkovich") {
      this.state.user.title = "President";
      this.state.user.extension = "602.678.5111 ext 289";
      this.state.user.email = "phil@cooperaerial.com";
    } else if (this.state.proposal.asst_pm == "Emily Martin") {
      this.state.user.title = "Project Manager";
      this.state.user.extension = "602.678.5111 ext 215";
      this.state.user.email = "emily@cooperaerial.com";
    } else if (this.state.proposal.asst_pm == "Zachary Radel") {
      this.state.user.title = "Sr. Project Manager";
      this.state.user.extension = "602.678.5111 ext 222";
      this.state.user.email = "zachary@cooperaerial.com";
    } else if (this.state.proposal.asst_pm == "Dave Major") {
      this.state.user.title = "VP Operations - Tucson";
      this.state.user.extension = "602.678.5111 ext 102";
      this.state.user.email = "dave@cooperaerial.com";
    } else if (this.state.proposal.asst_pm == "Pete Priestner") {
      this.state.user.title = "Business Development Manager";
      this.state.user.extension = "862-268-0978";
      this.state.user.email = "pete@cooperaerial.com";
    } else if (this.state.proposal.asst_pm == "Kimberly Edwards") {
      this.state.user.title = "Project Manager";
      this.state.user.extension = "602.678.5111 ext 230";
      this.state.user.email = "kedwards@cooperaerial.com";
    } else if (this.state.proposal.asst_pm == "Jim Crume") {
      this.state.user.title = "Surveying Project Manager";
      this.state.user.extension = "602.678.5111 ext 224";
      this.state.user.email = "jcrume@cooperaerial.com";
    } else if (this.state.proposal.asst_pm == "Sandy Little") {
      this.state.user.title = "Project Manager";
      this.state.user.extension = "814.345.1167";
      this.state.user.email = "slittle@noreastmapping.com";
    } else if (this.state.proposal.asst_pm == "Zachary Bearley") {
      this.state.user.title = "Project Manager";
      this.state.user.extension = "602.678.5111 ext 221";
      this.state.user.email = "zbearley@cooperaerial.com";
    } else if (this.state.proposal.asst_pm == "Dennis Harmon") {
      this.state.user.title = "Survey Project Manager";
      this.state.user.extension = "253.344.9864";
      this.state.user.email = "dennis@cooperaerial.com";
    }

    if (this.state.quote.client_survey == "No") {
      this.state.quote.survey_sub = "Cooper Aerial Surveys";
    } else if (this.state.quote.client_survey == "Yes") {
      this.state.quote.survey_sub = "Client";
    } else {
      this.state.quote.survey_sub = this.state.quote.survey_sub;
    }

    if (this.state.specification.map_prs > 0) {
      if (this.state.proposal.proposal_type == "Film" || this.state.proposal.proposal_type == "ADOT") {
        const re = /\d:(\d+)/;
        var pixRes = parseFloat(this.state.specification.map_prs.match(re)[1] / 2450 / 12).toFixed(2);
      }
    }

    {/* lidar level of detail pricing */ }
    var detailPrice = parseInt(this.state.quote.detail_level);
    var processHours = parseInt(this.state.quote.lidar_hours);
    var processTotal = parseInt(detailPrice) * parseInt(processHours);
    var processPrice = processTotal;

    if (this.state.proposal.proposal_type != "LiDAR") {
      var lidarTotal = parseInt(this.state.quote.lidar_flight_cost + processPrice);
    } else {
      var lidarTotal = parseInt(this.state.quote.lidar_flight_cost);
    }

    { /* survey services cost */ }
      var rlsTotal = parseInt(this.state.quote.rls_cp) * parseFloat(this.state.quote.rls_hrs);
      var spcTotal = parseInt(this.state.quote.spc_cp) * parseFloat(this.state.quote.spc_hrs);
      var stTotal = parseInt(this.state.quote.st_cp) * parseFloat(this.state.quote.st_hrs);
      var sc2Total = parseInt(this.state.quote.sc2_cp) * parseFloat(this.state.quote.sc2_hrs);
      var sc3Total = parseInt(this.state.quote.sc3_cp) * parseFloat(this.state.quote.sc3_hrs);
      var rwTotal = parseInt(this.state.quote.rw_plans_cp) * parseFloat(this.state.quote.rw_plans_hrs);
      var servicesTotal = rlsTotal + spcTotal + stTotal + sc2Total + sc3Total + rwTotal;

    { /* PM fee */ }
    if (this.state.proposal.proposal_type == "Survey") {
      var pmFee = 0;
    } else if (this.state.proposal.proposal_type == "Other") {
      var pmFee = 0;
    } else if (this.state.proposal.proposal_type == "NAAS") {
      var pmFee = 0;
    } else {
      var pmFee = 250;
    };

    // data drive cost
    if (this.state.quote.fuel_charge == "0") {
      var dataDrive = 0;
    } else if (this.state.quote.fuel_charge == "1") {
      var dataDrive = "40GB";
    } else if (this.state.quote.fuel_charge == "2") {
      var dataDrive = "80GB";
    } else if (this.state.quote.fuel_charge == "3") {
      var dataDrive = "120GB";
    } else if (this.state.quote.fuel_charge == "4") {
      var dataDrive = "1TB";
    } else if (this.state.quote.fuel_charge == "5") {
      var dataDrive = "2TB";
    };

    return (
      <div id="target" className="card">
        <div className="card-body">
          {/*<button className="btn btn-secondary btn-sm" id="print-button" onClick={this.printPDF}>To PDF</button>*/}
          <h4 className="mb-4 text-4xl">
            {this.state.opportunity.name} Proposal for {this.state.company.name}
            <br />
            {this.state.proposal.proposal_number}
          </h4>
          <div className="row">
            <div className="col">
              <span id="cost-proposal">PROJECT MANAGER</span>
              <br />
              <p>{this.state.proposal.asst_pm}</p>
              <br />
              <span id="cost-proposal">CLIENT REP</span>
              <br />
              <p>{this.state.proposal.project_manager}</p>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-9 text-left">
              <span id="cost-proposal">CLIENT INFORMATION</span>
              <br />
              <p>
                {this.state.contact.f_name} {this.state.contact.l_name}
                <br />
                {this.state.company.name}-{this.state.office.client_number}
                <br />
                {this.state.office.address}, {this.state.office.city},{" "}
                {this.state.office.state} {this.state.office.zip}
                <br />
                {this.state.contact.phone.replace(
                  /(\d{3})(\d{3})(\d{4})/,
                  "($1) $2-$3"
                )}
                <br />
                {this.state.contact.email}
              </p>
            </div>
            {this.state.proposal.use_alt_info == true && (
              <div className="col-md-9 text-left">
                <span id="cost-proposal">ALT. CLIENT INFORMATION</span>
                <br />
                <small>
                  * The information below will appear on the PDF version of the
                  Proposal *
                </small>
                <br />
                <p>
                  {this.state.proposal.alt_client}
                  <br />
                  {this.state.proposal.alt_company}
                  <br />
                  {this.state.proposal.alt_address},{" "}
                  {this.state.proposal.alt_city},{" "}
                  {this.state.proposal.alt_state} {this.state.proposal.alt_zip}
                  <br />
                  {this.state.proposal.alt_phone.replace(
                    /(\d{3})(\d{3})(\d{4})/,
                    "($1) $2-$3"
                  )}
                  <br />
                  {this.state.proposal.alt_email}
                </p>
              </div>
            )}
            <div className="col-md-3 text-left">
              <span id="cost-proposal">PROJECT INFORMATION</span>
              <br />
              <p>
                <strong>
                  Proposal Date:{" "}
                  {moment(this.state.proposal.proposal_date).format(
                    "MM/DD/YYYY"
                  )}
                </strong>
                <br />
                <strong>Project Location:</strong>{" "}
                {this.state.opportunity.location}
                <br />
                {this.state.opportunity.city && (
                  <span>{this.state.opportunity.city},</span>
                )}
                {this.state.opportunity.state && (
                  <span>
                    {" " + this.state.opportunity.state}
                    <br />
                  </span>
                )}
                {this.state.opportunity.zip && (
                  <span>
                    {" " + this.state.opportunity.zip}
                    <br />
                  </span>
                )}
                Invoice <i>Flight</i> First:{" "}
                {this.state.proposal.invoice_flight}
                <br />
                Invoice <i>Survey</i> First:{" "}
                {this.state.proposal.invoice_survey}
              </p>
            </div>
          </div>
          <hr />
          <span id="cost-proposal">PROJECT SCOPE</span>
          <p />
          Project Management Fee:{" "}
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(pmFee)}
          <br />
          Adjustment:{" "}
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(this.state.quote.total_adjustment)}
          <p />
          {this.state.proposal.proposal_type == "BIM" && (
            <div>
              <div className="row">
                <div id="service-cards" className="col-md-3 card">
                  <div className="card-body text-center">
                    BIM Services
                    <div>
                      <small>
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(this.state.quote.map_cost)}
                      </small>
                    </div>
                  </div>
                </div>
                <div id="service-cards" className="col-md-9 card">
                  <div className="card-body text-left">
                    <div>
                      <small>
                        Specs: {this.state.specification.location} -{" "}
                        {this.state.specification.size_y}
                        <br />
                        Level Of Detail: {this.state.quote.bim_lod || "n/a"}
                        <br />
                        BIM Hours: {this.state.quote.analytics_qty} hours @ $
                        {this.state.quote.analytics_cp}/hr
                        <br />
                        QC Hours: {this.state.quote.photogrammetry_qty} hours @
                        ${this.state.quote.photogrammetry_cp}/hr
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.proposal.proposal_type == "Film (Mapping Only)" && (
            <div>
              {this.state.quote.panels > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Survey Services
                      {this.state.quote.survey_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.survey_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      {this.state.quote.survey_sub} is responsible for{" "}
                      {this.state.quote.panels} ground control locations and
                      post-process data
                      {this.state.proposal.survey_notes && (
                        <div>
                          <small>{this.state.proposal.survey_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.flight_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Flight Services
                      {this.state.quote.flight_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.flight_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      Acquire {this.state.specification.map_prs}{" "}
                      {this.state.specification.film_type} photography
                      {this.state.proposal.flight_notes && (
                        <div>
                          <small>{this.state.proposal.flight_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "No" &&
                this.state.specification.map_type != "Plan Only" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        1"={this.state.specification.map_scale}' scale map with
                        a {this.state.specification.ci}{" "}
                        {this.state.specification.units} CI collection of{" "}
                        {this.state.specification.map_type}
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "No" &&
                this.state.specification.map_type == "Plan Only" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        1"={this.state.specification.map_scale}' scale map of{" "}
                        {this.state.specification.map_type}
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "Yes" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        Imagery Only AT services
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.lab_cost > 0 &&
                this.state.specification.mapping_only == "No" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Lab Services
                        {this.state.quote.lab_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.lab_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        Scan all photography at 10 microns
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.img_cost > 0 &&
                this.state.specification.mapping_only == "No" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Imagery Services
                        {this.state.quote.img_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.img_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        Orthorectified {pixRes} file
                        {this.state.proposal.imagery_notes && (
                          <div>
                            <small>{this.state.proposal.imagery_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.lidar_flight_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      LiDAR Flight Services
                      {this.state.quote.lidar_flight_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(lidarTotal)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      {this.state.quote.lidar_flight_sub} will acquire{" "}
                      {this.state.quote.lidar_ppsm} PPSM LiDAR
                      {this.state.proposal.lidar_notes && (
                        <div>
                          <small>{this.state.proposal.lidar_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {this.state.proposal.proposal_type ==
            "Film (Mapping Only) - Photo Geodetic" && (
            <div>
              {this.state.quote.panels > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Survey Services
                      {this.state.quote.survey_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.survey_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      {this.state.quote.survey_sub} is responsible for{" "}
                      {this.state.quote.panels} ground control locations and
                      post-process data
                      {this.state.proposal.survey_notes && (
                        <div>
                          <small>{this.state.proposal.survey_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.flight_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Flight Services
                      {this.state.quote.flight_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.flight_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      Acquire {this.state.specification.map_prs}{" "}
                      {this.state.specification.film_type} photography
                      {this.state.proposal.flight_notes && (
                        <div>
                          <small>{this.state.proposal.flight_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "No" &&
                this.state.specification.map_type != "Plan Only" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        1"={this.state.specification.map_scale}' scale map with
                        a {this.state.specification.ci}{" "}
                        {this.state.specification.units} CI collection of{" "}
                        {this.state.specification.map_type}
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "No" &&
                this.state.specification.map_type == "Plan Only" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        1"={this.state.specification.map_scale}' scale map of{" "}
                        {this.state.specification.map_type}
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "Yes" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        Imagery Only AT services
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.lab_cost > 0 &&
                this.state.specification.mapping_only == "No" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Lab Services
                        {this.state.quote.lab_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.lab_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        Scan all photography at 10 microns
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.img_cost > 0 &&
                this.state.specification.mapping_only == "No" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Imagery Services
                        {this.state.quote.img_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.img_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        Orthorectified {pixRes} file
                        {this.state.proposal.imagery_notes && (
                          <div>
                            <small>{this.state.proposal.imagery_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.lidar_flight_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      LiDAR Flight Services
                      {this.state.quote.lidar_flight_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(lidarTotal)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      {this.state.quote.lidar_flight_sub} will acquire{" "}
                      {this.state.quote.lidar_ppsm} PPSM LiDAR
                      {this.state.proposal.lidar_notes && (
                        <div>
                          <small>{this.state.proposal.lidar_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {this.state.proposal.proposal_type == "Film (Image Only)" && (
            <div>
              {this.state.quote.panels > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Survey Services
                      {this.state.quote.survey_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.survey_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      {this.state.quote.survey_sub} is responsible for{" "}
                      {this.state.quote.panels} ground control locations and
                      post-process data
                      {this.state.proposal.survey_notes && (
                        <div>
                          <small>{this.state.proposal.survey_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.flight_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Flight Services
                      {this.state.quote.flight_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.flight_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      Acquire {this.state.specification.map_prs}{" "}
                      {this.state.specification.film_type} photography
                      {this.state.proposal.flight_notes && (
                        <div>
                          <small>{this.state.proposal.flight_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "No" &&
                this.state.specification.map_type != "Plan Only" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        1"={this.state.specification.map_scale}' scale map with
                        a {this.state.specification.ci}{" "}
                        {this.state.specification.units} CI collection of{" "}
                        {this.state.specification.map_type}
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "No" &&
                this.state.specification.map_type == "Plan Only" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        1"={this.state.specification.map_scale}' scale map of{" "}
                        {this.state.specification.map_type}
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "Yes" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        Imagery Only AT services
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.lab_cost > 0 &&
                this.state.specification.mapping_only == "No" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Lab Services
                        {this.state.quote.lab_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.lab_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        Scan all photography at 10 microns
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.img_cost > 0 &&
                this.state.specification.mapping_only == "No" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Imagery Services
                        {this.state.quote.img_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.img_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        Orthorectified {pixRes} file
                        {this.state.proposal.imagery_notes && (
                          <div>
                            <small>{this.state.proposal.imagery_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.lidar_flight_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      LiDAR Flight Services
                      {this.state.quote.lidar_flight_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(lidarTotal)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      {this.state.quote.lidar_flight_sub} will acquire{" "}
                      {this.state.quote.lidar_ppsm} PPSM LiDAR
                      {this.state.proposal.lidar_notes && (
                        <div>
                          <small>{this.state.proposal.lidar_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {this.state.proposal.proposal_type ==
            "Film (Image Only) - Photo Geodetic" && (
            <div>
              {this.state.quote.panels > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Survey Services
                      {this.state.quote.survey_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.survey_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      {this.state.quote.survey_sub} is responsible for{" "}
                      {this.state.quote.panels} ground control locations and
                      post-process data
                      {this.state.proposal.survey_notes && (
                        <div>
                          <small>{this.state.proposal.survey_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.flight_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Flight Services
                      {this.state.quote.flight_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.flight_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      Acquire {this.state.specification.map_prs}{" "}
                      {this.state.specification.film_type} photography
                      {this.state.proposal.flight_notes && (
                        <div>
                          <small>{this.state.proposal.flight_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "No" &&
                this.state.specification.map_type != "Plan Only" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        1"={this.state.specification.map_scale}' scale map with
                        a {this.state.specification.ci}{" "}
                        {this.state.specification.units} CI collection of{" "}
                        {this.state.specification.map_type}
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "No" &&
                this.state.specification.map_type == "Plan Only" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        1"={this.state.specification.map_scale}' scale map of{" "}
                        {this.state.specification.map_type}
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "Yes" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        Imagery Only AT services
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.lab_cost > 0 &&
                this.state.specification.mapping_only == "No" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Lab Services
                        {this.state.quote.lab_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.lab_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        Scan all photography at 10 microns
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.img_cost > 0 &&
                this.state.specification.mapping_only == "No" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Imagery Services
                        {this.state.quote.img_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.img_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        Orthorectified {pixRes} file
                        {this.state.proposal.imagery_notes && (
                          <div>
                            <small>{this.state.proposal.imagery_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.lidar_flight_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      LiDAR Flight Services
                      {this.state.quote.lidar_flight_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(lidarTotal)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      {this.state.quote.lidar_flight_sub} will acquire{" "}
                      {this.state.quote.lidar_ppsm} PPSM LiDAR
                      {this.state.proposal.lidar_notes && (
                        <div>
                          <small>{this.state.proposal.lidar_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {this.state.proposal.proposal_type == "Film" && (
            <div>
              {this.state.quote.panels > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Survey Services
                      {this.state.quote.survey_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.survey_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      {this.state.quote.survey_sub} is responsible for{" "}
                      {this.state.quote.panels} ground control locations and
                      post-process data
                      {this.state.proposal.survey_notes && (
                        <div>
                          <small>{this.state.proposal.survey_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.flight_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Flight Services
                      {this.state.quote.flight_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.flight_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      Acquire {this.state.specification.map_prs}{" "}
                      {this.state.specification.film_type} photography
                      {this.state.proposal.flight_notes && (
                        <div>
                          <small>{this.state.proposal.flight_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "No" &&
                this.state.specification.map_type != "Plan Only" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        1"={this.state.specification.map_scale}' scale map with
                        a {this.state.specification.ci}{" "}
                        {this.state.specification.units} CI collection of{" "}
                        {this.state.specification.map_type}
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "No" &&
                this.state.specification.map_type == "Plan Only" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        1"={this.state.specification.map_scale}' scale map of{" "}
                        {this.state.specification.map_type}
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "Yes" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        Imagery Only AT services
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.lab_cost > 0 &&
                this.state.specification.mapping_only == "No" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Lab Services
                        {this.state.quote.lab_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.lab_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        Scan all photography at 10 microns
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.img_cost > 0 &&
                this.state.specification.mapping_only == "No" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Imagery Services
                        {this.state.quote.img_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.img_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        Orthorectified {pixRes} file
                        {this.state.proposal.imagery_notes && (
                          <div>
                            <small>{this.state.proposal.imagery_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.lidar_flight_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      LiDAR Flight Services
                      {this.state.quote.lidar_flight_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(lidarTotal)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      {this.state.quote.lidar_flight_sub} will acquire{" "}
                      {this.state.quote.lidar_ppsm} PPSM LiDAR
                      {this.state.proposal.lidar_notes && (
                        <div>
                          <small>{this.state.proposal.lidar_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {this.state.proposal.proposal_type == "Film - Photo Geodetic" && (
            <div>
              {this.state.quote.panels > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Survey Services
                      {this.state.quote.survey_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.survey_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      {this.state.quote.survey_sub} is responsible for{" "}
                      {this.state.quote.panels} ground control locations and
                      post-process data
                      {this.state.proposal.survey_notes && (
                        <div>
                          <small>{this.state.proposal.survey_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.flight_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Flight Services
                      {this.state.quote.flight_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.flight_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      Acquire {this.state.specification.map_prs}{" "}
                      {this.state.specification.film_type} photography
                      {this.state.proposal.flight_notes && (
                        <div>
                          <small>{this.state.proposal.flight_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "No" &&
                this.state.specification.map_type != "Plan Only" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        1"={this.state.specification.map_scale}' scale map with
                        a {this.state.specification.ci}{" "}
                        {this.state.specification.units} CI collection of{" "}
                        {this.state.specification.map_type}
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "No" &&
                this.state.specification.map_type == "Plan Only" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        1"={this.state.specification.map_scale}' scale map of{" "}
                        {this.state.specification.map_type}
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "Yes" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        Imagery Only AT services
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.lab_cost > 0 &&
                this.state.specification.mapping_only == "No" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Lab Services
                        {this.state.quote.lab_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.lab_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        Scan all photography at 10 microns
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.img_cost > 0 &&
                this.state.specification.mapping_only == "No" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Imagery Services
                        {this.state.quote.img_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.img_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        Orthorectified {pixRes} file
                        {this.state.proposal.imagery_notes && (
                          <div>
                            <small>{this.state.proposal.imagery_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.lidar_flight_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      LiDAR Flight Services
                      {this.state.quote.lidar_flight_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(lidarTotal)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      {this.state.quote.lidar_flight_sub} will acquire{" "}
                      {this.state.quote.lidar_ppsm} PPSM LiDAR
                      {this.state.proposal.lidar_notes && (
                        <div>
                          <small>{this.state.proposal.lidar_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {this.state.proposal.proposal_type == "ADOT" && (
            <div>
              {this.state.quote.panels > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Survey Services
                      {this.state.quote.survey_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.survey_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      {this.state.quote.survey_sub} is responsible for{" "}
                      {this.state.quote.panels} ground control locations and
                      post-process data
                      {this.state.proposal.survey_notes && (
                        <div>
                          <small>{this.state.proposal.survey_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.flight_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Flight Services
                      {this.state.quote.flight_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.flight_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      Acquire {this.state.specification.map_prs}{" "}
                      {this.state.specification.film_type} photography
                      {this.state.proposal.flight_notes && (
                        <div>
                          <small>{this.state.proposal.flight_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "No" &&
                this.state.specification.map_type != "Plan Only" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        1"={this.state.specification.map_scale}' scale map with
                        a {this.state.specification.ci}{" "}
                        {this.state.specification.units} CI collection of{" "}
                        {this.state.specification.map_type}
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "No" &&
                this.state.specification.map_type == "Plan Only" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        1"={this.state.specification.map_scale}' scale map of{" "}
                        {this.state.specification.map_type}
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.lab_cost > 0 &&
                this.state.specification.mapping_only == "No" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Lab Services
                        {this.state.quote.lab_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.lab_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        Scan all photography at 10 microns
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.img_cost > 0 &&
                this.state.specification.mapping_only == "No" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Imagery Services
                        {this.state.quote.img_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.img_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        Orthorectified {pixRes} GSD file
                        {this.state.proposal.imagery_notes && (
                          <div>
                            <small>{this.state.proposal.imagery_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.lidar_flight_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      LiDAR Flight Services
                      {this.state.quote.lidar_flight_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(lidarTotal)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      {this.state.quote.lidar_flight_sub} will acquire{" "}
                      {this.state.quote.lidar_ppsm} PPSM LiDAR
                      {this.state.proposal.lidar_notes && (
                        <div>
                          <small>{this.state.proposal.lidar_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {this.state.proposal.proposal_type == "Digital" && (
            <div>
              {this.state.quote.panels > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Survey Services
                      {this.state.quote.survey_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.survey_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      {this.state.quote.survey_sub} is responsible for{" "}
                      {this.state.quote.panels} ground control locations and
                      post-process data
                      {this.state.proposal.survey_notes && (
                        <div>
                          <small>{this.state.proposal.survey_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.flight_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Flight Services
                      {this.state.quote.flight_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.flight_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      Acquire {this.state.specification.gsd}{" "}
                      {this.state.specification.film_type} photography
                      {this.state.proposal.flight_notes && (
                        <div>
                          <small>{this.state.proposal.flight_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "No" &&
                this.state.specification.map_type != "Plan Only" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        1"={this.state.specification.map_scale}' scale map with
                        a {this.state.specification.ci}{" "}
                        {this.state.specification.units} CI collection of{" "}
                        {this.state.specification.map_type}
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "No" &&
                this.state.specification.map_type == "Plan Only" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        1"={this.state.specification.map_scale}' scale map of{" "}
                        {this.state.specification.map_type}
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "Yes" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        Imagery Only AT services
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.lab_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Lab Services
                      {this.state.quote.lab_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.lab_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      Scan all photography at 10 microns
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.img_cost > 0 &&
                this.state.specification.mapping_only == "No" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Imagery Services
                        {this.state.quote.img_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.img_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        Orthorectified {this.state.specification.gsd} GSD file
                        {this.state.proposal.imagery_notes && (
                          <div>
                            <small>{this.state.proposal.imagery_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.lidar_flight_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      LiDAR Flight Services
                      {this.state.quote.lidar_flight_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(lidarTotal)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      {this.state.quote.lidar_flight_sub} will acquire{" "}
                      {this.state.quote.lidar_ppsm} PPSM LiDAR
                      {this.state.proposal.lidar_notes && (
                        <div>
                          <small>{this.state.proposal.lidar_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {this.state.proposal.proposal_type == "Digital - Photo Geodetic" && (
            <div>
              {this.state.quote.panels > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Survey Services
                      {this.state.quote.survey_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.survey_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      {this.state.quote.survey_sub} is responsible for{" "}
                      {this.state.quote.panels} ground control locations and
                      post-process data
                      {this.state.proposal.survey_notes && (
                        <div>
                          <small>{this.state.proposal.survey_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.flight_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Flight Services
                      {this.state.quote.flight_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.flight_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      Acquire {this.state.specification.gsd}{" "}
                      {this.state.specification.film_type} photography
                      {this.state.proposal.flight_notes && (
                        <div>
                          <small>{this.state.proposal.flight_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "No" &&
                this.state.specification.map_type != "Plan Only" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        1"={this.state.specification.map_scale}' scale map with
                        a {this.state.specification.ci}{" "}
                        {this.state.specification.units} CI collection of{" "}
                        {this.state.specification.map_type}
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "No" &&
                this.state.specification.map_type == "Plan Only" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        1"={this.state.specification.map_scale}' scale map of{" "}
                        {this.state.specification.map_type}
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "Yes" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        Imagery Only AT services
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.lab_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Lab Services
                      {this.state.quote.lab_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.lab_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      Scan all photography at 10 microns
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.img_cost > 0 &&
                this.state.specification.mapping_only == "No" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Imagery Services
                        {this.state.quote.img_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.img_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        Orthorectified {this.state.specification.gsd} GSD file
                        {this.state.proposal.imagery_notes && (
                          <div>
                            <small>{this.state.proposal.imagery_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.lidar_flight_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      LiDAR Flight Services
                      {this.state.quote.lidar_flight_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(lidarTotal)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      {this.state.quote.lidar_flight_sub} will acquire{" "}
                      {this.state.quote.lidar_ppsm} PPSM LiDAR
                      {this.state.proposal.lidar_notes && (
                        <div>
                          <small>{this.state.proposal.lidar_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {this.state.proposal.proposal_type == "Digital (Mapping Only)" && (
            <div>
              {this.state.quote.panels > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Survey Services
                      {this.state.quote.survey_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.survey_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      {this.state.quote.survey_sub} is responsible for{" "}
                      {this.state.quote.panels} ground control locations and
                      post-process data
                      {this.state.proposal.survey_notes && (
                        <div>
                          <small>{this.state.proposal.survey_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.flight_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Flight Services
                      {this.state.quote.flight_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.flight_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      Acquire {this.state.specification.gsd}{" "}
                      {this.state.specification.film_type} photography
                      {this.state.proposal.flight_notes && (
                        <div>
                          <small>{this.state.proposal.flight_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "No" &&
                this.state.specification.map_type != "Plan Only" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        1"={this.state.specification.map_scale}' scale map with
                        a {this.state.specification.ci}{" "}
                        {this.state.specification.units} CI collection of{" "}
                        {this.state.specification.map_type}
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "No" &&
                this.state.specification.map_type == "Plan Only" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        1"={this.state.specification.map_scale}' scale map of{" "}
                        {this.state.specification.map_type}
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "Yes" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        Imagery Only AT services
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.lab_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Lab Services
                      {this.state.quote.lab_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.lab_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      Scan all photography at 10 microns
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.img_cost > 0 &&
                this.state.specification.mapping_only == "No" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Imagery Services
                        {this.state.quote.img_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.img_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        Orthorectified {this.state.specification.gsd} GSD file
                        {this.state.proposal.imagery_notes && (
                          <div>
                            <small>{this.state.proposal.imagery_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.lidar_flight_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      LiDAR Flight Services
                      {this.state.quote.lidar_flight_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(lidarTotal)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      {this.state.quote.lidar_flight_sub} will acquire{" "}
                      {this.state.quote.lidar_ppsm} PPSM LiDAR
                      {this.state.proposal.lidar_notes && (
                        <div>
                          <small>{this.state.proposal.lidar_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {this.state.proposal.proposal_type ==
            "Digital (Mapping Only) - Photo Geodetic" && (
            <div>
              {this.state.quote.panels > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Survey Services
                      {this.state.quote.survey_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.survey_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      {this.state.quote.survey_sub} is responsible for{" "}
                      {this.state.quote.panels} ground control locations and
                      post-process data
                      {this.state.proposal.survey_notes && (
                        <div>
                          <small>{this.state.proposal.survey_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.flight_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Flight Services
                      {this.state.quote.flight_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.flight_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      Acquire {this.state.specification.gsd}{" "}
                      {this.state.specification.film_type} photography
                      {this.state.proposal.flight_notes && (
                        <div>
                          <small>{this.state.proposal.flight_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "No" &&
                this.state.specification.map_type != "Plan Only" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        1"={this.state.specification.map_scale}' scale map with
                        a {this.state.specification.ci}{" "}
                        {this.state.specification.units} CI collection of{" "}
                        {this.state.specification.map_type}
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "No" &&
                this.state.specification.map_type == "Plan Only" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        1"={this.state.specification.map_scale}' scale map of{" "}
                        {this.state.specification.map_type}
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "Yes" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        Imagery Only AT services
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.lab_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Lab Services
                      {this.state.quote.lab_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.lab_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      Scan all photography at 10 microns
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.img_cost > 0 &&
                this.state.specification.mapping_only == "No" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Imagery Services
                        {this.state.quote.img_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.img_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        Orthorectified {this.state.specification.gsd} GSD file
                        {this.state.proposal.imagery_notes && (
                          <div>
                            <small>{this.state.proposal.imagery_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.lidar_flight_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      LiDAR Flight Services
                      {this.state.quote.lidar_flight_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(lidarTotal)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      {this.state.quote.lidar_flight_sub} will acquire{" "}
                      {this.state.quote.lidar_ppsm} PPSM LiDAR
                      {this.state.proposal.lidar_notes && (
                        <div>
                          <small>{this.state.proposal.lidar_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {this.state.proposal.proposal_type == "Digital (Image Only)" && (
            <div>
              {this.state.quote.panels > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Survey Services
                      {this.state.quote.survey_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.survey_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      {this.state.quote.survey_sub} is responsible for{" "}
                      {this.state.quote.panels} ground control locations and
                      post-process data
                      {this.state.proposal.survey_notes && (
                        <div>
                          <small>{this.state.proposal.survey_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.flight_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Flight Services
                      {this.state.quote.flight_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.flight_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      Acquire {this.state.specification.gsd}{" "}
                      {this.state.specification.film_type} photography
                      {this.state.proposal.flight_notes && (
                        <div>
                          <small>{this.state.proposal.flight_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "No" &&
                this.state.specification.map_type != "Plan Only" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        1"={this.state.specification.map_scale}' scale map with
                        a {this.state.specification.ci}{" "}
                        {this.state.specification.units} CI collection of{" "}
                        {this.state.specification.map_type}
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "No" &&
                this.state.specification.map_type == "Plan Only" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        1"={this.state.specification.map_scale}' scale map of{" "}
                        {this.state.specification.map_type}
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "Yes" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        Imagery Only AT services
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.lab_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Lab Services
                      {this.state.quote.lab_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.lab_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      Scan all photography at 10 microns
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.img_cost > 0 &&
                this.state.specification.mapping_only == "No" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Imagery Services
                        {this.state.quote.img_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.img_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        Orthorectified {this.state.specification.gsd} GSD file
                        {this.state.proposal.imagery_notes && (
                          <div>
                            <small>{this.state.proposal.imagery_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.lidar_flight_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      LiDAR Flight Services
                      {this.state.quote.lidar_flight_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(lidarTotal)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      {this.state.quote.lidar_flight_sub} will acquire{" "}
                      {this.state.quote.lidar_ppsm} PPSM LiDAR
                      {this.state.proposal.lidar_notes && (
                        <div>
                          <small>{this.state.proposal.lidar_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {this.state.proposal.proposal_type ==
            "Digital (Image Only) - Photo Geodetic" && (
            <div>
              {this.state.quote.panels > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Survey Services
                      {this.state.quote.survey_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.survey_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      {this.state.quote.survey_sub} is responsible for{" "}
                      {this.state.quote.panels} ground control locations and
                      post-process data
                      {this.state.proposal.survey_notes && (
                        <div>
                          <small>{this.state.proposal.survey_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.flight_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Flight Services
                      {this.state.quote.flight_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.flight_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      Acquire {this.state.specification.gsd}{" "}
                      {this.state.specification.film_type} photography
                      {this.state.proposal.flight_notes && (
                        <div>
                          <small>{this.state.proposal.flight_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "No" &&
                this.state.specification.map_type != "Plan Only" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        1"={this.state.specification.map_scale}' scale map with
                        a {this.state.specification.ci}{" "}
                        {this.state.specification.units} CI collection of{" "}
                        {this.state.specification.map_type}
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "No" &&
                this.state.specification.map_type == "Plan Only" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        1"={this.state.specification.map_scale}' scale map of{" "}
                        {this.state.specification.map_type}
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.map_cost > 0 &&
                this.state.specification.image_only == "Yes" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Mapping Services
                        {this.state.quote.map_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.map_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        Imagery Only AT services
                        {this.state.proposal.mapping_notes && (
                          <div>
                            <small>{this.state.proposal.mapping_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.lab_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Lab Services
                      {this.state.quote.lab_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.lab_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      Scan all photography at 10 microns
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.img_cost > 0 &&
                this.state.specification.mapping_only == "No" && (
                  <div className="row">
                    <div id="service-cards" className="col-md-3 card">
                      <div className="card-body text-center">
                        Imagery Services
                        {this.state.quote.img_cost > 0 && (
                          <div>
                            <small>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(this.state.quote.img_cost)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="service-cards" className="col-md-9 card">
                      <div className="card-body text-left">
                        Orthorectified {this.state.specification.gsd} GSD file
                        {this.state.proposal.imagery_notes && (
                          <div>
                            <small>{this.state.proposal.imagery_notes}</small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.quote.lidar_flight_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      LiDAR Flight Services
                      {this.state.quote.lidar_flight_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(lidarTotal)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      {this.state.quote.lidar_flight_sub} will acquire{" "}
                      {this.state.quote.lidar_ppsm} PPSM LiDAR
                      {this.state.proposal.lidar_notes && (
                        <div>
                          <small>{this.state.proposal.lidar_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {this.state.proposal.proposal_type == "Survey" && (
            <div>
              <div className="row">
                <div id="service-cards" className="col-md-3 card">
                  <div className="card-body text-center">
                    Survey Services
                    {this.state.quote.survey_cost > 0 && (
                      <div>
                        <small>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(this.state.quote.survey_cost)}
                        </small>
                      </div>
                    )}
                  </div>
                </div>
                <div id="service-cards" className="col-md-9 card">
                  <div className="card-body text-left">
                    {this.state.proposal.survey_notes && (
                      <div>{this.state.proposal.survey_notes}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div id="service-cards" className="col-md-3 card">
                  <div className="card-body text-center">
                    Surveyor Services
                    {servicesTotal > 0 && (
                      <div>
                        <small>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(servicesTotal)}
                        </small>
                      </div>
                    )}
                  </div>
                </div>
                <div id="service-cards" className="col-md-9 card">
                  <div className="card-body text-left">
                    <p>
                      Registered Land Surveyor: {this.state.quote.rls_hrs} hours
                      @{" "}
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(this.state.quote.rls_cp)}{" "}
                      per hour
                    </p>
                    <p>
                      Survey Party Chief: {this.state.quote.spc_hrs} hours @{" "}
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(this.state.quote.spc_cp)}{" "}
                      per hour
                    </p>
                    <p>
                      Survey Technician: {this.state.quote.st_hrs} hours @{" "}
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(this.state.quote.st_cp)}{" "}
                      per hour
                    </p>
                    <p>
                      2 Person Survey Crew: {this.state.quote.sc2_hrs} hours @{" "}
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(this.state.quote.sc2_cp)}{" "}
                      per hour
                    </p>
                    <p>
                      3 Person Survey Crew: {this.state.quote.sc3_hrs} hours @{" "}
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(this.state.quote.sc3_cp)}{" "}
                      per hour
                    </p>
                    <p>
                      R/W Plans Technician: {this.state.quote.rw_plans_hrs}{" "}
                      hours @{" "}
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(this.state.quote.rw_plans_cp)}{" "}
                      per hour
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.proposal.proposal_type == "Flight" && (
            <div>
              <div className="row">
                <div id="service-cards" className="col-md-3 card">
                  <div className="card-body text-center">
                    Flight Services
                    {this.state.quote.flight_cost > 0 && (
                      <div>
                        <small>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(this.state.quote.flight_cost)}
                        </small>
                      </div>
                    )}
                  </div>
                </div>
                <div id="service-cards" className="col-md-9 card">
                  <div className="card-body text-left">
                    Acquire {this.state.specification.map_prs}{" "}
                    {this.state.specification.film_type} photography for mapping
                    and imagery
                    {this.state.proposal.flight_notes && (
                      <div>
                        <small>{this.state.proposal.flight_notes}</small>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div id="service-cards" className="col-md-3 card">
                  <div className="card-body text-center">
                    Fuel Charge
                    {this.state.quote.fuel_charge > 0 && (
                      <div>
                        <small>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(this.state.quote.fuel_charge)}
                        </small>
                      </div>
                    )}
                  </div>
                </div>
                <div id="service-cards" className="col-md-9 card">
                  <div className="card-body text-left">
                    Fuel charge for {this.state.quote.flight_distance} miles{" "}
                    {this.state.quote.travel}
                  </div>
                </div>
              </div>
              <div className="row">
                <div id="service-cards" className="col-md-3 card">
                  <div className="card-body text-center">
                    RAW Data Charge
                    {this.state.quote.raw_data_charge > 0 && (
                      <div>
                        <small>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(this.state.quote.raw_data_charge)}
                        </small>
                      </div>
                    )}
                  </div>
                </div>
                <div id="service-cards" className="col-md-9 card">
                  <div className="card-body text-left">
                    Charge for processing data
                  </div>
                </div>
              </div>
              <div className="row">
                <div id="service-cards" className="col-md-3 card">
                  <div className="card-body text-center">
                    Permit Charge
                    {this.state.quote.raw_data_charge > 0 && (
                      <div>
                        <small>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(this.state.quote.permit_charge)}
                        </small>
                      </div>
                    )}
                  </div>
                </div>
                <div id="service-cards" className="col-md-9 card">
                  <div className="card-body text-left">Charge for permit</div>
                </div>
              </div>
              <div className="row">
                <div id="service-cards" className="col-md-3 card">
                  <div className="card-body text-center">
                    Airspace Charge
                    {this.state.quote.raw_data_charge > 0 && (
                      <div>
                        <small>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(this.state.quote.airspace_charge)}
                        </small>
                      </div>
                    )}
                  </div>
                </div>
                <div id="service-cards" className="col-md-9 card">
                  <div className="card-body text-left">Charge for airspace</div>
                </div>
              </div>
            </div>
          )}
          {this.state.proposal.proposal_type == "NAAS" && (
            <div>
              <div className="row">
                <div id="service-cards" className="col-md-3 card">
                  <div className="card-body text-center">
                    Flight Services
                    {this.state.quote.flight_cost > 0 && (
                      <div>
                        <small>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(this.state.quote.flight_cost)}
                        </small>
                      </div>
                    )}
                  </div>
                </div>
                <div id="service-cards" className="col-md-9 card">
                  <div className="card-body text-left">
                    Acquire {this.state.specification.map_prs}{" "}
                    {this.state.specification.film_type} photography for mapping
                    and imagery
                    {this.state.proposal.flight_notes && (
                      <div>
                        <small>{this.state.proposal.flight_notes}</small>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div id="service-cards" className="col-md-3 card">
                  <div className="card-body text-center">
                    Survey Mission Total Miles
                    {this.state.quote.flight_distance > 0 && (
                      <div>
                        <small>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(this.state.quote.flight_distance_price)}
                        </small>
                      </div>
                    )}
                  </div>
                </div>
                <div id="service-cards" className="col-md-9 card">
                  <div className="card-body text-left">
                    Charge for {this.state.quote.flight_distance} miles {this.state.quote.travel}
                  </div>
                </div>
              </div>
              <div className="row">
                <div id="service-cards" className="col-md-3 card">
                  <div className="card-body text-center">
                    Flight Lines
                    {this.state.quote.flight_lines > 0 && (
                      <div>
                        <small>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(this.state.quote.flight_lines_price)}
                        </small>
                      </div>
                    )}
                  </div>
                </div>
                <div id="service-cards" className="col-md-9 card">
                  <div className="card-body text-left">
                    Charge for # of flight lines
                  </div>
                </div>
              </div>
              <div className="row">
                <div id="service-cards" className="col-md-3 card">
                  <div className="card-body text-center">
                    On Line Hours Flight Time
                    {this.state.quote.flight_time > 0 && (
                      <div>
                        <small>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(this.state.quote.flight_time_price)}
                        </small>
                      </div>
                    )}
                  </div>
                </div>
                <div id="service-cards" className="col-md-9 card">
                  <div className="card-body text-left">On Line flight time charge</div>
                </div>
              </div>
              <div className="row">
                <div id="service-cards" className="col-md-3 card">
                  <div className="card-body text-center">
                    Data Drive
                    {this.state.quote.fuel_charge > 0 && (
                      <div>
                        <small>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(this.state.quote.fuel_charge_price)}
                        </small>
                      </div>
                    )}
                  </div>
                </div>
                <div id="service-cards" className="col-md-9 card">
                  <div className="card-body text-left">Data Drive charge for {dataDrive}</div>
                </div>
              </div>
              <div className="row">
                <div id="service-cards" className="col-md-3 card">
                  <div className="card-body text-center">
                    RAW Data Processing
                    {this.state.quote.raw_data_charge > 0 && (
                      <div>
                        <small>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(this.state.quote.raw_data_charge_price)}
                        </small>
                      </div>
                    )}
                  </div>
                </div>
                <div id="service-cards" className="col-md-9 card">
                  <div className="card-body text-left">
                    Charge for processing data to Level{" "}
                    {this.state.quote.raw_data_charge}
                  </div>
                </div>
              </div>
              <div className="row">
                <div id="service-cards" className="col-md-3 card">
                  <div className="card-body text-center">
                    Shipping
                    {this.state.quote.shipping > 0 && (
                      <div>
                        <small>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(this.state.quote.shipping)}
                        </small>
                      </div>
                    )}
                  </div>
                </div>
                <div id="service-cards" className="col-md-9 card">
                  <div className="card-body text-left">Shipping charge</div>
                </div>
              </div>
              <div className="row">
                <div id="service-cards" className="col-md-3 card">
                  <div className="card-body text-center">
                    Permit Charge
                    {this.state.quote.permit_charge > 0 && (
                      <div>
                        <small>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(this.state.quote.permit_charge_price)}
                        </small>
                      </div>
                    )}
                  </div>
                </div>
                <div id="service-cards" className="col-md-9 card">
                  <div className="card-body text-left">Charge for permit</div>
                </div>
              </div>
              <div className="row">
                <div id="service-cards" className="col-md-3 card">
                  <div className="card-body text-center">
                    Overtime Weekends and Holidays
                    {this.state.quote.nights_weekends > 0 && (
                      <div>
                        <small>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(this.state.quote.night_weekends_price)}
                        </small>
                      </div>
                    )}
                  </div>
                </div>
                <div id="service-cards" className="col-md-9 card">
                  <div className="card-body text-left">
                    Overtime Weekends/Holidays charge
                  </div>
                </div>
              </div>
              <div className="row">
                <div id="service-cards" className="col-md-3 card">
                  <div className="card-body text-center">
                    ABGPS Ground Station
                    {this.state.quote.abgps > 0 && (
                      <div>
                        <small>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(this.state.quote.travel_price)}
                        </small>
                      </div>
                    )}
                  </div>
                </div>
                <div id="service-cards" className="col-md-9 card">
                  <div className="card-body text-left">
                    Charge for ABGPS ground stations
                  </div>
                </div>
              </div>
              <div className="row">
                <div id="service-cards" className="col-md-3 card">
                  <div className="card-body text-center">
                    O2 Use as per High Altitude Number of Lines
                    {this.state.quote.o2_use > 0 && (
                      <div>
                        <small>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(this.state.quote.o2_use_price)}
                        </small>
                      </div>
                    )}
                  </div>
                </div>
                <div id="service-cards" className="col-md-9 card">
                  <div className="card-body text-left">Charge for O2 use</div>
                </div>
              </div>
              <div className="row">
                <div id="service-cards" className="col-md-3 card">
                  <div className="card-body text-center">
                    Restricted or Class B Airspace Coordination Charge
                    {this.state.quote.airspace_charge > 0 && (
                      <div>
                        <small>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(this.state.quote.airspace_charge_price)}
                        </small>
                      </div>
                    )}
                  </div>
                </div>
                <div id="service-cards" className="col-md-9 card">
                  <div className="card-body text-left">
                    Charge for Restricted or Class B airspace
                  </div>
                </div>
              </div>
              <div className="row">
                <div id="service-cards" className="col-md-3 card">
                  <div className="card-body text-center">
                    Overnight
                    {this.state.quote.overnight > 0 && (
                      <div>
                        <small>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(this.state.quote.overnight_price)}
                        </small>
                      </div>
                    )}
                  </div>
                </div>
                <div id="service-cards" className="col-md-9 card">
                  <div className="card-body text-left">
                    Charge for overnight
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.proposal.proposal_type == "Scan" && (
            <div>
              <div className="row">
                <div id="service-cards" className="col-md-3 card">
                  <div className="card-body text-center">
                    Lab Services
                    {this.state.quote.lab_cost > 0 && (
                      <div>
                        <small>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(this.state.quote.lab_cost)}
                        </small>
                      </div>
                    )}
                  </div>
                </div>
                <div id="service-cards" className="col-md-9 card">
                  <div className="card-body text-left">
                    Scan all photography at {this.state.specification.scan_res}{" "}
                    microns
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.proposal.proposal_type == "UAV" && (
            <div>
              {this.state.quote.panels > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Survey Services
                      {this.state.quote.survey_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.survey_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      {this.state.quote.survey_sub} is responsible for{" "}
                      {this.state.quote.panels} ground control locations and
                      post-process data
                      {this.state.proposal.survey_notes && (
                        <div>
                          <small>{this.state.proposal.survey_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.flight_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Flight Services
                      {this.state.quote.flight_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.flight_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    {this.state.proposal.uav_lidar == "No" && (
                      <div className="card-body text-left">
                        Acquire {this.state.specification.map_prs}{" "}
                        {this.state.specification.film_type} photography for
                        mapping and imagery
                        {this.state.proposal.flight_notes && (
                          <div>
                            <small>{this.state.proposal.flight_notes}</small>
                          </div>
                        )}
                      </div>
                    )}
                    {this.state.proposal.uav_lidar == "Yes" && (
                      <div className="card-body text-left">
                        Acquire {this.state.specification.map_prs}{" "}
                        {this.state.specification.film_type} photography and
                        LiDAR for mapping and imagery
                        {this.state.proposal.flight_notes && (
                          <div>
                            <small>{this.state.proposal.flight_notes}</small>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
              {this.state.specification.exposures > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Mapping Services
                      {this.state.quote.map_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.map_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      Cooper Aerial will process{" "}
                      {this.state.specification.exposures} exposures ( 1"=
                      {this.state.specification.map_scale}' scale map with a{" "}
                      {this.state.specification.ci} Ft CI collection )
                      {this.state.proposal.mapping_notes && (
                        <div>
                          <small>{this.state.proposal.mapping_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.specification.obliques_exposures > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Obliques Services
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      Cooper Aerial will process{" "}
                      {this.state.specification.obliques_exposures} oblique
                      exposures
                    </div>
                  </div>
                </div>
              )}
              {this.state.specification.total_videos > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">Video Services</div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      Cooper Aerial will process{" "}
                      {this.state.specification.total_videos} videos
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.lidar_flight_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      LiDAR Flight Services
                      {this.state.quote.lidar_flight_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(lidarTotal)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      {this.state.quote.lidar_flight_sub} will acquire{" "}
                      {this.state.quote.lidar_ppsm} PPSM LiDAR
                      {this.state.proposal.lidar_notes && (
                        <div>
                          <small>{this.state.proposal.lidar_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {this.state.proposal.proposal_type == "LiDAR" && (
            <div>
              {this.state.quote.panels > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Survey Services
                      {this.state.quote.survey_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.survey_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      {this.state.quote.survey_sub} is responsible for{" "}
                      {this.state.quote.panels} ground control locations and
                      post-process data
                      {this.state.proposal.survey_notes && (
                        <div>
                          <small>{this.state.proposal.survey_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.flight_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      LiDAR Flight Services
                      {this.state.quote.flight_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.flight_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      Acquire {this.state.specification.ppsm} PPSM LiDAR
                      {this.state.proposal.flight_notes && (
                        <div>
                          <small>{this.state.proposal.flight_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.map_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Mapping Services
                      {this.state.quote.map_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.map_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      1"={this.state.specification.map_scale}' scale map with a{" "}
                      {this.state.specification.ci}{" "}
                      {this.state.specification.units} CI collection of{" "}
                      {this.state.specification.map_type}
                      {this.state.proposal.mapping_notes && (
                        <div>
                          <small>{this.state.proposal.mapping_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.lab_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Lab Services
                      {this.state.quote.lab_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.lab_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      Scan all photography at 10 microns
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.img_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Imagery Services
                      {this.state.quote.img_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.img_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      Orthorectified 0.098' GSD file
                      {this.state.proposal.imagery_notes && (
                        <div>
                          <small>{this.state.proposal.imagery_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.quote.lidar_flight_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Imagery Flight Services
                      {this.state.quote.lidar_flight_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(lidarTotal)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      Acquire {this.state.specification.gsd}{" "}
                      {this.state.specification.map_prs}{" "}
                      {this.state.specification.film_type} photography for
                      mapping and imagery
                      {this.state.proposal.lidar_notes && (
                        <div>
                          <small>{this.state.proposal.lidar_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div id="service-cards" className="col-md-3 card">
                  <div className="card-body text-center">
                    Processing Services
                  </div>
                </div>
                <div id="service-cards" className="col-md-9 card">
                  <div className="card-body text-left">
                    {this.state.quote.size} {this.state.specification.size_type}{" "}
                    will be processed
                    {this.state.proposal.lidar_processing_notes && (
                      <div>
                        <small>
                          {this.state.proposal.lidar_processing_notes}
                        </small>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.proposal.proposal_type == "LiDAR Processing" && (
            <div>
              <div className="row">
                <div id="service-cards" className="col-md-3 card">
                  <div className="card-body text-center">
                    Processing Services
                    {this.state.quote.map_cost > 0 && (
                      <div>
                        <small>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(this.state.quote.map_cost)}
                        </small>
                      </div>
                    )}
                  </div>
                </div>
                <div id="service-cards" className="col-md-9 card">
                  <div className="card-body text-left">
                    {this.state.specification.size}{" "}
                    {this.state.specification.size_type} will be processed
                    {this.state.proposal.lidar_processing_notes && (
                      <div>
                        <small>
                          {this.state.proposal.lidar_processing_notes}
                        </small>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.proposal.proposal_type == "Plot" && (
            <div>
              {this.state.quote.flight_cost > 0 && (
                <div className="row">
                  <div id="service-cards" className="col-md-3 card">
                    <div className="card-body text-center">
                      Flight Services
                      {this.state.quote.flight_cost > 0 && (
                        <div>
                          <small>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(this.state.quote.flight_cost)}
                          </small>
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="service-cards" className="col-md-9 card">
                    <div className="card-body text-left">
                      Acquire photography
                      {this.state.proposal.flight_notes && (
                        <div>
                          <small>{this.state.proposal.flight_notes}</small>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div id="service-cards" className="col-md-3 card">
                  <div className="card-body text-center">Plot Services</div>
                </div>
                <div id="service-cards" className="card">
                  <div className="card-body text-left">
                    Quantity: {this.state.specification.quantity}
                    <br />
                    Size: {this.state.specification.size_x}x
                    {this.state.specification.size_y}
                    <br />
                    Mounted: {this.state.specification.mounted}
                    <br />
                    Frame: {this.state.specification.frame}
                    <br />
                    {this.state.specification.frame == "Metal" ||
                      (this.state.specification.frame == "Plastic" && (
                        <div>
                          Frame Color: {this.state.specification.frame_color}{" "}
                          <br />
                        </div>
                      ))}
                    {this.state.specification.laminated == "Yes" && (
                      <div>
                        Laminate Type: {this.state.specification.plot_options}{" "}
                        <br />
                      </div>
                    )}
                    <hr />
                    {additionalPlots.map((plot, idx) => (
                      <div key={`plot-${idx}`}>
                        {this.state.additionalPlots[idx]["quantity"] >= 1 && (
                          <div>
                            Quantity:{" "}
                            {this.state.additionalPlots[idx]["quantity"]}
                            <br />
                            Size: {this.state.additionalPlots[idx]["size_x"]}x
                            {this.state.additionalPlots[idx]["size_y"]}
                            <br />
                            Mounted:{" "}
                            {this.state.additionalPlots[idx]["mounted"]}
                            <br />
                            Frame: {this.state.additionalPlots[idx]["frame"]}
                            {this.state.additionalPlots[idx]["frame"] ==
                              "Metal" ||
                              (this.state.additionalPlots[idx]["frame"] ==
                                "Plastic" && (
                                <div>
                                  Frame Color:{" "}
                                  {
                                    this.state.additionalPlots[idx][
                                      "frame_color"
                                    ]
                                  }
                                  <br />
                                </div>
                              ))}
                            {this.state.additionalPlots[idx]["laminated"] ==
                              "Yes" && (
                              <div>
                                Laminate Type:{" "}
                                {
                                  this.state.additionalPlots[idx][
                                    "plot_options"
                                  ]
                                }
                                <br />
                              </div>
                            )}
                            <hr />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.proposal.proposal_type == "Other" && (
            <div>{this.state.proposal.project_scope || "n/a"}</div>
          )}
          <hr />
          <p>
            <span id="cost-proposal">DELIVERABLES</span>
            <br /> {this.state.proposal.deliverables}
          </p>
          {this.state.proposal.proposal_type != "Plot" && (
            <div>
              <hr />
              <p>
                <span id="cost-proposal">ESTIMATED DURATION</span>
                <br /> {this.state.proposal.working_days}
              </p>
            </div>
          )}
          <p />
          {this.state.proposal.notes && (
            <div>
              <hr />
              <p>Additional Notes: {this.state.proposal.notes}</p>
            </div>
          )}
          <hr />
          <div className="row">
            <div className="col-md-3">
              <p>
                <strong>
                  <span id="cost-proposal">TOTAL FEE</span>
                  <br />{" "}
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(this.state.quote.quote_cost)}
                </strong>
              </p>
            </div>
          </div>
          <hr />
        </div>
      </div>
    );
  }
}
