/* eslint-disable react/prefer-stateless-function */
import React from 'react';

export default class Invoice extends React.Component {
  render() {
    return (
      <div>
        <h3 id="header">Projects Ready for Invoice</h3>
        <a
          className="view-record"
          href="/cascopt/invoicing/time_and_materials"
        >
          View Invoicing - Time and Materials Dashboard
        </a>
        <br />
        <a
          className="view-record"
          href="/cascopt/invoicing/partial_billing"
        >
          View Invoicing - Partial Billing Dashboard
        </a>
      </div>
    );
  }
}
