import React from 'react';
import PropTypes from 'prop-types';
import { FormErrors } from './FormErrors';
import update from 'immutability-helper';
import { validations } from '../utils/validations';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

export default class RectificationForm extends React.Component {
  static propTypes = {
    handleNewRectification: PropTypes.func
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      client_number: { value: '', valid: false },
      type: { value: '', valid: true },
      job_number: { value: '', valid: true },
      due_date: { value: '', valid: true },
      client_name: { value: '', valid: false },
      models: { value: '', valid: false },
      flight_lines: { value: '', valid: false },
      exposures: { value: '', valid: false },
      color_film: '',
      project_id: { value: '', valid: false },
      img_staff: { value: '', valid: false },
      img_done: { value: '', valid: false },
      file_location: { value: '', valid: false },
      img_complete: '',
      to_sub: '',
      sub_received: '',
      total_hours: { value: '', valid: true },
      rejected: '',
      rejected_date: { value: '', valid: true },
      sub_received_date: { value: '', valid: true },
      sub_due_date: { value: '', valid: true },
      img_done_date: { value: '', valid: true },
      project_notes: { value: '', valid: true },
      rect_notes: { value: '', valid: true },
      fixed: '',
      formErrors: {},
      formValid: false,
      editing: false
    }
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
  }

  static formValidations = {
    project_id: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    img_staff: [
      (s) => { return (validations.checkMinLength(s, 3)) }
    ],
    img_done: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    file_location: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    total_hours: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    rejected_date: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    sub_received_date: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    sub_due_date: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    img_done_date: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    models: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    flight_lines: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    exposures: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    client_name: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    project_notes: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
    rect_notes: [
      (s) => { return (validations.checkMinLength(s, 1)) }
    ],
  }

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/cascopt/rectifications/${this.props.match.params.id}`,
        dataType: "JSON"
      }).done((data) => {
        this.setState({
          type: { value: data.quality_control.type, valid: true },
          client_name: { value: data.project.client_name, valid: true },
          client_number: { value: data.project.client_number, valid: true },
          job_number: { value: data.project.job_number, valid: true },
          due_date: { value: data.project.due_date, valid: true },
          models: { value: data.project.models, valid: true },
          flight_lines: { value: data.project.flight_lines, valid: true },
          exposures: { value: data.project.exposures, valid: true },
          color_film: data.project.color_film,
          project_id: { value: data.rectification.project_id, valid: true },
          img_staff: { value: data.rectification.img_staff, valid: true },
          img_done: { value: data.rectification.img_done, valid: true },
          file_location: { value: data.rectification.file_location, valid: true },
          img_complete: data.rectification.img_complete,
          to_sub: data.rectification.to_sub,
          sub_received: data.rectification.sub_received,
          total_hours: { value: data.rectification.total_hours, valid: true },
          rejected: data.rectification.rejected,
          rejected_date: { value: data.rectification.rejected_date, valid: true },
          img_done_date: { value: data.rectification.img_done_date, valid: true },
          sub_received_date: { value: data.rectification.sub_received_date, valid: true },
          sub_due_date: { value: data.rectification.sub_due_date, valid: true },
          project_notes: { value: data.project.project_notes, valid: true },
          rect_notes: { value: data.project.rect_notes, valid: true },
          fixed: data.rectification.fixed,
          editing: this.props.match.path === '/cascopt/rectifications/:id/edit'
        });
      });
    }
  }

  handleUserInput = (fieldName, fieldValue, validations) => {
    const newFieldState = update(this.state[fieldName],
      { value: { $set: fieldValue } });
    this.setState({ [fieldName]: newFieldState },
      () => { this.validateField(fieldName, fieldValue, validations) });
  }

  validateField(fieldName, fieldValue, validations) {
    let fieldValid;

    let fieldErrors = validations.reduce((errors, v) => {
      let e = v(fieldValue);
      if (e !== '') {
        errors.push(e);
      }
      return (errors);
    }, []);

    fieldValid = fieldErrors.length === 0;

    const newFieldState = update(this.state[fieldName],
      { valid: { $set: fieldValid } });

    const newFormErrors = update(this.state.formErrors,
      { $merge: { [fieldName]: fieldErrors } });

    this.setState({
      [fieldName]: newFieldState,
      formErrors: newFormErrors
    }, this.validateForm);
  }

  validateForm() {
    this.setState({
      formValid: this.state.project_id.valid &&
        this.state.img_staff.valid &&
        this.state.img_done.valid &&
        this.state.file_location.valid &&
        this.state.total_hours.valid &&
        this.state.rejected_date.valid &&
        this.state.img_done_date.valid &&
        this.state.sub_received_date.valid &&
        this.state.sub_due_date.valid &&
        this.state.models.valid &&
        this.state.flight_lines.valid &&
        this.state.exposures.valid &&
        this.state.project_notes.valid &&
        this.state.rect_notes.valid
    });
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.state.editing ?
      this.updateRectification() :
      this.addRectification();
  }

  updateRectification() {
    const rectification = {
      models: this.state.models.value,
      flight_lines: this.state.flight_lines.value,
      exposures: this.state.exposures.value,
      color_film: this.state.color_film,
      project_id: this.state.project_id.value,
      img_staff: this.state.img_staff.value,
      img_done: this.state.img_done.value,
      file_location: this.state.file_location.value,
      img_complete: this.state.img_complete,
      to_sub: this.state.to_sub,
      sub_received: this.state.sub_received,
      total_hours: this.state.total_hours.value,
      rejected: this.state.rejected,
      rejected_date: this.state.rejected_date.value,
      img_done_date: this.state.img_done_date.value,
      sub_received_date: this.state.sub_received_date.value,
      sub_due_date: this.state.sub_due_date.value,
      project_notes: this.state.project_notes.value,
      rect_notes: this.state.rect_notes.value,
      fixed: this.state.fixed
    };
    $.ajax({
      type: "PATCH",
      url: `/cascopt/rectifications/${this.props.match.params.id}`,
      data: { rectification: rectification }
    })
      .done((data) => {
        console.log('rectification updated!');
        this.resetFormErrors();
        this.props.history.goBack('/cascopt/rectifications');
      })
      .fail((response) => {
        alert("Please review the form for errors");
        this.setState({
          formErrors: response.responseJSON,
          formValid: false
        })
      });
  }

  addRectification() {
    const rectification = {
      models: this.state.models.value,
      flight_lines: this.state.flight_lines.value,
      exposures: this.state.exposures.value,
      color_film: this.state.color_film,
      project_id: this.state.project_id.value,
      img_staff: this.state.img_staff.value,
      img_done: this.state.img_done.value,
      file_location: this.state.file_location.value,
      img_complete: this.state.img_complete,
      to_sub: this.state.to_sub,
      sub_received: this.state.sub_received,
      total_hours: this.state.total_hours.value,
      rejected: this.state.rejected,
      rejected_date: this.state.rejected_date.value,
      img_done_date: this.state.img_done_date.value,
      sub_received_date: this.state.sub_received_date.value,
      sub_due_date: this.state.sub_due_date.value,
      project_notes: this.state.project_notes.value,
      rect_notes: this.state.rect_notes.value,
      fixed: this.state.fixed
    };
    $.post('/cascopt/rectifications', { rectification: rectification })
      .done((data) => {
        this.props.handleNewRectification(data);
        this.resetFormErrors();
      })
      .fail((response) => {
        this.setState({
          formErrors: response.responseJSON,
          formValid: false
        })
      });
  }

  deleteRectification = () => {
    if (confirm("Are you sure you want to delete this rectification?")) {
      $.ajax({
        type: "DELETE",
        url: `/cascopt/rectifications/${this.props.match.params.id}`,
      })
        .done((data) => {
          this.props.history.push('/cascopt/rectifications');
          this.resetFormErrors();
        })
        .fail((response) => {
          console.log("Rectification deletion failed!");
        });
    }
  }

  resetFormErrors() {
    this.setState({ formErrors: {} })
  }

  handleChange(e) {
    this.handleUserInput(e.target.name, e.target.value, RectificationForm.formValidations[e.target.name]);
  }

  handleSelectChange(key) {
    return function ({ value }) {
      this.setState({ [key]: value });
    }.bind(this);
  }

  render() {
    return (
      <div className="container" id="form-card">
        <h4 className="mb-4 text-4xl">
          {this.state.editing ?
            'Update Rectification ' :
            'Create Rectification '}
          for |{this.state.client_number.value} - {this.state.job_number.value}|
        </h4>
        <p>Due: {moment(this.state.due_date.value).format("MM/DD/YYYY")}</p>
        <p>Client: {this.state.client_name.value} </p>
        <p>Flight Lines: {this.state.flight_lines.value || ''} | Exposures: {this.state.exposures.value || ''} |
            Models: {this.state.models.value || ''} | Roll Number: (need) | Color: {this.state.color_film || ''}
        </p>
        <small>
          (* represents a <strong>required field</strong>)
        </small>
        <FormErrors formErrors={this.state.formErrors} />
        <form onSubmit={this.handleFormSubmit} >
          <div className="row">
            <div className="col">
              <label>Image Staff *</label>
              <input name="img_staff" placeholder="Image Staff"
                value={this.state.img_staff.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
            <div className="col">
              <label>To Sub *</label>
              <Select
                name="to_sub"
                value={this.state.to_sub}
                onChange={this.handleSelectChange("to_sub")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
            {this.state.to_sub == 'Yes' &&
            <div className="col">
              <label>Sub Received</label>
              <Select
                name="sub_received"
                value={this.state.sub_received}
                onChange={this.handleSelectChange("sub_received")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>}
            <div className="col">
              <label>Rejected</label>
              <Select
                name="rejected"
                value={this.state.rejected}
                onChange={this.handleSelectChange("rejected")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
          </div>

          <p />
          <div className="row">
            {this.state.to_sub == 'Yes' &&
            <div className="col">
              <label>Sub Due Date *</label>
              <input type="date" name="sub_due_date" placeholder="Sub Due Date"
                value={this.state.sub_due_date.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>}
            {this.state.to_sub == 'Yes' &&
            <div className="col">
              <label>Sub Received Date</label>
              <input type="date" name="sub_received_date" placeholder="Sub Received Date"
                value={this.state.sub_received_date.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>}
            {this.state.rejected == 'Yes' &&
            <div className="col">
              <label>Rejected Date</label>
              <input type="date" name="rejected_date" placeholder="Date Rejected"
                value={this.state.rejected_date.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>}
          </div>
          <p />
          <div className="row">
            <div className="col-md-4">
              <label>Image Complete</label>
              <Select
                name="img_complete"
                value={this.state.img_complete}
                onChange={this.handleSelectChange("img_complete")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
            {this.state.img_complete == 'Yes' &&
            <div className="col-md-4">
              <label>Image Done Date</label>
              <input type="date" name="img_done_date" placeholder="Image Done Date"
                value={this.state.img_done_date.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>}
            <div className="col-md-4">
              <label>File Location *</label>
              <input name="file_location" placeholder="File Location"
                value={this.state.file_location.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
          </div>
          {this.state.type.value == "PostQc" && 
          <div className="row">
            <div className="col-md-2">
              <label>Post QC - Fixed</label>
              <Select
                name="fixed"
                value={this.state.fixed}
                onChange={this.handleSelectChange("fixed")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
          </div>}
          <p />
          <div className="row">
            <div className="col">
              <label>Notes</label>
              <textarea name="rect_notes" placeholder="Notes" rows="4" cols="40"
                value={this.state.rect_notes.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
          </div>
          <p />

          <div className="row">
            <div className="col">
              <label>Project Notes</label>
              <textarea name="project_notes" placeholder="Project Notes" rows="4" cols="40"
                value={this.state.project_notes.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
          </div>

          <p />
          <input type="submit"
            value={this.state.editing ? 'Update' : 'Create'} className="btn btn-secondary btn-sm"
          />
          <a className="btn btn-danger btn-sm" onClick={this.props.history.goBack}>Cancel</a>
        </form>
        <br />
      </div>
    )
  }
}
