import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Deliveries from './Deliveries';
import DeliveryForm from './DeliveryForm';
import DeliveryView from './DeliveryView';

export default (props) => {
  return (
    <Router>
      <div>
        <Route exact path="/cascopt/deliveries" render={routeProps => <Deliveries {...props} {...routeProps}/>} />
        <Route exact path="/cascopt/deliveries/:id" render={routeProps => <DeliveryView {...props} {...routeProps}/>} />
        <Route path="/cascopt/deliveries/:id/edit" component={DeliveryForm} />
      </div>
    </Router>
  )
}
