import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

export default class Invoice extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      invoice: props.invoice,
      project: props.project
    }
  }

  static propTypes = {
    invoice: PropTypes.object
  }

  static defaultProps = {
    invoice: {}
  }

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/cascopt/invoices/${this.props.match.params.id}`,
        dataType: "JSON"
      }).done((data) => {
        this.setState({ data });
      })
    }
  }

  render() {

    const change_order_sum = this.state.project.time_and_materials.reduce((total, item) => {
      if (item.change_order) {
        return total + item.total_amount;
      } else {
        return total;
      }
    }, 0);

    const partial_billing_paid = this.state.project.partial_billings.reduce(
      (total, item) => {
        if (item.paid) {
          return total + item.amount;
        } else {
          return total;
        }
      },
      0
    );

    var invoiceCostDiff = this.state.project.cost_difference;
    if (
      this.state.project.project_type == "Survey Only" ||
      this.state.project.project_type == "Add On" ||
      this.state.project.quote_type == "Other"
    ) {
      var pmFee = 0
    } else {
      var pmFee =
        this.state.project.quote_cost - this.state.project.initial_fee;
    };

    {/* lidar level of detail pricing */ }
    var lidarFlightQuoteCost = this.state.project.lidar_cost;
    var detailPrice = parseFloat(this.state.project.detail_level);
    var processHours = parseInt(this.state.project.lidar_size);
    if (this.state.project.size_type == "Acres" && this.state.project.lidar_size <= parseInt(1000)) {
      var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 20;
    } else if (this.state.project.size_type == "Acres" && this.state.project.lidar_size >= parseInt(1000) && this.state.project.lidar_size <= parseInt(10000)) {
      var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 10;
    } else if (this.state.project.size_type == "Acres" && this.state.project.lidar_size >= parseInt(10000) && this.state.project.lidar_size <= parseInt(20000)) {
      var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 7;
    } else if (this.state.project.size_type == "Acres" && this.state.project.lidar_size >= parseInt(20000)) {
      var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 5;
    } else if (this.state.project.size_type == "Linear Feet") {
      var processTotal = parseFloat(detailPrice) * parseInt(processHours) * .25;
    }

    var lidarSubtotal = processTotal;

    {/* survey pricing */ }
    var rlsHrs = parseInt(this.state.project.rls_hrs);
    var rlsCp = parseInt(this.state.project.rls_cp);
    var rlsCost = parseInt(rlsHrs) * parseInt(rlsCp);

    var spcHrs = parseInt(this.state.project.spc_hrs);
    var spcCp = parseInt(this.state.project.spc_cp);
    var spcCost = parseInt(spcHrs) * parseInt(spcCp);

    var stHrs = parseInt(this.state.project.st_hrs);
    var stCp = parseInt(this.state.project.st_cp);
    var stCost = parseInt(stHrs) * parseInt(stCp);

    var sc2Hrs = parseInt(this.state.project.sc2_hrs);
    var sc2Cp = parseInt(this.state.project.sc2_cp);
    var sc2Cost = parseInt(sc2Hrs) * parseInt(sc2Cp);

    var sc3Hrs = parseInt(this.state.project.sc3_hrs);
    var sc3Cp = parseInt(this.state.project.sc3_cp);
    var sc3Cost = parseInt(sc3Hrs) * parseInt(sc3Cp);

    var rwPlansHrs = parseInt(this.state.project.rw_plans_hrs);
    var rwPlansCp = parseInt(this.state.project.rw_plans_cp);
    var rwPlansCost = parseInt(rwPlansHrs) * parseInt(rwPlansCp);

    var initialCost = parseInt(rwPlansCost) + parseInt(sc3Cost) + parseInt(sc2Cost) + parseInt(stCost) + parseInt(spcCost) + parseInt(rlsCost) + parseInt(this.state.project.survey_cost);

    var surveyTotal = parseInt(rwPlansCost) + parseInt(sc3Cost) + parseInt(sc2Cost) + parseInt(stCost) + parseInt(spcCost) + parseInt(rlsCost) + parseInt(this.state.project.survey_cost);

    var surveyPmFee = parseInt(this.state.project.quote_cost) - parseInt(initialCost);


    var quoteCostBeforeAdjustment = this.state.project.initial_fee + processTotal

    var bonus_percent = parseFloat(this.state.project.bonus_perc) / 100;

    var quoteWithoutBonus = parseFloat(this.state.project.flight_cost || 0) + parseFloat(this.state.project.survey_cost || 0) + parseFloat(this.state.project.lab_cost || 0) + parseFloat(this.state.project.map_cost || 0) + parseFloat(this.state.project.image_cost || 0) + parseFloat(this.state.project.lidar_cost || 0) + parseFloat(this.state.project.lidarSubtotal || 0) + parseFloat(this.state.project.survey_quote_cost || 0) + parseFloat(this.state.project.total_adjustment || 0);

    var bonusAmount = Math.ceil(bonus_percent * parseInt(quoteWithoutBonus));

    var lidarProcessingAmount = parseFloat(this.state.project.quote_cost || 0) - bonusAmount;

    var final_quote_cost = parseFloat(this.state.project.quote_cost || 0) + parseFloat(change_order_sum || 0) - parseFloat(partial_billing_paid || 0);

    return (
      <div className="card">
        <div className="container">
          <Link to={`/cascopt/invoices/${this.state.invoice.id}/edit`}>
            <button className="btn btn-sm btn-success">Edit Invoice</button>
          </Link>
          <p />
          <div id="pt-view-project-block">
            <h5>Project Details</h5>
            <b>Client Rep:</b> {this.state.project.project_manager} |{" "}
            <b>Project Manager:</b> {this.state.project.asst_pm || "n/a"} <br />
            <b>Invoice Flight First:</b>{" "}
            {this.state.project.invoice_flight || " "}
            <hr />
            <div className="row">
              <div className="col-md">
                <b>Job #:</b> {this.state.project.job_number}
                <br />
                <b>Project Due Date:</b>{" "}
                {moment(this.state.project.due_date || "").format("MM/DD/YYYY")}
                <br />
                <b>Client:</b> {this.state.project.client_name || ""}
                <br />
                <b>Proposal Date:</b>{" "}
                {moment(this.state.project.proposal_date || "").format(
                  "MM/DD/YYYY"
                )}
              </div>
              <div className="col-md">
                <b>Client Name:</b> {this.state.project.client_contact || "n/a"}
                <br />
                <b>Client Email:</b> {this.state.project.email || "n/a"}
                <br />
                <b>Address:</b> {this.state.project.address || "n/a"}
                <br />
                <b>City, State, Zip:</b> {this.state.project.city || "n/a"},{" "}
                {this.state.project.state || "n/a"}{" "}
                {this.state.project.zip || "n/a"}
                <br />
                <b>Phone:</b> {this.state.project.phone || "n/a"}
              </div>
              {this.state.project.add_billing_info == "Yes" && (
                <div className="col-md">
                  <b>Billing Client Name:</b>{" "}
                  {this.state.project.billing_contact || "n/a"}
                  <br />
                  <b>Billing Client Email:</b>{" "}
                  {this.state.project.billing_email || "n/a"}
                  <br />
                  <b>Billing Address:</b>{" "}
                  {this.state.project.billing_address || "n/a"}
                  <br />
                  <b>Billing City, State, Zip:</b>{" "}
                  {this.state.project.billing_city || "n/a"},{" "}
                  {this.state.project.billing_state || "n/a"}{" "}
                  {this.state.project.billing_zip || "n/a"}
                  <br />
                  <b>Billing Phone:</b>{" "}
                  {this.state.project.billing_phone || "n/a"}
                  <br />
                  <b>Billing Notes:</b>{" "}
                  {this.state.project.billing_notes || "n/a"}
                </div>
              )}
            </div>
            {this.state.project.use_alt_info == true && (
              <div className="row">
                <h5>
                  Alternate Client Information <small>*bill this client*</small>
                </h5>
                <div className="col-md">
                  <b>Alt. Company: </b>{" "}
                  {this.state.project.alt_company || "n/a"}
                  <br />
                  <b>Alt. Client: </b> {this.state.project.alt_client || "n/a"}
                  <b>Address: </b> {this.state.project.alt_address || "n/a"}
                  <br />
                  <b>City, State, Zip: </b>{" "}
                  {this.state.project.alt_city || "n/a"},{" "}
                  {this.state.project.alt_state || "n/a"}{" "}
                  {this.state.project.alt_zip || "n/a"}
                  <br />
                  <b>Phone: </b> {this.state.project.alt_phone || "n/a"}
                  <br />
                  <b>Email: </b> {this.state.project.alt_email || "n/a"}
                </div>
              </div>
            )}
          </div>
          <hr />
          <h5>Project Type: {this.state.project.project_type}</h5>
          <h6>Quote Type: {this.state.project.quote_type}</h6>
          <h6>Fly and Hold: {this.state.project.fly_and_hold}</h6>
          <h6>Fly and AT: {this.state.project.fly_and_at}</h6>
          <hr />
          <h5>Pricing Breakdown</h5>
          {this.state.project.project_type != "Survey Only" &&
            this.state.project.project_type != "Add On" &&
            this.state.project.project_type != "Plot Only" &&
            this.state.project.quote_type != "Other" && (
              <p>
                PM Fee:{" "}
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format("250" || "0")}
              </p>
            )}
          <form>
            {this.state.project.project_type == "Add On" && (
              <div>
                <div className="row">
                  <div className="col-md-3">
                    Flight Cost:{" "}
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format("0")}
                    <br />
                    Survey Cost:{" "}
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format("0")}
                    <br />
                    Survey Cost:{" "}
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format("0")}
                    <br />
                    Lab Cost:{" "}
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format("0")}
                    <br />
                    Map Cost:{" "}
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format("0")}
                    <br />
                  </div>
                  <div className="col-md-3">
                    Image Cost:{" "}
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format("0")}
                    <br />
                    LiDAR Flight Cost:{" "}
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format("0")}
                    <br />
                    LiDAR Processing Cost:{" "}
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(lidarSubtotal || "0")}
                    <br />
                    Initial Quote Cost:{" "}
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format("0")}
                    <br />
                    Adjustment Fee:{" "}
                    <span className="adjustment-fee">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format("0")}
                    </span>
                    <br />
                    Bonus Percentage:
                    <span> {this.state.project.bonus_perc || 0}%</span>
                    <br />
                    Final Quote Cost:{" "}
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format("0")}
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col">
                    *This project needs to be recorded but not billed to client,
                    this Add-On was done at no additional cost.
                  </div>
                </div>
              </div>
            )}
            {this.state.project.project_type != "Add On" && (
              <div className="row">
                <div className="col-md-3">
                  Flight Cost:{" "}
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(this.state.project.flight_cost || "0")}
                  <br />
                  Survey Cost:{" "}
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(this.state.project.survey_cost || "0")}
                  <br />
                  Lab Cost:{" "}
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(this.state.project.lab_cost || "0")}
                  <br />
                  Map Cost:{" "}
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(
                    this.state.project.quote_type === "LiDAR Processing"
                      ? 0
                      : this.state.project.map_cost || 0
                  )}
                  <br />
                  Image Cost:{" "}
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(this.state.project.image_cost || "0")}
                  <br />
                  LiDAR Flight Cost:{" "}
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(this.state.project.lidar_cost || "0")}
                  <br />
                  <div>
                    {this.state.project.quote_type == "LiDAR Processing" && (
                      <div>
                        LiDAR Processing Cost:{" "}
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(lidarProcessingAmount || "0")}
                        <br />
                      </div>
                    )}
                  </div>
                  <div>
                    {this.state.project.quote_type != "LiDAR Processing" && (
                      <div>
                        LiDAR Processing Cost:{" "}
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(lidarSubtotal || "0")}
                        <br />
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-3">
                  {this.state.project.quote_type == "UAV" && (
                    <div>
                      UAV Mapping Cost:{" "}
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(this.state.project.survey_quote_cost || "0")}
                    </div>
                  )}
                  {/* <br />
                  Initial Quote Cost:{" "}
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(quoteCostBeforeAdjustment || "0")} */}
                  <br />
                  Adjustment Fee:{" "}
                  <span className="adjustment-fee">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(this.state.project.total_adjustment || "0")}
                  </span>
                  <br />
                  Bonus Percentage:
                  <span>
                    {" "}
                    {this.state.project.bonus_perc || 0}% (
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(bonusAmount || "0")}
                    )
                  </span>
                  <br />
                  Initial Quote Cost:{" "}
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(this.state.project.quote_cost || "0")}
                  <br />
                  Change Order Amount:{" "}
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(change_order_sum || "0")}
                  <br />
                  Paid Partial Billing Amount:{" "}
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(partial_billing_paid || "0")}
                  <br />
                  Final Quote Cost:{" "}
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(final_quote_cost || "0")}
                </div>
                <div className="col-md-3">
                  <div>
                    {this.state.project.time_and_materials.map(
                      (item, index) =>
                        item.change_order && (
                          <div key={index}>
                            <b>Change Order:</b>
                            <br />
                            Hours: {item.hours} | Rate:{" "}
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(item.rate)}{" "}
                            | Total Amount:{" "}
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(item.total_amount)}
                          </div>
                        )
                    )}
                  </div>
                  {this.state.project.time_and_materials.some(
                    (item) => item.change_order
                  ) && (
                    <b>
                      Total Change Order Amount:{" "}
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(change_order_sum || "0")}
                    </b>
                  )}
                </div>
                <div className="col-md-3">
                  <div>
                    {this.state.project.partial_billings.map(
                      (item, index) =>
                        item.billed && (
                          <div key={index}>
                            <b>Partial Billing:</b>
                            <br />% Total Contract:{" "}
                            {item.percent_total_contract} | Amount:{" "}
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(item.amount)}{" "}
                            <br />
                            Date Paid:{" "}
                            {moment(item.date_paid || "n/a").format(
                              "MM/DD/YYYY"
                            )}
                          </div>
                        )
                    )}
                  </div>
                  {this.state.project.partial_billings.some(
                    (item) => item.paid
                  ) && (
                    <b>
                      Total Partial Billing Paid Amount:{" "}
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(partial_billing_paid || "0")}
                    </b>
                  )}
                </div>
                {this.state.project.invoice_flight == "Yes" && (
                  <div className="col-md-6">
                    Cost After Flight is Invoiced: {invoiceCostDiff}
                  </div>
                )}
              </div>
            )}
            <hr />
            <small>
              *If the Client PM doesn't match the Client Name in the Project
              Details box, contact the PM!
            </small>
            <hr />
            <div className="row">
              <div className="col-md-3">
                <label>Actual Flight Sub Cost</label>
                <p>{this.state.invoice.flight_sub_cost || "0"}</p>
              </div>
              <div className="col-md-3">
                <label>Actual Map Sub Cost</label>
                <p>{this.state.invoice.map_sub_cost || "0"}</p>
              </div>
              <div className="col-md-3">
                <label>Actual Survey Sub Cost</label>
                <p>{this.state.invoice.survey_sub_cost || "0"}</p>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-3">
                <label>Purchase Order #</label>
                <p>{this.state.project.purchase_order || "None"}</p>
              </div>
              <div className="col-md-3">
                <label>Invoice Flight First</label>
                <p>{this.state.project.invoice_flight || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Client PM</label>
                <br />
                <p>{this.state.invoice.invoice_contact || "n/a"}</p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Invoice #</label>
                <p>{this.state.invoice.invoice_number || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Invoiced</label>
                <p>{this.state.invoice.invoiced || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Invoice Date</label>
                <p>
                  {moment(this.state.invoice.invoice_date || "n/a").format(
                    "MM/DD/YYYY"
                  )}
                </p>
              </div>
              <div className="col-md-3">
                <label>Invoice Due Date</label>
                <p>
                  {moment(this.state.invoice.due_date || "n/a").format(
                    "MM/DD/YYYY"
                  )}
                </p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Paid</label>
                <p>{this.state.invoice.paid || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Date Paid</label>
                <p>
                  {moment(this.state.invoice.date_paid || "n/a").format(
                    "MM/DD/YYYY"
                  )}
                </p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Billing Info</label>
                <p>{this.state.invoice.billing_info || "n/a"}</p>
              </div>
            </div>
            <p />
            {this.state.project.project_type != "Survey Only" && (
              <h5>Flight Invoice Information (if applicable)</h5>
            )}
            {this.state.project.project_type == "Survey Only" && (
              <h5>Survey Invoice Information (if applicable)</h5>
            )}
            <hr />
            <div className="row">
              <div className="col-md-3">
                <label>Alt. Invoice #</label>
                <p>{this.state.invoice.alt_invoice_number || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Alt. Invoice Date</label>
                <p>
                  {moment(this.state.invoice.alt_invoice_date || "n/a").format(
                    "MM/DD/YYYY"
                  )}
                </p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Alt. Invoiced</label>
                <p>{this.state.invoice.alt_invoiced || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Alt. Invoice Paid</label>
                <p>{this.state.invoice.alt_paid || "n/a"}</p>
              </div>
              {this.state.invoice.alt_paid == "Yes" && (
                <div className="col-md-3">
                  <label>Alt. Invoice Date Paid</label>
                  <p>
                    {moment(this.state.invoice.alt_paid_date || "n/a").format(
                      "MM/DD/YYYY"
                    )}
                  </p>
                </div>
              )}
            </div>
            <p />
            {this.state.project.proposal_pdf && (
              <div className="row">
                <h5>PDF of Proposal</h5>
                <div className="col-md-3">
                  <label>PDF Proposal File</label>
                  <p />
                  <img src={this.state.project.proposal_pdf || ""} />
                  <p />
                  <a
                    href={this.state.project.proposal_pdf_download}
                    className="btn btn-info btn-sm"
                  >
                    Download
                  </a>
                  <br />
                  <small>
                    <strong>Filename:</strong>
                    <br />
                    {this.state.project.proposal_pdf_file || ""}
                  </small>
                </div>
              </div>
            )}
          </form>
          <p />
        </div>
      </div>
    );
  }
}
