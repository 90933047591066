import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

export default class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: props.edit,
      project: props.project,
      compilation: props.compilation,
      survey: props.survey,
      quality_control: props.quality_control,
      isHidden: false,
    };
  }

  static propTypes = {
    edit: PropTypes.object,
  };

  static defaultProps = {
    edit: {},
  };

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/cascopt/edits/${this.props.match.params.id}`,
        dataType: "JSON",
      }).done((data) => {
        this.setState({ data });
      });
    }
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }

  render() {
    const problemColor = (color) => {
      const problemYes = this.state.edit.problem_comp == "Yes";
      const problemNo = this.state.edit.problem_comp == "No";

      if (problemYes) {
        return "red";
      }
      if (problemNo) {
        return "green";
      }
    };
    return (
      <div className="card">
        <div className="container">
          <a href={`/cascopt/edits`} className="stage-record">
            Back to Edit Dashboard
          </a>
          <hr />
          <Link to={`/cascopt/edits/${this.state.edit.id}/edit`}>
            <button className="btn btn-sm btn-success">Edit</button>
          </Link>
          <p />
          <button
            onClick={this.toggleHidden.bind(this)}
            title="Click to expand/hide create company form"
            className="btn btn-secondary btn-sm"
          >
            {this.state.isHidden ? (
              <span>
                Show Project Details{" "}
                <i className="fa fa-plus-circle" aria-hidden="true"></i>
              </span>
            ) : (
              <span>
                Hide Project Details{" "}
                <i className="fa fa-minus-circle" aria-hidden="true"></i>
              </span>
            )}
          </button>
          <p />
          {!this.state.isHidden && (
            <div id="pt-view-project-block">
              <h5>Project Details</h5>
              <b>Client Rep:</b> {this.state.project.project_manager} |{" "}
              <b>Project Manager:</b> {this.state.project.asst_pm || "n/a"}
              <hr />
              <div className="row">
                <div className="col-md-6">
                  <b>Job:</b> {this.state.project.job_name || ""} |{" "}
                  {this.state.project.client_number || ""}-
                  {this.state.project.job_number || ""}
                  <br />
                  <b>Edge Job #:</b> {this.state.project.edge_job_number || "n/a"}
                  <br />
                  <b>Due:</b>{" "}
                  {moment(this.state.project.due_date || "").format(
                    "MM/DD/YYYY"
                  )}
                  <br />
                  <b>Client:</b> {this.state.project.client_name || ""}
                  <br />
                  <b>Survey Staff:</b> {this.state.survey.survey_staff || ""}
                  <br />
                  <b>Deliverables:</b>{" "}
                  {this.state.project.deliverables ||
                    "Contact PM for deliverables info"}
                  <br />
                </div>
                <div className="col-md-6">
                  <b># of Exposures:</b> {this.state.project.exposures || ""}
                  <br />
                  <b>Actual Flight Date:</b>{" "}
                  {moment(this.state.project.flight_done || "").format(
                    "MM/DD/YYYY"
                  ) || "Project has not been flown"}
                  <br />
                  <b>Units:</b> {this.state.project.units || "n/a"}
                  <br />
                  <b>Project Type:</b>{" "}
                  {this.state.project.project_type || "n/a"}
                  <br />
                  <b>CAD Version:</b> {this.state.project.version}
                </div>
              </div>
            </div>
          )}
          <br />
          Last Updated: {this.state.project.last_updated}
          <hr />
          <form>
            <p>
              <strong>
                ***If editing is not required, mark the 'Is Editing Required?'
                field to 'No' and submit the form to have the project move past
                Editing!***
              </strong>
            </p>
            <div className="row">
              <div className="col-md-3">
                <label>Is Editing Required?</label>
                <p>{this.state.edit.no_edit_required || "n/a"}</p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md">
                <label>Edit Staff</label>
                <p>{this.state.edit.edit_staff || "n/a"}</p>
              </div>
              <div className="col-md">
                <label>Edit Status</label>
                <p>{this.state.edit.edit_status || "n/a"}</p>
              </div>
              <div className="col-md">
                <label>Edit Check</label>
                <p>{this.state.edit.edit_check || "n/a"}</p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-4">
                <label>Translated</label>
                <p>{this.state.edit.translated || "n/a"}</p>
              </div>
              <div className="col-md-4">
                <label>Custom Translation</label>
                <br />
                {this.state.project.custom_trans == "Yes" && (
                  <strong>This project requires custom translation.</strong>
                )}
                {this.state.project.custom_trans == "No" && (
                  <strong>
                    This project does not require custom translation.
                  </strong>
                )}
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-4">
                <label>Problem w/ Compilation</label>
                <p style={{color: problemColor(this.state.edit.problem_comp.value)}}>{this.state.edit.problem_comp || "n/a"}</p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-4">
                <label>Has an XML been generated for this project?</label>
                <p>{this.state.edit.xml_generated || "n/a"}</p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-4">
                <label>From Survey tab: Needs Shifted</label>
                <p>{this.state.survey.needs_shifted || "n/a"}</p>
              </div>
              <div className="col-md-4">
                <label>From Survey tab: Known System</label>
                <p>{this.state.survey.known_system || "n/a"}</p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-4">
                <label>Edit Done</label>
                <p>{this.state.edit.edit_done || "n/a"}</p>
              </div>
              <div className="col-md-4">
                <label>Edit Date Done</label>
                <p>
                  {moment(this.state.edit.edit_date_done || "").format(
                    "MM/DD/YYYY"
                  )}
                </p>
              </div>
            </div>
            <p />
            {this.state.quality_control.type == "PostQc" &&
            <div className="form-group row">
              <div className="col-md-4">
                <label>Post QC - Fixed</label>
                <p>{this.state.edit.fixed || "n/a"}</p>
              </div>
            </div>}
            <p />
            <div className="row">
              <div className="col">
                <label>Edit Notes</label>
                <p>{this.state.project.edit_notes || "n/a"}</p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>QC Notes</label>
                <p>{this.state.project.qc_notes || "n/a"}</p>
              </div>
            </div>
            <p />
            {this.state.project.lidar_scope && (
              <div className="row">
                <div className="col">
                  <label>LiDAR Scope</label>
                  <p>{this.state.project.lidar_scope || "n/a"}</p>
                </div>
              </div>
            )}
          </form>
          <hr />
          {this.state.project.project_content && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Project Notes from Proposal</label>
                  <p>{this.state.project.project_content || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.mapping_content && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Mapping Notes from Proposal</label>
                  <p>{this.state.project.mapping_content || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.deliverable_notes && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Deliverable Notes</label>
                  <p>{this.state.project.deliverable_notes || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.project_notes && (
            <div>
              <h5>Project Information</h5>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Notes from Project</label>
                  <p>{this.state.project.project_notes || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.survey_notes && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Notes from Survey</label>
                  <p>{this.state.project.survey_notes || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.flights_notes && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Notes from Flight</label>
                  <p>{this.state.project.flights_notes || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.drone_notes && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Notes from Drone</label>
                  <p>{this.state.project.drone_notes || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.scan_notes && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Notes from Scan</label>
                  <p>{this.state.project.scan_notes || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.aero_notes && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Notes from AT</label>
                  <p>{this.state.project.aero_notes || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.lidar_notes && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Notes from LiDAR</label>
                  <p>{this.state.project.lidar_notes || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.comp_notes && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Notes from Compilation</label>
                  <p>{this.state.project.comp_notes || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.rect_notes && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Notes from Rectification</label>
                  <p>{this.state.project.rect_notes || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.project_purpose && (
            <div>
              <h5>Project Purpose Information</h5>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Project Purpose</label>
                  <p>{this.state.project.project_purpose || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          <p />
          <h5>Compilation Information</h5>
          <hr />
          <div className="row">
            <div className="col">
              <label>Notes from Compilation</label>
              <p>{this.state.project.comp_notes || "n/a"}</p>
            </div>
          </div>
          <p />
          {this.state.compilation.comp_staff && <label>Comp Staff</label>}
          <div className="row">
            {this.state.compilation.comp_staff && (
              <div className="col-md-3">
                <p>{this.state.compilation.comp_staff || "n/a"}</p>
              </div>
            )}
          </div>
          <p />
          {this.state.compilation.sub_name && <label>Sub Name</label>}
          <div className="row">
            {this.state.compilation.sub_name && (
              <div className="col-md-3">
                <p>{this.state.compilation.sub_name || "n/a"}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
