import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import moment from 'moment';

export default class Archives extends React.Component {
  static propTypes = {
    archives: PropTypes.array
  }

  static defaultProps = {
    archives: []
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      archives: this.props.archives,
      isHidden: true,
      isHiddenNotComplete: false,
      isLoading: false
    }
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden
    })
  }

  toggleHiddenNotComplete() {
    this.setState({
      isHiddenNotComplete: !this.state.isHiddenNotComplete
    })
  }

  componentDidMount() {
    if (this.props.match) {
      this.setState({ isLoading: true });
      $.ajax({
        type: "GET",
        url: "/archived",
        dataType: "JSON"
      }).done((data) => {
        this.setState({ archives: data, isLoading: false });
      })
    }
  }

  render() {
    const { archives, isLoading } = this.state;
    if (isLoading) {
      return <div className="loader"></div>;
    }

    return (
      <div>
        <Header />
        <p />
        {!this.state.isHiddenNotComplete && (
          <ReactTable
            key={archives.id}
            data={archives}
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id]) === filter.value
            }
            defaultPageSize={100}
            style={{
              height: "800px",
              textAlign: "center",
            }}
            columns={[
              {
                Header: "Project Info",
                columns: [
                  {
                    Header: "Client Rep",
                    accessor: "project_manager",
                    filterMethod: (filter, row) => {
                      if (filter.value === "all") {
                        return true;
                      }
                      if (filter.value === "phil") {
                        return row[filter.id] == "Philip Gershkovich";
                      }
                      if (filter.value === "emily") {
                        return row[filter.id] == "Emily Martin";
                      }
                      if (filter.value === "zachary") {
                        return row[filter.id] == "Zachary Radel";
                      }
                      if (filter.value === "pete") {
                        return row[filter.id] == "Pete Priestner";
                      }
                      if (filter.value === "dave") {
                        return row[filter.id] == "Dave Major";
                      }
                      if (filter.value === "dennis") {
                        return row[filter.id] == "Dennis Harmon";
                      }
                      if (filter.value === "jim") {
                        return row[filter.id] == "Jim Crume";
                      }
                      if (filter.value === "zach") {
                        return row[filter.id] == "Zachary Bearley";
                      }
                      if (filter.value === "sandy") {
                        return row[filter.id] == "Sandy Little";
                      }
                      if (filter.value === "tom") {
                        return row[filter.id] == "Tom Warren";
                      }
                      if (filter.value === "grayson") {
                        return row[filter.id] == "Grayson Ing";
                      }
                      if (filter.value === "mike") {
                        return row[filter.id] == "Mike Ing";
                      }
                      if (filter.value === "brett") {
                        return row[filter.id] === "Brett Nowacki";
                      }
                    },
                    Filter: ({ filter, onChange }) => (
                      <select
                        onChange={(event) => onChange(event.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : "all"}
                      >
                        <option value="all">Select User</option>
                        <option value="brett">Brett</option>
                        <option value="dave">Dave</option>
                        <option value="dennis">Dennis</option>
                        <option value="emily">Emily</option>
                        <option value="grayson">Grayson</option>
                        <option value="jim">Jim</option>
                        <option value="mike">Mike</option>
                        <option value="pete">Pete</option>
                        <option value="phil">Phil</option>
                        <option value="sandy">Sandy</option>
                        <option value="tom">Tom</option>
                        <option value="zachary">Zachary Radel</option>
                        <option value="zach">Zach</option>
                      </select>
                    ),
                  },
                  {
                    Header: "Project Manager",
                    accessor: "asst_pm",
                    filterMethod: (filter, row) => {
                      if (filter.value === "all") {
                        return true;
                      }
                      if (filter.value === "phil") {
                        return row[filter.id] == "Philip Gershkovich";
                      }
                      if (filter.value === "emily") {
                        return row[filter.id] == "Emily Martin";
                      }
                      if (filter.value === "zachary") {
                        return row[filter.id] == "Zachary Radel";
                      }
                      if (filter.value === "pete") {
                        return row[filter.id] == "Pete Priestner";
                      }
                      if (filter.value === "dave") {
                        return row[filter.id] == "Dave Major";
                      }
                      if (filter.value === "dennis") {
                        return row[filter.id] == "Dennis Harmon";
                      }
                      if (filter.value === "jim") {
                        return row[filter.id] == "Jim Crume";
                      }
                      if (filter.value === "zach") {
                        return row[filter.id] == "Zachary Bearley";
                      }
                      if (filter.value === "sandy") {
                        return row[filter.id] == "Sandy Little";
                      }
                      if (filter.value === "tom") {
                        return row[filter.id] == "Tom Warren";
                      }
                      if (filter.value === "grayson") {
                        return row[filter.id] == "Grayson Ing";
                      }
                      if (filter.value === "mike") {
                        return row[filter.id] == "Mike Ing";
                      }
                      if (filter.value === "camden") {
                        return row[filter.id] == "Camden Kopchik";
                      }
                      if (filter.value === "brett") {
                        return row[filter.id] === "Brett Nowacki";
                      }
                    },
                    Filter: ({ filter, onChange }) => (
                      <select
                        onChange={(event) => onChange(event.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : "all"}
                      >
                        <option value="all">Select User</option>
                        <option value="brett">Brett</option>
                        <option value="camden">Camden</option>
                        <option value="dave">Dave</option>
                        <option value="dennis">Dennis</option>
                        <option value="emily">Emily</option>
                        <option value="grayson">Grayson</option>
                        <option value="jim">Jim</option>
                        <option value="mike">Mike</option>
                        <option value="pete">Pete</option>
                        <option value="phil">Phil</option>
                        <option value="sandy">Sandy</option>
                        <option value="tom">Tom</option>
                        <option value="zachary">Zachary Radel</option>
                        <option value="zach">Zach</option>
                      </select>
                    ),
                  },
                  {
                    Header: "Job Number",
                    accessor: "job_number",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id].includes(filter.value),
                    Cell: (props) => (
                      <a
                        href={`/cascopt/projects/${props.original.id}`}
                        className="view-record"
                      >
                        {props.original.job_number}
                      </a>
                    ),
                  },
                  {
                    Header: "Project Name",
                    accessor: "job_name",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .includes(filter.value.toLowerCase()),
                    Cell: (props) => (
                      <a
                        href={`/cascopt/projects/${props.original.id}`}
                        className="view-record"
                      >
                        {props.value}
                      </a>
                    ),
                  },
                  {
                    Header: "Client Name",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .includes(filter.value.toLowerCase()),
                    accessor: "client_name",
                  },
                  {
                    Header: "Client Number",
                    accessor: "client_number",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id].includes(filter.value),
                  },
                  {
                    Header: "Flight Crew",
                    accessor: "flight_crew",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .includes(filter.value.toLowerCase()),
                  },
                  {
                    Header: "Location",
                    accessor: "project_location",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .includes(filter.value.toLowerCase()),
                  },
                  {
                    Header: "Project Type",
                    accessor: "project_type",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .includes(filter.value.toLowerCase()),
                  },
                ],
              },
            ]}
            SubComponent={(v) => (
              <div className="text-left" style={{ padding: "10px" }}>
                <b>Project Notes:</b>
                <br />
                {v.original.project_notes || "None."}
                <br />
                <b>Notes from Client:</b>
                <br />
                {v.original.client_notes || "None."}
                <br />
              </div>
            )}
            defaultSorted={[
              {
                id: "due_date",
                asc: true,
              },
            ]}
            className="-striped -highlight"
          />
        )}
      </div>
    );
  }

}
