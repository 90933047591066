import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

export default class Delivery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      delivery: props.delivery,
      project: props.project,
      isHidden: false,
    };
  }

  static propTypes = {
    delivery: PropTypes.object,
  };

  static defaultProps = {
    delivery: {},
  };

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/cascopt/deliveries/${this.props.match.params.id}`,
        dataType: "JSON",
      }).done((data) => {
        this.setState({ data });
      });
    }
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }

  render() {
    return (
      <div className="card">
        <div className="container">
          <a href={`/cascopt/deliveries`} className="stage-record">
            Back to Delivery Dashboard
          </a>
          <hr />
          <Link to={`/cascopt/deliveries/${this.state.delivery.id}/edit`}>
            <button className="btn btn-sm btn-success">Edit Delivery</button>
          </Link>
          <p />
          <button
            onClick={this.toggleHidden.bind(this)}
            title="Click to expand/hide create company form"
            className="btn btn-secondary btn-sm"
          >
            {this.state.isHidden ? (
              <span>
                Show Project Details{" "}
                <i className="fa fa-plus-circle" aria-hidden="true"></i>
              </span>
            ) : (
              <span>
                Hide Project Details{" "}
                <i className="fa fa-minus-circle" aria-hidden="true"></i>
              </span>
            )}
          </button>
          <p />
          {!this.state.isHidden && (
            <p id="pt-view-project-block">
              <h5>Project Details</h5>
              <b>Client Rep:</b> {this.state.project.project_manager} |{" "}
              <b>Project Manager:</b> {this.state.project.asst_pm || "n/a"}
              <hr />
              <b>Job:</b> {this.state.project.job_name || ""} |{" "}
              {this.state.project.client_number || ""}-
              {this.state.project.job_number || ""}
              <br />
              <b>Due:</b>{" "}
              {moment(this.state.project.due_date || "").format("MM/DD/YYYY")}
              <br />
              <b>Client:</b> {this.state.project.client_name || ""}
              <br />
              <b>Deliverables:</b>{" "}
              {this.state.project.deliverables ||
                "Contact PM for deliverables info"}
              <br />
              <b>Units:</b> {this.state.project.units || "n/a"}
              <br />
              <b>Project Type:</b> {this.state.project.project_type || "n/a"}
            </p>
          )}
          <br />
          Last Updated: {this.state.project.last_updated}
          <p />
          <div className="row">
            <div className="col-md-3">
              <label>FTP Name</label>
              <br />
              {this.state.project.ftp_name ||
                "None supplied in company record."}
            </div>
            <div className="col-md-3">
              <label>FTP Password</label>
              <br />
              {this.state.project.ftp_password ||
                "None supplied in company record."}
            </div>
          </div>
          <hr />
          <form>
            <div className="row">
              <div className="col-md-3">
                <label>Delivery Method</label>
                <p>{this.state.delivery.delivery_method || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Delivered</label>
                <p>{this.state.delivery.delivered || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Date Delivered</label>
                <p>{this.state.delivery.date_delivered || "Not delivered"}</p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Invoice Stage Sent To</label>
                <p>{this.state.delivery.invoice_stage || "n/a"}</p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Delivery Notes</label>
                <p>{this.state.project.delivery_notes || "n/a"}</p>
              </div>
            </div>
          </form>
          <hr />
          {this.state.project.project_notes && (
            <div>
              <h5>Project Information</h5>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Notes from Project</label>
                  <p>{this.state.project.project_notes || "n/a"}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
