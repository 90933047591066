import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import moment from 'moment';

export default class InvoiceSurveyFirst extends React.Component {
    static propTypes = {
        invoice_survey_first: PropTypes.array
    }

    static defaultProps = {
        invoice_survey_first: []
    }

    constructor(props, railsContext) {
        super(props)
        this.state = {
            projects: this.props.invoice_survey_first,
            isHidden: true,
            isHiddenNotComplete: false,
            isLoading: false
        }
        console.log(props);
    }

    toggleHidden() {
        this.setState({
            isHidden: !this.state.isHidden
        })
    }

    toggleHiddenNotComplete() {
        this.setState({
            isHiddenNotComplete: !this.state.isHiddenNotComplete
        })
    }

    componentDidMount() {
        if (this.props.match) {
            this.setState({ isLoading: true });
            $.ajax({
                type: "GET",
                url: "/cascopt/invoice_survey_first",
                dataType: "JSON"
            }).done((data) => {
                this.setState({ projects: data, isLoading: false });
            })
        }
    }

    render() {
        const { projects, isLoading } = this.state;
        if (isLoading) {
            return <div className="loader"></div>;
        }

        return (
            <div>
                <p />
                {!this.state.isHiddenNotComplete && <ReactTable key={projects.id}
                    data={projects}
                    filterable
                    defaultFilterMethod={(filter, row) =>
                        String(row[filter.id]) === filter.value}
                    defaultPageSize={100}
                    sortable={true}
                    style={{
                        height: "800px",
                        textAlign: "center"
                    }}
                    columns={[
                        {
                            Header: "Project Info",
                            columns: [
                                {
                                    Header: "Job Number",
                                    accessor: "project.job_number",
                                    Filter: ({ filter, onChange }) => (
                                        <input
                                            placeholder={"Search for..."}
                                            onChange={event => onChange(event.target.value)}
                                            value={filter ? filter.value : ''}
                                            style={{
                                                width: '100%',
                                                color: 'black',
                                            }}
                                        />
                                    ),
                                    filterMethod: (filter, row) =>
                                        row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
                                    Cell: props => <a href={`/cascopt/projects/${props.original.id}`} className="view-record" >{props.original.job_number}</a>
                                },
                                {
                                    Header: "Project Name",
                                    accessor: "job_name",
                                    width: 300,
                                    Filter: ({ filter, onChange }) => (
                                        <input
                                            placeholder={"Search for..."}
                                            onChange={event => onChange(event.target.value)}
                                            value={filter ? filter.value : ''}
                                            style={{
                                                width: '100%',
                                                color: 'black',
                                            }}
                                        />
                                    ),
                                    filterMethod: (filter, row) =>
                                        row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
                                },
                                {
                                    Header: "Client #",
                                    Filter: ({ filter, onChange }) => (
                                        <input
                                            placeholder={"Search for..."}
                                            onChange={event => onChange(event.target.value)}
                                            value={filter ? filter.value : ''}
                                            style={{
                                                width: '100%',
                                                color: 'black',
                                            }}
                                        />
                                    ),
                                    filterMethod: (filter, row) =>
                                        row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
                                    accessor: "client_number"
                                },
                                {
                                    Header: "Client Name",
                                    Filter: ({ filter, onChange }) => (
                                        <input
                                            placeholder={"Search for..."}
                                            onChange={event => onChange(event.target.value)}
                                            value={filter ? filter.value : ''}
                                            style={{
                                                width: '100%',
                                                color: 'black',
                                            }}
                                        />
                                    ),
                                    filterMethod: (filter, row) =>
                                        row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
                                    accessor: "client_name"
                                }
                            ]
                        }
                    ]}
                    defaultSorted={[
                        {
                            id: "job_number",
                            asc: true
                        }
                    ]}
                    className="-striped -highlight"
                />}
            </div>
        )
    }
}
