import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import moment from 'moment';

export default class ProjectsQuickGlance extends React.Component {
  static propTypes = {
    projects: PropTypes.array
  }

  static defaultProps = {
    projects: []
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      projects: this.props.projects,
      isHidden: true,
      isHiddenNotComplete: false,
      isLoading: false
    }
    console.log(props);
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden
    })
  }

  toggleHiddenNotComplete() {
    this.setState({
      isHiddenNotComplete: !this.state.isHiddenNotComplete
    })
  }

  componentDidMount() {
    if (this.props.match) {
      this.setState({ isLoading: true });
      $.ajax({
        type: "GET",
        url: "/cascopt/accounting/quick_glance",
        dataType: "JSON"
      }).done((data) => {
        this.setState({ projects: data, isLoading: false });
      })
    }
  }

  render() {
    const { projects, isLoading } = this.state;
    if (isLoading) {
      return <div className="loader"></div>;
    }

    return (
      <div>
        <p />
        {!this.state.isHiddenNotComplete && <ReactTable key={projects.id}
          data={projects}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value}
          defaultPageSize={100}
          sortable={true}
          style={{
            height: "800px",
            textAlign: "center"
          }}
          columns={[
            {
              Header: "Project Info",
              columns: [
                {
                  Header: "Job Number",
                  accessor: "job_number",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={event => onChange(event.target.value)}
                      value={filter ? filter.value : ''}
                      style={{
                        width: '100%',
                        color: 'black',
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id].includes(filter.value),
                  Cell: props => <a href={`/cascopt/projects/${props.original.id}`} className="view-record" >{props.original.job_number}</a>
                },
                {
                  Header: "Project Name",
                  accessor: "job_name",
                  width: 300,
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={event => onChange(event.target.value)}
                      value={filter ? filter.value : ''}
                      style={{
                        width: '100%',
                        color: 'black',
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
                },
                {
                  Header: "Client #",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={event => onChange(event.target.value)}
                      value={filter ? filter.value : ''}
                      style={{
                        width: '100%',
                        color: 'black',
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id].includes(filter.value),
                  accessor: "client_number"
                },
                {
                  Header: "Client Name",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={event => onChange(event.target.value)}
                      value={filter ? filter.value : ''}
                      style={{
                        width: '100%',
                        color: 'black',
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
                  accessor: "client_name"
                },
                {
                  Header: "Flight Quote",
                  filterable: false,
                  Cell: props => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.original.quote.flight_quote)
                },
                {
                  Header: "Flight Crew",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={event => onChange(event.target.value)}
                      value={filter ? filter.value : ''}
                      style={{
                        width: '100%',
                        color: 'black',
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
                  accessor: "quote.flight_sub"
                },
                {
                  Header: "Survey Quote",
                  filterable: false,
                  Cell: props => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.original.quote.survey_quote)
                },
                {
                  Header: "Survey Crew",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={event => onChange(event.target.value)}
                      value={filter ? filter.value : ''}
                      style={{
                        width: '100%',
                        color: 'black',
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
                  accessor: "quote.survey_sub"
                }
              ]
            }
          ]}
          defaultSorted={[
            {
              id: "job_number",
              asc: true
            }
          ]}
          className="-striped -highlight"
        />}
      </div>
    )
  }
}
