import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import 'react-select/dist/react-select.css';

export default class ProposalForm extends React.Component {
  static propTypes = {
    handleNewProposal: PropTypes.func
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      project_scope: "n/a",
      lidar_processing_notes: "",
      flight_notes: "",
      survey_notes: "",
      imagery_notes: "",
      mapping_notes: "",
      lidar_notes: "",
      notes: "",
      user_id: "",
      opportunity_id: "",
      specification_id: "",
      quote_id: "",
      proposal_date: "",
      asst_pm: "",
      project_manager: "",
      project_manager_options: [
        { value: "Sandy Little", label: "Sandy Little" },
        { value: "Emily Martin", label: "Emily Martin" },
        { value: "Brett Nowacki", label: "Brett Nowacki" },
        { value: "Bryan Baker", label: "Bryan Baker" },
        { value: "Dave Major", label: "Dave Major" },
        { value: "Dennis Harmon", label: "Dennis Harmon" },
        { value: "Grayson Ing", label: "Grayson Ing" },
        { value: "Jim Crume", label: "Jim Crume" },
        { value: "Mike Ing", label: "Mike Ing" },
        { value: "Pete Priestner", label: "Pete Priestner" },
        { value: "Philip Gershkovich", label: "Philip Gershkovich" },
        { value: "Tom Warren", label: "Tom Warren" },
        { value: "Tyler Seabase", label: "Tyler Seabase" },
        { value: "Zachary Bearley", label: "Zachary Bearley" },
        { value: "Zachary Radel", label: "Zachary Radel" },
      ],
      deliverables: "",
      deliverables_options: [
        {
          value: "2D AutoCAD of the Planimetrics",
          label: "2D AutoCAD of the Planimetrics",
        },
        {
          value: "Civil 3D Surface file",
          label: "Civil 3D Surface file",
        },
        {
          value: "2D Planimetrics and Contours Civil3D (DWG)",
          label: "2D Planimetrics and Contours Civil3D (DWG)",
        },
        {
          value: "3D DTM and Contours Civil 3D (DWG)",
          label: "3D DTM and Contours Civil 3D (DWG)",
        },
        {
          value: "XML Surface file",
          label: "XML Surface file",
        },
        {
          value: "ASCII format files of the DTM",
          label: "ASCII format files of the DTM",
        },
        {
          value: "3D Autocad file of the DTM",
          label: "3D Autocad file of the DTM",
        },
        {
          value:
            "PLS CADD -Classified LAZ, .XYZ, .BAK (provide FCL before flight)",
          label:
            "PLS CADD -Classified LAZ, .XYZ, .BAK (provide FCL before flight)",
        },
        {
          value: "Orthorectified Imagery JPG",
          label: "Orthorectified Imagery JPG",
        },
        {
          value: "Orthorectified Imagery TIF",
          label: "Orthorectified Imagery TIF",
        },
        {
          value: "Orthorectified Imagery ECW",
          label: "Orthorectified Imagery ECW",
        },
        {
          value: "Orthorectified Imagery SID format (3-band)",
          label: "Orthorectified Imagery SID format (3-band)",
        },
        {
          value: "Orthorectified Imagery SID format (4-band)",
          label: "Orthorectified Imagery SID format (4-band)",
        },
        {
          value: "Orthorectified Imagery/GEN 2",
          label: "Orthorectified Imagery/GEN 2",
        },
        {
          value: "Georeferenced Imagery",
          label: "Georeferenced Imagery",
        },
        {
          value: "IR Imagery",
          label: "IR Imagery",
        },
        {
          value: "Obliques",
          label: "Obliques",
        },
        {
          value: "Raw Orthos",
          label: "Raw Orthos",
        },
        {
          value: "Weather Station data",
          label: "Weather Station data",
        },
        {
          value: "Unregistered Lidar Data",
          label: "Unregistered Lidar Data",
        },
        {
          value: "Unclassified Registered Lidar",
          label: "Unclassified Registered Lidar",
        },
        {
          value: "Ground Classified LAS/LAZ",
          label: "Ground Classified LAS/LAZ",
        },
        {
          value: "Plan Classified LAS/LAZ - (Provide classification list)",
          label: "Plan Classified LAS/LAZ - (Provide classification list)",
        },
        {
          value: "Bare Earth Lidar",
          label: "Bare Earth Lidar",
        },
        {
          value: "Ground only LAS",
          label: "Ground only LAS",
        },
        {
          value: "Major Breaklines",
          label: "Major Breaklines",
        },
        {
          value: "Tin Surface",
          label: "Tin Surface",
        },
        {
          value: "Ground control report",
          label: "Ground control report",
        },
        {
          value: "Tree heights",
          label: "Tree heights",
        },
        {
          value: "Tree canopy raster",
          label: "Tree canopy raster",
        },
        {
          value: "DEM raster 1ft grid",
          label: "DEM raster 1ft grid",
        },
        {
          value: "DEM raster 2ft grid",
          label: "DEM raster 2ft grid",
        },
        {
          value: "DSM raster 1ft grid",
          label: "DSM raster 1ft grid",
        },
        {
          value: "DSM raster 2ft grid",
          label: "DSM raster 2ft grid",
        },
        {
          value: "2D and 3D Planimetrics and Contours Microstation (DGN)",
          label: "2D and 3D Planimetrics and Contours Microstation (DGN)",
        },
        {
          value: "Surface File Microstation OpenRoads/InRoads (DGN) (ORD)",
          label: "Surface File Microstation OpenRoads/InRoads (DGN) (ORD)",
        },
        {
          value: "Microstation 2D Files (DGN)",
          label: "Microstation 2D Files (DGN)",
        },
        {
          value: "Microstation 3D Files (DGN)",
          label: "Microstation 3D Files (DGN)",
        },
        {
          value: "Sign and Seal of the contour mapping",
          label: "Sign and Seal of the contour mapping",
        },
        {
          value: "Sign and Seal",
          label: "Sign and Seal",
        },
      ],
      pm_sig: "",
      cas_office: "Phoenix",
      proposal_type: "",
      pm_hours: "0",
      working_days: "",
      editing: false,
    };
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/proposals/${this.props.match.params.id}`,
        dataType: "JSON"
      }).done((data) => {
        this.setState({
          asst_pm: data.proposal.asst_pm,
          project_scope: data.proposal.project_scope,
          lidar_processing_notes: data.proposal.lidar_processing_notes,
          flight_notes: data.proposal.flight_notes,
          survey_notes: data.proposal.survey_notes,
          imagery_notes: data.proposal.imagery_notes,
          mapping_notes: data.proposal.mapping_notes,
          lidar_notes: data.proposal.lidar_notes,
          notes: data.proposal.notes,
          proposal_type: data.proposal.proposal_type,
          user_id: data.proposal.user_id,
          opportunity_id: data.proposal.opportunity_id,
          specification_id: data.proposal.specification_id,
          quote_id: data.proposal.quote_id,
          proposal_date: data.proposal.proposal_date,
          project_manager: data.proposal.project_manager,
          pm_sig: data.proposal.pm_sig,
          cas_office: data.proposal.cas_office,
          pm_hours: data.proposal.pm_hours,
          working_days: data.proposal.working_days,
          deliverables: data.proposal.deliverables,
          editing: this.props.match.path === '/proposals/:id/edit'
        });
      });
    }
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.state.editing ?
      this.updateProposal() :
      this.addProposal();
  }

  updateProposal() {
    const proposal = {
      asst_pm: this.state.asst_pm,
      lidar_processing_notes: this.state.lidar_processing_notes,
      project_scope: this.state.project_scope,
      flight_notes: this.state.flight_notes,
      survey_notes: this.state.survey_notes,
      imagery_notes: this.state.imagery_notes,
      mapping_notes: this.state.mapping_notes,
      lidar_notes: this.state.lidar_notes,
      notes: this.state.notes,
      proposal_type: this.props.proposalType,
      user_id: this.props.userId,
      opportunity_id: this.props.oppId,
      specification_id: this.props.specId,
      quote_id: this.props.quoteId,
      proposal_date: this.state.proposal_date,
      project_manager: this.state.project_manager,
      pm_sig: this.state.pm_sig,
      cas_office: this.state.cas_office,
      pm_hours: this.state.pm_hours,
      working_days: this.state.working_days,
      deliverables: this.state.deliverables,
    };
    $.ajax({
      type: "PATCH",
      url: `/proposals/${this.props.match.params.id}`,
      data: { proposal: proposal }
    })
      .done((data) => {
        console.log('Proposal updated!');
      })
      .fail((response) => {
        console.log("did not work");
        alert("Please review the form for errors");
      });
  }

  addProposal() {
    const proposal = {
      asst_pm: this.state.asst_pm,
      lidar_processing_notes: this.state.lidar_processing_notes,
      project_scope: this.state.project_scope,
      flight_notes: this.state.flight_notes,
      survey_notes: this.state.survey_notes,
      imagery_notes: this.state.imagery_notes,
      mapping_notes: this.state.mapping_notes,
      lidar_notes: this.state.lidar_notes,
      notes: this.state.notes,
      proposal_type: this.props.proposalType,
      user_id: this.props.userId,
      opportunity_id: this.props.oppId,
      specification_id: this.props.specId,
      quote_id: this.props.quoteId,
      proposal_date: this.state.proposal_date,
      project_manager: this.props.clientRep,
      pm_sig: this.state.pm_sig,
      cas_office: this.state.cas_office,
      pm_hours: this.state.pm_hours,
      working_days: this.state.working_days,
      deliverables: this.state.deliverables,
    };
    $.post('/proposals', { proposal: proposal })
      .done((data) => {
        this.props.handleNewProposal(data);
        console.log("Proposal Successfully Created!");
      })
      .fail((response) => {
        console.log("did not work");
        alert("Please review the form for errors");
      });
  }

  deleteQuote = () => {
    if (confirm("Are you sure you want to delete this proposal?")) {
      $.ajax({
        type: "DELETE",
        url: `/proposals/${this.props.match.params.id}`,
      })
        .done((data) => {
          this.props.history.push('/proposals');
        })
        .fail((response) => {
          console.log("Proposal deletion failed!");
        });
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSelectChange(key) {
    return function ({ value }) {
      this.setState({ [key]: value });
    }.bind(this);
  }

  handleMultiSelectChange(key) {
    return function (array) {
      this.setState({ [key]: array });
    }.bind(this);
  }

  render() {

    let today = new Date().toISOString().substr(0, 10);
    this.state.proposal_date = today;

    return (
      <div className="card">
        <div className="card-body">
          <h4 className="mb-4 text-4xl">
            {this.state.editing ? "Update Proposal" : "Create Proposal"}
          </h4>
          <small>
            (* represents a <strong>required field</strong>)
          </small>
          <form onSubmit={this.handleFormSubmit}>
            <div className="row">
              <div className="col-md-3">
                <label>Proposal Type *</label>
                <Select
                  name="proposal_type"
                  disabled={true}
                  value={this.props.proposalType}
                  options={[
                    { value: "ADOT", label: "ADOT" },
                    { value: "Film", label: "Film" },
                    {
                      value: "Film - Photo Geodetic",
                      label: "Film - Photo Geodetic",
                    },
                    { value: "Film (Image Only)", label: "Film (Image Only)" },
                    {
                      value: "Film (Image Only) - Photo Geodetic",
                      label: "Film (Image Only) - Photo Geodetic",
                    },
                    {
                      value: "Film (Mapping Only)",
                      label: "Film (Mapping Only)",
                    },
                    {
                      value: "Film (Mapping Only) - Photo Geodetic",
                      label: "Film (Mapping Only) - Photo Geodetic",
                    },
                    { value: "Flight", label: "Flight" },
                    { value: "NAAS", label: "NAAS" },
                    { value: "UAV", label: "UAV" },
                    { value: "Digital", label: "Digital" },
                    {
                      value: "Digital - Photo Geodetic",
                      label: "Digital - Photo Geodetic",
                    },
                    {
                      value: "Digital (Image Only)",
                      label: "Digital (Image Only)",
                    },
                    {
                      value: "Digital (Image Only) - Photo Geodetic",
                      label: "Digital (Image Only) - Photo Geodetic",
                    },
                    {
                      value: "Digital (Mapping Only)",
                      label: "Digital (Mapping Only)",
                    },
                    {
                      value: "Digital (Mapping Only) - Photo Geodetic",
                      label: "Digital (Mapping Only) - Photo Geodetic",
                    },
                    { value: "LiDAR", label: "LiDAR" },
                    { value: "LiDAR Processing", label: "LiDAR Processing" },
                    { value: "Scan", label: "Scan" },
                    { value: "Plot", label: "Plot" },
                    { value: "Survey", label: "Survey" },
                    { value: "Other", label: "Other" },
                    { value: "Construction", label: "Construction" },
                  ]}
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Proposal Date *</label>
                <input
                  type="date"
                  name="proposal_date"
                  placeholder="Proposal Date"
                  required
                  value={this.state.proposal_date}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-md-3">
                <label>Project Manager *</label>
                <Select.Creatable
                  required
                  allowCreate={true}
                  name="asst_pm"
                  value={this.state.asst_pm}
                  onChange={this.handleSelectChange("asst_pm")}
                  options={this.state.project_manager_options}
                />
              </div>
              <div className="col-md-3">
                <label>Client Rep *</label>
                <Select.Creatable
                  required
                  name="project_manager"
                  value={this.props.clientRep}
                  onChange={this.handleSelectChange("project_manager")}
                  options={this.state.project_manager_options}
                />
              </div>
              <div className="col-md-3">
                <label>CAS Office *</label>
                <Select
                  required
                  name="cas_office"
                  value={this.state.cas_office}
                  onChange={this.handleSelectChange("cas_office")}
                  options={[
                    { value: "Phoenix", label: "Phoenix" },
                    { value: "Tucson", label: "Tucson" },
                    { value: "Kylertown", label: "Kylertown" },
                    { value: "Sacramento", label: "Sacramento" },
                    { value: "NAAS", label: "NAAS" },
                    { value: "Photo Geodetic", label: "Photo Geodetic" },
                    { value: "Philadelphia", label: "Philadelphia" },
                    { value: "Bakersfield", label: "Bakersfield" },
                  ]}
                />
              </div>
            </div>
            <p />
            {this.props.proposalType != "Construction" && (
              <div>
                <div className="row">
                  {this.props.proposalType != "Survey" && (
                    <div className="col">
                      <label>Working Days *</label>
                      <input
                        type="text"
                        name="working_days"
                        required
                        placeholder="i.e. 10 working days from the receipt of the flight date and control being received"
                        value={
                          this.state.working_days ||
                          "X working days from the receipt of flight and verification of control"
                        }
                        onChange={this.handleChange}
                        className="form-control"
                      />
                    </div>
                  )}
                  {this.props.proposalType == "Survey" && (
                    <div className="col">
                      <label>Working Days *</label>
                      <input
                        type="text"
                        name="working_days"
                        required
                        placeholder="i.e. 10 working days from the receipt of the flight date and control being received"
                        value={this.state.working_days || "X working days"}
                        onChange={this.handleChange}
                        className="form-control"
                      />
                    </div>
                  )}
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <div>
                      <label>Deliverables *</label>
                      <Select.Creatable
                        name="deliverables"
                        value={this.state.deliverables}
                        allowCreate={true}
                        multi
                        simpleValue
                        delimiter=", "
                        onChange={this.handleMultiSelectChange("deliverables")}
                        options={this.state.deliverables_options}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <p />
            {this.props.proposalType == "Other" && (
              <div className="row">
                <div className="col">
                  <label>Project Scope</label>
                  <textarea
                    type="text"
                    name="project_scope"
                    placeholder="Project Scope"
                    value={this.state.project_scope}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            )}
            {/* <p />
            <h5>*THIS METHOD OF ENTERING NOTES IS DEPRECATED AND WILL BE REMOVED SOON.</h5>
            <p>Please use the new Notes system by clicking the 'Add Notes' button on the Proposal overview page.</p>
            <div className="row">
              <div className="col">
                <label>
                  Notes (any notes entered here will appear on the generated
                  proposal for the client!) *
                </label>
                <textarea
                  type="text"
                  name="notes"
                  placeholder="Proposal Notes"
                  value={this.state.notes}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>
                  Flight Notes (any notes entered here will appear on the
                  generated proposal for the client!) *
                </label>
                <textarea
                  type="text"
                  name="flight_notes"
                  placeholder="Flight Notes"
                  value={this.state.flight_notes}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>
                  Survey Notes (any notes entered here will appear on the
                  generated proposal for the client!) *
                </label>
                <textarea
                  type="text"
                  name="survey_notes"
                  placeholder="Survey Notes"
                  value={this.state.survey_notes}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>
                  Imagery Notes (any notes entered here will appear on the
                  generated proposal for the client!) *
                </label>
                <textarea
                  type="text"
                  name="imagery_notes"
                  placeholder="Imagery Notes"
                  value={this.state.imagery_notes}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>
                  Mapping Notes (any notes entered here will appear on the
                  generated proposal for the client!) *
                </label>
                <textarea
                  type="text"
                  name="mapping_notes"
                  placeholder="Mapping Notes"
                  value={this.state.mapping_notes}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>
                  LiDAR Flight Notes (any notes entered here will appear on the
                  generated proposal for the client!) *
                </label>
                <textarea
                  type="text"
                  name="lidar_notes"
                  placeholder="LiDAR Notes"
                  value={this.state.lidar_notes}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>
                  LiDAR Processing Notes (any notes entered here will appear on
                  the generated proposal for the client!) *
                </label>
                <textarea
                  type="text"
                  name="lidar_processing_notes"
                  placeholder="LiDAR Processing Notes"
                  value={this.state.lidar_processing_notes}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <hr /> */}
            <p />
            <input
              type="submit"
              value={this.state.editing ? "Update" : "Next"}
              className="btn btn-secondary btn-sm"
            />
          </form>
          <br />
        </div>
      </div>
    );
  }

}
