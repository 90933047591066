import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Rectifications from './Rectifications';
import RectificationForm from './RectificationForm';
import RectView from './RectView';

export default (props) => {
  return (
    <Router>
      <div>
        <Route exact path="/cascopt/rectifications" render={routeProps => <Rectifications {...props} {...routeProps}/>} />
        <Route exact path="/cascopt/rectifications/:id" render={routeProps => <RectView {...props} {...routeProps}/>} />
        <Route path="/cascopt/rectifications/:id/edit" render={routeProps => <RectificationForm {...props} {...routeProps}/>} />
      </div>
    </Router>
  )
}
