/* eslint-disable react/prefer-stateless-function */
import React from 'react';

export default class ImageArchives extends React.Component {
  render() {
    return (
      <div>
        <h3 id="header">Image Archives</h3>
        <hr />
      </div>
    );
  }
}
