import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';

export default class Flight extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      flight: props.flight,
      project: props.project,
      isHidden: false
    }
  }

  static propTypes = {
    flight: PropTypes.object
  }

  static defaultProps = {
    flight: {}
  }

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/cascopt/flights/${this.props.match.params.id}`,
        dataType: "JSON"
      }).done((data) => {
        this.setState({ data });
      })
    }
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden
    })
  }

  render() {
    const problemColor = (color) => {
      const problemYes = this.state.flight.problem_survey == "Yes";
      const problemNo = this.state.flight.problem_survey == "No";

      if (problemYes) {
        return "red";
      }
      if (problemNo) {
        return "green";
      }
    };

    return (
      <div className="card">
        <div className="container">
          <a href={`/cascopt/flights`} className="stage-record">
            Back to Flight Dashboard
          </a>
          <hr />
          <Link to={`/cascopt/flights/${this.state.flight.id}/edit`}>
            <button className="btn btn-sm btn-success">Edit Flight</button>
          </Link>
          <p />
          <button
            onClick={this.toggleHidden.bind(this)}
            title="Click to expand/hide create company form"
            className="btn btn-secondary btn-sm"
          >
            {this.state.isHidden ? (
              <span>
                Show Project Details{" "}
                <i className="fa fa-plus-circle" aria-hidden="true"></i>
              </span>
            ) : (
              <span>
                Hide Project Details{" "}
                <i className="fa fa-minus-circle" aria-hidden="true"></i>
              </span>
            )}
          </button>
          <p />
          {!this.state.isHidden && (
            <p id="pt-view-project-block">
              <h5>Project Details</h5>
              <b>Client Rep:</b> {this.state.project.project_manager} |{" "}
              <b>Project Manager:</b> {this.state.project.asst_pm || "n/a"}
              <hr />
              <div className="form-group row">
                <div className="col-md-6">
                  <b>Job:</b> {this.state.project.job_name || ""} |{" "}
                  {this.state.project.client_number || ""}-
                  {this.state.project.job_number || ""}
                  <br />
                  <b>Edge Job #:</b>{" "}
                  {this.state.project.edge_job_number || "n/a"}
                  <br />
                  <b>Due:</b>{" "}
                  {moment(this.state.project.due_date || "").format(
                    "MM/DD/YYYY"
                  )}
                  <br />
                  <b>Client:</b> {this.state.project.client_name || ""}
                  <br />
                  <b>Deliverables:</b>{" "}
                  {this.state.project.deliverables ||
                    "Contact PM for deliverables info"}
                  <br />
                  <b>Project Location:</b>{" "}
                  {this.state.project.project_location || ""}
                  <br />
                  <b>Flight Crew:</b> {this.state.project.flight_crew || ""}
                  <br />
                  <b>Est Flight Date:</b>{" "}
                  {moment(this.state.project.est_flight_date || "").format(
                    "MM/DD/YYYY"
                  )}
                </div>
                <div className="col-md-6">
                  <b>Color Film:</b> {this.state.project.color_film || ""}
                  <br />
                  <b>Flight Lines:</b> {this.state.project.flight_lines || ""}
                  <br />
                  <b>Exposures:</b> {this.state.project.exposures || ""}
                  <br />
                  <b>Stereo PRS:</b> {this.state.project.stereo_prs || ""}
                  <br />
                  <b>Units:</b> {this.state.project.units || "n/a"}
                  <br />
                  <b>Project Type:</b>{" "}
                  {this.state.project.project_type || "n/a"}
                </div>
              </div>
            </p>
          )}
          <br />
          Last Updated: {this.state.project.last_updated}
          <hr />
          <div className="row">
            <div className="col-md-3">
              <label>Flight to Use</label>
              <p>{this.state.flight.flight_to_use}</p>
            </div>
          </div>
          <hr />
          <form>
            <div className="row">
              <div className="col-md-3">
                <label>Flown</label>
                <p>{this.state.flight.flown || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Actual Flight Date</label>
                <p>
                  {moment(this.state.flight.flight_date).format("MM/DD/YYYY")}
                </p>
              </div>
              <div className="col-md-3">
                <label>UAV Flight</label>
                <p>{this.state.flight.uav_flight || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Problem w/ Survey</label>
                <p
                  style={{
                    color: problemColor(this.state.flight.problem_survey.value),
                  }}
                >
                  {this.state.flight.problem_survey || "n/a"}
                </p>
              </div>
            </div>
            <p />
            {this.state.flight.uav_flight == "Yes" && (
              <div>
                <h5>UAV Information</h5>
                <hr />
                <div className="row">
                  <div className="col-md-3">
                    <label>Aircraft</label>
                    <p>{this.state.flight.aircraft || "n/a"}</p>
                  </div>
                  <div className="col-md-3">
                    <label>Camera</label>
                    <p>{this.state.flight.camera || "n/a"}</p>
                  </div>
                  <div className="col-md-3">
                    <label>Altitude</label>
                    <p>{this.state.flight.altitude || "n/a"}</p>
                  </div>
                  <div className="col-md-3">
                    <label>GSD</label>
                    <p>{this.state.flight.gsd || "n/a"}</p>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col-md-3">
                    <label>Photo Quality</label>
                    <p>{this.state.flight.photo_quality || "n/a"}</p>
                  </div>
                  <div className="col-md-3">
                    <label>Processing Software</label>
                    <p>{this.state.flight.processing_software || "n/a"}</p>
                  </div>
                  <div className="col-md-3">
                    <label>Deliverables</label>
                    <p>{this.state.flight.deliverable || "n/a"}</p>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col-md-3">
                    <label>Video</label>
                    <p>{this.state.flight.video || "n/a"}</p>
                  </div>
                  <div className="col-md-3">
                    <label>Photos Uploaded</label>
                    <p>{this.state.flight.photos_uploaded || "n/a"}</p>
                  </div>
                  <div className="col-md-3">
                    <label>No Control</label>
                    <p>{this.state.flight.no_control || "n/a"}</p>
                  </div>
                  <div className="col-md-3">
                    <label>Obliques</label>
                    <p>{this.state.flight.obliques || "n/a"}</p>
                  </div>
                </div>
              </div>
            )}
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Secondary Flight</label>
                <p>{this.state.flight.second_flight || "n/a"}</p>
              </div>
              {this.state.flight.sent_gpc && (
                <div className="col-md-3">
                  <label>Sent GCP (old field, discontinued)</label>
                  <p>
                    {moment(this.state.flight.sent_gpc).format("MM/DD/YYYY")}
                  </p>
                </div>
              )}
              <div className="col-md-3">
                <label>Sent GCP (new field)</label>
                <p>
                  {moment(this.state.project.sent_gcp).format("MM/DD/YYYY")}
                </p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Hobbs Time (NAAS)</label>
                <p>{this.state.flight.hobbs_time || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Maintenance Time (NAAS)</label>
                <p>{this.state.flight.maintenance_time || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>N # (NAAS)</label>
                <p>{this.state.flight.tail_number || "n/a"}</p>
              </div>
            </div>
            <p />
            {this.state.flight.second_flight == "Yes" && (
              <div>
                <h5>Secondary Flight Information</h5>
                <hr />
                <div className="row">
                  <div className="col-md-3">
                    <label>Imagery or LiDAR</label>
                    <p>{this.state.flight.imagery_lidar || "n/a"}</p>
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col-md-3">
                    <label>Flown</label>
                    <p>{this.state.flight.second_flown || "n/a"}</p>
                  </div>
                  <div className="col-md-3">
                    <label>Actual Flight Date</label>
                    <p>
                      {moment(this.state.flight.second_flight_date).format(
                        "MM/DD/YYYY"
                      )}
                    </p>
                  </div>
                  <div className="col-md-3">
                    <label>UAV Flight</label>
                    <p>{this.state.flight.second_uav_flight || "n/a"}</p>
                  </div>
                  <div className="col-md-3">
                    <label>Problem w/ Survey</label>
                    <p>{this.state.flight.second_problem_survey || "n/a"}</p>
                  </div>
                </div>
              </div>
            )}
            <p />
            <div className="row">
              <div className="col">
                <label>Flight Notes</label>
                <p>{this.state.project.flights_notes || "n/a"}</p>
              </div>
            </div>
            <p />
            {this.state.project.lidar_scope && (
              <div className="row">
                <div className="col">
                  <label>LiDAR Scope</label>
                  <p>{this.state.project.lidar_scope || "n/a"}</p>
                </div>
              </div>
            )}
            <hr />
            {this.state.project.project_notes && (
              <div>
                <h5>Project Information</h5>
                <hr />
                <div className="row">
                  <div className="col">
                    <label>Notes from Project</label>
                    <p>{this.state.project.project_notes || "n/a"}</p>
                  </div>
                </div>
                <p />
              </div>
            )}
            {this.state.project.survey_notes && (
              <div>
                <div className="row">
                  <div className="col">
                    <label>Notes from Survey</label>
                    <p>{this.state.project.survey_notes || "n/a"}</p>
                  </div>
                </div>
                <p />
              </div>
            )}
            {this.state.project.project_purpose && (
              <div>
                <h5>Project Purpose Information</h5>
                <hr />
                <div className="row">
                  <div className="col">
                    <label>Project Purpose</label>
                    <p>{this.state.project.project_purpose || "n/a"}</p>
                  </div>
                </div>
                <p />
              </div>
            )}
          </form>
          <br />
        </div>
      </div>
    );
  }
}
