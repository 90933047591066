import React from 'react';
import AdotQuoteForm from './AdotQuoteForm';
import FilmQuoteForm from './FilmQuoteForm';
import DigitalQuoteForm from './DigitalQuoteForm';
import LidarQuoteForm from './LidarQuoteForm';
import LidarProcessingQuoteForm from './LidarProcessingQuoteForm';
import ScanQuoteForm from './ScanQuoteForm';
import PlotQuoteForm from './PlotQuoteForm';
import SurveyQuoteForm from './SurveyQuoteForm';
import OtherQuoteForm from './OtherQuoteForm';
import FlightQuoteForm from './FlightQuoteForm';
import DroneQuoteForm from './DroneQuoteForm';
import FilmMapOnlyQuoteForm from './FilmMapOnlyQuoteForm';
import FilmImageOnlyQuoteForm from './FilmImageOnlyQuoteForm';
import DigitalImageOnlyQuoteForm from './DigitalImageOnlyQuoteForm';
import DigitalMapOnlyQuoteForm from './DigitalMapOnlyQuoteForm';
import PgFilmQuoteForm from './PgFilmQuoteForm';
import PgDigitalQuoteForm from './PgDigitalQuoteForm';
import ConstructionQuoteForm from './ConstructionQuoteForm';
import NaasQuoteForm from './NaasQuoteForm';

const getType = (quote_type) => {
  switch (quote_type) {
    case 'Film':
      return FilmQuoteForm;
    case 'Film (Image Only)':
      return FilmImageOnlyQuoteForm;
    case 'Film (Mapping Only)':
      return FilmMapOnlyQuoteForm;
    case 'Film (Image Only) - Photo Geodetic':
      return FilmImageOnlyQuoteForm;
    case 'Film (Mapping Only) - Photo Geodetic':
      return FilmMapOnlyQuoteForm;
    case 'Digital':
      return DigitalQuoteForm;
    case 'Digital (Image Only)':
      return DigitalImageOnlyQuoteForm;
    case 'Digital (Mapping Only)':
      return DigitalMapOnlyQuoteForm;
    case 'Digital (Image Only) - Photo Geodetic':
      return DigitalImageOnlyQuoteForm;
    case 'Digital (Mapping Only) - Photo Geodetic':
      return DigitalMapOnlyQuoteForm;
    case 'LiDAR':
      return LidarQuoteForm;
    case 'LiDAR Processing':
      return LidarProcessingQuoteForm;
    case 'Scan':
      return ScanQuoteForm;
    case 'Plot':
      return PlotQuoteForm;
    case 'Survey':
      return SurveyQuoteForm;
    case 'Other':
      return OtherQuoteForm;
    case 'Flight':
      return FlightQuoteForm;
    case 'UAV':
      return DroneQuoteForm;
    case 'ADOT':
      return AdotQuoteForm;
    case 'Film - Photo Geodetic':
      return PgFilmQuoteForm;
    case 'Digital - Photo Geodetic':
      return PgDigitalQuoteForm;
    case 'Construction':
      return ConstructionQuoteForm;
    case 'NAAS':
      return NaasQuoteForm;
    default:
      return null;
  }
};

const QuoteType = (props) => {
  const QuotingForm = getType(props.quote.quote_type);
  if (!QuotingForm) return null;
  return (
    <QuotingForm {...props} />
  );
};

export default QuoteType;
