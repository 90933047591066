import React from "react";
import PropTypes from "prop-types";
import AltHeader from "./AltHeader";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import moment from "moment";

export default class ProjectsNoPdf extends React.Component {
  static propTypes = {
    projects: PropTypes.array,
  };

  static defaultProps = {
    projects: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      projects: this.props.projects,
      isHidden: true,
      isHiddenNotComplete: false,
      isLoading: false,
    };
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }

  toggleHiddenNotComplete() {
    this.setState({
      isHiddenNotComplete: !this.state.isHiddenNotComplete,
    });
  }

  componentDidMount() {
    if (this.props.match) {
      this.setState({ isLoading: true });
      $.ajax({
        type: "GET",
        url: "/cascopt/projects",
        dataType: "JSON",
      }).done((data) => {
        this.setState({ projects: data, isLoading: false });
      });
    }
  }

  render() {
    const pickDateColor = (date) => {
      const dueDate = new moment(date);
      const yellowDate = new moment(date).subtract(5, "days");
      const now = moment();

      if (now < yellowDate) {
        return "black";
      }
      if (now < dueDate) {
        return "blue";
      }
      return "red";
    };

    const { projects, isLoading } = this.state;
    if (isLoading) {
      return <div className="loader"></div>;
    }

    return (
      <div>
        <div className="row">
          <div className="col-md-8">
            <AltHeader />
          </div>
        </div>
        <hr />
        <p />
        {!this.state.isHiddenNotComplete && (
          <ReactTable
            key={projects.id}
            data={projects}
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id]) === filter.value
            }
            defaultPageSize={100}
            sortable={true}
            style={{
              height: "800px",
              textAlign: "center",
            }}
            columns={[
              {
                Header: "Project Info",
                columns: [
                  {
                    Header: "Client Rep",
                    accessor: "project_manager",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .includes(filter.value.toLowerCase()),
                  },
                  {
                    Header: "Project Manager",
                    accessor: "asst_pm",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .includes(filter.value.toLowerCase()),
                  },
                  {
                    Header: "Job Number",
                    accessor: "job_number",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id].includes(filter.value),
                    Cell: (props) => (
                      <a
                        href={`/cascopt/projects/${props.original.id}`}
                        className="view-record"
                      >
                        {props.original.job_number}
                      </a>
                    ),
                  },
                  {
                    Header: "Project Name",
                    accessor: "job_name",
                    width: 300,
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .includes(filter.value.toLowerCase()),
                    Cell: (props) => (
                      <a
                        href={`/cascopt/projects/${props.original.id}`}
                        className="view-record"
                      >
                        {props.value}
                      </a>
                    ),
                  },
                  {
                    Header: "Company",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .includes(filter.value.toLowerCase()),
                    accessor: "client_name",
                  },
                  {
                    Header: "Stage",
                    accessor: "stage",
                    filterMethod: (filter, row) => {
                      if (filter.value === "all") {
                        return true;
                      }
                      if (filter.value === "not_started") {
                        return row[filter.id] == "Not Started";
                      }
                      if (filter.value === "survey") {
                        return row[filter.id] == "Survey";
                      }
                      if (filter.value === "flight") {
                        return row[filter.id] == "Flight";
                      }
                      if (filter.value === "scan") {
                        return row[filter.id] == "Scan";
                      }
                      if (filter.value === "at") {
                        return row[filter.id] == "AT";
                      }
                      if (filter.value === "lidar") {
                        return row[filter.id] == "Lidar";
                      }
                      if (filter.value === "dsm") {
                        return row[filter.id] == "DSM";
                      }
                      if (filter.value === "compilation") {
                        return row[filter.id] == "Compilation";
                      }
                      if (filter.value === "edit") {
                        return row[filter.id] == "Edit";
                      }
                      if (filter.value === "qc") {
                        return row[filter.id] == "QC";
                      }
                      if (filter.value === "plot") {
                        return row[filter.id] == "Plot";
                      }
                      if (filter.value === "delivery") {
                        return row[filter.id] == "Delivery";
                      }
                    },
                    Filter: ({ filter, onChange }) => (
                      <select
                        onChange={(event) => onChange(event.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : "all"}
                      >
                        <option value="all">All Stages</option>
                        <option value="not_started">Not Started</option>
                        <option value="survey">Survey</option>
                        <option value="flight">Flight</option>
                        <option value="scan">Scan</option>
                        <option value="at">AT</option>
                        <option value="lidar">LiDAR</option>
                        <option value="dsm">DSM</option>
                        <option value="compilation">Compilation</option>
                        <option value="edit">Edit</option>
                        <option value="qc">QC</option>
                        <option value="plot">Plot</option>
                        <option value="delivery">Delivery</option>
                      </select>
                    ),
                  },
                ],
              },
            ]}
            defaultSorted={[
              {
                id: "due_date",
                asc: true,
              },
            ]}
            className="-striped -highlight"
          />
        )}
      </div>
    );
  }
}
