import React from 'react';
import AdotSpecForm from './AdotSpecForm';
import FilmSpecForm from './FilmSpecForm';
import DigitalSpecForm from './DigitalSpecForm';
import LidarSpecForm from './LidarSpecForm';
import LidarProcessingSpecForm from './LidarProcessingSpecForm';
import ScanSpecForm from './ScanSpecForm';
import PlotSpecForm from './PlotSpecForm';
import SurveySpecForm from './SurveySpecForm';
import OtherSpecForm from './OtherSpecForm';
import FlightSpecForm from './FlightSpecForm';
import DroneSpecForm from './DroneSpecForm';
import PgFilmSpecForm from './PgFilmSpecForm';
import PgDigitalSpecForm from './PgDigitalSpecForm';
import ConstructionSpec from './ConstructionSpec';
import NaasSpecForm from './NaasSpecForm';

const getType = (spec_type) => {
  switch (spec_type) {
    case 'Film':
      return FilmSpecForm;
    case 'Film (Mapping Only)':
      return FilmSpecForm;
    case 'Film (Image Only)':
      return FilmSpecForm;
    case 'Film (Mapping Only) - Photo Geodetic':
      return FilmSpecForm;
    case 'Film (Image Only) - Photo Geodetic':
      return FilmSpecForm;
    case 'Digital':
      return DigitalSpecForm;
    case 'Digital (Mapping Only)':
      return DigitalSpecForm;
    case 'Digital (Image Only)':
      return DigitalSpecForm;
    case 'Digital (Mapping Only) - Photo Geodetic':
      return DigitalSpecForm;
    case 'Digital (Image Only) - Photo Geodetic':
      return DigitalSpecForm;
    case 'LiDAR':
      return LidarSpecForm;
    case 'LiDAR Processing':
      return LidarProcessingSpecForm;
    case 'Scan':
      return ScanSpecForm;
    case 'Plot':
      return PlotSpecForm;
    case 'Survey':
      return SurveySpecForm;
    case 'Other':
      return OtherSpecForm;
    case 'Flight':
      return FlightSpecForm;
    case 'UAV':
      return DroneSpecForm;
    case 'ADOT':
      return AdotSpecForm;
    case 'Film - Photo Geodetic':
      return PgFilmSpecForm;
    case 'Digital - Photo Geodetic':
      return PgDigitalSpecForm;
    case 'Construction':
      return ConstructionSpec;
    case 'NAAS':
      return NaasSpecForm;
    default:
      return null;
  }
};

const SpecType = (props) => {
  const SpecForm = getType(props.specification.spec_type);
  if (!SpecForm) return null;
  return (
    <SpecForm {...props} />
  );
};

export default SpecType;
