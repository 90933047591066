import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Scans from './Scans';
import ScanForm from './ScanForm';
import ScanView from './ScanView';

export default (props) => {
  return (
    <Router>
      <div>
        <Route exact path="/cascopt/scans" render={routeProps => <Scans {...props} {...routeProps}/>} />
        <Route exact path="/cascopt/scans/:id" render={routeProps => <ScanView {...props} {...routeProps}/>} />
        <Route path="/cascopt/scans/:id/edit" component={ScanForm} />
      </div>
    </Router>
  )
}
