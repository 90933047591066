import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import update from 'immutability-helper';
import AdotQuoteForm from './AdotQuoteForm';
import FilmQuoteForm from './FilmQuoteForm';
import DigitalQuoteForm from './DigitalQuoteForm';
import LidarQuoteForm from './LidarQuoteForm';
import LidarProcessingQuoteForm from './LidarProcessingQuoteForm';
import ScanQuoteForm from './ScanQuoteForm';
import PlotQuoteForm from './PlotQuoteForm';
import SurveyQuoteForm from './SurveyQuoteForm';
import OtherQuoteForm from './OtherQuoteForm';
import FlightQuoteForm from './FlightQuoteForm';
import DroneQuoteForm from './DroneQuoteForm';
import FilmImageOnlyQuoteForm from './FilmImageOnlyQuoteForm';
import FilmMapOnlyQuoteForm from './FilmMapOnlyQuoteForm';
import DigitalImageOnlyForm from './DigitalImageOnlyForm';
import DigitalMapOnlyForm from './DigitalMapOnlyForm';
import DigitalLidarQuoteForm from './DigitalLidarQuoteForm';
import PgFilmQuoteForm from './PgFilmQuoteForm';
import PgDigitalQuoteForm from './PgDigitalQuoteForm';
import PgDigitalLidarQuoteForm from './PgDigitalLidarQuoteForm';
import ConstructionQuoteForm from './ConstructionQuoteForm';
import NaasQuoteForm from './NaasQuoteForm';
import moment from 'moment';

export default class Specification extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      plots: props.specification.plots ? Object.keys(props.specification.plots).map(key => props.specification.plots[key]) : [],
      specification: props.specification,
      opportunity: props.opportunity,
      quotes: [],
      isHidden: true,
    }
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden
    })
  }

  static propTypes = {
    specification: PropTypes.object
  }

  static defaultProps = {
    specification: {}
  }

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/specifications/${this.props.match.params.id}`,
        dataType: "JSON"
      }).done((data) => {
        this.setState({ data });
      })
    }
  }

  addNewQuote = (quote) => {
    const quotes = update(this.state.quotes, { $push: [quote] });
    this.setState({
      quotes: quotes.sort(function (a, b) {
        return new Date(a.created_at) - new Date(b.created_at);
      })
    });
  }

  render() {

    const { plots } = this.state;

    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-3">
              <label>Date</label>
              <input
                type="text"
                readOnly
                value={moment(this.state.opportunity.date || "").format(
                  "MM/DD/YYYY"
                )}
                className="form-control"
              />
            </div>
            <div className="col-md-3">
              <label>Location</label>
              <input
                type="text"
                readOnly
                value={this.state.opportunity.location || ""}
                className="form-control"
              />
            </div>
            {this.state.specification.spec_type != "Construction" && (
              <div>
                <br />
                <div className="col-md-3">
                  <label>Project Units</label>
                  <input
                    type="text"
                    readOnly
                    value={
                      this.state.specification.project_units ||
                      "None Specified, update form if needed."
                    }
                    className="form-control"
                  />
                </div>
                <br />
                <div className="col-md-3">
                  <label>Projection</label>
                  <input
                    type="text"
                    readOnly
                    value={
                      this.state.specification.projection || "None Specified"
                    }
                    className="form-control"
                  />
                </div>
              </div>
            )}
          </div>
          <p />
          {this.state.specification.spec_type == "Film (Mapping Only)" && (
            <div>
              <div className="row">
                <div className="col-md-3">
                  <label>Spec Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.spec_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Mapping Only</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.mapping_only || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Image Only</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.image_only || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Map Scale</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_scale || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Map PRS</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_prs || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>CI</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.ci || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Units</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.units || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Map Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_type || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Film Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.film_type || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>File Format</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.file_format || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Version</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.version || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Models</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Flight Lines</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.flight_lines || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Exposures</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                {this.state.specification.diff_img_flight == "Yes" && (
                  <div className="col-md-3">
                    <label>Diff. Img. Flight</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.diff_img_flight || ""}
                      className="form-control"
                    />
                  </div>
                )}
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.specification.notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          )}
          {this.state.specification.spec_type ==
            "Film (Mapping Only) - Photo Geodetic" && (
            <div>
              <div className="row">
                <div className="col-md-3">
                  <label>Spec Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.spec_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Mapping Only</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.mapping_only || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Image Only</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.image_only || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Map Scale</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_scale || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Map PRS</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_prs || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>CI</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.ci || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Units</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.units || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Map Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_type || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Film Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.film_type || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>File Format</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.file_format || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Version</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.version || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Models</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Flight Lines</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.flight_lines || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Exposures</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                {this.state.specification.diff_img_flight == "Yes" && (
                  <div className="col-md-3">
                    <label>Diff. Img. Flight</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.diff_img_flight || ""}
                      className="form-control"
                    />
                  </div>
                )}
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.specification.notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          )}
          {this.state.specification.spec_type == "Film (Image Only)" && (
            <div>
              <div className="row">
                <div className="col-md-3">
                  <label>Spec Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.spec_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Mapping Only</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.mapping_only || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Image Only</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.image_only || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Map Scale</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_scale || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Map PRS</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_prs || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>CI</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.ci || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Units</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.units || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Map Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_type || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Film Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.film_type || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>File Format</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.file_format || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Version</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.version || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Models</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Flight Lines</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.flight_lines || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Exposures</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                {this.state.specification.diff_img_flight == "Yes" && (
                  <div className="col-md-3">
                    <label>Diff. Img. Flight</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.diff_img_flight || ""}
                      className="form-control"
                    />
                  </div>
                )}
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.specification.notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          )}
          {this.state.specification.spec_type ==
            "Film (Image Only) - Photo Geodetic" && (
            <div>
              <div className="row">
                <div className="col-md-3">
                  <label>Spec Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.spec_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Mapping Only</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.mapping_only || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Image Only</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.image_only || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Map Scale</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_scale || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Map PRS</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_prs || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>CI</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.ci || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Units</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.units || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Map Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_type || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Film Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.film_type || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>File Format</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.file_format || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Version</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.version || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Models</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Flight Lines</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.flight_lines || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Exposures</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                {this.state.specification.diff_img_flight == "Yes" && (
                  <div className="col-md-3">
                    <label>Diff. Img. Flight</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.diff_img_flight || ""}
                      className="form-control"
                    />
                  </div>
                )}
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.specification.notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          )}
          {this.state.specification.spec_type == "Film" && (
            <div>
              <div className="row">
                <div className="col-md-3">
                  <label>Spec Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.spec_type || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Size w/ Buffer</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.size || "None Specified"}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Size Type</label>
                  <input
                    type="text"
                    readOnly
                    value={
                      this.state.specification.size_type || "None Specified"
                    }
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Mapping Only</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.mapping_only || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Image Only</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.image_only || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Map Scale</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_scale || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Map PRS</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_prs || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>CI</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.ci || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Units</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.units || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Map Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_type || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Film Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.film_type || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>File Format</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.file_format || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Version</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.version || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Models</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Flight Lines</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.flight_lines || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Exposures</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || ""}
                    className="form-control"
                  />
                </div>
              </div>
              {this.state.specification.diff_img_flight == "Yes" && (
                <div>
                  <p />
                  <div className="row">
                    <div className="col-md-3">
                      <label>Diff. Img. Flight</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.specification.diff_img_flight || ""}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <p />
                  <div className="row">
                    <div className="col-md-3">
                      <label>Map PRS</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.specification.img_prs || ""}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-3">
                      <label>Models</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.specification.img_models || ""}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-3">
                      <label>Flight Lines</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.specification.img_flight_lines || ""}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-3">
                      <label>Exposures</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.specification.img_exposures || ""}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              )}
              <p />
              <div className="row">
                <div className="col">
                  <label>Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.specification.notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          )}
          {this.state.specification.spec_type == "Film - Photo Geodetic" && (
            <div>
              <div className="row">
                <div className="col-md-3">
                  <label>Spec Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.spec_type || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Size w/ Buffer</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.size || "None Specified"}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Size Type</label>
                  <input
                    type="text"
                    readOnly
                    value={
                      this.state.specification.size_type || "None Specified"
                    }
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Mapping Only</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.mapping_only || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Image Only</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.image_only || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Map Scale</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_scale || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Map PRS</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_prs || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>CI</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.ci || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Units</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.units || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Map Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_type || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Film Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.film_type || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>File Format</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.file_format || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Version</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.version || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Models</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Flight Lines</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.flight_lines || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Exposures</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || ""}
                    className="form-control"
                  />
                </div>
              </div>
              {this.state.specification.diff_img_flight == "Yes" && (
                <div>
                  <p />
                  <div className="row">
                    <div className="col-md-3">
                      <label>Diff. Img. Flight</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.specification.diff_img_flight || ""}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <p />
                  <div className="row">
                    <div className="col-md-3">
                      <label>Map PRS</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.specification.img_prs || ""}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-3">
                      <label>Models</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.specification.img_models || ""}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-3">
                      <label>Flight Lines</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.specification.img_flight_lines || ""}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-3">
                      <label>Exposures</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.specification.img_exposures || ""}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              )}
              <p />
              <div className="row">
                <div className="col">
                  <label>Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.specification.notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          )}
          {this.state.specification.spec_type == "ADOT" && (
            <div>
              <div className="row">
                <div className="col-md-3">
                  <label>Spec Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.spec_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Mapping Only</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.mapping_only || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Image Only</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.image_only || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Map Scale</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_scale || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Map PRS</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_prs || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>CI</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.ci || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Units</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.units || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Map Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_type || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Film Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.film_type || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>File Format</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.file_format || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Version</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.version || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Models</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Flight Lines</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.flight_lines || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Exposures</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                {this.state.specification.diff_img_flight == "Yes" && (
                  <div className="col-md-3">
                    <label>Diff. Img. Flight</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.diff_img_flight || ""}
                      className="form-control"
                    />
                  </div>
                )}
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.specification.notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          )}
          {this.state.specification.spec_type == "Digital" && (
            <div>
              <div className="row">
                <div className="col-md-3">
                  <label>Spec Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.spec_type || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Size w/ Buffer</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.size || "None Specified"}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Size Type</label>
                  <input
                    type="text"
                    readOnly
                    value={
                      this.state.specification.size_type || "None Specified"
                    }
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Mapping Only</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.mapping_only || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Image Only</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.image_only || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Map Scale</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_scale || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>GSD</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.gsd || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>CI</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.ci || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Units</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.units || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Map Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_type || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>File Format</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.file_format || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Version</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.version || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Models</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Flight Lines</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.flight_lines || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Exposures</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || ""}
                    className="form-control"
                  />
                </div>
              </div>
              {this.state.specification.diff_img_flight == "Yes" && (
                <div>
                  <p />
                  <div className="row">
                    <div className="col-md-3">
                      <label>Diff. Img. Flight</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.specification.diff_img_flight || ""}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <p />
                  <div className="row">
                    <div className="col-md-3">
                      <label>Map PRS</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.specification.img_prs || ""}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-3">
                      <label>Models</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.specification.img_models || ""}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-3">
                      <label>Flight Lines</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.specification.img_flight_lines || ""}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-3">
                      <label>Exposures</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.specification.img_exposures || ""}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              )}
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Include LiDAR *</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.include_lidar || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.specification.notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          )}
          {this.state.specification.spec_type == "Digital - Photo Geodetic" && (
            <div>
              <div className="row">
                <div className="col-md-3">
                  <label>Spec Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.spec_type || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Size w/ Buffer</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.size || "None Specified"}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Size Type</label>
                  <input
                    type="text"
                    readOnly
                    value={
                      this.state.specification.size_type || "None Specified"
                    }
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Mapping Only</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.mapping_only || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Image Only</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.image_only || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Map Scale</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_scale || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>GSD</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.gsd || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>CI</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.ci || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Units</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.units || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Map Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_type || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>File Format</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.file_format || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Version</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.version || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Models</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Flight Lines</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.flight_lines || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Exposures</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || ""}
                    className="form-control"
                  />
                </div>
              </div>
              {this.state.specification.diff_img_flight == "Yes" && (
                <div>
                  <p />
                  <div className="row">
                    <div className="col-md-3">
                      <label>Diff. Img. Flight</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.specification.diff_img_flight || ""}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <p />
                  <div className="row">
                    <div className="col-md-3">
                      <label>Map PRS</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.specification.img_prs || ""}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-3">
                      <label>Models</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.specification.img_models || ""}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-3">
                      <label>Flight Lines</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.specification.img_flight_lines || ""}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-3">
                      <label>Exposures</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.specification.img_exposures || ""}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              )}
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Include LiDAR *</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.include_lidar || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.specification.notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          )}
          {this.state.specification.spec_type == "Digital (Mapping Only)" && (
            <div>
              <div className="row">
                <div className="col-md-3">
                  <label>Spec Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.spec_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Mapping Only</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.mapping_only || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Image Only</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.image_only || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Map Scale</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_scale || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>GSD</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.gsd || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>CI</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.ci || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Units</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.units || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Map Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_type || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>File Format</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.file_format || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Version</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.version || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Models</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Flight Lines</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.flight_lines || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Exposures</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.specification.notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          )}
          {this.state.specification.spec_type ==
            "Digital (Mapping Only) - Photo Geodetic" && (
            <div>
              <div className="row">
                <div className="col-md-3">
                  <label>Spec Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.spec_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Mapping Only</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.mapping_only || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Image Only</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.image_only || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Map Scale</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_scale || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>GSD</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.gsd || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>CI</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.ci || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Units</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.units || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Map Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_type || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>File Format</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.file_format || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Version</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.version || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Models</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Flight Lines</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.flight_lines || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Exposures</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.specification.notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          )}
          {this.state.specification.spec_type == "Digital (Image Only)" && (
            <div>
              <div className="row">
                <div className="col-md-3">
                  <label>Spec Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.spec_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Mapping Only</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.mapping_only || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Image Only</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.image_only || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Map Scale</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_scale || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>GSD</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.gsd || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>CI</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.ci || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Units</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.units || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Map Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_type || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>File Format</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.file_format || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Version</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.version || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Models</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Flight Lines</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.flight_lines || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Exposures</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.specification.notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          )}
          {this.state.specification.spec_type ==
            "Digital (Image Only) - Photo Geodetic" && (
            <div>
              <div className="row">
                <div className="col-md-3">
                  <label>Spec Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.spec_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Mapping Only</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.mapping_only || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Image Only</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.image_only || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Map Scale</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_scale || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>GSD</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.gsd || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>CI</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.ci || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Units</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.units || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Map Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_type || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>File Format</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.file_format || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Version</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.version || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Models</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Flight Lines</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.flight_lines || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Exposures</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.specification.notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          )}
          {this.state.specification.spec_type == "LiDAR" && (
            <div>
              <div className="row">
                <div className="col-md-3">
                  <label>Spec Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.spec_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Map Scale</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_scale || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>CI</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.ci || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Map Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_type || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>File Format</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.file_format || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Version</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.version || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Bare Earth Only</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.bare_earth_only || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Coordinate System</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.coordinate_system || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Classification</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.classification || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.specification.notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          )}
          {this.state.specification.spec_type == "LiDAR Processing" && (
            <div>
              <div className="row">
                <div className="col-md-3">
                  <label>Spec Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.spec_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>PPSM</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.ppsm || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Size</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.size || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Size Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.size_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Bare Earth Only</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.bare_earth_only || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Detail Level</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.detail_level || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Coordinate System</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.coordinate_system || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Classification</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.classification || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.specification.notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          )}
          {this.state.specification.spec_type == "Scan" && (
            <div>
              <div className="row">
                <div className="col-md-3">
                  <label>Spec Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.spec_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Scan Res.</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.scan_res || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Number of Scans</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.number_scans || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Film Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.film_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.specification.notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          )}
          {/* start of plotting */}
          {this.state.specification.spec_type == "Plot" && (
            <div>
              <div className="row">
                <div className="col-md-3">
                  <label>Spec Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.spec_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-4">
                  <label>Quantity</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.quantity || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-2">
                  <label>Size X</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.size_x || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-2">
                  <label>Size Y</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.size_y || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-4">
                  <label>Mounted</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.mounted || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-4">
                  <label>Frame</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.frame || ""}
                    className="form-control"
                  />
                </div>
                {this.state.specification.frame == "Metal" && (
                  <div className="col-md-4">
                    <label>Frame Color</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.frame_color || ""}
                      className="form-control"
                    />
                  </div>
                )}
                {this.state.specification.frame == "Plastic" && (
                  <div className="col-md-4">
                    <label>Frame Color</label>
                    <input
                      type="text"
                      readOnly
                      value={this.state.specification.frame_color || ""}
                      className="form-control"
                    />
                  </div>
                )}
              </div>
              <p />
              <div className="row">
                <div className="col-md-4">
                  <label>Laminated</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.laminated || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-4">
                  <label>Lamination Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.plot_options || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.specification.notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              {plots.map((plot, idx) => (
                <div key={`plot-${idx}`}>
                  {this.state.plots[idx]["quantity"] >= 1 && (
                    <div>
                      <h5>Additonal Plots</h5>
                      <hr />
                      <div className="row">
                        <div className="col-md-4">
                          <label>Quantity</label>
                          <input
                            type="text"
                            readOnly
                            name={`plot-${idx}-quantity`}
                            value={this.state.plots[idx]["quantity"]}
                            className="form-control"
                          />
                        </div>
                        <div className="col-md-2">
                          <label>Size X *</label>
                          <input
                            type="text"
                            readOnly
                            name={`plot-${idx}-size_x`}
                            placeholder="Size X"
                            value={this.state.plots[idx]["size_x"]}
                            className="form-control"
                          />
                        </div>
                        <div className="col-md-2">
                          <label>Size Y *</label>
                          <input
                            type="text"
                            readOnly
                            name={`plot-${idx}-size_y`}
                            placeholder="Size Y"
                            value={this.state.plots[idx]["size_y"]}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-4">
                          <label>Mounted *</label>
                          <input
                            type="text"
                            readOnly
                            name={`plot-${idx}-mounted`}
                            value={this.state.plots[idx]["mounted"]}
                            className="form-control"
                          />
                        </div>
                        <div className="col-md-4">
                          <label>Frame *</label>
                          <input
                            type="text"
                            readOnly
                            name={`plot-${idx}-frame`}
                            value={this.state.plots[idx]["frame"]}
                            className="form-control"
                          />
                        </div>
                        {this.state.plots[idx]["frame"] == "Metal" && (
                          <div className="col-md-4">
                            <label>Frame Color</label>
                            <input
                              type="text"
                              readOnly
                              name={`plot-${idx}-frame_color`}
                              value={this.state.plots[idx]["frame_color"] || ""}
                              className="form-control"
                            />
                          </div>
                        )}
                        {this.state.plots[idx]["frame"] == "Plastic" && (
                          <div className="col-md-4">
                            <label>Frame Color</label>
                            <input
                              type="text"
                              readOnly
                              name={`plot-${idx}-frame_color`}
                              value={this.state.plots[idx]["frame_color"] || ""}
                              className="form-control"
                            />
                          </div>
                        )}
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-4">
                          <label>Laminated</label>
                          <input
                            type="text"
                            readOnly
                            name={`plot-${idx}-laminated`}
                            value={this.state.plots[idx]["laminated"]}
                            className="form-control"
                          />
                        </div>
                        <div className="col-md-4">
                          <label>Laminated Type *</label>
                          <input
                            type="text"
                            readOnly
                            name={`plot-${idx}-plot_options`}
                            value={this.state.plots[idx]["plot_options"]}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-8">
                          <label>Notes</label>
                          <textarea
                            readOnly
                            name={`plot-${idx}-notes`}
                            placeholder="Notes"
                            value={this.state.plots[idx]["notes"]}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <hr />
                    </div>
                  )}
                </div>
              ))}
              <hr />
            </div>
          )}
          {/* end of plotting */}
          {this.state.specification.spec_type == "Survey" && (
            <div>
              <div className="row">
                <div className="col-md-3">
                  <label>Spec Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.spec_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Location</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.location || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Staff</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.staff || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Notes (internal)</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.specification.notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          )}
          {this.state.specification.spec_type == "Construction" && (
            <div>
              <div className="row">
                <div className="col-md-3">
                  <label>Staff</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.staff || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Notes (internal)</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.specification.notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          )}
          {this.state.specification.spec_type == "Flight" && (
            <div>
              <div className="row">
                <div className="col-md-3">
                  <label>Spec Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.spec_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Map Scale</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_scale || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Map PRS</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_prs || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>GSD</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.gsd || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Film Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.film_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Models</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.models || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Flight Lines</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.flight_lines || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Exposures</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.specification.notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          )}
          <p />
          {this.state.specification.spec_type == "NAAS" && (
            <div>
              <div className="row">
                <div className="col-md-3">
                  <label>Spec Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.spec_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Map Scale</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_scale || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Map PRS</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.map_prs || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>GSD</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.gsd || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Film Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.film_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Flight Lines</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.flight_lines || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Exposures</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.exposures || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.specification.notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          )}
          <p />
          {this.state.specification.spec_type == "UAV" && (
            <div>
              <div className="row">
                <div className="col-md-3">
                  <label>Spec Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.spec_type || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>UAV Mapping</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.uav_mapping || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>UAV Oblique</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.uav_oblique || ""}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>UAV Video</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.uav_video || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              {this.state.specification.uav_mapping == "Yes" && (
                <div>
                  <div className="row">
                    <div className="col">
                      <label>Acres</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.specification.size}
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <label>GSD</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.specification.gsd}
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <label>Number of Locations</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.specification.location_number}
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <label>Flights</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.specification.flights}
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <label>Exposures</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.specification.exposures}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-3">
                      <div className="col">
                        <label>Map Scale</label>
                        <input
                          type="text"
                          readOnly
                          value={this.state.specification.map_scale}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="col">
                        <label>C.I.</label>
                        <input
                          type="text"
                          readOnly
                          value={this.state.specification.ci}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <p />
              {this.state.specification.uav_oblique == "Yes" && (
                <div>
                  <div className="row">
                    <div className="col">
                      <label>Oblique Description</label>
                      <textarea
                        type="text"
                        readOnly
                        value={this.state.specification.oblique_desc}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <p />
                  <div className="row">
                    <div className="col">
                      <label>Number of Locations</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.specification.obliques_locations}
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <label>Exposures</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.specification.obliques_exposures}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              )}
              <p />
              {this.state.specification.uav_video == "Yes" && (
                <div>
                  <div className="row">
                    <div className="col">
                      <label>Video Description</label>
                      <textarea
                        type="text"
                        readOnly
                        value={this.state.specification.video_desc}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <p />
                  <div className="row">
                    <div className="col">
                      <label>Number of Locations</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.specification.video_locations}
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <label>Total Videos</label>
                      <input
                        type="text"
                        readOnly
                        value={this.state.specification.total_videos}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              )}
              <p />
              <div className="row">
                <div className="col">
                  <label>Notes (internal)</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.specification.notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          )}
          <p />
          {this.state.specification.spec_type == "Other" && (
            <div>
              <div className="row">
                <div className="col-md-3">
                  <label>Spec Type</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.specification.spec_type || ""}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>Notes</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.specification.notes || ""}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          )}
          <p />
          {!this.state.opportunity.quote_id && (
            <button
              title="Click to expand/hide quote form"
              type="button"
              onClick={this.toggleHidden.bind(this)}
              className="btn btn-secondary btn-sm"
            >
              {this.state.isHidden ? (
                <span>
                  Add Quote{" "}
                  <i className="fa fa-plus-circle" aria-hidden="true"></i>
                </span>
              ) : (
                <span>
                  Close Quote Form{" "}
                  <i className="fa fa-minus-circle" aria-hidden="true"></i>
                </span>
              )}
            </button>
          )}
          <p />
          {(!this.state.isHidden &&
            this.state.specification.spec_type == "Film (Image Only)") ||
            (!this.state.isHidden &&
              this.state.specification.spec_type ==
                "Film (Image Only) - Photo Geodetic" && (
                <FilmImageOnlyQuoteForm
                  handleNewQuote={this.addNewQuote}
                  imgQty={this.state.specification.exposures}
                  xsecQty={this.state.specification.models}
                  translationQty={this.state.specification.models}
                  cartographyQty={this.state.specification.models}
                  photogrammetryQty={this.state.specification.models}
                  analyticsQty={this.state.specification.exposures}
                  scanQty={this.state.specification.exposures}
                  diaPositives={this.state.specification.exposures}
                  contactPrints={this.state.specification.exposures}
                  specId={this.props.match.params.id}
                  oppId={this.state.opportunity.id}
                  lidar_size={this.state.specification.size}
                  quoteType={this.state.specification.spec_type}
                  userId={this.state.specification.user_id}
                  premium_charge={this.props.company.premium_charge}
                />
              ))}
          {!this.state.isHidden &&
            this.state.specification.spec_type == "Film (Image Only)" && (
              <FilmImageOnlyQuoteForm
                handleNewQuote={this.addNewQuote}
                imgQty={this.state.specification.exposures}
                xsecQty={this.state.specification.models}
                translationQty={this.state.specification.models}
                cartographyQty={this.state.specification.models}
                photogrammetryQty={this.state.specification.models}
                analyticsQty={this.state.specification.exposures}
                scanQty={this.state.specification.exposures}
                diaPositives={this.state.specification.exposures}
                contactPrints={this.state.specification.exposures}
                specId={this.props.match.params.id}
                oppId={this.state.opportunity.id}
                lidar_size={this.state.specification.size}
                quoteType={this.state.specification.spec_type}
                userId={this.state.specification.user_id}
                premium_charge={this.props.company.premium_charge}
              />
            )}
          {(!this.state.isHidden &&
            this.state.specification.spec_type == "Film (Mapping Only)") ||
            (!this.state.isHidden &&
              this.state.specification.spec_type ==
                "Film (Mapping Only) - Photo Geodetic" && (
                <FilmMapOnlyQuoteForm
                  handleNewQuote={this.addNewQuote}
                  imgQty={this.state.specification.exposures}
                  xsecQty={this.state.specification.models}
                  translationQty={this.state.specification.models}
                  cartographyQty={this.state.specification.models}
                  photogrammetryQty={this.state.specification.models}
                  analyticsQty={this.state.specification.exposures}
                  scanQty={this.state.specification.exposures}
                  diaPositives={this.state.specification.exposures}
                  contactPrints={this.state.specification.exposures}
                  specId={this.props.match.params.id}
                  oppId={this.state.opportunity.id}
                  lidar_size={this.state.specification.size}
                  quoteType={this.state.specification.spec_type}
                  userId={this.state.specification.user_id}
                  premium_charge={this.props.company.premium_charge}
                />
              ))}
          {!this.state.isHidden &&
            this.state.specification.spec_type == "Film (Mapping Only)" && (
              <FilmMapOnlyQuoteForm
                handleNewQuote={this.addNewQuote}
                imgQty={this.state.specification.exposures}
                xsecQty={this.state.specification.models}
                translationQty={this.state.specification.models}
                cartographyQty={this.state.specification.models}
                photogrammetryQty={this.state.specification.models}
                analyticsQty={this.state.specification.exposures}
                scanQty={this.state.specification.exposures}
                diaPositives={this.state.specification.exposures}
                contactPrints={this.state.specification.exposures}
                specId={this.props.match.params.id}
                oppId={this.state.opportunity.id}
                lidar_size={this.state.specification.size}
                quoteType={this.state.specification.spec_type}
                userId={this.state.specification.user_id}
                premium_charge={this.props.company.premium_charge}
              />
            )}
          {!this.state.isHidden &&
            this.state.specification.spec_type == "Flight" && (
              <FlightQuoteForm
                handleNewQuote={this.addNewQuote}
                specId={this.props.match.params.id}
                oppId={this.state.opportunity.id}
                quoteType={this.state.specification.spec_type}
                userId={this.state.specification.user_id}
              />
            )}
          {!this.state.isHidden &&
            this.state.specification.spec_type == "NAAS" && (
              <NaasQuoteForm
                handleNewQuote={this.addNewQuote}
                specId={this.props.match.params.id}
                oppId={this.state.opportunity.id}
                quoteType={this.state.specification.spec_type}
                userId={this.state.specification.user_id}
                flightLines={this.state.specification.flight_lines}
              />
            )}
          {!this.state.isHidden &&
            this.state.specification.spec_type == "UAV" && (
              <DroneQuoteForm
                handleNewQuote={this.addNewQuote}
                specId={this.props.match.params.id}
                oppId={this.state.opportunity.id}
                quoteType={this.state.specification.spec_type}
                userId={this.state.specification.user_id}
                exposures={this.state.specification.exposures}
                obliques={this.state.specification.obliques_exposures}
                videoQty={this.state.specification.total_videos}
                acreage={this.state.specification.size}
                premium_charge={this.props.company.premium_charge}
              />
            )}
          {!this.state.isHidden &&
            this.state.specification.spec_type == "Film" &&
            this.state.specification.diff_img_flight == "No" && (
              <FilmQuoteForm
                handleNewQuote={this.addNewQuote}
                imgQty={this.state.specification.exposures}
                xsecQty={this.state.specification.models}
                translationQty={this.state.specification.models}
                cartographyQty={this.state.specification.models}
                photogrammetryQty={this.state.specification.models}
                analyticsQty={this.state.specification.exposures}
                scanQty={this.state.specification.exposures}
                diaPositives={this.state.specification.exposures}
                contactPrints={this.state.specification.exposures}
                specId={this.props.match.params.id}
                oppId={this.state.opportunity.id}
                lidar_size={this.state.specification.size}
                quoteType={this.state.specification.spec_type}
                userId={this.state.specification.user_id}
                premium_charge={this.props.company.premium_charge}
              />
            )}
          {!this.state.isHidden &&
            this.state.specification.spec_type == "Film - Photo Geodetic" &&
            this.state.specification.diff_img_flight == "No" && (
              <PgFilmQuoteForm
                handleNewQuote={this.addNewQuote}
                imgQty={this.state.specification.exposures}
                xsecQty={this.state.specification.models}
                translationQty={this.state.specification.models}
                cartographyQty={this.state.specification.models}
                photogrammetryQty={this.state.specification.models}
                analyticsQty={this.state.specification.exposures}
                scanQty={this.state.specification.exposures}
                diaPositives={this.state.specification.exposures}
                contactPrints={this.state.specification.exposures}
                specId={this.props.match.params.id}
                oppId={this.state.opportunity.id}
                lidar_size={this.state.specification.size}
                quoteType={this.state.specification.spec_type}
                userId={this.state.specification.user_id}
                premium_charge={this.props.company.premium_charge}
              />
            )}
          {!this.state.isHidden &&
            this.state.specification.spec_type == "Film" &&
            this.state.specification.diff_img_flight == "Yes" && (
              <FilmQuoteForm
                handleNewQuote={this.addNewQuote}
                imgQty={this.state.specification.img_exposures}
                xsecQty={this.state.specification.models}
                translationQty={this.state.specification.models}
                cartographyQty={this.state.specification.models}
                photogrammetryQty={this.state.specification.models}
                analyticsQty={this.state.specification.exposures}
                scanQty={this.state.specification.exposures}
                diaPositives={this.state.specification.exposures}
                contactPrints={this.state.specification.exposures}
                specId={this.props.match.params.id}
                oppId={this.state.opportunity.id}
                lidar_size={this.state.specification.size}
                quoteType={this.state.specification.spec_type}
                userId={this.state.specification.user_id}
                premium_charge={this.props.company.premium_charge}
              />
            )}
          {!this.state.isHidden &&
            this.state.specification.spec_type == "Film - Photo Geodetic" &&
            this.state.specification.diff_img_flight == "Yes" && (
              <PgFilmQuoteForm
                handleNewQuote={this.addNewQuote}
                imgQty={this.state.specification.img_exposures}
                xsecQty={this.state.specification.models}
                translationQty={this.state.specification.models}
                cartographyQty={this.state.specification.models}
                photogrammetryQty={this.state.specification.models}
                analyticsQty={this.state.specification.exposures}
                scanQty={this.state.specification.exposures}
                diaPositives={this.state.specification.exposures}
                contactPrints={this.state.specification.exposures}
                specId={this.props.match.params.id}
                oppId={this.state.opportunity.id}
                lidar_size={this.state.specification.size}
                quoteType={this.state.specification.spec_type}
                userId={this.state.specification.user_id}
                premium_charge={this.props.company.premium_charge}
              />
            )}
          {!this.state.isHidden &&
            this.state.specification.spec_type == "ADOT" && (
              <AdotQuoteForm
                handleNewQuote={this.addNewQuote}
                imgQty={this.state.specification.exposures}
                xsecQty={this.state.specification.models}
                translationQty={this.state.specification.models}
                cartographyQty={this.state.specification.models}
                photogrammetryQty={this.state.specification.models}
                analyticsQty={this.state.specification.exposures}
                scanQty={this.state.specification.exposures}
                diaPositives={this.state.specification.exposures}
                contactPrints={this.state.specification.exposures}
                specId={this.props.match.params.id}
                oppId={this.state.opportunity.id}
                quoteType={this.state.specification.spec_type}
                userId={this.state.specification.user_id}
              />
            )}
          {!this.state.isHidden &&
            this.state.specification.spec_type == "Digital" &&
            this.state.specification.diff_img_flight == "No" &&
            this.state.specification.include_lidar == "No" && (
              <DigitalQuoteForm
                handleNewQuote={this.addNewQuote}
                imgQty={this.state.specification.exposures}
                xsecQty={this.state.specification.models}
                translationQty={this.state.specification.models}
                cartographyQty={this.state.specification.models}
                photogrammetryQty={this.state.specification.models}
                analyticsQty={this.state.specification.exposures}
                scanQty={this.state.specification.exposures}
                diaPositives={this.state.specification.exposures}
                contactPrints={this.state.specification.exposures}
                specId={this.props.match.params.id}
                oppId={this.state.opportunity.id}
                quoteType={this.state.specification.spec_type}
                lidar_size={this.state.specification.size}
                userId={this.state.specification.user_id}
                premium_charge={this.props.company.premium_charge}
              />
            )}
          {!this.state.isHidden &&
            this.state.specification.spec_type == "Digital - Photo Geodetic" &&
            this.state.specification.diff_img_flight == "No" &&
            this.state.specification.include_lidar == "No" && (
              <PgDigitalQuoteForm
                handleNewQuote={this.addNewQuote}
                imgQty={this.state.specification.exposures}
                xsecQty={this.state.specification.models}
                translationQty={this.state.specification.models}
                cartographyQty={this.state.specification.models}
                photogrammetryQty={this.state.specification.models}
                analyticsQty={this.state.specification.exposures}
                scanQty={this.state.specification.exposures}
                diaPositives={this.state.specification.exposures}
                contactPrints={this.state.specification.exposures}
                specId={this.props.match.params.id}
                oppId={this.state.opportunity.id}
                quoteType={this.state.specification.spec_type}
                lidar_size={this.state.specification.size}
                userId={this.state.specification.user_id}
                premium_charge={this.props.company.premium_charge}
              />
            )}
          {!this.state.isHidden &&
            this.state.specification.spec_type == "Digital" &&
            this.state.specification.diff_img_flight == "Yes" && (
              <DigitalQuoteForm
                handleNewQuote={this.addNewQuote}
                imgQty={this.state.specification.img_exposures}
                xsecQty={this.state.specification.models}
                translationQty={this.state.specification.models}
                cartographyQty={this.state.specification.models}
                photogrammetryQty={this.state.specification.models}
                analyticsQty={this.state.specification.exposures}
                scanQty={this.state.specification.exposures}
                diaPositives={this.state.specification.exposures}
                contactPrints={this.state.specification.exposures}
                specId={this.props.match.params.id}
                oppId={this.state.opportunity.id}
                lidar_size={this.state.specification.size}
                quoteType={this.state.specification.spec_type}
                userId={this.state.specification.user_id}
                premium_charge={this.props.company.premium_charge}
              />
            )}
          {!this.state.isHidden &&
            this.state.specification.spec_type == "Digital - Photo Geodetic" &&
            this.state.specification.diff_img_flight == "Yes" && (
              <PgDigitalQuoteForm
                handleNewQuote={this.addNewQuote}
                imgQty={this.state.specification.img_exposures}
                xsecQty={this.state.specification.models}
                translationQty={this.state.specification.models}
                cartographyQty={this.state.specification.models}
                photogrammetryQty={this.state.specification.models}
                analyticsQty={this.state.specification.exposures}
                scanQty={this.state.specification.exposures}
                diaPositives={this.state.specification.exposures}
                contactPrints={this.state.specification.exposures}
                specId={this.props.match.params.id}
                oppId={this.state.opportunity.id}
                lidar_size={this.state.specification.size}
                quoteType={this.state.specification.spec_type}
                userId={this.state.specification.user_id}
                premium_charge={this.props.company.premium_charge}
              />
            )}
          {!this.state.isHidden &&
            this.state.specification.spec_type == "Digital" &&
            this.state.specification.include_lidar == "Yes" && (
              <DigitalLidarQuoteForm
                handleNewQuote={this.addNewQuote}
                imgQty={this.state.specification.exposures}
                xsecQty={this.state.specification.models}
                translationQty={this.state.specification.models}
                cartographyQty={this.state.specification.models}
                photogrammetryQty={this.state.specification.models}
                analyticsQty={this.state.specification.exposures}
                scanQty={this.state.specification.exposures}
                diaPositives={this.state.specification.exposures}
                contactPrints={this.state.specification.exposures}
                specId={this.props.match.params.id}
                oppId={this.state.opportunity.id}
                lidar_size={this.state.specification.size}
                quoteType={this.state.specification.spec_type}
                userId={this.state.specification.user_id}
                premium_charge={this.props.company.premium_charge}
              />
            )}
          {!this.state.isHidden &&
            this.state.specification.spec_type == "Digital - Photo Geodetic" &&
            this.state.specification.include_lidar == "Yes" && (
              <PgDigitalLidarQuoteForm
                handleNewQuote={this.addNewQuote}
                imgQty={this.state.specification.exposures}
                xsecQty={this.state.specification.models}
                translationQty={this.state.specification.models}
                cartographyQty={this.state.specification.models}
                photogrammetryQty={this.state.specification.models}
                analyticsQty={this.state.specification.exposures}
                scanQty={this.state.specification.exposures}
                diaPositives={this.state.specification.exposures}
                contactPrints={this.state.specification.exposures}
                specId={this.props.match.params.id}
                oppId={this.state.opportunity.id}
                lidar_size={this.state.specification.size}
                quoteType={this.state.specification.spec_type}
                userId={this.state.specification.user_id}
                premium_charge={this.props.company.premium_charge}
              />
            )}
          {(!this.state.isHidden &&
            this.state.specification.spec_type == "Digital (Image Only)") ||
            (!this.state.isHidden &&
              this.state.specification.spec_type ==
                "Digital (Image Only) - Photo Geodetic" && (
                <DigitalImageOnlyForm
                  handleNewQuote={this.addNewQuote}
                  imgQty={this.state.specification.exposures}
                  diffImgQty={this.state.specification.img_exposures}
                  xsecQty={this.state.specification.models}
                  translationQty={this.state.specification.models}
                  cartographyQty={this.state.specification.models}
                  photogrammetryQty={this.state.specification.models}
                  analyticsQty={this.state.specification.exposures}
                  scanQty={this.state.specification.exposures}
                  diaPositives={this.state.specification.exposures}
                  contactPrints={this.state.specification.exposures}
                  specId={this.props.match.params.id}
                  oppId={this.state.opportunity.id}
                  lidar_size={this.state.specification.size}
                  quoteType={this.state.specification.spec_type}
                  userId={this.state.specification.user_id}
                  premium_charge={this.props.company.premium_charge}
                />
              ))}
          {!this.state.isHidden &&
            this.state.specification.spec_type == "Digital (Image Only)" && (
              <DigitalImageOnlyForm
                handleNewQuote={this.addNewQuote}
                imgQty={this.state.specification.exposures}
                diffImgQty={this.state.specification.img_exposures}
                xsecQty={this.state.specification.models}
                translationQty={this.state.specification.models}
                cartographyQty={this.state.specification.models}
                photogrammetryQty={this.state.specification.models}
                analyticsQty={this.state.specification.exposures}
                scanQty={this.state.specification.exposures}
                diaPositives={this.state.specification.exposures}
                contactPrints={this.state.specification.exposures}
                specId={this.props.match.params.id}
                oppId={this.state.opportunity.id}
                lidar_size={this.state.specification.size}
                quoteType={this.state.specification.spec_type}
                userId={this.state.specification.user_id}
                premium_charge={this.props.company.premium_charge}
              />
            )}
          {(!this.state.isHidden &&
            this.state.specification.spec_type == "Digital (Mapping Only)") ||
            (!this.state.isHidden &&
              this.state.specification.spec_type ==
                "Digital (Mapping Only) - Photo Geodetic" && (
                <DigitalMapOnlyForm
                  handleNewQuote={this.addNewQuote}
                  imgQty={this.state.specification.exposures}
                  xsecQty={this.state.specification.models}
                  translationQty={this.state.specification.models}
                  cartographyQty={this.state.specification.models}
                  photogrammetryQty={this.state.specification.models}
                  analyticsQty={this.state.specification.exposures}
                  scanQty={this.state.specification.exposures}
                  diaPositives={this.state.specification.exposures}
                  contactPrints={this.state.specification.exposures}
                  specId={this.props.match.params.id}
                  oppId={this.state.opportunity.id}
                  lidar_size={this.state.specification.size}
                  quoteType={this.state.specification.spec_type}
                  userId={this.state.specification.user_id}
                  premium_charge={this.props.company.premium_charge}
                />
              ))}
          {!this.state.isHidden &&
            this.state.specification.spec_type == "Digital (Mapping Only)" && (
              <DigitalMapOnlyForm
                handleNewQuote={this.addNewQuote}
                imgQty={this.state.specification.exposures}
                xsecQty={this.state.specification.models}
                translationQty={this.state.specification.models}
                cartographyQty={this.state.specification.models}
                photogrammetryQty={this.state.specification.models}
                analyticsQty={this.state.specification.exposures}
                scanQty={this.state.specification.exposures}
                diaPositives={this.state.specification.exposures}
                contactPrints={this.state.specification.exposures}
                specId={this.props.match.params.id}
                oppId={this.state.opportunity.id}
                lidar_size={this.state.specification.size}
                quoteType={this.state.specification.spec_type}
                userId={this.state.specification.user_id}
                premium_charge={this.props.company.premium_charge}
              />
            )}
          {!this.state.isHidden &&
            this.state.specification.spec_type == "LiDAR" && (
              <LidarQuoteForm
                handleNewQuote={this.addNewQuote}
                areaSize={this.state.specification.size || 0}
                sizeType={this.state.specification.size_type || 0}
                detailLevel={this.state.specification.detail_level || 0}
                specId={this.props.match.params.id}
                oppId={this.state.opportunity.id}
                quoteType={this.state.specification.spec_type}
                userId={this.state.specification.user_id}
                rectificationQty={this.state.specification.exposures || "0"}
                premium_charge={this.props.company.premium_charge}
              />
            )}
          {!this.state.isHidden &&
            this.state.specification.spec_type == "LiDAR Processing" && (
              <LidarProcessingQuoteForm
                handleNewQuote={this.addNewQuote}
                areaSize={this.state.specification.size}
                sizeType={this.state.specification.size_type}
                detailLevel={this.state.specification.detail_level}
                specId={this.props.match.params.id}
                oppId={this.state.opportunity.id}
                quoteType={this.state.specification.spec_type}
                userId={this.state.specification.user_id}
                rectificationQty={this.state.specification.exposures}
              />
            )}
          {!this.state.isHidden &&
            this.state.specification.spec_type == "Scan" && (
              <ScanQuoteForm
                handleNewQuote={this.addNewQuote}
                contactPrints={this.state.specification.number_scans}
                specId={this.props.match.params.id}
                oppId={this.state.opportunity.id}
                quoteType={this.state.specification.spec_type}
                userId={this.state.specification.user_id}
              />
            )}
          {!this.state.isHidden &&
            this.state.specification.spec_type == "Plot" && (
              <PlotQuoteForm
                handleNewQuote={this.addNewQuote}
                plotQty={this.state.specification.quantity}
                plotLength={this.state.specification.size_x}
                plotWidth={this.state.specification.size_y}
                plotMount={this.state.specification.mounted}
                specId={this.props.match.params.id}
                oppId={this.state.opportunity.id}
                quoteType={this.state.specification.spec_type}
                userId={this.state.specification.user_id}
                plots={this.state.plots}
                frame={this.state.specification.frame}
                laminateType={this.state.specification.plot_options}
                laminate={this.state.specification.laminated}
              />
            )}
          {!this.state.isHidden &&
            this.state.specification.spec_type == "Survey" && (
              <SurveyQuoteForm
                handleNewQuote={this.addNewQuote}
                specId={this.props.match.params.id}
                oppId={this.state.opportunity.id}
                quoteType={this.state.specification.spec_type}
                userId={this.state.specification.user_id}
                surveyStaff={this.state.specification.staff}
              />
            )}
          {!this.state.isHidden &&
            this.state.specification.spec_type == "Construction" && (
              <ConstructionQuoteForm
                handleNewQuote={this.addNewQuote}
                specId={this.props.match.params.id}
                oppId={this.state.opportunity.id}
                quoteType={this.state.specification.spec_type}
                userId={this.state.specification.user_id}
                surveyStaff={this.state.specification.staff}
              />
            )}
          {!this.state.isHidden &&
            this.state.specification.spec_type == "Other" && (
              <OtherQuoteForm
                handleNewQuote={this.addNewQuote}
                imgQty={this.state.specification.exposures}
                cartographyQty={this.state.specification.models}
                photogrammetryQty={this.state.specification.models}
                analyticsQty={this.state.specification.exposures}
                scanQty={this.state.specification.exposures}
                diaPositives={this.state.specification.exposures}
                contactPrints={this.state.specification.exposures}
                specId={this.props.match.params.id}
                oppId={this.state.opportunity.id}
                quoteType={this.state.specification.spec_type}
                userId={this.state.specification.user_id}
              />
            )}
          <hr />
        </div>
      </div>
    );
  }
}
