import React from 'react';
import PropTypes from 'prop-types';
import { FormErrors } from './FormErrors';
import update from 'immutability-helper';
import { validations } from '../utils/validations';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

export default class CompilationForm extends React.Component {
  static propTypes = {
    handleNewCompilation: PropTypes.func,
  };

  constructor(props, railsContext) {
    super(props);
    this.state = {
      sub_alloted_hours: "0",
      compilation_required: "",
      model_diagram: "No",
      need_comp_qc: "No",
      problem_at: "",
      type: { value: "", valid: true },
      user_type: { value: "", valid: true },
      user_role: { value: "", valid: true },
      grid_distance: { value: "", valid: true },
      actual_comp_hours: { value: "", valid: true },
      sub_name: { value: "", valid: false },
      sub_due_date: { value: "", valid: false },
      sub_done: { value: "", valid: false },
      models_to_sub: { value: "", valid: false },
      models_to_do: { value: "", valid: false },
      received_date: { value: "", valid: false },
      rejected: "",
      rejected_date: { value: "", valid: false },
      sub_notes: { value: "", valid: false },
      hours: { value: "", valid: false },
      total_comp_hours: { value: "", valid: false },
      roll_number: { value: "", valid: false },
      image_sub: { value: "", valid: false },
      camera_number: { value: "", valid: false },
      comp_done_date: { value: "", valid: false },
      job_name: { value: "", valid: false },
      client_number: { value: "", valid: false },
      client_name: { value: "", valid: false },
      job_number: { value: "", valid: false },
      due_date: { value: "", valid: false },
      models: { value: "", valid: false },
      edge_job_number: { value: "", valid: false },
      map_scale: { value: "", valid: false },
      ci: { value: "", valid: false },
      map_type: { value: "", valid: false },
      edge_job_number: { value: "", valid: false },
      project_id: { value: "", valid: false },
      current_comp_staff: "",
      comp_staff: "",
      comp_staff1: "",
      comp_staff2: { value: "", valid: false },
      comp_done: "",
      hrs_per_model: { value: "", valid: true },
      models_todo: { value: "", valid: true },
      batch_set: "",
      status: { value: "", valid: true },
      setup_quality: { value: "", valid: true },
      readability: { value: "", valid: true },
      to_sub: "",
      project_notes: { value: "", valid: true },
      comp_notes: { value: "", valid: true },
      comp_staff_options: [
        { value: "READY", label: "READY" },
        { value: "Axel", label: "Axel" },
        { value: "Becky C", label: "Becky C" },
        { value: "Ben", label: "Ben" },
        { value: "Brian", label: "Brian" },
        { value: "Chris", label: "Chris" },
        { value: "Dave", label: "Dave" },
        { value: "Grek", label: "Grek" },
        { value: "Joe", label: "Joe" },
        { value: "Josh", label: "Josh" },
        { value: "Justin", label: "Justin" },
        { value: "Karole", label: "Karole" },
        { value: "Kevin Rowell", label: "Kevin Rowell" },
        { value: "Lawrence", label: "Lawrence" },
        { value: "Michael", label: "Michael" },
        { value: "Miguel", label: "Miguel" },
        { value: "Needs QC", label: "Needs QC" },
        { value: "NEM", label: "NEM" },
        { value: "PhotoGeo", label: "PhotoGeo" },
        { value: "Scott", label: "Scott" },
        { value: "Steve", label: "Steve" },
        { value: "Will Matson", label: "Will Matson" },
      ],
      fixed: "",
      formErrors: {},
      formValid: false,
      editing: false,
      isHidden: true,
    };
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
  }

  static formValidations = {
    project_id: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    actual_comp_hours: [
      (s) => {
        return validations.checkMinLength(s, 0);
      },
    ],
    comp_staff2: [
      (s) => {
        return validations.checkMinLength(s, 3);
      },
    ],
    hrs_per_model: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    models_todo: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    status: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    setup_quality: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    readability: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    models: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    edge_job_number: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    comp_done_date: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    sub_name: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    due_date: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    sub_done: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    models_to_sub: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    models_to_do: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    received_date: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    rejected_date: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    sub_notes: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    hours: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    total_comp_hours: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    project_notes: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    comp_notes: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    sub_alloted_hours: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
    grid_distance: [
      (s) => {
        return validations.checkMinLength(s, 1);
      },
    ],
  };

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/cascopt/compilations/${this.props.match.params.id}`,
        dataType: "JSON",
      }).done((data) => {
        this.setState({
          user_type: { value: data.user.type, valid: true },
          user_role: { value: data.user.role, valid: true },
          type: { value: data.quality_control.type, valid: true },
          sub_alloted_hours: {
            value: data.compilation.sub_alloted_hours,
            valid: true,
          },
          grid_distance: { value: data.compilation.grid_distance, valid: true },
          compilation_required: data.compilation.compilation_required,
          actual_comp_hours: {
            value: data.project.actual_comp_hours,
            valid: true,
          },
          job_name: { value: data.project.job_name, valid: true },
          sub_name: { value: data.compilation.sub_name, valid: true },
          sub_due_date: { value: data.compilation.sub_due_date, valid: true },
          sub_done: { value: data.compilation.sub_done, valid: true },
          models_to_sub: { value: data.compilation.models_to_sub, valid: true },
          models_to_do: { value: data.compilation.models_to_do, valid: true },
          received_date: { value: data.compilation.received_date, valid: true },
          rejected: data.compilation.rejected,
          need_comp_qc: data.compilation.need_comp_qc,
          rejected_date: { value: data.compilation.rejected_date, valid: true },
          sub_notes: { value: data.compilation.sub_notes, valid: true },
          hours: { value: data.compilation.hours, valid: true },
          total_comp_hours: {
            value: data.compilation.total_comp_hours,
            valid: true,
          },
          roll_number: { value: data.scan.roll_number, valid: true },
          image_sub: { value: data.aerotriangulation.image_sub, valid: true },
          camera_number: { value: data.scan.camera_number, valid: true },
          comp_done_date: {
            value: data.compilation.comp_done_date,
            valid: true,
          },
          client_number: { value: data.project.client_number, valid: true },
          client_name: { value: data.project.client_name, valid: true },
          job_number: { value: data.project.job_number, valid: true },
          due_date: { value: data.compilation.due_date, valid: true },
          models: { value: data.project.models, valid: true },
          edge_job_number: { value: data.project.edge_job_number, valid: true },
          map_scale: { value: data.project.map_scale, valid: true },
          ci: { value: data.project.ci, valid: true },
          map_type: { value: data.project.map_type, valid: true },
          edge_job_number: { value: data.project.edge_job_number, valid: true },
          project_id: { value: data.compilation.project_id, valid: true },
          current_comp_staff: data.compilation.current_comp_staff,
          comp_staff: data.compilation.comp_staff,
          comp_staff1: data.compilation.comp_staff1,
          comp_staff2: { value: data.compilation.comp_staff2, valid: true },
          comp_done: data.compilation.comp_done,
          hrs_per_model: { value: data.compilation.hrs_per_model, valid: true },
          models_todo: { value: data.compilation.models_todo, valid: true },
          batch_set: data.compilation.batch_set,
          status: { value: data.compilation.status, valid: true },
          setup_quality: { value: data.compilation.setup_quality, valid: true },
          readability: { value: data.compilation.readability, valid: true },
          to_sub: data.compilation.to_sub,
          problem_at: data.compilation.problem_at,
          model_diagram: data.compilation.model_diagram,
          project_notes: { value: data.project.project_notes, valid: true },
          project_manager: data.project.project_manager,
          comp_notes: { value: data.project.comp_notes, valid: true },
          fixed: data.compilation.fixed,
          editing: this.props.match.path === "/cascopt/compilations/:id/edit",
        });
      });
    }
  }

  handleUserInput = (fieldName, fieldValue, validations) => {
    const newFieldState = update(this.state[fieldName], {
      value: { $set: fieldValue },
    });
    this.setState({ [fieldName]: newFieldState }, () => {
      this.validateField(fieldName, fieldValue, validations);
    });
  };

  validateField(fieldName, fieldValue, validations) {
    let fieldValid;

    let fieldErrors = validations.reduce((errors, v) => {
      let e = v(fieldValue);
      if (e !== "") {
        errors.push(e);
      }
      return errors;
    }, []);

    fieldValid = fieldErrors.length === 0;

    const newFieldState = update(this.state[fieldName], {
      valid: { $set: fieldValid },
    });

    const newFormErrors = update(this.state.formErrors, {
      $merge: { [fieldName]: fieldErrors },
    });

    this.setState(
      {
        [fieldName]: newFieldState,
        formErrors: newFormErrors,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.project_id.valid &&
        this.state.sub_alloted_hours.valid &&
        this.state.grid_distance.valid &&
        this.state.models.actual_comp_hours &&
        this.state.models.valid &&
        this.state.edge_job_number.valid &&
        this.state.comp_staff2.valid &&
        this.state.hrs_per_model.valid &&
        this.state.models_todo.valid &&
        this.state.status.valid &&
        this.state.setup_quality.valid &&
        this.state.readability.valid &&
        this.state.comp_done_date.valid &&
        this.state.sub_name.valid &&
        this.state.sub_due_date.valid &&
        this.state.sub_done.valid &&
        this.state.models_to_sub.valid &&
        this.state.models_to_do.valid &&
        this.state.received_date.valid &&
        this.state.rejected_date.valid &&
        this.state.sub_notes.valid &&
        this.state.hours.valid &&
        this.state.project_notes.valid &&
        this.state.comp_notes.valid &&
        this.state.total_comp_hours.valid &&
        this.state.due_date.valid,
    });
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.state.editing ? this.updateCompilation() : this.addCompilation();
  };

  updateCompilation() {
    const compilation = {
      compilation_required: this.state.compilation_required,
      sub_alloted_hours: this.state.sub_alloted_hours.value,
      grid_distance: this.state.grid_distance.value,
      actual_comp_hours: this.state.actual_comp_hours.value,
      sub_name: this.state.sub_name.value,
      need_comp_qc: this.state.need_comp_qc,
      project_manager: this.state.project_manager,
      due_date: this.state.due_date.value,
      sub_done: this.state.sub_done.value,
      models_to_sub: this.state.models_to_sub.value,
      models_to_do: this.state.models_to_do.value,
      received_date: this.state.received_date.value,
      rejected: this.state.rejected,
      rejected_date: this.state.rejected_date.value,
      sub_notes: this.state.sub_notes.value,
      hours: this.state.hours.value,
      total_comp_hours: this.state.total_comp_hours.value,
      comp_done_date: this.state.comp_done_date.value,
      models: this.state.models.value,
      edge_job_number: this.state.edge_job_number.value,
      project_id: this.state.project_id.value,
      current_comp_staff: this.state.current_comp_staff,
      comp_staff: this.state.comp_staff,
      comp_staff1: this.state.comp_staff1,
      comp_staff2: this.state.comp_staff2.value,
      comp_done: this.state.comp_done,
      hrs_per_model: this.state.hrs_per_model.value,
      models_todo: this.state.models_todo.value,
      batch_set: this.state.batch_set,
      status: this.state.status.value,
      setup_quality: this.state.setup_quality.value,
      readability: this.state.readability.value,
      to_sub: this.state.to_sub,
      problem_at: this.state.problem_at,
      project_notes: this.state.project_notes.value,
      comp_notes: this.state.comp_notes.value,
      model_diagram: this.state.model_diagram,
      fixed: this.state.fixed,
    };
    $.ajax({
      type: "PATCH",
      url: `/cascopt/compilations/${this.props.match.params.id}`,
      data: { compilation: compilation },
    })
      .done((data) => {
        console.log("compilation updated!");
        this.resetFormErrors();
        this.props.history.goBack("/cascopt/compilations");
      })
      .fail((response) => {
        alert("Please review the form for errors");
        this.setState({
          formErrors: response.responseJSON,
          formValid: false,
        });
      });
  }

  addCompilation() {
    const compilation = {
      compilation_required: this.state.compilation_required,
      sub_alloted_hours: this.state.sub_alloted_hours.value,
      grid_distance: this.state.grid_distance.value,
      actual_comp_hours: this.state.actual_comp_hours.value,
      sub_name: this.state.sub_name.value,
      need_comp_qc: this.state.need_comp_qc,
      project_manager: this.state.project_manager,
      due_date: this.state.due_date.value,
      sub_done: this.state.sub_done.value,
      models_to_sub: this.state.models_to_sub.value,
      models_to_do: this.state.models_to_do.value,
      received_date: this.state.received_date.value,
      rejected: this.state.rejected,
      rejected_date: this.state.rejected_date.value,
      sub_notes: this.state.sub_notes.value,
      hours: this.state.hours.value,
      total_comp_hours: this.state.total_comp_hours.value,
      comp_done_date: this.state.comp_done_date.value,
      models: this.state.models.value,
      edge_job_number: this.state.edge_job_number.value,
      project_id: this.state.project_id.value,
      current_comp_staff: this.state.current_comp_staff,
      comp_staff: this.state.comp_staff,
      comp_staff1: this.state.comp_staff1,
      comp_staff2: this.state.comp_staff2.value,
      comp_done: this.state.comp_done,
      hrs_per_model: this.state.hrs_per_model.value,
      models_todo: this.state.models_todo.value,
      batch_set: this.state.batch_set,
      status: this.state.status.value,
      setup_quality: this.state.setup_quality.value,
      readability: this.state.readability.value,
      to_sub: this.state.to_sub,
      problem_at: this.state.problem_at,
      project_notes: this.state.project_notes.value,
      comp_notes: this.state.comp_notes.value,
      model_diagram: this.state.model_diagram,
      fixed: this.state.fixed,
    };
    $.post("/cascopt/compilations", { compilation: compilation })
      .done((data) => {
        this.props.handleNewCompilation(data);
        this.resetFormErrors();
      })
      .fail((response) => {
        this.setState({ formErrors: response.responseJSON, formValid: false });
      });
  }

  deleteCompilation = () => {
    if (confirm("Are you sure you want to delete this compilation?")) {
      $.ajax({
        type: "DELETE",
        url: `/cascopt/compilations/${this.props.match.params.id}`,
      })
        .done((data) => {
          this.props.history.push("/cascopt/compilations");
          this.resetFormErrors();
        })
        .fail((response) => {
          console.log("Compilation deletion failed!");
        });
    }
  };

  resetFormErrors() {
    this.setState({ formErrors: {} });
  }

  handleChange(e) {
    this.handleUserInput(
      e.target.name,
      e.target.value,
      CompilationForm.formValidations[e.target.name]
    );
  }

  handleSelectChange(key) {
    return function ({ value }) {
      this.setState({ [key]: value });
    }.bind(this);
  }

  handleMultiSelectChange(key) {
    return function (array) {
      this.setState({ [key]: array });
    }.bind(this);
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }

  shouldDisplaySubCost() {
    const { user_type, user_role } = this.state;
    return (
      user_type.value === "Admin" ||
      user_type.value === "ProductionManager" ||
      user_type.value === "ProjectManager" ||
      user_role.value === "operations"
    );
  }

  render() {
    return (
      <div className="container" id="form-card">
        <h4 className="mb-4 text-4xl">
          {this.state.editing ? "Update Compilation" : "Create Compilation"} for{" "}
          {this.state.job_name.value || ""} |{" "}
          {this.state.client_number.value || ""}-
          {this.state.job_number.value || ""}
        </h4>
        {this.state.problem_at == "Yes" && (
          <div>
            <h5 style={{ color: "red" }}>
              The Problem w/ AT field is marked as <b>Yes</b>, be sure to update
              the field if the problem has been resolved!
            </h5>
          </div>
        )}
        <div id="pt-view-project-block">
          <h5>Project Details</h5>
          Job: {this.state.job_name.value || ""} /{" "}
          {this.state.job_number.value || ""}
          <br />
          Client: {this.state.client_name.value || "n/a"}
          <br />
          Camera: {this.state.camera_number.value || "n/a"} | Roll:{" "}
          {this.state.roll_number.value || "n/a"}| Map Scale:{" "}
          {this.state.map_scale.value || "n/a"} | CI:{" "}
          {this.state.ci.value || "n/a"}| Map Type:{" "}
          {this.state.map_type.value || "n/a"} | Edge Match Job:{" "}
          {this.state.edge_job_number.value || "n/a"}
          <br />
          Project Manager: {this.state.project_manager || ""}
          <br />
          Image Sub: {this.state.image_sub.value || "n/a"}
        </div>
        <small>
          (* represents a <strong>required field</strong>)
        </small>
        <FormErrors formErrors={this.state.formErrors} />
        <form onSubmit={this.handleFormSubmit}>
          <div className="row">
            <div className="col-md-3">
              <label>Is Compilation Required?</label>
              <Select
                required
                name="compilation_required"
                value={this.state.compilation_required}
                onChange={this.handleSelectChange("compilation_required")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
            <div className="col-md-3">
              <label>Grid Distance</label>
              <input
                name="grid_distance"
                value="This field has moved to the DSM view!"
                onChange={this.handleChange}
                readOnly
                className="form-control"
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col">
              <label>Current Comp Staff *</label>
              <Select.Creatable
                name="current_comp_staff"
                value={this.state.current_comp_staff}
                allowCreate={true}
                multi
                simpleValue
                delimiter=", "
                onChange={this.handleMultiSelectChange("current_comp_staff")}
                options={this.state.comp_staff_options}
              />
            </div>
            <div className="col">
              <label>Comp Staff *</label>
              <Select.Creatable
                name="comp_staff"
                value={this.state.comp_staff}
                allowCreate={true}
                multi
                simpleValue
                delimiter=", "
                onChange={this.handleMultiSelectChange("comp_staff")}
                options={this.state.comp_staff_options}
              />
            </div>
            <div className="col">
              <label>To Sub *</label>
              <Select
                required
                name="to_sub"
                value={this.state.to_sub}
                onChange={this.handleSelectChange("to_sub")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col">
              <label>Total Models</label>
              <input
                name="models"
                value={this.state.models.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col">
              <label>Hours per Model *</label>
              <input
                name="hrs_per_model"
                placeholder="Hours per Model"
                value={this.state.hrs_per_model.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col">
              <label>Models To Do *</label>
              <input
                name="models_todo"
                placeholder="Models To Do"
                value={this.state.models_todo.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col">
              <label>Total Hours (hh:mm)*</label>
              <input
                name="total_comp_hours"
                placeholder="Total Comp Hours"
                value={this.state.total_comp_hours.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col">
              <label>Batch Set *</label>
              <Select
                name="batch_set"
                value={this.state.batch_set}
                onChange={this.handleSelectChange("batch_set")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col-md-3">
              <label>Setup Quality *</label>
              <input
                name="setup_quality"
                placeholder="Setup Quality"
                value={this.state.setup_quality.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col-md-3">
              <label>Readability *</label>
              <input
                name="readability"
                placeholder="Readability"
                value={this.state.readability.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col-md-3">
              <label>Model Diagram</label>
              <Select
                name="model_diagram"
                value={this.state.model_diagram}
                onChange={this.handleSelectChange("model_diagram")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col-md-3">
              <label>Compilation Done *</label>
              <Select
                name="comp_done"
                value={this.state.comp_done}
                onChange={this.handleSelectChange("comp_done")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
            {this.state.comp_done == "Yes" && (
              <div className="col-md-3">
                <label>Done Date *</label>
                <input
                  type="date"
                  name="comp_done_date"
                  value={this.state.comp_done_date.value}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            )}
          </div>
          <p />
          <div className="row">
            <div className="col-md-3">
              <label>Problem w/ AT</label>
              <Select
                name="problem_at"
                value={this.state.problem_at}
                onChange={this.handleSelectChange("problem_at")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
          </div>
          <p />
          {this.state.type.value == "PostQc" && (
            <div className="row">
              <div className="col-md-2">
                <label>Post QC - Fixed</label>
                <Select
                  name="fixed"
                  value={this.state.fixed}
                  onChange={this.handleSelectChange("fixed")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
            </div>
          )}
          <p />
          <h5>Comp Sub Info</h5>
          <div className="row">
            <div className="col">
              <label>Sub Name</label>
              <input
                type="text"
                name="sub_name"
                placeholder="Who is doing the Compilation?"
                value={this.state.sub_name.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            {this.shouldDisplaySubCost() && (
              <div className="col">
                <label>Sub Cost</label>
                <input
                  type="text"
                  name="sub_alloted_hours"
                  placeholder="How much is the sub charging?"
                  value={this.state.sub_alloted_hours.value}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            )}
            <div className="col">
              <label>Sub Due Date</label>
              <input
                type="date"
                name="due_date"
                value={this.state.due_date.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col">
              <label>Received From Sub Date</label>
              <input
                type="date"
                name="received_date"
                value={this.state.received_date.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col-md-4">
              <label>Models to Sub</label>
              <input
                type="text"
                name="models_to_sub"
                placeholder="How many models were sent to sub?"
                value={this.state.models_to_sub.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <div className="col-md-4">
              <label>Rejected</label>
              <Select
                name="rejected"
                value={this.state.rejected}
                onChange={this.handleSelectChange("rejected")}
                options={[
                  { value: "No", label: "No" },
                  { value: "Yes", label: "Yes" },
                ]}
              />
            </div>
            <div className="col-md-4">
              <label>Rejected Date</label>
              <input
                type="date"
                name="rejected_date"
                value={this.state.rejected_date.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col">
              <label>Sub Notes</label>
              <textarea
                name="sub_notes"
                placeholder="Notes from Sub"
                value={this.state.sub_notes.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col">
              <label>Notes *</label>
              <textarea
                name="comp_notes"
                placeholder="Comp Notes"
                rows="4"
                cols="40"
                value={this.state.comp_notes.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col">
              <label>Project Notes</label>
              <textarea
                name="project_notes"
                placeholder="Project Notes"
                rows="4"
                cols="40"
                value={this.state.project_notes.value}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
          </div>
          <p />
          <input
            type="submit"
            value={this.state.editing ? "Update" : "Create"}
            className="btn btn-secondary btn-sm"
          />
          <a
            className="btn btn-danger btn-sm"
            onClick={this.props.history.goBack}
          >
            Cancel
          </a>
        </form>
        <br />
      </div>
    );
  }
}
