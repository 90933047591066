/* eslint-disable react/prefer-stateless-function */
import React from "react";

export default class Invoice extends React.Component {
  render() {
    return (
      <div>
        <h3 id="header">Invoices - Partial Billing</h3>
        <a className="view-record" href="/cascopt/invoices">
          View Invoicing - Main Dashboard
        </a>
        <br />
        <a className="view-record" href="/cascopt/invoicing/time_and_materials">
          View Invoicing - Time and Materials Dashboard
        </a>
      </div>
    );
  }
}
