/* eslint-disable react/prefer-stateless-function */
import React from 'react';

export default class Scan extends React.Component {
  render() {
    return (
      <div>
        <h3 id="header">Projects in Scanning</h3>
        <a href="/cascopt/scan_search" className="view-record">
          Scan Search
        </a>
      </div>
    );
  }
}
