import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

export default class Survey extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      survey: props.survey,
      project: props.project,
      isHidden: false
    }
  }

  static propTypes = {
    survey: PropTypes.object
  }

  static defaultProps = {
    survey: {}
  }

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/cascopt/surveys/${this.props.match.params.id}`,
        dataType: "JSON"
      }).done((data) => {
        this.setState({ data });
      })
    }
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden
    })
  }

  render() {
    return (
      <div className="card">
        <div className="container">
          <a href={`/cascopt/surveys`} className="stage-record">
            Back to Survey Dashboard
          </a>
          <hr />
          <Link to={`/cascopt/surveys/${this.state.survey.id}/edit`}>
            <button className="btn btn-sm btn-success">Edit Survey</button>
          </Link>
          <p />
          <button
            onClick={this.toggleHidden.bind(this)}
            title="Click to expand/hide create company form"
            className="btn btn-secondary btn-sm"
          >
            {this.state.isHidden ? (
              <span>
                Show Project Details{" "}
                <i className="fa fa-plus-circle" aria-hidden="true"></i>
              </span>
            ) : (
              <span>
                Hide Project Details{" "}
                <i className="fa fa-minus-circle" aria-hidden="true"></i>
              </span>
            )}
          </button>
          <p />
          {!this.state.isHidden && (
            <p id="pt-view-project-block">
              <h5>Project Details</h5>
              <b>Client Rep:</b> {this.state.project.project_manager} |{" "}
              <b>Project Manager:</b> {this.state.project.asst_pm || "n/a"}
              <hr />
              <div className="form-group row">
                <div className="col-md-6">
                  <b>Job:</b> {this.state.project.job_name || ""} |{" "}
                  {this.state.project.client_number || ""}-
                  {this.state.project.job_number || ""}
                  <br />
                  <b>Edge Job #:</b> {this.state.project.edge_job_number || "n/a"}
                  <br />
                  <b>Due:</b>{" "}
                  {moment(this.state.project.due_date || "").format(
                    "MM/DD/YYYY"
                  )}
                  <br />
                  <b>Client:</b> {this.state.project.client_name || ""}
                  <br />
                  <b>Deliverables:</b>{" "}
                  {this.state.project.deliverables ||
                    "Contact PM for deliverables info"}
                  <br />
                  <b>Location:</b> {this.state.project.project_location || ""}
                  <br />
                  <b>Est. Flight Date:</b>{" "}
                  {moment(this.state.project.est_flight_date || "").format(
                    "MM/DD/YYYY"
                  )}
                  <br />
                </div>
                <div className="col-md-6">
                  <b>Units:</b> {this.state.project.units || "n/a"}
                  <br />
                  <b>Project Type:</b>{" "}
                  {this.state.project.project_type || "n/a"}
                </div>
              </div>
            </p>
          )}
          <br />
          <h4 className="mb-4 text-4xl">Survey Details</h4>
          <hr />
          <form>
            <p>Last Updated: {this.state.project.last_updated}</p>
            <div className="row">
              <div className="col">
                <label>Survey Staff</label>
                <p>{this.state.survey.survey_staff || "None"}</p>
              </div>
              <div className="col">
                <label>Results</label>
                <p>{this.state.survey.results || "n/a"}</p>
              </div>
              <div className="col">
                <label>Coordinate System</label>
                <p>{this.state.survey.coordinate_system || "n/a"}</p>
              </div>
              <div className="col">
                <label>ABGPS</label>
                <p>{this.state.project.abgps || "n/a"}</p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Control Status</label>
                <p>{this.state.project.control_status || "n/a"}</p>
              </div>
              {this.state.project.lidar_cost >= 0 && (
                <div className="col-md-3">
                  <label>Control Sent to LiDAR Flight Sub</label>
                  <p>{this.state.survey.control_lidar_flight || "No"}</p>
                </div>
              )}
              <div className="col-md-3">
                <label>Control In</label>
                <p>
                  {moment(this.state.project.control_in || "n/a").format(
                    "MM/DD/YYYY"
                  )}
                </p>
              </div>
              <div className="col-md-3">
                <label>Survey Done</label>
                <p>{this.state.survey.survey_done || "n/a"}</p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Sent GCP</label>
                <p>
                  {moment(this.state.survey.sent_gcp || "n/a").format(
                    "MM/DD/YYYY"
                  )}
                </p>
              </div>
              <div className="col-md-3">
                <label>Needs Shifted</label>
                <p>
                  {this.state.survey.needs_shifted || "n/a"}
                </p>
              </div>
              <div className="col-md-6">
                <label>Known System</label>
                <p>
                  {this.state.survey.known_system || "n/a"}
                </p>
              </div>
            </div>
            <p />
            {this.state.project.project_type == "Survey Mapping" && 
            <div className="row">
              <div className="col-md-3">
                <label>Survey Mapping Complete</label>
                <p>{this.state.survey.survey_mapping_complete || "n/a"}</p>
              </div>
            </div>}
            <p />
            <div className="row">
              <div className="col">
                <label>Survey Notes</label>
                <p>{this.state.project.survey_notes || "n/a"}</p>
              </div>
            </div>
            <p />
            {this.state.project.lidar_scope && (
              <div className="row">
                <div className="col">
                  <label>LiDAR Scope</label>
                  <p>{this.state.project.lidar_scope || "n/a"}</p>
                </div>
              </div>
            )}
          </form>
          <hr />
          {this.state.project.project_notes && (
            <div>
              <h5>Project Information</h5>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Notes from Project</label>
                  <p>{this.state.project.project_notes || ""}</p>
                </div>
              </div>
              <hr />
            </div>
          )}
          {this.state.project.project_purpose && (
            <div>
              <h5>Project Purpose Information</h5>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Project Purpose</label>
                  <p>{this.state.project.project_purpose || ""}</p>
                </div>
              </div>
              <p />
            </div>
          )}
        </div>
      </div>
    );
  }
}
