/* eslint-disable react/prefer-stateless-function */
import React from 'react';

export default class NotReceived extends React.Component {
  render() {
    return (
      <div>
        <h3 id="header">Projects Ready for AT - Control Status Not Received</h3>
        <a href="/cascopt/aerotriangulations" className="view-record">AT Dashboard</a>
      </div>
    );
  }
}
