import React from 'react';
import PropTypes from 'prop-types';

export default class NotReceived extends React.Component {
  render() {
    return (
      <div>
        <div className="card">
          <div className="card-body">
            <h6 id="header">AT Legend</h6>
            <div className="row">
              <small className="red-legend">Red represents the project is due or past due.</small>
            </div>
            <div className="row">
              <small className="blue-legend">Blue represents the project is due within 5 days</small>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
