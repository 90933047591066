/* eslint-disable react/prefer-stateless-function */
import React from 'react';

export default class Flight extends React.Component {
  render() {
    return (
      <div>
        <h3 id="header">
          Projects in Flight-Photolab
        </h3>
      </div>
    );
  }
}
