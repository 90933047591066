import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Surfaces from './Surfaces';
import SurfaceForm from './SurfaceForm';
import SurfaceView from './SurfaceView';

export default (props) => {
  return (
    <Router>
      <div>
        <Route exact path="/cascopt/surfaces" render={routeProps => <Surfaces {...props} {...routeProps}/>} />
        <Route exact path="/cascopt/surfaces/:id" render={routeProps => <SurfaceView {...props} {...routeProps}/>} />
        <Route path="/cascopt/surfaces/:id/edit" render={routeProps => <SurfaceForm {...props} {...routeProps}/>} />
      </div>
    </Router>
  )
}
