import React from 'react';
import PropTypes from 'prop-types';
import { FormErrors } from './FormErrors';
import update from 'immutability-helper';
import { validations } from '../utils/validations';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

export default class PlotForm extends React.Component {
  static propTypes = {
    handleNewPlot: PropTypes.func
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      client_number: { value: '', valid: false },
      client_name: { value: '', valid: false },
      due_date: { value: '', valid: false },
      job_number: { value: '', valid: false },
      project_id: {value: '', valid: false},
      laminated: '',
      number_of_prints: {value: '', valid: true},
      size: {value: '', valid: true},
      scale: {value: '', valid: true},
      shipping_address: {value: '', valid: true},
      tracking_number: {value: '', valid: true},
      framed: '',
      framed_glass: '',
      plot_done: '',
      project_notes: { value: '', valid: true },
      plot_notes: { value: '', valid: true },
      formErrors: {},
      formValid: false,
      editing: false
    }
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
  }

  static formValidations = {
    project_id: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    number_of_prints: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    size: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    scale: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    shipping_address: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    tracking_number: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    project_notes: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
    plot_notes: [
      (s) => { return(validations.checkMinLength(s, 1)) }
    ],
  }

  componentDidMount() {
    if(this.props.match) {
    $.ajax({
      type: "GET",
      url: `/cascopt/plots/${this.props.match.params.id}`,
      dataType: "JSON"
    }).done((data) => {
      this.setState({
        client_number: { value: data.project.client_number, valid: true },
        client_name: { value: data.project.client_name, valid: true },
        job_number: { value: data.project.job_number, valid: true },
        due_date: { value: data.project.due_date, valid: true },
        project_id: {value: data.plot.project_id, valid: true},
        laminated: data.plot.laminated,
        number_of_prints: {value: data.plot.number_of_prints, valid: true},
        size: {value: data.plot.size, valid: true},
        scale: {value: data.plot.scale, valid: true},
        shipping_address: {value: data.plot.shipping_address, valid: true},
        tracking_number: {value: data.plot.tracking_number, valid: true},
        framed: data.plot.framed,
        framed_glass: data.plot.framed_glass,
        project_notes: {value: data.project.project_notes, valid: true},
        plot_notes: {value: data.project.plot_notes, valid: true},
        plot_done: data.plot.plot_done,
        editing: this.props.match.path === '/cascopt/plots/:id/edit'
      });
    });
   }
  }

  handleUserInput = (fieldName, fieldValue, validations) => {
    const newFieldState = update(this.state[fieldName],
                                  {value: {$set: fieldValue}});
    this.setState({[fieldName]: newFieldState},
                  () => { this.validateField(fieldName, fieldValue, validations) });
  }

  validateField (fieldName, fieldValue, validations) {
    let fieldValid;

    let fieldErrors = validations.reduce((errors, v) => {
      let e = v(fieldValue);
      if(e !== '') {
        errors.push(e);
      }
      return(errors);
    }, []);

    fieldValid = fieldErrors.length === 0;

    const newFieldState = update(this.state[fieldName],
                                  {valid: {$set: fieldValid}});

    const newFormErrors = update(this.state.formErrors,
                                  {$merge: {[fieldName]: fieldErrors}});

    this.setState({[fieldName]: newFieldState,
                    formErrors: newFormErrors}, this.validateForm);
  }

  validateForm() {
    this.setState({formValid: this.state.project_id.valid &&
                              this.state.number_of_prints.valid &&
                              this.state.size.valid &&
                              this.state.scale.valid &&
                              this.state.shipping_address.valid &&
                              this.state.tracking_number.valid &&
                              this.state.project_notes.valid &&
                              this.state.plot_notes.valid
                            });
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.state.editing ?
      this.updatePlot() :
      this.addPlot();
  }

  updatePlot() {
    const plot = {project_id: this.state.project_id.value,
      laminated: this.state.laminated,
      number_of_prints: this.state.number_of_prints.value,
      size: this.state.size.value,
      scale: this.state.scale.value,
      shipping_address: this.state.shipping_address.value,
      tracking_number: this.state.tracking_number.value,
      framed: this.state.framed,
      framed_glass: this.state.framed_glass,
      project_notes: this.state.project_notes.value,
      plot_notes: this.state.plot_notes.value,
      plot_done: this.state.plot_done};
    $.ajax({
      type: "PATCH",
      url: `/cascopt/plots/${this.props.match.params.id}`,
      data: {plot: plot}
    })
    .done((data) => {
      console.log('plot updated!');
      this.resetFormErrors();
      this.props.history.goBack('/cascopt/plots');
    })
    .fail((response) => {
      alert("Please review the form for errors");
      this.setState({
        formErrors: response.responseJSON,
        formValid: false
       })
      });
  }

  addPlot() {
    const plot = {project_id: this.state.project_id.value,
      laminated: this.state.laminated,
      number_of_prints: this.state.number_of_prints.value,
      size: this.state.size.value,
      scale: this.state.scale.value,
      shipping_address: this.state.shipping_address.value,
      tracking_number: this.state.tracking_number.value,
      framed: this.state.framed,
      framed_glass: this.state.framed_glass,
      project_notes: this.state.project_notes.value,
      plot_notes: this.state.plot_notes.value,
      plot_done: this.state.plot_done};
    $.post('/cascopt/plots', {plot: plot})
    .done((data) => {
      this.props.handleNewPlot(data);
      this.resetFormErrors();
    })
    .fail((response) => {
      this.setState({formErrors: response.responseJSON, formValid: false})
    });
  }

  deletePlot = () => {
    if(confirm("Are you sure you want to delete this plot?")) {
      $.ajax({
        type: "DELETE",
        url: `/cascopt/plots/${this.props.match.params.id}`,
      })
      .done((data) => {
        this.props.history.push('/cascopt/plots');
        this.resetFormErrors();
      })
      .fail((response) => {
        console.log("Plot deletion failed!");
      });
    }
  }

  resetFormErrors() {
    this.setState({formErrors: {}})
  }

  handleChange(e) {
    this.handleUserInput(e.target.name, e.target.value, PlotForm.formValidations[e.target.name]);
  }

  handleSelectChange(key) {
  return function ({ value }) {
      this.setState({[key]: value});
    }.bind(this);
  }

  render () {
    return (
      <div className="container" id="form-card">
        <h4 className="mb-4 text-4xl">
        {this.state.editing ?
          'Update Plot' :
            'Create Plot'} for |{this.state.client_number.value} - {this.state.job_number.value}|
        </h4>
        <p>Due: {moment(this.state.due_date.value).format("MM/DD/YYYY")}</p>
        <p>Client: {this.state.client_name.value}</p>
        <small>
          (* represents a <strong>required field</strong>)
        </small>
        <FormErrors formErrors = {this.state.formErrors} />
        <form onSubmit={this.handleFormSubmit} >
          <div className="row">
            <div className="col">
              <label>Laminated *</label>
              <Select
                name="laminated"
                value={this.state.laminated}
                onChange={this.handleSelectChange("laminated")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
            <div className="col">
              <label>Number of Prints *</label>
              <input name="number_of_prints" placeholder="Number of Prints"
                value={this.state.number_of_prints.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
            <div className="col">
              <label>Size *</label>
              <input name="size" placeholder="Size"
                value={this.state.size.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
            <div className="col">
              <label>Scale *</label>
              <input name="scale" placeholder="Scale"
                value={this.state.scale.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col">
              <label>Shipping Address *</label>
              <textarea name="shipping_address" placeholder="Shipping Address"
                value={this.state.shipping_address.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col-md-3">
              <label>Tracking Number *</label>
              <input name="tracking_number" placeholder="Tracking Number"
                value={this.state.tracking_number.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
            <div className="col-md-3">
              <label>Framed *</label>
              <Select
                name="framed"
                value={this.state.framed}
                onChange={this.handleSelectChange("framed")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
            <div className="col-md-3">
              <label>Framed w/Glass *</label>
              <Select
                name="framed_glass"
                value={this.state.framed_glass}
                onChange={this.handleSelectChange("framed_glass")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col-md-3">
              <label>Plot Done *</label>
              <Select
                name="plot_done"
                value={this.state.plot_done}
                onChange={this.handleSelectChange("plot_done")}
                options={[
                  { value: 'No', label: 'No' },
                  { value: 'Yes', label: 'Yes' },
                ]}
              />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col">
              <label>Notes *</label>
              <textarea name="plot_notes" placeholder="Notes"
                value={this.state.plot_notes.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
          </div>
          <p />
          <div className="row">
            <div className="col">
              <label>Project Notes</label>
              <textarea name="project_notes" placeholder="Project Notes"
                value={this.state.project_notes.value}
                onChange={this.handleChange}
                className="form-control" />
            </div>
          </div>
          <p />
          <input type="submit"
            value={this.state.editing ? 'Update' : 'Create'} className="btn btn-secondary btn-sm"
          />
          <a className="btn btn-danger btn-sm" onClick={this.props.history.goBack}>Cancel</a>
        </form>
        <br />
      </div>
    )
  }
}
