import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

export default class Surface extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      surface: props.surface,
      project: props.project,
      isHidden: false,
    };
  }

  static propTypes = {
    surface: PropTypes.object,
  };

  static defaultProps = {
    surface: {},
  };

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/cascopt/surfaces/${this.props.match.params.id}`,
        dataType: "JSON",
      }).done((data) => {
        this.setState({ data });
      });
    }
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }

  render() {
    return (
      <div className="card">
        <div className="container">
          <a href={`/cascopt/surfaces`} className="stage-record">
            Back to Surface Dashboard
          </a>
          <hr />
          <Link to={`/cascopt/surfaces/${this.state.surface.id}/edit`}>
            <button className="btn btn-sm btn-success">Edit Surface</button>
          </Link>
          <p />
          <button
            onClick={this.toggleHidden.bind(this)}
            className="btn btn-secondary btn-sm"
          >
            {this.state.isHidden ? (
              <span>
                Show Project Details{" "}
                <i className="fa fa-plus-circle" aria-hidden="true"></i>
              </span>
            ) : (
              <span>
                Hide Project Details{" "}
                <i className="fa fa-minus-circle" aria-hidden="true"></i>
              </span>
            )}
          </button>
          <p />
          {!this.state.isHidden && (
            <p id="pt-view-project-block">
              <h5>Project Details</h5>
              <b>Client Rep:</b> {this.state.project.project_manager} |{" "}
              <b>Project Manager:</b> {this.state.project.asst_pm || "n/a"}
              <hr />
              <div className="form-group row">
                <div className="col-md-6">
                  <b>Job:</b> {this.state.project.job_name || ""} |{" "}
                  {this.state.project.client_number || ""}-
                  {this.state.project.job_number || ""}
                  <br />
                  <b>Edge Job #:</b> {this.state.project.edge_job_number || "n/a"}
                  <br />
                  <b>Due:</b>{" "}
                  {moment(this.state.project.due_date || "").format(
                    "MM/DD/YYYY"
                  )}
                  <br />
                  <b>Client:</b> {this.state.project.client_name || ""}
                  <br />
                  <b>Deliverables:</b>{" "}
                  {this.state.project.deliverables ||
                    "Contact PM for deliverables info"}
                  <br />
                  <b>Location:</b> {this.state.project.project_location || ""}
                  <br />
                  <b>Est. Flight Date:</b>{" "}
                  {moment(this.state.project.est_flight_date || "").format(
                    "MM/DD/YYYY"
                  )}
                  <br />
                </div>
                <div className="col-md-6">
                  <b>Units:</b> {this.state.project.units || "n/a"}
                  <br />
                  <b>Project Type:</b>{" "}
                  {this.state.project.project_type || "n/a"}
                </div>
              </div>
            </p>
          )}
          <br />
          Last Updated: {this.state.project.last_updated}
          <hr />
          <form>
            <div className="row">
              <div className="col">
                <label>Surface Complete</label>
                <p>{this.state.surface.complete || "n/a"}</p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Surface Notes</label>
                <p>{this.state.surface.notes || "n/a"}</p>
              </div>
            </div>
            <p />
            {this.state.project.lidar_scope && (
              <div className="row">
                <div className="col">
                  <label>LiDAR Scope</label>
                  <p>{this.state.project.lidar_scope || "n/a"}</p>
                </div>
              </div>
            )}
          </form>
          <hr />
          {this.state.project.project_notes && (
            <div>
              <h5>Project Information</h5>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Notes from Project</label>
                  <p>{this.state.project.project_notes || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.project_purpose && (
            <div>
              <h5>Project Purpose Information</h5>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Project Purpose</label>
                  <p>{this.state.project.project_purpose || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
        </div>
      </div>
    );
  }
}
