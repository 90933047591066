import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Invoices from './Invoices';
import InvoiceForm from './InvoiceForm';
import InvoiceView from './InvoiceView';

export default (props) => {
  return (
    <Router>
      <div>
        <Route exact path="/cascopt/invoices" render={routeProps => <Invoices {...props} {...routeProps}/>} />
        <Route exact path="/cascopt/invoices/:id" render={routeProps => <InvoiceView {...props} {...routeProps}/>} />
        <Route path="/cascopt/invoices/:id/edit" component={InvoiceForm} />
      </div>
    </Router>
  )
}
