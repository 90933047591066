import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Aerotriangulations from './Aerotriangulations';
import AerotriangulationForm from './AerotriangulationForm';
import AeroView from './AeroView';

export default (props) => {
  return (
    <Router>
      <div>
        <Route exact path="/cascopt/aerotriangulations" render={routeProps => <Aerotriangulations {...props} {...routeProps}/>} />
        <Route exact path="/cascopt/aerotriangulations/:id" render={routeProps => <AeroView {...props} {...routeProps}/>} />
        <Route path="/cascopt/aerotriangulations/:id/edit" component={AerotriangulationForm} />
      </div>
    </Router>
  )
}
