import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import update from "immutability-helper";
import { withRouter } from "react-router-dom";
import 'react-select/dist/react-select.css';

export default class FlightVendorForm extends React.Component {
  static propTypes = {
    handleNewFlightVendor: PropTypes.func,
  };

  constructor(props, _railsContext) {
    super(props);
    this.state = {
      company: "",
      contact: "",
      email: "",
      phone: "",
      phone_alt: "",
      fax: "",
      website: "",
      camera: "",
      address: "",
      city: "",
      state: "",
      country: "",
      zip: "",
      digital: "",
      film: "",
      coverage_states: "",
      states_options: [
        { value: 'AL', label: 'AL - Alabama' },
        { value: 'AK', label: 'AK - Alaska' },
        { value: 'AZ', label: 'AZ - Arizona' },
        { value: 'AR', label: 'AR - Arkansas' },
        { value: 'CA', label: 'CA - California' },
        { value: 'CO', label: 'CO - Colorado' },
        { value: 'CT', label: 'CT - Connecticut' },
        { value: 'DC', label: 'DC - District of Columbia' },
        { value: 'DE', label: 'DE - Delaware' },
        { value: 'FL', label: 'FL - Florida' },
        { value: 'GA', label: 'GA - Georgia' },
        { value: 'HI', label: 'HI - Hawaii' },
        { value: 'ID', label: 'ID - Idaho' },
        { value: 'IL', label: 'IL - Illinois' },
        { value: 'IN', label: 'IN - Indiana' },
        { value: 'IA', label: 'IA - Iowa' },
        { value: 'KS', label: 'KS - Kansas' },
        { value: 'KY', label: 'KY - Kentucky' },
        { value: 'LA', label: 'LA - Louisiana' },
        { value: 'ME', label: 'ME - Maine' },
        { value: 'MD', label: 'MD - Maryland' },
        { value: 'MA', label: 'MA - Massachusetts' },
        { value: 'MI', label: 'MI - Michigan' },
        { value: 'MN', label: 'MN - Minnesota' },
        { value: 'MS', label: 'MS - Mississippi' },
        { value: 'MO', label: 'MO - Missouri' },
        { value: 'MT', label: 'MT - Montana' },
        { value: 'NE', label: 'NE - Nebraska' },
        { value: 'NV', label: 'NV - Nevada' },
        { value: 'NH', label: 'NH - New Hampshire' },
        { value: 'NJ', label: 'NJ - New Jersey' },
        { value: 'NM', label: 'NM - New Mexico' },
        { value: 'NY', label: 'NY - New York' },
        { value: 'NC', label: 'NC - North Carolina' },
        { value: 'ND', label: 'ND - North Dakota' },
        { value: 'OH', label: 'OH - Ohio' },
        { value: 'OK', label: 'OK - Oklahoma' },
        { value: 'OR', label: 'OR - Oregon' },
        { value: 'PA', label: 'PA - Pennslyvania' },
        { value: 'RI', label: 'RI - Rhode Island' },
        { value: 'SC', label: 'SC - South Carolina' },
        { value: 'SD', label: 'SD - South Dakota' },
        { value: 'TN', label: 'TN - Tennessee' },
        { value: 'TX', label: 'TX - Texas' },
        { value: 'UT', label: 'UT - Utah' },
        { value: 'VT', label: 'VT - Vermont' },
        { value: 'VA', label: 'VA - Virginia' },
        { value: 'WA', label: 'WA - Washington' },
        { value: 'WV', label: 'WV - West Virginia' },
        { value: 'WI', label: 'WI - Wisconsin' },
        { value: 'WY', label: 'WY - Wyoming' },
      ],
    };
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/flight_vendors/${this.props.match.params.id}`,
        dataType: "JSON",
      }).done((data) => {
        this.setState({
          company: data.company,
          contact: data.contact,
          email: data.email,
          phone: data.phone,
          phone_alt: data.phone_alt,
          fax: data.fax,
          website: data.website,
          camera: data.camera,
          address: data.address,
          city: data.city,
          state: data.state,
          country: data.country,
          zip: data.zip,
          digital: data.digital,
          film: data.film,
          coverage_states: data.coverage_states,
          editing: this.props.match.path === "/flight_vendors/:id/edit",
        });
      });
    }
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.state.editing ? this.updateFlightVendor() : this.addFlightVendor();
  };

  updateFlightVendor() {
    const flight_vendor = {
      company: this.state.company,
      contact: this.state.contact,
      email: this.state.email,
      phone: this.state.phone,
      phone_alt: this.state.phone_alt,
      fax: this.state.fax,
      website: this.state.website,
      camera: this.state.camera,
      address: this.state.address,
      city: this.state.city,
      state: this.state.state,
      country: this.state.country,
      zip: this.state.zip,
      digital: this.state.digital,
      film: this.state.film,
      coverage_states: this.state.coverage_states,
    };
    $.ajax({
      type: "PATCH",
      url: `/flight_vendors/${this.props.match.params.id}`,
      data: { flight_vendor: flight_vendor },
    })
      .done((_data) => {
        console.log("flight_vendor updated!");
      })
      .fail((_response) => {
        console.log("did not work");
        alert("Please review the form for errors");
      });
  }

  addFlightVendor() {
    const flight_vendor = {
      company: this.state.company,
      contact: this.state.contact,
      email: this.state.email,
      phone: this.state.phone,
      phone_alt: this.state.phone_alt,
      fax: this.state.fax,
      website: this.state.website,
      camera: this.state.camera,
      address: this.state.address,
      city: this.state.city,
      state: this.state.state,
      country: this.state.country,
      zip: this.state.zip,
      digital: this.state.digital,
      film: this.state.film,
      coverage_states: this.state.coverage_states,
    };
    $.post("/flight_vendors", { flight_vendor: flight_vendor })
      .done((data) => {
        this.props.handleNewFlightVendor(data);
        console.log("Flight Vendor Successfully Created!");
      })
      .fail((_response) => {
        console.log("did not work");
        alert("Please review the form for errors");
      });
  }

  deleteFlightVendor = () => {
    if (confirm("Are you sure you want to delete this vendor?")) {
      $.ajax({
        type: "DELETE",
        url: `/flight_vendors/${this.props.match.params.id}`,
      })
        .done((_data) => {
          this.props.history.push("/flight_vendors");
        })
        .fail((_response) => {
          console.log("Vendor deletion failed!");
        });
    }
  };

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSelectChange(key) {
    return function ({ value }) {
      this.setState({ [key]: value });
    }.bind(this);
  }

  handleMultiSelectChange(key) {
    return function (array) {
      this.setState({ [key]: array });
    }.bind(this);
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          {this.state.editing && (
            <div>
              <a
                href={`/flight_vendors`}
                title="Click to go back to main vendor dashboard"
                className="btn btn-sm btn-light"
              >
                Back to Vendor Dashboard
              </a>
              <hr />
              <h3 className="mb-4 text-4xl">Update {this.state.name || ""}</h3>
            </div>
          )}
          <h4 className="mb-4 text-4xl">{this.state.editing ? "" : "Create Vendor"}</h4>
          <form onSubmit={this.handleFormSubmit}>
            <div className="row">
              <div className="col-lg-6">
                <label title="Enter vendor name">Name</label>
                <input
                  type="text"
                  name="company"
                  placeholder="Flight Vendor Name"
                  required
                  value={this.state.company}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-lg-6">
                <label title="Enter vendor website">Website</label>
                <input
                  type="text"
                  name="website"
                  placeholder="Flight Vendor Website"
                  value={this.state.website}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-lg-6">
                <label title="Enter vendor contact name">Contact Name</label>
                <input
                  type="text"
                  name="contact"
                  required
                  placeholder="Flight Vendor Contact"
                  value={this.state.contact}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-lg-6">
                <label title="Enter vendor email">Email</label>
                <input
                  type="text"
                  name="email"
                  required
                  placeholder="Email"
                  value={this.state.email}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-lg-4">
                <label title="Enter vendor phone">Phone #</label>
                <input
                  type="text"
                  name="phone"
                  required
                  placeholder="Phone #"
                  value={this.state.phone}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-lg-4">
                <label title="Enter vendor alt. phone">Alt. Phone #</label>
                <input
                  type="text"
                  name="phone_alt"
                  placeholder="Alt. Phone #"
                  value={this.state.phone_alt}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-lg-4">
                <label title="Enter vendor fax">Fax</label>
                <input
                  type="text"
                  name="fax"
                  placeholder="Fax #"
                  value={this.state.fax}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-lg-12">
                <label title="Enter vendor address">Address</label>
                <input
                  type="text"
                  name="address"
                  required
                  placeholder="Address"
                  value={this.state.address}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-4">
                <label title="Enter vendor city">City</label>
                <input
                  type="text"
                  name="city"
                  required
                  placeholder="City"
                  value={this.state.city}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-md-2">
                <div>
                  <label>State</label>
                  <Select.Creatable
                    name="state"
                    value={this.state.state}
                    allowCreate={true}
                    required
                    multi
                    simpleValue
                    delimiter=", "
                    onChange={this.handleMultiSelectChange("state")}
                    options={this.state.states_options}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <label title="Enter vendor zip">Zip</label>
                <input
                  type="text"
                  name="zip"
                  required
                  placeholder="Zip Code"
                  value={this.state.zip}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-md-4">
                <label title="Enter vendor country">Country</label>
                <input
                  type="text"
                  name="country"
                  required
                  placeholder="Country"
                  value={this.state.country}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-4">
                <label title="Enter vendor camera">Camera</label>
                <input
                  type="text"
                  name="camera"
                  placeholder="Camera"
                  value={this.state.camera}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-md-4">
                <label title="Does the vendor do digital?">Digital Flights</label>
                <Select
                  name="digital"
                  value={this.state.digital}
                  onChange={this.handleSelectChange("digital")}
                  options={[
                    { value: 'No', label: 'No' },
                    { value: 'Yes', label: 'Yes' },

                  ]}
                />
              </div>
              <div className="col-md-4">
                <label title="Does the vendor do film?">Film Flights</label>
                <Select
                  name="film"
                  value={this.state.film}
                  onChange={this.handleSelectChange("film")}
                  options={[
                    { value: 'No', label: 'No' },
                    { value: 'Yes', label: 'Yes' },

                  ]}
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <div>
                  <label>Coverage States</label>
                  <Select.Creatable
                    name="coverage_states"
                    value={this.state.coverage_states}
                    allowCreate={true}
                    multi
                    simpleValue
                    delimiter=", "
                    onChange={this.handleMultiSelectChange("coverage_states")}
                    options={this.state.states_options}
                  />
                </div>
              </div>
            </div>
            <p />
            <input
              title="Click to submit"
              type="submit"
              value={this.state.editing ? "Update Vendor" : "Create Vendor"}
              className="btn btn-secondary btn-sm"
            />
          </form>
          <br />
        </div>
      </div>
    );
  }
}
