import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import update from 'immutability-helper';

export default class Company extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      company: props.company,
      offices: [],
      isHidden: true
    }
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden
    })
  }

  static propTypes = {
    company: PropTypes.object
  }

  static defaultProps = {
    company: {}
  }

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/companies/${this.props.match.params.id}`,
        dataType: "JSON"
      }).done((data) => {
        this.setState({ company: data });
      })
    }
  }

  addNewOffice = (office) => {
    const offices = update(this.state.offices, { $push: [office]});
    this.setState({
      offices: offices.sort(function(a,b){
        return new Date(a.created_at) - new Date(b.created_at);
      })
    });
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <a
            href={`/companies`}
            title="Click to go back to main company dashboard"
            className="btn btn-sm btn-light"
          >
            Back to Company Dashboard
          </a>
          <hr />
          <h3 className="mb-4 text-4xl">{this.state.company.name || ""}</h3>
          <Link to={`/companies/${this.state.company.id}/edit`}>
            <button
              title="Click to edit this company"
              className="btn btn-sm btn-success"
            >
              Edit Company
            </button>
          </Link>
          <p />
          <form>
            <div className="row">
              <div className="col-md-3">
                <label>Company Website</label>
                <p>{this.state.company.website || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Company FTP</label>
                <p>{this.state.company.ftp_name || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>FTP Password</label>
                <p>{this.state.company.ftp_password || "n/a"}</p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Premium Charge</label>
                <p>{this.state.company.premium_charge || "n/a"}</p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Alert</label>
                <p>{this.state.company.alert || "n/a"}</p>
              </div>
              <div className="col-md-6">
                <label>Alert Message</label>
                <p>{this.state.company.alert_message || "n/a"}</p>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
