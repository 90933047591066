import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import 'react-select/dist/react-select.css';

export default class SpecificationForm extends React.Component {
  static propTypes = {
    handleNewSpecification: PropTypes.func
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      project_units: "",
      project_unit_options: [
        { value: "US Survey Feet", label: "US Survey Feet" },
        { value: "Intl Feet", label: "Intl Feet" },
      ],
      mapping_only: "",
      image_only: "",
      map_scale: "",
      map_scale_options: [{ value: "20", label: "20" }],
      gsd: "",
      gsd_options: [
        { value: "", label: "" },
        { value: "1.67cm", label: "1.67cm" },
        { value: "2cm", label: "2cm" },
        { value: "2.5cm", label: "2.5cm" },
        { value: "3.5cm", label: "3.5cm" },
        { value: "4cm", label: "4cm" },
        { value: "5cm", label: "5cm" },
        { value: "7.2cm", label: "7.2cm" },
        { value: "10cm", label: "10cm" },
        { value: "16cm", label: "16cm" },
        { value: "18cm", label: "18cm" },
      ],
      ci: "",
      ci_options: [{ value: "1", label: "1" }],
      models: "",
      map_type: "",
      file_format: "",
      version: "",
      flight_lines: "",
      exposures: "",
      notes: "",
      map_prs: "",
      stereo_prs_options: [
        { value: "1:2000", label: "1:2000" },
        { value: "1:3600", label: "1:3600" },
        { value: "1:7200", label: "1:7200" },
        { value: "1:9600", label: "1:9600" },
        { value: "1:12000", label: "1:12000" },
        { value: "1:14400", label: "1:44400" },
        { value: "1:24000", label: "1:24000" },
        { value: "1:2400", label: "1:2400" },
        { value: "1:2800", label: "1:2800" },
        { value: "1:3000", label: "1:3000" },
        { value: "1:3300", label: "1:3300" },
        { value: "1:3340", label: "1:3340" },
        { value: "1:3360", label: "1:3360" },
        { value: "1:3400", label: "1:3400" },
        { value: "1:4000", label: "1:4000" },
        { value: "1:4800", label: "1:4800" },
        { value: "1:5000", label: "1:5000" },
        { value: "1:5400", label: "1:5400" },
        { value: "1:6000", label: "1:6000" },
        { value: "1:6400", label: "1:6400" },
        { value: "1:6600", label: "1:6600" },
        { value: "1:8000", label: "1:8000" },
        { value: "1:10000", label: "1:10000" },
        { value: "1:13000", label: "1:13000" },
        { value: "1:14000", label: "1:14000" },
        { value: "1:16000", label: "1:16000" },
        { value: "1:18000", label: "1:18000" },
        { value: "1:20000", label: "1:20000" },
        { value: "1:28000", label: "1:28000" },
      ],
      film_type: "",
      units: "",
      diff_img_flight: "",
      ppsm: "",
      acreage: "",
      square_miles: "",
      bare_earth_only: "",
      detail_level: "",
      quantity: "",
      laminated: "",
      size_y: "",
      size_x: "",
      frame: "",
      logo: "",
      frame_color: "",
      classification: "",
      mounted: "",
      plot_options: "",
      scan_res: "",
      number_scans: "",
      deliverable: "",
      location: "",
      staff: "",
      uav_mapping: "No",
      uav_oblique: "No",
      uav_video: "No",
      location_number: "",
      flights: "",
      oblique_desc: "",
      video_desc: "",
      total_videos: "",
      obliques_locationss: "",
      obliques_exposures: "",
      video_locations: "",
      size: "",
      editing: false,
      spec_type: this.props.specType || "",
      user_id: this.props.userId || "",
      ...props.specification,
    };
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if(this.props.match) {
    $.ajax({
      type: "GET",
      url: `/specifications/${this.props.match.params.id}`,
      dataType: "JSON"
    }).done((data) => {
      this.setState({
        project_units: data.specification.project_units,
        user_id: data.specification.user_id,
        opportunity_id: data.specification.opportunity_id,
        mapping_only: data.specification.mapping_only,
        image_only: data.specification.image_only,
        map_scale: data.specification.map_scale,
        gsd: data.specification.gsd,
        ci: data.specification.ci,
        models: data.specification.models,
        map_type: data.specification.map_type,
        file_format: data.specification.file_format,
        version: data.specification.version,
        flight_lines: data.specification.flight_lines,
        exposures: data.specification.exposures,
        notes: data.specification.notes,
        map_prs: data.specification.map_prs,
        film_type: data.specification.film_type,
        units: data.specification.units,
        diff_img_flight: data.specification.diff_img_flight,
        ppsm: data.specification.ppsm,
        acreage: data.specification.acreage,
        square_miles: data.specification.square_miles,
        bare_earth_only: data.specification.bare_earth_only,
        detail_level: data.specification.detail_level,
        quantity: data.specification.quantity,
        laminated: data.specification.laminated,
        size_y: data.specification.size_y,
        size_x: data.specification.size_x,
        spec_type: data.specification.spec_type,
        frame: data.specification.frame,
        logo: data.specification.logo,
        frame_color: data.specification.frame_color,
        classification: data.specification.classification,
        mounted: data.specification.mounted,
        plot_options: data.specification.plot_options,
        scan_res: data.specification.scan_res,
        number_scans: data.specification.number_scans,
        deliverable: data.specification.deliverable,
        location: data.specification.location,
        staff: data.specification.staff,
        uav_mapping: data.specification.uav_mapping,
        uav_oblique: data.specification.uav_oblique,
        uav_video: data.specification.uav_video,
        location_number: data.specification.location_number,
        flights: data.specification.flights,
        oblique_desc: data.specification.oblique_desc,
        video_desc: data.specification.video_desc,
        total_videos: data.specification.total_videos,
        obliques_locations: data.specification.obliques_locations,
        obliques_exposures: data.specification.obliques_exposures,
        video_locations: data.specification.video_location,
        size: data.specification.size,
        editing: this.props.match.path === '/specifications/:id/edit'
      });
    });
   }
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.state.editing ?
      this.updateSpecification() :
      this.addSpecification();
  }

  updateSpecification() {
    const specification = {
      project_units: this.state.project_units,
      user_id: this.props.userId,
      opportunity_id: this.props.oppId,
      mapping_only: this.state.mapping_only,
      image_only: this.state.image_only,
      map_scale: this.state.map_scale,
      gsd: this.state.gsd,
      ci: this.state.ci,
      models: this.state.models,
      map_type: this.state.map_type,
      file_format: this.state.file_format,
      version: this.state.version,
      flight_lines: this.state.flight_lines,
      exposures: this.state.exposures,
      notes: this.state.notes,
      map_prs: this.state.map_prs,
      film_type: this.state.film_type,
      units: this.state.units,
      diff_img_flight: this.state.diff_img_flight,
      ppsm: this.state.ppsm,
      acreage: this.state.acreage,
      square_miles: this.state.square_miles,
      bare_earth_only: this.state.bare_earth_only,
      detail_level: this.state.detail_level,
      quantity: this.state.quantity,
      laminated: this.state.laminated,
      size_y: this.state.size_y,
      size_x: this.state.size_x,
      spec_type: this.props.specType,
      frame: this.state.frame,
      logo: this.state.logo,
      frame_color: this.state.frame_color,
      classification: this.state.classification,
      mounted: this.state.mounted,
      plot_options: this.state.plot_options,
      scan_res: this.state.scan_res,
      number_scans: this.state.number_scans,
      deliverable: this.state.deliverable,
      location: this.state.location,
      staff: this.state.staff,
      uav_mapping: this.state.uav_mapping,
      uav_oblique: this.state.uav_oblique,
      uav_video: this.state.uav_video,
      location_number: this.state.location_number,
      flights: this.state.flights,
      oblique_desc: this.state.oblique_desc,
      video_desc: this.state.video_desc,
      total_videos: this.state.total_videos,
      obliques_locations: this.state.obliques_locations,
      obliques_exposures: this.state.obliques_exposures,
      video_locations: this.state.video_locations,
      size: this.state.size,
    };
    $.ajax({
      type: "PATCH",
      url: `/specifications/${this.props.match.params.id}`,
      data: { specification: specification }
    })
      .done((data) => {
        console.log('Specification updated!');
      })
      .fail((response) => {
        console.log("did not work");
        alert("Please review the form for errors");
      });
  }

  addSpecification() {
    const specification = {
      project_units: this.state.project_units,
      user_id: this.props.userId,
      opportunity_id: this.props.oppId,
      mapping_only: this.state.mapping_only,
      image_only: this.state.image_only,
      map_scale: this.state.map_scale,
      gsd: this.state.gsd,
      ci: this.state.ci,
      models: this.state.models,
      map_type: this.state.map_type,
      file_format: this.state.file_format,
      version: this.state.version,
      flight_lines: this.state.flight_lines,
      exposures: this.state.exposures,
      notes: this.state.notes,
      map_prs: this.state.map_prs,
      film_type: this.state.film_type,
      units: this.state.units,
      diff_img_flight: this.state.diff_img_flight,
      ppsm: this.state.ppsm,
      acreage: this.state.acreage,
      square_miles: this.state.square_miles,
      bare_earth_only: this.state.bare_earth_only,
      detail_level: this.state.detail_level,
      quantity: this.state.quantity,
      laminated: this.state.laminated,
      size_y: this.state.size_y,
      size_x: this.state.size_x,
      spec_type: this.props.specType,
      frame: this.state.frame,
      logo: this.state.logo,
      frame_color: this.state.frame_color,
      classification: this.state.classification,
      mounted: this.state.mounted,
      plot_options: this.state.plot_options,
      scan_res: this.state.scan_res,
      number_scans: this.state.number_scans,
      deliverable: this.state.deliverable,
      location: this.state.location,
      staff: this.state.staff,
      uav_mapping: this.state.uav_mapping,
      uav_oblique: this.state.uav_oblique,
      uav_video: this.state.uav_video,
      location_number: this.state.location_number,
      flights: this.state.flights,
      oblique_desc: this.state.oblique_desc,
      video_desc: this.state.video_desc,
      total_videos: this.state.total_videos,
      obliques_locations: this.state.obliques_locations,
      obliques_exposures: this.state.obliques_exposures,
      video_locations: this.state.video_locations,
      size: this.state.size,
    };
    $.post('/specifications', { specification: specification })
      .done((data) => {
        this.props.handleNewSpecification(data);
        console.log("Specification Successfully Created!");
      })
      .fail((response) => {
        console.log("did not work");
        alert("Please review the form for errors");
      });
  }

  deleteSpecification = () => {
    if (confirm("Are you sure you want to delete this specification?")) {
      $.ajax({
        type: "DELETE",
        url: `/specifications/${this.props.match.params.id}`,
      })
        .done((data) => {
          this.props.history.push('/specifications');
        })
        .fail((response) => {
          console.log("Specification deletion failed!");
        });
    }
  }

  handleChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  handleSelectChange(key) {
    return function ({ value }) {
      this.setState({ [key]: value });
    }.bind(this);
  }

  render() {
    // var exposures = parseInt(this.state.models) + parseInt(this.state.flight_lines);
    // var exposuresCount = exposures;
    // this.state.exposures = exposuresCount;

    return (
      <div className="card">
        <div className="card-body">
          <h4 className="mb-4 text-4xl">
            {this.state.editing
              ? "Update UAV Specification"
              : "Create UAV Specification"}
          </h4>
          <small>
            (* represents a <strong>required field</strong>)
          </small>
          <form onSubmit={this.handleFormSubmit}>
            <div className="row">
              <div className="col-md-3">
                <label title="The type of specification the form is for, this is set per the opportunity type">
                  Spec Type *
                </label>
                <Select
                  name="spec_type"
                  disabled={true}
                  value={this.state.spec_type}
                  options={[
                    { value: "Film", label: "Film" },
                    { value: "Digital", label: "Digital" },
                    { value: "LiDAR", label: "LiDAR" },
                    { value: "Scan", label: "Scan" },
                    { value: "Plot", label: "Plot" },
                    { value: "Survey", label: "Survey" },
                    { value: "Flight", label: "Flight" },
                    { value: "UAV", label: "UAV" },
                    { value: "Other", label: "Other" },
                  ]}
                />
              </div>
              <div className="col-md-3">
                <label title="Does this project include UAV mapping?">
                  UAV Mapping *
                </label>
                <Select
                  name="uav_mapping"
                  value={this.state.uav_mapping}
                  onChange={this.handleSelectChange("uav_mapping")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
              <div className="col-md-3">
                <label title="Does this project include UAV obliques?">
                  UAV Oblique *
                </label>
                <Select
                  name="uav_oblique"
                  value={this.state.uav_oblique}
                  onChange={this.handleSelectChange("uav_oblique")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
              <div className="col-md-3">
                <label title="Does this project include UAV video?">
                  UAV Video *
                </label>
                <Select
                  name="uav_video"
                  value={this.state.uav_video}
                  onChange={this.handleSelectChange("uav_video")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label title="Units for Project">Project Units *</label>
                <Select.Creatable
                  required
                  allowCreate={true}
                  name="project_units"
                  value={this.state.project_units}
                  onChange={this.handleSelectChange("project_units")}
                  options={this.state.project_unit_options}
                />
              </div>
            </div>
            <p />
            {this.state.uav_mapping == "Yes" && (
              <div>
                <h5>UAV Mapping</h5>
                <hr />
                <div className="row">
                  <div className="col">
                    <label title="Enter the number of acres">Acres *</label>
                    <input
                      type="text"
                      name="size"
                      placeholder="Acres"
                      value={this.state.size}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label title="Select the GSD. If you do not see the value listed, you can create the option">
                      GSD *
                    </label>
                    <Select.Creatable
                      allowCreate={true}
                      name="gsd"
                      value={this.state.gsd}
                      onChange={this.handleSelectChange("gsd")}
                      options={this.state.gsd_options}
                    />
                  </div>
                  <div className="col">
                    <label title="Enter the number of locations">
                      Number of Locations *
                    </label>
                    <input
                      type="text"
                      name="location_number"
                      placeholder="# of Locations"
                      value={this.state.location_number}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label title="Enter the number of flights">Flights *</label>
                    <input
                      type="text"
                      name="flights"
                      placeholder="Flights"
                      value={this.state.flights}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label title="Enter the number of exposures for this project">
                      Exposures *
                    </label>
                    <input
                      type="text"
                      name="exposures"
                      placeholder="Exposures"
                      value={this.state.exposures}
                      onChange={this.handleChange}
                      required
                      className="form-control"
                    />
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-md-3">
                    <label title="Select the map scale">Map Scale *</label>
                    <Select.Creatable
                      allowCreate={true}
                      name="map_scale"
                      value={this.state.map_scale}
                      onChange={this.handleSelectChange("map_scale")}
                      options={this.state.map_scale_options}
                    />
                  </div>
                  <div className="col-md-3">
                    <label title="Select the C.I.">C.I. *</label>
                    <Select.Creatable
                      allowCreate={true}
                      name="ci"
                      value={this.state.ci}
                      onChange={this.handleSelectChange("ci")}
                      options={this.state.ci_options}
                    />
                  </div>
                </div>
              </div>
            )}
            <p />
            {this.state.uav_oblique == "Yes" && (
              <div>
                <h5>UAV Oblique</h5>
                <hr />
                <div className="row">
                  <div className="col">
                    <label title="Enter a description of the obliques">
                      Oblique Description *
                    </label>
                    <textarea
                      type="text"
                      name="oblique_desc"
                      placeholder="Oblique Description"
                      value={this.state.oblique_desc}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label title="Enter the number of locations">
                      Number of Locations *
                    </label>
                    <input
                      type="text"
                      name="obliques_locations"
                      placeholder="# of Locations"
                      value={this.state.obliques_locations}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label title="Enter the number of exposures for this project">
                      Exposures *
                    </label>
                    <input
                      type="text"
                      name="obliques_exposures"
                      placeholder="Exposures"
                      value={this.state.obliques_exposures}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            )}
            <p />
            {this.state.uav_video == "Yes" && (
              <div>
                <h5>UAV Video</h5>
                <hr />
                <div className="row">
                  <div className="col">
                    <label title="Enter a description for the video">
                      Video Description *
                    </label>
                    <textarea
                      type="text"
                      name="video_desc"
                      placeholder="Video Description"
                      value={this.state.video_desc}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <p />
                <div className="row">
                  <div className="col">
                    <label title="Enter the number of locations">
                      Number of Locations *
                    </label>
                    <input
                      type="text"
                      name="video_locations"
                      placeholder="# of Locations"
                      value={this.state.video_locations}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col">
                    <label title="Enter the number of total videos">
                      Total Videos *
                    </label>
                    <input
                      type="text"
                      name="total_videos"
                      placeholder="Total Videos"
                      value={this.state.total_videos}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            )}
            <p />
            <div className="row">
              <div className="col">
                <label>Notes (internal)</label>
                <textarea
                  name="notes"
                  placeholder="Notes"
                  value={this.state.notes}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <input
              title="Click to submit"
              type="submit"
              value={this.state.editing ? "Update" : "Create"}
              className="btn btn-secondary btn-sm"
            />
            <br />
            <br />
            <a
              title="Click to cancel"
              className="btn btn-danger btn-sm"
              onClick={this.props.history.goBack}
            >
              Cancel
            </a>
          </form>
          <br />
        </div>
      </div>
    );
  }

}
