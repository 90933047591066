/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prefer-stateless-function */
import React from "react";

export default class Contact extends React.Component {
  render() {
    return (
      <div>
        {/* eslint - disable - next - line react/jsx-one-expression-per-line */}
        <h3 className="mb-4 text-4xl">
          <i className="fa fa-users" /> Listing Inactive Clients
        </h3>
        <a href="/contacts" className="view-record">
          View Active Clients
        </a>
        <br />
        <b>
          Clients on the list below are inactive, meaning they are no longer
          with the company they were added to in the Hub. This list will update
          over time as Cooper Aerial receives data on current (active) clients.
        </b>
        <hr />
      </div>
    );
  }
}
