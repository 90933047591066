import React from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import update from "immutability-helper";
import moment from "moment";

export default class Proposals extends React.Component {
  static propTypes = {
    proposals: PropTypes.array,
  };

  static defaultProps = {
    proposals: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      proposals: this.props.proposals,
      isHidden: true,
      isHiddenNotComplete: false,
      isLoading: false,
    };
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }

  toggleHiddenNotComplete() {
    this.setState({
      isHiddenNotComplete: !this.state.isHiddenNotComplete,
    });
  }

  componentDidMount() {
    if (this.props.match) {
      this.setState({ isLoading: true });
      $.ajax({
        type: "GET",
        url: "/proposals",
        dataType: "JSON",
      }).done((data) => {
        this.setState({ proposals: data, isLoading: false });
      });
    }
  }

  addNewProposal = (proposal) => {
    const proposals = update(this.state.proposals, { $push: [proposal] });
    this.setState({
      proposals: proposals.sort(function (a, b) {
        return new Date(a.created_at) - new Date(b.created_at);
      }),
    });
  };

  render() {
    const { proposals, isLoading } = this.state;
    if (isLoading) {
      return <div className="loader"></div>;
    }

    return (
      <div>
        <p />
        <ReactTable
          key={proposals.id}
          data={proposals}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          defaultPageSize={100}
          style={{
            height: "800px",
            textAlign: "center",
          }}
          columns={[
            {
              Header: "Proposal Info",
              columns: [
                {
                  Header: "Client Rep",
                  accessor: "project_manager",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={(event) => onChange(event.target.value)}
                      value={filter ? filter.value : ""}
                      style={{
                        width: "100%",
                        color: "black",
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id]
                      .toLowerCase()
                      .includes(filter.value.toLowerCase()),
                },
                {
                  Header: "Project Manager",
                  accessor: "asst_pm",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={(event) => onChange(event.target.value)}
                      value={filter ? filter.value : ""}
                      style={{
                        width: "100%",
                        color: "black",
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id]
                      .toLowerCase()
                      .includes(filter.value.toLowerCase()),
                },
                {
                  Header: "Proposal Date",
                  accessor: "proposal_date",
                  filterable: false,
                  Cell: (props) => (
                    <a
                      href={`/crm/proposals/${props.original.id}`}
                      className="view-record"
                    >
                      {moment(props.value).format("MM/DD/YYYY")}
                    </a>
                  ),
                },
                {
                  Header: "Proposal #",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={(event) => onChange(event.target.value)}
                      value={filter ? filter.value : ""}
                      style={{
                        width: "100%",
                        color: "black",
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id]
                      .toLowerCase()
                      .startsWith(filter.value.toLowerCase()),
                  accessor: "proposal_number",
                  width: 200,
                },
                {
                  Header: "Company",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={(event) => onChange(event.target.value)}
                      value={filter ? filter.value : ""}
                      style={{
                        width: "100%",
                        color: "black",
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id]
                      .toLowerCase()
                      .startsWith(filter.value.toLowerCase()),
                  accessor: "company.name",
                  width: 200,
                },
                {
                  Header: "Proposal Name",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={(event) => onChange(event.target.value)}
                      value={filter ? filter.value : ""}
                      style={{
                        width: "100%",
                        color: "black",
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id]
                      .toLowerCase()
                      .startsWith(filter.value.toLowerCase()),
                  accessor: "opportunity.name",
                  width: 200,
                },
                {
                  Header: "Location",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={(event) => onChange(event.target.value)}
                      value={filter ? filter.value : ""}
                      style={{
                        width: "100%",
                        color: "black",
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id]
                      .toLowerCase()
                      .startsWith(filter.value.toLowerCase()),
                  accessor: "opportunity.location",
                },
                {
                  Header: "Industry",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={(event) => onChange(event.target.value)}
                      value={filter ? filter.value : ""}
                      style={{
                        width: "100%",
                        color: "black",
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id]
                      .toLowerCase()
                      .startsWith(filter.value.toLowerCase()),
                  accessor: "opportunity.industry",
                  width: 200,
                },
                {
                  Header: "Proposal Type",
                  accessor: "proposal_type",
                  filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                      return true;
                    }
                    if (filter.value === "adot") {
                      return row[filter.id] == "ADOT";
                    }
                    if (filter.value === "digital") {
                      return row[filter.id] == "Digital";
                    }
                    if (filter.value === "digitalImg") {
                      return row[filter.id] == "Digital (Image Only)";
                    }
                    if (filter.value === "digitalMap") {
                      return row[filter.id] == "Digital (Mapping Only)";
                    }
                    if (filter.value === "film") {
                      return row[filter.id] == "Film";
                    }
                    if (filter.value === "filmImg") {
                      return row[filter.id] == "Film (Image Only)";
                    }
                    if (filter.value === "filmMap") {
                      return row[filter.id] == "Film (Mapping Only)";
                    }
                    if (filter.value === "flight") {
                      return row[filter.id] == "Flight";
                    }
                    if (filter.value === "lidar") {
                      return row[filter.id] == "LiDAR";
                    }
                    if (filter.value === "lidarProcessing") {
                      return row[filter.id] == "LiDAR Processing";
                    }
                    if (filter.value === "plot") {
                      return row[filter.id] == "Plot";
                    }
                    if (filter.value === "scan") {
                      return row[filter.id] == "Scan";
                    }
                    if (filter.value === "survey") {
                      return row[filter.id] == "Survey";
                    }
                    if (filter.value === "uav") {
                      return row[filter.id] == "UAV";
                    }
                  },
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={(event) => onChange(event.target.value)}
                      style={{ width: "100%" }}
                      value={filter ? filter.value : "all"}
                    >
                      <option value="all">All</option>
                      <option value="adot">ADOT</option>
                      <option value="digital">Digital</option>
                      <option value="digitalImg">Digital (Image Only)</option>
                      <option value="digitalMap">Digital (Mapping Only)</option>
                      <option value="film">Film</option>
                      <option value="filmImg">Film (Image Only)</option>
                      <option value="filmMap">Film (Mapping Only)</option>
                      <option value="flight">Flight</option>
                      <option value="lidar">LiDAR</option>
                      <option value="lidarProcessing">LiDAR Processing</option>
                      <option value="plot">Plot</option>
                      <option value="scan">Scan</option>
                      <option value="survey">Survey</option>
                      <option value="uav">UAV</option>
                    </select>
                  ),
                },
                {
                  Header: "Won",
                  Filter: ({ filter, onChange }) => (
                    <input
                      placeholder={"Search for..."}
                      onChange={(event) => onChange(event.target.value)}
                      value={filter ? filter.value : ""}
                      style={{
                        width: "100%",
                        color: "black",
                      }}
                    />
                  ),
                  filterMethod: (filter, row) =>
                    row[filter.id]
                      .toLowerCase()
                      .startsWith(filter.value.toLowerCase()),
                  accessor: "mark_as_won",
                },
                {
                  Header: "Modify",
                  filterable: false,
                  Cell: (props) => (
                    <a
                      href={`/proposals/${props.original.id}/edit`}
                      className="view-record"
                    >
                      Edit
                    </a>
                  ),
                },
              ],
            },
          ]}
          className="-striped -highlight"
        />
      </div>
    );
  }
}
