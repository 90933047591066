import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

export default class QualityControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quality_control: props.quality_control,
      project: props.project,
      rectification: props.rectification,
      isHidden: false,
    };
  }

  static propTypes = {
    quality_control: PropTypes.object,
  };

  static defaultProps = {
    quality_control: {},
  };

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/cascopt/quality_controls/${this.props.match.params.id}`,
        dataType: "JSON",
      }).done((data) => {
        this.setState({ data });
      });
    }
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }

  render() {
    const problemColor = (color) => {
      const problemYes = this.state.quality_control.problem_edit == "Yes";
      const problemNo = this.state.quality_control.problem_edit == "No";

      if (problemYes) {
        return "red";
      }
      if (problemNo) {
        return "green";
      }
    };
    const problemImagery = (color) => {
      const problemYes = this.state.quality_control.problem_img == "Yes";
      const problemNo = this.state.quality_control.problem_img == "No";

      if (problemYes) {
        return "red";
      }
      if (problemNo) {
        return "green";
      }
    };
    const problemComp = (color) => {
      const problemYes = this.state.quality_control.problem_comp == "Yes";
      const problemNo = this.state.quality_control.problem_comp == "No";

      if (problemYes) {
        return "red";
      }
      if (problemNo) {
        return "green";
      }
    };
    return (
      <div className="card">
        <div className="container">
          <a href={`/cascopt/quality_controls`} className="stage-record">
            Back to QC Dashboard
          </a>
          <hr />
          <Link
            to={`/cascopt/quality_controls/${this.state.quality_control.id}/edit`}
          >
            <button className="btn btn-sm btn-success">Edit QC</button>
          </Link>
          <p />
          <button
            onClick={this.toggleHidden.bind(this)}
            title="Click to expand/hide create company form"
            className="btn btn-secondary btn-sm"
          >
            {this.state.isHidden ? (
              <span>
                Show Project Details{" "}
                <i className="fa fa-plus-circle" aria-hidden="true"></i>
              </span>
            ) : (
              <span>
                Hide Project Details{" "}
                <i className="fa fa-minus-circle" aria-hidden="true"></i>
              </span>
            )}
          </button>
          <p />
          {!this.state.isHidden && (
            <p id="pt-view-project-block">
              <h5>Project Details</h5>
              <b>Client Rep:</b> {this.state.project.project_manager} |{" "}
              <b>Project Manager:</b> {this.state.project.asst_pm || "n/a"}
              <hr />
              <b>Job:</b> {this.state.project.job_name || ""} |{" "}
              {this.state.project.client_number || ""}-
              {this.state.project.job_number || ""}
              <br />
              <b>Edge Job #:</b> {this.state.project.edge_job_number || "n/a"}
              <br />
              <b>Client:</b> {this.state.project.client_name || ""},{" "}
              {this.state.project.client_number || ""}
              <br />
              <b>Deliverables:</b>{" "}
              {this.state.project.deliverables ||
                "Contact PM for deliverables info"}
              <br />
              <b>Job Due Date:</b>{" "}
              {moment(this.state.project.due_date || "").format("MM/DD/YYYY")}
              <br />
              <b>Units:</b> {this.state.project.units || "n/a"}
              <br />
              <b>Project Type:</b> {this.state.project.project_type || "n/a"}
              <br />
              <b>File Location:</b>{" "}
              {this.state.rectification.file_location || "n/a"}
            </p>
          )}
          <br />
          Last Updated: {this.state.project.last_updated}
          <hr />
          <form>
            <div className="row">
              <div className="col-md-3">
                <label>QC Staff</label>
                <p>{this.state.quality_control.qc_staff || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>QC Done</label>
                <p>{this.state.quality_control.qc_done || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Imagery QC Done</label>
                <p>{this.state.quality_control.imagery_qc || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>QC Date</label>
                <p>
                  {moment(this.state.quality_control.qc_date || "n/a").format(
                    "MM/DD/YYYY"
                  )}
                </p>
              </div>
            </div>
            <p />
            {this.state.project.project_type == "BIM" && (
              <div className="row">
                <div className="col-md-3">
                  <label>Problem w/ RFI</label>
                  <p>{this.state.quality_control.problem_comp || "n/a"}</p>
                </div>
                <div className="col-md-3">
                  <label>Problem w/ Scans</label>
                  <p>{this.state.quality_control.problem_edit || "n/a"}</p>
                </div>
                <div className="col-md-3">
                  <label>Problem w/ Modeling</label>
                  <p>{this.state.quality_control.problem_img || "n/a"}</p>
                </div>
              </div>
            )}
            <p />
            {this.state.project.project_type !== "BIM" && (
              <div className="row">
                <div className="col-md-3">
                  <label>Problem w/ Compilation</label>
                  <p
                    style={{
                      color: problemComp(
                        this.state.quality_control.problem_comp.value
                      ),
                    }}
                  >
                    {this.state.quality_control.problem_comp || "n/a"}
                  </p>
                </div>
                <div className="col-md-3">
                  <label>Problem w/ Edit</label>
                  <p
                    style={{
                      color: problemColor(
                        this.state.quality_control.problem_edit.value
                      ),
                    }}
                  >
                    {this.state.quality_control.problem_edit || "n/a"}
                  </p>
                </div>
                <div className="col-md-3">
                  <label>Problem w/ Imagery</label>
                  <p
                    style={{
                      color: problemImagery(
                        this.state.quality_control.problem_img.value
                      ),
                    }}
                  >
                    {this.state.quality_control.problem_img || "n/a"}
                  </p>
                </div>
              </div>
            )}
            <p />
            <div>
              <h5>Post QC</h5>
              <small>
                * For the project to move to Delivery, the Final Edit and Final
                Image fields need to be marked as 'Yes'
              </small>
              <hr />
              <div className="row">
                <div className="col-md-3">
                  <label>Edit Needs Fixed</label>
                  <p>{this.state.quality_control.edit_fix || "n/a"}</p>
                </div>
                <div className="col-md-3">
                  <label>Imagery Needs Fixed</label>
                  <p>{this.state.quality_control.imagery_fix || "n/a"}</p>
                </div>
                <div className="col-md-3">
                  <label>Compilation Needs Fixed</label>
                  <p>{this.state.quality_control.comp_fix || "n/a"}</p>
                </div>
                <div className="col-md-3">
                  <label>Needs Re-QC</label>
                  <p>{this.state.quality_control.re_qc || "n/a"}</p>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Final Edit on ToArchive</label>
                  <p>{this.state.quality_control.final_edit || "n/a"}</p>
                </div>
                <div className="col-md-3">
                  <label>Final Image on ToArchive</label>
                  <p>{this.state.quality_control.final_img || "n/a"}</p>
                </div>
                <div className="col-md-3">
                  <label>Final Delivery QC Done</label>
                  <p>{this.state.quality_control.final_delivery || "n/a"}</p>
                </div>
              </div>
              <p />
              <hr />
              <div className="row">
                <div className="col">
                  <label>QC Notes</label>
                  <p>{this.state.project.qc_notes || "n/a"}</p>
                </div>
              </div>
            </div>
          </form>
          <hr />
          {this.state.project.project_notes && (
            <div>
              <h5>Project Information</h5>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Notes from Project</label>
                  <p>{this.state.project.project_notes || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.project_purpose && (
            <div>
              <h5>Project Purpose Information</h5>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Project Purpose</label>
                  <p>{this.state.project.project_purpose || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          <label>Uploaded Files</label>
          <hr />
          <div className="row">
            <div className="col">
              {this.state.project.pm_notes && (
                <div className="col">
                  <label>PM File</label>
                  <p />
                  <img src={this.state.project.pm_notes || ""} />
                  <p />
                  <a
                    href={this.state.project.pm_file_download}
                    className="btn btn-info btn-sm"
                  >
                    Download
                  </a>
                  <br />
                  <small>
                    <strong>Filename:</strong>
                    <br />
                    {this.state.project.pm_file || ""}
                  </small>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
