import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table-6';
import "react-table-6/react-table.css";
import update from 'immutability-helper';
import moment from 'moment';

export default class WonOpps extends React.Component {
    static propTypes = {
        opportunities: PropTypes.array
    }

    static defaultProps = {
        opportunities: []
    }

    constructor(props, railsContext) {
        super(props)
        this.state = {
            opportunities: this.props.opportunities,
            isHidden: true,
            isHiddenNotComplete: false,
            isLoading: false
        }
    }

    toggleHidden() {
        this.setState({
            isHidden: !this.state.isHidden
        })
    }

    toggleHiddenNotComplete() {
        this.setState({
            isHiddenNotComplete: !this.state.isHiddenNotComplete
        })
    }

    componentDidMount() {
        if (this.props.match) {
            this.setState({ isLoading: true });
            $.ajax({
                type: "GET",
                url: "/opportunities",
                dataType: "JSON"
            }).done((data) => {
                this.setState({ opportunities: data, isLoading: false });
            })
        }
    }

    addNewOpportunity = (opportunity) => {
        const opportunities = update(this.state.opportunities, { $push: [opportunity] });
        this.setState({
            opportunities: opportunities.sort(function (a, b) {
                return new Date(a.created_at) - new Date(b.created_at);
            })
        });
    }

    render() {
        const { opportunities, isLoading } = this.state;
        if (isLoading) {
            return <div className="loader"></div>;
        }

        return (
          <div>
            <p />
            <ReactTable
              key={opportunities.id}
              data={opportunities}
              filterable
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]) === filter.value
              }
              defaultPageSize={100}
              style={{
                height: "800px",
                textAlign: "center",
              }}
              columns={[
                {
                  Header: "Opportunity Info",
                  columns: [
                    {
                      Header: "Created By",
                      accessor: "user.full_name",
                      Filter: ({ filter, onChange }) => (
                        <input
                          placeholder={"Search for..."}
                          onChange={(event) => onChange(event.target.value)}
                          value={filter ? filter.value : ""}
                          style={{
                            width: "100%",
                            color: "black",
                          }}
                        />
                      ),
                      filterMethod: (filter, row) =>
                        row[filter.id]
                          .toLowerCase()
                          .includes(filter.value.toLowerCase()),
                    },
                    {
                      Header: "Client First Name",
                      accessor: "contact.f_name",
                      Filter: ({ filter, onChange }) => (
                        <input
                          placeholder={"Search for..."}
                          onChange={(event) => onChange(event.target.value)}
                          value={filter ? filter.value : ""}
                          style={{
                            width: "100%",
                            color: "black",
                          }}
                        />
                      ),
                      filterMethod: (filter, row) =>
                        row[filter.id]
                          .toLowerCase()
                          .includes(filter.value.toLowerCase()),
                    },
                    {
                      Header: "Client Last Name",
                      accessor: "contact.l_name",
                      Filter: ({ filter, onChange }) => (
                        <input
                          placeholder={"Search for..."}
                          onChange={(event) => onChange(event.target.value)}
                          value={filter ? filter.value : ""}
                          style={{
                            width: "100%",
                            color: "black",
                          }}
                        />
                      ),
                      filterMethod: (filter, row) =>
                        row[filter.id]
                          .toLowerCase()
                          .includes(filter.value.toLowerCase()),
                    },
                    {
                      Header: "Opp Name",
                      accessor: "name",
                      width: 300,
                      Cell: (props) => (
                        <a
                          href={`/opportunities/${props.original.id}`}
                          className="view-record"
                        >
                          {props.value}
                        </a>
                      ),
                      Filter: ({ filter, onChange }) => (
                        <input
                          placeholder={"Search for..."}
                          onChange={(event) => onChange(event.target.value)}
                          value={filter ? filter.value : ""}
                          style={{
                            width: "100%",
                            color: "black",
                          }}
                        />
                      ),
                      filterMethod: (filter, row) =>
                        row[filter.id]
                          .toLowerCase()
                          .includes(filter.value.toLowerCase()),
                    },
                    {
                      Header: "Opp Date",
                      accessor: "date",
                      filterable: false,
                      Cell: (props) => (
                        <span>{moment(props.value).format("MM/DD/YYYY")}</span>
                      ),
                    },
                    {
                      Header: "Location",
                      accessor: "location",
                      Filter: ({ filter, onChange }) => (
                        <input
                          placeholder={"Search for..."}
                          onChange={(event) => onChange(event.target.value)}
                          value={filter ? filter.value : ""}
                          style={{
                            width: "100%",
                            color: "black",
                          }}
                        />
                      ),
                      filterMethod: (filter, row) =>
                        row[filter.id]
                          .toLowerCase()
                          .includes(filter.value.toLowerCase()),
                    },
                    {
                      Header: "Opp Type",
                      accessor: "opportunity_type",
                      filterMethod: (filter, row) => {
                        if (filter.value === "all") {
                          return true;
                        }
                        if (filter.value === "adot") {
                          return row[filter.id] == "ADOT";
                        }
                        if (filter.value === "construction") {
                          return row[filter.id] == "Construction";
                        }
                        if (filter.value === "digital") {
                          return row[filter.id] == "Digital";
                        }
                        if (filter.value === "digitalImg") {
                          return row[filter.id] == "Digital (Image Only)";
                        }
                        if (filter.value === "digitalMap") {
                          return row[filter.id] == "Digital (Mapping Only)";
                        }
                        if (filter.value === "Pgdigital") {
                          return row[filter.id] == "Digital - Photo Geodetic";
                        }
                        if (filter.value === "PgdigitalMap") {
                          return (
                            row[filter.id] ==
                            "Digital (Mapping Only) - Photo Geodetic"
                          );
                        }
                        if (filter.value === "PgdigitalImg") {
                          return (
                            row[filter.id] ==
                            "Digital (Image Only) - Photo Geodetic"
                          );
                        }
                        if (filter.value === "film") {
                          return row[filter.id] == "Film";
                        }
                        if (filter.value === "filmImg") {
                          return row[filter.id] == "Film (Image Only)";
                        }
                        if (filter.value === "filmMap") {
                          return row[filter.id] == "Film (Mapping Only)";
                        }
                        if (filter.value === "Pgfilm") {
                          return row[filter.id] == "Film - Photo Geodetic";
                        }
                        if (filter.value === "PgfilmImg") {
                          return (
                            row[filter.id] ==
                            "Film (Image Only) - Photo Geodetic"
                          );
                        }
                        if (filter.value === "PgfilmMap") {
                          return (
                            row[filter.id] ==
                            "Film (Mapping Only) - Photo Geodetic"
                          );
                        }
                        if (filter.value === "flight") {
                          return row[filter.id] == "Flight";
                        }
                        if (filter.value === "lidar") {
                          return row[filter.id] == "LiDAR";
                        }
                        if (filter.value === "lidarProcessing") {
                          return row[filter.id] == "LiDAR Processing";
                        }
                        if (filter.value === "plot") {
                          return row[filter.id] == "Plot";
                        }
                        if (filter.value === "scan") {
                          return row[filter.id] == "Scan";
                        }
                        if (filter.value === "survey") {
                          return row[filter.id] == "Survey";
                        }
                        if (filter.value === "uav") {
                          return row[filter.id] == "UAV";
                        }
                      },
                      Filter: ({ filter, onChange }) => (
                        <select
                          onChange={(event) => onChange(event.target.value)}
                          style={{ width: "100%" }}
                          value={filter ? filter.value : "all"}
                        >
                          <option value="all">All</option>
                          <option value="adot">ADOT</option>
                          <option value="construction">Construction</option>
                          <option value="digital">Digital</option>
                          <option value="digitalImg">
                            Digital (Image Only)
                          </option>
                          <option value="digitalMap">
                            Digital (Mapping Only)
                          </option>
                          <option value="Pgdigital">
                            Digital - Photo Geodetic
                          </option>
                          <option value="PgdigitalImg">
                            Digital (Image Only) - Photo Geodetic
                          </option>
                          <option value="PgdigitalMap">
                            Digital (Mapping Only) - Photo Geodetic
                          </option>
                          <option value="film">Film</option>
                          <option value="filmImg">Film (Image Only)</option>
                          <option value="filmMap">Film (Mapping Only)</option>
                          <option value="Pgfilm">Film - Photo Geodetic</option>
                          <option value="PgfilmImg">
                            Film (Image Only) - Photo Geodetic
                          </option>
                          <option value="PgfilmMap">
                            Film (Mapping Only) - Photo Geodetic
                          </option>
                          <option value="flight">Flight</option>
                          <option value="lidar">LiDAR</option>
                          <option value="lidarProcessing">
                            LiDAR Processing
                          </option>
                          <option value="plot">Plot</option>
                          <option value="scan">Scan</option>
                          <option value="survey">Survey</option>
                          <option value="uav">UAV</option>
                        </select>
                      ),
                    },
                    {
                      Header: "Opp Status",
                      filterable: false,
                      accessor: "status",
                    },
                    {
                      Header: "Modify",
                      filterable: false,
                      Cell: (props) => (
                        <a
                          href={`/opportunities/${props.original.id}/edit`}
                          className="view-record"
                        >
                          Edit
                        </a>
                      ),
                    },
                  ],
                },
              ]}
              className="-striped -highlight"
            />
          </div>
        );
    }

}
