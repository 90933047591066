import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import ContactOpportunityForm from './ContactOpportunityForm';
import update from 'immutability-helper';

export default class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      contact: props.contact,
      office: props.office,
      company: props.company,
      opportunities: [],
      isHidden: true
    }
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden
    })
  }

  static propTypes = {
    contact: PropTypes.object
  }

  static defaultProps = {
    contact: {}
  }

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/contacts/${this.props.match.params.id}`,
        dataType: "JSON"
      }).done((data) => {
        this.setState({ data });
      })
    }
  }

  addNewOpportunity = (opportunity) => {
    const opportunities = update(this.state.opportunities, { $push: [opportunity]});
    this.setState({
      opportunities: opportunities.sort(function(a,b){
        return new Date(a.created_at) - new Date(b.created_at);
      })
    });
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <form>
            <div className="row">
              <div className="col-md">
                <label>Client Rep</label>
                <p>{this.state.contact.client_rep || "None entered."}</p>
              </div>
              <div className="col-md">
                <label>Email</label>
                <p>{this.state.contact.email || ""}</p>
              </div>
              <div className="col-md">
                <label>Accounting Email</label>
                <p>{this.state.contact.accounting_email || ""}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label>Position</label>
                <p>{this.state.contact.position || ""}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label>Office Phone</label>
                <p>
                  {this.state.contact.phone.replace(
                    /(\d{3})(\d{3})(\d{4})/,
                    "($1) $2-$3"
                  ) || ""}
                </p>
              </div>
              {this.state.contact.ext && (
                <div className="col-md-2">
                  <label>Ext</label>
                  <p>{this.state.contact.ext || ""}</p>
                </div>
              )}
            </div>
            {this.state.contact.mobile && (
              <div>
                <div className="row">
                  <div className="col-md-6">
                    <label>Mobile</label>
                    <p>
                      {this.state.contact.mobile.replace(
                        /(\d{3})(\d{3})(\d{4})/,
                        "($1) $2-$3"
                      ) || ""}
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md">
                <label>Contact Alert</label>
                <p>{this.state.contact.contact_alert || "None"}</p>
              </div>
            </div>
            {this.state.contact.add_billing_info == "Yes" && (
              <div>
                <hr />
                <h5>Billing Information (Different than Office Information)</h5>
                <hr />
              </div>
            )}
            <div className="row">
              {this.state.contact.add_billing_info == "Yes" && (
                <div className="col-md-3">
                  <label>Billing Contact</label>
                  <p>{this.state.contact.billing_contact || ""}</p>
                </div>
              )}
              {this.state.contact.add_billing_info == "Yes" && (
                <div className="col-md-3">
                  <label>Billing Email</label>
                  <p>{this.state.contact.billing_email || ""}</p>
                </div>
              )}
              {this.state.contact.add_billing_info == "Yes" && (
                <div className="col-md-3">
                  <label>Billing Phone</label>
                  <p>
                    {this.state.contact.billing_phone.replace(
                      /(\d{3})(\d{3})(\d{4})/,
                      "($1) $2-$3"
                    ) || ""}
                  </p>
                </div>
              )}
            </div>
            <p />
            <div className="row">
              {this.state.contact.add_billing_info == "Yes" && (
                <div className="col-md-6">
                  <label>Billing Address</label>
                  <p>{this.state.contact.billing_address || ""}</p>
                </div>
              )}
              {this.state.contact.add_billing_info == "Yes" && (
                <div className="col-md-3">
                  <label>Billing City</label>
                  <p>{this.state.contact.billing_city || ""}</p>
                </div>
              )}
              {this.state.contact.add_billing_info == "Yes" && (
                <div className="col-md-2">
                  <label>Billing State</label>
                  <p>{this.state.contact.billing_state || ""}</p>
                </div>
              )}
              {this.state.contact.add_billing_info == "Yes" && (
                <div className="col-md-1">
                  <label>Billing Zip</label>
                  <p>{this.state.contact.billing_zip || ""}</p>
                </div>
              )}
            </div>
            {this.state.contact.add_billing_info == "Yes" && (
              <div>
                <br />
                <div className="row">
                  <div className="col">
                    <label>Billing Notes</label>
                    <p>{this.state.contact.billing_notes || "None"}</p>
                  </div>
                </div>
              </div>
            )}
            {this.state.contact.active_client == true && (
              <button
                title="Click to expand/hide create opportunity form"
                type="button"
                onClick={this.toggleHidden.bind(this)}
                className="btn btn-secondary btn-sm"
              >
                {this.state.isHidden ? (
                  <span>
                    Add Opportunity{" "}
                    <i className="fa fa-plus-circle" aria-hidden="true"></i>
                  </span>
                ) : (
                  <span>
                    Close Opportunity Form{" "}
                    <i className="fa fa-minus-circle" aria-hidden="true"></i>
                  </span>
                )}
              </button>
            )}
          </form>
          {!this.state.isHidden && (
            <ContactOpportunityForm
              handleNewOpportunity={this.addNewOpportunity}
              contactId={this.props.match.params.id}
            />
          )}
          <hr />
        </div>
      </div>
    );
  }
}
