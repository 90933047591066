import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import 'react-select/dist/react-select.css';

export default class QuoteForm extends React.Component {
  static propTypes = {
    handleNewQuote: PropTypes.func
  }

  constructor(props, railsContext) {
    super(props)
    this.state = {
      bonus_perc: 2.5,
      total_adjustment: 0,
      adjustment_reason: "",
      lidar_notes: "",
      client_survey: "",
      plot_laminate: "",
      lidar_flight_sub: "",
      lidar_flight_sub_options: [
        { value: "Cooper Aerial", label: "Cooper Aerial" },
        { value: "Geomni", label: "Geomni" },
        { value: "Verisk", label: "Verisk" },
        { value: "NAAS", label: "NAAS" },
        { value: "SAM", label: "SAM" },
        { value: "CSTI", label: "CSTI" },
        { value: "Kucera", label: "Kucera" },
        { value: "Terra Flight", label: "Terra Flight" },
        { value: "Talos Aviation", label: "Talos Aviation" },
        { value: "Paragon", label: "Paragon" },
        { value: "Berry and Associates", label: "Berry and Associates" },
        { value: "Aerial Surveys Int'l", label: "Aerial Surveys Int'l" },
        { value: "G Squared", label: "G Squared" },
        { value: "SurvTech Solutions", label: "SurvTech Solutions" },
        { value: "Sitech", label: "Sitech" },
        { value: "Argos", label: "Argos" },
        { value: "Atlantic Group", label: "Atlantic Group" },
        { value: "Aero-Graphics", label: "Aero-Graphics" },
        { value: "Keystone Aerial", label: "Keystone Aerial" },
        { value: "American Aerial", label: "American Aerial" },
        { value: "Valley Air", label: "Valley Air" },
        { value: "Radman Aerial", label: "Radman Aerial" },
        { value: "Air Flight SVCS", label: "Air Flight SVCS" },
        {
          value: "Skyview Aerial Photo (South CA)",
          label: "Skyview Aerial Photo (South CA)",
        },
        { value: "Midwest Aerial Photo", label: "Midwest Aerial Photo" },
        { value: "Gammair", label: "Gammair" },
        {
          value: "Richard Crouse and Associates",
          label: "Richard Crouse and Associates",
        },
        {
          value: "I.F. Rooks & Associates, Inc.",
          label: "I.F. Rooks & Associates, Inc.",
        },
        {
          value: "Aerial Data Service, Inc.",
          label: "Aerial Data Service, Inc.",
        },
        { value: "Aerodata, Inc.", label: "Aerodata, Inc." },
        { value: "Lasermap USA", label: "Lasermap USA" },
        { value: "Aerials, Inc.", label: "Aerials, Inc." },
        {
          value: "Gulf Coast Aerial Mapping",
          label: "Gulf Coast Aerial Mapping",
        },
        { value: "Air Photographics, Inc.", label: "Air Photographics, Inc." },
        { value: "Ace Aerial", label: "Ace Aerial" },
        { value: "Georgia Aerial Surveys", label: "Georgia Aerial Surveys" },
        {
          value: "Krawietz Aerial Photo Service",
          label: "Krawietz Aerial Photo Service",
        },
        {
          value: "Continental Aerial Surveys Inc.",
          label: "Continental Aerial Surveys Inc.",
        },
        { value: "Aerial Imaging Inc.", label: "Aerial Imaging Inc." },
        {
          value: "McClintock Land Associates, Inc.",
          label: "McClintock Land Associates, Inc.",
        },
        { value: "Eagle Aerial Solutions", label: "Eagle Aerial Solutions" },
        {
          value: "Rocky Mountain Aerial Surveys",
          label: "Rocky Mountain Aerial Surveys",
        },
        { value: "Blue Skies Consulting", label: "Blue Skies Consulting" },
        { value: "GeoTerra", label: "GeoTerra" },
        { value: "Quantum Spatial", label: "Quantum Spatial" },
        { value: "Aerocon", label: "Aerocon" },
        { value: "Pickett Aerial", label: "Pickett Aerial" },
        { value: "Airborne Sensing", label: "Airborne Sensing" },
        { value: "MAP RES", label: "MAP RES" },
        { value: "Predictive Service", label: "Predictive Service" },
        { value: "Wilson Company", label: "Wilson Company" },
        { value: "KBM, Inc.", label: "KBM, Inc." },
        { value: "Air Flight Service", label: "Air Flight Service" },
        { value: "Lidar Services LLC", label: "Lidar Services LLC" },
        { value: "Eagle Mapping", label: "Eagle Mapping" },
      ],
      lidar_flight_quote: "0",
      lidar_flight_markup: "0",
      lidar_flight_cost: "0",
      lidar_size: "0",
      size_type: "Acres",
      detail_level: "0",
      lidar_hours: "0",
      lidar_ppsm: "0",
      lidar_bare_earth: "",
      scan_qty: "",
      analytics_qty: "",
      photogrammetry_qty: "",
      cartography_qty: "",
      img_qty: "",
      flight_sub: "",
      flight_sub_options: [
        { value: "Cooper Aerial", label: "Cooper Aerial" },
        { value: "Geomni", label: "Geomni" },
        { value: "Verisk", label: "Verisk" },
        { value: "NAAS", label: "NAAS" },
        { value: "SWAS", label: "SWAS" },
        { value: "SAM", label: "SAM" },
        { value: "CSTI", label: "CSTI" },
        { value: "Kucera", label: "Kucera" },
        { value: "Paragon", label: "Paragon" },
        { value: "Terra Flight", label: "Terra Flight" },
        { value: "Berry and Associates", label: "Berry and Associates" },
        { value: "Aerial Surveys Int'l", label: "Aerial Surveys Int'l" },
        { value: "G Squared", label: "G Squared" },
        { value: "SurvTech Solutions", label: "SurvTech Solutions" },
        { value: "Sitech", label: "Sitech" },
        { value: "Argos", label: "Argos" },
        { value: "Atlantic Group", label: "Atlantic Group" },
        { value: "Aero-Graphics", label: "Aero-Graphics" },
        { value: "Keystone Aerial", label: "Keystone Aerial" },
        { value: "American Aerial", label: "American Aerial" },
        { value: "Valley Air", label: "Valley Air" },
        { value: "Radman Aerial", label: "Radman Aerial" },
        { value: "Air Flight SVCS", label: "Air Flight SVCS" },
        {
          value: "Skyview Aerial Photo (South CA)",
          label: "Skyview Aerial Photo (South CA)",
        },
        { value: "Midwest Aerial Photo", label: "Midwest Aerial Photo" },
        { value: "Gammair", label: "Gammair" },
        {
          value: "Richard Crouse and Associates",
          label: "Richard Crouse and Associates",
        },
        {
          value: "I.F. Rooks & Associates, Inc.",
          label: "I.F. Rooks & Associates, Inc.",
        },
        {
          value: "Aerial Data Service, Inc.",
          label: "Aerial Data Service, Inc.",
        },
        { value: "Aerodata, Inc.", label: "Aerodata, Inc." },
        { value: "Lasermap USA", label: "Lasermap USA" },
        { value: "Aerials, Inc.", label: "Aerials, Inc." },
        {
          value: "Gulf Coast Aerial Mapping",
          label: "Gulf Coast Aerial Mapping",
        },
        { value: "Air Photographics, Inc.", label: "Air Photographics, Inc." },
        { value: "Ace Aerial", label: "Ace Aerial" },
        { value: "Georgia Aerial Surveys", label: "Georgia Aerial Surveys" },
        {
          value: "Krawietz Aerial Photo Service",
          label: "Krawietz Aerial Photo Service",
        },
        {
          value: "Continental Aerial Surveys Inc.",
          label: "Continental Aerial Surveys Inc.",
        },
        { value: "Aerial Imaging Inc.", label: "Aerial Imaging Inc." },
        {
          value: "McClintock Land Associates, Inc.",
          label: "McClintock Land Associates, Inc.",
        },
        { value: "Eagle Aerial Solutions", label: "Eagle Aerial Solutions" },
        {
          value: "Rocky Mountain Aerial Surveys",
          label: "Rocky Mountain Aerial Surveys",
        },
        { value: "Blue Skies Consulting", label: "Blue Skies Consulting" },
        { value: "GeoTerra", label: "GeoTerra" },
        { value: "Quantum Spatial", label: "Quantum Spatial" },
        { value: "Aerocon", label: "Aerocon" },
        { value: "Pickett Aerial", label: "Pickett Aerial" },
        { value: "Airborne Sensing", label: "Airborne Sensing" },
        { value: "MAP RES", label: "MAP RES" },
        { value: "Predictive Service", label: "Predictive Service" },
        { value: "Wilson Company", label: "Wilson Company" },
        { value: "KBM, Inc.", label: "KBM, Inc." },
        { value: "Air Flight Service", label: "Air Flight Service" },
        { value: "Lidar Services LLC", label: "Lidar Services LLC" },
        { value: "Eagle Mapping", label: "Eagle Mapping" },
      ],
      flight_quote: "0",
      flight_markup: "0",
      flight_cost: "",
      flight_notes: "",
      panels: "0",
      abgps: "Yes",
      survey_sub: "",
      survey_cost: "",
      survey_quote: "0",
      survey_markup: "0",
      survey_notes: "",
      contact_prints: "",
      contact_prints_cp: "10",
      diapositives: "",
      diapositives_cp: "15",
      scan_cp: "20",
      lab_discount: "0",
      lab_cost: "",
      lab_notes: "",
      analytics_hp: ".15",
      analytics_cp: "100",
      photogrammetry_cp: "75",
      photogrammetry_hp: ".5",
      xsec: "0",
      xsec_cp: "0",
      xsec_hp: "3",
      cartography_cp: "50",
      cartography_hp: ".2",
      translation: "0",
      translation_cp: "0",
      translation_hp: ".2",
      map_discount: "0",
      map_cost: "",
      img_hp: "1",
      img_cp: "20",
      plots: "0",
      plot_x: "",
      plot_y: "",
      plot_cp: "50",
      delivery: "0",
      delivery_type: "",
      delivery_type_options: [
        { value: "FTP", label: "FTP" },
        { value: "USB", label: "USB" },
        { value: "H/D", label: "H/D" },
        { value: "DVD", label: "DVD" },
      ],
      delivery_cp: "0",
      img_discount: "0",
      img_cost: "",
      quote_cost: "",
      map_notes: "",
      img_notes: "",
      plot_mount: "",
      camera: "",
      supplemental_survey: "",
      plain_gc: "",
      description: "",
      sales_tax: "",
      unit_price: "",
      shipping: "",
      discount: "0",
      isHiddenImageComplete: true,
      editing: false,
    };
    window.getState = () => this.state;
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/quotes/${this.props.match.params.id}`,
        dataType: "JSON"
      }).done((data) => {
        this.setState({
          bonus_perc: data.quote.bonus_perc,
          total_adjustment: data.quote.total_adjustment,
          adjustment_reason: data.quote.adjustment_reason,
          lidar_notes: data.quote.lidar_notes,
          client_survey: data.quote.client_survey,
          plot_laminate: data.quote.plot_laminate,
          user_id: data.quote.user_id,
          lidar_ppsm: this.state.lidar_ppsm,
          lidar_bare_earth: this.state.lidar_bare_earth,
          scan_qty: data.quote.scan_qty,
          analytics_qty: data.quote.analytics_qty,
          cartography_qty: data.quote.cartography_qty,
          photogrammetry_qty: data.quote.photogrammetry_qty,
          img_qty: data.quote.img_qty,
          opportunity_id: data.quote.opportunity_id,
          specification_id: data.quote.specification_id,
          flight_sub: data.quote.flight_sub,
          flight_quote: data.quote.flight_quote,
          flight_markup: data.quote.flight_markup,
          flight_cost: data.quote.flight_cost,
          flight_notes: data.quote.flight_notes,
          panels: data.quote.panels,
          abgps: data.quote.abgps,
          survey_sub: data.quote.survey_sub,
          survey_cost: data.quote.survey_cost,
          survey_quote: data.quote.survey_quote,
          survey_markup: data.quote.survey_markup,
          survey_notes: data.quote.survey_notes,
          contact_prints: data.quote.contact_prints,
          contact_prints_cp: data.quote.contact_prints_cp,
          diapositives: data.quote.diapositives,
          diapositives_cp: data.quote.diapositives_cp,
          scan_cp: data.quote.scan_cp,
          lab_discount: data.quote.lab_discount,
          lab_cost: data.quote.lab_cost,
          lab_notes: data.quote.lab_notes,
          analytics_hp: data.quote.analytics_hp,
          analytics_cp: data.quote.analytics_cp,
          photogrammetry_cp: data.quote.photogrammetry_cp,
          photogrammetry_hp: data.quote.photogrammetry_hp,
          xsec: data.quote.xsec,
          xsec_cp: data.quote.xsec_cp,
          xsec_hp: data.quote.xsec_hp,
          cartography_cp: data.quote.cartography_cp,
          cartography_hp: data.quote.cartography_hp,
          translation: data.quote.translation,
          translation_cp: data.quote.translation_cp,
          translation_hp: data.quote.translation_hp,
          map_discount: data.quote.map_discount,
          map_cost: data.quote.map_cost,
          img_hp: data.quote.img_hp,
          img_cp: data.quote.img_cp,
          plots: data.quote.plots,
          plot_x: data.quote.plot_x,
          plot_y: data.quote.plot_y,
          plot_cp: data.quote.plot_cp,
          delivery: data.quote.delivery,
          delivery_type: data.quote.delivery_type,
          delivery_cp: data.quote.delivery_cp,
          img_discount: data.quote.img_discount,
          img_cost: data.quote.img_cost,
          quote_cost: data.quote.quote_cost,
          map_notes: data.quote.map_notes,
          img_notes: data.quote.img_notes,
          plot_mount: data.quote.plot_mount,
          camera: data.quote.camera,
          supplemental_survey: data.quote.supplemental_survey,
          plain_gc: data.quote.plain_gc,
          description: data.quote.description,
          sales_tax: data.quote.sales_tax,
          unit_price: data.quote.unit_price,
          shipping: data.quote.shipping,
          discount: data.quote.discount,
          quote_type: data.quote.quote_type,
          lidar_flight_sub: data.quote.lidar_flight_sub,
          lidar_flight_quote: data.quote.lidar_flight_quote,
          lidar_flight_markup: data.quote.lidar_flight_markup,
          lidar_flight_cost: data.quote.lidar_flight_cost,
          lidar_size: data.quote.lidar_size,
          size_type: data.quote.size_type,
          detail_level: data.quote.detail_level,
          lidar_hours: data.quote.lidar_hours,
          editing: this.props.match.path === "/quotes/:id/edit",
        });
      });
    }
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    this.state.editing ?
      this.updateQuote() :
      this.addQuote();
  }

  updateQuote() {
    const quote = {
      bonus_perc: this.state.bonus_perc,
      total_adjustment: this.state.total_adjustment,
      adjustment_reason: this.state.adjustment_reason,
      lidar_notes: this.state.lidar_notes,
      client_survey: this.state.client_survey,
      plot_laminate: this.state.plot_laminate,
      user_id: this.props.userId,
      lidar_size: this.state.lidar_size,
      size_type: this.state.size_type,
      detail_level: this.state.detail_level,
      lidar_hours: this.state.lidar_hours,
      scan_qty: this.props.scanQty,
      analytics_qty: this.props.analyticsQty,
      cartography_qty: this.props.cartographyQty,
      photogrammetry_qty: this.props.photogrammetryQty,
      img_qty: this.props.imgQty,
      opportunity_id: this.props.oppId,
      specification_id: this.props.specId,
      quote_type: this.props.quoteType,
      flight_sub: this.state.flight_sub,
      flight_quote: this.state.flight_quote,
      flight_markup: this.state.flight_markup,
      flight_cost: this.state.flight_cost,
      flight_notes: this.state.flight_notes,
      lidar_flight_sub: this.state.lidar_flight_sub,
      lidar_flight_quote: this.state.lidar_flight_quote,
      lidar_flight_markup: this.state.lidar_flight_markup,
      lidar_flight_cost: this.state.lidar_flight_cost,
      lidar_ppsm: this.state.lidar_ppsm,
      panels: this.state.panels,
      abgps: this.state.abgps,
      survey_sub: this.state.survey_sub,
      survey_cost: this.state.survey_cost,
      survey_quote: this.state.survey_quote,
      survey_markup: this.state.survey_markup,
      survey_notes: this.state.survey_notes,
      contact_prints: this.state.contact_prints,
      contact_prints_cp: this.state.contact_prints_cp,
      diapositives: this.state.diapositives,
      diapositives_cp: this.state.diapositives_cp,
      scan_cp: this.state.scan_cp,
      lab_discount: this.state.lab_discount,
      lab_cost: this.state.lab_cost,
      lab_notes: this.state.lab_notes,
      analytics_hp: this.state.analytics_hp,
      analytics_cp: this.state.analytics_cp,
      photogrammetry_cp: this.state.photogrammetry_cp,
      photogrammetry_hp: this.state.photogrammetry_hp,
      xsec: this.props.xsecQty,
      xsec_cp: this.state.xsec_cp,
      xsec_hp: this.state.xsec_hp,
      cartography_cp: this.state.cartography_cp,
      cartography_hp: this.state.cartography_hp,
      translation: this.props.translationQty,
      translation_cp: this.state.translation_cp,
      translation_hp: this.state.translation_hp,
      map_discount: this.state.map_discount,
      map_cost: this.state.map_cost,
      img_hp: this.state.img_hp,
      img_cp: this.state.img_cp,
      plots: this.state.plots,
      plot_x: this.state.plot_x,
      plot_y: this.state.plot_y,
      plot_cp: this.state.plot_cp,
      delivery: this.state.delivery,
      delivery_type: this.state.delivery_type,
      delivery_cp: this.state.delivery_cp,
      img_discount: this.state.img_discount,
      img_cost: this.state.img_cost,
      quote_cost: this.state.quote_cost,
      map_notes: this.state.map_notes,
      img_notes: this.state.img_notes,
      plot_mount: this.state.plot_mount,
      camera: this.state.camera,
      supplemental_survey: this.state.supplemental_survey,
      plain_gc: this.state.plain_gc,
      description: this.state.description,
      sales_tax: this.state.sales_tax,
      unit_price: this.state.unit_price,
      shipping: this.state.shipping,
      discount: this.state.discount,
      lidar_bare_earth: this.state.lidar_bare_earth,
    };
    $.ajax({
      type: "PATCH",
      url: `/quotes/${this.props.match.params.id}`,
      data: { quote: quote }
    })
      .done((data) => {
        console.log('Quote updated!');
      })
      .fail((response) => {
        console.log("did not work");
        alert("Please review the form for errors");
      });
  }

  addQuote() {
    const quote = {
      bonus_perc: this.state.bonus_perc,
      total_adjustment: this.state.total_adjustment,
      adjustment_reason: this.state.adjustment_reason,
      lidar_notes: this.state.lidar_notes,
      client_survey: this.state.client_survey,
      plot_laminate: this.state.plot_laminate,
      user_id: this.props.userId,
      lidar_size: this.props.lidar_size,
      size_type: this.state.size_type,
      detail_level: this.state.detail_level,
      lidar_hours: this.state.lidar_hours,
      scan_qty: this.props.scanQty,
      analytics_qty: this.props.analyticsQty,
      cartography_qty: this.props.cartographyQty,
      photogrammetry_qty: this.props.photogrammetryQty,
      img_qty: this.props.imgQty,
      opportunity_id: this.props.oppId,
      specification_id: this.props.specId,
      quote_type: this.props.quoteType,
      flight_sub: this.state.flight_sub,
      flight_quote: this.state.flight_quote,
      flight_markup: this.state.flight_markup,
      flight_cost: this.state.flight_cost,
      flight_notes: this.state.flight_notes,
      lidar_flight_sub: this.state.lidar_flight_sub,
      lidar_flight_quote: this.state.lidar_flight_quote,
      lidar_flight_markup: this.state.lidar_flight_markup,
      lidar_flight_cost: this.state.lidar_flight_cost,
      lidar_ppsm: this.state.lidar_ppsm,
      panels: this.state.panels,
      abgps: this.state.abgps,
      survey_sub: this.state.survey_sub,
      survey_cost: this.state.survey_cost,
      survey_quote: this.state.survey_quote,
      survey_markup: this.state.survey_markup,
      survey_notes: this.state.survey_notes,
      contact_prints: this.state.contact_prints,
      contact_prints_cp: this.state.contact_prints_cp,
      diapositives: this.state.diapositives,
      diapositives_cp: this.state.diapositives_cp,
      scan_cp: this.state.scan_cp,
      lab_discount: this.state.lab_discount,
      lab_cost: this.state.lab_cost,
      lab_notes: this.state.lab_notes,
      analytics_hp: this.state.analytics_hp,
      analytics_cp: this.state.analytics_cp,
      photogrammetry_cp: this.state.photogrammetry_cp,
      photogrammetry_hp: this.state.photogrammetry_hp,
      xsec: this.props.xsecQty,
      xsec_cp: this.state.xsec_cp,
      xsec_hp: this.state.xsec_hp,
      cartography_cp: this.state.cartography_cp,
      cartography_hp: this.state.cartography_hp,
      translation: this.props.translationQty,
      translation_cp: this.state.translation_cp,
      translation_hp: this.state.translation_hp,
      map_discount: this.state.map_discount,
      map_cost: this.state.map_cost,
      img_hp: this.state.img_hp,
      img_cp: this.state.img_cp,
      plots: this.state.plots,
      plot_x: this.state.plot_x,
      plot_y: this.state.plot_y,
      plot_cp: this.state.plot_cp,
      delivery: this.state.delivery,
      delivery_type: this.state.delivery_type,
      delivery_cp: this.state.delivery_cp,
      img_discount: this.state.img_discount,
      img_cost: this.state.img_cost,
      quote_cost: this.state.quote_cost,
      map_notes: this.state.map_notes,
      img_notes: this.state.img_notes,
      plot_mount: this.state.plot_mount,
      camera: this.state.camera,
      supplemental_survey: this.state.supplemental_survey,
      plain_gc: this.state.plain_gc,
      description: this.state.description,
      sales_tax: this.state.sales_tax,
      unit_price: this.state.unit_price,
      shipping: this.state.shipping,
      discount: this.state.discount,
      lidar_bare_earth: this.state.lidar_bare_earth,
    };
    $.post('/quotes', { quote: quote })
      .done((data) => {
        this.props.handleNewQuote(data);
        console.log("Quote Successfully Created!");
      })
      .fail((response) => {
        console.log("did not work");
        alert("Please review the form for errors");
      });
  }

  deleteQuote = () => {
    if (confirm("Are you sure you want to delete this quote?")) {
      $.ajax({
        type: "DELETE",
        url: `/quotes/${this.props.match.params.id}`,
      })
        .done((data) => {
          this.props.history.push('/quotes');
        })
        .fail((response) => {
          console.log("Quote deletion failed!");
        });
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSelectChange(key) {
    return function ({ value }) {
      this.setState({ [key]: value });
    }.bind(this);
  }

  toggleHiddenImageComplete() {
    this.setState({
      isHiddenImageComplete: !this.state.isHiddenImageComplete
    })
  }

  render() {
    {/* flight cost */ }
    var flightMarkup = parseInt(this.state.flight_quote) * parseFloat(this.state.flight_markup);
    var flightCost = flightMarkup / 100 + parseInt(this.state.flight_quote);
    var flightQuoteCost = Math.round(flightCost);
    this.state.flight_cost = flightQuoteCost;

    {/* survey cost */ }
    var surveyMarkup = parseInt(this.state.survey_quote) * parseFloat(this.state.survey_markup);
    var surveyCost = surveyMarkup / 100 + parseInt(this.state.survey_quote);
    var surveyQuoteCost = surveyCost;
    this.state.survey_cost = surveyQuoteCost;

    {/* analytics cost */ }
    var analyticsNumber = parseInt(this.props.analyticsQty);
    var analyticsCp = parseInt(this.state.analytics_cp);
    var analyticsHp = parseFloat(this.state.analytics_hp);
    var analyticsHours = parseInt(analyticsNumber) * parseFloat(analyticsHp);
    var analyticsPrice = parseInt(analyticsCp) * parseFloat(analyticsHours);
    var analyticsTotal = analyticsPrice;

    {/* photogrammetry cost */ }
    var photogrammetryNumber = parseInt(this.props.photogrammetryQty);
    var photogrammetryCp = parseInt(this.state.photogrammetry_cp);
    var photogrammetryHp = parseFloat(this.state.photogrammetry_hp);
    var photogrammetryHours = parseInt(photogrammetryNumber) * parseFloat(photogrammetryHp);
    var photogrammetryPrice = parseInt(photogrammetryCp) * parseInt(photogrammetryHours);
    var photogrammetryTotal = photogrammetryPrice;

    {/* x-section cost */ }
    var xsecQty = parseInt(this.props.xsecQty);
    var xsecCp = parseInt(this.state.xsec_cp);
    var xsecHp = parseFloat(this.state.xsec_hp);
    var xsecHours = parseInt(xsecQty) * parseFloat(xsecHp);
    var xsecPrice = parseInt(xsecCp) * parseInt(xsecHours);
    var xsecTotal = xsecPrice;

    {/* cartography cost */ }
    var cartographyNumber = parseInt(this.props.cartographyQty);
    var cartographyCp = parseInt(this.state.cartography_cp);
    var cartographyHp = parseFloat(this.state.cartography_hp);
    var cartographyHours = parseInt(cartographyNumber) * parseFloat(cartographyHp);
    var cartographyPrice = parseInt(cartographyCp) * parseInt(cartographyHours);
    var cartographyTotal = cartographyPrice;

    {/* translation cost */ }
    var translationQty = parseInt(this.props.translationQty);
    var translationCp = parseInt(this.state.translation_cp);
    var translationHp = parseFloat(this.state.translation_hp);
    var translationHours = parseInt(translationQty) * parseFloat(translationHp);
    var translationPrice = parseInt(translationCp) * parseInt(translationHours);
    var translationTotal = translationPrice;

    {/* mapping subtotal cost */ }
    var mapSubtotal = parseInt(analyticsTotal) + parseInt(photogrammetryTotal) + parseInt(xsecTotal) + parseInt(cartographyTotal) + parseInt(translationTotal);
    {/* map discount */ }
    var mapDiscount = parseInt(mapSubtotal) * parseFloat(this.state.map_discount);
    var mapPrice = parseInt(mapSubtotal) - parseInt(mapDiscount);
    {/* end of map discount */ }
    this.state.map_cost = mapPrice;

    {/* rectification cost */ }
    var rectificationQty = parseInt(this.props.imgQty);
    var rectificationCp = parseInt(this.state.img_cp);
    var rectificationHp = parseFloat(this.state.img_hp);
    var rectificationHours = parseInt(rectificationQty) * parseFloat(rectificationHp);
    var rectificationPrice = parseInt(rectificationCp) * parseInt(rectificationHours);
    var rectificationTotal = rectificationPrice;

    {/* plots cost */ }
    var plotQty = parseInt(this.state.plots);
    var plotCp = parseInt(this.state.plot_cp);
    var plotPrice = parseInt(plotQty) * parseInt(plotCp);
    var plotTotal = plotPrice;

    {/* delivery cost */ }
    var deliveryQty = parseInt(this.state.delivery);
    var deliveryCp = parseInt(this.state.delivery_cp);
    var deliveryPrice = parseInt(deliveryQty) * parseInt(deliveryCp);
    var deliveryTotal = deliveryPrice;

    {/* imagery cost */ }
    var imageSubtotal = parseInt(rectificationTotal) + parseInt(plotTotal) + parseInt(deliveryTotal)
    {/* image discount */ }
    var imgDiscount = parseInt(imageSubtotal) * parseFloat(this.state.img_discount);
    var imgPrice = parseInt(imageSubtotal) - parseInt(imgDiscount);
    {/* end of image discount */ }
    this.state.img_cost = imgPrice;

    {/* lidar flight cost */ }
    var lidarFlightMarkup = parseInt(this.state.lidar_flight_quote) * parseFloat(this.state.lidar_flight_markup);
    var lidarFlightCost = lidarFlightMarkup / 100 + parseInt(this.state.lidar_flight_quote);
    var lidarFlightQuoteCost = Math.round(lidarFlightCost);
    this.state.lidar_flight_cost = lidarFlightQuoteCost;

    {/* lidar level of detail pricing */ }
    var detailPrice = parseFloat(this.state.detail_level);
    var processHours = parseInt(this.props.lidar_size);
    if (this.state.size_type == "Acres") {
      var processTotal = parseFloat(detailPrice) * parseInt(processHours) * 20;
    } else if (this.state.size_type == "Linear Feet") {
      var processTotal = parseFloat(detailPrice) * parseInt(processHours) * .25;
    } else if (detailPrice == '0') {
      var processTotal = 0;
    }
    var processPrice = Math.round(processTotal);

    var lidarSubtotal = lidarFlightQuoteCost + Math.round(processTotal);

    {/* quote cost */ }
    var quoteCost = parseInt(flightQuoteCost) + parseInt(surveyQuoteCost) + parseInt(mapPrice)
      + parseInt(imgPrice) + parseInt(lidarFlightQuoteCost)
      + parseInt(processTotal) + parseFloat(this.state.total_adjustment);

    {
      /* bonus percentage calculation */
    }
    var bonus_percent = parseFloat(this.state.bonus_perc);
    var bonus_amount = bonus_percent / 100;
    var bonus_total = bonus_amount * quoteCost;

    this.state.quote_cost = Math.ceil(quoteCost + 250 + bonus_total).toFixed(2);

    return (
      <div className="card">
        <div className="card-body">
          <h4 className="mb-4 text-4xl">
            {this.state.editing
              ? "Update Digital w/ LiDAR Quote"
              : "Create Digital w/ LiDAR Quote"}
          </h4>
          <small>
            (* represents a <strong>required field</strong>)
          </small>
          <form onSubmit={this.handleFormSubmit}>
            <div className="row">
              <div className="col-md-3">
                <label>Quote Type *</label>
                <Select
                  name="quote_type"
                  disabled={true}
                  value={this.props.quoteType}
                  options={[
                    { value: "Film", label: "Film" },
                    {
                      value: "Film (Mapping Only)",
                      label: "Film (Mapping Only)",
                    },
                    { value: "Film (Image Only)", label: "Film (Image Only)" },
                    { value: "Digital", label: "Digital" },
                    {
                      value: "Digital (Mapping Only)",
                      label: "Digital (Mapping Only)",
                    },
                    {
                      value: "Digital (Image Only)",
                      label: "Digital (Image Only)",
                    },
                    { value: "LiDAR", label: "LiDAR" },
                    { value: "Scan", label: "Scan" },
                    { value: "Plot", label: "Plot" },
                    { value: "Survey", label: "Survey" },
                    { value: "Other", label: "Other" },
                  ]}
                />
              </div>
            </div>
            <p />
            <h5>Flight</h5>
            <hr />
            <div className="row">
              <div className="col-md-3">
                <label>Flight Sub *</label>
                <Select.Creatable
                  required
                  allowCreate={true}
                  name="flight_sub"
                  value={this.state.flight_sub}
                  onChange={this.handleSelectChange("flight_sub")}
                  options={this.state.flight_sub_options}
                />
              </div>
              <div className="col-md-3">
                <label>Flight Quote *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="flight_quote"
                    required
                    value={this.state.flight_quote}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <label>Flight Markup *</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    name="flight_markup"
                    required
                    value={this.state.flight_markup}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                  <span className="input-group-text">%</span>
                </div>
              </div>
              <div className="col-md-3">
                <label>Flight Cost *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="flight_cost"
                    required
                    value={flightQuoteCost}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Flight Notes</label>
                <textarea
                  name="flight_notes"
                  value={this.state.flight_notes}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <h5>Survey</h5>
            <hr />
            <div className="row">
              <div className="col-md-4">
                <label>Is Client Doing Survey? *</label>
                <Select
                  required
                  name="client_survey"
                  value={this.state.client_survey}
                  onChange={this.handleSelectChange("client_survey")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                    { value: "Cooper Aerial", label: "Cooper Aerial" },
                  ]}
                />
              </div>
              <div className="col-md-4">
                <label>Panels *</label>
                <input
                  type="text"
                  name="panels"
                  required
                  value={this.state.panels}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-md-4">
                <label>ABGPS *</label>
                <Select
                  required
                  name="abgps"
                  value={this.state.abgps}
                  onChange={this.handleSelectChange("abgps")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-3">
                <label>Surveyor *</label>
                <input
                  type="text"
                  name="survey_sub"
                  required
                  value={this.state.survey_sub}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-md-3">
                <label>Survey Quote *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="survey_quote"
                    required
                    value={this.state.survey_quote}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <label>Markup *</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    name="survey_markup"
                    required
                    value={this.state.survey_markup}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                  <span className="input-group-text">%</span>
                </div>
              </div>
              <div className="col-md-3">
                <label>Survey Cost *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="survey_cost"
                    required
                    value={surveyQuoteCost}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Survey Notes</label>
                <textarea
                  name="survey_notes"
                  value={this.state.survey_notes}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <h5>Mapping</h5>
            <hr />
            <div className="row">
              <div className="col">
                <label>Analytics *</label>
                <input
                  type="text"
                  name="analytics_qty"
                  readOnly
                  value={this.props.analyticsQty}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours Per *</label>
                <input
                  type="text"
                  name="analytics_hp"
                  required
                  value={this.state.analytics_hp}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours *</label>
                <input
                  type="text"
                  required
                  value={analyticsHours}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Cost Per *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="analytics_cp"
                    required
                    value={this.state.analytics_cp}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Total *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={analyticsTotal}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Photogrammetry *</label>
                <input
                  type="text"
                  name="photogrammetry_qty"
                  readOnly
                  value={this.props.photogrammetryQty}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours Per *</label>
                <input
                  type="text"
                  name="photogrammetry_hp"
                  required
                  value={this.state.photogrammetry_hp}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours *</label>
                <input
                  type="text"
                  required
                  value={photogrammetryHours}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Cost Per *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="photogrammetry_cp"
                    required
                    value={this.state.photogrammetry_cp}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Total *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={photogrammetryTotal}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>X Sections *</label>
                <input
                  type="text"
                  name="xsec"
                  readOnly
                  value={this.props.xsecQty}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours Per *</label>
                <input
                  type="text"
                  name="xsec_hp"
                  required
                  value={this.state.xsec_hp}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours *</label>
                <input
                  type="text"
                  required
                  value={xsecHours}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Cost Per *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="xsec_cp"
                    required
                    value={this.state.xsec_cp}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Total *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={xsecTotal}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Cartography *</label>
                <input
                  type="text"
                  name="cartography_qty"
                  readOnly
                  value={this.props.cartographyQty}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours Per *</label>
                <input
                  type="text"
                  name="cartography_hp"
                  required
                  value={this.state.cartography_hp}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours *</label>
                <input
                  type="text"
                  required
                  value={cartographyHours}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Cost Per *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="cartography_cp"
                    required
                    value={this.state.cartography_cp}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Total *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={cartographyTotal}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Translation *</label>
                <input
                  type="text"
                  name="translation"
                  readOnly
                  value={this.props.translationQty}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours Per *</label>
                <input
                  type="text"
                  name="translation_hp"
                  required
                  value={this.state.translation_hp}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours *</label>
                <input
                  type="text"
                  required
                  value={translationHours}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Cost Per *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="translation_cp"
                    required
                    value={this.state.translation_cp}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Total *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={translationTotal}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Subtotal *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={mapSubtotal}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Discount *</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    name="map_discount"
                    required
                    value={this.state.map_discount}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                  <span className="input-group-text">%</span>
                </div>
              </div>
              <div className="col">
                <label>Mapping Cost *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="map_cost"
                    required
                    value={this.state.map_cost}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Map Notes</label>
                <textarea
                  name="map_notes"
                  value={this.state.map_notes}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <h5>Imagery</h5>
            <hr />
            <div className="row">
              <div className="col">
                <label>Rectification *</label>
                <input
                  type="text"
                  name="img_qty"
                  readOnly
                  value={this.props.imgQty}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours Per</label>
                <input
                  type="text"
                  name="img_hp"
                  required
                  value={this.state.img_hp}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Hours</label>
                <input
                  type="text"
                  required
                  value={rectificationHours}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Cost Per</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="img_cp"
                    required
                    value={this.state.img_cp}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Total *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={rectificationTotal}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Subtotal *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={imageSubtotal}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Plots *</label>
                <input
                  type="text"
                  name="plots"
                  required
                  value={this.state.plots}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Length</label>
                <input
                  type="text"
                  name="plot_x"
                  value={this.state.plot_x}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Width</label>
                <input
                  type="text"
                  name="plot_y"
                  value={this.state.plot_y}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Mounted</label>
                <Select
                  name="plot_mount"
                  value={this.state.plot_mount}
                  onChange={this.handleSelectChange("plot_mount")}
                  options={[
                    { value: "None", label: "None" },
                    { value: "1/2", label: "1/2" },
                    { value: "3/16", label: "3/16" },
                  ]}
                />
              </div>
              <div className="col">
                <label>Laminated</label>
                <Select
                  name="plot_laminate"
                  value={this.state.plot_laminate}
                  onChange={this.handleSelectChange("plot_laminate")}
                  options={[
                    { value: "No", label: "No" },
                    { value: "Yes", label: "Yes" },
                  ]}
                />
              </div>
              <div className="col">
                <label>Cost Per</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="plot_cp"
                    required
                    value={this.state.plot_cp}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Total *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={plotTotal}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Discount *</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    name="img_discount"
                    required
                    value={this.state.img_discount}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                  <span className="input-group-text">%</span>
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Delivery</label>
                <input
                  type="text"
                  name="delivery"
                  required
                  value={this.state.delivery}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Delivery Type</label>
                <Select.Creatable
                  allowCreate={true}
                  name="delivery_type"
                  value={this.state.delivery_type}
                  onChange={this.handleSelectChange("delivery_type")}
                  options={this.state.delivery_type_options}
                />
              </div>
              <div className="col"></div>
              <div className="col">
                <label>Cost Per</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="delivery_cp"
                    required
                    value={this.state.delivery_cp}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Total *</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    required
                    value={deliveryTotal}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col">
                <label>Imagery Cost</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="img_cost"
                    required
                    value={this.state.img_cost}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div>
              <h5>LiDAR</h5>
              <hr />
              <div className="row">
                <div className="col-md-3">
                  <label>LiDAR Flight Sub *</label>
                  <Select.Creatable
                    allowCreate={true}
                    name="lidar_flight_sub"
                    value={this.state.lidar_flight_sub}
                    onChange={this.handleSelectChange("lidar_flight_sub")}
                    options={this.state.lidar_flight_sub_options}
                  />
                </div>
                <div className="col-md-3">
                  <label>LiDAR Flight Quote *</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      name="lidar_flight_quote"
                      value={this.state.lidar_flight_quote}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <label>LiDAR Flight Markup *</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      name="lidar_flight_markup"
                      value={this.state.lidar_flight_markup}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
                <div className="col-md-3">
                  <label>LiDAR Flight Cost *</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      name="lidar_flight_cost"
                      value={lidarFlightQuoteCost}
                      onChange={this.handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>Size</label>
                  <input
                    type="text"
                    name="lidar_size"
                    value={this.props.lidar_size}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Size Type</label>
                  <Select
                    name="size_type"
                    value={this.state.size_type}
                    onChange={this.handleSelectChange("size_type")}
                    options={[
                      { value: "Acres", label: "Acres" },
                      { value: "Linear Feet", label: "Linear Feet" },
                    ]}
                  />
                </div>
                <div className="col-md-2">
                  <label>L.O.D. (cost per hour)</label>
                  <Select
                    name="detail_level"
                    value={this.state.detail_level}
                    onChange={this.handleSelectChange("detail_level")}
                    options={[
                      { value: "0", label: "0" },
                      { value: "1.1", label: "1" },
                      { value: "1.3", label: "2" },
                      { value: "1.5", label: "3" },
                      { value: "1.7", label: "4" },
                      { value: "2.0", label: "5" },
                    ]}
                  />
                </div>
                {/*
                  <div className="col-md-2">
                    <label>Hours</label>
                    <input type="text" name="lidar_hours"
                      value={this.state.lidar_hours}
                      onChange={this.handleChange}
                      className="form-control" />
                  </div>
                  */}
                <div className="col-md-2">
                  <label>Total</label>
                  <div className="input-group mb-3">
                    <span className="input-group-text">$</span>
                    <input
                      type="text"
                      value={processPrice}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>PPSM *</label>
                  <input
                    type="text"
                    name="lidar_ppsm"
                    placeholder="PPSM"
                    value={this.state.lidar_ppsm}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Bare Earth Only *</label>
                  <Select
                    name="lidar_bare_earth"
                    value={this.state.lidar_bare_earth}
                    onChange={this.handleSelectChange("lidar_bare_earth")}
                    options={[
                      { value: "No", label: "No" },
                      { value: "Yes", label: "Yes" },
                    ]}
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col-md-3">
                  <label>LiDAR Total *</label>
                  <input
                    type="text"
                    value={lidarSubtotal}
                    className="form-control"
                  />
                </div>
              </div>
              <p />
              <div className="row">
                <div className="col">
                  <label>LiDAR Notes</label>
                  <textarea
                    name="lidar_notes"
                    value={this.state.lidar_notes}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-2">
                <label>Total Adjustment</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="total_adjustment"
                    required
                    value={this.state.total_adjustment}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <label>Explanation</label>
                <input
                  type="text"
                  name="adjustment_reason"
                  value={this.state.adjustment_reason}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div>
              **If this is a LiDAR project, the cost should be 10% to 25% higher
              than the standard mapping cost for this project.
            </div>
            <p />
            <div className="row">
              <div className="col-md-2">
                <label>Bonus Percentage</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">%</span>
                  <input
                    type="number"
                    step="0.1"
                    min="0"
                    max="2.5"
                    name="bonus_perc"
                    required
                    value={this.state.bonus_perc || 0}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            {this.props.premium_charge == "Yes" && (
              <div className="row">
                <p />
                <div className="col">
                  <div class="alert alert-info" role="alert">
                    This company has a premium upcharge of at least 10%
                  </div>
                </div>
              </div>
            )}
            <p />
            <h5>Total Quote Cost</h5>
            <hr />
            <div className="row">
              <div className="col-md-2">
                <label>Quote Cost</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="quote_cost"
                    required
                    value={this.state.quote_cost}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <p />
            <input
              type="submit"
              value={this.state.editing ? "Update" : "Create"}
              className="btn btn-secondary btn-sm"
            />
          </form>
          <br />
        </div>
      </div>
    );
  }

}
