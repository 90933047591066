import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import update from 'immutability-helper';
import AdotSpecForm from './AdotSpecForm';
import FilmSpecForm from './FilmSpecForm';
import DigitalSpecForm from './DigitalSpecForm';
import LidarSpecForm from './LidarSpecForm';
import LidarProcessingSpecForm from './LidarProcessingSpecForm';
import ScanSpecForm from './ScanSpecForm';
import PlotSpecForm from './PlotSpecForm';
import SurveySpecForm from './SurveySpecForm';
import OtherSpecForm from './OtherSpecForm';
import FlightSpecForm from './FlightSpecForm';
import DroneSpecForm from './DroneSpecForm';
import PgFilmSpec from './PgFilmSpec';
import PgDigitalSpec from './PgDigitalSpec';
import ConstructionSpec from './ConstructionSpec';
import NaasSpecForm from './NaasSpecForm';
import moment from 'moment';

export default class Opportunity extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      opportunity: props.opportunity,
      contact: props.contact,
      project_manager: props.project_manager,
      specifications: [],
      isHidden: true
    }
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden
    })
  }

  static propTypes = {
    opportunity: PropTypes.object
  }

  static defaultProps = {
    opportunity: {}
  }

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/opportunities/${this.props.match.params.id}`,
        dataType: "JSON"
      }).done((data) => {
        this.setState({ data });
      })
    }
  }

  addNewSpecification = (specification) => {
    const specifications = update(this.state.specifications, { $push: [specification] });
    this.setState({
      specifications: specifications.sort(function (a, b) {
        return new Date(a.created_at) - new Date(b.created_at);
      })
    });
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <form>
            <div className="row">
              <div className="col-md-6">
                <label>Opportunity Created By</label>
                <input
                  type="text"
                  readOnly
                  value={this.state.project_manager || ""}
                  className="form-control"
                />
              </div>
              {this.state.secondary_contact && (
                <div className="col-md-6">
                  <label>Secondary Contact</label>
                  <input
                    type="text"
                    readOnly
                    value={this.state.opportunity.secondary_contact || ""}
                    className="form-control"
                  />
                </div>
              )}
            </div>
            <p />
            <div className="row">
              <div className="col-md-6">
                <label>Client Email</label>
                <input
                  type="text"
                  readOnly
                  value={this.state.contact.email || ""}
                  className="form-control"
                />
              </div>
              <div className="col-md-6">
                <label>Client Phone</label>
                <input
                  type="text"
                  readOnly
                  value={this.state.contact.phone.replace(
                    /(\d{3})(\d{3})(\d{4})/,
                    "($1) $2-$3"
                  )}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-6">
                <label>Opportunity Name</label>
                <input
                  type="text"
                  readOnly
                  value={this.state.opportunity.name || ""}
                  className="form-control"
                />
              </div>
              <div className="col-md-6">
                <label>Opportunity Industry</label>
                <input
                  type="text"
                  readOnly
                  value={this.state.opportunity.industry || ""}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Opportunity Date</label>
                <input
                  type="text"
                  readOnly
                  value={moment(this.state.opportunity.date || "").format(
                    "MM/DD/YYYY"
                  )}
                  className="form-control"
                />
              </div>
              <div className="col-md-6">
                <label>Opportunity Location</label>
                <input
                  type="text"
                  readOnly
                  value={this.state.opportunity.location || ""}
                  className="form-control"
                />
              </div>
              <div className="col-md-3">
                <label>Opportunity Zip</label>
                <input
                  type="text"
                  readOnly
                  value={this.state.opportunity.zip || ""}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-6">
                <label>Opportunity Type</label>
                <input
                  type="text"
                  readOnly
                  value={this.state.opportunity.opportunity_type || ""}
                  className="form-control"
                />
              </div>
              <div className="col-md-6">
                <label>Opportunity Status</label>
                <input
                  type="text"
                  readOnly
                  value={this.state.opportunity.status || ""}
                  className="form-control"
                />
              </div>
            </div>
            <p />
            <div className="row">
              {this.state.opportunity.status == "Lost" && (
                <div className="col">
                  <label>Reason Lost</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.opportunity.reason_lost || ""}
                    className="form-control"
                  />
                </div>
              )}
            </div>
            <p />
            <div className="row">
              {this.state.opportunity.comments && (
                <div className="col">
                  <label>Comments</label>
                  <textarea
                    type="text"
                    readOnly
                    value={this.state.opportunity.comments || ""}
                    className="form-control"
                  />
                </div>
              )}
            </div>
            <p />
            <div className="row">
              <div className="col">
                <label>Notes (internal)</label>
                <textarea
                  type="text"
                  readOnly
                  value={this.state.opportunity.notes || ""}
                  className="form-control"
                />
              </div>
            </div>
            <p />
          </form>
          <button
            title="Click to expand/hide create specification form"
            type="button"
            onClick={this.toggleHidden.bind(this)}
            className="btn btn-secondary btn-sm"
          >
            {this.state.isHidden ? (
              <span>
                Add Specs{" "}
                <i className="fa fa-plus-circle" aria-hidden="true"></i>
              </span>
            ) : (
              <span>
                Close Specs Form{" "}
                <i className="fa fa-minus-circle" aria-hidden="true"></i>
              </span>
            )}
          </button>
          <br />
          <small>
            * Opportunities now support multiple specifications. Click the
            button above to add a new specification.
          </small>
          <p />
          {!this.state.isHidden &&
            this.state.opportunity.opportunity_type == "ADOT" && (
              <AdotSpecForm
                handleNewSpecification={this.addNewSpecification}
                oppId={this.props.match.params.id}
                userId={this.state.opportunity.user_id}
                specType={this.state.opportunity.opportunity_type}
              />
            )}
          {!this.state.isHidden &&
            this.state.opportunity.opportunity_type == "UAV" && (
              <DroneSpecForm
                handleNewSpecification={this.addNewSpecification}
                oppId={this.props.match.params.id}
                specType={this.state.opportunity.opportunity_type}
                userId={this.state.opportunity.user_id}
              />
            )}
          {!this.state.isHidden &&
            this.state.opportunity.opportunity_type == "NAAS" && (
              <NaasSpecForm
                handleNewSpecification={this.addNewSpecification}
                oppId={this.props.match.params.id}
                specType={this.state.opportunity.opportunity_type}
                userId={this.state.opportunity.user_id}
              />
            )}
          {!this.state.isHidden &&
            this.state.opportunity.opportunity_type == "Flight" && (
              <FlightSpecForm
                handleNewSpecification={this.addNewSpecification}
                oppId={this.props.match.params.id}
                specType={this.state.opportunity.opportunity_type}
                userId={this.state.opportunity.user_id}
              />
            )}
          {!this.state.isHidden &&
            this.state.opportunity.opportunity_type == "Film" && (
              <FilmSpecForm
                handleNewSpecification={this.addNewSpecification}
                oppId={this.props.match.params.id}
                userId={this.state.opportunity.user_id}
                specType={this.state.opportunity.opportunity_type}
              />
            )}
          {!this.state.isHidden &&
            this.state.opportunity.opportunity_type == "Film (Image Only)" && (
              <FilmSpecForm
                handleNewSpecification={this.addNewSpecification}
                oppId={this.props.match.params.id}
                userId={this.state.opportunity.user_id}
                specType={this.state.opportunity.opportunity_type}
              />
            )}
          {!this.state.isHidden &&
            this.state.opportunity.opportunity_type ==
              "Film (Image Only) - Photo Geodetic" && (
              <PgFilmSpec
                handleNewSpecification={this.addNewSpecification}
                oppId={this.props.match.params.id}
                userId={this.state.opportunity.user_id}
                specType={this.state.opportunity.opportunity_type}
              />
            )}
          {!this.state.isHidden &&
            this.state.opportunity.opportunity_type ==
              "Film (Mapping Only)" && (
              <FilmSpecForm
                handleNewSpecification={this.addNewSpecification}
                oppId={this.props.match.params.id}
                userId={this.state.opportunity.user_id}
                specType={this.state.opportunity.opportunity_type}
              />
            )}
          {!this.state.isHidden &&
            this.state.opportunity.opportunity_type ==
              "Film (Mapping Only) - Photo Geodetic" && (
              <PgFilmSpec
                handleNewSpecification={this.addNewSpecification}
                oppId={this.props.match.params.id}
                userId={this.state.opportunity.user_id}
                specType={this.state.opportunity.opportunity_type}
              />
            )}
          {!this.state.isHidden &&
            this.state.opportunity.opportunity_type == "Digital" && (
              <DigitalSpecForm
                handleNewSpecification={this.addNewSpecification}
                oppId={this.props.match.params.id}
                userId={this.state.opportunity.user_id}
                specType={this.state.opportunity.opportunity_type}
              />
            )}
          {!this.state.isHidden &&
            this.state.opportunity.opportunity_type ==
              "Digital (Image Only)" && (
              <DigitalSpecForm
                handleNewSpecification={this.addNewSpecification}
                oppId={this.props.match.params.id}
                userId={this.state.opportunity.user_id}
                specType={this.state.opportunity.opportunity_type}
              />
            )}
          {!this.state.isHidden &&
            this.state.opportunity.opportunity_type ==
              "Digital (Mapping Only)" && (
              <DigitalSpecForm
                handleNewSpecification={this.addNewSpecification}
                oppId={this.props.match.params.id}
                userId={this.state.opportunity.user_id}
                specType={this.state.opportunity.opportunity_type}
              />
            )}
          {!this.state.isHidden &&
            this.state.opportunity.opportunity_type ==
              "Digital (Image Only) - Photo Geodetic" && (
              <PgDigitalSpec
                handleNewSpecification={this.addNewSpecification}
                oppId={this.props.match.params.id}
                userId={this.state.opportunity.user_id}
                specType={this.state.opportunity.opportunity_type}
              />
            )}
          {!this.state.isHidden &&
            this.state.opportunity.opportunity_type ==
              "Digital (Mapping Only) - Photo Geodetic" && (
              <PgDigitalSpec
                handleNewSpecification={this.addNewSpecification}
                oppId={this.props.match.params.id}
                userId={this.state.opportunity.user_id}
                specType={this.state.opportunity.opportunity_type}
              />
            )}
          {!this.state.isHidden &&
            this.state.opportunity.opportunity_type == "LiDAR" && (
              <LidarSpecForm
                handleNewSpecification={this.addNewSpecification}
                oppId={this.props.match.params.id}
                userId={this.state.opportunity.user_id}
                specType={this.state.opportunity.opportunity_type}
              />
            )}
          {!this.state.isHidden &&
            this.state.opportunity.opportunity_type == "LiDAR Processing" && (
              <LidarProcessingSpecForm
                handleNewSpecification={this.addNewSpecification}
                oppId={this.props.match.params.id}
                userId={this.state.opportunity.user_id}
                specType={this.state.opportunity.opportunity_type}
              />
            )}
          {!this.state.isHidden &&
            this.state.opportunity.opportunity_type == "Scan" && (
              <ScanSpecForm
                handleNewSpecification={this.addNewSpecification}
                oppId={this.props.match.params.id}
                userId={this.state.opportunity.user_id}
                specType={this.state.opportunity.opportunity_type}
              />
            )}
          {!this.state.isHidden &&
            this.state.opportunity.opportunity_type == "Plot" && (
              <PlotSpecForm
                handleNewSpecification={this.addNewSpecification}
                oppId={this.props.match.params.id}
                userId={this.state.opportunity.user_id}
                specType={this.state.opportunity.opportunity_type}
              />
            )}
          {!this.state.isHidden &&
            this.state.opportunity.opportunity_type == "Survey" && (
              <SurveySpecForm
                handleNewSpecification={this.addNewSpecification}
                oppId={this.props.match.params.id}
                userId={this.state.opportunity.user_id}
                specType={this.state.opportunity.opportunity_type}
                surveyLocation={this.state.opportunity.location}
                surveyNotes={this.state.opportunity.notes}
              />
            )}
          {!this.state.isHidden &&
            this.state.opportunity.opportunity_type == "Other" && (
              <OtherSpecForm
                handleNewSpecification={this.addNewSpecification}
                oppId={this.props.match.params.id}
                userId={this.state.opportunity.user_id}
                specType={this.state.opportunity.opportunity_type}
              />
            )}
          {!this.state.isHidden &&
            this.state.opportunity.opportunity_type ==
              "Film - Photo Geodetic" && (
              <PgFilmSpec
                handleNewSpecification={this.addNewSpecification}
                oppId={this.props.match.params.id}
                userId={this.state.opportunity.user_id}
                specType={this.state.opportunity.opportunity_type}
              />
            )}
          {!this.state.isHidden &&
            this.state.opportunity.opportunity_type ==
              "Digital - Photo Geodetic" && (
              <PgDigitalSpec
                handleNewSpecification={this.addNewSpecification}
                oppId={this.props.match.params.id}
                userId={this.state.opportunity.user_id}
                specType={this.state.opportunity.opportunity_type}
              />
            )}
          {!this.state.isHidden &&
            this.state.opportunity.opportunity_type == "Construction" && (
              <ConstructionSpec
                handleNewSpecification={this.addNewSpecification}
                oppId={this.props.match.params.id}
                userId={this.state.opportunity.user_id}
                surveyLocation={this.state.opportunity.location}
                specType={this.state.opportunity.opportunity_type}
              />
            )}
          <hr />
        </div>
      </div>
    );
  }
}
