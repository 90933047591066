import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="search"
export default class extends Controller {
  static targets = [
    "title",
    "content",
    "search",
    "pm",
    "invoiceContact",
    "po",
    "table",
  ];

  connect() {
    // You can initialize here if needed
  }

  filterPosts(event) {
    const searchTerm = event.target.value.toLowerCase();

    this.titleTargets.forEach((title) => {
      const postContent =
        title.nextElementSibling?.textContent?.toLowerCase() || "";
      const postTitle = title.textContent.toLowerCase();
      const postSearch =
        title.nextElementSibling?.nextElementSibling?.textContent?.toLowerCase() ||
        "";
      const postPM =
        title.nextElementSibling?.nextElementSibling?.nextElementSibling?.textContent?.toLowerCase() ||
        "";
      const postInvoiceContact =
        title.nextElementSibling?.nextElementSibling?.nextElementSibling?.nextElementSibling?.textContent?.toLowerCase() ||
        "";
      const postPO =
        title.nextElementSibling?.nextElementSibling?.nextElementSibling?.nextElementSibling?.nextElementSibling?.textContent?.toLowerCase() ||
        "";

      const matchesSearch =
        postContent.includes(searchTerm) ||
        postTitle.includes(searchTerm) ||
        postSearch.includes(searchTerm) ||
        postPM.includes(searchTerm) ||
        postInvoiceContact.includes(searchTerm) ||
        postPO.includes(searchTerm);

      title.parentElement.style.display = matchesSearch ? "" : "none";
    });
  }

  sort(event) {
    const table = this.tableTarget;
    const tbody = table.tBodies[0];
    const th = event.currentTarget;
    const index = Array.from(th.parentNode.children).indexOf(th);
    const direction = th.dataset.sortDirection === "asc" ? "desc" : "asc";
    const rows = Array.from(tbody.rows);

    rows.sort((rowA, rowB) => {
      const cellA = rowA.cells[index]?.textContent?.trim() || "";
      const cellB = rowB.cells[index]?.textContent?.trim() || "";

      if (cellA === "" || cellB === "") return 0;
      return cellA > cellB ? 1 : -1;
    });

    if (direction === "desc") rows.reverse();

    rows.forEach((row) => tbody.appendChild(row));

    th.dataset.sortDirection = direction;
  }
}
