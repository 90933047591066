import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Planimetrics from './Planimetrics';
import PlanimetricForm from './PlanimetricForm';
import PlanimetricView from './PlanimetricView';

export default (props) => {
  return (
    <Router>
      <div>
        <Route exact path="/cascopt/planimetrics" render={routeProps => <Planimetrics {...props} {...routeProps}/>} />
        <Route exact path="/cascopt/planimetrics/:id" render={routeProps => <PlanimetricView {...props} {...routeProps}/>} />
        <Route path="/cascopt/planimetrics/:id/edit" render={routeProps => <PlanimetricForm {...props} {...routeProps}/>} />
      </div>
    </Router>
  )
}
