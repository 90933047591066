import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import OfficeContactForm from './OfficeContactForm';
import update from 'immutability-helper';

export default class Office extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      office: props.office,
      company: props.company,
      contacts: [],
      isHidden: true
    }
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden
    })
  }

  static propTypes = {
    office: PropTypes.object
  }

  static defaultProps = {
    office: {}
  }

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/offices/${this.props.match.params.id}`,
        dataType: "JSON"
      }).done((data) => {
        this.setState({ data });
      })
    }
  }

  addNewContact = (contact) => {
    const contacts = update(this.state.contacts, { $push: [contact] });
    this.setState({
      contacts: contacts.sort(function (a, b) {
        return new Date(a.created_at) - new Date(b.created_at);
      })
    });
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <form>
            <div className="row">
              <div className="col-md-6">
                <label>Address</label>
                <p>
                  {this.state.office.address || ''}, {this.state.office.city || ''}, {this.state.office.state || ''} {this.state.office.zip || ''}
                  <br />
                  <small><b>Region:</b> {this.state.office.region || 'n/a'}</small>
                </p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Phone</label>
                <p>{this.state.office.phone || ''}</p>
              </div>
              {this.state.office.fax &&
                <div className="col-md-3">
                  <label>Fax</label>
                  <p>{this.state.office.fax || ''}</p>
                </div>}
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Custom Translation</label>
                <p>{this.state.office.custom_trans || ''}</p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-6">
                <label>Deliverable Notes</label>
                <p>{this.state.office.deliverable_notes || 'n/a'}</p>
              </div>
            </div>
            <p />
            <button title="Click to add a contact to this office" type="button" onClick={this.toggleHidden.bind(this)} className="btn btn-secondary btn-sm" >
              {this.state.isHidden ?
                <span>Add New Contact <i className="fa fa-plus-circle" aria-hidden="true"></i></span> :
                <span>Close Contact Form <i className="fa fa-minus-circle" aria-hidden="true"></i></span>}
            </button>
            <p />
          </form>
          {!this.state.isHidden && <OfficeContactForm handleNewContact={this.addNewContact} officeId={this.props.match.params.id} />}
          <hr />
        </div>
      </div>
    )
  }
}
