import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Metashapes from './Metashapes';
import MetashapeForm from './MetashapeForm';
import MetashapeView from './MetashapeView';

export default (props) => {
  return (
    <Router>
      <div>
        <Route exact path="/cascopt/metashapes" render={routeProps => <Metashapes {...props} {...routeProps}/>} />
        <Route exact path="/cascopt/metashapes/:id" render={routeProps => <MetashapeView {...props} {...routeProps}/>} />
        <Route path="/cascopt/metashapes/:id/edit" render={routeProps => <MetashapeForm {...props} {...routeProps}/>} />
      </div>
    </Router>
  )
}
