import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

export default class Rectification extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      rectification: props.rectification,
      project: props.project,
      scan: props.scan,
      quality_control: props.quality_control,
      isHidden: false
    }
  }

  static propTypes = {
    rectification: PropTypes.object
  }

  static defaultProps = {
    rectification: {}
  }

  componentDidMount() {
    if (this.props.match) {
      $.ajax({
        type: "GET",
        url: `/cascopt/rectifications/${this.props.match.params.id}`,
        dataType: "JSON"
      }).done((data) => {
        this.setState({ data });
      })
    }
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden
    })
  }

  render() {
    return (
      <div className="card">
        <div className="container">
          <a href={`/cascopt/rectifications`} className="stage-record">
            Back to Rectification Dashboard
          </a>
          <hr />
          <Link
            to={`/cascopt/rectifications/${this.state.rectification.id}/edit`}
          >
            <button className="btn btn-sm btn-success">
              Edit Rectification
            </button>
          </Link>
          <p />
          <button
            onClick={this.toggleHidden.bind(this)}
            title="Click to expand/hide create company form"
            className="btn btn-secondary btn-sm"
          >
            {this.state.isHidden ? (
              <span>
                Show Project Details{" "}
                <i className="fa fa-plus-circle" aria-hidden="true"></i>
              </span>
            ) : (
              <span>
                Hide Project Details{" "}
                <i className="fa fa-minus-circle" aria-hidden="true"></i>
              </span>
            )}
          </button>
          <p />
          {!this.state.isHidden && (
            <p id="pt-view-project-block">
              <h5>Project Details</h5>
              <b>Client Rep:</b> {this.state.project.project_manager} |{" "}
              <b>Project Manager:</b> {this.state.project.asst_pm || "n/a"}
              <hr />
              <div className="form-group row">
                <div className="col-md-6">
                  <b>Job:</b> {this.state.project.job_name || ""} |{" "}
                  {this.state.project.client_number || ""}-
                  {this.state.project.job_number || ""}
                  <br />
                  <b>Edge Job #:</b> {this.state.project.edge_job_number || "n/a"}
                  <br />
                  <b>Due:</b>{" "}
                  {moment(this.state.project.due_date || "").format(
                    "MM/DD/YYYY"
                  )}
                  <br />
                  <b>Client:</b> {this.state.project.client_name || ""}
                  <br />
                  <b>Deliverables:</b>{" "}
                  {this.state.project.deliverables ||
                    "Contact PM for deliverables info"}
                  <br />
                  <b>Flight Lines:</b> {this.state.project.flight_lines || ""} |{" "}
                  <b>Exposures:</b> {this.state.project.exposures || ""}|{" "}
                  <b>Models:</b> {this.state.project.models || ""} |{" "}
                  <b>Roll Number:</b> {this.state.scan.roll_number || ""} |{" "}
                  <b>Color:</b> {this.state.project.color_film || ""}
                </div>
                <div className="col-md-6">
                  <b>Image Sub:</b> {this.state.project.image_sub_name || "n/a"}
                  <br />
                  <b>Units:</b> {this.state.project.units || "n/a"}
                  <br />
                  <b>Project Type:</b>{" "}
                  {this.state.project.project_type || "n/a"}
                  <br />
                  <b>CAD Version:</b> {this.state.project.version || "n/a"}
                  <br />
                  <b>GSD Results:</b> {this.state.project.gsd_results || "n/a"}
                </div>
              </div>
            </p>
          )}
          <br />
          Last Updated: {this.state.project.last_updated}
          <hr />
          <form>
            <div className="row">
              <div className="col-md-3">
                <label>Image Staff</label>
                <p>{this.state.rectification.img_staff || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>To Sub</label>
                <p>{this.state.rectification.to_sub || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Sub Received</label>
                <p>{this.state.rectification.sub_received || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Rejected</label>
                <p>{this.state.rectification.rejected || "n/a"}</p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Sub Due Date</label>
                <p>
                  {moment(
                    this.state.rectification.sub_due_date || "n/a"
                  ).format("MM/DD/YYYY")}
                </p>
              </div>
              <div className="col-md-3">
                <label>Sub Received Date</label>
                <p>
                  {moment(
                    this.state.rectification.sub_received_date || "n/a"
                  ).format("MM/DD/YYYY")}
                </p>
              </div>
              <div className="col-md-3">
                <label>Rejected Date</label>
                <p>
                  {moment(
                    this.state.rectification.rejected_date || "n/a"
                  ).format("MM/DD/YYYY")}
                </p>
              </div>
            </div>
            <p />
            <div className="row">
              <div className="col-md-3">
                <label>Image Complete</label>
                <p>{this.state.rectification.img_complete || "n/a"}</p>
              </div>
              <div className="col-md-3">
                <label>Image Done Date</label>
                <p>
                  {moment(
                    this.state.rectification.img_done_date || "n/a"
                  ).format("MM/DD/YYYY")}
                </p>
              </div>
              <div className="col-md-3">
                <label>File Location</label>
                <p>{this.state.rectification.file_location || "n/a"}</p>
              </div>
            </div>
            <p />
            {this.state.quality_control.type == "PostQc" &&
            <div className="form-group row">
              <div className="col-md-3">
                <label>Post QC - Fixed</label>
                <p>{this.state.rectification.fixed || "n/a"}</p>
              </div>
            </div>}
            <p />
            <div className="row">
              <div className="col">
                <label>Rectification Notes</label>
                <p>{this.state.project.rect_notes || "n/a"}</p>
              </div>
            </div>
            <p />
            {this.state.project.lidar_scope && (
              <div className="row">
                <div className="col">
                  <label>LiDAR Scope</label>
                  <p>{this.state.project.lidar_scope || "n/a"}</p>
                </div>
              </div>
            )}
          </form>
          <hr />
          {this.state.project.project_notes && (
            <div>
              <h5>Project Information</h5>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Notes from Project</label>
                  <p>{this.state.project.project_notes || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.survey_notes && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Notes from Survey</label>
                  <p>{this.state.project.survey_notes || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.flights_notes && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Notes from Flight</label>
                  <p>{this.state.project.flights_notes || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.drone_notes && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Notes from Drone</label>
                  <p>{this.state.project.drone_notes || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.scan_notes && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Notes from Scan</label>
                  <p>{this.state.project.scan_notes || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.aero_notes && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Notes from AT</label>
                  <p>{this.state.project.aero_notes || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.lidar_notes && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Notes from LiDAR</label>
                  <p>{this.state.project.lidar_notes || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.comp_notes && (
            <div>
              <div className="row">
                <div className="col">
                  <label>Notes from Compilation</label>
                  <p>{this.state.project.comp_notes || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
          {this.state.project.project_purpose && (
            <div>
              <h5>Project Purpose Information</h5>
              <hr />
              <div className="row">
                <div className="col">
                  <label>Project Purpose</label>
                  <p>{this.state.project.project_purpose || "n/a"}</p>
                </div>
              </div>
              <p />
            </div>
          )}
        </div>
      </div>
    );
  }
}
