import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Contacts from './Contacts';
import ContactForm from './ContactForm';
import ContactView from './ContactView';

export default (props) => {
  return (
    <Router>
      <div>
        <Route exact path="/contacts" render={routeProps => <Contacts {...props} {...routeProps}/>} />
        <Route exact path="/contacts/:id" render={routeProps => <ContactView {...props} {...routeProps}/>} />
        <Route path="/contacts/:id/edit" render={routeProps => <ContactForm {...props} {...routeProps}/>} />
      </div>
    </Router>
  )
}
