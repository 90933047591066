/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';

export default class Dsm extends React.Component {
  render() {
    return (
      <div>
        <h3 id="header">Projects Ready for DSM</h3>
        <a href="/cascopt/waiting_for_at" className="view-record">
          DSM Projects Waiting on AT
        </a>
        <br />
        <a href="/cascopt/aerotriangulations" className="view-record">
          AT Dashboard
        </a>
      </div>
    );
  }
}
