import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Lidars from './Lidars';
import LidarForm from './LidarForm';
import LidarView from './LidarView';

export default (props) => {
  return (
    <Router>
      <div>
        <Route exact path="/cascopt/lidars" render={routeProps => <Lidars {...props} {...routeProps}/>} />
        <Route exact path="/cascopt/lidars/:id" render={routeProps => <LidarView {...props} {...routeProps}/>} />
        <Route path="/cascopt/lidars/:id/edit" component={LidarForm} />
      </div>
    </Router>
  )
}
