import React from "react";
import PropTypes from "prop-types";
import update from "immutability-helper";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import moment from "moment";

export default class ATSearch extends React.Component {
  static propTypes = {
    aerotriangulations: PropTypes.array,
  };

  static defaultProps = {
    aerotriangulations: [],
  };

  constructor(props, railsContext) {
    super(props);
    this.state = {
      aerotriangulations: this.props.aerotriangulations,
      isHidden: true,
      isHiddenNotComplete: false,
      isHiddenComplete: true,
      isLoading: false,
    };
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }

  toggleHiddenNotComplete() {
    this.setState({
      isHiddenNotComplete: !this.state.isHiddenNotComplete,
    });
  }

  toggleHiddenComplete() {
    this.setState({
      isHiddenComplete: !this.state.isHiddenComplete,
    });
  }

  componentDidMount() {
    if (this.props.match) {
      this.setState({ isLoading: true });
      $.ajax({
        type: "GET",
        url: "/cascopt/at_search",
        dataType: "JSON",
      }).done((data) => {
        this.setState({ aerotriangulations: data, isLoading: false });
      });
    }
  }

  addNewAerotriangulation(aerotriangulation) {
    const aerotriangulations = update(this.state.aerotriangulations, {
      $push: [aerotriangulation],
    });
    this.setState({
      aerotriangulations: aerotriangulations.sort(function (a, b) {
        return new Date(a.created_at) - new Date(b.created_at);
      }),
    });
  }

  render() {
    const pickDateColor = (date) => {
      const dueDate = new moment(date);
      const yellowDate = new moment(date).subtract(5, "days");
      const now = moment();

      if (now < yellowDate) {
        return "black";
      }
      if (now < dueDate) {
        return "black";
      }
      return "black";
    };

    const { aerotriangulations, isLoading } = this.state;
    if (isLoading) {
      return <div className="loader"></div>;
    }

    return (
      <div>
        <div className="row">
          <div className="col-md-8">
            <h3 id="header">Aerial Triangulation Search</h3>
            <a href="/cascopt/aerotriangulations" className="view-record">
              AT Dashboard
            </a>
            <br />
          </div>
        </div>
        <hr />
        {!this.state.isHiddenNotComplete && (
          <ReactTable
            key={aerotriangulations.id}
            data={aerotriangulations}
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id]) === filter.value
            }
            defaultPageSize={100}
            style={{
              height: "800px",
              textAlign: "center",
            }}
            columns={[
              {
                Header: "AT Info",
                columns: [
                  {
                    Header: "Staff",
                    accessor: "at_staff",
                    filterable: true,
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .startsWith(filter.value.toLowerCase()),
                  },
                  {
                    Header: "AT Date Done",
                    accessor: "at_done_date",
                    filterable: false,
                    Cell: (props) => (
                      <span style={{ color: pickDateColor(props.value) }}>
                        {moment(props.value).format("MM/DD/YYYY")}
                      </span>
                    ),
                  },
                  {
                    Header: "Job Number",
                    accessor: "project.job_number",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id].startsWith(filter.value),
                    Cell: (props) => (
                      <a
                        href={`/cascopt/aerotriangulations/${props.original.id}`}
                        className="view-record"
                      >
                        {props.original.project.job_number}
                      </a>
                    ),
                  },
                  {
                    Header: "Job Name",
                    accessor: "project.job_name",
                    width: 300,
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .startsWith(filter.value.toLowerCase()),
                    Cell: (props) => (
                      <a
                        href={`/cascopt/aerotriangulations/${props.original.id}`}
                        className="view-record"
                      >
                        {props.original.project.job_name}
                      </a>
                    ),
                  },
                  {
                    Header: "Client",
                    accessor: "project.client_name",
                    Filter: ({ filter, onChange }) => (
                      <input
                        placeholder={"Search for..."}
                        onChange={(event) => onChange(event.target.value)}
                        value={filter ? filter.value : ""}
                        style={{
                          width: "100%",
                          color: "black",
                        }}
                      />
                    ),
                    filterMethod: (filter, row) =>
                      row[filter.id]
                        .toLowerCase()
                        .startsWith(filter.value.toLowerCase()),
                  },
                  {
                    Header: "Project Due Date",
                    accessor: "project.due_date",
                    filterable: false,
                    Cell: (props) => (
                      <span style={{ color: pickDateColor(props.value) }}>
                        {moment(props.value).format("MM/DD/YYYY")}
                      </span>
                    ),
                  },
                ],
              },
            ]}
            defaultSorted={[
              {
                id: "project.due_date",
                asc: true,
              },
            ]}
            className="-striped -highlight"
          />
        )}
      </div>
    );
  }
}
